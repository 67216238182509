import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CardContent, Box } from '@material-ui/core';
import FlatCard from '../flatCard';

import useStyles from './styles';
import { toCEP, toMoney } from '../../utils/parsers';

const OrderResume = ({
  enderecoEntrega, pagamentos, meta, desconto
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.shippingContainer}>
      <div>
        <Typography variant="subtitle2">
          A ENTREGA SERA REALIZADA EM:
        </Typography>

        <FlatCard>
          <CardContent>
            <Box minHeight={80}>
              <Typography variant="subtitle2">
                {`${enderecoEntrega.endereco}, ${enderecoEntrega.numero}`}
              </Typography>

              <Typography variant="subtitle2">
                {enderecoEntrega.bairro}
              </Typography>

              <Typography variant="subtitle2">
                {`${enderecoEntrega.cidade} - ${enderecoEntrega.estado}`}
              </Typography>

              <Typography variant="subtitle2">
                {toCEP(enderecoEntrega.cep)}
              </Typography>
            </Box>
          </CardContent>
        </FlatCard>
      </div>

      <div>
        <Typography variant="subtitle2">
          PAGAMENTO
        </Typography>

        <FlatCard>
          <CardContent>
            <Box minHeight={80}>
              <div className={classes.paymentItem}>
                <Typography variant="subtitle2">
                  SUB-TOTAL
                </Typography>

                <Typography variant="caption">
                  {toMoney(meta.subTotal)}
                </Typography>
              </div>

              <div className={classes.paymentItem}>
                <Typography variant="subtitle2">
                  FRETE
                </Typography>

                <Typography variant="caption">
                  {toMoney(meta.shipping)}
                </Typography>
              </div>

              <div className={classes.paymentItem}>
                <Typography variant="subtitle2">
                  DESCONTO
                </Typography>

                <Typography variant="caption">
                  {toMoney(desconto)} 
                </Typography>
              </div>

              <div className={classes.paymentItem}>
                <Typography variant="subtitle2">
                  GARANTIA ESTENDIDA
                </Typography>

                <Typography variant="caption">
                  {toMoney(pagamentos[0]?.garantiaEstendida)}
                </Typography>
              </div>

              <div className={classes.paymentItem}>
                <Typography variant="subtitle2">
                  VALE
                </Typography>

                <Typography variant="caption">
                  {toMoney(meta.voucher)}
                </Typography>
              </div>

              {/* <div className={classes.paymentItem}>
                <Typography variant="subtitle2">
                  IMPOSTOS
                </Typography>

                <Typography variant="caption">
                  {toMoney(totalImpostos)}
                </Typography>
              </div> */}
            </Box>
          </CardContent>
        </FlatCard>
      </div>

      <div>
        <FlatCard>
          <CardContent>
            <Box minHeight={80}>
              <Typography variant="subtitle2">
                O PEDIDO FOI FEITO COM:
              </Typography>

              <div className={classes.paymentMethood}>
                {pagamentos.map((payment, index) => (
                  <Typography variant="subtitle2" key={`${index + 1}`}>
                    {payment.formaPagamento}
                  </Typography>
                ), 0)}

                <Typography variant="subtitle2">
                  {toMoney(meta.total)}
                </Typography>
              </div>
            </Box>
          </CardContent>
        </FlatCard>
      </div>
    </Box>
  );
};

OrderResume.propTypes = {
  enderecoEntrega: PropTypes.instanceOf(Object).isRequired,
  pagamentos: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  // totalImpostos: PropTypes.number.isRequired,
};

export default OrderResume;
