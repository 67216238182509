import React, { useEffect, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import PropTypes from 'prop-types';
import useStyles from './styles';

const RefinementListCustom = ({ refinement, user, customer }) => {
  const classes = useStyles();
  const [filtersOptions, setFiltersOptions] = useState([]);
  const [allFiltersOptions, setAllFiltersOptions] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const sellerFilialName = user.filiais[0].nomeFilial.replace('CD', '').trim();
  const clientCity = customer ? customer.cidade : '';

  const {
    attribute,
    titleShowMore,
    isSearchable,
  } = refinement;

  const options = {
    attribute,
    sortBy: ['isRefined:desc', 'name:asc'],
  };

  const {
    divBtn,
    refinementList,
    refinementBtn,
    refinementCheckbox,
    refinementLabel,
    refinementCount,
    searchContent,
    searchInput,
    searchInputIcon,
  } = classes;

  const {
    items,
    refine,
    toggleShowMore,
    searchForItems,
  } = useRefinementList({
    ...options,
  });

  const handleSearch = (search) => {
    searchForItems(search);
  };

  const handleShowMore = () => setShowMore(!showMore);

  useEffect(() => {
    toggleShowMore();
  }, []);

  useEffect(() => {
    const newItems = items.map((item) => {
      const { value } = item;
      let typeFilial = 0;
      const nameFilial = value.toUpperCase();
      if (nameFilial.includes('CAJAMAR')) {
        typeFilial = 3;
      } else if (nameFilial.includes(clientCity.toUpperCase())) {
        typeFilial = 2;
      } else if (nameFilial.includes(sellerFilialName.toUpperCase())) {
        typeFilial = 1;
      }
      return ({ ...item, typeFilial });
    });

    const res = newItems.sort((a, b) => b.typeFilial - a.typeFilial);

    setAllFiltersOptions(res);

    if (res.length > 5) {
      setAllFiltersOptions(res);
      setFiltersOptions(res.slice(0, 5));
    } else {
      setShowMore(false);
      setFiltersOptions(res);
      setAllFiltersOptions([]);
    }
  }, [items]);

  return (
    <>
      {
        isSearchable && (
          <>
            <input
              type="search"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              placeholder="Buscar"
              spellCheck={false}
              className={searchInput}
              onChange={(e) => handleSearch(e.currentTarget.value)}
            />
            <div className={searchContent}>
              <span className={searchInputIcon} />
            </div>
          </>
        )
      }
      <ul>
        {
          (showMore && allFiltersOptions.length > 5 ? allFiltersOptions : filtersOptions).map(({
            label, value, count, isRefined,
          }) => (
            <li key={label} className={refinementList}>
              <input
                className={refinementCheckbox}
                type="checkbox"
                checked={isRefined}
                value={value}
                onChange={() => refine(value)}
              />
              <span className={refinementLabel}>{label}</span>
              <span className={refinementCount}>{count}</span>
            </li>
          ))
       }
      </ul>
      { allFiltersOptions.length > 5 && (
      <div className={divBtn}>
        <button className={refinementBtn} type="button" onClick={handleShowMore}>
          {showMore ? 'Ver menos' : `Ver todas as ${titleShowMore}`}
        </button>
      </div>
      )}
    </>
  );
};

RefinementListCustom.propTypes = {
  refinement: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
};

export default RefinementListCustom;
