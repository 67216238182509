import React from 'react';
import {
  Box, Typography, Button, List,
} from '@material-ui/core';

import useStyles from './styles';
import { goBack, location } from '../../history';

const NotFoundError = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <List className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          404
        </Typography>

        <Box pb={1} />

        <Typography>
          Desculpe, não encontramos a referencia:
        </Typography>

        <Typography>
          {location.pathname}
        </Typography>

        <Box pb={1} />

        <Button onClick={() => goBack()}>
          Voltar
        </Button>
      </List>
    </main>
  );
};

export default NotFoundError;
