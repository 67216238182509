import apiService from '../../../shared/services/apiService';
import apiServiceOc from '../../../shared/services/apiServiceOc';

// eslint-disable-next-line import/prefer-default-export
export const getOrder = async (orderId, customerId) => {
  const { data: { results } } = await apiService.get(`/pedidos/${orderId}/clientes/${customerId}/detalhes`);

  if (!results.entregas.length) {
    throw new Error('Não foi possivel localizar o pedido');
  }
  const productsCount = results.entregas.reduce((accumuml, delivery) => (
    delivery.itens.reduce((acc, item) => (
      item.quantidade + acc
    ), 0) + accumuml
  ), 0);

  const subTotal = results.pagamentos.length ? results.pagamentos[0].subTotal : 0;

  const shipping = results.pagamentos.length ? results.pagamentos[0].frete : 0;

  // const totalImpostos = results.totalImpostos ? results.totalImpostos : 0;

  const voucher = results.pagamentos.length ? results.pagamentos[0].valorVoucherCupom : 0;

  const discount = results.pagamentos.reduce((accumuml, payment) => (
    payment.desconto + accumuml
  ), 0);

  const total = results.pagamentos.reduce((accumuml, payment) => (
    (payment.installmentValue * payment.installmentQuantity) + accumuml
  ), 0);

  const hasBillet = results.pagamentos.some((payment) => payment.type === 'B');

  const { informacoesAdicionais } = results;

  return {
    ...results,
    // totalImpostos,
    informacoesAdicionais: {
      observacaoDePedido: informacoesAdicionais.observacaoDePedido || '',
      ordemDeCompra: informacoesAdicionais.ordemDeCompra || '',
      classificacaoCliente: informacoesAdicionais.classificacaoCliente || '',
      inscricaoEstadual: informacoesAdicionais.inscricaoEstadual || '',
    },
    meta: {
      productsCount,
      subTotal,
      shipping,
      voucher,
      discount,
      total,
      hasBillet,
    },
  };
};

export const getLinkFile = async (orderId) => {
  const { data } = await apiService.get(`/pedidos/ordens-compra?codigoPedido=${orderId}`);

  if (!data) {
    throw new Error('Este pedido não contém OC!');
  }
  return {
    ...data,
  };
};

export const postFileOc = async (orderId, formData) => {
  const { data } = await apiServiceOc.post(`/pedidos/${orderId}/ordens-compra`, formData);

  return {
    ...data,
  };
};
