import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, TextField, Box, Button, IconButton, useMediaQuery, useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';

import { Close } from '@material-ui/icons';
import {
  closeDialogDiscountAction, checkoutDiscountAction, checkoutRequestDiscountAction, openDialogDiscountObsAction,
  openDialogDiscountRequestAction,
} from '../../../../../main/checkout/actions';
import useStyles from './styles';
import { toMoney, toFloat } from '../../../../utils/parsers';

const DialogDiscount = ({
  dialogDiscountOpened, closeDialogDiscount, onSubmit, loading, initialValue,
  discountStatus, readOnly, isNotMarketPlaceOnly, maxDiscount, totalInstallment, descontoDesabilitado, 
  freight, justificativa, checkoutRequestDiscount, aguardandoSolicitacao, hasCoupon, subTotalPrazo,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setRequestSuccess(false);
    closeDialogDiscount();
  };

  const [form, setForm] = useState({
    percentualDesconto: initialValue,
    observacaoSolicitacao: '',
  });
  const [amount, setAmount] = useState(maxDiscount);
  const [discountStatusError, setDiscountStatusError] = useState('');
  const [isPercent, setIspercent] = useState(true);
  const [requestSuccess, setRequestSuccess] = useState(false);
  

  const valueRef = useRef();
  const discriptionRef = useRef();
  const isInvalid = form.percentualDesconto > 10.2 && !form.observacaoSolicitacao;

  useEffect(() => {
    setForm({
      percentualDesconto: initialValue,
      observacaoSolicitacao: '',
    });
  }, [initialValue]);

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  useEffect(() => {
    return setAmount(maxDiscount);
  }, [maxDiscount]);

  useHotkeys('shift+w', () => !readOnly && !descontoDesabilitado && !hasCoupon && setTimeout(() => {
    if (isNotMarketPlaceOnly) valueRef.current.focus();
  }, 400), [valueRef, isNotMarketPlaceOnly, hasCoupon]);

  const handleChange = ({ target }) => setForm({
    ...form,
    [target.name]: target.value,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!aguardandoSolicitacao) {
      if (justificativa && form.observacaoSolicitacao != '' && form.observacaoSolicitacao.trim().length > 4) {
        checkoutRequestDiscount({
          descontoSolicitado: +form.percentualDesconto,
          obsSolicitacao: form.observacaoSolicitacao,
        });
        setRequestSuccess(true);
      } else {
        if (!justificativa && !aguardandoSolicitacao ) {
          if (isPercent) {
            onSubmit({
              porcentagemDesconto: +form.percentualDesconto,
            });
          } else {
            onSubmit({
              valorDesconto: +amount,
              valorTotal: subTotalPrazo,
            });
          }
        }
      }
    }

  };

  const handleBlur = () => {
    if (!aguardandoSolicitacao) {
      if (justificativa && form.observacaoSolicitacao != '' && form.observacaoSolicitacao.trim().length > 4) {
        checkoutRequestDiscount({
          descontoSolicitado: +form.percentualDesconto,
          obsSolicitacao: form.observacaoSolicitacao,
        });
        setRequestSuccess(true);
      } else {
        if (!justificativa && !aguardandoSolicitacao ) {
          if (isPercent) {
            onSubmit({
              porcentagemDesconto: +form.percentualDesconto,
            });
          } else {
            onSubmit({
              valorDesconto: +amount,
              valorTotal: subTotalPrazo,
            });
          }
        }
      }
    }
  };
  const handlePercent = ({ target: { value, name } }) => {
    const total = totalInstallment - freight;
    if (isPercent) {
      const parsedValue = +value.replace(/\D/g, '') / 100;
      setForm({
        ...form,
        [name]: parsedValue >= 100 ? 99 : parsedValue.toFixed(2),
      });
      return setAmount(
        parsedValue >= 100
          ? total
          : +((((total) / 100) * parsedValue)).toFixed(2),
      );
    }
    const parsedValue = +value.replace(/\D/g, '') / 100;
    setAmount(parsedValue > total ? total : parsedValue);
    return setForm({
      ...form,
      [name]: parsedValue >= total
        ? 100
        : ((parsedValue / total) * 100).toFixed(2),
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={dialogDiscountOpened}
      
    >
      <Box position="relative" style={{minHeight:190}}>
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        <DialogTitle id="simple-dialog-title">{justificativa || aguardandoSolicitacao ? 'Solicitação de Desconto' : 'Editar Desconto Total'}</DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit}>
          {!justificativa && !aguardandoSolicitacao && (
            <Box display="flex"  style={{marginBottom:40}}  >
              <TextField
                variant="outlined"
                label="Desconto Total"
                name="percentualDesconto"
                id="percentualDesconto"
                className={classes.discount}
                onChange={handlePercent}
                onBlur={handleBlur}
                required
                disabled={loading}
                value={
                isPercent
                  ? `% ${toFloat(form.percentualDesconto)}`
                  : toMoney(amount)
              }
                InputProps={{
                  inputProps: {
                    ref: valueRef,
                  },
                }}
                
              />

              <Button
                variant="outlined"
                className={classes.discountButton}
                onClick={() => setIspercent(!isPercent)}
              >
                {isPercent ? 'R$' : '%'}
              </Button>
            </Box>
            )}

            {justificativa && !requestSuccess && !aguardandoSolicitacao && (
            <>
              <Box pr={2} pb={2}/>

              <TextField style={isMobile ? {marginBottom:40, fontSize:8} : {marginBottom:40}} 
                variant="outlined"
                ref={discriptionRef}
                label={!isMobile ? "Preencha abaixo com a justificativa da solicitação de desconto": "Preencha com a justificativa"}
                disabled={loading}
                fullWidth
                onBlur={handleBlur}
                required
                value={form.observacaoSolicitacao}
                name="observacaoSolicitacao"
                onChange={handleChange}
               
              />
            </>
            )}

            {requestSuccess && !aguardandoSolicitacao && justificativa && (
            <>
              <Box pr={2} pb={2}/>
              <Box style={{marginBottom:40}}>
                <Box style={{textAlign: 'center', fontSize: 24, color: '#21AD34', marginBottom: 8}}>
                  Solicitação enviada com sucesso!
                </Box>
                <Box style={{textAlign: 'center', fontSize: 16}}>
                  Aguarde alguns instantes para prosseguir com a venda.
                </Box>
              </Box>
            </>
            )}

            {aguardandoSolicitacao && (
            <>
              <Box pr={2} pb={2}/>
              <Box style={{marginBottom:40}}>
                <Box style={{textAlign: 'center', fontSize: 24, color: '#2296F3', marginBottom: 8}}>
                  Desconto em análise!
                </Box>
                <Box style={{textAlign: 'center', fontSize: 16}}>
                  Aguarde alguns instantes para prosseguir com a venda.
                </Box>
              </Box>
            </>
            )}

            <Box display="none">
              <input type="submit" />
            </Box>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

DialogDiscount.defaultProps = {
  discountStatus: null,
  initialValue: '',
  maxDiscount: 0,
  totalInstallment: 0,
};

DialogDiscount.propTypes = {
  closeDialogDiscount: PropTypes.func.isRequired,
  dialogDiscountOpened: PropTypes.bool.isRequired,
  justificativa: PropTypes.bool.isRequired,
  aguardandoSolicitacao: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  checkoutRequestDiscount: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  freight: PropTypes.number.isRequired,
  subTotalPrazo: PropTypes.number.isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  discountStatus: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  descontoDesabilitado: PropTypes.bool.isRequired,
  isNotMarketPlaceOnly: PropTypes.bool.isRequired,
  maxDiscount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  totalInstallment: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  hasCoupon: PropTypes.bool,
};

const mapState = (state) => {
  const { checkout } = state.main.checkout.geral;

  return {
    ...state.main.checkout.geral,
    initialValue: checkout.discountCart,
    freight: checkout.resume ? checkout.resume.freight : 0,
    discountStatus: checkout.resume && checkout.resume.discountStatus,
    maxDiscount: (checkout.resume || {}).maxDiscount,
    totalInstallment: (checkout.resume || {}).totalInstallment,
  };
};

export default connect(mapState, {
  closeDialogDiscount: closeDialogDiscountAction,
  onSubmit: checkoutDiscountAction,
  checkoutRequestDiscount: checkoutRequestDiscountAction,
  openDialogDiscountObs: openDialogDiscountObsAction,
  openDialogDiscountRequest: openDialogDiscountRequestAction,
})(DialogDiscount);
