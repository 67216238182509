import React from 'react';
import * as PropTypes from 'prop-types';

import useStyles from './styles';

const CardInfo = ({
  label,
  value,
  disabled
}) => {
  const {
    infoContent,
    labelField,
    valueField,
    disabledField
  } = useStyles();

  return (
    <div className={infoContent}>
      <span className={labelField}>{label}</span>
      <span className={`${valueField} ${disabled && disabledField}`}>{value}</span>
    </div>
  );
};

CardInfo.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default CardInfo;