import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  IconButton,
  DialogTitle,
  Dialog,
  DialogActions,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Search } from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from 'react-select';
import Pagination from '@material-ui/lab/Pagination';
import CheckCircle from '@material-ui/icons/CheckCircleOutline';
import colorStyle from '../../../../../shared/utils/selectBorder';
import CardBarSale from '../../../../../shared/components/cardBarSale';
import ResponsiveTable from '../../../../../shared/components/responsiveTable';
import useStyles from '../../../styles';
import apiService from '../../../../../shared/services/apiService';
import {
  getVendedoresInativosAction, putStatusVendedoresAction, getVendedoresInativosFiltroAction, getCanalDeVendasAction,
  getUnNegociosAction, getCargosAction, getPermissoesAction, getNiveisAction,
} from '../../../actions';

const Vendedores = ({
  loading, usuariosInativos, getVendedoresInativos, totalpagesinativos, putStatusVendedores, getPermissoes, getVendedoresInativosFiltro, unNegocios, canaisDeVendaFilter, getCanalDeVendasFilter,
  cargos, permissoes, niveis, detailsVendedor, user, getCanalDeVendas, getUnNegocios, getCargos, getNiveis,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [idVendedores, setIdVendedores] = useState(null);
  const [nomeVendedor, setNomeVendedor] = useState('');
  const [status] = useState(1);
  const [pageSelects] = useState(null);
  const [open, setOpen] = useState(false);
  const [docCliente, setDocCliente] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [filtros, setFiltros] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [unNegocio, setUnNegocio] = useState('');
  const [canalVendas, setCanalVendas] = useState('');
  const [cargo, setCargo] = useState('');
  const [permissao, setPermissao] = useState([]);
  const [reloadField, setReloadField] = useState(false);
  const [nivel, setNivel] = useState('');
  const [arrayCanalVendas, setArrayCanalVendas] = useState([]);
  const [unNegocioLabel, setUnNegocioLabel] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAtivar = () => {
    putStatusVendedores({ idVendedores, status, page });
    setOpen(false);
  };

  useEffect(() => {
    getVendedoresInativosFiltro({ page, docCliente, unNegocio, canalVendas, cargo, nivel });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };


  const handleFilter = async () => {
    setLoadingSearch(true);
    setOpenSearch(true);
    await getVendedoresInativosFiltro({ page, docCliente, unNegocio, canalVendas, cargo, nivel });
    setLoadingSearch(false);
  };

  const optionNivel = niveis.find((a) => a.id
  === detailsVendedor.idNivel);

  const handleFilterOptions = async () => {
    if (filtros) {
      setFiltros(false);
    } else {
      setFiltros(true);
    }

  };

  useEffect(() => {
    getCanalDeVendas({ page: pageSelects });
    getUnNegocios({ page: pageSelects });
    getCargos({ page: pageSelects });
    getPermissoes({ page: pageSelects });
    getNiveis();
    getDadosSelects();
  }, []);



  const arrayUnNegocios = [];
  arrayUnNegocios.push({ value: '', label: 'Todos' });
  unNegocios.forEach((item) => {
    const { idUnidadeNegocio: value, nomeUnidadeNegocio: label } = item;
    arrayUnNegocios.push({ value, label });
    if (unNegocioLabel === label && unNegocio == '') {
      setUnNegocio(value);
    }
  });
  const arrayCargos = [];
  arrayCargos.push({ value: '', label: 'Todos' });
  cargos.forEach((item) => {
    const { id: value, nome: label } = item;
    arrayCargos.push({ value, label });
  });
  const arrayPermissoes = [];
  permissoes.forEach((item) => {
    const { id: value, nome: label } = item;
    arrayPermissoes.push({ value, label });
  });
  const arrayNiveis = [];
  arrayNiveis.push({ value: '', label: 'Todos' });
  niveis.forEach((item) => {
    const { id: value, nomeCategoria: label } = item;
    arrayNiveis.push({ value, label });
  });

  useEffect(() => {
    handleFilter();
  }, [unNegocio, canalVendas, cargo, nivel]);


  const getDadosSelects = async () => {
    try {
      const data = await apiService.get(`/vendedores/recuperacao/desempenho/${user.idVendedor}`);
      if (data.status) {
          arrayCanalVendas.push({ value: '', label: 'Todos' });
          data.data.results.canaisVenda.forEach((item) => {
            const { idCanalVendas: value, descricao: label } = item;
            arrayCanalVendas.push({ value, label });
          });

          setUnNegocioLabel(data.data?.results?.canaisVenda[0]?.unidadeDeNegocio);

      }
    } catch (error) {
    }
  };

  return (
    <div className={classes.divRow}>
      <div style={{ width: '100%' }}>
        <CardBarSale title="Home" />
        {loading && (<LinearProgress />)}

        <div>
          <div className={classes.titleAndButton}>
            <span>Usuários Inativos</span>

                  <div>
                      <div className={classes.divSearchIconContainerFilter}>
                        <IconButton onClick={handleFilterOptions}>
                          <FilterListIcon style={{ color: '#FFF' }} />
                        </IconButton>
                      </div>

                      <div className={classes.divSearch}>

                        <TextField
                          id="idSearch"
                          size="small"
                          fullWidth
                          variant="outlined"
                          placeholder="Busque usuário por nome ou e-mail"
                          align="right"
                          value={docCliente}
                          onChange={(e) => setDocCliente(e.target.value)}
                          onClick={docCliente !== '' ? () => setOpenSearch(true) : () => setOpenSearch(false)}
                          style={{ background: '#FFF' }}
                          InputProps={{
                            endAdornment: (
                              <>
                                <div className={classes.divSearchIconContainer}>
                                  <IconButton onClick={handleFilter}>
                                    <Search style={{ color: '#FFF' }} />
                                  </IconButton>
                                </div>
                              </>
                            ),
                          }}
                        />
                    </div>
                  </div>
          </div>

                {filtros && (
                  <Grid item spacing={2} container xs={11} style={{ marginTop: 20, marginLeft:25 }}>
                    <Grid item xs={4}>
                      <Typography className={classes.textsSilver}>CANAL DE VENDAS</Typography>
                      <Select
                        placeholder="Selecione aqui..."
                        styles={colorStyle}
                        options={arrayCanalVendas}
                        key={reloadField}
                        onChange={(e) => setCanalVendas(e.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.textsSilver}>CARGO</Typography>
                      <Select
                        styles={colorStyle}
                        placeholder="Selecione aqui..."
                        options={arrayCargos}
                        onChange={(e) => setCargo(e.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                    <Typography className={classes.textsSilver}>NÍVEL RECUPERAÇÃO</Typography>
                    <Select
                      styles={colorStyle}
                      placeholder={optionNivel ? optionNivel.nomeCategoria : 'Selecione Aqui...'}
                      options={arrayNiveis}
                      onChange={(e) => setNivel(e.value)}
                    />

                    </Grid>
                  </Grid>
                )}
        </div>
        <section className={classes.sessionTable}>
          <Paper elevation={1} style={{ width: '95%' }}>
            <ResponsiveTable pointer>
              <TableHead style={{ backgroundColor: '#F9F9F9' }}>
                <TableRow>
                  <TableCell component="th" align="left">
                    Nome
                  </TableCell>
                  <TableCell component="th">
                    E-mail
                  </TableCell>
                  <TableCell component="th">
                    Cargo
                  </TableCell>
                  <TableCell component="th">
                    UN.Negôcio
                  </TableCell>
                  <TableCell component="th">
                    Canal Vendas
                  </TableCell>
                  <TableCell component="th">
                    Ação
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {usuariosInativos.map((item) => (
                  <TableRow
                    key={item.idVendedor}
                    hover
                  >
                    <TableCell datatype="Nome" align="left">
                      <Box>
                        {item.nome ? item.nome : '-'}
                      </Box>
                      <Box style={{ color: '#888E9D', fontSize: 12 }}>
                        {` ID BSLELLER: ${item.idBseller ? item.idBseller : '-'}`}
                      </Box>
                    </TableCell>

                    <TableCell datatype="E-mail" style={{ color: '#888E9D' }}>
                      {item.email ? item.email : '-'}
                    </TableCell>

                    <TableCell datatype="Cargo" style={{ color: '#888E9D' }}>
                      {item.cargo ? item.cargo : '-'}
                    </TableCell>

                    <TableCell datatype="UN.Negôcio" style={{ color: '#888E9D' }}>
                      {item.unidadeDeNegocio ? item.unidadeDeNegocio : '-'}
                    </TableCell>
                    <TableCell datatype="Canal Vendas" style={{ color: '#888E9D' }}>
                      {item.canalDeVendas ? item.canalDeVendas : '-'}
                    </TableCell>

                    <TableCell datatype="Ação">
                      <IconButton onClick={() => {
                        handleClickOpen();
                        setIdVendedores(item.idVendedor);
                        setNomeVendedor(item.nome);
                      }}
                      >
                        <CheckCircle className={classes.boxSucess} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {!usuariosInativos.length && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Typography variant="body2">
                        {loading ? 'Buscando lista de usuários...'
                          : 'Nenhum Usuário Encontrado'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </ResponsiveTable>
          </Paper>
        </section>
        <div style={{
          display: 'flex', justifyContent: 'flex-end', marginBottom: 30, marginRight: 50,
        }}
        >
          <Pagination
            count={totalpagesinativos}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>
      </div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ATIVAR USUÁRIO
        </DialogTitle>
        <span style={{ marginLeft: 25 }}>
          Deseja Ativar
          {' '}
          {nomeVendedor}
          ?
        </span>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Não
          </Button>
          <Button onClick={handleAtivar} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Vendedores.propTypes = {
  loading: PropTypes.bool.isRequired,
  usuariosInativos: PropTypes.arrayOf(Object).isRequired,
  // checkoutMeta: PropTypes.instanceOf(Object).isRequired,
  getVendedoresInativos: PropTypes.func.isRequired,
  getVendedoresInativosFiltro: PropTypes.func.isRequired,
  putStatusVendedores: PropTypes.func.isRequired,
  // clearMeta: PropTypes.func.isRequired,
  // takeCheckout: PropTypes.func.isRequired,
  detailsVendedor: PropTypes.instanceOf(Object).isRequired,
  totalpagesinativos: PropTypes.number.isRequired,
  getUnNegocios: PropTypes.func.isRequired,
  getCargos: PropTypes.func.isRequired,
  getNiveis: PropTypes.func.isRequired,
  getPermissoes: PropTypes.func.isRequired,
  unNegocios: PropTypes.arrayOf(Object).isRequired,
  canaisDeVendaFilter: PropTypes.arrayOf(Object).isRequired,
  cargos: PropTypes.arrayOf(Object).isRequired,
  permissoes: PropTypes.arrayOf(Object).isRequired,
  getCanalDeVendasFilter: PropTypes.func.isRequired,
  niveis: PropTypes.arrayOf(Object).isRequired,
  getCanalDeVendas: PropTypes.func.isRequired,
  getUnNegocios: PropTypes.func.isRequired,
  getCargos: PropTypes.func.isRequired,
  getNiveis: PropTypes.func.isRequired,
  getPermissoes: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => {
  const { snack } = state.main.app;
  return ({
    ...state.main.app,
    ...state.main.sales,
    user: state.auth.login.user,
  });
};

export default connect(mapState, {
  getVendedoresInativos: getVendedoresInativosAction,
  putStatusVendedores: putStatusVendedoresAction,
  getVendedoresInativosFiltro: getVendedoresInativosFiltroAction,
  getCanalDeVendas: getCanalDeVendasAction,
  getUnNegocios: getUnNegociosAction,
  getCargos: getCargosAction,
  getNiveis: getNiveisAction,
  getPermissoes: getPermissoesAction,
})(Vendedores);
