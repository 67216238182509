import apiService from '../../shared/services/apiService';

export const singinRequest = async ({ user, senha }) => {
  const {
    data: { results },
  } = await apiService.post('/login/vendedor/', {
    identificacao: user,
    senha,
  });

  if (!results.response) {
    throw new Error(results);
  }

  return {
    results,
  };
};

export const getVendedorDados = async ({ idVendedor }) => {
  const {
    data: { results },
  } = await apiService.get(`/vendedores/${idVendedor}`);

  return {
    results,
  };
};
