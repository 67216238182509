import { makeStyles } from '@material-ui/core';
import { CloseIcon, ExtendedWarrantyIcon, VoltageIcon } from '../../../../assets/algolia';
import { GuaranteeIcon } from '../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  closeIcon: {
    background: `url(${CloseIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
    width: 24,
    height: 24,
    border: 0,

    [breakpoints.down('sm')]: {
      position: 'absolute',
      right: 10,
      top: 15,
    },
  },
  dialogPaper: {
    maxWidth: 496,
    minHeight: 306,

    [breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 24,
    gap: 30,

    [breakpoints.down('sm')]: {
      gap: 0,
      flexDirection: 'column',
      padding: '30px 45px 30px 30px',
    },
  },
  title: {
    padding: 0,

    '& h2': {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 600,
      color: '#000000',

      [breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
  },
  voltageContainer: {
    display: 'flex',
    gap: 15,
    justifyContent: 'center',
    padding: '0 0 5px 0',
  },
  voltageInputContainer: {
    boxShadow: '0px 4px 8px 0px #0000001A',
    minHeight: 56,
    minWidth: 168,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 15,
    border: '2px solid #CFCFD4',
    borderRadius: 8,
    padding: '20px 30px',
    textAlign: 'center',

    '&.selected': {
      border: '2px solid #FE5000',
      boxShadow: 'none',
    },

    '& input[type=radio]': {
      appearance: 'none',
      minWidth: 14,
      height: 14,
      border: '1px solid #CFCFD4',
      borderRadius: '100%',

      '&:checked': {
        appearance: 'none',
        border: '1px solid #FE5000',

        '&:after': {
          background: '#FE5000',
          content: '""',
          width: 8,
          height: 8,
          display: 'block',
          position: 'relative',
          borderRadius: '100%',
          left: 2,
          top: 2,
        },
      },
    },
  },
  voltageIcon: {
    background: `url(${VoltageIcon})`,
    minWidth: 12,
    height: 20,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  extendedWarrantyIcon: {
    background: `url(${ExtendedWarrantyIcon})`,
    width: 129,
    height: 48,
    display: 'block',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    margin: 8,
  },
  optionsWarranty: {
    fontSize: 12,
    lineHeight: '19px',
    color: '#7B7F82',
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    margin: 0,

    '& li': {
      display: 'flex',
      gap: 2,

      '&.selected': {
        color: '#FE5000 !important',
      },
    },

    '& input[type=radio]': {
      appearance: 'none',
      width: 14,
      height: 14,
      border: '1px solid #CFCFD4',
      borderRadius: '100%',

      '&:checked': {
        appearance: 'none',
        border: '1px solid #FE5000',

        '&:after': {
          background: '#FE5000',
          content: '""',
          width: 8,
          height: 8,
          display: 'block',
          position: 'relative',
          borderRadius: '100%',
          left: 2,
          top: 2,
        },
      },
    },
  },
  rootActions: {
    margin: '0 24px 24px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px 0',
  },
  addCartBtn: {
    width: '100%',
    background: '#21AD34',
    color: '#FFFFFF',
    height: 48,
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'math-auto',
    lineHeight: '10px',

    '& .MuiButton-label': {
      gap: 6,
    },

    '&:hover': {
      background: '#008000',
    },

    '&:disabled': {
      background: '#CFCFD4',
      color: '#7B7F82',
    },
  },
  rootContent: {
    padding: '0 24px 8px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundcolor: '#909599',
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#909599',
    },

    '& .MuiTypography-body1': {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 2,
      color: '#242424',
    },

    '& .MuiTypography-h2': {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: 3,
    },

    '& span' :{
      fontWeight: 600,
      color: '#FE5000',
    },

    '& a' :{
      fontSize: 12,
      fontWeight: 600,
      color: '#FE5000'
    }
  },
  accordion: {
    boxShadow: 'none',
    border: 'none',
    background: 'none',
    margin: 0,
    padding: 0,
  },
  accordionSummary: {
    padding: 0,
    margin: 0,
    height: 50,
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    }
  },
  accordionDetails: {
    padding: 0,
    margin: 0,
  },
  btnMoreInfo: {
    '& .MuiButton-label': {
      gap: 4,
      lineHeight: 'normal',
      color: '#2296F3',
      fontSize: 12,
      textTransform: 'none',
      fontWeight: 600,
    }
  },
  iconGuarantee: {
    height: 48,
    width: 170,
    background: `url(${GuaranteeIcon})`,
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    backgroundSize: 170
  },
}));

export default useStyles;
