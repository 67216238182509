import types from './types';
import { setUser, clearUser, setUserFiliais } from '../../shared/services/auth';
import {clearCanalDeVendas, clearFilial, clearFilialVendedor, clearUnidadeDeNegocio, setCanalDeVendas, setFilialVendedor, setUnidadeDeNegocio} from '../../shared/services/app';
import apiService from '../../shared/services/apiService';
import rolePermition from '../../shared/utils/rolePermition';
import { AES } from 'crypto-js';
import { setFilialAction } from '../../main/actions';
import { singinRequest } from './repository';
import LoginTypes from "./types";
const { REACT_APP_SECRET_ENCRYPT_KEY } = process.env;

const hasEmailAction = ({ identificacao }) => async (dispatch, getState) => {
  dispatch({ type: types.CHECK_LOGIN_REQUEST });
  try {
    const {
      data: {
        results,
      },
    } = await apiService.post('/login/contas?tipoUsuario=V', {
      credencial: identificacao,
    });

    if (!results.response) {
      throw results;
    }

    dispatch({
      type: types.CHECK_LOGIN_SUCCESS,
      payload: {
        user: {
          ...results.value[0],
          identificacao,
        },
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
    dispatch({ type: types.CHECK_LOGIN_FAILURE });
  }
};

const setPageAction = (page) => ({
  type: types.SET_PAGE,
  payload: { page },
});

const singinAction = ({ user, senha }) => async (dispatch, getState) => {
  dispatch({ type: types.SINGIN_REQUEST });
  if (!user || !senha) throw new Error('Informe identificação e senha!');

  singinRequest({ user, senha })
    .then(async (response) => {
      const { results } = response;

      if (!rolePermition(results.value?.idCargo)) {
        clearUser();
        const { snack } = getState().main.app;
        snack.enqueueSnackbar("Acesso não permitido", { variant: 'error' });
        dispatch({ type: types.SINGIN_FAILURE });
      } else {
        dispatch({
          type: types.SINGIN_SUCCESS,
          payload: {
            user: {
              ...results.value,
              identificacao: user,
              senha: AES.encrypt(senha, REACT_APP_SECRET_ENCRYPT_KEY).toString(),
              nome: user,
            },
          },
        });
        const vendedor = results.value;
        const { idCanalDeVendas, idUnidadeDeNegocio, idFilial } = vendedor;

        let filial = null;
        if (idUnidadeDeNegocio === 1) {
          // idCajamar = 2
          filial = vendedor.filiais.find((f) => f.idFilial === 2);
        } else {
          filial = vendedor.filiais.find((f) => f.idFilial === idFilial);
        }

        setUser(vendedor);

        dispatch(setFilialAction(filial));
        dispatch(singinUserFiliaisAction(filial, vendedor));

        setFilialVendedor(idFilial);
        setUnidadeDeNegocio(idUnidadeDeNegocio);
        setCanalDeVendas(idCanalDeVendas);
      }
    })
    .catch((e) => {
      dispatch({ type: types.SINGIN_FAILURE });
      const { snack } = getState().main.app;
      snack.enqueueSnackbar(e.message || e, { variant: 'error' });
    });
};

const singinUserFiliaisAction = ( filial, user )=> async (dispatch, getState) => {
  dispatch({ type: types.SINGIN_REQUEST });
  try {
  setUserFiliais(filial, user);
} catch (e) {
  const { snack } = getState().main.app;
  snack.enqueueSnackbar(e.message || e, { variant: 'error' });
}
};

const logoutAction = () => (dispatch) => {
  clearFilial();
  clearUser();
  clearCanalDeVendas();
  clearFilialVendedor();
  clearUnidadeDeNegocio();
  dispatch({ type: LoginTypes.LOGOUT});
};

const logoutCatalogoAction = () => async (dispatch, getState) => {
  try {
    const { REACT_APP_HOST_CATALAGO } = process.env;
    window.location.href = `${REACT_APP_HOST_CATALAGO}/logout`;
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

export {
  singinAction,
  hasEmailAction,
  setPageAction,
  logoutAction,
  singinUserFiliaisAction,
  logoutCatalogoAction,
};
