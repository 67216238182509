import apiService from '../../shared/services/apiService';

export const getSolicitacoesCredito = async ({
  dataInicio,
  dataFim,
  page,
  rpp,
  orcamento,
  statusPedido,
  idVendedor,
  idCanalVendas,
  idUnidadeNegocio,
  status,
  ordenacao,
  campoOrdenacao,
}) => {
  const {
    data: { results, meta },
  } = await apiService.get(`solicitacoes-credito?dataInicio=${dataInicio}&dataFim=${dataFim}&page=${page}&rpp=${rpp}&idVendedor=${idVendedor}&idCanalVendas=${idCanalVendas}&idUnidadeNegocio=${idUnidadeNegocio}&statusSolicitacaoCredito=${status}&statusPedido=${statusPedido}&checkoutId=${orcamento || ''}&ordenacao=${ordenacao}&campoOrdenacao=${campoOrdenacao}`);  

  return {
    results,
    meta
  };
};

export const getVendedoresSimplificado = async (idCanalVendas) => {
  const {
    data,
  } = await apiService.get(`vendedores/listar-ativos-simplificado?idCanalVenda=${idCanalVendas}&idCargos=1,2,4,7,8,11,13,15,18`);

  return data;
};