import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import types from './types';

const INITIAL_STATE = {
  filterIsOpened: false,
  checkoutMeta: {
    el: null,
    checkoutId: null,
  },
  relatorio: [],
  detailRelatorio: {},
  loading: false,
  results: [],
};

const startLoading = combineActions(
  types.GET_RELATORIO_PRODUTOS_MC_REQUEST,
  types.GET_RELATORIO_FECHAMENTO_DE_CAIXA_REQUEST,
  types.GET_RELATORIO_VENDAS_MENSAIS_REQUEST,
);

const stopLoading = combineActions(
  types.GET_RELATORIO_PRODUTOS_MC_SUCCESS,
  types.GET_RELATORIO_FECHAMENTO_DE_CAIXA_SUCCESS,
  types.GET_RELATORIO_VENDAS_MENSAIS_SUCCESS,
  types.GET_RELATORIO_PRODUTOS_MC_FAILURE,
  types.GET_RELATORIO_FECHAMENTO_DE_CAIXA_FAILURE,
  types.GET_RELATORIO_VENDAS_MENSAIS_FAILURE,
);

const list = handleActions({
  [startLoading]: (state) => ({
    ...state,
    loading: true,
  }),
  [stopLoading]: (state) => ({
    ...state,
    loading: false,
  }),
  [types.GET_RELATORIO_PRODUTOS_MC_SUCCESS]: (state) => ({
    ...state,
  }),
  [types.GET_RELATORIO_FECHAMENTO_DE_CAIXA_SUCCESS]: (state) => ({
    ...state,
  }),
  [types.GET_RELATORIO_VENDAS_MENSAIS_SUCCESS]: (state) => ({
    ...state,
  }),
  [types.SET_META]: (state, { payload }) => ({
    ...state,
    checkoutMeta: payload,
  }),
  [types.CLEAR_META]: (state) => ({
    ...state,
    checkoutMeta: {
      el: null,
      checkoutId: null,
    },
  }),
  [types.OPEN_FILTER]: (state) => ({
    ...state,
    filterIsOpened: true,
  }),
  [types.CLOSE_FILTER]: (state) => ({
    ...state,
    filterIsOpened: false,
  }),
}, INITIAL_STATE);

export default combineReducers({
  list,
});
