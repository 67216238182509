export default {
  GET_ADDRESSES_CLIENT_REQUEST: '@individualPerson/GET_ADDRESSES_CLIENT_REQUEST',
  GET_ADDRESSES_CLIENT_SUCCESS: '@individualPerson/GET_ADDRESSES_CLIENT_SUCCESS',
  GET_ADDRESSES_CLIENT_FAILURE: '@individualPerson/GET_ADDRESSES_CLIENT_FAILURE',

  GET_ADDRESS_CLIENT_REQUEST: '@individualPerson/GET_ADDRESS_CLIENT_REQUEST',
  GET_ADDRESS_CLIENT_SUCCESS: '@individualPerson/GET_ADDRESS_CLIENT_SUCCESS',
  GET_ADDRESS_CLIENT_FAILURE: '@individualPerson/GET_ADDRESS_CLIENT_FAILURE',

  PUT_ADDRESS_CLIENT_REQUEST: '@individualPerson/PUT_ADDRESS_CLIENT_REQUEST',
  PUT_ADDRESS_CLIENT_SUCCESS: '@individualPerson/PUT_ADDRESS_CLIENT_SUCCESS',
  PUT_ADDRESS_CLIENT_FAILURE: '@individualPerson/PUT_ADDRESS_CLIENT_FAILURE',

  POST_ADDRESS_CLIENT_REQUEST: '@individualPerson/POST_ADDRESS_CLIENT_REQUEST',
  POST_ADDRESS_CLIENT_SUCCESS: '@individualPerson/POST_ADDRESS_CLIENT_SUCCESS',
  POST_ADDRESS_CLIENT_FAILURE: '@individualPerson/POST_ADDRESS_CLIENT_FAILURE',

  PUT_MAIN_ADDRESS_REQUEST: '@individualPerson/PUT_MAIN_ADDRESS_REQUEST',
  PUT_MAIN_ADDRESS_SUCCESS: '@individualPerson/PUT_MAIN_ADDRESS_SUCCESS',
  PUT_MAIN_ADDRESS_FAILURE: '@individualPerson/PUT_MAIN_ADDRESS_FAILURE',

  PUT_INACTIVE_ADDRESS_REQUEST: '@individualPerson/PUT_INACTIVE_ADDRESS_REQUEST',
  PUT_INACTIVE_ADDRESS_SUCCESS: '@individualPerson/PUT_INACTIVE_ADDRESS_SUCCESS',
  PUT_INACTIVE_ADDRESS_FAILURE: '@individualPerson/PUT_INACTIVE_ADDRESS_FAILURE',
};
