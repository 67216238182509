import { handleActions, combineActions } from 'redux-actions';
import discountListTypes from './types';

const INITIAL_STATE = {
  discounts: [],
  totalPage: 1,
  currentPage: 1,
  loading: true,
  loadings: {
    pdf: false,
  },
  opened: false,
  action: null,
  selected: null,
  saving: false,
  count: 0,
  rpp: 10,
};

const stopLoadingPDF = combineActions(
  discountListTypes.PDF_ORCAMENTOS_SUCCESS,
  discountListTypes.PDF_ORCAMENTOS_FAILURE,
);

const list = handleActions({
  [discountListTypes.GET_DISCOUNT_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [discountListTypes.GET_DISCOUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    discounts: payload.discounts,
    totalPage: payload.totalPage,
    currentPage: payload.currentPage,
    count: payload.count,
    rpp: payload.rpp,
  }),
  [discountListTypes.GET_DISCOUNT_FAIL]: (state) => ({
    ...state,
    loading: false,
  }),
  [discountListTypes.UPDATE_DISCOUNT_REQUEST]: (state) => ({
    ...state,
    saving: true,
  }),
  [discountListTypes.UPDATE_DISCOUNT_SUCCESS]: (state) => ({
    ...state,
    saving: false,
    opened: false,
  }),
  [discountListTypes.UPDATE_DISCOUNT_FAIL]: (state) => ({
    ...state,
    saving: false,
  }),
  [discountListTypes.PDF_ORCAMENTOS_REQUEST]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      pdf: true,
    },
  }),
  [stopLoadingPDF]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      pdf: false,
    },
  }),
  [discountListTypes.OPEN]: (state, { payload }) => ({
    ...state,
    opened: true,
    action: payload.action,
    selected: payload.selected,
  }),
  [discountListTypes.CLOSE]: (state) => ({
    ...state,
    opened: false,
  }),
}, INITIAL_STATE);

export default list;
