import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  tableHead: {
    height: 64,
  },
  tableRow: {
    backgroundColor: '#FFFFFF !important',
  },
  tableBody: {
    backgroundColor: '#FFFFFF',
  },
  tableCellHead: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 14,
    color: '#0F1012',
  },
  checkoutTable: {
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
