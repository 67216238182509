import { Hidden, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textsSilver: {
    color: '#888E9D',
    fontSize: 14,
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  gridTop: {
    height: 70,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonLigar: {
    backgroundColor: '#FFF0ED',
    height: 48,
    width: 258,
    marginRight: 24,
    maxWidth: '100%',
    color: '#FA4616',
    fontSize: 13,
    fontWeight: 600,
    border: '1px #FA4616 solid',
    '&.MuiButton-root:hover': {
      backgroundColor: '#FA4616 !important',
      color: '#FFF !important',
    },
  },
  buttonLigarSucess: {
    backgroundColor: '#FA4616',
    height: 48,
    width: 258,
    marginRight: 24,
    maxWidth: '100%',
    color: '#ffff',
    fontSize: 13,
    fontWeight: 600,
    '&.MuiButton-root:hover': {
      backgroundColor: '#FA4616 !important',
      color: '#FFF !important',
    },
  },
  buttonCopyPhone: {
    backgroundColor: '#FFF0ED',
    height: 48,
    width: 48,
    marginRight: 40,
    maxWidth: 48,
    color: '#FA4616',
    fontSize: 13,
    border: '1px #FA4616 solid',
    '&.MuiButton-root:hover': {
      backgroundColor: '#FA4616 !important',
      color: '#FFF !important',
    },
  },
  textTopTitle: {
    fontSize: 12,
    color: '#888E9D',
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  buttonsBottom: {
    display: 'flex',
    width: '100%',
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 20,
    paddingRight: 40,
  },
  buttonDesistiu: {
    height: 36,
    width: 205,
    fontSize: 14,
    color: '#F4282D',
    backgroundColor: 'rgb(0, 0, 0, 0)',
    border: '1px #F4282D solid',
    fontFamily: ['Poppins', 'sans serif'].join(','),
    '&.MuiButton-root:hover': {
      backgroundColor: '#F4282D',
      color: '#fff',
    },
  },
  buttonSemContato: {
    height: 36,
    width: 205,
    fontSize: 14,
    color: '#1D1F2A',
    backgroundColor: 'rgb(0, 0, 0, 0)',
    border: '1px #1D1F2A solid',
    fontFamily: ['Poppins', 'sans serif'].join(','),
    '&.MuiButton-root:hover': {
      backgroundColor: '#1D1F2A',
      color: '#fff',
    },
  },
  buttonWhatsApp: {
    height: 36,
    width: 205,
    fontSize: 14,
    color: '#25D366',
    backgroundColor: 'rgb(0, 0, 0, 0)',
    border: '1px #25D366 solid',
    fontFamily: ['Poppins', 'sans serif'].join(','),
    '&.MuiButton-root:hover': {
      backgroundColor: '#25D366',
      color: '#fff',
    },
  },
  buttonAdicionarAoCarrinho: {
    height: 36,
    width: 205,
    fontSize: 14,
    color: '#FFF',
    backgroundColor: '#21AD34',
    fontFamily: ['Poppins', 'sans serif'].join(','),
    '&.MuiButton-root:hover': {
      backgroundColor: '#18C02F',
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#F3F4F6',
    height: 58,
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'none',
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  gridDialog: {
    margin: 20,
  },
  typographDialog: {
    marginBottom: 5,
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  radioRecusa: {
    color: '#FA4616 !important',
  },
  divButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    paddingRight: 20,
    marginBottom: 20,
  },
  buttonSalvar: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 140,
    height: 40,
    borderRadius: 4,
    backgroundColor: '#FF5225',
    color: '#ffff',
    "&:hover": {
      color: 'black',
    }
  },
  buttonExcluir: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 140,
    height: 40,
    borderRadius: 4,
    backgroundColor: '#FF5225',
    color: '#ffff',
    "&:hover": {
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #F4282D',
    }
  },
  buttonCancelar: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 140,
    height: 40,
    borderRadius: 4,
    backgroundColor: '#E3E3E7',
    color: 'black',
    "&:hover": {
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #E3E3E7',
    }
  },
  gridDialogDataHour: {
    margin: 20,
    height: 30,
  },
  typographDialogDataHour: {
    marginBottom: 10,
    fontSize: 14,
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  inputOters: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 90,
    margin: 10,
  },
  centerTitleModal: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  titleModal: {
    fontSize: 18,
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  alertIcon: {
    color: '#F4282D',
    marginTop: '2.5px',
  },
  alertTitle: {
    color: '#232B3E',
    fontWeight: 600,
    fontSize: '20px',
  },
  alertAppBar: {
    position: 'relative',
    backgroundColor: 'transparent',
    height: 58,
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'none',
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  alertProductImg: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  alertBadge: {
    backgroundColor: '#FA4616',
    width: '18px',
    height: '18px',
    color: '#FFFFFF',
    fontSize: '14px',
    borderRadius: '50%',
    position: 'absolute',
    top: '5px',
    left: '5px',
    textAlign: 'center',
  },
  productImgBadge: {
    position: 'relative'
  },
  productList: {
    backgroundColor: '#F6F6F7',
    borderRadius: '4px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    padding: 10,
    borderBottom: '1px solid #E3E3E7',
  },
  productTitle: {
    color: 'black',
    fontSize: '16px',
    fontWeight: 600,
  },
  productCode: {
    color: '#565D6F',
    fontSize: '12px',
    fontWeight: 600,
  },
  productPoints: {
    color: '#21AD34',
    fontSize: '14px',
    marginTop: '4px',
  },
  gPoints: {
    width: '14px',
    height: '14px',
  },
  productTotal: {
    fontSize: '16px',
    fontWeight: 600,
  },
  centerInline: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  productDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '10px',
  },
  productValue: {
    marginRight: '10px',
  },
  marketplaceList: {
    width: '100%',
    maxHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
  },
  removeIcon: {
    color: 'white',
    height: '25px',
    widht: '25px',
  },
  removeIconBg: {
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    background: 'linear-gradient(45deg, #FF1E1A 14.65%, #F07754 85.35%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  removeTitle: {
    fontSize: '20px',
    fontWeight: 600,
  },
  removeDescription: {
    fontSize: '16px',
    fontWeight: 400,
    maxWidth: '70%',
  },
  gridDialogRemoved: {
    margin: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  customerGrid: {
    rowGap: 12,
  },
  customerDivider: {
    marginTop: 12,
    marginBottom: 12,
  },
  customerPaper: {
    marginTop: 12,
    marginBottom: 12,
    padding: 16,
  },
  customerTitle: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 600,
  }
}));

export default useStyles;
