const PAYMENT_OPTIONS = [
  {
    code: 'B',
    name: 'Boleto',
  },
  //{
  //  code: 'CC',
  //  name: 'Cartão',
  //},
  {
    code: 'PX',
    name: 'Pix',
  },
  // {
  //   code: 'LP',
  //   name: 'LDM PRO',
  // },
  // {
  //   code: 'ML',
  //   name: 'Mercado Livre',
  // },
  {
    code: 'BP',
    name: 'Boleto à Prazo',
  },
  {
    code: 'DN',
    name: 'Dinheiro - Pagamento Caixa',
  },
  {
    code: 'DC',
    name: 'Depósito em Conta',
  },
  {
    code: 'CS',
    name: 'Consórcio',
  },
  {
    code: 'FN',
    name: 'Financiamento',
  },
  // {
  //   code: 'B2',
  //   name: 'MarketPlace B2W',
  // },
  // {
  //   code: 'AZ',
  //   name: 'MarketPlace Amazon',
  // },
  // {
  //   code: 'MZ',
  //   name: 'Magalu',
  // },
];

export default PAYMENT_OPTIONS;
