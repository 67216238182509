import moment from 'moment';

const cardExpirationValidate = (value) => {
  if (value.length !== 7) return 'Digite uma data valida (MM/YYYY)';

  const [month] = value.split('/');
  if (+month > 12 || +month < 1) return 'Digite um mês valido (1 a 12)';

  const creditDate = moment(value, 'MM/YYYY');
  const today = moment(moment().format('YYYY-MM'));

  if (!creditDate) return 'Digite uma data valida (MM/YYYY)';
  if (!today.isSame(creditDate) && today.isAfter(creditDate)) return 'Esse cartão expirou!';

  return null;
};

export default cardExpirationValidate;
