import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cardGrid: {
    display: 'flex',
    justifyContent: 'center',
    // marginTop: 48,
  },
  cardContent: {
    padding: 0,
  },
}));

export default useStyles;
