import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formControl: {
    display: 'flex',
  },
  checkbox: {
    width: 16,
    height: 16,
    border: '1px solid #6C757D',
    borderRadius: 3,
  },
  checked: {
    width: 16,
    height: 16,
    background: '#FE5000',
    borderRadius: 3,
    '&:before': {
      content: "''",
      border: '1px solid #FFFFFF',
      borderWidth: '1px 1px 0 0',
      height: 4,
      width: 8,
      position: 'absolute',
      transform: 'rotate(135deg)',
      left: 13,
      top: 14,
    },
  },
  menuItem: {
    padding: 0,
    '&.Mui-selected': {
      background: 'none !important',
      '&:hover': {
        background: '#0000000A !important',
      },
    },
  },
  itemBtn: {
    display: 'flex',
    width: '100%',
    padding: '0 16px 0 16px',
    alignItems: 'center',
    textAlign: 'left',
    border: 0,
    background: 'none',
  },
  listItem: {
    color: '#6C757D',
  },
}));

export default useStyles;
