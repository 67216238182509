import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Dialog,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { putCargosAction } from '../../../actions';
import useStyles from '../../../styles';

const DialogRefreshOffice = ({
  dialog, handleToggle, putCargos, page, cargosFilter,
}) => {
  const classes = useStyles();
  const [nameCargo, setNameCargo] = useState('');

  const renderPutCargos = () => {
    handleToggle();
    putCargos({
      id: cargosFilter.id,
      nome: nameCargo === '' ? cargosFilter.nome : nameCargo,
      page,
    });
  };

  return (
    <Dialog open={dialog} onClose={handleToggle} maxWidth="xs" fullWidth>
      <Box position="relative" style={{ backgroundColor: '#F3F4F6' }}>
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={handleToggle}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogTitle>
          Atualizar Cargo
        </DialogTitle>
      </Box>
      <div style={{ padding: 20 }}>
        <Grid item spacing={2} container xs={12}>
          <Grid item xs={7}>
            <Typography>NOME</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              placeholder={`${cargosFilter.nome ? cargosFilter.nome : '...'}`}
              value={nameCargo}
              onChange={(e) => setNameCargo(e.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            <Button
              className={classes.buttonCriar}
              onClick={renderPutCargos}
              disabled={nameCargo !== '' && nameCargo.length < 5}
            >
              Atualizar
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

DialogRefreshOffice.propTypes = {
  dialog: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  putCargos: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  cargosFilter: PropTypes.instanceOf(Object).isRequired,
};
const mapState = (state) => state.main.sales;

export default connect(mapState, {
  putCargos: putCargosAction,
})(DialogRefreshOffice);
