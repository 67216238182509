import { handleActions, combineActions } from 'redux-actions';
import { Types } from './index';
const INITIAL_STATE = {
  loading: {
    zipcode: false,
    customer: false
  }
};

const stopLoadingZipcode = combineActions(
  Types.GET_ZIPCODE_SUCCESS,
  Types.GET_ZIPCODE_FAILURE,
);

const stopLoadingCustomer = combineActions(
  Types.REGISTER_CUSTOMER_SUCCESS,
  Types.REGISTER_CUSTOMER_FAILURE,
);



export default handleActions({
  [stopLoadingZipcode]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      zipcode: false,
    },
  }),
  [stopLoadingCustomer]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      customer: false,
    },
  }),

  [Types.GET_ZIPCODE_REQUEST]: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        zipcode: true,
      },
    };
  },
  [Types.REGISTER_CUSTOMER_REQUEST]: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        customer: true,
      },
    };
  },
}, INITIAL_STATE);
