import { handleActions, combineActions } from 'redux-actions';
import types from './types';

const INITIAL_STATE = {
  funcionalidades: [],
  loading: false,
};

const startLoading = combineActions(
  types.GET_FUNCIONALIDADES_REQUEST,
  types.PUT_FUNCIONALIDADES_REQUEST,
);

const stopLoading = combineActions(
  types.GET_FUNCIONALIDADES_SUCCESS,
  types.GET_FUNCIONALIDADES_FAILURE,
  types.PUT_FUNCIONALIDADES_SUCCESS,
  types.PUT_FUNCIONALIDADES_FAILURE,
);

const devAcess = handleActions({
  [startLoading]: (state) => ({
    ...state,
    loading: true,
  }),
  [stopLoading]: (state) => ({
    ...state,
    loading: false,
  }),

  [types.GET_FUNCIONALIDADES_SUCCESS]: (state, { payload }) => ({
    ...state,
    funcionalidades: payload.results,
  }),
}, INITIAL_STATE);

export default devAcess;
