import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as PropTypes from 'prop-types';

const TitleInfo = ({ title }) => (
  <ListItem>
    <ListItemText
      primary={title}
      primaryTypographyProps={{
        style: {
          fontWeight: 500,
        },
      }}
    />
  </ListItem>
);

TitleInfo.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleInfo;
