import React, { useEffect } from 'react';
import {
  Box, Button, Grid, IconButton, LinearProgress,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import {
  change,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import * as PropTypes from 'prop-types';
import { Search } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import {
  cellphone,
  cnpjValid,
  email,
  maxLenght100,
  maxLenght9,
  maxLength14,
  minLength10,
  minLength3,
  minLength6,
  minLength8,
  required,
} from '../../../../../../shared/fields/validate';
import { InputFieldForm } from '../../../../../authClient/_components/forms';
import {
  InputCEPRender,
  InputCnpjRender,
  InputPhoneRender,
} from '../../../../../../shared/fields';
import { getZipcodeDataAction } from '../../../../../authClient/register/actions';
import {
  AlertMessage,
  CheckboxForm,
  SelectForm,
} from '../../../../../authClient/register/_components';
import {
  getAddressClientPFAction,
  getAddressClientPJAction,
  putAddressClientPFAction,
  putAddressClientPJAction,
} from '../../actions';
import { goBack } from '../../../../../../history';

const AddressEditForm = ({
  handleSubmit,
  getZipcodeData,
  getAddressClientPF,
  putAddressClientPF,
  getAddressClientPJ,
  putAddressClientPJ,
  addressSelector,
  loading,
  address,
  client,
}) => {
  const {
    semNumero,
    bitGenerico,
    tipoContribuinte,
    isSuframa,
    numero,
    inscricaoEstadual,
    inscricaoSuframa,
  } = addressSelector;
  const isCorporate = client && client.tipoPessoa === 'J';
  const showIE = tipoContribuinte !== 0 && [1, 6, 8].includes(tipoContribuinte);
  const dispatch = useDispatch();
  const { addressId } = useParams();

  const {
    addressGrid,
    editFormGrid,
    numberGrid,
    noNumberGrid,
    numberMainGrid,
    btnBox,
    cepInfoGrid,
    info,
    corporateAddressGrid,
    suframaMainGrid,
    suframaGrid,
  } = useStyles();

  const updateAddress = async () => {
    if (isCorporate) {
      await putAddressClientPJ();
    } else {
      await putAddressClientPF();
    }
  };

  const searchCEP = () => {
    window.open('http://www.buscacep.correios.com.br/', '_blank');
  };

  const handleCepRequest = () => {
    const { cep } = addressSelector;
    if (loading.zipcode) return;
    if (cep.length < 8) return;

    getZipcodeData(cep).then((res) => {
      dispatch(change('client/address/edit', 'bitGenerico', res.flagcepgenerico));
      dispatch(change('client/address/edit', 'logradouro', res.logradouro));
      dispatch(change('client/address/edit', 'bairro', res.bairro));
      dispatch(change('client/address/edit', 'cidade', res.localidade));
      dispatch(change('client/address/edit', `${isCorporate ? 'estado' : 'uf'}`, res.uf));
    }).catch(() => {
      dispatch(change('client/address/edit', 'bitGenerico', false));
      dispatch(change('client/address/edit', 'rua', ''));
      dispatch(change('client/address/edit', 'bairro', ''));
      dispatch(change('client/address/edit', 'cidade', ''));
      dispatch(change('client/address/edit', `${isCorporate ? 'estado' : 'uf'}`, ''));

    });
  };

  useEffect(() => {
    if (isCorporate) {
      getAddressClientPJ(addressId);
    } else {
      getAddressClientPF(addressId);
    }
  }, []);

  useEffect(() => {
    if (address) {
      dispatch(change('client/address/edit', 'cep', address.cep));
      dispatch(change('client/address/edit', 'logradouro', address.logradouro));
      dispatch(change('client/address/edit', 'numero', address.numero));
      dispatch(change('client/address/edit', 'bairro', address.bairro));
      dispatch(change('client/address/edit', 'cidade', address.cidade));
      dispatch(change('client/address/edit', 'complemento', address.complemento));
      dispatch(change('client/address/edit', 'informacoesAdicionais', address.informacoesAdicionais));
      dispatch(change('client/address/edit', 'bitPrincipal', address.bitPrincipal));
      dispatch(change('client/address/edit', 'bitInativo', address.bitInativo));
      dispatch(change('client/address/edit', 'codCliente', address.codCliente));
      dispatch(change('client/address/edit', 'semNumero', address.numero === 'S/N'));
      dispatch(change('client/address/edit', 'telefoneContato', address.telefoneContato));

      if (isCorporate) {
        dispatch(change('client/address/edit', 'estado', address.estado));
        dispatch(change('client/address/edit', 'razaoSocial', address.razaoSocial));
        dispatch(change('client/address/edit', 'idFilial', address.idFilial));
        dispatch(change('client/address/edit', 'cnpj', address.cnpj));
        dispatch(change('client/address/edit', 'tipoContribuinte', address.tipoContribuinte));
        dispatch(change('client/address/edit', 'inscricaoEstadual', address.inscricaoEstadual));
        dispatch(change('client/address/edit', 'inscricaoSuframa', address.inscricaoSuframa));
        dispatch(change('client/address/edit', 'isSuframa', !!address.inscricaoSuframa));
        dispatch(change('client/address/edit', 'emailNf', address.emailNf));
      } else {
        dispatch(change('client/address/edit', 'uf', address.uf));
        dispatch(change('client/address/edit', 'responsavel', address.responsavel));
        dispatch(change('client/address/edit', 'id', address.id));
      }
    }
  }, [address]);

  useEffect(() => {
    dispatch(change('client/address/edit', 'numero',
      // eslint-disable-next-line no-nested-ternary
      semNumero ? 'S/N'
        : (numero === 'S/N' ? '' : numero)));
  }, [semNumero]);

  useEffect(() => {
    dispatch(change('client/address/edit', 'inscricaoEstadual',
      showIE ? inscricaoEstadual : ''));
  }, [showIE]);

  useEffect(() => {
    dispatch(change('client/address/edit', 'inscricaoSuframa',
      isSuframa ? inscricaoSuframa : ''));
  }, [isSuframa]);

  return (
    <Grid className={editFormGrid}>
      <form onSubmit={handleSubmit(updateAddress)}>
        <Grid container spacing={2}>
          {
            isCorporate ? (
              <>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <InputFieldForm
                    name="cnpj"
                    component={InputCnpjRender}
                    placeholder="ex. XX.XXX.XXX/0001-XX"
                    label="CNPJ"
                    required
                    disabled={address && address.cnpj}
                    validate={[required, cnpjValid]}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <InputFieldForm
                    name="razaoSocial"
                    minLength={6}
                    maxLength={100}
                    label="Razão Social"
                    validate={[required, minLength6, maxLenght100]}
                    required
                  />
                </Grid>

                <Grid item xs={12} className={corporateAddressGrid}>
                  <Grid item sm={6} xs={12}>
                    <SelectForm
                      name="tipoContribuinte"
                      label="Tipo de Contribuinte"
                      required
                      validate={[required]}
                      options={[
                        {
                          id: 1,
                          label: 'Contribuinte',
                        },
                        {
                          id: 5,
                          label: 'Não Contribuinte',
                        },
                        {
                          id: 8,
                          label: 'Não contribuinte com I.E',
                        },
                        {
                          id: 6,
                          label: 'Órgão Público',
                        },
                      ]}
                    />
                  </Grid>

                  {showIE && (
                    <Grid item sm={6} xs={12}>
                      <InputFieldForm
                        name="inscricaoEstadual"
                        label="Inscrição Estadual"
                        maxLength={14}
                        validate={[required, maxLength14]}
                        required
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputFieldForm
                    name="emailNf"
                    maxLength={60}
                    label="E-mail para Nota Fiscal"
                    validate={[email]}
                  />
                </Grid>

                <Grid xs={12} className={suframaMainGrid}>
                  <Grid
                    item
                    sm={6}
                    className={suframaGrid}
                    style={{ marginTop: isSuframa ? 18 : 0 }}
                  >
                    <Box height="100%">
                      <CheckboxForm
                        name="isSuframa"
                        label="Tem inscrição no SUFRAMA"
                      />
                    </Box>
                  </Grid>

                  { isSuframa && (
                    <Grid item sm={6}>
                      <InputFieldForm
                        name="inscricaoSuframa"
                        maxLength={9}
                        label="Número SUFRAMA"
                        validate={[required, maxLenght9]}
                        required
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid item sm={6} xs={12}>
                  <InputFieldForm
                    name="telefoneContato"
                    component={InputPhoneRender}
                    label="Telefone de Contato"
                    validate={[required, cellphone]}
                    required
                  />
                  <span className={info}>
                    Este número será usado apenas para auxiliar a entrega
                  </span>
                </Grid>

                <Grid item xs={12}>
                  <AlertMessage icon="warning">
                    {`Por ser empresa, o endereço de envio e faturamento
                  deve ser o mesmo. Validamos os dados junto à SEFAZ.`}
                  </AlertMessage>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={6} xs={12}>
                  <InputFieldForm
                    name="responsavel"
                    label="Nome Completo"
                    minLength={3}
                    maxLength={50}
                    validate={[required, minLength3]}
                    required
                  />
                  <span className={info}>Como aparece no RG</span>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <InputFieldForm
                    name="telefoneContato"
                    component={InputPhoneRender}
                    label="Telefone de Contato"
                    validate={[required, cellphone]}
                    required
                  />
                  <span className={info}>
                    Este número será usado apenas para auxiliar a entrega
                  </span>
                </Grid>
              </>
            )
          }

          <Grid item sm={6} xs={12}>
            <InputFieldForm
              name="cep"
              label="CEP"
              component={InputCEPRender}
              placeholder="ex. 12345-789"
              onBlur={handleCepRequest}
              endAdornment={(
                <IconButton
                  style={{ marginTop: 10 }}
                  onClick={handleCepRequest}
                >
                  <Search />
                </IconButton>
                )}
              validate={[required, minLength8]}
              required
            />
            <LinearProgress hidden={!loading.zipcode} />
          </Grid>
          <Grid item sm={6} xs={12} className={cepInfoGrid}>
            <Button
              onClick={searchCEP}
            >
              Não sei o meu CEP
            </Button>
          </Grid>

          <Grid item xs={12} className={addressGrid}>
            <Grid item sm={6} xs={12}>
              <InputFieldForm
                name="logradouro"
                label="Rua / Avenida"
                maxLength={60}
                validate={[required]}
                required
                disabled={loading.zipcode || !bitGenerico}
              />
            </Grid>

            <Grid sm={6} xs={12} className={numberMainGrid}>
              <Grid item sm={6} className={numberGrid}>
                <InputFieldForm
                  name="numero"
                  maxLength={6}
                  label="Número"
                  validate={!semNumero && [required]}
                  required
                  disabled={loading.zipcode || semNumero}
                />
              </Grid>

              <Grid item sm={6} className={noNumberGrid}>
                <Box marginLeft={2} height="100%" marginTop={2}>
                  <CheckboxForm
                    name="semNumero"
                    label="Sem Número"
                    color="primary"
                    disabled={loading.zipcode}
                    active
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sm={6}
          >
            <InputFieldForm
              name="complemento"
              label="Complemento"
              maxLength={50}
              disabled={loading.zipcode}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputFieldForm
              name="bairro"
              label="Bairro"
              validate={[required]}
              maxLength={50}
              required
              disabled={loading.zipcode || !bitGenerico}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputFieldForm
              name="cidade"
              label="Cidade"
              validate={[required]}
              maxLength={50}
              required
              disabled
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputFieldForm
              name={isCorporate ? 'estado' : 'uf'}
              label="Estado"
              validate={[required]}
              maxLength={2}
              required
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <InputFieldForm
              style={{ height: 214 }}
              name="informacoesAdicionais"
              label="Informações Adicionais para Entrega"
              required={semNumero}
              disabled={loading.zipcode}
              multiline
              maxLength={200}
              rows={4}
              validate={semNumero && [required, minLength10]}
              placeholder="Descrição da fachada, pontos de referência, informações de segurança etc."
            />
          </Grid>

          <Grid item xs={12}>
            <Box className={btnBox}>
              <Button
                onClick={() => goBack()}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
              >
                Alterar Endereço
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

const addressOptions = [
  'cep',
  'bitGenerico',
  'semNumero',
  'tipoContribuinte',
  'numero',
  'inscricaoEstadual',
  'isSuframa',
  'inscricaoSuframa',
];

const mapState = (state) => {
  const { snack } = state.main.app;
  const { address } = state.main.clientAddresses;
  const { client } = state.authClient;
  const selector = formValueSelector('client/address/edit');
  const addressSelector = selector(state, ...addressOptions);

  return {
    snack,
    client,
    address,
    addressSelector,
    ...state.register,
  };
};

AddressEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  getZipcodeData: PropTypes.func.isRequired,
  getAddressClientPF: PropTypes.func.isRequired,
  putAddressClientPF: PropTypes.func.isRequired,
  addressSelector: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.instanceOf(Object).isRequired,
  address: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  getAddressClientPJ: PropTypes.func.isRequired,
  putAddressClientPJ: PropTypes.func.isRequired,
};

export default connect(mapState, {
  getZipcodeData: getZipcodeDataAction,
  getAddressClientPF: getAddressClientPFAction,
  putAddressClientPF: putAddressClientPFAction,
  getAddressClientPJ: getAddressClientPJAction,
  putAddressClientPJ: putAddressClientPJAction,
})(reduxForm({
  form: 'client/address/edit',
  initialValues: {
    telefoneContato: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: '',
    complemento: '',
    informacoesAdicionais: '',
    responsavel: '',
    semNumero: false,
    bitPrincipal: true,
    bitInativo: false,
    id: '',
    bitGenerico: false,
    codCliente: '',
    tipoContribuinte: null,
    inscricaoEstadual: '',
    estado: '',
    idFilial: '',
    razaoSocial: '',
    cnpj: '',
    emailNf: '',
    isSuframa: false,
    inscricaoSuframa: '',
  },
})(AddressEditForm));
