import React from 'react';
import { useRefinementList, useToggleRefinement } from 'react-instantsearch';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const CheckboxRefinement = ({ checkbox }) => {
  const { attribute, content, isPpl } = checkbox;
  const { value, canRefine, refine } = useToggleRefinement({ attribute });
  const { items: itemsPpl } = useRefinementList({ attribute: 'ppl.hash', showMoreLimit: 1000, limit: 1000 });

  const classes = useStyles();
  const {
    checkboxRefinementBox,
    checkboxRefinementLabel,
    checkboxRefinementTitle,
    checkboxRefinementCount,
  } = classes;

  return (
    <Box className={checkboxRefinementBox}>
      <div className={`${checkboxRefinementLabel} ${!canRefine && ('disabled')}`}>
        <input
          type="checkbox"
          checked={value.isRefined}
          disabled={!canRefine}
          onChange={(event) => {
            refine({ isRefined: !event.target.checked });
          }}
        />
        <span
          className={checkboxRefinementTitle}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <span className={checkboxRefinementCount}>
          {`(${(isPpl ? itemsPpl.length : value.onFacetValue.count) || 0})`}
        </span>
      </div>
    </Box>
  );
};

CheckboxRefinement.propTypes = {
  checkbox: PropTypes.instanceOf(Object).isRequired,
};

export default CheckboxRefinement;
