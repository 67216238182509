import React from 'react';
import { Route } from 'react-router-dom';
import RecoveryListPage from './list/Vendedor/Vendedor';
import RecoveryPage from './list/Page';
import RecoveryRelatorioPage from './list/Relatorio';
import Pedido from './list/Pedido/Pedido';
import CustomSwitch from '../../shared/components/customSwitch';

const RecoveryRouter = () => (
  <CustomSwitch>
    <Route
      path="/recovery/carrinhos/:idList"
      component={RecoveryListPage}
      exact
    />
    <Route
      path="/recovery/:idListHome"
      component={RecoveryPage}
      exact
    />
    <Route
      path="/recovery/relatorio/:idRelatorio"
      component={RecoveryRelatorioPage}
      exact
    />
    <Route
      path="/recovery/pedidos/:idPedido"
      component={Pedido}
      exact
    />
  </CustomSwitch>
);

export default RecoveryRouter;
