/* istanbul ignore file */

import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import * as history from './history';
import { LoginPage } from './auth/login/index';
import AutomaticLogin from './auth/login/automaticLogin/Page';
import { MainPage } from './main';
import { getConfigAction, registerSnackAction } from './main/actions';

import { useClearCacheCtx } from 'react-clear-cache';

const RouterPage = ({ user, filial, registerSnack, getConfig }) => {
	const snack = useSnackbar();
	useEffect(() => {
		registerSnack(snack);
		getConfig(snack);
	}, [registerSnack, snack, getConfig]);

	const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

	useEffect(() => {
		if (!isLatestVersion) {
			emptyCacheStorage();
		}
	}, [isLatestVersion]);

	return (
		<Router history={history}>
			<Switch>
				<Route path="/login" exact component={LoginPage} />
				<Route path="/automatic-login" exact component={AutomaticLogin} />
				{user && user.idVendedor && filial ? (
					<>
						<Route path="/" component={MainPage} />
					</>
				) : (
					<>
						<Redirect to="/login" />
					</>
				)}
			</Switch>
		</Router>
	);
};

const mapStateToProps = state => ({
	...state.auth.login,
	...state.main.app,
});

RouterPage.propTypes = {
	user: PropTypes.instanceOf(Object),
	filial: PropTypes.instanceOf(Object),
	registerSnack: PropTypes.func.isRequired,
	getConfig: PropTypes.func.isRequired,
};

RouterPage.defaultProps = {
	user: null,
	filial: null,
};

export default connect(mapStateToProps, {
	registerSnack: registerSnackAction,
	getConfig: getConfigAction,
})(RouterPage);
