import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box, Dialog, DialogTitle, IconButton,
} from '@material-ui/core';
import { FieldArray, reduxForm } from 'redux-form';
import { Close } from '@material-ui/icons';
import {
  clearCashierPaymentAction, savePaymentCashierDiscountAction,
} from '../actions';
import CashierPayments from './CashierPayments';

const CashierDialog = ({
  clearCashierPayment, cashierPayment, handleSubmit, savePaymentCashierDiscount,
  dispatch,
}) => (
  <Dialog
    open={!!cashierPayment}
    onClose={clearCashierPayment}
    fullWidth
    maxWidth="sm"
  >
    <Box position="relative">
      <Box position="absolute" top={4} right={4}>
        <IconButton onClick={clearCashierPayment}>
          <Close />
        </IconButton>
      </Box>

      {!!cashierPayment && (
      <>
        <DialogTitle>
          Informe os pagamentos a serem feitos no caixa
        </DialogTitle>

        <form onSubmit={handleSubmit(savePaymentCashierDiscount)}>
          <FieldArray name="payments" component={CashierPayments} dispatch={dispatch} />
        </form>
      </>
      )}
    </Box>
  </Dialog>
);

CashierDialog.defaultProps = {
  cashierPayment: null,
};

CashierDialog.propTypes = {
  clearCashierPayment: PropTypes.func.isRequired,
  savePaymentCashierDiscount: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  cashierPayment: PropTypes.instanceOf(Object),
};

const mapState = (state) => state.main.checkout.payment;

export default connect(mapState, {
  clearCashierPayment: clearCashierPaymentAction,
  savePaymentCashierDiscount: savePaymentCashierDiscountAction,
})(reduxForm({
  form: 'checkout/cashier-on-time',
  enableReinitialize: true,
  initialValues: {
    payments: [{ installments: 1 }],
  },
})(CashierDialog));
