import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { pt } from 'date-fns/locale';
import {
  Box,
  Button,
  IconButton,
  Popover,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as PropTypes from 'prop-types';
import {
  getPedidosAction,
  resetFiltersAction,
} from '../../actions';
import { InputRender, InputPhoneRender, Checkmarks } from '../../../../shared/fields';
import useStyles from './styles';
import { getCanalDeVendasFilterAction } from '../../../salesChannel/actions';
import { getUnidadeDeNegocio } from '../../../../shared/services/app';
import { isExclusivePosition, trackingList } from '../../../../shared/utils/ordersHelpers';

const filtersSelector = [
  'codPedido',
  'documento',
  'nome',
  'telefone',
  'email',
  'idEntrega',
  'checkoutId',
  'idCanalVendas',
  'pontosTracking',
];

const FilterOrder = ({
  filterOpened,
  loading,
  handleSubmit,
  getPedidos,
  setFilterDataInicio,
  setFilterDataFim,
  filterDataFim,
  filterDataInicio,
  getCanalDeVendasFilter,
  canaisDeVendaFilter,
  idCargo,
  isTrackingState,
  resetFilters,
  filters,
  filtersStorage,
}) => {
  const classes = useStyles();

  const [dateOpen, setDateOpen] = useState(null);
  const [dateInicio, setDateInicio] = useState('');
  const [dateFim, setDateFim] = useState('');
  const [salesChannelOptions] = useState([]);

  const businessUnit = getUnidadeDeNegocio();
  const { isTracking, setIsTracking } = isTrackingState;

  const d = new Date();
  d.setDate(d.getDate() - 30);
  const [date, setDate] = useState([
    {
      startDate: d,
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleRequest = () => {
    const startDateIsSame = moment(date[0].startDate).format('DD/MM/YYYY') === moment(d).format('DD/MM/YYYY');
    const endDateIsSame = moment(date[0].endDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY');

    const {
      codPedido,
      nome,
      documento,
      telefone,
      email,
      idEntrega,
      checkoutId,
      idCanalVendas,
      pontosTracking,
    } = filters;

    const open = !!(codPedido || nome || documento || telefone
      || email || idEntrega || checkoutId || idCanalVendas
      || (Array.isArray(pontosTracking) && pontosTracking.length) || isTracking
      || !startDateIsSame || !endDateIsSame);

    localStorage.setItem('filtersOrder', JSON.stringify({
      ...filters,
      startDate: date[0].startDate,
      endDate: date[0].endDate,
      isTracking,
      openFilter: open,
    }));

    getPedidos(filterDataInicio, filterDataFim, isTracking, { page: 1 });
  };

  const handleIsTracking = () => setIsTracking(!isTracking);

  const clearFilters = () => {
    localStorage.removeItem('filtersOrder');
    resetFilters('order');
    setDate([{ startDate: d, endDate: new Date(), key: 'selection' }]);
    setIsTracking(false);
  };

  useEffect(() => {
    if (Object.keys(filtersStorage).length) {
      setIsTracking(!!filtersStorage.isTracking);
      setDate([{
        startDate: new Date(filtersStorage.startDate),
        endDate: new Date(filtersStorage.endDate),
        key: 'selection',
      }]);
    }
  }, []);

  useEffect(() => {
    setFilterDataInicio(moment(date[0].startDate).format('DD/MM/YYYY'));
    setFilterDataFim(moment(date[0].endDate).format('DD/MM/YYYY'));
    setDateInicio(moment(date[0].startDate).format('DD/MM/YYYY'));
    setDateFim(moment(date[0].endDate).format('DD/MM/YYYY'));
  }, [date]);

  useEffect(() => {
    if (businessUnit) {
      getCanalDeVendasFilter(businessUnit);
    }
  }, [businessUnit]);

  useEffect(() => {
    if (canaisDeVendaFilter) {
      canaisDeVendaFilter.map(({ idCanalVendas: key, descricao: value }) => {
        salesChannelOptions.push({ key, value });
      });
    }
  }, [canaisDeVendaFilter]);

  return (
    <div
      className={`${classes.wrapper} ${filterOpened ? classes.wrapperOpened : null}`}
    >
      <form onSubmit={handleSubmit(handleRequest)}>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={2}>
              <Field
                name="codPedido"
                component={InputRender}
                label="Pedido"
                format={(v = '') => v.replace(/[\D]/g, '')}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={2}>
              <Field
                name="idEntrega"
                component={InputRender}
                label="Entrega"
                format={(v = '') => v.replace(/[\D]/g, '')}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={2}>
              <Field
                name="checkoutId"
                component={InputRender}
                label="Orçamento"
                format={(v = '') => v.replace(/[\D]/g, '')}
              />
            </Grid>

            <Grid item xs={12} sm={isExclusivePosition(idCargo) ? 4 : 6} lg={2}>
              <Field
                name="documento"
                component={InputRender}
                label="Documento"
                format={(v = '') => v.replace(/[\D]/g, '')}
                maxLength={14}
              />
            </Grid>

            {isExclusivePosition(idCargo) && (
              <Grid item xs={12} sm={isExclusivePosition(idCargo) ? 4 : 6} lg={2}>
                <Field
                  name="idCanalVendas"
                  label="Canal de vendas"
                  component={Checkmarks}
                  options={salesChannelOptions}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={isExclusivePosition(idCargo) ? 4 : 6} lg={2}>
              <Field
                name="pontosTracking"
                label="Tracking"
                component={Checkmarks}
                options={trackingList}
                multiple
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={2}>
              <Field
                name="nome"
                component={InputRender}
                label="Nome"
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
              <Field
                name="telefone"
                component={InputPhoneRender}
                label="Telefone"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Field
                name="email"
                component={InputRender}
                type="email"
                label="Email"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
              <div>
                <div
                  className={classes.inputFakeDate}
                  onClick={(e) => setDateOpen(e.currentTarget)}
                >
                  <div className={classes.dateContainer}>
                    <span>
                      {dateInicio}
                    </span>
                    <span>-</span>
                    <span>{dateFim}</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `| <b>${isTracking ? 'Data Últ. Ponto' : 'Data do Pedido'}</b>`,
                      }}
                    />
                  </div>
                  <ArrowDropDownIcon style={{ marginLeft: 30 }} />
                </div>
                <Popover
                  open={Boolean(dateOpen)}
                  anchorEl={dateOpen}
                  onClose={() => setDateOpen(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box>
                    <Box className={classes.btnBox}>
                      <Button
                        className={`${classes.dateBtn} minWidthStyled ${!isTracking && ('selected')}`}
                        onClick={handleIsTracking}
                      >
                        Data do Pedido
                      </Button>
                      <Button
                        className={`${classes.dateBtn} ${!!isTracking && 'selected'}`}
                        onClick={handleIsTracking}
                      >
                        Data do Último Ponto
                      </Button>
                    </Box>
                    <DateRange
                      months={1}
                      name="date"
                      minDate={addDays(date[0].startDate, -29)}
                      maxDate={addDays(date[0].startDate, 29)}
                      onChange={(item) => setDate([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={date}
                      rangeColors={['#FF5225']}
                      showMonthAndYearPickers={false}
                      locale={pt}
                    />
                  </Box>
                </Popover>
              </div>
            </Grid>

            <Grid item className={classes.btnGrid} xs={12} sm={6} md={3} lg={2} xl={1}>
              <IconButton
                type="submit"
                className={classes.searchButton}
                disabled={loading}
              >
                <SearchIcon />
              </IconButton>
              <Button
                variant="outlined"
                className={classes.clearFilterBtn}
                onClick={clearFilters}
              >
                Limpar filtro
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
};

FilterOrder.defaultProps = {
  loading: false,
};

FilterOrder.propTypes = {
  filterOpened: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  getPedidos: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFilterDataInicio: PropTypes.func.isRequired,
  setFilterDataFim: PropTypes.func.isRequired,
  filterDataFim: PropTypes.string.isRequired,
  filterDataInicio: PropTypes.string.isRequired,
  getCanalDeVendasFilter: PropTypes.func.isRequired,
  canaisDeVendaFilter: PropTypes.arrayOf(Object).isRequired,
  idCargo: PropTypes.number.isRequired,
  isTrackingState: PropTypes.instanceOf(Object).isRequired,
  resetFilters: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  filtersStorage: PropTypes.instanceOf(Object).isRequired,
};

const selector = formValueSelector('checkoutsAndOrders/filter/order');
const mapStateToProps = (state) => {
  const filtersStorage = localStorage.getItem('filtersOrder')
    ? JSON.parse(localStorage.getItem('filtersOrder')) : {};

  const filters = selector(state, ...filtersSelector);

  return ({
    ...state.main.checkoutsAndOrders,
    ...state.main.sales,
    idCargo: state.auth.login.user.idCargo,
    filters,
    filtersStorage,
    initialValues: {
      documento: filtersStorage.documento,
      nome: filtersStorage.nome,
      telefone: filtersStorage.telefone,
      email: filtersStorage.email,
      codPedido: filtersStorage.codPedido,
      idEntrega: filtersStorage.idEntrega,
      checkoutId: filtersStorage.checkoutId,
      idCanalVendas: filtersStorage.idCanalVendas,
      pontosTracking: filtersStorage.pontosTracking,
    },
  });
};

export default connect(mapStateToProps, {
  getPedidos: getPedidosAction,
  getCanalDeVendasFilter: getCanalDeVendasFilterAction,
  resetFilters: resetFiltersAction,
})(reduxForm({
  form: 'checkoutsAndOrders/filter/order',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(FilterOrder));
