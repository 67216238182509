/* eslint-disable no-nested-ternary */
import makeStyles from '@material-ui/core/styles/makeStyles';
import { common } from '@material-ui/core/colors';
import { fade, getLuminance } from '@material-ui/core';

const useStyles = makeStyles(({ palette, transitions }) => ({
  root: {
    backgroundColor: ({ color, variant }) => variant === 'contained'
      && (color || palette.primary.main),
    color: ({ color, variant }) => (variant !== 'contained'
      ? color
      : getLuminance(color) < 0.500 ? common.white : common.black),
    height: 38,
    trasition: transitions.create('all', {
      duration: transitions.duration.shorter,
      easing: transitions.easing.easeInOut,
    }),
    borderColor: ({ color, variant }) => variant === 'outlined' && color,
    '&:hover': {
      backgroundColor: ({ hoverColor, variant, color }) => (variant === 'contained'
        ? (hoverColor || palette.primary.dark)
        : fade(color, 0.2)),
    },
  },
}));

export default useStyles;
