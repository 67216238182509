import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, Typography, Button, DialogActions,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { hideInvalidDialogAction, showAddInfoAction } from '../../actions';

const InvalidDialog = ({
  invalidDialog, hideInvalidDialog, showAddInfo,
}) => (
  <Dialog
    open
    maxWidth="sm"
    fullWidth
    onClose={hideInvalidDialog}
  >
    <DialogTitle id="title">
      Aviso
    </DialogTitle>

    <DialogContent>
      <Typography variant="body1">
        Encontramos algumas inconsistencias no cadastro do cliente.
      </Typography>
    </DialogContent>

    {invalidDialog.address && (
      <DialogContent>
        <Typography variant="subtitle2">
          Endereço Inválido:
        </Typography>

        <Typography variant="body2">
          É necessário cadastrar um endereço válido para o cliente. Não será possível
          calcular o frete, nem inserir pagamentos sem essas informações.
        </Typography>
      </DialogContent>
    )}

    {invalidDialog.document && (
      <>
        <DialogContent>
          <Typography variant="subtitle2">
            Sem documento:
          </Typography>

          <Typography variant="body2">
            É necessário informar um CPF/CNPJ para prosseguir. Você pode cadastrar um
            CPF na seção: Informações da Nota, esse documento será vinculado ao cliente caso
            ele não possua um ainda. Não será possível inserir pagamentos sem documento.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={showAddInfo}>Inserir Documento</Button>
        </DialogActions>
      </>
    )}
  </Dialog>
);

InvalidDialog.propTypes = {
  invalidDialog: PropTypes.instanceOf(Object).isRequired,
  hideInvalidDialog: PropTypes.func.isRequired,
  showAddInfo: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.checkout.geral;

export default connect(mapState, {
  hideInvalidDialog: hideInvalidDialogAction,
  showAddInfo: showAddInfoAction,
})(InvalidDialog);
