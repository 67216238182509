/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Box, IconButton, Popover } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as PropTypes from 'prop-types';
import { getListDiscountAction } from '../actions';

import { InputRender } from '../../../../shared/fields';
import useStyles from './style';

const FilterDiscount = ({
  open, handleFilter, handleSubmit, loading, onSubmit,
  setFilterDataInicio, setFilterDataFim, filterDataFim, filterDataInicio,
}) => {
  const classes = useStyles();

  const [dateOpen, setDateOpen] = useState(null);
  const [dateInicio, setDateInicio] = useState('');
  const [dateFim, setDateFim] = useState('');
  const d = new Date();
  d.setDate(d.getDate() - 30);
  const [date, setDate] = useState([
    {
      startDate: d,
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  useEffect(() => {
    setFilterDataInicio(moment(date[0].startDate).format('DD/MM/YYYY'));
    setFilterDataFim(moment(date[0].endDate).format('DD/MM/YYYY'));
    setDateInicio(moment(date[0].startDate).format('DD/MM/YYYY'));
    setDateFim(moment(date[0].endDate).format('DD/MM/YYYY'));
  }, [date]);

  const handleRequest = () => {
    const filterDataInicioFormatted = moment(date[0].startDate).format('DD/MM/YYYY');
    const filterDataFimFormatted = moment(date[0].endDate).format('DD/MM/YYYY');
    handleFilter(filterDataInicioFormatted, filterDataFimFormatted);
  };

  return (
    <div
      className={`${classes.wrapper} ${open ? classes.wrapperOpened : null}`}
    >
      <form onSubmit={handleSubmit(() => onSubmit({ page: 1 }))}>
        <Box p={2}>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Field
                name={'orcamento'}
                component={InputRender}
                label={'Orçamento'}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div>
                <div
                  className={classes.inputFakeDate}
                  onClick={(e) => setDateOpen(e.currentTarget)}
                >
                  <div>
                    <span>
                      {dateInicio}
                      {' '}
                    </span>
                    <span>- </span>
                    <span>{dateFim}</span>
                  </div>
                  <ArrowDropDownIcon style={{ marginLeft: 30 }} />
                </div>
                <Popover
                  open={Boolean(dateOpen)}
                  anchorEl={dateOpen}
                  onClose={() => setDateOpen(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <DateRange
                    // months={1}
                    name="date"
                    // minDate={addDays(date[0].startDate, -90)}
                    maxDate={addDays(date[0].startDate, 30)}
                    onChange={(item) => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    rangeColors={['#FF5225']}
                    showMonthAndYearPickers={false}
                    locale={pt}
                  />
                </Popover>
              </div>
            </Grid>

            <Grid item xs={12} sm="auto">
              <IconButton
                type="submit"
                className={classes.searchButton}
                onClick={() => handleRequest()}
                disabled={loading}
              >
                <SearchIcon />
              </IconButton>
            </Grid>

          </Grid>

        </Box>
      </form>
    </div>
  );
};

FilterDiscount.defaultProps = {
  loading: false,
};

FilterDiscount.propTypes = {
  page: PropTypes.string,
  open: PropTypes.bool.isRequired,
  isCheckout: PropTypes.bool,
  loading: PropTypes.bool,
  handleFilter: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFilterDataInicio: PropTypes.func,
  setFilterDataFim: PropTypes.func,
  filterDataFim: PropTypes.string,
  filterDataInicio: PropTypes.string,
};

const seletor = formValueSelector('discount/filter');
const mapStateToProps = (state) => ({
  ...state.main.checkoutsAndOrders,
});

export default connect(mapStateToProps, {
  getOrcamentos: getListDiscountAction,
  getPedidos: getListDiscountAction,
})(reduxForm({
  form: 'discount/filter',
  destroyOnUnmount: false,
  initialValues: {
    dataInicio: moment().subtract(1, 'month'),
    dataFim: moment(),
  },
})(FilterDiscount));
