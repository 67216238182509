import { Collapse, makeStyles, withStyles } from '@material-ui/core';
import { DropDownOrangeIcon } from '../../../../../assets/algolia';

const useStyles = makeStyles(() => ({
  collapseBox: {
    padding: '4px 12px',
  },
  collapseHeader: {
    border: 'none',
    borderRadius: '8px 8px 0 0',
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 600,
    fontFamily: ['Poppins', 'sans serif'],
    padding: 12,
    background: '#F6F6F7',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',

    '&.rotateIcon': {
      '&:after': {
        transform: 'rotate(180deg)',
      },
    },

    '&:after': {
      background: `url(${DropDownOrangeIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 20,
      content: '""',
      width: 24,
      height: 24,
    },
  },
}));

export const CollapseStyled = withStyles(() => ({
  wrapper: {
    border: '1px solid #F8F9FA',
    padding: '12px 8px',
  },
}))(Collapse);

export default useStyles;
