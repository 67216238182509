import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  Box,
  DialogContent, Tooltip as TooltipMUI,
} from '@material-ui/core';
// eslint-disable-next-line import/no-cycle
import { connect } from 'react-redux';
import {
  AddButton,
  Autocomplete,
  Breadcrumb,
  CategoryLevels,
  ContentText,
  StockList,
  Tooltip,
} from '../index';
import useStyles, {
  DialogStyled as Dialog,
  DialogTitleStyled as DialogTitle,
  DialogActionsStyled as DialogActions,
} from './styles';

const DetailsDialog = ({
  open,
  setOpen,
  product,
  data,
  addProduct,
  qtyProductRequested,
  setQtyProductRequested,
  priceEstablishment,
  pplInfo,
  loading,
  isFisicStore,
  productAvailable,
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [urlImageSelected, setUrlImageSelected] = useState(null);
  const { REACT_APP_URL_IMAGES } = process.env;
  const mainUrlImages = REACT_APP_URL_IMAGES || '';
  const classes = useStyles();
  const {
    available,
    description,
    title,
    images,
    model,
    pma,
    priceEstablishments,
    stockEstablishments,
    erpId,
  } = product;

  const {
    descriptionProductGrid,
    header,
    closeIcon,
    detailsAndCategoriesBox,
    dialogActionsBox,
    image,
    imageAnchor,
    imageGrid,
    imageMain,
    imageStockAndFreightBox,
    pplIcon,
    addProductGrid,
    btnProductErpId,
    productErpId,
    copyIcon,
    popper,
    tooltip,
  } = classes;

  const {
    stockText,
    filialText,
    localFilial,
    qtyLocalFilial,
  } = data;

  const close = () => {
    setOpen(false);
    setUrlImageSelected(images[0]);
  };

  const handleImage = (img) => {
    setUrlImageSelected(img);
  };

  const handleTooltip = () => setOpenTooltip(!openTooltip);

  const copyToClipboard = (idProduct) => {
    navigator.clipboard.writeText(idProduct).catch((err) => console.log(err.message));
    handleTooltip();
  };

  useEffect(() => {
    setUrlImageSelected(images[0]);
  }, []);

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        handleTooltip();
      }, 1000);
    }
  }, [openTooltip]);

  return (
    <Dialog
      open={open}
      onClose={close}
      scroll="paper"
    >
      <Grid className={header}>
        {!!pplInfo && (<span className={pplIcon} />)}
        <DialogTitle>
          {`${title} ${model || ''}`}
          <TooltipMUI
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltip}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{ popper, tooltip }}
            title="Código copiado"
            placement="left"
          >
            <button type="button" className={btnProductErpId} onClick={() => copyToClipboard(erpId)}>
              <div className={productErpId}>
                {`COD BSELLER: ${erpId}`}
              </div>
              <div className={copyIcon} />
            </button>
          </TooltipMUI>
        </DialogTitle>
        <button type="button" aria-label="close details dialog" className={closeIcon} onClick={close} />
      </Grid>
      <DialogContent dividers>
        <Grid className={imageStockAndFreightBox}>
          <Box className={imageStockAndFreightBox}>
            <Grid
              className={imageGrid}
            >
              {
                images.map(
                  (img) => (
                    <button
                      type="button"
                      aria-label="image option"
                      key={`${img}`}
                      onClick={() => handleImage(img)}
                      className={imageAnchor}
                    >
                      <img
                        alt="imagem produto"
                        className={image}
                        src={`${mainUrlImages}${img}`}
                      />
                    </button>
                  ),
                )
              }
            </Grid>
            <img alt={title} className={imageMain} src={`${mainUrlImages}${urlImageSelected}`} />
          </Box>
          <Box>
            <div id="stockTotal">
              <StockList
                priceEstablishments={priceEstablishments}
                stockEstablishments={stockEstablishments}
              />
            </div>
          </Box>
        </Grid>
        <Grid className={descriptionProductGrid}>
          <h3 id="productDetails">Descrição do produto:</h3>
          <Box>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box className={detailsAndCategoriesBox}>
          <Breadcrumb />
          <CategoryLevels
            categoryLevels={product.categoryHierarchy}
            setOpen={setOpen}
          />
        </Box>
        <Box className={dialogActionsBox}>
          <ContentText
            priceProduct
            isFisicStore={isFisicStore}
            data={{
              ...priceEstablishment,
              pma,
              available,
              ppl: pplInfo,
            }}
            className="contentDetailsDialog"
            notDiscount
            isDetails
          />
          <Grid className={`${addProductGrid} ${(!productAvailable || loading) && 'disabled'}`}>
            <Autocomplete
              disabled={!productAvailable}
              value={qtyProductRequested}
              setValue={setQtyProductRequested}
              onClickDisabled={(!productAvailable || loading)}
            />
            {
              productAvailable && !loading ? (
                <Tooltip
                  textSecondary={`${stockText} ${filialText} filial`}
                >
                  <span>
                    <AddButton
                      localFilial={localFilial}
                      qty={qtyLocalFilial}
                      onClick={(!productAvailable || loading)
                        ? () => {} : addProduct}
                      disabled={!productAvailable || loading}
                    />
                  </span>
                </Tooltip>
              ) : (
                <span>
                  <AddButton
                    localFilial={localFilial}
                    qty={qtyLocalFilial}
                    disabled={!productAvailable || loading}
                  />
                </span>
              )
}
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

DetailsDialog.defaultProps = {
  pplInfo: null,
  isFisicStore: false,
  productAvailable: false,
};

DetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  product: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  addProduct: PropTypes.func.isRequired,
  qtyProductRequested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setQtyProductRequested: PropTypes.func.isRequired,
  priceEstablishment: PropTypes.instanceOf(Object).isRequired,
  pplInfo: PropTypes.instanceOf(Object),
  loading: PropTypes.bool.isRequired,
  isFisicStore: PropTypes.bool,
  productAvailable: PropTypes.bool,
};

const mapState = (state) => ({
  loading: state.main.checkout.geral.loading,
});

export default connect(mapState)(DetailsDialog);
