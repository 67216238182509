import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ breakpoints }) => ({
  cardGrid: {
    display: 'flex',
    justifyContent: 'center',
    // marginTop: 48,
  },
  cardContent: {
    padding: 0,
    marginBottom: 24,

    '& p.MuiFormHelperText-root': {
      width: 200,
      margin: 0,
    },
  },
  contentTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: '#2A2A2A',
    marginTop: 24,
    marginBottom: 12,
  },
  labelField: {
    fontSize: 14,
    fontWeight: 600,
    color: '#7B7F82',
    marginBottom: 6,
  },
  complementoGrid: {
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'block',
      minHeight: 97,
    },
  },
  complementoMobileGrid: {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  complementoStyled: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    minHeight: 97,
  },
}));

export default useStyles;
