import {
  makeStyles,
  Select,
  withStyles,
} from '@material-ui/core';
import { LogoutIcon } from '../../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  arrowIcon: {
    borderTop: '2px solid #FE5000',
    borderRight: '2px solid #FE5000',
    width: 8,
    height: 8,
    borderRadius: 0,
    transform: 'rotate(135deg)',

    '&.open': {
      transform: 'rotate(315deg)',
    },

    [breakpoints.down('sm')]: {
      borderColor: '#FFFFFF',
    },
  },
  arrowToggleBtn: {
    padding: 0,
    minWidth: 24,
    height: 24,
  },
  sellerAvatar: {
    width: 40,
    height: 40,
    borderRadius: 8,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    background: '#FE5000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    fontSize: 24,
    fontWeight: 600,

    '&.open-menu': {
      width: 56,
      height: 56,
      borderRadius: 12,
      fontSize: 33,
    },

    [breakpoints.down('sm')]: {
      background: '#FFFFFF',
      color: '#FE5000',
    },
  },
  sellerMenuBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,

    '& span:first-child': {
      fontSize: 10,
      lineHeight: '12px',
      color: '#242424',
    },
  },
  sellerMenuBoxMain: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    height: 80,
    padding: '0 20px',
    minWidth: 203,
    justifyContent: 'center',

    [breakpoints.up('md')]: {
      borderRight: '1px solid #CFCFD4',
    },
  },
  sellerName: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontWeight: 600,
    textTransform: 'capitalize',
    width: 133,
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    display: '-webkit-box',
    overflow: 'hidden',
    wordBreak: 'break-all',

    '&.open-menu': {
      display: '-webkit-box',
      fontSize: '16px !important',
      maxWidth: 320,
      width: 320,
      color: '#0F1012',
      textOverflow: 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      textAlign: 'center',
      minHeight: 32,
      wordBreak: 'initial',
    },
  },
  sellerMenuPopover: {
    transform: 'translate3d(0px, 45px, 0px) !important',

    [breakpoints.down('sm')]: {
      top: '45px !important',
      left: '0 !important',
      transform: 'none !important',
    },
  },
  sellerMenuPopoverPaper: {
    background: 'none',
    top: '8px !important',
    left: '72px !important',

    [breakpoints.down('sm')]: {
      left: '50% !important',
      transform: 'translate(-50%, 0) !important',
    },
  },
  store: {
    color: '#7B7F82',
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    '-webkitBoxOrient': 'vertical',
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    width: 100,

    [breakpoints.down('sm')]: {
      color: '#FFFFFF',
    },
  },
  userLoggedBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 15,
  },
  logoutBtn: {
    border: '1px solid #CFCFD4',
    backgroundColor: '#F6F6F7',
    borderRadius: 8,
    color: '#7B7F82',
    fontSize: 16,
    height: 48,
    justifyContent: 'center',
    width: '100%',
    lineHeight: '18px',
    textTransform: 'initial',
    padding: 12,

    '& [class*=label]': {
      gap: 6,
      alignItems: 'center',
    },

    '&:hover': {
      backgroundColor: '#7B7F8240',
    },
  },
  logoutIcon: {
    background: `url(${LogoutIcon})`,
    width: 18,
    height: 18,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 18,
  },
  logoutBox: {
    width: '100%',
    borderTop: '1px solid #CFCFD4',
    paddingTop: 16,
  },
  selectFilialBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  selectFilialLabel: {
    fontSize: 14,
    lineHeight: '21px',
    color: '#787F82',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filialName: {
    borderBottom: '1px solid #E3E3E7',
    fontSize: 12,
    lineHeight: '18px',
    textTransform: 'uppercase',
    color: '#242424',
    padding: '8px 8px 7px 8px',
  },
  userLoggedPaper: {
    height: 378,
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    border: '1px solid #CFCFD4',
    borderRadius: 16,
    padding: 16,
    boxShadow: 'initial',

    [breakpoints.down('xs')]: {
      minWidth: 288,
      borderRadius: 8,
    },
  },
  selectFilialMenu: {
    boxShadow: 'none',
    border: '1px solid #E3E3E7',
    borderBottom: 0,
    top: '333px !important',
    maxHeight: 171,
    minWidth: '288px !important',

    '& ul': {
      padding: 0,
    },

    '&::-webkit-scrollbar': {
      width: 6,
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#D9D9D9',
      borderRadius: 4,
    },
  },
  sellerIdBox: {
    paddingBottom: 16,
    fontSize: 10,
    color: '#707070',
    borderBottom: '1px solid #cfcfd4',
    lineHeight: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,

    '& span:last-child': {
      fontSize: 14,
      maxWidth: 320,
      minHeight: 30,
      textTransform: 'uppercase',
      textOverflow: 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      display: '-webkit-box',
      textAlign: 'center',
    },
  },
  arrowPopover: {
    position: 'relative',
    height: 23,
    display: 'block',

    '&:before': {
      content: '""',
      display: 'block',
      width: 32,
      height: 32,
      position: 'absolute',
      background: '#FFFFFF',
      transform: 'rotate(45deg)',
      border: '1px solid #CFCFD4',
      top: 7,
      right: 74,
      borderRight: 0,
      borderBottom: 0,
      borderRadius: '8px 0',
    },
  },
  sellerMenuGrid: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const SelectStyled = withStyles(() => ({
  select: {
    border: '1px solid #E3E3E7',
    borderRadius: '8px !important',
    padding: '14px 24px 14px 12px',
    fontSize: 14,
    lineHeight: '18px',
    textTransform: 'uppercase',
    color: '#242424',

    '&:focus': {
      background: 'none',
      borderRadius: 4,
    },
  },

  icon: {
    right: 12,
    top: 'auto',
    borderTop: '2px solid #FE5000',
    borderRight: '2px solid #FE5000',
    width: 8,
    height: 8,
    display: 'block',
    transform: 'rotate(135deg)',

    '& path': {
      display: 'none',
    },
  },

  iconOpen: {
    transform: 'rotate(315deg)',
  },
}))(Select);

export default useStyles;
