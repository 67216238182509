import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Calendar } from 'react-date-range';
import pt from 'date-fns/locale/pt';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import useStyles from './styles';

const DatePickerSchedule = ({
  dataMinimaEntregaAgendada,
  limiteEntregaAgendada,
  open,
  onChange,
  handleSelectDate,
  dateSelected,
  handleClose
}) => {
  const classes = useStyles();

  const convertDate = (date) => {
    if (!dataMinimaEntregaAgendada && limiteEntregaAgendada) return;

    return new Date(moment(date, "DD/MM/YYYY").format('MM-DD-YYYY'));
  };

  const handleSelect = (date) => handleSelectDate(moment(date).format('DD/MM/YYYY'));

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        Entrega Agendada
        <IconButton onClick={handleClose}>
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Calendar
          locale={pt}
          minDate={convertDate(dataMinimaEntregaAgendada)}
          maxDate={convertDate(limiteEntregaAgendada)}
          color="#FF5225"
          date={dateSelected}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onChange(convertDate(dataMinimaEntregaAgendada))} color="primary" variant="outlined">Limpar</Button>
        <Button onClick={handleSelect} color="primary" variant="contained">Selecionar</Button>
      </DialogActions>
    </Dialog>
  );
};

DatePickerSchedule.defaultProps = {
  handleSelectDate: () => {},
  handleClose: () => {},
};

DatePickerSchedule.propTypes = {
  dataMinimaEntregaAgendada: PropTypes.string.isRequired,
  limiteEntregaAgendada: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  dateSelected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};

export default DatePickerSchedule;
