// eslint-disable-next-line import/no-cycle
import { Types } from './index';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import apiService from '../../../shared/services/apiService';
import { clearClient, setClient } from '../../../shared/services/authClient';

const handleCpfFormatted = (value) => value.replace(/(\d{3}).(\d{3}).(\d{3})-(\d{2})/g, '$1$2$3$4');

const handleCnpjFormatted = (value) => value.replace(/(\d{2}).(\d{3}).(\d{3})\/(\d{4}).(\d{2})/g, '$1$2$3$4$5');

const clientSignInAction = ({ credential }) => async (dispatch, getState) => {
  dispatch({ type: Types.CLIENT_SIGN_IN_REQUEST });
  try {
    let credencial = credential;
    if (!credencial) throw new Error('Informe o e-mail, CPF ou CNPJ do Cliente!');

    if (credencial.length === 14) {
      credencial = handleCpfFormatted(credencial);
    } else if (credencial.length === 18) {
      credencial = handleCnpjFormatted(credencial);
    }

    const {
      data: { content },
    } = await apiService.post('/clientes/login', { credencial });

    if (content.length === 1) {
      let valeTotal = 0;
      const response = await apiService.get(`/vales/clientes/${content[0].codCliente}/saldo-total`);

      if (response.status) {
        valeTotal = response.data.content.saldoTotal;
      }

      setClient({
        ...content[0],
        valeTotal,
        checkoutId: 0,
        qtyProducts: 0,
      });

      dispatch({
        type: Types.SET_CLIENT_SUCCESS,
        payload: {
          client: {
            ...content[0],
            valeTotal,
            checkoutId: 0,
            qtyProducts: 0,
          },
          clients: null,
        },
      });
    } else {
      dispatch({
        type: Types.CLIENT_SIGN_IN_SUCCESS,
        payload: {
          clients: [...content],
        },
      });
    }
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.CLIENT_SIGN_IN_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const setClientAction = ({ client }) => async (dispatch, getState) => {
  dispatch({ type: Types.SET_CLIENT_REQUEST });

  try {
    let valeTotal = 0;
    const response = await apiService.get(`/vales/clientes/${client.codCliente}/saldo-total`);

    if (response.status) {
      valeTotal = response.data.content.saldoTotal;
    }

    dispatch({
      type: Types.SET_CLIENT_SUCCESS,
      payload: {
        client: {
          ...client,
          valeTotal,
          checkoutId: 0,
        },
        clients: null,
      },
    });

    setClient({
      ...client,
      valeTotal,
      checkoutId: 0,
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.SET_CLIENT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const clientLogoutAction = () => async (dispatch, getState) => {
  dispatch({ type: Types.SET_CLIENT_REQUEST });

  try {
    dispatch({
      type: Types.SET_CLIENT_SUCCESS,
      payload: { client: null, clients: null },
    });

    clearClient();
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.SET_CLIENT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const clientLogoutCatalogoAction = () => async (dispatch, getState) => {
  try {
    const { REACT_APP_HOST_CATALAGO } = process.env;
    window.location.href = `${REACT_APP_HOST_CATALAGO}/logout-customer`;
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  clientSignInAction,
  clientLogoutAction,
  setClientAction,
  clientLogoutCatalogoAction,
};
