import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import useStyles from './styles';

const EmptyCart = () => {
  const {
    content,
    emptyCartGrid,
    icon,
    paper,
  } = useStyles();

  return (
    <Grid className={emptyCartGrid}>
      <Card classes={{ root: paper }}>
        <CardContent classes={{ root: content }}>
          <span className={icon} />
          <span>Seu carrinho ainda está vazio.</span>
          <span>Explore a nossa busca e comece a adicionar os itens que seu cliente deseja!</span>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EmptyCart;
