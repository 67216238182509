import React from 'react';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const AddButton = ({
  localFilial,
  qty,
  disabled,
  onClick,
}) => {
  const classes = useStyles();
  const isFilialClass = localFilial ? 'localFilial' : '';

  const {
    addIcon,
    addProduct,
    addProductBox,
    stockBox,
    stockQty,
    unity,
  } = classes;

  return (
    <Button
      disableElevation
      className={`${addProduct} ${isFilialClass}`}
      disabled={!!disabled}
      onClick={onClick}
    >
      <Box className={addProductBox}>
        <div className={`${addIcon} ${isFilialClass}`} />
        <Box className={`${stockBox} ${isFilialClass}`}>
          <span className={stockQty}>{qty}</span>
          <span className={unity}>unid.</span>
        </Box>
      </Box>
    </Button>
  );
};

AddButton.defaultProps = {
  localFilial: false,
  disabled: false,
  qty: 0,
};

AddButton.propTypes = {
  localFilial: PropTypes.bool,
  disabled: PropTypes.bool,
  qty: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

export default AddButton;
