import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  checkboxRefinementBox: {
    '& input[type=checkbox]': {
      appearance: 'none',
      minWidth: 17,
      height: 17,
      borderRadius: 2,
      border: '2px solid #242424',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:checked': {
        border: '2px solid #FE5000',

        '& ~ span': {
          color: '#FE5000',
        },

        '&::before': {
          content: '""',
          position: 'absolute',
          width: 8,
          height: 5,
          marginBottom: 1,
          marginRight: 1,
          border: '2px solid #FE5000',
          borderTop: 'none',
          borderRight: 'none',
          transform: 'rotate(-45deg)',
        },
      },
    },
  },
  checkboxRefinementLabel: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',

    '&.disabled': {
      opacity: 0.5,
    },
  },
  checkboxRefinementTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#242424',

    [breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  checkboxRefinementCount: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#242424',

    [breakpoints.down('sm')]: {
      fontSize: 10,
      lineHeight: '14px',
    },
  },
}));

export default useStyles;
