import React from 'react';
import * as PropTypes from 'prop-types';
import {
  IconButton, Typography, Box, Divider,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { goBack, push, entries } from '../../../history';

import useStyles from './style';

const CardBar = ({
  title, titleElement, leading, children, flat, forcePath,
}) => {
  const classes = useStyles();

  const handleBack = () => {
    if (forcePath) return push(forcePath);
    if (entries.length) return goBack();
    return push('/');
  };

  return (
    <header>
      <Box className={classes.appBar}>
        <div className={classes.wrapper}>
          {!leading ? (
            <IconButton onClick={handleBack} color="primary">
              <ArrowBack />
            </IconButton>
          ) : leading}

          <Box mr={1} />

          {titleElement || (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          )}

          <Box mr={1} />

          <div className={classes.container}>
            {children}
          </div>
        </div>
      </Box>
      {!flat && <Divider />}
    </header>
  );
};

CardBar.propTypes = {
  title: PropTypes.string,
  forcePath: PropTypes.string,
  titleElement: PropTypes.element,
  leading: PropTypes.element,
  children: PropTypes.node,
  flat: PropTypes.bool,
};

CardBar.defaultProps = {
  title: '',
  titleElement: null,
  forcePath: null,
  leading: null,
  children: null,
  flat: false,
};

export default CardBar;
