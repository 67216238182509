/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import { Grid, Tooltip, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DvrIcon from '@material-ui/icons/Dvr';
import PaymentMethod from '../paymentMethod/PaymentMethod';
import PaymentType from './paymentType';
import SettingsIcon from '../../../../assets/settings.svg';
import { checkDiscountOfferAction, checkDiscountOfferRequestAction } from '../../actions';
import { connect } from 'react-redux';
import apiService from '../../../../shared/services/apiService';
import { getUnidadeDeNegocio} from '../../../../shared/services/app';

const PaymentRadio = ({
  paymentMethod, setPaymentMethod, hasPayments, drawer, disabled, chosedFreight, checkDiscountOfferRequest,
  hasMarketPlace, hasDeniedPayments, checkDiscountOffer, discountCart, observacaoDesconto, checkout, justificativa, aguardandoSolicitacao,
}) => {

  const [observacaoSolicitacaoDesconto, setObservacaoSolicitacaoDesconto] = useState([false]);
  
  useEffect(() => {
    //obsRefresh();
    setPaymentMethod({ paymentMethod: PaymentType.AVANÇADO });
  }, [checkout]);

  const obsRefresh  = async () => {
    validarProdutoDesconto();
    if (observacaoSolicitacaoDesconto) {
      await checkDiscountOfferRequest();
    }
  }

  const validarProdutoDesconto = () => {
    let percentPermission = 10.2;
    if (getUnidadeDeNegocio() > 1) {
      percentPermission = 0;
    }
    let produtoComDesconto = false;
      if (checkout?.itemsFilials !== undefined) {
        for (let i = 0; i < checkout.itemsFilials.length; i++) {
          if (checkout.itemsFilials[i].items !== undefined) {
            for (let r = 0; r < checkout.itemsFilials[i].items.length; r++) {
              let poncent = (checkout.itemsFilials[i].items[r].discount*100)/(checkout.itemsFilials[i].items[r].totalOnTime);
              if (poncent > percentPermission) {
                produtoComDesconto = true;
              }
            }
          }
        }
      }
  
      if (discountCart <= percentPermission) {
        setObservacaoSolicitacaoDesconto(false);
      } else { 
        setObservacaoSolicitacaoDesconto(true);
      }
  }

  const requestDiscount  = async () => {
    let percentPermission = 10.2;
    if (getUnidadeDeNegocio() > 1) {
      percentPermission = 0;
    }
    let produtoComDesconto = false;
    if (checkout?.itemsFilials !== undefined) {
      for (let i = 0; i < checkout.itemsFilials.length; i++) {
        if (checkout.itemsFilials[i].items !== undefined) {
          for (let r = 0; r < checkout.itemsFilials[i].items.length; r++) {
            let poncent = (checkout.itemsFilials[i].items[r].discount*100)/(checkout.itemsFilials[i].items[r].totalOnTime);
            if (poncent > percentPermission) {
              produtoComDesconto = true;
            }
          }
        }
      }
    }

    if (discountCart <= percentPermission) {
      setPaymentMethod({ paymentMethod: PaymentType.CAIXA });
    } else {
      if (await checkDiscountOffer()) {
        setPaymentMethod({ paymentMethod: PaymentType.CAIXA });
      }
    }
  };

  const [messageDiscountDenied, setMessageDiscountMessage] = useState('');

  const getMessageDiscountDenied = async() => {
    const { checkoutId } = checkout;
    const { data: { results: { response, value } } } = await apiService.get(`/buscar-justificativa-desconto-reprovado/checkoutid/${checkoutId}`);
    if(response) {
      setMessageDiscountMessage(value)
    }
  }

  useEffect(() => {
    if(observacaoSolicitacaoDesconto && observacaoDesconto === 'Solicitação de desconto reprovada.') {
      getMessageDiscountDenied()
    } 
  }, [observacaoSolicitacaoDesconto, observacaoDesconto])

  return (
  <Box
    aria-label="quiz"
    name="quiz"
  >
    <Grid container>

      <Grid item xs={12} md={4} lg={drawer ? 4 : 2} xl={2} id="avancado">
        <Tooltip
          title={
            hasMarketPlace
              ? 'Não permitido em pedidos com item Market Place'
              : !chosedFreight
                ? 'Selecione uma forma de entrega'
                : 'shift + A'
          }
          placement="top"
        >
          <span>
            <PaymentMethod
              onClick={() => {
                setPaymentMethod({ paymentMethod: PaymentType.AVANÇADO });
              }}
              src={SettingsIcon}
              title="Avançado"
              disabled={disabled
                || (hasPayments && PaymentType.AVANÇADO !== paymentMethod)
                || hasMarketPlace}
              hasNotification={hasDeniedPayments}
            />
          </span>
        </Tooltip>
      </Grid>
      

      <Grid  style={{ padding: 16 }} item xs={12} md={4} lg={drawer ? 4 : 4} xl={4} id="observacoes">
        <Tooltip
          placement="top"
        >
            <span>
              OBSERVAÇÕES:<br />{observacaoSolicitacaoDesconto && observacaoDesconto}<br />
              {messageDiscountDenied.length > 0 && (
                <>JUSTIFICATIVA:<br />{messageDiscountDenied}</>
              )}
            </span>
        </Tooltip>
      </Grid>

    </Grid>
  </Box>
  );
};

PaymentRadio.defaultProps = {
  paymentMethod: null,
  disabled: false,
};

const mapStateToProps = (state) => ({
  ...state.auth.login,
  ...state.main.app,
  ...state.main.checkout.geral,
});

PaymentRadio.propTypes = {
  paymentMethod: PropTypes.string,
  setPaymentMethod: PropTypes.func.isRequired,
  drawer: PropTypes.bool.isRequired,
  hasPayments: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  chosedFreight: PropTypes.bool.isRequired,
  checkDiscountOffer: PropTypes.func.isRequired,
  hasMarketPlace: PropTypes.bool.isRequired,
  hasDeniedPayments: PropTypes.bool.isRequired,
  observacaoDesconto: PropTypes.string.isRequired,
  justificativa: PropTypes.bool.isRequired,
  aguardandoSolicitacao: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
  checkDiscountOffer: checkDiscountOfferAction,
  checkDiscountOfferRequest: checkDiscountOfferRequestAction,
})(PaymentRadio);
