import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const CheckBoxRender = ({
  input: { onChange, value },
  color,
  ...rest
}) => (
  <FormControlLabel
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    control={(
      <Checkbox
        checked={!!value}
        onChange={onChange}
        color={color}
      />
    )}
  />
);

CheckBoxRender.defaultProps = {
  color: 'primary',
  disabled: false,
};

CheckBoxRender.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.instanceOf(Object).isRequired,
  color: PropTypes.string,
};
export default CheckBoxRender;
