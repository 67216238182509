import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, transitions }) => ({
  wrapper: {
    backgroundColor: '#EAEAEA',
    maxHeight: 0,
    overflow: 'hidden',
    transition: transitions.create('max-height', {
      duration: transitions.duration.shortest,
      easing: transitions.easing.easeInOut,
    }),
  },
  wrapperOpened: {
    maxHeight: 464,
    '& label': {
      fontSize: 14,
      lineHeight: '20px',
    },
    [breakpoints.down('xs')]: {
      maxHeight: 712,
    },
  },
  searchButton: {
    float: 'right',
  },
  inputFakeDate: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
    borderBottomColor: '#000000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    color: '#6C757D',
    height: 38,
    fontSize: 14,
    paddingLeft: 5,
    cursor: 'pointer',
    alignItems: 'center',
    maxWidth: 361,
    '&:active': {
      borderColor: '#FF5225',
    },
    [breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  dateContainer: {
    display: 'flex',
    gap: 4,
  },
  btnGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  clearFilterBtn: {
    borderRadius: 150,
    fontSize: 12,
    lineHeight: '18px',
    textTransform: 'capitalize',
    color: '#FA4616',
    borderColor: '#FA4616',
    fontWeight: 600,
    height: 32,
    minWidth: 156,
  },
  btnBox: {
    display: 'flex',
    margin: 10,
    backgroundColor: '#EFF2F7',
    borderRadius: 50,
    border: '1px solid #D9D9D9',
  },
  dateBtn: {
    borderRadius: 50,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 12,
    lineHeight: '18px',
    textTransform: 'none',
    fontWeight: 600,
    backgroundColor: '#EFF2F7',
    color: '#ADADAD',
    minWidth: '55%',

    '&.minWidthStyled': {
      minWidth: '45%',
    },

    '&.selected': {
      backgroundColor: '#FA4616',
      color: '#FFFFFF',
    },
  },
}));

export default useStyles;
