import { combineActions, handleActions } from 'redux-actions';
import TYPES from './types';

const INITIAL_STATE = {
  isLoading: false,
  options: [],
};

const stopLoading = combineActions(
  TYPES.CREATE_TICKET_FAILURE,
  TYPES.CREATE_TICKET_SUCCESS,
);

const suggestion = handleActions({
  [TYPES.LOAD_OPTIONS]: (state, { payload }) => ({
    ...state,
    options: payload.options,
  }),
  [TYPES.CREATE_TICKET_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [stopLoading]: (state) => ({
    ...state,
    isLoading: false,
  }),
}, INITIAL_STATE);

export default suggestion;
