import { handleActions, combineActions } from 'redux-actions';
import types from './types';

const INITIAL_STATE = {
  loadings: {
    customer: false,
    order: false,
    detailed: false,
    linkFile: false,
  },
  order: null,
  customer: null,
  detailed: null,
  linkFile: null,
};

const startLoadingOrder = combineActions(
  types.PRINT_BILLET_REQUEST,
  types.GET_ORDER_REQUEST,
  types.PDF_REQUEST,
  types.GET_LINK_FILE_REQUEST,
  types.GET_ORDER_CAIXA_REQUEST,
);

const stopLoadingOrder = combineActions(
  types.GET_ORDER_SUCCESS,
  types.GET_ORDER_FAIL,
  types.GET_LINK_FILE_SUCCESS,
  types.GET_LINK_FILE_FAIL,
  types.UPDATE_INFO_SUCCESS,
  types.UPDATE_INFO_FAIL,
  types.PRINT_BILLET_SUCCESS,
  types.PRINT_BILLET_FAIL,
  types.PDF_SUCCESS,
  types.PDF_FAIL,
  types.GET_ORDER_CAIXA_SUCCESS,
  types.GET_ORDER_CAIXA_FAIL,
);

const stopLoadingCustomer = combineActions(
  types.GET_CUSTOMER_SUCCESS,
  types.GET_CUSTOMER_FAIL,
);

const detail = handleActions({
  [startLoadingOrder]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      order: true,
    },
  }),
  [types.RESET_CHECKOUT]: (state) => ({
    ...state,
    order: null,
  }),
  [stopLoadingOrder]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      order: false,
    },
  }),
  [types.GET_CUSTOMER_REQUEST]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      customer: true,
    },
  }),
  [stopLoadingCustomer]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      customer: false,
    },
  }),
  [types.GET_ORDER_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [types.GET_CUSTOMER_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [types.DETAILED_DELIVERY_REQUEST]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      detailed: true,
    },
  }),
  [types.DETAILED_DELIVERY_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
    loadings: {
      ...state.loadings,
      detailed: false,
    },
  }),
  [types.DETAILED_DELIVERY_FAIL]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      detailed: false,
    },
  }),
  [types.GET_LINK_FILE_REQUEST]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      linkFile: true,
    },
  }),
  [types.GET_LINK_FILE_SUCCESS]: (state, { payload }) => ({
    ...state,
    linkFile: payload,
    loadings: {
      ...state.loadings,
      linkFile: false,
    },
  }),
  [types.GET_LINK_FILE_FAIL]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      linkFile: false,
    },
  }),
  [types.RESET_DETAILED_DELIVERY]: (state) => ({
    ...state,
    detailed: null,
  }),
  [types.GET_ORDER_CAIXA_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
}, INITIAL_STATE);

export default detail;
