import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EmptyCart } from './_components';
import { push } from '../../history';
import useStyles from './styles';
import { getClient } from '../../shared/services/authClient';

const HomePage = ({
  client,
}) => {
  const { section } = useStyles();

  if (!client) {
    push('/client-login');
  } else if (!client.confirmAddress) {
    push('/client-login');
  }

  return (
    <section className={section}>
      {!!client && !client.qtyProducts && (
        <EmptyCart />
      )}
    </section>
  );
};

HomePage.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => {
  let { client } = state.authClient;

  client = getClient() || client;

  return ({
    ...state.main.home,
    client,
  });
};

export default connect(mapStateToProps, {
})(HomePage);
