import React from 'react';
import PropTypes from 'prop-types';
import {
  Stepper, Step, StepLabel, Box, Button,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  CustomConnector,
  CustomStepLabelIcon,
} from '../customStepper';
import useStyles from './styles';

const ItemStatusMobile = ({ delivery, detailedDelivery }) => {
  const activeTrack = delivery.tracking.filter((track, index) => (
    delivery.tracking[index + 1]
      ? track.data && !delivery.tracking.some((item, i) => item.data && i > index)
      : track.data
  ))[0];
  const indexTrack = delivery.tracking.indexOf(activeTrack);
  const classes = useStyles({
    colorTracking: activeTrack && activeTrack.corTracking,
  });

  const color = activeTrack ? activeTrack.corTracking : '#ccc';

  return (
    <div>
      {delivery.tracking.length ? (
        <div>
          <Stepper
            activeStep={indexTrack}
            orientation="vertical"
            connector={<CustomConnector color={activeTrack.corTracking} />}
          >
            {delivery.tracking.map((track, index) => (
              <Step key={`track-${index + 1}`}>
                <StepLabel
                  StepIconComponent={CustomStepLabelIcon}
                  StepIconProps={{ color }}
                >
                  <Box
                    className={clsx(classes.trackingText, {
                      [classes.active]: indexTrack === index,
                    })}
                  >
                    <Box color={(indexTrack === index && color) || null}>
                      {track.descricaoTracking}
                    </Box>
                  </Box>

                  <Box className={classes.trackingText}>
                    {track.data}
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Button
            style={{marginTop: 12}}
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => detailedDelivery(delivery.idEntrega)}
            disableElevation
          >
            Entrega detalhada
          </Button>
        </div>
      ) : (
        'Nenhum status de tracking encontrado'
      )}

    </div>
  );
};

ItemStatusMobile.propTypes = {
  delivery: PropTypes.instanceOf(Object).isRequired,
  detailedDelivery: PropTypes.func,
};

export default ItemStatusMobile;
