import React from 'react';
import { Accordion, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
    '&::before': {
      content: '',
      opacity: 0,
    },
  },
  expanded: {
    margin: '0 !important',
  },
});

const FlatExpansionPanel = (props) => {
  const classes = useStyles();

  return (
    <Accordion
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      classes={classes}
    />
  );
};

export default FlatExpansionPanel;
