import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { AES } from 'crypto-js';
import { connect, useDispatch } from 'react-redux';

import { setFilialAction } from '../../../main/actions';

import types from '../types';
import { setPageAction, singinUserFiliaisAction } from '../action';
import { getVendedorDados, singinRequest } from '../repository';

import CircularLoading from '../../../shared/components/loading/CircularLoading';
import { clearFilial, setCanalDeVendas, setFilialVendedor, setUnidadeDeNegocio } from '../../../shared/services/app';
import { clearUser } from '../../../shared/services/auth';

const AutomaticLogin = ({ login, singinUserFiliais, setFilial }) => {
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  const { user, pwd, route } = params;

  const handleRedirect = (routeRedirect) => window.location.href = routeRedirect || '/';

  if (!user || !pwd) handleRedirect('/login');

  const handleVendedor = async (vendedor) => {
    getVendedorDados({idVendedor: vendedor.idVendedor}).then((response) => {
      const { results } = response;

      const filial = vendedor.filiais.find((f) => f.idFilial === results.idFilial);
      setFilial(filial);
      singinUserFiliais(filial, vendedor);

      setFilialVendedor(results.idFilial);
      setUnidadeDeNegocio(results.idUnidadeDeNegocio);
      setCanalDeVendas(results.idCanalDeVendas);

      handleRedirect(route);
    });
  }

  useLayoutEffect(() => {
    if (login.user) {
      dispatch(setPageAction(0));
      clearFilial();
      clearUser();
    };

    dispatch({ type: types.SINGIN_REQUEST });
    singinRequest({ user, senha: pwd }).then((response) => {
      const { results } = response;

      dispatch({
        type: types.SINGIN_SUCCESS,
        payload: {
          user: {
            ...results.value,
            identificacao: user,
            senha: AES.encrypt(pwd, process.env.REACT_APP_SECRET_ENCRYPT_KEY).toString(),
            nome: user,
          },
        },
      });

      handleVendedor(results.value);
    }).catch(() => {
      dispatch({ type: types.SINGIN_FAILURE });
      handleRedirect('/login');
    });
  }, []);

  return (
    <CircularLoading open={true} message="Efetuando login..."/>
  );
};

AutomaticLogin.propTypes = {
  login: PropTypes.instanceOf(Object),
}

const mapState = (state) => {
  return {
    login: state.auth.login,
  };
}

export default connect(mapState, {
  setFilial: setFilialAction,
  singinUserFiliais: singinUserFiliaisAction
})(AutomaticLogin);
