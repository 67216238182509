import makeStyles from '@material-ui/core/styles/makeStyles';
import { common } from '@material-ui/core/colors';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: ({ color }) => (color || palette.primary.main),
    color: common.white,
    width: '100%',
    height: 38,
    trasition: 'all 180ms ease',
    '&:hover': {
      backgroundColor: ({ colorHover }) => (colorHover || palette.primary.dark),
    },
  },
}));

export default useStyles;
