import types from './types';
import resolveError from '../../shared/utils/resolveError';
import {
  getFuncionalidades, putFuncionalidade,
} from './repository';

export const getFuncionalidadeAction = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_FUNCIONALIDADES_REQUEST });
  try {
    const { results } = await getFuncionalidades();
    dispatch({
      type: types.GET_FUNCIONALIDADES_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_FUNCIONALIDADES_FAILURE });
  }
};

export const putFuncionalidadeAction = (body) => async (dispatch, getState) => {
  dispatch({ type: types.PUT_FUNCIONALIDADES_REQUEST });
  const { snack } = getState().main.app;
  try {
    const { results } = await putFuncionalidade(body);
    await dispatch({
      type: types.PUT_FUNCIONALIDADES_SUCCESS,
      payload: {
        results,
      },
    });
    await dispatch(getFuncionalidadeAction());
    snack.enqueueSnackbar('Funcionalidade Atualizada com sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.PUT_FUNCIONALIDADES_FAILURE });
  }
};
