import { makeStyles } from '@material-ui/core';
import { CheckIcon, SearchGreyIcon } from '../../../../../assets/algolia';

const useStyles = makeStyles(() => ({
  refinementList: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  refinementBtn: {
    background: 'none',
    border: 'none',
    fontSize: 15,
    lineHeight: '23px',
    color: '#2296F3',
    textDecoration: 'underline',
    fontFamily: ['Poppins', 'sans serif'],
    padding: '0 12px',
  },
  refinementCheckbox: {
    border: '2px solid #909599',
    appearance: 'none',
    borderRadius: 3,
    width: 15,
    height: 15,

    '&:checked': {
      background: `url(${CheckIcon})`,
      border: 0,
      appearance: 'none',
    },
  },
  refinementLabel: {
    fontSize: 14,
    lineHeight: '21px',
  },
  refinementCount: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#7B7F82',
    background: '#F6F6F7',
    padding: '2px 4px',
    borderRadius: 6,
  },
  divBtn: {
    borderTop: '1px solid #CFCFD4',
    borderTopStyle: 'dashed',
    paddingTop: 8,
  },
  searchInput: {
    height: 40,
    border: '1px solid #BDC1CA',
    width: '100%',
    borderRadius: 4,
    padding: '10px 30px 10px 10px',
    fontFamily: ['Poppins', 'sans serif'],
    fontSize: 14,
    lineHeight: '21px',
    '&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration': {
      '-webkit-appearance': 'none',
    },
  },
  searchContent: {
    position: 'relative',
  },
  searchInputIcon: {
    background: `url(${SearchGreyIcon})`,
    width: 14,
    height: 14,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    right: 10,
    bottom: 13,
  },
}));

export default useStyles;
