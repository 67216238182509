import React from 'react';
import { Route } from 'react-router-dom';

import CreditRequestPage from './Page';
import CustomSwitch from '../../shared/components/customSwitch';

const CreditRouter = () => (
  <CustomSwitch>
    <Route
      path="/credit/requests"
      component={CreditRequestPage}
      exact
    />
  </CustomSwitch>
);

export default CreditRouter;
