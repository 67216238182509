import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { Button, Typography } from '@material-ui/core';
import { required } from '../../../../../shared/fields/validate';
import {
  savePaymentAction, removePaymentAction, savePaymentDiscountAction, setCashierPaymentAction,
  getConditionsAction
} from '../actions';
import { SelectRender } from '../../../../../shared/fields';
import PAYMENT_TYPE from '../paymentType';
import CashierDialog from './CashierDialog';

import { checkDiscountOfferAction, openConfirmDialogAction } from '../../../actions';

const Cashier = ({
  loadingConditions, loading, handleSubmit, savePayment, checkoutId, isNew, paymentMethod,
  totalInstallment, total, discountStatus, setCashierPayment, getConditions, checkout, checkDiscountOffer, openConfirmDialog
}) => {

  useEffect(() => {
    getConditions();
  }, [getConditions]);

  const [showDiscountStatus, setShowDiscountStatus] = useState(false);

  const onSubmit = () => {
    const canUseDiscount = paymentMethod === 'V' && discountStatus !== 'Aguardando aprovação';
    
    if (canUseDiscount && total < totalInstallment) {
      return savePayment({
        installmentQuantity: 1,
        type: 'MA',
        paymentId: 16,
        checkoutId,
        installmentValue: total,
        total,
      });
    }
    
    return savePayment({
      installmentQuantity: 1,
      paymentId: 16,
      type: 'MA',
      checkoutId,
      installmentValue: totalInstallment,
      total: totalInstallment,
    });
  };

  const handleCashDiscount = async () => { 
    const canUseDiscount = paymentMethod === 'V' && discountStatus !== 'Aguardando aprovação';


    if (await checkDiscountOffer()) {
      if (canUseDiscount && total < totalInstallment) {
        savePayment({
          installmentQuantity: 1,
          type: 'MA',
          paymentId: 16,
          checkoutId,
          installmentValue: total,
          total,
        });
      } else {
        savePayment({
          installmentQuantity: 1,
          paymentId: 16,
          type: 'MA',
          checkoutId,
          installmentValue: totalInstallment,
          total: totalInstallment,
        });
      }
      return openConfirmDialog();
    }

    return false;
  }

  const paymentOptions = () => {
    const { discountCart } = checkout
    if(discountCart === 0) {
      return [
        { id: 'P', name: 'Valor à prazo' },
      ]
    } 
    return [
      { id: 'P', name: 'Valor à prazo' },
      { id: 'V', name: 'Valor à vista' },
    ]
  }

  const hasCashDiscount = paymentMethod === "V" && checkout.discountCart > 0 && checkout.discountCart < 10.2
  
  const checkDiscountStatus = async () => { 
    if(await checkDiscountOffer() || discountStatus === 'Aguardando aprovação') return setShowDiscountStatus(true)
    return setShowDiscountStatus(false) 
  }

  return (
    <Box p={2}>
      <form onSubmit={hasCashDiscount ? handleSubmit(handleCashDiscount) : handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item container sx={12} md={6} justify="center" />

          <Grid container item xs={12} spacing={2} md={6}>
            <Grid item xs={12}>
              <Box display="flex" width="100%">
                <Field
                  name="paymentMethod"
                  component={SelectRender}
                  label="Forma de pagamento"
                  validate={required}
                  options={paymentOptions()}
                  disabled={loading || !isNew || paymentOptions().length === 1}
                  onChange={() => { checkDiscountStatus() }}
                /> 
              </Box>
            </Grid>

            <Grid item xs={12}>
              {isNew && (
                <Button
                  variant="outlined"
                  type="submit"
                  fullWidth
                  disabled={
                    loadingConditions
                    || loading
                    || (paymentMethod === 'V' && discountStatus === 'Aguardando aprovação')
                  }
                >
                  Realizar Pagamento
                </Button>
              )}

              {paymentMethod === 'V' && (
                <Box color="#666" mt={1}>
                  <Typography variant="body2" color="inherit">
                    {showDiscountStatus && `Status de aprovação: ${discountStatus}`}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>

      <CashierDialog />
    </Box>
  );
};

Cashier.defaultProps = {
  paymentMethod: '',
  discountStatus: '',
};

Cashier.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  getConditions: PropTypes.func.isRequired,
  loadingConditions: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  checkoutId: PropTypes.number.isRequired,
  savePayment: PropTypes.func.isRequired,
  setCashierPayment: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
  totalInstallment: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  total: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  discountStatus: PropTypes.string,
};

const selector = formValueSelector('checkout/cashier');

export const mapState = (state) => {
  const { checkoutId, resume, payments } = state.main.checkout.geral.checkout;
  const { loading } = state.main.checkout.geral;
  const {
    loading: loadingPayment,
    loadingConditions,
  } = state.main.checkout.payment;

  const payment = payments.find((item) => (
    item.type === PAYMENT_TYPE.CAIXA
  ));

  let paymentMethod = 'P';
  if (payment) {
    paymentMethod = payment.installmentValue === resume.totalInstallment ? 'P' : 'V';
  }

  return ({
    checkoutId,
    loading: loading || loadingPayment,
    loadingConditions,
    isNew: !payment,
    paymentId: payment && payment.paymentId,
    paymentMethod: selector(state, 'paymentMethod'),
    totalInstallment: resume.remainderInstallment,
    total: resume.remainder,
    discountStatus: resume.discountStatus,
    initialValues: {
      paymentMethod,
    },
  });
};

export default connect(mapState, {
  savePayment: savePaymentAction,
  removePayment: removePaymentAction,
  savePaymentDiscount: savePaymentDiscountAction,
  setCashierPayment: setCashierPaymentAction,
  getConditions: getConditionsAction,
  checkDiscountOffer: checkDiscountOfferAction,
  openConfirmDialog: openConfirmDialogAction, 
})(reduxForm({
  form: 'checkout/cashier',
})(Cashier));
