const makePayment = (checkoutId, form, sellerName, customer) => {
  switch (form.type) {
    case 'B': 
      return {
        paymentId: 12,
        type: 'B',
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
    case 'PX':
      return {
        paymentId: 33,
        type: 'PX',
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
        codCliente: customer.customerId,
      };
    case 'BP':
      return {
        paymentId: 20,
        type: 'BP',
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        paymentTermId: form.paymentTermId,
        choosedPaymentTerm: '',
        total: form.total,
        hasDiscount: [1].includes(form.paymentTermId) && form.hasDiscount === 'V',
      };
    case 'DN':
      return {
        type: 'DN',
        paymentId: 25,
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
    case 'DC':
      return {
        type: 'DC',
        paymentId: 24,
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
    case 'LP':
      return {
        type: 'LP',
        paymentId: 34,
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
    case 'ML':
      return {
        type: 'ML',
        paymentId: 36,
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
    case 'JS':
      return {
        type: 'JS',
        paymentId: 35,
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
    case 'B2':
      return {
        type: 'B2',
        paymentId: 37,
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
    case 'AZ':
      return {
        type: 'AZ',
        paymentId: 38,
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
      case 'MZ':
        return {
          type: 'MZ',
          paymentId: 39,
          checkoutId,
          installmentQuantity: 1,
          installmentValue: form.total,
          total: form.total,
        };
    case 'CS':
      return {
        type: 'CS',
        paymentId: 27,
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
    case 'FN':
      return {
        type: 'FN',
        paymentId: 28,
        checkoutId,
        installmentQuantity: 1,
        installmentValue: form.total,
        total: form.total,
      };
    case 'CC':
      // eslint-disable-next-line no-throw-literal
      if (!form.brand) throw { message: 'Bandeira de cartão inválida!' };
      return {
        paymentId: 5,
        type: 'CC',
        checkoutId,
        installmentQuantity: +form.installments,
        installmentValue: +form.total / +form.installments,
        total: +form.total,
        strN: form.number,
        strS: '50',
        strDg: form.cod,
        strDc: form.cpfCnpj,
        strNm: form.name,
        strTl: form.phone,
        strVL: form.cardValidate,
        codBandeira: form.brand.toUpperCase(),
        nomeVendedor: sellerName,
      };
    default:
      // eslint-disable-next-line no-throw-literal
      throw { message: 'Não encontramos esse tipo de pagamento' };
  }
};

export default makePayment;
