import { makeStyles, Switch, withStyles } from '@material-ui/core';
import {
  BoxesIcon,
  DeliveryIcon,
  ExpressIcon,
  LikeIcon,
  StoreIcon,
} from '../../../../../assets/algolia';

const useStyles = makeStyles(() => ({
  toggleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    padding: '14px 16px',
    margin: 0,
    fontSize: 16,
    lineHeight: '21px',
    color: '#212529',

    '&.borderTop': {
      borderTop: '1px solid #CFCFD4',
    },
  },
  contentToggle: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,

    '& .withdrawStore': {
      background: `url(${BoxesIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 16,
      height: 16,
    },
    '& .storeText': {
      color: '#FE5000',
      fontWeight: 600,
    },
    '& .expressDelivery': {
      background: `url(${ExpressIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 101,
      height: 21,
    },
    '& .freeText': {
      color: '#1D992E',
      fontWeight: 600,
    },
    '& .delivery': {
      background: `url(${DeliveryIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 22,
      height: 22,
    },
    '& .blackFridayText': {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    '& .store': {
      background: `url(${StoreIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 119,
      height: 16,
    },
    '& .indicateText': {
      fontSize: 14,
      lineHeight: '12px',
    },
    '& .like': {
      background: `url(${LikeIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 15,
      height: 17,
    },
  },
}));

export const SwitchStyled = withStyles(() => ({
  root: {
    width: 45,
    height: 23,
    padding: 0,
    borderRadius: 100,
  },
  switchBase: {
    padding: 0,
    top: 2,
    left: 2,
  },
  thumb: {
    width: 19,
    height: 19,
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
  },
  track: {
    backgroundColor: '#868686',
  },
  checked: {
    transform: 'translateX(22px) !important',
    color: '#FE5000 !important',
    '& + $track': {
      backgroundColor: '#FE5000 !important',
      opacity: '1 !important',
    },
  },
  disabled: {
    '& + $track': {
      opacity: '0.05 !important',
    },
  },
}))(Switch);

export default useStyles;
