/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Link, Divider, Box,
} from '@material-ui/core';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import {
  useParams,
} from 'react-router-dom';
// import { ReactComponent as UsersIcon } from '../../../assets/users.svg';

import useStyles from './style';

const CardBarSale = ({
  flat,
}) => {
  const classes = useStyles();
  const {
    idsupervisor, idnewuser, idbusinessunit, idoffice, idsaleschannel, idhomeSales, iduserInativos,
    idparametrizacao,
  } = useParams();
  return (
    <header>
      <Box className={classes.appBar}>
        <Link color="inherit" href="/homeSales/true" className={idhomeSales ? classes.linkPress : classes.link}>
          <HomeOutlinedIcon className={classes.icon} />
          <span className={classes.spanLink}>
            Home
          </span>
        </Link>
        <span>|</span>
        <Link color="inherit" href="/homeSales/newuser/true" className={idnewuser ? classes.linkPress : classes.link}>
          <PeopleAltOutlinedIcon className={classes.icon} />
          <span className={classes.spanLink}>
            Usuários Ativos
          </span>
        </Link>
        <span>|</span>
        {/*<Link color="inherit" href="/homeSales/supervisor/true" className={idsupervisor ? classes.linkPress : classes.link}>*/}
        {/*  <PeopleAltOutlinedIcon className={classes.icon} />*/}
        {/*  <span className={classes.spanLink}>*/}
        {/*    Supervisor*/}
        {/*  </span>*/}
        {/*</Link>*/}
        {/*<span>|</span>*/}
        <Link color="inherit" href="/homeSales/businessunit/true" className={idbusinessunit ? classes.linkPress : classes.link}>
          <PeopleAltOutlinedIcon className={classes.icon} />
          <span className={classes.spanLink}>
            Unidade de Negócios
          </span>
        </Link>
        <span>|</span>
        <Link color="inherit" href="/homeSales/saleschannel/true" className={idsaleschannel ? classes.linkPress : classes.link}>
          <PeopleAltOutlinedIcon className={classes.icon} />
          <span className={classes.spanLink}>
            Canal de Vendas
          </span>
        </Link>
        <span>|</span>
        <Link color="inherit" href="/homeSales/office/true" className={idoffice ? classes.linkPress : classes.link}>
          <PeopleAltOutlinedIcon className={classes.icon} />
          <span className={classes.spanLink}>
            Cargo
          </span>
        </Link>
        <span>|</span>
        <Link color="inherit" href="/homeSales/userInativos/true" className={iduserInativos ? classes.linkPress : classes.link}>
          <PeopleAltOutlinedIcon className={classes.icon} />
          <span className={classes.spanLink}>
            Usuários Inativos
          </span>
        </Link>
        <span>|</span>
        <Link color="inherit" href="/homeSales/parametrizacao/true" className={idparametrizacao ? classes.linkPress : classes.link}>
          <PeopleAltOutlinedIcon className={classes.icon} />
          <span className={classes.spanLink}>
            Parametrização
          </span>
        </Link>
      </Box>

      {!flat && <Divider />}
    </header>
  );
};

CardBarSale.propTypes = {
  flat: PropTypes.bool,
};

CardBarSale.defaultProps = {
  flat: false,
};

export default CardBarSale;
