import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { TableHead, TableRow, TableCell, TableBody, TablePagination, LinearProgress, Box, Chip, Typography, Card, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import { getListDiscountAction, openAction } from './actions';

import CardBar from '../../../shared/components/cardBar';
import ResponsiveTable from '../../../shared/components/responsiveTable';
import { toMoney, toPercent } from '../../../shared/utils/parsers';

import DialogDetails from './modal/DialogDetails';
import { getDiscountDetails } from './repository';
import useStyles from './styles';
import FilterDiscount from './filterDiscount/FilterDiscount';
import moment from 'moment';
import { FilterList } from '@material-ui/icons';
import { is } from 'date-fns/locale';

const ListDiscountPage = ({ getListDiscount, open, loading, discounts, currentPage, idUser, count, rpp, loadingPDF, user }) => {
	const classes = useStyles();
	// const handleChangePage = (_, page) => {
	// 	if (!loading) getListDiscount({ page: page + 1, idUser, rpp });
	// };

	// const handleChangeRpp = ({ target }) => {
	// 	if (!loading) getListDiscount({ page: 1, idUser, rpp: target.value });
	// };

	const [modalOrder, setModalOrder] = useState({});

	const [modal, setModal] = useState(false);

	const [filterDataInicio, setFilterDataInicio] = useState(moment(new Date()).subtract(1, 'month').format('DD/MM/YYYY'));
	const [filterDataFim, setFilterDataFim] = useState(moment(new Date()).format('DD/MM/YYYY'));

	const [filterOpen, setFilterOpen] = useState(false);

	const openModal = item => {
		getDiscountDetails(item.descontoId, idUser).then(data => {
			const { content } = data;
			setModalOrder(content);
			setModal(true);
		});
	};

	const handleCloseModal = () => {
		setModal(false);

		setTimeout(() => {
			setModalOrder({});
		}, 200);
	};

	const handleUrl = url => window.open(`${window.location.origin}/checkout/${url}`, '_blank');

	const handleFilter = (dataInicio, dataFim) => {
		getListDiscount({ dataInicio, dataFim, page: 1, idUser, rpp: 10 });
	}

	const handleFilterOpen = () => setFilterOpen(!filterOpen);
  const { breakpoints } = useTheme();
	const isMobile = useMediaQuery(breakpoints.down('xs'));

	useEffect(() => {
		if (idUser != null) {
			const discountPermission = user.permissoes.find(permission => permission.id === 8);
			if (!discountPermission) {
				window.location.href = process.env.REACT_APP_HOST_CATALAGO;
			} else {
				getListDiscount({ page: 1, idUser, rpp: 10 });
			}
		}
	}, [getListDiscount, idUser]);


	return (
		<Box className={classes.container}>
			<CardBar title="Aprovação de Descontos">
				{/* <TablePagination
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={count}
					rowsPerPage={rpp}
					page={currentPage - 1}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRpp}
					labelRowsPerPage="Registros:"
				/> */}
				<IconButton onClick={handleFilterOpen}>
          <FilterList />
        </IconButton>
			</CardBar>

			<FilterDiscount
				open={filterOpen}
				handleFilter={handleFilter}
				onSubmit={() => console.log('submit')}
				setFilterDataInicio={setFilterDataInicio}
				setFilterDataFim={setFilterDataFim}
				filterDataInicio={filterDataInicio}
				filterDataFim={filterDataFim}
				loading={false}
			/>

			{loading && <LinearProgress />}
			{loadingPDF && <LinearProgress />}

			{!loading && (
				<ResponsiveTable striped>
					<TableHead>
						<TableRow
							className={classes.tableHeadRow}
							classes={{
								root: classes.oddRow,
							}}
						>
							<TableCell className={classes.tableHeadCell} component="th">
								Orçamento
							</TableCell>
							<TableCell className={classes.tableHeadCell} component="th">
								Cliente
							</TableCell>
							<TableCell className={classes.tableHeadCell} component="th">
								Vendedor
							</TableCell>
							<TableCell className={classes.tableHeadCell} component="th">
								Valor
							</TableCell>
							<TableCell className={classes.tableHeadCell} component="th">
								Margem
							</TableCell>
							<TableCell className={classes.tableHeadCell} component="th">
								Desconto
							</TableCell>
							<TableCell className={classes.tableHeadCell} component="th">
								Data da Solicitação
							</TableCell>
							<TableCell className={classes.tableHeadCell} component="th">
								Detalhes
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>

					<TableBody>
						{loading && (
							<TableRow>
								<TableCell colSpan={10} datatype="Mensagem" className="message">
									Buscando solicitações de desconto...
								</TableCell>
							</TableRow>
						)}

						{!loading && !discounts.length && (
							<TableRow>
								<TableCell colSpan={10} datatype="Mensagem" className="message">
									Nenhuma solicitação foi encontrada
								</TableCell>
							</TableRow>
						)}

						{discounts.map((item, index) => (
							<TableRow className={classes.tableBodyRow} key={`row-${index + 1}`}>
								<TableCell datatype="Orçamento">
									<Typography variant="subtitle2" className={classes.tableBodyCellClickable}>
										<Box
											onClick={() => handleUrl(item.checkoutId)}
											component="span"
											display="flex"
											alignItems="center"
											justifyContent="center"
											gridGap={6}
										>
											{item.checkoutId}
											<Icon sx={{ fontSize: 7 }} fontSize="inherit">
												open_in_new
											</Icon>
										</Box>
									</Typography>
								</TableCell>

								<TableCell datatype="Cliente" className={classes.customerCell}>
									<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
										{item.nome}
									</Box>
								</TableCell>

								<TableCell className={classes.tableBodyCell} datatype="Assistente">
									<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
										{item.nomeVendedor}
									</Box>
								</TableCell>

								<TableCell className={classes.tableBodyCell} datatype="Valor">
									<Box whiteSpace="nowrap" component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
										{toMoney(item.valor)}
									</Box>
								</TableCell>

								<TableCell className={classes.tableBodyCell} datatype="Margem">
									<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
										{item.margem}
									</Box>
								</TableCell>

								<TableCell className={classes.tableBodyCell} datatype="Desconto">
									<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
										{isMobile ? (
											<Typography variant="subtitle2" className={classes.percentageDiscount}>{toPercent(item.descontoSolicitado)}</Typography>
										) : (
											<Chip label={toPercent(item.descontoSolicitado)} color="primary" />
										)}
									</Box>
								</TableCell>

								<TableCell className={classes.tableBodyCell} datatype="Data">
									<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
										{item.dataSolicitacao}
									</Box>
								</TableCell>

								<TableCell datatype="Ações">
									<Box className={classes.actions}>
										<Tooltip title="Ver detalhes do desconto">
											<IconButton
												disabled={loadingPDF}
												onClick={e => {
													e.stopPropagation();
													openModal(item);
												}}
												sx={{ color: '#000000' }}
											>
												<Icon>content_paste_search_outlined</Icon>
											</IconButton>
										</Tooltip>
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</ResponsiveTable>
			)}
			<DialogDetails
				dataInicio={filterDataInicio}
				dataFim={filterDataFim}
				discount={modalOrder}
				open={modal}
				close={handleCloseModal}
			/>
		</Box>
	);
};

ListDiscountPage.propTypes = {
	getListDiscount: PropTypes.func.isRequired,
	open: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	discounts: PropTypes.arrayOf(Object).isRequired,
	currentPage: PropTypes.number.isRequired,
	idUser: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	rpp: PropTypes.number.isRequired,
	loadingPDF: PropTypes.bool.isRequired,
	user: PropTypes.instanceOf(Object).isRequired,
};

const mapState = state => ({
	...state.main.discount.list,
	...state.main.sales,
	idUser: state.auth.login.user.idVendedor,
	drawerOpenned: state.main.app.drawer,
	user: state.auth.login.user,
	loadingPDF: state.main.discount.list.loadings.pdf,
});

export default connect(mapState, {
	getListDiscount: getListDiscountAction,
	open: openAction,
})(ListDiscountPage);
