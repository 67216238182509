import { makeStyles } from '@material-ui/core';
import { AddIcon, AddLightIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(() => ({
  addProduct: {
    padding: '0px 8px 0 3px',
    backgroundColor: '#FEBD01',
    width: 89,
    height: 32,
    '&:hover': {
      backgroundColor: '#FEBD01',
    },
    '&.localFilial': {
      backgroundColor: '#008000',
      color: 'white',
    },
  },
  addProductBox: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  addIcon: {
    width: 24,
    height: 24,
    backgroundImage: `url(${AddIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    '&:after': {
      content: '""',
      borderRight: '1px solid #242424',
      left: 15,
      position: 'relative',
    },

    '&.localFilial': {
      backgroundImage: `url(${AddLightIcon})`,
      '&:after': {
        borderColor: '#FFFFFF',
      },
    },
  },
  stockQty: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 600,
  },
  unity: {
    fontSize: 10,
    lineHeight: '10px',
    textTransform: 'lowercase',
  },
  stockBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default useStyles;
