import React, { useEffect, useRef, useState } from 'react';
import {
  useClearRefinements,
  useHierarchicalMenu,
  useSearchBox,
  useStats,
} from 'react-instantsearch';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper, Popper } from '@material-ui/core';
import useStyles from './styles';
import { resetSearchAction, searchByCategoryAction } from '../../../../main/checkout/actions';
import { Hits } from '../index';

const SearchBox = ({
  resetSearch: isResetSearch,
  resetSearchAction: resetSearch,
  searchByCategory,
  inputValue,
  categoryFilter,
  categoryList,
  user,
  client,
  disabled,
  isCheckout,
  facets,
}, props) => {
  const classes = useStyles();
  const { query, refine } = useSearchBox(props);
  const { query: queryStats } = useStats();
  const { refine: refineCategory } = useHierarchicalMenu({
    attributes: ['categoryLevels.lvl0', 'categoryLevels.lvl1', 'categoryLevels.lvl2'],
  });
  const { canRefine, refine: clearFilters } = useClearRefinements();
  const { nbHits } = useStats();

  const [value, setValue] = useState(query);
  const [hasHits, setHasHits] = useState(false);

  const inputRef = useRef(null);

  const setQuery = (newQuery) => {
    setValue(newQuery);
    refine(newQuery);
  };

  const handleSearchBox = () => {
    setQuery(inputValue || '');

    if (inputRef.current) {
      inputRef.current.focus();
    }
    resetSearch(false);
  };

  const handleClearRefinements = () => {
    if (categoryList) {
      searchByCategory('', null);
    }
    clearFilters();
  };

  useEffect(() => {
    if ((value === queryStats) && categoryFilter) {
      if (categoryList.detail) {
        refineCategory(categoryFilter);
        searchByCategory(null, categoryList);
      }
    }
  }, [value, queryStats, categoryFilter, categoryList]);

  useEffect(() => {
    if (isResetSearch) {
      handleSearchBox();
    } else if ((value === queryStats) && categoryFilter) {
      if (categoryList.detail) {
        refineCategory(categoryFilter);
        searchByCategory('', categoryList);
      }
    }
  }, [isResetSearch]);

  useEffect(() => {
    setHasHits(!!nbHits);
  }, [nbHits]);

  return (
    <div className={classes.searchBoxContainer}>
      <form
        action=""
        role="search"
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        className={classes.searchBox}
      >
        <input
          ref={inputRef}
          placeholder="O que você procura..."
          type="search"
          value={value}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
            if (canRefine) {
              handleClearRefinements();
            }
          }}
          className={classes.searchInput}
          disabled={disabled}
        />
        <button aria-label="search product" type="submit" className={classes.searchButton} />
      </form>
      <Popper
        open={hasHits}
        className={classes.hitsPopper}
      >
        <Paper
          className={classes.hitsPaper}
        >
          <Hits
            user={user}
            customer={client}
            hasHits={hasHits}
            isCheckout={isCheckout}
            facets={facets}
            hasFilters
          />
        </Paper>
      </Popper>
    </div>
  );
};

SearchBox.defaultProps = {
  disabled: false,
  isCheckout: false,
};

SearchBox.propTypes = {
  resetSearch: PropTypes.bool.isRequired,
  resetSearchAction: PropTypes.func.isRequired,
  searchByCategory: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  categoryFilter: PropTypes.string.isRequired,
  categoryList: PropTypes.instanceOf(Array).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  disabled: PropTypes.bool,
  isCheckout: PropTypes.bool,
  facets: PropTypes.instanceOf(Array).isRequired,
};

const mapState = (state) => {
  const {
    resetSearch,
    inputSearch,
    category,
    categoryHierarchy,
  } = state.main.checkout.geral;

  return ({
    resetSearch,
    inputValue: inputSearch,
    categoryFilter: category,
    categoryList: categoryHierarchy,
    user: state.auth.login.user,
    client: state.authClient.client,
  });
};

export default connect(mapState, {
  resetSearchAction,
  searchByCategory: searchByCategoryAction,
})(SearchBox);
