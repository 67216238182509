import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  radioBox: {
    '& input[type=radio]': {
      appearance: 'none',
      width: 14,
      height: 14,
      border: '2px solid #909599',
      borderRadius: '100%',
      cursor: 'pointer',
      marginRight: 11,
      position: 'relative',
      top: 2,
    },
    '& input[type=radio]:checked': {
      appearance: 'none',
      width: 6,
      height: 6,
      background: '#FE5000',
      borderRadius: '100%',
      position: 'relative',
      top: 6,
      left: 4,
      marginRight: 19,
      border: 0,

      '&:after': {
        content: '""',
        position: 'relative',
        width: 14,
        height: 14,
        border: '2px solid #FE5000',
        borderRadius: '100%',
        top: -4,
        left: -4,
        cursor: 'pointer',
        display: 'inline-block',
      },
    },
  },
}));

export default useStyles;
