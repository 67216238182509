/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  change,
  Field,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { IconButton, Button, DialogActions } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import axios from 'axios';
import useStyles from '../../styles';
import { InputCurrencyRender, SelectRender } from '../../../../shared/fields';
import { required } from '../../../../shared/fields/validate';
import { searchInstallmentsAction } from './actions';

const ManualPayment = ({
  opened, close, value, paymentForm, checkoutId, installments,
  remainder, remainderInstallment, dispatch, searchInstallments, listInstallments,
  isLoadingInstallment, checkout, user,
}) => {
  const classes = useStyles();
  const installmentProps = listInstallments.find((i) => i.quantity === installments) || {};
  const [returnMaquininha, setReturnMaquininha] = useState('');
  const [loadingTef, setLoadingTef] = useState(false);
  const { REACT_APP_TOKEN } = process.env;

  useEffect(() => {
    dispatch(change('payment/creditCardQrCode', 'value', remainderInstallment));
    if (remainder <= 0.01 && remainderInstallment <= 0.01) {
      setReturnMaquininha('');
      close();
    }
  }, [remainder, remainderInstallment, dispatch, opened]);

  const handleCancelAllTef = async () => {
    await axios.post(`https://hub.lojadomecanico.com.br/tef-api/api/tef/cancel/machine/${user.idVendedor}/all`, {}, {
      headers: {
        'x-access-token': `Bearer ${REACT_APP_TOKEN}`,
      },
    }).then((response) => response).catch((error) => error);
  };

  const handleTef = async () => {
    if (opened) {
      setLoadingTef(true);

      await handleCancelAllTef();

      try {
        const response = await axios.get(`https://hub.lojadomecanico.com.br/tef-api/api/tef/${user.idVendedor}`, {
          headers: {
            'x-access-token': `Bearer ${REACT_APP_TOKEN}`,
          },
        });

        if (response.data.máquina !== 'Sem máquina vinculada') {
          const returnPost = await axios.post('https://hub.lojadomecanico.com.br/tef-api/api/tef/sale', {
            caixaId: user.idVendedor.toString(),
            checkoutId: checkoutId.toString(),
            valor: paymentForm === 'C' && installmentProps.quantity === 1
              ? installmentProps.value
              : value,
            installments: installments.toString(),
            paymentFor: paymentForm,
          },
          {
            headers: { 'x-access-token': `Bearer ${REACT_APP_TOKEN}` },
          }).catch((error) => console.log(error));
          if (returnPost.data) {
            setReturnMaquininha('Pagamento inserido com sucesso!');
          }
          setLoadingTef(false);
        } else {
          setReturnMaquininha(response.data.máquina);
          setLoadingTef(false);
        }
      } catch (error) {
        setLoadingTef(false);
        setReturnMaquininha('Erro ao Registrar Tef');
      }
    }
  };

  useEffect(() => {
    if (opened) {
      searchInstallments(
        remainderInstallment,
        checkout.discountCart,
        checkout.resume.remainder,
        checkout.resume.remainderInstallment,
      );
    }
  }, [
    dispatch, checkout.resume.remainder, checkout.resume.remainderInstallment,
    searchInstallments, remainderInstallment, checkout.discountCart, opened,
  ]);

  useEffect(() => {
    if (returnMaquininha) {
      setTimeout(() => {
        setReturnMaquininha('');
      }, 3000);
    }
  }, [returnMaquininha]);

  const paymentOptions = [{
    value: 'C',
    name: 'Crédito',
  }, {
    value: 'D',
    name: 'Debito',
  }];

  const handlePaymentForm = (inputValue) => {
    dispatch(change(
      'payment/creditCardQrCode', 'value', inputValue !== 'C'
        ? remainder
        : remainderInstallment,
    ));
    dispatch(change(
      'payment/creditCardQrCode', 'installments', inputValue !== 'C'
        ? 1
        : installments,
    ));
    searchInstallments(
      inputValue !== 'C'
        ? remainder
        : remainderInstallment,
      checkout.discountCart,
      checkout.resume.remainder,
      checkout.resume.remainderInstallment,
    );
  };

  const handlePaymentValue = (inputValue) => {
    dispatch(change('payment/creditCardQrCode', 'installments', 1));
    searchInstallments(
      inputValue,
      checkout.discountCart,
      checkout.resume.remainder,
      checkout.resume.remainderInstallment,
    );
  };

  const closeModal = () => {
    setReturnMaquininha('');
    close();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={opened}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
    >
      <Box position="relative">
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>

        <form>
          <DialogTitle id="form-dialog-title">
            Registrar TEF
          </DialogTitle>

          <DialogContent>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="paymentForm"
                  label="Forma de Pagamento *"
                  options={paymentOptions}
                  component={SelectRender}
                  validate={[required]}
                  model={{
                    id: 'value',
                    name: 'name',
                  }}
                  onChange={(_, v) => handlePaymentForm(v)}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="value"
                  component={InputCurrencyRender}
                  label="Valor *"
                  validate={required}
                  onChange={(v) => handlePaymentValue(v)}
                  max={
                    paymentForm !== 'C'
                      ? remainder
                      : remainderInstallment
                  }
                />
              </Grid>
              {isLoadingInstallment && 'Carregando...'}
              {paymentForm === 'C' && (
                <Grid item xs={12}>
                  <Field
                    name="installments"
                    label="Parcelamento *"
                    options={listInstallments}
                    component={SelectRender}
                    validate={[required]}
                    model={{
                      id: 'quantity',
                      name: 'label',
                    }}
                  />
                </Grid>
              )}
            </Grid>

            <Box pb={2} />
          </DialogContent>
          <DialogActions
            classes={{ root: classes.manualPaymentDialogActions }}
          >
            <Box left={0}>
              <span style={{ fontSize: 14, color: '#D45E23' }}>{returnMaquininha}</span>
            </Box>
            <Button
              className={classes.buttonTef}
              onClick={handleTef}
              disabled={loadingTef}
            >
              ACIONAR MÁQUININHA
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

ManualPayment.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  close: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  installments: PropTypes.number,
  paymentForm: PropTypes.string,
  checkout: PropTypes.instanceOf(Object).isRequired,
  checkoutId: PropTypes.number.isRequired,
  remainder: PropTypes.number.isRequired,
  remainderInstallment: PropTypes.number.isRequired,
  searchInstallments: PropTypes.func.isRequired,
  listInstallments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isLoadingInstallment: PropTypes.bool.isRequired,
};

ManualPayment.defaultProps = {
  value: null,
  installments: null,
  paymentForm: null,
};

const seletor = formValueSelector('payment/creditCardQrCode');
const mapState = (state) => {
  const { config } = state.main.app;
  const { checkout } = state.main.checkout.geral;
  const { resume, checkoutId, discountCart } = checkout;
  const { payment } = state.main.checkout;
  const { customer: { customerId, name } } = checkout;
  const { customer: { address: { zipCode } } } = checkout;
  return {
    user: state.auth.login.user,
    checkoutId,
    customerId,
    zipCode,
    name,
    checkout,
    discountCart,
    ...payment,
    pixEnabled: !!config.bitPix,
    remainder: +resume.remainder,
    remainderInstallment: +resume.remainderInstallment,
    installments: seletor(state, 'installments'),
    value: seletor(state, 'value'),
    paymentForm: seletor(state, 'paymentForm'),
    initialValues: {
      value: resume.remainderInstallment,
      installments: 1,
      paymentForm: 'C',
    },
  };
};

export default connect(mapState, {
  searchInstallments: searchInstallmentsAction,
})(reduxForm({
  form: 'payment/creditCardQrCode',
  enableReinitialize: true,
})(ManualPayment));
