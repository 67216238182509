import { handleActions, combineActions } from 'redux-actions';
import LoginTypes from './types';
import { getUser } from '../../shared/services/auth';

const INITIAL_STATE = {
  user: getUser(),
  page: 0,
  isLoading: false,
  hasException: null,
};

const stopLoading = combineActions(
  LoginTypes.CHECK_LOGIN_SUCCESS,
  LoginTypes.CHECK_LOGIN_FAILURE,
  LoginTypes.SINGIN_FAILURE,
  LoginTypes.SINGIN_SUCCESS,
);

const startLoading = combineActions(
  LoginTypes.CHECK_LOGIN_REQUEST,
  LoginTypes.SINGIN_REQUEST,
);

export default handleActions({
  [stopLoading]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [startLoading]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [LoginTypes.CHECK_LOGIN_SUCCESS]: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      page: 0,
      user,
    };
  },
  [LoginTypes.SET_PAGE]: (state, action) => {
    const { page } = action.payload;
    return {
      ...state,
      page,
    };
  },
  [LoginTypes.SINGIN_SUCCESS]: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
      page: 0,
    };
  },
  [LoginTypes.LOGOUT]: (state) => ({
    ...state,
    user: null,
    isLoading: false,
  }),
}, INITIAL_STATE);
