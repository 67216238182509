import { makeStyles } from '@material-ui/core';
import loadcar from '../../../../assets/loadcar.svg';

const useStyles = makeStyles(({ breakpoints }) => ({
  svgLogo: {
    display: 'flex',
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  svgLogoImg: {
    width: 32,
    height: 24,
    animation: 'zoom-in-zoom-out 1s ease infinite',
    maskImage: `url(${loadcar})`,
    maskSize: 32,
    backgroundColor: '#FE5000',
  },
  svgLogoBorder: {
    content: ' ',
    display: 'block',
    position: 'absolute',
    width: 60,
    height: 60,
    margin: 1,
    borderRadius: '50%',
    border: '3px solid #FE5000',
    borderColor: '#FE5000 transparent #FE5000 transparent',
    animation: 'borda-loading 1.2s linear infinite',
    top: -18,
  },
  loadCar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 9999,
    [breakpoints.down('sm')]: {
      left: '43%',
    },
  },
  label: {
    marginTop: 25,
    color: '#FE5000',
    textSlign: 'center',
    lineHeight: 1.5,
    fontSize: 14,
    fontWeight: 600,
  },
  backdrop: {
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
