import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  guaranteeActions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: 0,

    '& .MuiDialogActions-spacing > :not(:first-child)': {
      margin: 0,
    },

    '& .MuiDialogActions-spacing': {
      margin: 0,
    },

    '& .MuiButton-contained': {
      height: 48,
      borderRadius: 8,
    },

    '& .MuiFormGroup-root': {
      margin: '16px 0',
      width: '100%',
      border: '1px solid #CFCFD4',
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
      borderBottomLeftRadius: '20px',

      '& .MuiFormControlLabel-root': {
        paddingLeft: 12,
        margin: '0!important',
        height: 48,

        '&:first-child': {
          borderTopRightRadius: '20px',
          borderBottom: '1px solid #CFCFD4',
        },

        '&:last-child': {
          borderBottomRightRadius: '20px',
          borderBottomLeftRadius: '20px',
          borderTop: '1px solid #CFCFD4',
        },

        '&:nth-child(odd)': {
          background: '#F6F6F7',
        }
      }
    }
  },

  selectGuarantee: {
    '& .MuiFormControlLabel-label': {
      width: '100%',
      color: '#7B7F82',
      paddingRight: 24,

      '& .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
      },

      '& .MuiTypography-body1': {
        fontSize: 12,
        fontWeight: 500,
      },

      '& .MuiTypography-body2': {
        color: '#242424',
        fontWeight: 700,
        fontSize: 16,
      }
    }
  },

  selectedGuarantee: {
    '& .MuiRadio-colorSecondary.Mui-checked .MuiIconButton-label': {
      color: '#FF5225',
    },
    '& .MuiFormControlLabel-label .MuiTypography-root': {
      color: '#FF5225',
      fontWeight: 700,
    }
  },

  guaranteeRecomendation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '-24px',

    '& .MuiTypography-root': {
      fontSize: 12,
      color: '#FFF',
      fontWeight: 600,
      padding: '8px 60px',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      background: '#FF5225',
    },
  },
  guaranteeRecomendationTriangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 15px 8px 15px;',
    borderColor: 'transparent transparent #FF5225 transparent',
    transform: 'rotate(180deg)',
  },
}));

export default useStyles;
