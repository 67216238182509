import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import PropTypes from 'prop-types';
import MainRouter from './Router';
import MainDrawer from './_components/mainDrawer';
import { MainAppBar, MainAppBarSearch, MainAppBarSearchAlgolia } from './_components/mainAppBar';
import useStyles from './styles';
import { goBack, goForward } from '../history';
import { InstantSearch } from '../shared/components/algolia';
import { resetSearchAction } from './checkout/actions';
import SubheaderFilters from './_components/subheaderFilters';

const Page = ({ user, resetSearch }) => {
  const [className, setClassName] = useState('');
  const classes = useStyles();
  const param = window.location.pathname.split('/');
  useHotkeys('alt+v', goBack);
  useHotkeys('alt+p', goForward);
  const hideNavigation = window.location.pathname === '/suggestion';

  const routes = [
    'client-space',
    'client-login',
    'checkout',
    'register',
  ];

  const isHomePage = param.every((path) => path === '') || param[1] === 'client-space';
  const showAlgolia = routes.some((route) => param[1] === route) || isHomePage;
  const isCheckout = !!param[2] && param[1] === 'checkout';

  useEffect(() => {
    resetSearch(true, '');
  }, [isCheckout]);

  useEffect(() => {
    let styles = classes.root;
    if (isCheckout) {
      styles = classes.rootCheckout;
    } else if (isHomePage) {
      styles += ' homePage';
    }

    setClassName(styles);
  }, [param]);

  return (
    <section className={className}>
      {showAlgolia
        ? (
          <InstantSearch>
            {
              user.bitAlgolia ? (
                <>
                  <MainAppBarSearchAlgolia
                    hideNavigation={hideNavigation}
                    isCheckout={isCheckout}
                  />
                  {/* { !isCheckout && (<SubheaderFilters />) } */}
                </>
              ) : (
                <MainAppBarSearch hideNavigation={hideNavigation} />
              )
            }
            <MainDrawer route={param[1]} hideNavigation={hideNavigation} isCheckoutScreen />
            <main>
              <MainRouter />
            </main>
          </InstantSearch>
        )
        : (
          <>
            <MainAppBar hideNavigation={hideNavigation} />
            <MainDrawer route={param[1]} hideNavigation={hideNavigation} />
            <main>
              <MainRouter />
            </main>
          </>
        )}
    </section>
  );
};

Page.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  resetSearch: PropTypes.func.isRequired,
};

const mapState = (state) => {
  const { checkout } = state.main.checkout.geral;
  return ({
    ...state.main.app,
    user: state.auth.login.user,
    checkout,
  });
};

export default connect(mapState, {
  resetSearch: resetSearchAction,
})(Page);
