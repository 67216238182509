import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent', 
    position: 'inherit',
  },
  orange: {
    color: '#FA4616'
  },
  accordion: {
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 42,
      height: 42,
    },
    minHeight: 42,
    height: 42,
  },
  accordionTitle: {
    fontWeight: 600, 
    fontSize: 12, 
    display: 'flex', 
    alignItems: 'center'
  },
  accordionIcon: {
    width: 16, 
    marginRight: 4
  },
  labelFreight: {
    marginLeft: 16, 
    fontWeight: 600, 
    fontSize: 12, 
    display: 'flex', 
    alignItems: 'center'
  },
  list: {
    fontWeight: 400, 
    fontSize: 12
  },
  iconNitrous: {
    width: 12, 
    height: 12, 
    marginLeft: 4
  }
}));

export default useStyles;
