import { makeStyles } from '@material-ui/core';
import {
  CreditRequestIcon, CreditRequestIconOrange,
  FavoritesIcon, FavoritesIconOrange, LocationIcon,
  LocationIconGray, LogoutIcon, OrdersIcon,
  OrdersIconGray, UserSettingsIcon,
  UserSettingsIconGray, VouchersIcon,
  VouchersIconGray,
} from '../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  clientMenuHeader: {
    marginBottom: 25,
    padding: '0 24px',
  },
  clientMenuGrid: {
    margin: -24,
    height: 'calc(100vh - 195px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 710,

    '& h3': {
      fontSize: 20,
      lineHeight: '22px',
      color: '#FE5000',
      fontWeight: 600,
      marginBottom: 12,
    },

    '& h4': {
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 600,
      color: '#0F1012',
      display: '-webkit-box',
      overflow: 'hidden',
      maxWidth: '100%',
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
    },

    [breakpoints.down('md')]: {
      height: '100%',
    },
  },
  clientMenuTabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },

    '& $optionTab:last-child': {
      '& .MuiTab-wrapper': {
        border: 0,
      },
    },
  },
  icon: {
    width: 24,
    height: 24,
    margin: 0,
    backgroundSize: 'contain !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',

    '&.data': {
      background: `url(${UserSettingsIconGray})`,
    },

    '&.address': {
      background: `url(${LocationIconGray})`,
    },

    '&.orders': {
      background: `url(${OrdersIconGray})`,
    },

    '&.favorites': {
      background: `url(${FavoritesIcon})`,
    },

    '&.vouchers': {
      background: `url(${VouchersIconGray})`,
    },

    '&.credit-request': {
      background: `url(${CreditRequestIcon})`,
    },

  },
  optionTab: {
    opacity: 1,
    padding: '20px 24px 0 24px',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    '& .MuiTab-wrapper': {
      width: '100%',
      justifyContent: 'start',
      borderBottom: '1px solid #CFCFD4',
      paddingBottom: 24,
      flexDirection: 'row',
      gap: 16,
    },

    '&:after': {
      content: '""',
      width: 8,
      height: 8,
      borderTop: '2px solid #7B7F82',
      borderRight: '2px solid #7B7F82',
      transform: 'rotate(45deg)',
      margin: '3px 3px 16px 3px',
      position: 'absolute',
      right: 24,
    },

    '&:hover': {
      '&:after': {
        borderTop: '2px solid #FE5000',
        borderRight: '2px solid #FE5000',
      },
      '& $title, $subtitle': {
        color: '#FE5000',
      },
      '& $icon': {
        '&.data': {
          background: `url(${UserSettingsIcon})`,
        },

        '&.address': {
          background: `url(${LocationIcon})`,
        },

        '&.orders': {
          background: `url(${OrdersIcon})`,
        },

        '&.favorites': {
          background: `url(${FavoritesIconOrange})`,
        },

        '&.vouchers': {
          background: `url(${VouchersIcon})`,
        },

        '&.credit-request': {
          background: `url(${CreditRequestIconOrange})`,
        },
      },
    },

    '&.selected': {
      '&:after': {
        borderTop: '2px solid #FE5000',
        borderRight: '2px solid #FE5000',
      },
      '& $title, $subtitle': {
        color: '#FE5000',
      },
      '& $icon': {
        '&.data': {
          background: `url(${UserSettingsIcon})`,
        },

        '&.address': {
          background: `url(${LocationIcon})`,
        },

        '&.orders': {
          background: `url(${OrdersIcon})`,
        },

        '&.favorites': {
          background: `url(${FavoritesIconOrange})`,
        },

        '&.vouchers': {
          background: `url(${VouchersIcon})`,
        },

        '&.credit-request': {
          background: `url(${CreditRequestIconOrange})`,
        },
      },
    },
  },
  optionBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    gap: 8,
  },
  title: {
    fontSize: 16,
    lineHeight: '22px',
    color: '#242424',
    textTransform: 'initial',
    fontWeight: 600,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '12px',
    color: '#7B7F82',
    textTransform: 'none',
  },
  logoutBox: {
    width: '100%',
    padding: '16px 24px',
  },
  logoutBtn: {
    border: '1px solid #CFCFD4',
    backgroundColor: '#F6F6F7',
    borderRadius: 8,
    color: '#7B7F82',
    fontSize: 16,
    height: 48,
    justifyContent: 'center',
    width: '100%',
    lineHeight: '18px',
    textTransform: 'initial',
    padding: 12,

    '& [class*=label]': {
      gap: 6,
      alignItems: 'center',
    },

    '&:hover': {
      backgroundColor: '#7B7F8240',
    },
  },
  logoutIcon: {
    background: `url(${LogoutIcon})`,
    width: 18,
    height: 18,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 18,
  },
}));

export default useStyles;
