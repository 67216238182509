import { makeStyles, Tooltip, withStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  textTooltipPrimary: {
    color: '#484848',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    textTransform: 'uppercase',
  },
  textTooltipSecondary: {
    color: '#242424',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
}));

export const TooltipStyled = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFF9EB',
    textAlign: 'center',
    padding: 8,
  },
  popper: {
    filter: 'drop-shadow(-1px -1px 2px #0000001F)',
  },
  arrow: {
    color: '#FFF9EB',
    fontSize: 'large',
  },
}))(Tooltip);

export default useStyles;
