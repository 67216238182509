/* eslint-disable max-len */
import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import PhoneIphoneRoundedIcon from '@material-ui/icons/PhoneIphoneRounded';
import CheckIcon from '@material-ui/icons/Check';
import qrcode from '../../../../../assets/qrcode.svg';
import useStyles from './styles';

const FooterInfoPix = () => {
  const classes = useStyles();
  return (
    <div className={classes.divInfoPix}>
      <Grid item xs={6} className={classes.infoPix}>
        <div className={classes.circleIcon}>
          <PhoneIphoneRoundedIcon style={{ color: '#fff', fontSize: 18, fontWeight: 'bold' }} />
        </div>
        <span style={{ marginLeft: 10 }}>
          <span>1. abra o app do seu banco ou instituição financeira e entre no ambiente </span>
          <span style={{ fontWeight: 'bold' }}>Pix</span>
        </span>

      </Grid>
      <Grid item xs={6} className={classes.infoPix}>
        <div className={classes.circleIcon}>
          <img src={qrcode} alt="" style={{ color: '#fff', size: 14 }} />
        </div>
        <span style={{ marginLeft: 10 }}>2. escolha a opção pagar com QR Code e escaneie o código acima.</span>
      </Grid>
      <Grid item xs={6} className={classes.infoPix}>
        <div className={classes.circleIcon}>
          <CheckIcon style={{ color: '#fff', fontSize: 18, fontWeight: 'bold' }} />
        </div>
        <span style={{ marginLeft: 10 }}>3. confime as informações e finalize a compra.</span>

      </Grid>
    </div>
  );
};

export default FooterInfoPix;
