import { makeStyles } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    display: 'flex',
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    [breakpoints.down('sm')]: {
      marginTop: 31,
    },
  },
  main: {
    flex: 1,
    position: 'relative',
    scrollBehavior: 'smooth',
    [breakpoints.up('lg')]: {
      height: ({ loading }) => `calc(100vh - ${loading ? '65px' : '65px'})`,
      overflowY: 'auto',
    },
    [breakpoints.only('md')]: {
      height: ({ drawerIsOpened, loading }) => !drawerIsOpened && `calc(100vh - ${loading ? '65px' : '65px'})`,
      overflowY: ({ drawerIsOpened }) => !drawerIsOpened && 'auto',
    },
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.down('md')]: {
      width: ({ drawerIsOpened }) => drawerIsOpened && '100%',
    },
  },
  sugestion: {
    [breakpoints.down('sm')]: {
      width: 'fit-content',
      position: 'absolute',
      right: 10,
      top: 22,
    },
  },

  root: {
    display: 'flex',
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  itemsContainer: {
    flex: 1,
    overflowX: 'hidden',
    width: '96%',
    transition: transitions.create('scroll', { duration: '255ms' }),
    [breakpoints.down('xs')]: {
      width: '100%',
    },
    boxShadow: '0px 4px 6px rgb(0 0 0 / 10%)',
    background: '#FFFFFF',
    borderRadius: '4px',
    border: '0.5px solid #CFCFD4',
    margin: 'auto',
    marginTop: '64px',
    marginBottom: '100px',
    left: '2%',
    position: 'absolute',
  },
  details: {
    borderLeft: '1px solid #dcdcdc',
    width: '30%',
    position: 'relative',
    [breakpoints.up('lg')]: {
      height: ({ loading }) => `calc(100vh - ${loading ? '65px' : '65px'})`,
    },
    [breakpoints.only('md')]: {
      height: ({ drawerIsOpened, loading }) => !drawerIsOpened && `calc(100vh - ${loading ? '65px' : '65px'})`,
    },
    [breakpoints.down('md')]: {
      width: ({ drawerIsOpened }) => (drawerIsOpened ? '100%' : 400),
      borderLeft: ({ drawerIsOpened }) => (drawerIsOpened ? 'none' : '1px solid #dcdcdc'),
    },
    [breakpoints.down('sm')]: {
      width: '100% !important',
      borderLeft: 'none',
      minHeight: 0,
    },
  },
  fieldset: {
    border: 'none',
    margin: 0,
    padding: 0,
  },
  svgLogo: {
    display: 'flex',
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  svgLogoImg: {
    width: 'auto',
    height: 24,
    animation: 'zoom-in-zoom-out 1s ease infinite',
  },
  svgLogoBorder: {
    content: ' ',
    display: 'block',
    position: 'absolute',
    width: 60,
    height: 60,
    margin: 1,
    borderRadius: '50%',
    border: '3px solid #fa4616',
    borderColor: '#fa4616 transparent #fa4616 transparent',
    animation: 'borda-loading 1.2s linear infinite',
    top: -18,
    left: 4,
  },
  loadCar: {
    position: 'absolute',
    top: 300,
    zIndex: 9999,
    left: '50%',
    [breakpoints.down('sm')]: {
      left: '43%',
    },
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: 25,
    color: '#fa4616',
    textSlign: 'center',
    lineHeight: 1.5,
    fontSize: 14,
    fontWeight: 600,
  },
  buttonContainer: {
    [breakpoints.down('md')]: {
      display: ({ drawerIsOpened }) => drawerIsOpened && 'flex',
    },
  },
  resumeTop: {
    [breakpoints.up('lg')]: {
      maxHeight: 'calc(100vh - 232px)',
      overflow: 'hidden',
      overflowY: 'auto',
    },
    [breakpoints.only('md')]: {
      maxHeight: ({ drawerIsOpened }) => !drawerIsOpened && 'calc(100vh - 232px)',
      overflow: ({ drawerIsOpened }) => !drawerIsOpened && 'hidden',
      overflowY: ({ drawerIsOpened }) => !drawerIsOpened && 'auto',
    },
  },
  resumeBottom: {
    [breakpoints.up('lg')]: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    [breakpoints.only('md')]: {
      position: ({ drawerIsOpened }) => !drawerIsOpened && 'absolute',
      bottom: ({ drawerIsOpened }) => !drawerIsOpened && 0,
      width: ({ drawerIsOpened }) => !drawerIsOpened && '100%',
    },
  },
  buttonTef: {
    marginBottom: '8px',
    marginRight: '8px',
    border: '1px solid #21AD34',
    color: '#21AD34',
    maxWidth: '236px',
    '&.Mui-disabled': {
      backgroundColor: '#B5B5B5 !important',
      color: '#FFFFFF',
      borderColor: '#FFFFFF',
    },
  },
  '[class*=variantSuccess]': {
    backgroundColor: '#128745 !important',
  },
  margemDoCarrinho: {
    color: '#707070',
    fontSize: '12px',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    paddingRight: '17px',
  },
  manualPaymentDialogActions: {
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    textAlign: 'center',
  },
}));

export default useStyles;
