import { getCookie, setCookie } from '../utils/cookieApi';
import { getUser } from './auth';

const APP_KEY = 'PDV-LOJA-DO-MECANICO';

export const getFilial = () => {
  const filial = getCookie(`${APP_KEY}-filial`);
  const user = getUser();
  if (!user) return null;
  if (!user.filiais) return null;

  const selectedFilial = user.filiais.find((item) => item.idEstabelecimento === +filial);
  return selectedFilial || null;
};

export const getUnidadeDeNegocio = () => {
  const unidadeDeNegocio = getCookie(`${APP_KEY}-idUnidadeDeNegocio`);
  return unidadeDeNegocio;
};

export const setUnidadeDeNegocio = (idUnidadeDeNegocio) => setCookie(
  `${APP_KEY}-idUnidadeDeNegocio`,
  idUnidadeDeNegocio,
  7,
);

export const getFilialVendedor = () => {
  const filial = getCookie(`${APP_KEY}-filial-vendedor`);
  return filial;
};

export const setFilial = (filial) => setCookie(
  `${APP_KEY}-filial`,
  filial,
  7,
);

export const getCanalDeVendas = () => {
  const canalDeVenda = getCookie(`${APP_KEY}-idCanalDeVendas`);
  return canalDeVenda;
};

export const setCanalDeVendas = (idCanalDeVenda) => setCookie(
  `${APP_KEY}-idCanalDeVendas`,
  idCanalDeVenda,
  7,
);

export const setFilialVendedor = (filial) => setCookie(
  `${APP_KEY}-filial-vendedor`,
  filial,
  7,
);

export const clearFilial = () => setCookie(`${APP_KEY}-filial`, '', -1);
export const clearFilialVendedor = () => setCookie(`${APP_KEY}-filial-vendedor`, '', -1);
export const clearCanalDeVendas = () => setCookie(`${APP_KEY}-idCanalDeVendas`, '', -1);
export const clearUnidadeDeNegocio = () => setCookie(`${APP_KEY}-idUnidadeDeNegocio`, '', -1);

export const setLogoutCustomer = () => setCookie(
  `${APP_KEY}-DESLOGA-CLIENTE`,
  true,
  7,
);

export const clearLogoutCustomer = () => setCookie(`${APP_KEY}-DESLOGA-CLIENTE`, false, -1);
