import apiService from '../../shared/services/apiService';

export const getVendedoresAtivos = async ({ page }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`/vendedores?status=1${page ? `&page=${page}&rpp=10` : ''}`);
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};
export const getVendedoresAtivosFiltro = async ({ page , docCliente, unNegocio, canalVendas, cargo, nivel }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`/vendedores/filtrar?status=1${page ? `&page=${page}&rpp=10` : ''}&nome=${docCliente}&email=${docCliente}&canal=${canalVendas}&cargo=${cargo}&nivel=${nivel}&idUnidadeNegocio=${unNegocio}`);
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};
export const getVendedoresInativosFiltro = async ({ page , docCliente, unNegocio, canalVendas, cargo, nivel }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`/vendedores/filtrar?status=0${page ? `&page=${page}&rpp=10` : ''}&nome=${docCliente}&email=${docCliente}&canal=${canalVendas}&cargo=${cargo}&nivel=${nivel}&idUnidadeNegocio=${unNegocio}`);
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};
export const getVendedoresInativos = async ({ page }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`/vendedores?status=0&page=${page}&rpp=10`);
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};

export const getDetailsVendedor = async ({ idVendedor }) => {
  const {
    data: { results },
  } = await apiService.get(`/vendedores/${idVendedor}`);
  return {
    results,
  };
};
export const getCanalDeVenda = async ({ page }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`canais-de-vendas${page ? `?page=${page}&rpp=10` : ''}`);
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};

export const getCanalDeVendaFiltro = async (unNegocio) => {
  const {
    data: { results },
  } = await apiService.get(`canais-de-vendas/unidade-negocio/${unNegocio}`);
  return {
    results,
  };
};

export const getUnNegocios = async ({ page }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`/unidades-negocio${page ? `?page=${page}&rpp=10` : ''}`);
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};

export const getEstabelecimentos = async ({ page }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`/estabelecimentos/nao-utilizados`);
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};

export const getCargos = async ({ page }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`/cargos${page ? `?page=${page}&rpp=10` : ''}`);
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};

export const getPermissoes = async () => {
  const data = await apiService.get('/telas-pdv');
  return {
    data,
  };
};

export const getNiveis = async () => {
  const {
    data: { results },
  } = await apiService.get('/parametrizacao-categoria-vendedores');
  return {
    results,
  };
};

export const getCargoFilter = async ({ id }) => {
  const {
    data: { results },
  } = await apiService.get(`/cargos/${id}`);
  return {
    results,
  };
};

export const postVendedores = async ({
  nomeVendedor, login, nivelPermissao, idVendedorERP, ativo, vendedorLoja3, qtdBoletosNPagos,
  abandonoCarrinho, nomeExibicao, emailVendedor, idUnidadeDeNegocio, idCanalDeVendas,
  idCargo, permissoesTela, senha, ramal, user,
}) => {
  const {
    data: { results },
  } = await apiService.post('/vendedores', {
    nomeVendedor,
    login,
    nivelPermissao,
    idVendedorERP,
    ativo,
    vendedorLoja3,
    qtdBoletosNPagos,
    abandonoCarrinho,
    nomeExibicao,
    emailVendedor,
    idUnidadeDeNegocio,
    idCanalDeVendas,
    idCargo,
    permissoesTela,
    senha,
    ramal,
    usuarioCadastro: user.nomeVendedor,
  });
  return {
    results,
  };
};

export const postCargos = async ({ nome }) => {
  const {
    data: { results },
  } = await apiService.post('/cargos', {
    nome,
  });
  return {
    results,

  };
};

export const putCargos = async ({ id, nome }) => {
  const data = await apiService.put(`/cargos/${id}`, {
    nome,
    id,
  });

  return {
    data,
  };
};

export const putStatusVendedores = async ({ idVendedores, status }) => {
  const data = await apiService.put('/vendedores/alterar-status', {
    idVendedor: idVendedores,
    status,
  });

  return {
    data,
  };
};

export const putVendedores = async ({
  idVendedor, nomeVendedor, nomeExibicao, idVendedorERP, emailVendedor, login,
  ativo, vendedorLoja3, abandonoCarrinho, idUnidadeDeNegocio, idCanalDeVendas,
  idCargo, idNivel, permissoesTela, senha, ramal, user,
}) => {
  const data = await apiService.put(`/vendedores/${idVendedor}`, {
    idVendedor,
    nomeVendedor,
    nomeExibicao,
    idVendedorERP,
    emailVendedor,
    login,
    ativo,
    vendedorLoja3,
    abandonoCarrinho,
    idUnidadeDeNegocio,
    idCanalDeVendas,
    idCargo,
    idNivel,
    permissoesTela,
    senha,
    ramal,
    usuarioLogado: user.nomeVendedor,
  });

  return {
    data,
  };
};
export const postCanalDeVendas = async ({
  descricao, idBseller, usuarioAlteracao, idUnidadeDeNegocio, filial, idSupervisor 
}) => {
  const data = await apiService.post(`/canais-de-vendas`, {
    descricao,
    idBseller,
    usuarioAlteracao,
    idUnidadeDeNegocio,
    filial,
    idSupervisor,
  });

  return {
    data,
  };
};
export const putSupervisor = async ({
  idCanalDeVendas, idVendedor, user,
}) => {
  const data = await apiService.put(`/supervisores/canal-de-vendas/${idCanalDeVendas}`, {
    idVendedor,
    idCanalDeVendas,
    usuarioAlteracao: user.nomeVendedor,
  });

  return {
    data,
  };
};
export const getSupervisores = async ({ page }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`/supervisores${page ? `?page=${page}&rpp=10` : ''}`);
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};
export const getSupervisorDetails = async ({ idVendedor, idCanalVendas }) => {
  const {
    data: { results },
  } = await apiService.get(`/supervisores/${idVendedor}/canal-de-vendas/${idCanalVendas}`);
  return {
    results,
  };
};

export const getParametrizacao = async () => {
  const {
    data: { results },
  } = await apiService.get('/parametrizacao-categoria-vendedores');
  return {
    results,
  };
};

export const getDetailsParametrizacao = async ({ id }) => {
  const {
    data: { results },
  } = await apiService.get(`/parametrizacao-categoria-vendedores/${id}`);
  return {
    results,
  };
};
export const putParametrizacao = async ({
  id, nomeCategoria, numPedidosRefeitos, vlrMinimoVenda,
  taxaConversao, taxaDeclinio, taxaComissao,
  periodoBuscaMaxEmDias, vlrMaximoPedidos,
}) => {
  const data = await apiService.put(`/parametrizacao-categoria-vendedores/${id}`, {
    id,
    nomeCategoria,
    numPedidosRefeitos,
    vlrMinimoVenda,
    taxaConversao,
    taxaDeclinio,
    taxaComissao,
    periodoBuscaMaxEmDias,
    vlrMaximoPedidos,
  });

  return {
    data,
  };
};
export const postParametrizacao = async ({
  nomeCategoria, numPedidosRefeitos, vlrMinimoVenda,
  taxaConversao, taxaDeclinio, taxaComissao,
  periodoBuscaMaxEmDias, vlrMaximoPedidos,
}) => {
  const {
    data: { results },
  } = await apiService.post('/parametrizacao-categoria-vendedores', {
    nomeCategoria,
    numPedidosRefeitos,
    vlrMinimoVenda,
    taxaConversao,
    taxaDeclinio,
    taxaComissao,
    periodoBuscaMaxEmDias,
    vlrMaximoPedidos,
  });
  return {
    results,
  };
};
