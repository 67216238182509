import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Chip, Dialog, DialogContent, DialogTitle, IconButton, Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Close } from '@material-ui/icons';
import { hideItemAction } from '../../actions';
import useStyles from './styles';

const ItemDialog = ({ showItem, hideItem }) => {
  const classes = useStyles();
  return (
    <Dialog open={!!showItem} onClose={hideItem} fullWidth maxWidth="sm">
      {showItem && (
      <>
        <DialogTitle>
          <Box className={classes.dialogHeader}>
            <Box>
              {showItem.name}
            </Box>

            <Box position="relative" top={-8} left={14}>
              <IconButton onClick={hideItem}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <Box textAlign="center" mb={2}>
            <img
              src={`https://img.lojadomecanico.com.br/IMAGENS/${showItem.image}`}
              alt=""
              className={classes.img}
            />
          </Box>

          <Box className={classes.chipContainer}>
            {showItem.filials.map((filial) => (
              <Chip
                label={`${filial.nomeFilial}: ${filial.estoque}`}
                key={filial.idFilial}
                className={classes.chip}
              />
            ))}
          </Box>
          <Typography variant="h6">
            DESCRIÇÃO DO PRODUTO
          </Typography>

          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: showItem.datasheet.replace(/(:{2})/g, '<strong>#</strong>'),
            }}
          />
        </DialogContent>
      </>
      )}
    </Dialog>
  );
};

ItemDialog.defaultProps = {
  showItem: null,
};

ItemDialog.propTypes = {
  showItem: PropTypes.instanceOf(Object),
  hideItem: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.checkout.geral;

export default connect(mapState, {
  hideItem: hideItemAction,
})(ItemDialog);
