import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  border: {
    borderBottom: '1.5px solid #E0E0E0',
    width: '100%',
  },
  content: {
    fontSize: 16,
    fontWeight: 400,
    color: '#242424',
    padding: '0 16px',

    [breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    lineBreak: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
  },
}));

export default useStyles;
