import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';

const CustomStepLabelIcon = (props) => {
  const classes = useStyles(props);
  const { active, completed } = props;

  return (
    <span className={clsx(classes.root, {
      [classes.active]: active,
      [classes.completed]: completed,
    })}
    />
  );
};

CustomStepLabelIcon.defaultProps = {
  completed: false,
  active: false,
};

CustomStepLabelIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};
export default CustomStepLabelIcon;
