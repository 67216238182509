import { makeStyles } from '@material-ui/core';
import { CartIcon } from '../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  emptyCartGrid: {
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: 23,

    '& span': {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 600,

      '&:last-child': {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 500,
        marginTop: 8,
        maxWidth: 385,
        color: '#727F82',
      },
    },

    [breakpoints.down('sm')]: {
      padding: 16,

      '& span': {
        fontSize: 16,
        lineHeight: '21px',

        '&:last-child': {
          fontSize: 12,
          lineHeight: '18px',
        },
      },
    },
  },
  paper: {
    minWidth: 560,
    maxHeight: 570,
    boxShadow: 'none',
    borderRadius: 16,
    border: '1px solid #CFCFD4',

    [breakpoints.down('xs')]: {
      minWidth: 300,
    },

    [breakpoints.between('sm', 'sm')]: {
      minWidth: 358,
      maxHeight: 514,
    },

    [breakpoints.up('sm')]: {
      marginTop: 24,
    },
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    backgroundColor: '#FE5000',
    borderRadius: '100%',
    background: `url(${CartIcon})`,
    backgroundSize: 24,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginBottom: 24,

    [breakpoints.down('sm')]: {
      width: 40,
      height: 40,
      backgroundSize: 16,
    },
  },
}));

export default useStyles;
