import { push } from '../../../history';
import resolveError from '../../../shared/utils/resolveError';
import { clientSignInAction } from '../login/actions';
import { Types } from './index';

import {
  getZipcode,
  postRegisterCorporateCustomer,
  postRegisterCustomer,
} from './repository';

const normalizer = (string) => (string === '' ? null : string);

const getZipcodeDataAction = (zipcode) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_ZIPCODE_REQUEST });
  try {
    const { snack } = getState().main.app;
    const data = await getZipcode(zipcode);
    dispatch({ type: Types.GET_ZIPCODE_SUCCESS });
    snack.enqueueSnackbar(data.message, { variant: 'success' });
    return data.content;
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_ZIPCODE_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const requestRegisterCustomerAction = (form) => async (dispatch, getState) => {
  dispatch({ type: Types.REGISTER_CUSTOMER_REQUEST });
  try {
    const { content: { cpfCnpj } } = await postRegisterCustomer({
      ...form,
      endereco: {
        ...form.endereco,
        complemento: normalizer(form.endereco.complemento),
        informacoesAdicionais: normalizer(form.endereco.informacoesAdicionais),
        numero: !normalizer(form.endereco.numero) ? 'S/N' : normalizer(form.endereco.numero),
      },
      nomeSocial: normalizer(form.nomeSocial),
      rgIe: normalizer(form.rgIe),
      telefoneSecundario: normalizer(form.telefoneSecundario),
    });

    await dispatch(clientSignInAction({ credential: cpfCnpj }, dispatch, getState));
    dispatch({ type: Types.REGISTER_CUSTOMER_SUCCESS });

    push('/register/success');
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.REGISTER_CUSTOMER_FAILURE });
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
  }
};

const requestRegisterCorporateCustomerAction = (form) => async (dispatch, getState) => {
  dispatch({ type: Types.REGISTER_CUSTOMER_REQUEST });

  try {
    const {
      content: { cpfCnpj },
    } = await postRegisterCorporateCustomer({
      ...form,
      endereco: {
        ...form.endereco,
        complemento: normalizer(form.endereco.complemento),
        informacoesAdicionais: normalizer(form.endereco.informacoesAdicionais),
        numero: !normalizer(form.endereco.numero) ? 'S/N' : normalizer(form.endereco.numero),
      },
      ie: normalizer(form.ie),
      inscricaoSuframa: normalizer(form.inscricaoSuframa),
      telefoneSecundario: normalizer(form.telefoneSecundario),
    });

    await dispatch(clientSignInAction({ credential: cpfCnpj }, dispatch, getState));
    dispatch({ type: Types.REGISTER_CUSTOMER_SUCCESS });

    push('/register/success');
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.REGISTER_CUSTOMER_FAILURE });
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
  }
};

export {
  getZipcodeDataAction,
  requestRegisterCustomerAction,
  requestRegisterCorporateCustomerAction,
};
