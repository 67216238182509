/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, Box, Typography, TextField, Tooltip,
} from '@material-ui/core';
import PropTypes, { instanceOf } from 'prop-types';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { Add as AddIcon, Close, Done as DoneIcon } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';

import {
  closeDialogCouponAction,
  deleteCouponAction,
  insertCouponAction,
  checkCouponAction,
  addAllVouchersAction,
} from '../../../../../main/checkout/actions';
import { toMoney } from '../../../../utils/parsers';
import apiService from '../../../../services/apiService';

const DialogCoupon = ({
  insertCoupon, checkout, dialogCouponOpened, closeDialogCoupon, checkCoupon,
  loading, couponList, customerId,
}) => {
  const { checkoutId, resume, payments } = checkout;

  const disabled = loading
    || (!!resume && (resume.remainder <= 0 || resume.remainderInstallment <= 0));

  const [blockAdd, setBlockAdd] = useState(false);

  const handleClose = () => {
    closeDialogCoupon();
  };

  useEffect(() => {
    setBlockAdd(false);
  }, [dialogCouponOpened]);

  const add = (idCoupon) => insertCoupon({
    idCheckout: checkoutId,
    idCoupon,
    price: resume.subTotalPrazo,
    remainder: resume.remainder,
  });

  const addV = (idValeCliente, idVale, valorVale) => insertCoupon({
    idCheckout: checkoutId,
    idCoupon: null,
    hashCupom: null,
    idVale,
    idValeCliente,
    tipoVale: 'VA',
    price: resume.subTotalPrazo,
    remainder: resume.remainder,
    idClient: customerId,
  });

  const addC = (idCoupon, hashCupom, valorVale) => insertCoupon({
    idCheckout: checkoutId,
    idCoupon,
    hashCupom,
    idVale: null,
    idValeCliente: null,
    tipoVale: 'CP',
    price: resume.subTotalPrazo,
    remainder: resume.remainder,
    idClient: customerId,
  });

  const addManuel = (idCoupon) => {
    if (!idCoupon) return;
    if (disabled) return;
    if (blockAdd) return;
    if ((checkout.payments && !!checkout.payments.length)) return;
    const price = resume.subTotalPrazo;
    handleClose();
    setBlockAdd(true);
    checkCoupon({ idCheckout: checkoutId, idCoupon, price });
  };

  const vas = (couponList || []).filter((item) => item.tipo === 'VA');
  const cps = (couponList || []).filter((item) => item.tipo === 'CP');
  const paymentBP = payments && payments.find((a) => a.type === 'BP');
  const nome = checkout.payments && checkout.payments.map((item) => item.nomeVoucher);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={dialogCouponOpened}
    >
      <Box position="relative">
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        <DialogTitle id="simple-dialog-title">Selecione ou digite o cupom</DialogTitle>

        <DialogContent>
          {!!vas.length && (
            <Box>
              <Typography variant="subtitle2">Vales</Typography>
            </Box>
          )}

          {vas.map((item) => (
            <ListItem key={`coupon-${item.idValeCliente}`}>
              <ListItemText
                primary={item.idValeCliente}
                secondary={`${toMoney(item.valorVale)} - ${moment(item.dataValidade).format('DD/MM/YYYY')}`}
              />
              <Tooltip>
                <span>
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={() => { addV(item.idValeCliente, item.idVale, item.valorVale); setBlockAdd(true); }}
                    disabled={disabled || nome.includes(item.idVale) || blockAdd}
                  >
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </ListItem>
          ))}

          <Divider />

          {!!cps.length && (
            <Box pt={2}>
              <Typography variant="subtitle2">Cupons</Typography>
            </Box>
          )}

          {cps.map((item) => (
            <ListItem key={`coupon-${item.hashCupom}`}>
              <ListItemText
                primary={item.hashCupom}
                secondary={item.cupomPorcentagem ? `${item.valorVale}% - ${moment(item.dataValidade).format('DD/MM/YYYY')}` : `${toMoney(item.valorVale)} - ${moment(item.dataValidade).format('DD/MM/YYYY')}`}
              />
              <Tooltip>
                <span>
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={() => { addC(item.hashCupom, item.hashCupom, item.valorVale); setBlockAdd(true); }}
                    disabled={disabled || nome.includes(item.hashCupom) || blockAdd}
                  >
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </ListItem>
          ))}

          {!vas.length && !cps.length && (
            <ListItem>
              <ListItemText primary="Não encontramos nenhum vale ..." />
            </ListItem>
          )}
        </DialogContent>

        {!!vas.length && !!cps.length && <Divider />}
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            label="Insira seu cupom"
            disabled={(checkout.payments && !!checkout.payments.length) || disabled || blockAdd}
            InputProps={{
              endAdornment: (
                <IconButton
                  title="Adicionar"
                  color="primary"
                  edge="end"
                  disabled={(checkout.payments && !!checkout.payments.length) || disabled || blockAdd}
                  onClick={(e) => {
                    const input = e.target.parentNode.parentNode.parentNode.querySelector('input');

                    setTimeout(() => {
                      addManuel(input && input.value);
                    }, 100);
                    setBlockAdd(true);
                  }}
                >
                  <DoneIcon />
                </IconButton>
              ),
            }}
          />

        </DialogContent>
      </Box>
    </Dialog>
  );
};

DialogCoupon.defaultProps = {
  couponList: [],
};

DialogCoupon.propTypes = {
  insertCoupon: PropTypes.func.isRequired,
  closeDialogCoupon: PropTypes.func.isRequired,
  // checkCoupon: PropTypes.func.isRequired,
  dialogCouponOpened: PropTypes.bool.isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  couponList: PropTypes.arrayOf(instanceOf(Object)),
};

const mapState = (state) => state.main.checkout.geral;

export default connect(mapState, {
  insertCoupon: insertCouponAction,
  deleteCoupon: deleteCouponAction,
  closeDialogCoupon: closeDialogCouponAction,
  checkCoupon: checkCouponAction,
  addAllVouchers: addAllVouchersAction,
})(DialogCoupon);
