import React from 'react';
import { Route } from 'react-router-dom';

import CustomSwitch from '../../shared/components/customSwitch';
import ClientPage from './Page';
import NotFoundPage from "../../errors/NotFoundPage";

const ClientRouter = () => {
  const param = window.location.pathname.split('/');
  const routes = [
    'registration-data',
    'addresses',
    'address',
    // 'client-orders',
    // 'coupons-and-vouchers',
    // 'favorites',
    // 'credit-request',
  ];

  const isRoute = routes.some((route) => param[2] === route);

  return (
    <CustomSwitch>
      {
        isRoute ? (
          <>
            <Route
              path="/client-space/:tabName"
              component={ClientPage}
              exact
            />
            <Route
              path="/client-space/:tabName/new"
              component={ClientPage}
              exact
            />
            <Route
              path="/client-space/:tabName/edit/:addressId"
              component={ClientPage}
              exact
            />
          </>
        ) : (
          <Route component={NotFoundPage} />
        )
      }
    </CustomSwitch>
  );
};

export default ClientRouter;
