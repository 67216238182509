import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Home from './HomeRecovery/HomeRecovery';
import ForbidenErrorPage from '../../../errors/ForbidenError';

const RecoveryListPage = ({ user }) => {
  const recoveryPedido = user.permissoes.find((permission) => (
    permission.id === 3
  ));

  const relatorio = user.permissoes.find((permission) => (
    permission.id === 2
  ));

  const recovery = user.permissoes.find((permission) => (
    permission.id === 1
  ));

  return (
    <>
      {(recovery || relatorio || recoveryPedido) && <Home />}
      {(recovery === undefined
        && relatorio === undefined
        && recoveryPedido === undefined)
        && <ForbidenErrorPage />}
    </>
  );
};
RecoveryListPage.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
};
const mapState = (state) => ({
  ...state.main.app,
  user: state.auth.login.user,
});

export default connect(mapState, {
})(RecoveryListPage);
