import React from "react";

import { connect } from "react-redux";

import PropTypes from "prop-types";
import useStyles from "./styles";

const LuckyForThoseWhoDo = ({ luckyNumbers, total }) => {
  const classes = useStyles();

  return (
    <div className={classes.promotionalContainer}>
      <div className={classes.promotionalContent}>
        <h3 className={classes.promotionalTitle}>Promoção "SORTE DE QUEM FAZ"</h3>
        {total && total < 250 ? (
          <>
            <p className={classes.promotionalSubtitle}>
              Aviso: Falta pouco para alcançarmos <bold>R$250,00</bold> e participar da promoção!
            </p>
            <span className={classes.promotionalMessage}>
              <bold>Quase lá! O valor mínimo para o cliente garantir um número da sorte e concorrer à incrível Rampage Rebel Gasolina 2024 ainda não foi alcançado. Que tal oferecer mais produtos? Use a oportunidade da promoção para mostrar como ele pode aumentar suas chances de ganhar essa máquina! Não deixe seus clientes perderem essa chance única!</bold>
            </span>
          </>
        ) : (
          <>
            <p className={classes.promotionalSubtitle}>
              Parabéns! A compra gerou{" "}
              <bold>
                {luckyNumbers} {luckyNumbers > 1 ? "Números" : "Número"}{" "}
              </bold>{" "}
              da Sorte!
            </p>
            <span className={classes.promotionalMessage}>
              <bold>LEMBRETE PARA O CLIENTE:</bold>
              <p>Para validar os números e garantir a participação na promoção é necessário que o cliente aceite os Termos de Uso dentro da área da promoção no aplicativo da Loja do Mecânico. Para isso basta acessar o menu “SORTE DE QUEM FAZ” dentro do app.</p>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

LuckyForThoseWhoDo.propTypes = {
  checkout: PropTypes.instanceOf(Object).isRequired,
  luckyNumbers: PropTypes.number.isRequired,
  total: PropTypes.number,
};

const mapState = (state) => state.main.checkout.geral;

export default connect(mapState, {})(LuckyForThoseWhoDo);
