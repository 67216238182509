import { makeStyles } from '@material-ui/core';
import { SortByIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  sortByBtn: {
    minWidth: 40,
    width: 40,
    height: 40,
    borderRadius: 4,
    border: '1px solid #CFCFD4',
    background: '#FFFFFF',
    padding: 8,
  },
  sortByIcon: {
    background: `url(${SortByIcon})`,
    display: 'flex',
    width: 24,
    height: 24,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  sortByOption: {
    borderBottom: '1px solid #BDC1CA',
    fontSize: 12,
    lineHeight: '18px',
    color: '#4E5766',
    padding: '8px 8px 7px 8px',
    fontFamily: ['Poppins', 'sans'],
    listStyle: 'none',
    width: 240,

    '&.selected': {
      background: '#EFEFEF',
    },

    '&:hover': {
      background: '#EFEFEF',
    },
  },
  sortByBoxMain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  sortByBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
    border: '1px solid #BDC1CA',
    borderRadius: '4px',
    borderBottom: 'none',
  },
  sortByListBtn: {
    background: 'none',
    border: 0,
    padding: 0,
    cursor: 'pointer',
  },
  sortByPopover: {
    right: 78,
    top: '223px !important',
    left: 'auto !important',

    [breakpoints.between('md', 'md')]: {
      top: '132px !important',
      left: 'auto !important',
      right: 96,
    },
    [breakpoints.up('lg')]: {
      top: '132px !important',
      left: 'auto !important',
      right: 86,
    },
  },
}));

export default useStyles;
