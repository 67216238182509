export const statusRequestOptions = [
  { key: 1, value: 'Aberto' },
  { key: 2, value: 'Pendente' },
  { key: 3, value: 'Aprovado' },
  { key: 4, value: 'Reprovado' },
  { key: 6, value: 'Aprovado com Entrada' }
];

export const statusOrderOptions = [
  { key: 2, value: 'Aprovado' },
  { key: 1, value: 'Pendente' },
  { key: 3, value: 'Reprovado' },
  { key: 4, value: 'Sem Pedido' }
];

// VAS-813
export const FILTER_SALLES_CHANNEL = [
  2, 7, 8, 15, 18
]

// VAS-817
export const FILTER_SELLERS = [
  2, 4, 7, 8, 9, 11, 15, 18
]