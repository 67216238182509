import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { InstallmentsDialog } from '../index';

const ContentText = ({
  priceProduct,
  data,
  className,
  notDiscount,
  isFisicStore,
  isDetails,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const {
    centsPer,
    discount,
    discountIcon,
    installments: installmentsStyled,
    priceGrid,
    priceOf,
    priceOfBox,
    pricePer,
    pricePerBox,
    searchInstallmentsBtn,
  } = classes;

  const {
    available,
    pma,
    full,
    installmentPaymentQuantity,
    installmentPaymentValue,
    promotionalDiscountPercentage,
    term,
    ppl,
  } = data;

  const formatToMonetary = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const termInteger = Math.trunc(ppl ? ppl.price : term);
  const termCents = ((ppl ? ppl.price : term) - termInteger)
    .toFixed(2)
    .split('.')[1] || '00';

  if (priceProduct) {
    return (
      <Grid className={`${priceGrid} ${className} ${!available && 'disabled'}`}>
        <Box className={priceOfBox}>
          <span className={priceOf}>
            {formatToMonetary.format(term > full ? term : full)}
          </span>
          {!notDiscount && (full > term) && (
            <span className={discount}>
              <div className={discountIcon} />
              {`${(promotionalDiscountPercentage || 10)}%`}
            </span>
          )}
        </Box>
        <Box className={pricePerBox}>
          <span className={pricePer}>{`R$ ${termInteger.toLocaleString('pt-br')}`}</span>
          <span className={centsPer}>{`,${termCents}`}</span>
        </Box>
        {
          !isFisicStore && !isDetails ? (
            <span className={installmentsStyled}>
              <b>
                {`${!pma && !ppl
                  ? (formatToMonetary.format(term))
                  : ''} em ${installmentPaymentQuantity}x ${formatToMonetary.format(installmentPaymentValue)}`}
              </b>
            </span>
          ) : (
            <>
              <button
                type="button"
                className={searchInstallmentsBtn}
                onClick={() => setOpen(true)}
              >
                <span>
                  CONSULTAR PARCELAMENTO
                </span>
              </button>
              <InstallmentsDialog
                handleOpen={{ open, setOpen }}
                price={ppl ? ppl.price : term}
              />
            </>
          )
        }

      </Grid>
    );
  }

  return <></>;
};

ContentText.defaultProps = {
  priceProduct: false,
  notDiscount: false,
  isFisicStore: false,
  isDetails: false,
  className: '',
};

ContentText.propTypes = {
  priceProduct: PropTypes.bool,
  data: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  notDiscount: PropTypes.bool,
  isFisicStore: PropTypes.bool,
  isDetails: PropTypes.bool,
};

export default ContentText;
