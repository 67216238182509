import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Grid,
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import SweetScroll from 'sweet-scroll';

import { connect } from 'react-redux';
import { toMoney } from '../../utils/parsers';
import OrderStatusDesktop from './OrderStatusDesktop';
import OrderStatusMobile from './OrderStatusMobile';
import useStyles from './styles';
import garantiaLogo from '../../../assets/garantia_logo_escudo.png';

const OrderDetails = ({
  delivery, isFullWidth, drawer, width, detailedDelivery, order,
}) => {
  const classes = useStyles();
  // const { drawer, width } = useSelector((state) => state.main.app);
  const { breakpoints } = useTheme();
  const downMd = useMediaQuery(breakpoints.down('md'));
  const upMd = useMediaQuery(breakpoints.up('md'));
  // eslint-disable-next-line no-nested-ternary
  const showDesktop = useMediaQuery(breakpoints.up('sm'))
    ? downMd
      ? upMd && (!drawer || isFullWidth)
      : true
    : false;

  const containerRef = useRef(null);
  const { current } = containerRef;
  const [showArrowLeft, setShowArrowLeft] = useState(false);
  const [showArrowRight, setShowArrowRight] = useState(false);
  const [maxScroll, setMaxScroll] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (current) {
        const { scrollWidth, scrollLeft, clientWidth } = current;
        setMaxScroll(scrollWidth - clientWidth - 56);
        setShowArrowLeft(
          scrollWidth > clientWidth && scrollLeft > 48,
        );
        setShowArrowRight(
          scrollWidth > clientWidth && scrollLeft <= maxScroll,
        );
      }
    }, 250);
  }, [
    current,
    setShowArrowLeft,
    setMaxScroll,
    maxScroll,
    setShowArrowRight,
    drawer,
    width,
  ]);
  const scroller = new SweetScroll({ }, current);

  const onScrollRigth = () => {
    scroller.toLeft(
      width > 600
        ? current.scrollLeft + 300
        : current.scrollLeft + (width - 22),
    );
  };

  const onScrollLeft = () => {
    scroller.toLeft(
      width > 600
        ? current.scrollLeft - 300
        : current.scrollLeft - (width - 22),
    );
  };

  const onScroll = () => {
    const { scrollWidth, scrollLeft, clientWidth } = current;
    setShowArrowLeft(
      scrollWidth > clientWidth && scrollLeft > 0,
    );
    setShowArrowRight(
      scrollWidth > clientWidth && scrollLeft < maxScroll,
    );
    setMaxScroll(scrollWidth - clientWidth - 56);
  };

  return (
    <>
      <div className={classes.delivery}>
        <div style={{marginBottom: 10, marginTop: 10,}}>
          <Grid container direction='row' >
            <Typography className={classes.deliveryInfo} variant="caption">
              <bold>Entrega - {delivery.idEntrega ?? 'N/D'}</bold>
            </Typography>
            <Typography className={classes.deliveryInfo} variant="caption">
              - com <bold>{delivery.quantidadeItens ?? 'N/D'}</bold> produto(s)
            </Typography>
            {/* <Typography className={classes.deliveryInfo} variant="body2">
              - Saída de <bold>{delivery.centroDeDistribuicao ?? 'N/D'}</bold>
            </Typography> */}
          </Grid>
          <Typography className={classes.deliveryInfoDate} variant="body2">
            Data: <bold>{delivery.dataEntregaPrometida ?? 'Sem data'}</bold>
          </Typography>
          {delivery.objeto.nomeTransportadora && (
            <Typography className={classes.deliveryInfoDate} variant="body2">
              Transportadora: <bold>{delivery.objeto.nomeTransportadora}</bold>
            </Typography>
          )}
          {delivery.objeto.numeroObjetoTracking && (
            <Typography className={classes.deliveryInfoDate} variant="body2">
              Cod Rastreio: <bold>{delivery.objeto.numeroObjetoTracking}</bold>
            </Typography>
          )}

        </div>
        {!!delivery.itens.length && (
          <div className={classes.deliveryContainer}>
            {showArrowLeft && (
              <IconButton
                size="small"
                className={`${classes.navigateArrow} marginRight`}
                onClick={onScrollLeft}
              >
                <ArrowBack />
              </IconButton>
            )}

            <div
              className={classes.deliveryItemsContainer}
              ref={containerRef}
              onScroll={onScroll}
            >
              <section className={classes.deliveryItems}>
                {delivery.itens.map((item, index) => (
                  <article key={`item-${index + 1}`} className={classes.item}>
                    <img src={`https://img.lojadomecanico.com.br/IMAGENS/${item.foto}`} alt="" />

                    <Box ml={3}>
                      <Typography style={{fontSize: '0.675rem'}} component="h1" variant="body2">
                        {item.nomeProduto}
                      </Typography>

                      <Typography style={{fontSize: '0.65rem'}} component="h2" variant="caption">
                        {`
                          ${item.quantidade} Unidade${item.quantidade > 1 ? 's' : ''}
                          - ${toMoney(item.total)} 
                        `}
                      </Typography>
                      {item.garantia.valorGarantia > 0 && (
                        <Box>
                          <Box style={{float: 'left'}}>
                            <img src={garantiaLogo} alt="garantiaLogo"   style={{marginTop: '2px', width: '19px', marginRight: '5px', height: 'inherit', minWidth: 'inherit'}} />
                          </Box>
                          <Box style={{fontSize: '11px', textTransform: 'lowercase', paddingTop: '4px'}}>
                            {item.garantia.descricaoGarantia.replace("GARANTIA", "")} - <span  style={{textTransform: 'uppercase'}}>{toMoney(item.garantia.valorGarantia)} </span>
                          </Box>
                        </Box>
                      
                      )}
                    </Box>
                  </article>
                ))}
              </section>
            </div>

            {showArrowRight && (
              <IconButton
                size="small"
                className={`${classes.navigateArrow} marginLeft`}
                onClick={onScrollRigth}
              >
                <ArrowForward />
              </IconButton>
            )}
          </div>
        )}

        {delivery.mktplaceIn && delivery.mktplaceIn.idSeller && (
          <Box textAlign="end">
            {'Vendido e entregue por: '}
            <span className={classes.companyName}>{delivery.mktplaceIn.companyName}</span>
          </Box>
        )}

        {showDesktop ? (
          <OrderStatusDesktop
            dataEntregaAgendadaEscolhida={order.dataEntregaAgendadaEscolhida} 
            delivery={delivery} 
            detailedDelivery={detailedDelivery}
          />
        ) : (
          <OrderStatusMobile
            dataEntregaAgendadaEscolhida={order.dataEntregaAgendadaEscolhida}
            delivery={delivery}
            detailedDelivery={detailedDelivery}
          />
        )}
      </div>
      <Divider />
    </>
  );
};

OrderDetails.defaultProps = {
  isFullWidth: false,
};

OrderDetails.propTypes = {
  delivery: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  detailedDelivery: PropTypes.func,
  isFullWidth: PropTypes.bool,
  drawer: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
};

const mapState = (state) => state.main.app;

export default connect(mapState)(OrderDetails);
