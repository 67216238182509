import React, { useState } from 'react';
import {
  Typography,
  Paper,
  Button,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { toMoney } from '../../../../../shared/utils/parsers';

import useStyles from '../../../styles';
import {
  postParametrizacaoAction,
} from '../../../actions';

const NewParametrizacao = ({
  postParametrizacao, setCreateNewParametrizacao,
}) => {
  const classes = useStyles();
  const [nivel, setNivel] = useState('');
  const [pedidosRefeitos, setPedidosRefeitos] = useState('');
  const [taxaConversao, setTaxaConversao] = useState('');
  const [taxaDeclinio, setTaxaDeclinio] = useState('');
  const [taxaComissao, setTaxaComissao] = useState('');
  const [periodoMax, setPeriodoMax] = useState('');
  const [valorMaxMask, setValorMaxMask] = useState('');
  const [valorMinMask, setValorMinMask] = useState('');
  const [valorMax, setValorMax] = useState('');
  const [valorMin, setValorMin] = useState('');
  const renderPostParametrizacao = async () => {
    await postParametrizacao({
      nomeCategoria: nivel,
      numPedidosRefeitos: +pedidosRefeitos,
      vlrMinimoVenda: valorMin,
      taxaConversao: +taxaConversao,
      taxaDeclinio: +taxaDeclinio,
      taxaComissao: +taxaComissao,
      periodoBuscaMaxEmDias: +periodoMax,
      vlrMaximoPedidos: valorMax,
    });
    setCreateNewParametrizacao(false);
  };

  const renderBack = () => {
    setCreateNewParametrizacao(false);
  };

  const renderMaskValue = ({ target: { value } }) => {
    setValorMin(value.replace(/\D/g, '') / 100);
    const parsedValue = +value.replace(/\D/g, '') / 100;
    setValorMinMask(toMoney(parsedValue));
  };

  const renderMaskValueMaxValue = ({ target: { value } }) => {
    setValorMax(value.replace(/\D/g, '') / 100);
    const parsedValue = +value.replace(/\D/g, '') / 100;
    setValorMaxMask(toMoney(parsedValue));
  };

  return (
    <Paper elevation={1} style={{ width: '100%', padding: 24 }}>
      <Grid item spacing={2} container xs={12}>
        <Grid item xs={4}>
          <Typography className={classes.textsSilverParametrizacao}>Nome do Nível</Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            className={classes.helperMessage}
            onChange={(e) => setNivel(e.target.value)}
            value={nivel}
          />
        </Grid>
      </Grid>
      <Grid item spacing={2} container xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={4}>
          <Typography className={classes.textsSilverParametrizacao}>Pedidos Refeitos</Typography>
          <TextField
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            value={pedidosRefeitos.replace(/\D/g, '')}
            onChange={(e) => setPedidosRefeitos(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textsSilverParametrizacao}>
            Valor Mínimo de Venda (R$)
          </Typography>
          <TextField
            size="small"
            className={classes.helperMessage}
            fullWidth
            InputRenderSmall=""
            variant="outlined"
            value={valorMinMask}
            onChange={(e) => renderMaskValue(e)}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.textsSilverParametrizacao}>
            Taxa de Conversão (%)
          </Typography>
          <TextField
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            value={taxaConversao.replace(/\D/g, '')}
            onChange={(e) => setTaxaConversao(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  %
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.textsSilverParametrizacao}>
            Taxa de Declínio (%)
          </Typography>
          <TextField
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            value={taxaDeclinio.replace(/\D/g, '')}
            onChange={(e) => setTaxaDeclinio(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  %
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={2}>
          <Typography className={classes.textsSilverParametrizacao}>
            Taxa de Comissão (%)
          </Typography>
          <TextField
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            value={taxaComissao.replace(/\D/g, '')}
            onChange={(e) => setTaxaComissao(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  %
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3} style={{ marginLeft: 32 }}>
          <Typography className={classes.textsSilverParametrizacao}>
            Periodo Máximo de busca de pedidos
          </Typography>
          <TextField
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            value={periodoMax.replace(/\D/g, '')}
            onChange={(e) => {
              setPeriodoMax(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} style={{ marginLeft: 32 }}>
          <Typography className={classes.textsSilverParametrizacao}>
            Valor Máximo por Pedido
          </Typography>
          <TextField
            size="small"
            className={classes.helperMessage}
            fullWidth
            InputRenderSmall=""
            variant="outlined"
            value={valorMaxMask}
            onChange={(e) => renderMaskValueMaxValue(e)}
          />
        </Grid>
        {/* <Grid item xs={3} style={{ marginLeft: 24 }}>
          <Typography className={classes.textsSilverParametrizacao}>
            Qtd. Máxima de Declínios
          </Typography>
          <TextField
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            value={qtdMaxDeclinios.replace(/\D/g, '')}
            onChange={(e) => setQtdMaxDeclinios(e.target.value)}
          />
        </Grid> */}
      </Grid>

      <div className={classes.divButtonCriar}>
        <Button
          startIcon={<CloseOutlinedIcon />}
          className={classes.buttonCancelar}
          onClick={renderBack}
        >
          CANCELAR
        </Button>
        <Button
          startIcon={<SaveOutlinedIcon />}
          className={classes.buttonSalvar}
          onClick={renderPostParametrizacao}
        >
          SALVAR
        </Button>
      </div>

    </Paper>
  );
};

NewParametrizacao.propTypes = {
  postParametrizacao: PropTypes.func.isRequired,
  setCreateNewParametrizacao: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.sales;

export default connect(mapState, {
  postParametrizacao: postParametrizacaoAction,
})(NewParametrizacao);
