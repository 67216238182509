import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import {
  Field, formValueSelector, reduxForm,
} from 'redux-form';
import Box from '@material-ui/core/Box';
import CardBar from '../../shared/components/cardBar';
import { InputCurrencyRender, InputRender, SelectRender } from '../../shared/fields';
import { required } from '../../shared/fields/validate';
import { createTicketAction, loadOptionsAction } from './actions';

const RecoveryListPage = ({
  tipo,
  isLoading,
  createTicket,
  loadOptions,
  handleSubmit,
  location,
  change,
  snack,
  options,
}) => {
  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const query = new URLSearchParams(location.search);
    const codProduto = query.get('codProduto');
    const fromDetail = query.get('fromDetail') === 'S';
    if (codProduto) {
      change('produtoCod', codProduto);
    }
    loadOptions(fromDetail);
  }, [createTicket, loadOptions, change, location]);

  const onSubmit = (form) => createTicket({
    ...form,
    nomeType: options.find((option) => option.id === form.tipo).name,
  }, snack);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section>
        <CardBar title="Sugestão" />

        <Box p={2} maxWidth={600} margin="auto">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="emailVendedor"
                component={InputRender}
                validate={required}
                label="E-mail Vendedor *"
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                component={InputRender}
                name="nomeVendedor"
                label="Nome Vendedor *"
                validate={required}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                name="tipo"
                label="Tipo Chamado *"
                options={options}
                component={SelectRender}
                validate={[required]}
                model={{
                  id: 'id',
                  name: 'name',
                }}
              />
            </Grid>

            {tipo === 1 && (
              <>

                <Grid item xs={12}>
                  <Field
                    name="produtoCod"
                    component={InputRender}
                    label="Código Produto *"
                    validate={required}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="concorrenteNome"
                    component={InputRender}
                    label="Nome concorrente *"
                    validate={required}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="concorrenteUrl"
                    component={InputRender}
                    label="URL concorrente *"
                    validate={required}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="concorrentePreco"
                    component={InputCurrencyRender}
                    label="Preço concorrente *"
                    validate={required}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="nossoPreco"
                    component={InputCurrencyRender}
                    label="Nosso preço *"
                    validate={required}
                  />
                </Grid>

              </>
            )}

            {tipo === 2 && (
              <Grid item xs={12}>
                <Field
                  name="produtoCod"
                  component={InputRender}
                  label="Código Produto *"
                  validate={required}
                />
              </Grid>
            )}

            {tipo === 3 && (
              <>
                <Grid item xs={12}>
                  <Field
                    name="produtoNome"
                    component={InputRender}
                    label="Nome do Produto *"
                    validate={required}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="produtoDesc"
                    component={InputRender}
                    label="Descrição do Produto *"
                    validate={required}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="produtoUrl"
                    component={InputRender}
                    label="URL Concorrente *"
                    validate={required}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="produtoMarca"
                    component={InputRender}
                    label="Marca Produto *"
                    validate={required}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                disabled={isLoading}
              >
                ENVIAR
              </Button>
            </Grid>

          </Grid>
        </Box>

      </section>
    </form>
  );
};

RecoveryListPage.propTypes = {};

const seletor = formValueSelector('suggestion');
const mapState = (state) => ({
  tipo: seletor(state, 'tipo'),
  isLoading: state.main.suggestion.isLoading,
  options: state.main.suggestion.options,
  snack: state.main.app.snack || {},
  initialValues: {
    emailVendedor: (state.auth.login.user || {}).emailVendedor || 'pdv@gurgelmix.com.br',
    nomeVendedor: (state.auth.login.user || {}).nomeVendedor,
  },
});

RecoveryListPage.propTypes = {
  tipo: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  createTicket: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  snack: PropTypes.instanceOf(Object).isRequired,
};

RecoveryListPage.defaultProps = {
  tipo: null,
};

export default connect(mapState, {
  createTicket: createTicketAction,
  loadOptions: loadOptionsAction,
})(reduxForm({
  form: 'suggestion',
})(RecoveryListPage));
