import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const LoadingDark = ({
  message,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.backdrop}>
        <div className={classes.loadCar}>
          <span className={classes.svgLogo} style={{ maxHeight: 24 }}>
            <div className={classes.svgLogoImg} alt="loader" />
          </span>
          <span className={classes.svgLogoBorder} />
          <div className={classes.label}>{message || 'Carregando...'}</div>
        </div>
      </Box>
    </>
  );
};

LoadingDark.defaultProps = {
  message: 'Carregando...',
};

LoadingDark.propTypes = {
  message: PropTypes.string,
};

export default LoadingDark;
