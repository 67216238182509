// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import apiService from '../../services/apiService';
import Types from './types';

const getInstallmentsProductAction = (price) => async (dispatch, getState) => {
  dispatch({ type: Types.INSTALLMENT_PRODUCT_REQUEST });

  try {
    const {
      data: {
        results,
      },
    } = await apiService.post('/carrinho/parcelamento', {
      valorPrazo: price,
      valorVista: price,
    });

    dispatch({ type: Types.INSTALLMENT_PRODUCT_SUCCESS, installments: results });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.INSTALLMENT_PRODUCT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getInstallmentsProductAction,
};
