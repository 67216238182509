import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
  },
  error: {
    '& *': {
      color: palette.error.main,
    },
  },
}));

export default useStyles;
