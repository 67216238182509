import React from 'react';
import { Field } from 'redux-form';
import * as PropTypes from 'prop-types';
import useStyles from './styles';

import { InputRender, SelectRender } from '../../../../../shared/fields';

const SelectForm = ({
  name,
  label,
  model,
  validate,
  options,
  required,
  component,
  endAdornment,
  ...props
}) => {
  const {
    selectField,
    labelField,
  } = useStyles();

  return (
    <div>
      <label className={`${labelField}`} htmlFor={name}>{`${label} ${required ? '*' : ''}`}</label>
      <Field
        {...props}
        name={name}
        component={SelectRender}
        options={options}
        validate={validate}
        model={model}
        classes={{ root: selectField }}
        id={name}
      />
    </div>
  );
};

SelectForm.defaultProps = {
  required: false,
  type: 'input',
  variant: 'outlined',
  validate: [],
  model: {
    id: 'id',
    name: 'label',
  },
  component: InputRender,
};

SelectForm.propTypes = {
  component: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  model: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  validate: PropTypes.array,
  required: PropTypes.bool,
};

export default SelectForm;
