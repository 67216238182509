import { handleActions, combineActions } from 'redux-actions';
import Types from './types';

const INITIAL_STATE = {
  addresses: null,
  address: null,
  page: 0,
  isLoading: false,
  hasException: null,
};

const stopLoading = combineActions(
  Types.GET_ADDRESSES_CLIENT_FAILURE,
  Types.GET_ADDRESS_CLIENT_FAILURE,
  Types.PUT_ADDRESS_CLIENT_FAILURE,
  Types.POST_ADDRESS_CLIENT_FAILURE,
  Types.PUT_MAIN_ADDRESS_FAILURE,
  Types.PUT_INACTIVE_ADDRESS_FAILURE,
);

const startLoading = combineActions(
  Types.GET_ADDRESSES_CLIENT_REQUEST,
  Types.GET_ADDRESS_CLIENT_REQUEST,
  Types.PUT_ADDRESS_CLIENT_REQUEST,
  Types.POST_ADDRESS_CLIENT_REQUEST,
  Types.PUT_MAIN_ADDRESS_REQUEST,
  Types.PUT_INACTIVE_ADDRESS_REQUEST,
);

export default handleActions({
  [stopLoading]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [startLoading]: (state) => ({
    ...state,
    isLoading: true,
    addresses: null,
  }),
  [Types.GET_ADDRESSES_CLIENT_SUCCESS]: (state, action) => {
    const { addresses } = action.payload;
    console.log('addresses', addresses);
    return {
      ...state,
      addresses,
      page: 0,
      isLoading: false,
    };
  },
  [Types.PUT_MAIN_ADDRESS_SUCCESS]: (state) => ({
    ...state,
    page: 0,
    isLoading: false,
  }),
  [Types.PUT_INACTIVE_ADDRESS_REQUEST]: (state) => ({
    ...state,
  }),
  [Types.PUT_INACTIVE_ADDRESS_SUCCESS]: (state) => ({
    ...state,
    page: 0,
  }),
  [Types.GET_ADDRESS_CLIENT_SUCCESS]: (state, action) => {
    const { address } = action.payload;
    return {
      ...state,
      address,
      page: 0,
      isLoading: false,
    };
  },
  [Types.PUT_ADDRESS_CLIENT_SUCCESS]: (state) => ({
    ...state,
    page: 0,
    isLoading: false,
  }),
  [Types.POST_ADDRESS_CLIENT_SUCCESS]: (state) => ({
    ...state,
    page: 0,
    isLoading: false,
  }),
}, INITIAL_STATE);
