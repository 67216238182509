import React from 'react';
import {
  Box, Typography, Button, List,
} from '@material-ui/core';

import useStyles from './styles';

const UnknowError = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <List className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          500
        </Typography>

        <Box pb={1} />

        <Typography>
          Tivemos um problema, já fomos informados,
          tente novamente mais tarde ou contate o setor de desenvolvimento.
        </Typography>

        <Box pb={1} />

        <Button href="/">
          Voltar ao início
        </Button>
      </List>
    </main>
  );
};

export default UnknowError;
