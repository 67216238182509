const colourStyles = {
  control: (styles, {
    isDisabled, isFocused,
  }) => ({
    ...styles,
    boxShadow: 'none',
    color: isDisabled,
    cursor: 'pointer',
    borderColor: isFocused ? '#FF5225' : '#C4C4C4',
    ':hover': {
      ...styles[':hover'],
      boxShadow: 'none',
      borderColor: isFocused && '#FF5225',
    },
  }),
};
export default colourStyles;
