import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 16,
  },
  buttonLigar: {
    backgroundColor: '#FFF0ED',
    height: 48,
    width: 258,
    marginTop: 10,
    marginRight: 24,
    maxWidth: '100%',
    color: '#FA4616',
    fontSize: 13,
    fontWeight: 600,
    border: '1px #FA4616 solid',
    '&.MuiButton-root:hover': {
      backgroundColor: '#FA4616 !important',
      color: '#FFF !important',
    },
  },
  buttonCopyPhone: {
    backgroundColor: '#FFF0ED',
    height: 48,
    width: 58,
    marginTop: 10,
    marginRight: 0,
    maxWidth: 48,
    color: '#FA4616',
    fontSize: 13,
    border: '1px #FA4616 solid',
    '&.MuiButton-root:hover': {
      backgroundColor: '#FA4616 !important',
      color: '#FFF !important',
    },
  },
  gridLigarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  headerProducts: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  TextOrangeWeight: {
    color: '#FA4616',
    fontWeight: 600,
  },
  textOrangeMenor: {
    color: '#FA4616',
    fontWeight: 600,
    fontSize: 12,
  },
  divInfoPedidoHeader: {
    width: '40%',
    fontSize: 16,
    padding: 16,
  },
  divInfoPedidoDetalheHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  divDataHeader: {
    color: '#7B7F82',
    fontSize: 14,
    marginTop: 1,
    display: 'flex',
  },
  divInfoPagamentoHeader: {
    width: '35%',
    fontSize: 16,
    padding: 16,
  },
  divInfoPagamentoDetalheHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textWeight500: {
    fontWeight: 500,
  },
  divInfoRecusaHeader: {
    width: '30%',
    fontSize: 16,
    padding: 16,
  },
  divInfoRecusaDetalhesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paperHeaderPedido: {
    width: '100%',
    padding: 16,
  },
  divScrollProduct: {
    overflow: 'auto',
    maxHeight: 250,
  },
  paperListProdutos: {
    width: '100%',
    marginTop: 16,
  },
  textsTopInput: {
    color: '#6C757D',
    fontSize: 12,
    overflow: 'hidden',
  },
  img: {
    width: 55,
    height: 55,
  },
  paperDeclined: {
    position: 'absolute',
    width: 400,
    alignItems: 'center',
    backgroundColor: '#ffff',
    borderRadius: 8,
    padding: 5,
    justifyContent: 'center',
  },
  buttonDeclinar: {
    marginRight: 24,
    width: 184,
    height: 34,
    fontSize: 14,
    fontWeight: 600,
    color: '#F4282D',
    backgroundColor: 'rgb(0, 0, 0, 0)',
    border: '1px #F4282D solid',
    fontFamily: ['Poppins', 'sans serif'].join(','),
    '&.MuiButton-root:hover': {
      backgroundColor: '#F4282D',
      color: '#fff',
    },
    '&:disabled': {
      backgroundColor: '#CFCFD4',
    },
  },
  paper: {
    position: 'absolute',
    width: 600,
    alignItems: 'center',
    backgroundColor: '#ffff',
    borderRadius: 8,
    padding: 5,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRecuperar: {
    backgroundColor: '#21AD34',
    color: '#FFFF',
    width: 184,
    height: 34,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#37B548',
    },
    '&:disabled': {
      backgroundColor: '#CFCFD4',
    },
  },
  buttonModalDeclinar: {
    backgroundColor: '#21AD34',
    color: '#fff',
  },
  buttonModalCancelar: {
    backgroundColor: '#F4282D',
    color: '#fff',
  },
  divBodyModal: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  divProduct: {
    padding: 16,
    height: 103,
    border: '1px #E3E3E7 solid',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  divProductImg: {
    width: '35%',
    height: 71,
    display: 'flex',
    flexDirection: 'row',
  },
  borderImage: {
    border: '1px #E3E3E7 solid',
    minWidth: 71,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divInfoRightImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 16,
  },
  flagCodPedido: {
    fontSize: 11,
    borderRadius: 4,
    border: '1px #FF5225 solid',
    color: '#FF5225',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px 4px 1px 4px',
    maxWidth: 90,
  },
  textDescricao: {
    width: 366,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  divFabricante: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textFabricante: {
    color: '#7B7F82',
    fontWeight: 500,
  },
  textWeight600: {
    fontWeight: 600,
  },
  divFooter: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    height: 86,
    width: '100%',
    backgroundColor: '#F6F6F7',
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 0 8px #C4C4C4',
  },
  textMsgPedidos: {
    margin: '12px 24px 24px 50px',
    fontWeight: 600,
    fontSize: 16,
  },
  customerGrid: {
    rowGap: 12,
  },
  customerDivider: {
    marginTop: 12,
    marginBottom: 12,
  },
  customerPaper: {
    marginTop: 12,
    marginBottom: 12,
    padding: 16,
  },
  customerTitle: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 600,
  }
}));

export default useStyles;
