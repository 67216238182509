import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { resetSearchAction } from '../../../../main/checkout/actions';
import useStyles from './styles';

// eslint-disable-next-line no-shadow
const CloseSearchProducts = ({ resetSearchAction }) => {
  const closeSearchRef = useRef(null);
  const classes = useStyles();
  const {
    closeSearchBoxBtn,
    closeSearchIcon,
  } = classes;
  const handleClose = () => resetSearchAction(true);

  return (
    <Box>
      <Button
        ref={closeSearchRef}
        className={closeSearchBoxBtn}
        onClick={handleClose}
      >
        <span className={closeSearchIcon} />
      </Button>
    </Box>
  );
};

CloseSearchProducts.propTypes = {
  resetSearchAction: PropTypes.func.isRequired,
};

const mapState = (state) => {
  const { resetSearch } = state.main.checkout.geral;

  return ({
    ...state.main.checkout.geral,
    resetSearch,
  });
};

export default connect(mapState, {
  resetSearchAction,
})(CloseSearchProducts);
