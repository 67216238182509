import { makeStyles } from '@material-ui/core';
import { AlertIcon } from '../../../../../assets';

const useStyles = makeStyles(() => ({
  alertShipping: {
    marginTop: 12,
    marginBottom: 24,
    lineHeight: 1.5,
    border: '1px solid #CFCFD4',
    borderRadius: 8,
    background: '#F6F6F7',
    color: '#7B7F82',
    fontSize: 12,
    fontWeight: 500,
    alignItems: 'center',
    '& bold': {
      fontWeight: 600,
      marginRight: 4,
    }
  },
  alertIcon: {
    display: 'flex',
    width: 20,
    height: 30,
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    background: `url(${AlertIcon})`,
  }
}));

export default useStyles;
