import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
  Box,
  IconButton,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { push } from '../../../../history';
import {
  getPdfOrcamentoAction,
} from '../../actions';
import {
  toCPF, toCNPJ, toPhone, toMoney,
} from '../../../../shared/utils/parsers';
import Selectable from '../../Selectable';
import useStyles from './styles';

const CheckoutsTableBody = ({
  checkout,
  getPdf,
  loadingPDF,
  index,
}) => {
  const classes = useStyles();
  const {
    iconBtn,
    launchIcon,
    tableRowBody,
    tableCellBody,
    checkoutBox,
    labelBtn,
    copyIcon,
    btnCheckoutId,
    popper,
    tooltip,
  } = classes;

  const [openTooltip, setOpenTooltip] = useState(false);
  const postCheckout = async (id) => {
    push(`/checkout/${id}`);
  };

  const handleTooltip = () => setOpenTooltip(!openTooltip);

  const copyToClipboard = (idProduct) => {
    navigator.clipboard.writeText(idProduct).catch((err) => console.log(err.message));
    handleTooltip();
  };

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        handleTooltip();
      }, 1000);
    }
  }, [openTooltip]);

  return (
    <TableRow
      key={`row-${index + 1}`}
      classes={{ root: tableRowBody }}
    >
      <TableCell classes={{ root: tableCellBody }} datatype="Orçamento">
        <Box className={checkoutBox}>
          <Tooltip title="ir para o carrinho">
            <Button
              disableRipple
              classes={{ root: iconBtn, label: labelBtn }}
              onClick={() => postCheckout(checkout.checkoutId)}
            >
              <Icon classes={{ root: launchIcon }}>launch</Icon>
              <Box>
                {checkout.checkoutId}
              </Box>
            </Button>
          </Tooltip>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltip}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{ popper, tooltip }}
            title="Código copiado"
            placement="top"
          >
            <button
              type="button"
              className={btnCheckoutId}
              onClick={() => copyToClipboard(checkout.checkoutId)}
            >
              <div className={copyIcon} />
            </button>
          </Tooltip>
        </Box>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Cliente">
        <Box
          component="span"
          onClick={(e) => e.stopPropagation()}
          style={{ textTransform: 'uppercase', cursor: 'text' }}
        >
          {checkout.nomeSocial || checkout.nome}
        </Box>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Documento">
        <Box
          component="span"
          style={{ cursor: 'text' }}
          onClick={(e) => e.stopPropagation()}
          whiteSpace="nowrap"
        >
          {checkout.documento.length === 11 ? (
            toCPF(checkout.documento)
          ) : (
            toCNPJ(checkout.documento)
          )}
        </Box>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Itens">
        <Selectable>
          {checkout.produtos}
        </Selectable>
      </TableCell>
      <TableCell classes={{ root: tableCellBody }} datatype="Telefone">
        <Box
          component="span"
          style={{ cursor: 'text' }}
          onClick={(e) => e.stopPropagation()}
          whiteSpace="nowrap"
        >
          {toPhone((checkout.ddd || '') + checkout.telefone)}
        </Box>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Valor">
        <Box
          component="span"
          style={{ cursor: 'text' }}
          onClick={(e) => e.stopPropagation()}
          whiteSpace="nowrap"
        >
          {toMoney(checkout.total)}
        </Box>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Ultima Alteração">
        <Selectable>
          {moment(checkout.dateLastChange).format('DD/MM/YYYY')}
        </Selectable>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Criação">
        <Selectable>
          {moment(checkout.dateRegistration).format('DD/MM/YYYY')}
        </Selectable>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Vendedor">
        <Selectable>
          {checkout.nomeVendedor}
        </Selectable>
      </TableCell>
      <TableCell classes={{ root: tableCellBody }}>
        <Tooltip title="Imprimir detalhes do orçamento">
          <IconButton
            disabled={loadingPDF}
            onClick={(e) => { e.stopPropagation(); getPdf(checkout.checkoutId); }}
          >
            <Icon>print</Icon>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const mapState = (state) => ({
  ...state.main.checkoutsAndOrders,
  loadingPDF: state.main.checkoutsAndOrders.loadings.pdf,
});

CheckoutsTableBody.propTypes = {
  checkout: PropTypes.instanceOf(Object).isRequired,
  getPdf: PropTypes.func.isRequired,
  loadingPDF: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default connect(mapState, {
  getPdf: getPdfOrcamentoAction,
})(CheckoutsTableBody);
