import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  detailToolbar: {
    minHeight: 56,
    cursor: ({ onChangeData }) => (onChangeData ? 'pointer' : 'default'),
    padding: '0 8px 0 0',
    '& h2': {
      lineHeight: 1,
      color: palette.text.grey1,
    },
  },
  avatar: {
    backgroundColor: palette.primary.main,
  },
  customerName: {
    textTransform: 'capitalize',
    width: 'calc(100% - 16px)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  actionButton: {
    fontSize: '10px',
    float: 'right',
    width: '90px',
    borderRadius: '4px',
    fontWeight: 'bold',
    height:20,
    position: 'relative',
    top: '-4px'
  },
  buttonBlue: {
    color: '#2296F3',
    border: '1px solid #2296F3',
  },
  buttonOrange: {
    border: '1px solid #F4282D',
  }
}));

export default useStyles;
