import { makeStyles } from '@material-ui/core';
import { LocationIcon } from '../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  locationBox: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  locationIcon: {
    width: 24,
    height: 26,
    backgroundSize: 24,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    background: `url(${LocationIcon})`,
  },
  addressBox: {
    display: 'flex',
    flexDirection: 'column',
    color: '#7B7F82',
    fontSize: 12,
    lineHeight: '18px',

    '& span': {
      display: '-webkit-box',
      overflow: 'hidden',
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
    },

    '& span:first-child': {
      fontSize: 14,
      lineHeight: '21px',
      fontWeight: 600,
      width: 200,
      textTransform: 'capitalize',
    },

    '& span:last-child': {
      width: 300,

      [breakpoints.down('lg')]: {
        width: 200,
      },
    },
  },
  locationBtn: {
    textAlign: 'start',
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
