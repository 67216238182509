import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, DialogActions, Grid, IconButton, LinearProgress, useTheme,
} from '@material-ui/core';
import { change, Field, formValueSelector } from 'redux-form';
import { Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import { InputCurrencyRender, SelectRender } from '../../../../../shared/fields';
import PAYMENT_OPTIONS from '../../../../../shared/utils/paymentOptions';
import { required } from '../../../../../shared/fields/validate';
import { toMoney } from '../../../../../shared/utils/parsers';
import CashierResume from './CashierResume';
import { getInstallmentsAction } from '../advanced/actions';
import AutocompleteRender from '../../../../../shared/fields/AutocompleteRender';

const CashierPayments = ({
  fields, payments, remainder, installments, getInstallments, loadingInstallmets,
  dispatch, total, conditions,
}) => {
  const { palette } = useTheme();

  useEffect(() => {
    getInstallments(total);
  }, [getInstallments, total]);

  const computedPaid = useMemo(() => Number(
    payments.reduce((accumul, payment) => accumul + (payment.total || 0), 0),
  ).toFixed(2), [payments]);

  const getOtherPaids = (index) => payments.reduce((accumul, payment, i) => (
    i === index ? accumul : accumul + (payment.total || 0)
  ), 0);

  return (
    <>
      <Box
        css={{
          padding: 16,
          backgroundColor: 'rgb(240, 240, 240)',
          borderBottom: '1px solid #CDCDCD',
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <CashierResume
              value={`${toMoney(remainder)}`}
              label="Total"
            />
          </Grid>

          <Grid item xs={6}>
            <CashierResume
              value={`${toMoney(remainder - computedPaid)}`}
              label="Pendente"
            />
          </Grid>
        </Grid>

        {remainder < computedPaid && (
          <Box color={palette.error.main} textAlign="center" mt={1}>
            ATENÇÃO, o valor indicado ultrapassa o valor a ser pago!
          </Box>
        )}
      </Box>

      <Box>
        {fields.map((payment, index) => (
          <Box
            display="flex"
            alignItems="center"
            key={payment}
            p={2}
            css={{ backgroundColor: index % 2 !== 0 && 'rgb(240, 240, 240)' }}
          >
            <Box flex={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={payments[index].type === 'CC' ? 4 : 6}>
                  <Field
                    name={`${payment}.total`}
                    component={InputCurrencyRender}
                    label="Valor do pagamento"
                    validate={required}
                    max={remainder - getOtherPaids(index)}
                  />
                </Grid>

                <Grid item xs={12} sm={payments[index].type === 'CC' ? 4 : 6}>
                  <Field
                    name={`${payment}.type`}
                    label="Forma de Pagamento"
                    component={SelectRender}
                    options={PAYMENT_OPTIONS}
                    onChange={() => dispatch(change(
                      'checkout/cashier-on-time',
                      `${payment}.installments`,
                      1,
                    ))}
                    validate={[required]}
                    model={{
                      id: 'code',
                      name: 'name',
                    }}
                  />
                </Grid>

                {payments[index].type === 'CC' && (
                  <Grid item xs={12} sm={4}>
                    <Field
                      name={`${payment}.installments`}
                      label="Parcelas"
                      options={installments}
                      component={SelectRender}
                      validate={[required]}
                      model={{
                        id: 'quantidadeParcelas',
                        name: 'quantidadeParcelas',
                      }}
                    />

                    {loadingInstallmets && <LinearProgress />}
                  </Grid>
                )}

                {payments[index].type === 'BP' && (
                  <Grid item xs={12} sm={6}>
                    <Field
                      name={`${payment}.condition`}
                      component={AutocompleteRender}
                      label="Condição"
                      validate={[required]}
                      options={conditions}
                      model={{
                        id: 'id',
                        name: 'nome',
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            {fields.length > 1 && (
              <Box textAlign="end" ml={2} color={palette.error.main}>
                <IconButton color="inherit" onClick={() => fields.remove(index)} size="small">
                  <Delete />
                </IconButton>
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <DialogActions>
        <Button color="secondary" onClick={() => fields.push({ installments: 1 })}>
          Adicionar
        </Button>

        <Button color="primary" type="submit">
          Confirmar
        </Button>
      </DialogActions>
    </>
  );
};

CashierPayments.defaultProps = {
  conditions: null,
};

CashierPayments.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
  payments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  installments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  remainder: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  getInstallments: PropTypes.func.isRequired,
  loadingInstallmets: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

const selector = formValueSelector('checkout/cashier-on-time');

const mapState = (state) => ({
  loadingInstallmets: state.main.checkout.advanced.loadingInstallmets,
  remainder: state.main.checkout.geral.checkout.resume.remainder,
  conditions: state.main.checkout.payment.conditions,
  total: state.main.checkout.geral.checkout.resume.total,
  payments: selector(state, 'payments') || [],
  installments: state.main.checkout.advanced.installments.map((installment) => ({
    ...installment,
    label: `${installment.quantidadeParcelas} - ${toMoney(installment.valorParcela)}`,
  })),
});

export default connect(mapState, {
  getInstallments: getInstallmentsAction,
})(CashierPayments);
