import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  }
}));

export default useStyles;
