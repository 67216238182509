import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { useInfiniteHits, useToggleRefinement } from 'react-instantsearch';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import algoliasearch from 'algoliasearch';
import { Product } from '../../../../main/checkout/_components';
import useStyles from './styles';
// eslint-disable-next-line import/no-cycle
import { DrawerToFilters, envVariablesAlgolia } from '../index';
import {
  Collapse,
  NumericMenu,
  RefinementList,
  Slider,
  ToggleRefinement,
} from '../filters';
import { handleRefinements } from '../../../utils/algolia';
import { resetSearchAction, searchByCategoryAction } from '../../../../main/checkout/actions';
import { getFilialVendedor, getUnidadeDeNegocio } from '../../../services/app';
import businessUnitTypes from '../../../utils/businessUnitTypes';
import SubheaderFilters from '../../../../main/_components/subheaderFilters';

const Hits = ({
  user,
  customer,
  hasFilters,
  hasHits,
  resetSearch,
  categoryList,
  searchByCategory,
  isCheckout,
  facets,
}, props) => {
  const { appId, indexName, writeApiKey } = envVariablesAlgolia;
  const [businessUnit] = useState(getUnidadeDeNegocio || 2);
  const isFisicStore = Number(businessUnit) === businessUnitTypes.LOJAS_FISICAS;
  const [filialUserLinkedInRegister, setFilialUserLinkedInRegister] = useState(0);
  const [indexFilialUser, setIndexFilialUser] = useState(0);
  const [userFilialId, setUserFilialId] = useState(user.filiais[0].idEstabelecimento);
  const [sameFilialCheckbox, setSameFilialCheckbox] = useState({
    stock: false,
    withdrawStore: false,
  });
  const [refinements, setRefinements] = useState([]);
  const [attributesForFaceting] = useState(facets);
  const [component, setComponent] = useState([]);
  const client = algoliasearch(appId, writeApiKey);
  const indexAlgolia = client.initIndex(indexName);

  const classes = useStyles();
  const {
    filtersBox,
    hitsBox,
    hitsContainer,
  } = classes;

  const {
    hits, isLastPage, showMore,
  } = useInfiniteHits(props);
  const sentinelRef = useRef(null);
  const { value: valuePpl } = useToggleRefinement({ attribute: 'isPpl' });
  const showPpl = !isFisicStore ? true : valuePpl.isRefined;

  const handlePriceProductFilter = () => {
    const filterPrice = `priceEstablishments.${indexFilialUser}.term`;
    const result = attributesForFaceting.some((filter) => filter === filterPrice);
    let attributes = [...attributesForFaceting];

    if (!result) attributes = [...attributesForFaceting, filterPrice];

    indexAlgolia.setSettings({
      attributesForFaceting: [
        ...attributes,
      ],
    }).catch((err) => console.log('error:', err));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      }, { threshold: 1 });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (hits.length) {
      const handleEsc = (e) => {
        if (e.key === 'Escape') resetSearch(true);
      };
      window.addEventListener('keydown', handleEsc);

      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }
  }, [hits.length]);

  useEffect(() => {
    setUserFilialId(user.filiais[0].idEstabelecimento);
  }, [user.filiais[0].idEstabelecimento]);

  useEffect(() => {
    if (Number(businessUnit) === businessUnitTypes.LOJAS_FISICAS) {
      const res = Number(getFilialVendedor() || 2);
      setFilialUserLinkedInRegister(res);
    }
  }, []);

  useEffect(() => {
    if (indexFilialUser && facets) {
      handlePriceProductFilter();
    }
    setRefinements(handleRefinements(indexFilialUser));
  }, [indexFilialUser]);

  useEffect(() => {
    if (categoryList) {
      searchByCategory('', null);
    }
  }, [!!hasHits]);

  const handleProducts = () => {
    if (hasHits) {
      const productComponent = [];
      hits.map((hit) => {
        if (!valuePpl.isRefined) {
          productComponent.push(
            <Product
              className="ais-InfiniteHits-item"
              key={hit.objectID}
              product={hit}
              userFilialId={userFilialId}
              filialUserLinked={filialUserLinkedInRegister}
              setIndexFilialUser={setIndexFilialUser}
              isCheckout={isCheckout}
              isFisicStore={isFisicStore}
            />,
          );
        }

        if (hit.isPpl && hit.ppl && showPpl) {
          return hit.ppl.map((ppl, index) => {
            productComponent.push(
              <Product
                className="ais-InfiniteHits-item"
                key={`${hit.objectID}--${index}`}
                product={hit}
                pplInfo={ppl}
                userFilialId={userFilialId}
                filialUserLinked={filialUserLinkedInRegister}
                setIndexFilialUser={setIndexFilialUser}
                isCheckout={isCheckout}
                isFisicStore={isFisicStore}
              />,
            );
          });
        }
      });

      setComponent(productComponent);
    }
  };

  useEffect(() => {
    handleProducts();
  }, [hasHits && hits]);

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      className={hitsContainer}
    >
      {/* <SubheaderFilters /> */}
      {(hasFilters && !!hits.length) && (
        <div>
          <DrawerToFilters
            items={refinements}
            customer={customer}
            user={user}
            handleSameFilialCheckbox={{
              sameFilialCheckbox,
              setSameFilialCheckbox,
            }}
            indexFilialUser={indexFilialUser}
            hits={hits}
          >
            {
              refinements.map((ref, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={`${index}`} className={filtersBox}>
                  {
                    // eslint-disable-next-line array-callback-return,consistent-return
                      ref.options.map(({ refinement }) => {
                        if (refinement) {
                          const {
                            isToggle, hasCollapse, isNumeric, isSlider,
                          } = refinement;

                          if (hasCollapse) {
                            return (
                              <Collapse
                                user={user}
                                customer={customer}
                                key={refinement.title}
                                refinement={refinement}
                                handleSameFilialCheckbox={{
                                  sameFilialCheckbox,
                                  setSameFilialCheckbox,
                                }}
                              />
                            );
                          }
                          if (isSlider) {
                            return <Slider key={refinement.title} refinement={refinement} />;
                          }
                          if (isNumeric) {
                            return <NumericMenu key={refinement.title} refinement={refinement} />;
                          }
                          if (isToggle) {
                            return (
                              <ToggleRefinement key={refinement.title} refinement={refinement} />
                            );
                          }
                          return <RefinementList key={refinement.title} refinement={refinement} />;
                        }
                      })
                    }
                </Box>
              ))
            }
          </DrawerToFilters>
        </div>
      )}

      <div className="ais-InfiniteHits">
        <ul className={`ais-InfiniteHits-list ${hitsBox} ${!hasHits && 'd-none'}`}>
          {component}
          <li
            className="ais-InfiniteHits-sentinel"
            ref={sentinelRef}
            aria-hidden="true"
          />
        </ul>
      </div>
    </Box>
  );
};

Hits.defaultProps = {
  hasFilters: false,
  hasHits: false,
  isCheckout: false,
};

Hits.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  resetSearch: PropTypes.func.isRequired,
  hasFilters: PropTypes.bool,
  hasHits: PropTypes.bool,
  categoryList: PropTypes.instanceOf(Array).isRequired,
  searchByCategory: PropTypes.func.isRequired,
  isCheckout: PropTypes.bool,
  facets: PropTypes.instanceOf(Array).isRequired,
};

const mapState = (state) => {
  const {
    categoryHierarchy,
  } = state.main.checkout.geral;

  return ({
    categoryList: categoryHierarchy,
  });
};

export default connect(mapState, {
  resetSearch: resetSearchAction,
  searchByCategory: searchByCategoryAction,
})(Hits);
