import apiService from '../../../shared/services/apiService';

export const postRegisterCustomer = async (form) => {
  const { data } = await apiService.post('/clientes/pessoa-fisica', {
    ...form,
  });

  return {
    ...data,
  };
};

export const postRegisterCorporateCustomer = async (form) => {
  const { data } = await apiService.post('/clientes/pessoa-juridica', {
    ...form,
  });

  return {
    ...data,
  };
};

export const getZipcode = async (cep) => {
  const { data } = await apiService.get(`/clientes/buscar-endereco-cep/${cep}`);

  return {
    ...data,
  };
};
