/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import {
  Box, Tooltip,
} from '@material-ui/core';
// import { common } from '@material-ui/core/colors';
import { ExpandMore } from '@material-ui/icons';
// import { number } from 'prop-types';
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
// import { toMoney } from '../../../utils/parsers';
import { FlatExpansionPanel, FlatExpansionPanelSummary } from '../../flatExpansionPanel';
// import ItemResumeOrderInfo from '../resumeOrderInfo/itemResumeOrderInfo';
import useStyles from './styles';

const TaxesInfo = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const togglePanel = () => setIsOpen(!isOpen);

  useHotkeys('shift+l', togglePanel, [togglePanel]);

  return (
    <FlatExpansionPanel expanded={isOpen} onChange={togglePanel}>
      <Tooltip title="shift + L" placement="left">
        <span>
          <FlatExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="taxes-content"
            id="taxes-header"
            className={classes.wrapper}
          >
            <Box className={classes.labelWrapper} flex={1}>
              <span className={classes.labelWrapper}>
                {/* {customerHaveTaxes ? (
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={calculateTax}
                    disabled={disabled}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCalculateChange({
                        calculateTax: !calculateTax,
                        originFromTax: true,
                      });
                    }}
                  />
                ) : (
                  )} */}

                <Box ml={2} />
                {/* <Typography component="h1" className={classes.label}>
                  Impostos
                </Typography> */}
              </span>

              {/* <Typography component="h1" className={classes.label}>
                {toMoney(totalTax)}
              </Typography> */}
            </Box>
          </FlatExpansionPanelSummary>
        </span>
      </Tooltip>

      <Box className={classes.taxes}>
        {/* <ItemResumeOrderInfo
          primary="Valor IPI"
          secondary={toMoney(ipiTax)}
          colorSecondary={common.black}
        /> */}

        {/* <ItemResumeOrderInfo
          primary="Valor ST"
          secondary={toMoney(stTax)}
          colorSecondary={common.black}
        /> */}

        {/* <ItemResumeOrderInfo
          primary="Valor ICMS"
          secondary={toMoney(icmsTax)}
          colorSecondary={common.black}
        /> */}

        {/* <ItemResumeOrderInfo
          primary="Valor FCP"
          secondary={toMoney(fcpTax)}
          colorSecondary={common.black}
        /> */}
      </Box>
    </FlatExpansionPanel>
  );
};

TaxesInfo.defaultProps = {
  // ipiTax: 0,
  // stTax: 0,
  // icmsTax: 0,
  // fcpTax: 0,
  // totalTax: 0,
};

TaxesInfo.propTypes = {
  // ipiTax: number,
  // stTax: number,
  // icmsTax: number,
  // fcpTax: number,
  // totalTax: number,
  // disabled: bool.isRequired,
  // customerHaveTaxes: bool.isRequired,
  // calculateTax: bool.isRequired,
  // onCalculateChange: func.isRequired,
};

export default TaxesInfo;
