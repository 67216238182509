import apiService from '../../../shared/services/apiService';
import { getFilialVendedor } from '../../../shared/services/app';

export const getRelatorioCoordenador = async ({
  filterDataInicio,
  filterDataFim,
  filterVendedor,
  filterStatus,
  filterCanal,
  filterValor,
  filterTipo,
  page,
  rpp,
}) => {
  const {
    data: { results, meta },
  // } = await apiService.get(`https://mocki.io/v1/a2f9320b-501a-45de-a359-4e2b967964cd`);
   } = await apiService.get(`recuperacao/coordenador/relatorio?dataInicio=${filterDataInicio}&dataFim=${filterDataFim}&page=${page}&rpp=${rpp}${filterVendedor ? `&idVendedor=${filterVendedor}` : ''}&categoria=${filterValor}&tipo=${filterTipo}&canal=${filterCanal}&status=${filterStatus}`);
  
  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};

export const getDetailsRelatorioCoordenador = async ({ checkoutId }) => {
  const {
    data: { results },
  } = await apiService.get(`recuperacao/coordenador/relatorio/${checkoutId}`);
  return {
    results,
  };
};
export const getVendedores = async () => {
  const {
    data: { results },
  } = await apiService.get('vendedores/recuperacao');
  return {
    results,
  };
};

export const getCheckouts = async ({ idVendedor }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`recuperacao/vendedor/${idVendedor}`);
  const checkouts = results.map((checkout) => ({
    checkoutId: checkout.checkoutId,
    codPedido: checkout.codPedido,
    dataPedido: checkout.dataPedido,
    name: checkout.nomeRazaoSocial,
    nomeSocial: checkout.nomeSocial,
    phone: checkout.telefoneCliente,
    email: checkout.emailCliente,
    ddd: checkout.dddCliente,
    valor: checkout.valor,
  }));

  const { size } = meta;

  return {
    checkouts,
    size,
  };
};

export const getCheckoutByDoc = async ({ docCliente }) => {
  const {
    data: { results, meta },
  } = await apiService.get(`carrinho/abandono/cliente?doc=${docCliente}`);
  const checkoutsSearch = results.map((checkout) => ({
    checkoutId: checkout.checkoutId, //
    dataPedido: checkout.dataOrcamento, //
    name: checkout.nomeRazaoSocial, //
    phone: checkout.telefoneCliente, //
    email: checkout.emailCliente, //
    ddd: checkout.dddCliente, //
    valor: checkout.valor, //
  }));

  const { size } = meta;

  return {
    checkoutsSearch,
    size,
  };
};

export const putVendedores = async ({ checkoutId, filterVendedor }) => {
  const {
    data: { results },
  } = await apiService.put(`recuperacao/${checkoutId}/vendedor/${filterVendedor}`);

  return {
    results,
  };
};

export const getCheckoutsDetails = async ({ checkoutId }) => {
  const { data: { results, meta } } = await apiService.get(`recuperacao/${checkoutId}`);

  return {
    results,
    meta,
  };
};
export const getAgendamentos = async ({ idVendedor }) => {
  const { data: { results, meta } } = await apiService.get(`recuperacao/agendamento/${idVendedor}`);

  return {
    results,
    meta,
  };
};
export const takeCheckout = async ({ idVendedor, checkoutId }) => {
  let filial = getFilialVendedor();
  const {
    data: { results },
  } = await apiService.post(`/orcamento/${checkoutId}/vendedor/${idVendedor}/recuperacao?idFilial=${filial}`);

  if (!results.response) throw new Error(results.message || results.description);
  return results.message;
};
export const recoveryDenied = async ({ idVendedor, checkoutId, motivo }) => {
  await apiService.put('/recuperacao/nao-quis-comprar', {
    checkoutId,
    idVendedor,
    motivo,
  });
};
export const recoveryGiveUp = async ({ idVendedor, checkoutId, motivo }) => {
  await apiService.put('/recuperacao/nao-quis-comprar', {
    checkoutId,
    idVendedor,
    motivo,
  });
};

export const agendamentoAccept = async ({
  idVendedor, checkoutId, observacao, dataHora,
}) => {
  const { data } = await apiService.post('/recuperacao/agendamento', {
    checkoutId,
    idVendedor,
    data: dataHora,
    observacao,
  });

  return data;
};
export const recuperar = async ({
  userId, checkoutId, codCliente,
}) => {
  const { data } = await apiService.post('/recuperacao/recuperar', {
    checkoutId,
    codCliente,
    userId,
  });

  return data;
};
export const getPedidos = async ({ idVendedor }) => {
  const { data: { results } } = await apiService.get(`recuperacao-vendas-pdv/vendedor/${idVendedor}`);
  return {
    results,
  };
};
export const postRecuperarPedidos = async ({ idRecuperacao }) => {
  const { data: { results } } = await apiService.post(`recuperacao-vendas-pdv/recuperar/${idRecuperacao}`);
  return {
    results,
  };
};
export const getListDeclinio = async () => {
  const { data } = await apiService.get('tipos-motivos-declinio');

  return {
    data,
  };
};
export const postDeclinio = async ({ idRecuperacao, idMotivo, mensagemMotivo }) => {
  await apiService.post('motivo-declinio-recuperacao-vendas-pdv', {
    idRecuperacao,
    idMotivo,
    mensagemMotivo,
  });
};
