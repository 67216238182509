import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, transitions }) => ({
	container: {
		[breakpoints.down('sm')]: {
			marginBottom: '90px',
		}
	},
	name: {
		[breakpoints.down('sm')]: {
			width: 'auto',
		},
		[breakpoints.up('md')]: {
			width: '25%',
		},
	},
	amount: {
		[breakpoints.down('sm')]: {
			width: 'auto',
		},
		[breakpoints.up('md')]: {
			width: '15%',
		},
	},
	points: {
		[breakpoints.down('sm')]: {
			width: 'auto',
		},
		[breakpoints.up('md')]: {
			width: '10%',
		},
	},
	paginationContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	customerCell: {
		textTransform: 'uppercase',
		textAlign: 'center',
	},
	tableHeadRow: {
		'& .MuiTableCell-root': {
			fontWeight: 'bold',
			padding: '10px 20px',
		},
	},
	tableHeadCell: {
		textAlign: 'center',
		'& .MuiTableCell-root': {
			// Add your styles for MuiTableCell-root here
		},
	},
	tableBodyRow: {
		'& .MuiTableCell-root': {
			padding: '0 20px',
		},
		[breakpoints.down('md')]: {
			margin: '16px 0',
		}
	},
	tableBodyCell: {
		textAlign: 'center',
	},
	tableBodyCellClickable: {
		textAlign: 'center',
		'&:hover': {
			'& .MuiBox-root': {
				color: '#FF5225',
				transition: `color .1s`,
			},
		},
		fontWeight: '600',
		cursor: 'pointer',
		color: 'primary',
	},
	percentageDiscount: {
		fontWeight: '600',
		cursor: 'pointer',
		color: '#FF5225',
	},
	actions: {
		display: 'flex',
		justifyContent: 'center',
		[breakpoints.down('md')]: {
			'& .MuiButtonBase-root': {
				padding: '0',
				marginBottom: '16px'
			}
		}
	}
}));

export default useStyles;
