import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
    userSelect: 'none',
  },
  avatar: {
    marginRight: 8,
    backgroundColor: palette.primary.main,
    transition: 'all 298ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 198ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    [breakpoints.down('sm')]: {
      backgroundColor: '#ffffff',
      color: palette.primary.main,
    },
  },
  name: {
    fontSize: 14,
    fontWeight: 600,
  },
  sector: {
    fontSize: 12,
    fontWeight: 400,
    opacity: 0.67,
  },
  info: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menu: {
    width: 280,
    textAlign: 'center',
  },
  bigAvatar: {
    margin: 'auto',
    backgroundColor: palette.primary.main,
    width: 80,
    height: 80,
  },
  userName: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '21px',
    textTransform: 'capitalize',
  },
  // sectorName: {
  //   fontSize: 14,
  //   fontWeight: 400,
  //   color: '#5f6369',
  //   lineHeight: '19px',
  // },
}));

export default useStyles;
