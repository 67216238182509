import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  aditionalHeader: {
    fontSize: 16,
    fontWeight: 600,
  },
  aditionalInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    color: palette.text.grey1,
    '&:not(:last-of-type)': {
      marginTop: 16,
    },
    '& p:not(:first-of-type)': {
      fontWeight: 600,
    },
  },
  primaryText: {
    color: palette.primary.main,
  },
  resumeContainer: {
    display: 'flex',
    padding: '8px 16px',
    color: palette.text.grey1,
    justifyContent: 'space-between',
    '& > h6:last-of-type': {
      fontWeight: 600,
      color: palette.success.main,
    },
  },
  discountStatus: {
    color: ({ discountStatus }) => ({
      'Desconto reprovado': palette.error.main,
      'Desconto aprovado': palette.success.main,
      'Aguardando aprovação': palette.text.grey1,
    }[discountStatus]),
  },
  badge: {
    fontSize: 10,
    minWidth: 0,
    padding: 4,
    height: 12,
  },
  labelButton: {
    border: 'none',
    color: ({ readOnly }) => readOnly && 'rgba(0, 0, 0) !important',
    backgroundColor: 'transparent',
    textTransform: 'none',
    fontWeight: 400,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
