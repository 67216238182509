import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  dialogTitleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .MuiIconButton-sizeSmall': {
      color: '#242424',
    },
  },
  dialogTitleTypography: {
    fontSize: 20,
    fontWeight: 600,
  },
  divider: {
    paddingBottom: 16,
  },
  alertMessage: {
    margin: 6,
    marginTop: 12,
  },
  dialogActions: {
    padding: '16px 24px 24px',
  },
  dialogPaper: {
    borderRadius: 16,
  },
  dialogContent: {
    '& label': {
      color: '#7B7F82',
    },

    '& .MuiInputBase-root': {
      '&.Mui-disabled': {
        background: '#F6F6F7',
        color: '#7B7F82',
      },

      '& input.Mui-disabled': {
        cursor: 'not-allowed',
      },
    },
  },
  inputGrid: {
    '& p': {
      margin: 0,
      lineHeight: '14px',
      marginTop: 4,
    },
  },
}));

export default useStyles;
