import React, { useState } from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Close} from '@material-ui/icons';
import ResponsiveTable from '../../../shared/components/responsiveTable';
import { push } from '../../../history';
import alertIcon from '../../../assets/alertClose.png';
import { getCheckoutAction } from '../../checkout/actions';
import useStyles from "./styles";
import CheckoutsTableBody from "../_components/checkoutsTableBody/CheckoutsTableBody";


const Orcamentos = ({
  checkouts, loading, checkout,
})  => {
  const classes = useStyles();
  const {
    tableHead,
    tableRow,
    tableCellHead,
    tableBody,
    checkoutTable,
  } = classes;

  const [openModal, setOpenModal] = useState(false);
  const { REACT_APP_HOST_CATALAGO } = process.env;

  const handleClose = async () => {
    setOpenModal(false);
  };

  const postCheckout = async (id) => {
    push(`/checkout/${id}`);
  };

  return (
  <>
  <ResponsiveTable loading={loading} striped className={checkoutTable}>
    <TableHead classes={{ root: tableHead }}>
      <TableRow classes={{ root: tableRow }}>
        <TableCell classes={{ root: tableCellHead }} component="th">
          Orçamento
        </TableCell>
        <TableCell classes={{ root: tableCellHead }} component="th">
          Cliente
        </TableCell>
        <TableCell classes={{ root: tableCellHead }} component="th">
          Documento
        </TableCell>
        <TableCell classes={{ root: tableCellHead }} component="th">
          Itens
        </TableCell>
        <TableCell classes={{ root: tableCellHead }} component="th">
          Telefone
        </TableCell>
        <TableCell classes={{ root: tableCellHead }} component="th">
          Valor
        </TableCell>
        <TableCell classes={{ root: tableCellHead }} component="th">
          Última Alteração
        </TableCell>
        <TableCell classes={{ root: tableCellHead }} component="th">
          Criação
        </TableCell>
        <TableCell classes={{ root: tableCellHead }} component="th">
          Vendedor
        </TableCell>
        <TableCell classes={{ root: tableCellHead }} component="th" />
      </TableRow>
    </TableHead>
    <TableBody classes={{ root: tableBody }}>
      {loading && (
        <TableRow>
          <TableCell colSpan={12} datatype="Mensagem" className="message">
            Buscando Orçamentos
          </TableCell>
        </TableRow>
      )}

      {!loading && !checkouts.length && (
        <TableRow>
          <TableCell colSpan={10} datatype="Mensagem" className="message">
            Nenhum orçamento foi encontrado
          </TableCell>
        </TableRow>
      )}
      {!loading && checkouts.map((item, index) => (
        <CheckoutsTableBody
        checkout={item}
        index={index}
        key={index}
        />
      ))}
    </TableBody>
  </ResponsiveTable>


          <Dialog onClose={handleClose} open={openModal} maxWidth="sm">
            <DialogTitle position="relative">
              <Box position="absolute" top={4} right={4}>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <div style={{textAlign: 'center'}}>
                <img src={alertIcon} alt="alertIcon"   style={{margin: 'auto'}} />
              </div>
              <div style={{textAlign: 'center'}}>
                <br />
                Não foi possível acessar o orçamento.<br />
                Para continuar é necessário adicionar<br />um endereço de entrega.<br /> <br />

                  <Button
                    style={{backgroundColor: '#FF5225', color: '#ffff', marginTop: 10, marginBottom: 15, width: 275}}
                    onClick={() => {
                      window.location.href = `${REACT_APP_HOST_CATALAGO
                      }/cliente-${checkout?.customer?.typePerson === 'F' ? 'enderecos' : 'empresas'
                      }/${checkout?.customer?.customerId}/checkout/${checkout?.checkoutId}`;
                    }}
                  >
                    ADICIONAR ENDEREÇO
                  </Button>

              </div>

            </DialogContent>

          </Dialog>

  </>
  );
};

const mapState = (state) => {
  const { checkout } = state.main.checkout.geral;
  return ({
    ...state.main.checkoutsAndOrders,
    checkout,
  });
};

Orcamentos.propTypes = {
  checkouts: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapState, {
  getCheckout: getCheckoutAction,
})(Orcamentos);
