import { makeStyles } from '@material-ui/core';
import { CartIconOrange } from '../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  cartBox: {
    '& button': {
      textTransform: 'capitalize',

      '&:hover': {
        background: 'none',
      },

      '& .MuiButton-label': {
        display: 'flex',
        gap: 8,
      },
    },

    '& .MuiButton-label span:last-child': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '15px',
      color: '#FE5000',
      paddingTop: 12,
    },

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cartIcon: {
    display: 'block',
    width: 30,
    height: 30,
    background: `url(${CartIconOrange})`,
    backgroundSize: 30,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  itemsQty: {
    position: 'absolute',
    background: '#21AD34',
    color: '#FFFFFF !important',
    borderRadius: '100%',
    height: 25,
    minWidth: 25,
    fontSize: '10px !important',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    top: -5,
    lineHeight: '12px !important',
    padding: 8,
    left: 21,
  },
}));

export default useStyles;
