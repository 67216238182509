/* istanbul ignore file */

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import Root from './root';
import * as serviceWorker from './serviceWorker';

const {
  REACT_APP_SENTRY,
  REACT_APP_ENV,
} = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY,
});

ReactDOM.render(<Root />, document.getElementById('root'));

if (REACT_APP_ENV === 'dev') {
  serviceWorker.unregister();
} else {
  // teste do clearCache
  serviceWorker.register();
  // serviceWorker.unregister();
}
