import React, { useState } from 'react';
import { Popper, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles, { AutocompleteStyled } from './styles';
import { toThousand } from '../../../utils/parsers';

const Autocomplete = ({
  value, setValue, onClickDisabled, ...others
}) => {
  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { arrowBtn, arrowIcon } = classes;

  const PopperStyled = (props) => (
    <Popper
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      modifiers={{
        flip: {
          enabled: false,
        },
      }}
      popperOptions={{
        placement: 'top',
      }}
    />
  );

  const onChange = (newValue) => {
    setValue(newValue);
    setOpen(false);
  };

  const handleOpen = () => setOpen(!open);

  return (
    <AutocompleteStyled
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
      freeSolo
      disableClearable
      options={numbers}
      value={toThousand(value)}
      inputValue={toThousand(value)}
      onChange={(event, newValue) => setValue(newValue)}
      onInputChange={(event, inputNewValue) => onChange(toThousand(inputNewValue))}
      open={open}
      renderInput={(params) => (
        <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          type="number"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <button
                aria-label="quantity product"
                type="button"
                className={arrowBtn}
                onClick={onClickDisabled ? () => {} : handleOpen}
              >
                <span className={`${arrowIcon} ${open && 'open'}`} />
              </button>
            ),
          }}
        />
      )}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
      PopperComponent={(props) => <PopperStyled {...props} />}
    />
  );
};

Autocomplete.defaultProps = {
  onClickDisabled: false,
};

Autocomplete.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,
  onClickDisabled: PropTypes.bool,
};

export default Autocomplete;
