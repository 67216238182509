import React from 'react';
import { withStyles, Card, useTheme } from '@material-ui/core';

const FlatCard = (props) => {
  const { palette } = useTheme();

  const Wrapper = withStyles({
    root: {
      boxShadow: 'none',
      backgroundColor: palette.background.default,
      color: palette.text.grey1,
    },
  })(Card);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Wrapper {...props} />;
};

export default FlatCard;
