import { Box, Button, TextField, Tooltip } from '@material-ui/core';
import {
  bool, func, number, oneOfType, string,
} from 'prop-types';
import React, { useState, useEffect } from 'react';
import { toFloat, toMoney } from '../../../../shared/utils/parsers';

import useStyles from './style';
import PropTypes from 'prop-types';

const CheckoutItemDiscount = ({
  ppl, idSeller, loading, onSubmit, setPercent, percent, amount, justify, setJustify,
  productId, subTotalOnTime, setAmount, orderId, payments, total, quantity, discount, initialPercent,
}) => {
  const classes = useStyles();
  const [isPercent, setIspercent] = useState(true);
  const [oldPercent, setOldPercent] = useState(0);

  const handleBlur = async (e) => {
    if (oldPercent !== percent) {
      if (isPercent) {
        await onSubmit({ productId, justify, porcentagemDesconto: percent, total: subTotalOnTime  });
      } else {
        await onSubmit({ productId, amount: amount, justify, total: subTotalOnTime, quantidade: quantity  });
      }
    } 
    setPercent(initialPercent);
  };

  useEffect(() => {
    setAmount(discount);
  }, [discount]);

  const handleFocus = (e) => {
    setOldPercent(percent)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (oldPercent !== percent) {
      if (isPercent) {
        await onSubmit({ productId, justify, porcentagemDesconto: percent, total: subTotalOnTime  });
      } else {
        await onSubmit({ productId, amount: amount, justify, total: subTotalOnTime, quantidade: quantity  });
      }
    } 
    setPercent(initialPercent);
  };

  const handlePercent = ({ target: { value } }) => {
    if (isPercent) {
      const parsedValue = +value.replace(/\D/g, '') / 100;
      return setPercent(parsedValue >= 100 ? 99 : parsedValue);
    }

    const parsedValue = +value.replace(/\D/g, '') / 100;
    setAmount(parsedValue > total ? total : parsedValue);
    return setPercent(
      parsedValue >= subTotalOnTime
        ? 100
        : ((parsedValue / subTotalOnTime) * 100).toFixed(2),
    );
  };
  const handlePercentPPL = ({ target: { value } }) => {
    if (isPercent) {
      const parsedValue = +value.replace(/\D/g, '') / 100;
      return setPercent(parsedValue > 10 ? 10 : parsedValue);
    }
    const parsedValue = +value.replace(/\D/g, '') / 100;
    setAmount(parsedValue >= (subTotalOnTime / 10) ? (subTotalOnTime / 10) : parsedValue);
    return setPercent(
      parsedValue >= subTotalOnTime
        ? 10
        : ((parsedValue / subTotalOnTime) * 100).toFixed(0),
    );
  };

  const paymentBP = payments && payments.find((a) => a.type === 'BP');
  let descontoDesabilitado = false;
  if (paymentBP !== undefined) {
    if (paymentBP?.type === "BP") {
      // descontoDesabilitado = true;
    }
  }

  const hasCoupon = payments?.filter((item) => { return item.type === 'VA' || item.type === 'CP' })?.length > 0

  return !idSeller && (
    <form
      onSubmit={handleSubmit}
      className={classes.groupPrice}
    >
      <Tooltip
        title={
          hasCoupon 
          && 'Para inclusão/exclusão ou edição de desconto, deverá ser removido os pagamentos do tipo vale e cupom.' 
        }
      >
        <Box>
          <TextField
            disabled={loading || !!orderId || descontoDesabilitado || hasCoupon}
            className={classes.inputDiscount}
            size="small"
            label="Desconto"
            name="discount"
            variant="outlined"
            onChange={ppl ? handlePercentPPL : handlePercent}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={isPercent ? `% ${toFloat(percent)}` : toMoney(+amount)}
            inputProps={{
              style: (loading ? {padding: '10.5px 6px', color: 'transparent'} : { padding: '10.5px 6px' }),
            }}
          />

          <Button
            variant="outlined"
            className={classes.discountButton}
            onClick={() => setIspercent(!isPercent)}
          >
            {isPercent ? 'R$' : '%'}
          </Button>
        </Box>
      </Tooltip>

      <Box display="none">
        <input type="submit" />
      </Box>
    </form>
  );
};

CheckoutItemDiscount.defaultProps = {
  idSeller: null,
  ppl: false,
  orderId: null,
  payments: undefined,
};

CheckoutItemDiscount.propTypes = {
  productId: number.isRequired,
  subTotalOnTime: number.isRequired,
  percent: oneOfType([string, number]).isRequired,
  initialPercent: oneOfType([string, number]).isRequired,
  discount: oneOfType([string, number]).isRequired,
  amount: number.isRequired,
  orderId: number,
  onSubmit: func.isRequired,
  total: number.isRequired,
  quantity: number.isRequired,
  setJustify: func.isRequired,
  setAmount: func.isRequired,
  setPercent: func.isRequired,
  loading: bool.isRequired,
  ppl: bool,
  justify: string.isRequired,
  idSeller: oneOfType([string, number]),
  payments: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

export default CheckoutItemDiscount;
