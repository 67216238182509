import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  formControlLabel: {
    '&& .MuiTypography-root' :{ 
      fontSize: 14,
      fontWeight: 500,
      color: '#7B7F82'
    },
  },
}));

export default useStyles;
