import { handleActions, combineActions } from 'redux-actions';
import Types from './types';
import TYPE_PAGES from './typePages';

const INITIAL_STATE = {
  page: TYPE_PAGES.ORCAMENTOS,
  filterOpened: false,
  loadings: {
    order: false,
    checkout: false,
    pdf: false,
  },
  checkouts: [],
  orders: [],
  ordersPagination: {
    pageCount: 1,
    page: 1,
    size: 0,
    rpp: 10,
  },
  checkoutsPagination: {
    pageCount: 1,
    page: 1,
    size: 0,
    rpp: 10,
  },
  deliveryDetails: [],
};

const stopLoadingPDF = combineActions(
  Types.PDF_ORCAMENTOS_SUCCESS,
  Types.PDF_ORCAMENTOS_FAILURE,
  Types.PDF_PEDIDO_SUCCESS,
  Types.PDF_PEDIDO_FAILURE,
);

const stopLoadingCheckout = combineActions(
  Types.ORCAMENTOS_SUCCESS,
  Types.ORCAMENTOS_FAILURE,
);

const stopLoadingOrder = combineActions(
  Types.PEDIDO_SUCCESS,
  Types.PEDIDO_FAILURE,
  Types.RESET_FILTERS_FAILURE,
);

export default handleActions({
  [Types.SET_PAGE]: (state, action) => {
    const { page } = action.payload;
    return {
      ...state,
      page,
    };
  },
  [Types.TOGGLE_FILTER]: (state, action) => {
    const { filterOpened } = action.payload;
    return {
      ...state,
      filterOpened,
    };
  },
  [Types.ORCAMENTOS_REQUEST]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      checkout: true,
    },
  }),
  [Types.PDF_ORCAMENTOS_REQUEST]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      pdf: true,
    },
  }),
  [stopLoadingPDF]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      pdf: false,
    },
  }),
  [stopLoadingCheckout]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      checkout: false,
    },
  }),
  [stopLoadingOrder]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      order: false,
    },
  }),
  [Types.ORCAMENTOS_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [Types.PEDIDO_REQUEST]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      order: true,
    },
  }),
  [Types.PDF_PEDIDO_REQUEST]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      pdf: true,
    },
  }),
  [Types.PEDIDO_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [Types.DELIVERY_DETAILS_REQUEST]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      deliveryDetails: true,
    },
  }),
  [Types.DELIVERY_DETAILS_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
    loadings: {
      ...state.loadings,
      deliveryDetails: false,
    },
  }),
  [Types.DELIVERY_DETAILS_FAILURE]: (state) => ({
    ...state,
    loadings: {
      ...state.loadings,
      deliveryDetails: false,
    },
  }),
  [Types.RESET_DELIVERY_DETAILS]: (state) => ({
    ...state,
    deliveryDetails: [],
  }),
}, INITIAL_STATE);
