import { setCookie, getCookie } from '../utils/cookieApi';

const APP_KEY = 'PDV-LOJA-DO-MECANICO';

export const getClient = () => {
  const client = getCookie(`${APP_KEY}-CLIENT`);
  return client ? JSON.parse(client) : null;
};

export const clearClient = () => {
  setCookie(
    `${APP_KEY}-CLIENT`,
    '',
    -1,
  );

  setCookie(
    `${APP_KEY}-authPhp`,
    'false',
    -1,
  );
};

export const setClient = (client) => {
  clearClient();

  setCookie(
    `${APP_KEY}-CLIENT`,
    JSON.stringify(client),
    7,
  );
};
