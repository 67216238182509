import React from 'react';
import {
  Badge, Box, Button, Tooltip,
} from '@material-ui/core';

import * as PropTypes from 'prop-types';
import useStyles from './styles';

const PaymentMethod = ({
  src, title, onClick, disabled, Icon, hasNotification,
}) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={classes.root}
      startIcon={Icon
        ? <Icon className={classes.img} />
        : (
          <img
            className={classes.img}
            src={src}
            alt={title}
          />
        )}
    >
      {title}
      {hasNotification && (
        <Box position="absolute" top={0} right={10}>
          <Tooltip title="Esse orçamento possui pagamentos reprovados">
            <Badge variant="dot" color="primary" />
          </Tooltip>
        </Box>
      )}
    </Button>
  );
};

PaymentMethod.defaultProps = {
  disabled: false,
  hasNotification: false,
  Icon: null,
  src: null,
};

PaymentMethod.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hasNotification: PropTypes.bool,
  Icon: PropTypes.instanceOf(Object),
};

export default PaymentMethod;
