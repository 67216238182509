const PAYMENT_OPTIONS_NO_PIX = [
  {
    code: 'B',
    name: 'Boleto',
  },
  // {
  //   code: 'LP',
  //   name: 'LDM PRO',
  // },
  // {
  //   code: 'ML',
  //   name: 'Mercado Livre',
  // },
  {
    code: 'DN',
    name: 'Dinheiro - Pagamento Caixa',
  },
  {
    code: 'DC',
    name: 'Depósito em Conta',
  },
  {
    code: 'CS',
    name: 'Consórcio',
  },
  {
    code: 'FN',
    name: 'Financiamento',
  },
  {
    code: 'BP',
    name: 'Boleto à Prazo',
  }
  // {
  //   code: 'B2',
  //   name: 'MarketPlace B2W',
  // },
  // {
  //   code: 'AZ',
  //   name: 'MarketPlace Amazon',
  // },
  // {
  //   code: 'MZ',
  //   name: 'Magalu',
  // },
];

export default PAYMENT_OPTIONS_NO_PIX;
