import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Box,
  Button,
  Typography,
  Paper,
  Modal,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import CardBarSale from '../../../../../shared/components/cardBarSale';
import ResponsiveTable from '../../../../../shared/components/responsiveTable';
import DialogNewChannel from './DialogNewChannel';
import {
  getCanalDeVendasAction,
} from '../../../actions';
import useStyles from '../../../styles';

const SalesChannel = ({
  loading, canaisDeVenda, getCanalDeVendas, totalpagesCanalVendas,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [dialog, setDialog] = useState(false);
  const [dialogRefresh, setDialogRefresh] = useState(false);

  const handleToggle = () => setDialog(!dialog);
  const handleToggleRefresh = () => setDialogRefresh(!dialogRefresh);

  useEffect(() => {
    getCanalDeVendas({ page });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className={classes.divRow}>
      <div style={{ width: '100%', minHeight: '80vh' }}>
        <CardBarSale title="Home" />
        {loading && (<LinearProgress />)}
        <div className={classes.titleAndButton}>
          <span>Canal de Vendas</span>
          <Button
            className={classes.buttonTitle}
            onClick={handleToggle}
          >
            NOVO CANAL
          </Button>
        </div>
        <section className={classes.sessionTable}>
          <Paper elevation={1} style={{ width: '95%' }}>
            <ResponsiveTable pointer>
              <TableHead style={{ backgroundColor: '#F9F9F9' }}>
                <TableRow>
                  <TableCell component="th" align="left">
                    NOME
                  </TableCell>
                  <TableCell component="th">
                    ID BSELLER
                  </TableCell>
                  <TableCell component="th">
                    UNIDADE DE NEGÓCIOS
                  </TableCell>
                  <TableCell component="th">
                    SUPERVISOR
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {canaisDeVenda.map((item) => (
                  <TableRow
                    key={item.idCanalVendas}
                    hover
                  >
                    <TableCell datatype="Nome" align="left">
                      <Box>
                        {item.descricao}
                      </Box>
                    </TableCell>

                    <TableCell datatype="ID BSeller" style={{ color: '#888E9D' }}>
                      {item.idBseller}
                    </TableCell>

                    <TableCell datatype="Unidade de Negócios" style={{ color: '#888E9D' }}>
                      {item.unidadeDeNegocio}
                    </TableCell>

                    <TableCell datatype="Supervisor" style={{ color: '#888E9D' }}>
                      {item.supervisor}
                    </TableCell>

                  </TableRow>
                ))}

                {!canaisDeVenda.length && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Typography variant="body2">
                      {loading ? 'Buscando lista de canais de venda...' : 'Nenhum Canal Encontrado'}
                    </Typography>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </ResponsiveTable>
          </Paper>
        </section>

      {dialog
       && (
       <Modal
         open={dialog}
         onClose={handleToggle}
         className={classes.modal}
       >
         <DialogNewChannel
           dialog={dialog}
           handleToggle={handleToggle}
           page={page}
         />
       </Modal>
       )}


      </div>
      <div style={{
        display: 'flex', justifyContent: 'flex-end', marginBottom: 30, marginRight: 50,
      }}
      >
        <Pagination
          count={totalpagesCanalVendas}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

SalesChannel.propTypes = {
  loading: PropTypes.bool.isRequired,
  canaisDeVenda: PropTypes.arrayOf(Object).isRequired,
  getCanalDeVendas: PropTypes.func.isRequired,
  totalpagesCanalVendas: PropTypes.number.isRequired,
  // checkoutMeta: PropTypes.instanceOf(Object).isRequired,
  // clearMeta: PropTypes.func.isRequired,
  // takeCheckout: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.sales;

export default connect(mapState, {
  getCanalDeVendas: getCanalDeVendasAction,
})(SalesChannel);
