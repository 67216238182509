import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  label: {
    fontWeight: 500,
  },
  labelWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapper: {
    paddingLeft: 0,

    '& > div.MuiAccordionSummary-content': {
      margin: '0 !important',
    },
  },
  taxes: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: 16,
  },
});

export default useStyles;
