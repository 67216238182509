export const isExclusivePosition = (idCargo) => {
  const exclusives = [
    { id: 2, nome: 'Supervisor Vendas' },
    { id: 7, nome: 'Coordenador de Vendas' },
    { id: 8, nome: 'Gerente de Vendas' },
    { id: 15, nome: 'Gerente Distrital' },
    { id: 18, nome: 'Gerente Geral de Lojas' },
  ];

  return exclusives.some(({ id }) => id === Number(idCargo));
};

export const findStatusPayment = (statusId) => {
  const list = [
    {
      id: 1,
      descricao: 'Aguardando pagamento',
      color: 'rgb(254 189 1)',
      colorName: 'yellow',
    },
    {
      id: 2,
      descricao: 'Pagamento aprovado',
      color: 'rgb(33 173 52)',
      colorName: 'green',
    },
    {
      id: 3,
      descricao: 'Pagamento cancelado',
      color: 'rgb(244 40 45)',
      colorName: 'red',
    },
  ];

  return list.find(({ id }) => id === statusId);
};

export const trackingList = [
  { key: 'AES', value: '(AES) - Aguardando estoque' },
  { key: 'AEW', value: '(AEW) - Aguardando WMS' },
  { key: 'ASE', value: '(ASE) - A Separar' },
  { key: 'CAN', value: '(CAN) - Entrega cancelada' },
  { key: 'DEVP', value: '(DEVP) - Devolucao Parcial' },
  { key: 'DEVT', value: '(DEVT) - Devolucao Total' },
  { key: 'ENT', value: '(ENT) - Entregue ao Cliente' },
  { key: 'ETR', value: '(ETR) - Entregue a Transportadora' },
  { key: 'IVE', value: '(IVE) - Item virtual enviado' },
  { key: 'NFA', value: '(NFA) - NFe Aprovada' },
  { key: 'NFS', value: '(NFS) - NF emitida' },
  { key: 'PAP', value: '(PAP) - Pedido aprovado' },
  { key: 'PCE', value: '(PCE) - Problemas com a entrega' },
  { key: 'PDL', value: '(PDL) - Pedido Liquidado' },
  { key: 'PEI', value: '(PEI) - Pedido Incluido' },
  { key: 'PNA', value: '(PNA) - Pagamento nao aprovado' },
  { key: 'POK', value: '(POK) - Pagamento OK' },
  { key: 'PRP', value: '(PRP) - Pronto para Analise Pagto' },
  { key: 'RET', value: '(RET) - Mercadoria retirada no CD/LOJA' },
  { key: 'RIE', value: '(RIE) - Recebimento de Insucesso de Entrega' },
  { key: 'ROU', value: '(ROU) - Roubo' },
  { key: 'SEP', value: '(SEP) - Separado' },
  { key: 'WMS', value: '(WMS) - No WMS' },
  { key: 'PEA', value: '(PEA) - Pedido em Análise' },
  { key: 'PGA', value: '(PGA) - Pagamento em Análise' },
];
