import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Typography,
  Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import CardBarSale from '../../../../../shared/components/cardBarSale';
import ResponsiveTable from '../../../../../shared/components/responsiveTable';
import useStyles from '../../../styles';
import { getUnNegociosAction } from '../../../actions';

const BusinessUnit = ({
  loading, getUnNegocios, unNegocios, totalpagesUnNegocios,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);

  useEffect(() => {
    getUnNegocios({ page });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <div className={classes.divRow}>
      <div style={{ width: '100%', minHeight: '80vh' }}>
        <CardBarSale title="Home" />
        {loading && (<LinearProgress />)}
        <div className={classes.titleAndButton}>
          <span>Unidade de Negócios</span>
        </div>
        <section className={classes.sessionTable}>
          <Paper elevation={1} style={{ width: '95%' }}>
            <ResponsiveTable pointer>
              <TableHead style={{ backgroundColor: '#F9F9F9' }}>
                <TableRow>
                  <TableCell component="th" align="left">
                    Nome
                  </TableCell>
                  <TableCell component="th">
                    ID BSeller
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {unNegocios.map((item) => (
                  <TableRow
                    key={item.idUnidadeNegocio}
                    hover
                  >
                    <TableCell datatype="Grupo" align="left">
                      {item.nomeUnidadeNegocio}
                    </TableCell>
                    <TableCell datatype="Grupo" align="left">
                      {item.idBseller}
                    </TableCell>

                  </TableRow>
                ))}

                {!unNegocios.length && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Typography variant="body2">
                      {loading ? 'Buscando lista de unidades de negocio...' : 'Nenhuma lista Encontrada'}
                    </Typography>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </ResponsiveTable>
          </Paper>
        </section>

      </div>
      <div style={{
        display: 'flex', justifyContent: 'flex-end', marginBottom: 30, marginRight: 50,
      }}
      >
        <Pagination
          count={totalpagesUnNegocios}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

BusinessUnit.propTypes = {
  loading: PropTypes.bool.isRequired,
  getUnNegocios: PropTypes.func.isRequired,
  unNegocios: PropTypes.arrayOf(Object).isRequired,
  totalpagesUnNegocios: PropTypes.number.isRequired,
  // checkoutMeta: PropTypes.instanceOf(Object).isRequired,
  // clearMeta: PropTypes.func.isRequired,
  // takeCheckout: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.sales;

export default connect(mapState, {
  getUnNegocios: getUnNegociosAction,
})(BusinessUnit);
