import apiService from '../../../shared/services/apiService';

export const getDiscountDetails = async (descountId, idUser) => {
	const {
		data: { content, message },
	} = await apiService.get(`/solicitacao-desconto/detalhes/${descountId}/aprovador/${idUser}`);

	return {
		content,
	};
};
