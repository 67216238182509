import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  infoContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  labelField: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    color: '#7B7F82',
    marginBottom: 6,
  },
  valueField: {
    fontSize: 16,
    fontWeight: 600,
    color: '#242424',
    minHeight: 22,
  },
  disabledField: {
    color: '#7B7F82',
  },
}));

export default useStyles;
