import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  change,
  Form,
  formValueSelector,
  hasSubmitFailed,
  reduxForm,
  submit,
} from 'redux-form';
import { PropTypes } from 'prop-types';
import { Search } from '@material-ui/icons';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { push } from '../../../../history';

import { getZipcodeDataAction, requestRegisterCorporateCustomerAction } from '../actions';
import { AlertMessage, CheckboxForm, SelectForm } from '../_components';
import { Card, ButtonActions } from '../../_components';
import { InputFieldForm } from '../../_components/forms';

import { InputCEPRender, InputCnpjRender, InputPhoneRender } from '../../../../shared/fields';
import {
  cnpjValid,
  email,
  minLength6,
  minLength8,
  minLength10,
  maxLenght9,
  maxLenght100,
  required,
  number,
  maxLength5,
  cellphone,
} from '../../../../shared/fields/validate';

import CircularLoading from '../../../../shared/components/loading/CircularLoading';
import AlertModal from '../_components/alertModal/AlertModal';

import useStyles from './styles';

const CorporatePerson = ({
  loading,
  handleSubmit,
  getZipcodeData,
  requestRegisterCorporateCustomer,
  snack,
  submitFailed,
  cep,
  semNumero,
  tipoContribuinte,
  suframa,
  bitGenerico,
}) => {
  const {
    cardGrid,
    cardContent,
    contentTitle,
    complementoMobileGrid,
    complementoGrid,
    complementoStyled,
    gridStyled,
  } = useStyles();

  const anyLoading = loading.customer || loading.customerDocumentValidate || loading.zipcode;
  const dispatch = useDispatch();

  const showIE = tipoContribuinte !== 0 && [1, 6, 8].includes(tipoContribuinte);
  const handleRemoteSubmit = () => {
    dispatch(submit('register/CorporatePerson'));
  };

  const handleRequest = async (form) => {
    await requestRegisterCorporateCustomer(form);
  };

  const [customerModal, setCustomerModal] = useState(false);

  const handleCloseModalCustomer = () => {
    setCustomerModal(false);
    dispatch(change('register/CorporatePerson', 'cnpj', ''));
  };

  const handleAcceptModalCustomer = () => {
    setCustomerModal(false);
    push('/client-login');
  };

  const handleCepRequest = () => {
    if (loading.zipcode) return;
    if (cep.length < 8) return;

    getZipcodeData(cep).then((res) => {
      dispatch(change('register/CorporatePerson', 'endereco.bitGenerico', res.flagcepgenerico));
      dispatch(change('register/CorporatePerson', 'endereco.logradouro', res.logradouro));
      dispatch(change('register/CorporatePerson', 'endereco.bairro', res.bairro));
      dispatch(change('register/CorporatePerson', 'endereco.cidade', res.localidade));
      dispatch(change('register/CorporatePerson', 'endereco.estado', res.uf));
    }).catch((_) => {
      dispatch(change('register/CorporatePerson', 'endereco.bitGenerico', false));
      dispatch(change('register/CorporatePerson', 'endereco.logradouro', ''));
      dispatch(change('register/CorporatePerson', 'endereco.bairro', ''));
      dispatch(change('register/CorporatePerson', 'endereco.cidade', ''));
      dispatch(change('register/CorporatePerson', 'endereco.estado', ''));
    });
  };

  useEffect(() => {
    dispatch(change('register/CorporatePerson', 'endereco.numero', ''));
  }, [semNumero]);

  useEffect(() => {
    dispatch(change('register/CorporatePerson', 'inscricaoSuframa', ''));
  }, [suframa]);

  useEffect(() => {
    dispatch(change('register/CorporatePerson', 'ie', ''));
  }, [tipoContribuinte]);

  useEffect(() => {
    if (submitFailed) snack.enqueueSnackbar('Dados inválidos. Verifique os dados.', { variant: 'error' });
  }, [submitFailed]);

  return (
    <Grid className={cardGrid}>
      <AlertModal
        open={customerModal}
        title="Cliente já cadastrado"
        message="O cliente já se encontra cadastrado, deseja fazer login?"
        handleClose={handleCloseModalCustomer}
        handleAccept={handleAcceptModalCustomer}
      />
      <CircularLoading open={loading.customer} message="Cadastrando empresa..." />
      <Card
        title="Cadastro de Pessoa Jurídica"
        subtitle={(
          <>
            <Typography variant="subtitle2">Cliente já possui cadastro?</Typography>
            <Button
              variant="text"
              component={Link}
              to="/client-login"
            >
              Entrar
            </Button>
          </>
        )}
        icon="corporate-person"
      >

        <CardContent className={cardContent}>
          <Typography className={contentTitle}>Dados da Empresa:</Typography>
          <Form onSubmit={handleSubmit(handleRequest)}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="cnpj"
                  component={InputCnpjRender}
                  placeholder="ex. XX.XXX.XXX/0001-XX"
                  label="CNPJ"
                  required
                  disabled={loading.customerDocumentValidate}
                  validate={[required, cnpjValid]}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="razaoSocial"
                  minLength={6}
                  maxLength={100}
                  label="Razão Social"
                  validate={[required, minLength6, maxLenght100]}
                  required
                />
              </Grid>

              <Grid item className={gridStyled} sm={6} xs={12}>
                <SelectForm
                  name="tipoContribuinte"
                  label="Tipo de Contribuinte"
                  required
                  validate={[required]}
                  options={[
                    {
                      id: 1,
                      label: 'Contribuinte',
                    },
                    {
                      id: 5,
                      label: 'Não Contribuinte',
                    },
                    {
                      id: 8,
                      label: 'Não contribuinte com I.E',
                    },
                    {
                      id: 6,
                      label: 'Órgão Público',
                    },
                  ]}
                />
              </Grid>

              {showIE && (
                <Grid item sm={6} xs={12}>
                  <InputFieldForm
                    name="ie"
                    label="Inscrição Estadual"
                    maxLength={14}
                    validate={[required, number]}
                    required
                  />
                </Grid>
              )}

              <Grid item sm={12} xs={12}>
                <CheckboxForm
                  name="suframa"
                  label="Tem inscrição no SUFRAMA"
                />

                {suframa && (
                  <Grid item sm={6} xs={12}>
                    <InputFieldForm
                      maxLength={9}
                      name="inscricaoSuframa"
                      label="Número SUFRAMA"
                      validate={[required, maxLenght9]}
                      required
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="email"
                  label="E-mail"
                  required
                  maxLength={60}
                  validate={[email, required]}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="emailNfe"
                  maxLength={60}
                  label="E-mail para Nota Fiscal"
                  validate={[email]}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <InputFieldForm
                  name="telefone"
                  component={InputPhoneRender}
                  label="Telefone"
                  validate={[required, cellphone]}
                  required
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <InputFieldForm
                  name="telefoneSecundario"
                  component={InputPhoneRender}
                  label="Telefone Secundário"
                  validate={[cellphone]}
                />
              </Grid>
            </Grid>

            <Typography className={contentTitle}>Dados do Comprador:</Typography>
            <Grid container spacing={2}>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="nomeComprador"
                  label="Nome do Comprador"
                  validate={[required]}
                  maxLength={50}
                  hint="Responsável pela compra"
                  required
                />
              </Grid>
            </Grid>
            <Typography className={contentTitle}>Dados para Entrega:</Typography>
            <AlertMessage icon="warning">
              Por ser empresa, o endereço de envio e faturamento deve ser o mesmo. Validamos os dados junto à SEFAZ.
            </AlertMessage>

            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.cep"
                  label="CEP"
                  component={InputCEPRender}
                  placeholder="ex. 12345-789"
                  onBlur={handleCepRequest}
                  endAdornment={(
                    <IconButton
                      style={{ marginTop: 10 }}
                      onClick={handleCepRequest}
                    >
                      <Search />
                    </IconButton>
                  )}
                  validate={[required, minLength8]}
                  required
                />
                <LinearProgress hidden={!loading.zipcode} />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.logradouro"
                  label="Rua / Avenida"
                  maxLength={60}
                  validate={[required]}
                  required
                  disabled={loading.zipcode || !bitGenerico}
                />
              </Grid>

              <Grid item sm={12} xs={12} className={complementoStyled}>
                <Box display="flex" alignItems="center">
                  <Grid item sm={2} xs={6} style={{ minHeight: 97 }}>
                    <InputFieldForm
                      name="endereco.numero"
                      maxLength={5}
                      pattern="\d*"
                      label="Número"
                      validate={!semNumero && [required, number, maxLength5]}
                      required
                      disabled={loading.zipcode || semNumero}
                      type="text"
                    />
                  </Grid>

                  <Grid item sm={4} xs={6}>
                    <Box marginLeft={2} height="100%" marginTop={2}>
                      <CheckboxForm
                        name="endereco.semNumero"
                        label="Sem Número"
                        color="primary"
                        disabled={loading.zipcode}
                        active
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    sm={6}
                    className={complementoGrid}
                  >
                    <InputFieldForm
                      name="endereco.complemento"
                      label="Complemento"
                      maxLength={50}
                      disabled={loading.zipcode}
                    />
                  </Grid>
                </Box>

                <Grid
                  item
                  sm={12}
                  className={complementoMobileGrid}
                >
                  <InputFieldForm
                    name="endereco.complemento"
                    label="Complemento"
                    maxLength={50}
                    disabled={loading.zipcode}
                  />
                </Grid>
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.bairro"
                  label="Bairro"
                  validate={[required]}
                  maxLength={50}
                  required
                  disabled={loading.zipcode || !bitGenerico}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.cidade"
                  label="Cidade"
                  validate={[required]}
                  maxLength={50}
                  required
                  disabled
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.estado"
                  label="Estado"
                  validate={[required]}
                  maxLength={2}
                  required
                  disabled
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.informacoesAdicionais"
                  label="Informações Adicionais para Entrega"
                  required={semNumero}
                  disabled={loading.zipcode}
                  multiline
                  maxLength={200}
                  rows={4}
                  validate={semNumero && [required, minLength10]}
                  placeholder="Descrição da fachada, pontos de referência, informações de segurança etc."
                />
              </Grid>
            </Grid>
          </Form>
        </CardContent>

        <CardActions>
          <ButtonActions
            onClick={handleRemoteSubmit}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={anyLoading}
          >
            Realizar Cadastro
          </ButtonActions>

          <ButtonActions
            component={Link}
            to="/register"
            variant="outlined"
            color="primary"
            fullWidth
          >
            Voltar
          </ButtonActions>
        </CardActions>
      </Card>
    </Grid>
  );
};
const mapState = (state) => {
  const { snack } = state.main.app;
  const selector = formValueSelector('register/CorporatePerson');
  const cep = selector(state, 'endereco.cep');
  const semNumero = selector(state, 'endereco.semNumero');
  const bitGenerico = selector(state, 'endereco.bitGenerico');
  const tipoContribuinte = selector(state, 'tipoContribuinte');
  const suframa = selector(state, 'suframa');
  const submitFailed = hasSubmitFailed('register/CorporatePerson')(state);

  return {
    ...state.register,
    cep,
    snack,
    semNumero,
    tipoContribuinte,
    suframa,
    submitFailed,
    bitGenerico,
  };
};

CorporatePerson.propTypes = {
  handleSubmit: PropTypes.func,
  snack: PropTypes.instanceOf(Object),
};

export default connect(mapState, {
  getZipcodeData: getZipcodeDataAction,
  requestRegisterCorporateCustomer: requestRegisterCorporateCustomerAction,
})(reduxForm({
  form: 'register/CorporatePerson',
  initialValues: {
    nomeComprador: '',
    telefone: '',
    telefoneSecundario: '',
    email: '',
    cnpj: '',
    tipoContribuinte: null,
    razaoSocial: '',
    ie: '',
    emailNfe: '',
    suframa: false,
    receberEmail: false,
    inscricaoSuframa: '',
    revenda: false,
    endereco: {
      cep: '',
      logradouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
      complemento: '',
      informacoesAdicionais: '',
      semNumero: false,
      bitPrincipal: true,
      bitInativo: false,
      bitGenerico: false,
    },
  },
})(CorporatePerson));
