import {
  Box, Chip, Grid, Typography,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { toMoney } from '../../../../shared/utils/parsers';
import useStyles from './styles';

const FilialItems = ({ filial }) => {
  const classes = useStyles();

  return (
    <Box key={filial.idFilial}>
      <Box
        display="flex"
        alignItems="center"
        p="10px 8px 10px 0"
        color="#939393"
      >
        <Typography variant="subtitle1" color="inherit">
          <Box fontWeight={500}>
            {filial.name}
          </Box>
        </Typography>

        <Box pr={1} />

        <Typography variant="subtitle1" color="inherit">
          {toMoney(filial.freight)}
        </Typography>
      </Box>

      {filial.items.map((item) => (
        <Box key={item.productId} marginBottom={1}>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Box display="flex" alignItems="center">
                <img
                  height={82}
                  width={82}
                  src={`https://img.lojadomecanico.com.br/IMAGENS/${item.image}`}
                  alt=""
                />

                <Box ml={1}>
                  <Typography variant="subtitle2">
                    {item.name}
                  </Typography>

                  <Typography variant="body2" className={classes.productText}>
                    {`Bseller ${item.idItemBseller} - ${item.model}`}
                  </Typography>

                  {item.idSeller && (
                    <Box className={classes.productText}>
                      {'Vendido e entregue por: '}
                      <span>{item.companyName}</span>
                    </Box>
                  )}

                  <Typography variant="body2" className={classes.productText}>
                    {`${item.shippingCompany || ''}${
                      item.deliveryTime ? ` - Prazo de entrega: ${
                        item.deliveryTime
                      } dia${item.deliveryTime > 1 ? 's' : ''}` : ''
                    }`}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box className={classes.secondary}>
                <Chip label={item.quantity} classes={{ root: classes.chip }} />

                <div className={classes.pricesContainer}>
                  <div className={classes.groupPrice}>
                    <Typography variant="subtitle2" className={classes.currentPrice}>
                      {`${toMoney(item.total)} ou`}
                    </Typography>
                  </div>

                  <div className={classes.groupPrice}>
                    <Typography variant="subtitle2" className={classes.oldPrice}>
                      {`${toMoney(item.totalOnTime)} à prazo`}
                    </Typography>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

FilialItems.propTypes = {
  filial: PropTypes.instanceOf(Object).isRequired,
};

export default FilialItems;
