import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Input,
  MenuItem,
  Paper,
  Popover,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles, {
  SelectStyled as Select,
} from './styles';
import { setFilialAction } from '../../../../actions';
import {logoutAction, logoutCatalogoAction, singinUserFiliaisAction} from '../../../../../auth/login/action';
import apiService from '../../../../../shared/services/apiService';
import { getCheckoutAction } from "../../../actions";
import {clientLogoutAction, clientLogoutCatalogoAction} from "../../../../authClient/login/actions";

const SellerMenu = ({
  checkout,
  filial,
  getCheckout,
  logout,
  singinUserFiliais,
  user,
  setFilial,
  clientLogout,
  logoutCatalogo,
  clientLogoutCatalogo,
}) => {
  const { idVendedor, nomeVendedor, cargo } = user;
  const { idFilial, nomeFilial } = filial;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const sellerMenuRef = useRef(null);
  const [filialSelected, setFilialSelected] = useState({ idFilial, nomeFilial });
  const [filials, setFilials] = useState([]);
  const [idFilialSelected, setIdFilialSelected] = useState(idFilial);

  const {
    arrowPopover,
    arrowIcon,
    arrowToggleBtn,
    headerBox,
    selectFilialBox,
    selectFilialLabel,
    sellerAvatar,
    sellerMenuGrid,
    sellerMenuBox,
    sellerMenuBoxMain,
    sellerName,
    sellerMenuPopover,
    sellerMenuPopoverPaper,
    store,
    userLoggedBox,
    logoutBox,
    logoutBtn,
    logoutIcon,
    filialName,
    userLoggedPaper,
    selectFilialMenu,
    sellerIdBox,
  } = classes;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOpenSelect = () => setOpenSelect(!openSelect);

  const handleFilialSelected = (filial) => {
    setIdFilialSelected(filial);

    const selected = filials.find((f) => filial === f.idFilial);
    singinUserFiliais(selected, user);
    setFilial(selected);

    setFilialSelected({
      idFilial: selected.idFilial,
      nomeFilial: selected.nomeFilial,
    });

    if (checkout?.checkoutId) {
      getCheckout({
        idCheckout: checkout.checkoutId,
        startRequest: true,
        calculateTax: false
      });
    }
  };

  const exit = () => {
    handleToggle();
    clientLogout();
    logout();
    clientLogoutCatalogo();
    logoutCatalogo();
    localStorage.removeItem('filtersCheckout');
    localStorage.removeItem('filtersOrder');
  };

  const getFilials = async() => {
    const res = await apiService.get(`/login/vendedor/${idVendedor}`);
    setFilials(res.data.results.value.filiais || []);
  }

  useEffect(() => {
    if(open) getFilials();
  }, [open]);

  return (
    <Grid className={sellerMenuGrid}>
      <Box className={sellerMenuBoxMain}>
        <span className={sellerAvatar}>{nomeVendedor[0]}</span>
        <Box className={sellerMenuBox}>
          <span className={sellerName}>{nomeVendedor.toLowerCase()}</span>
          <span className={store}>{`Loja ${filialSelected.nomeFilial.toLowerCase()}`}</span>
        </Box>
        <Button
          ref={sellerMenuRef}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleToggle}
          className={arrowToggleBtn}
        >
          <span className={`${arrowIcon} ${open && 'open'}`} />
        </Button>
      </Box>
      <Popover
        classes={{root: sellerMenuPopover, paper: sellerMenuPopoverPaper}}
        open={open}
        anchorEl={sellerMenuRef.current}
        onClose={handleToggle}
        elevation={0}
      >
        <span className={arrowPopover}/>
        <Paper className={userLoggedPaper}>
          <Box className={sellerIdBox}>
            <span className={`${sellerAvatar} open-menu`}>{nomeVendedor[0]}</span>
            <span className={`${sellerName} open-menu`}>{nomeVendedor.toLowerCase()}</span>
            <span>{`ID: ${idVendedor} - ${cargo}`}</span>
          </Box>
          <Box className={userLoggedBox}>
            <Box>
              <Box className={selectFilialBox}>
                <Box className={headerBox}>
                  <span className={selectFilialLabel}>Filial</span>
                </Box>
                <Select
                  value={idFilialSelected}
                  onChange={(e) => handleFilialSelected(e.target.value)}
                  input={<Input disableUnderline />}
                  MenuProps={{ classes: { paper: selectFilialMenu } }}
                  onOpen={handleOpenSelect}
                  onClose={handleOpenSelect}
                >
                  {
                    filials.map((f) => (
                      <MenuItem
                        className={filialName}
                        value={f.idFilial}
                        name={f.nomeFilial}
                        key={f.nomeFilial}
                      >
                        {f.nomeFilial}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Box>
            </Box>
          </Box>
          <Box className={logoutBox}>
            <Button
              className={logoutBtn}
              onClick={exit}
            >
              <span className={logoutIcon}/>
              <span>Sair da conta</span>
            </Button>
          </Box>
        </Paper>
      </Popover>
    </Grid>
  );
};

SellerMenu.defaultProps = {
  closeMenu: null,
};

SellerMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  singinUserFiliais: PropTypes.func.isRequired,
  menuRef: PropTypes.instanceOf(Element),
  user: PropTypes.instanceOf(Object).isRequired,
  setFilial: PropTypes.func.isRequired,
  filial: PropTypes.instanceOf(Object),
  closeMenu: PropTypes.func,
  getCheckout: PropTypes.func.isRequired,
  loadingFilials: PropTypes.bool,
  clientLogout: PropTypes.func.isRequired,
  logoutCatalogo: PropTypes.func.isRequired,
  clientLogoutCatalogo: PropTypes.func.isRequired,
};

SellerMenu.defaultProps = {
  menuRef: null,
  filial: null,
  loadingFilials: false,
};

const mapState = (state) => {
  const { checkout } = state.main.checkout.geral;
  return ({
      ...state.auth.login,
      ...state.main.app,
      checkout,
    }
  )
};

export default connect(mapState, {
  logout: logoutAction,
  singinUserFiliais: singinUserFiliaisAction,
  getCheckout: getCheckoutAction,
  setFilial: setFilialAction,
  clientLogout: clientLogoutAction,
  logoutCatalogo: logoutCatalogoAction,
  clientLogoutCatalogo: clientLogoutCatalogoAction,
})(SellerMenu);
