import { handleActions, combineActions } from 'redux-actions';
import types from './types';

const INITIAL_STATE = {
  filterIsOpened: false,
  totalpages: 1,
  rpp: 10,
  checkoutMeta: {
    el: null,
    checkoutId: null,
  },
  relatorio: {},
  vendedores: [],
  detailRelatorio: {},
  checkouts: [],
  checkoutsSearch: [],
  checkoutDetail: {},
  agendamentos: {},
  loading: false,
  optionDeclinio: [],
  pedidos: {},
};

const startLoading = combineActions(
  types.GET_DETAIL_RELATORIO_COORDENADOR_REQUEST,
  types.GET_RELATORIO_COORDENADOR_REQUEST,
  types.GET_VENDEDORES_REQUEST,
  types.PUT_VENDEDORES_REQUEST,
  types.GET_CHECKOUT_REQUEST,
  // types.GET_CHECKOUT_BY_DOC_REQUEST,
  types.GET_CHECKOUT_DETAIL_REQUEST,
  types.AGENDAMENTO_REQUEST,
  types.GET_AGENDAMENTOS_REQUEST,
  types.TAKE_CHECKOUT_REQUEST,
  types.GET_PEDIDOS_REQUEST,
  types.POST_RECUPERAR_PEDIDO_REQUEST,
  types.GET_LIST_OPTION_REQUEST,
  types.POST_DECLINIO_PEDIDO_REQUEST,
);

const stopLoading = combineActions(
  types.GET_RELATORIO_COORDENADOR_SUCCESS,
  types.GET_RELATORIO_COORDENADOR_FAILURE,
  types.GET_DETAIL_RELATORIO_COORDENADOR_SUCCESS,
  types.GET_DETAIL_RELATORIO_COORDENADOR_FAILURE,
  types.GET_CHECKOUT_SUCCESS,
  types.GET_CHECKOUT_FAILURE,
  // types.GET_CHECKOUT_BY_DOC_SUCCESS,
  // types.GET_CHECKOUT_BY_DOC_FAILURE,
  types.AGENDAMENTO_SUCCESS,
  types.AGENDAMENTO_FAILURE,
  types.GET_AGENDAMENTOS_SUCCESS,
  types.GET_AGENDAMENTOS_FAILURE,
  types.GET_CHECKOUT_DETAIL_SUCCESS,
  types.GET_CHECKOUT_DETAIL_FAILURE,
  types.TAKE_CHECKOUT_SUCCESS,
  types.TAKE_CHECKOUT_FAILURE,
  types.GET_VENDEDORES_SUCCESS,
  types.GET_VENDEDORES_FAILURE,
  types.PUT_VENDEDORES_SUCCESS,
  types.PUT_VENDEDORES_FAILURE,
  types.GET_PEDIDOS_SUCCESS,
  types.GET_PEDIDOS_FAILURE,
  types.POST_RECUPERAR_PEDIDO_SUCCESS,
  types.POST_RECUPERAR_PEDIDO_FAILURE,
  types.GET_LIST_OPTION_SUCCESS,
  types.GET_LIST_OPTION_FAILURE,
  types.POST_DECLINIO_PEDIDO_SUCCESS,
  types.POST_DECLINIO_PEDIDO_FAILURE,
);

const list = handleActions({
  [startLoading]: (state) => ({
    ...state,
    loading: true,
  }),
  [stopLoading]: (state) => ({
    ...state,
    loading: false,
  }),
  [types.GET_RELATORIO_COORDENADOR_SUCCESS]: (state, { payload }) => ({
    ...state,
    relatorio: payload.results,
    totalpages: payload.totalpages,
  }),
  [types.GET_DETAIL_RELATORIO_COORDENADOR_SUCCESS]: (state, { payload }) => ({
    ...state,
    detailRelatorio: payload.results,
  }),
  [types.GET_VENDEDORES_SUCCESS]: (state, { payload }) => ({
    ...state,
    vendedores: payload.results,
  }),
  [types.GET_CHECKOUT_DETAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    checkoutDetail: payload.checkoutDetail,
  }),
  [types.GET_AGENDAMENTOS_SUCCESS]: (state, { payload }) => ({
    ...state,
    agendamentos: payload.agendamentos,
  }),
  [types.GET_CHECKOUT_SUCCESS]: (state, { payload }) => ({
    ...state,
    checkouts: payload.checkouts,
  }),
  [types.GET_CHECKOUT_BY_DOC_SUCCESS]: (state, { payload }) => ({
    ...state,
    checkoutsSearch: payload.checkoutsSearch,
  }),
  [types.GET_AGENDAMENTOS_SUCCESS]: (state, { payload }) => ({
    ...state,
    agendamentos: payload.agendamentos,
  }),
  [types.SET_META]: (state, { payload }) => ({
    ...state,
    checkoutMeta: payload,
  }),
  [types.CLEAR_META]: (state) => ({
    ...state,
    checkoutMeta: {
      el: null,
      checkoutId: null,
    },
  }),
  [types.OPEN_FILTER]: (state) => ({
    ...state,
    filterIsOpened: true,
  }),
  [types.CLOSE_FILTER]: (state) => ({
    ...state,
    filterIsOpened: false,
  }),
  [types.GET_PEDIDOS_SUCCESS]: (state, { payload }) => ({
    ...state,
    pedidos: payload.results,
  }),
  [types.GET_PEDIDOS_FAILURE]: (state, { payload }) => ({
    ...state,
    pedidos: {},
  }),
  [types.POST_DECLINIO_PEDIDO_SUCCESS]: (state, { payload }) => ({
    ...state,
    pedidos: payload.results,
  }),
  [types.GET_LIST_OPTION_SUCCESS]: (state, { payload }) => ({
    ...state,
    optionDeclinio: payload.data,
  }),
  [types.POST_DECLINIO_PEDIDO_SUCCESS]: (state, { payload }) => ({
    ...state,
    pedidos: payload.results,
  }),
}, INITIAL_STATE);

export default list;
