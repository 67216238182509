import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Dialog,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { postCargosAction } from '../../../actions';
import useStyles from '../../../styles';

const DialogNewOffice = ({
  dialog, handleToggle, postCargos, page,
}) => {
  const classes = useStyles();
  const [nameCargo, setNameCargo] = useState('');

  const renderPostCargos = () => {
    handleToggle();
    postCargos({ nome: nameCargo, page });
  };

  return (
    <Dialog open={dialog} onClose={handleToggle} maxWidth="xs" fullWidth>
      <Box position="relative" style={{ backgroundColor: '#F3F4F6' }}>
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={handleToggle}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogTitle>
          Novo Cargo
        </DialogTitle>
      </Box>
      <div style={{ padding: 20 }}>
        <Grid item spacing={2} container xs={12}>
          <Grid item xs={7}>
            <Typography>NOME</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              value={nameCargo}
              onChange={(e) => setNameCargo(e.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            <Button
              className={classes.buttonCriar}
              onClick={renderPostCargos}
              disabled={nameCargo.length < 5}
            >
              Criar
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

DialogNewOffice.propTypes = {
  dialog: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  postCargos: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};
const mapState = (state) => state.main.sales;
export default connect(mapState, {
  postCargos: postCargosAction,
})(DialogNewOffice);
