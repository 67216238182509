import axios from 'axios';
import types from './types';

const {
  REACT_APP_FRESHDESK_TOKEN,
  REACT_APP_FRESHDESK_GROUP_ID,
  REACT_APP_FRESHDESK_CC_EMAIL,
} = process.env;

const CC_EMAIL = (REACT_APP_FRESHDESK_CC_EMAIL || '').split(',')
  .filter((item) => item);

export const createTicketBody = (form) => {
  switch (form.tipo) {
    case 1:
      return {
        subject: `Sugestão Comercial - ${form.nomeType} por ${form.nomeVendedor}`,
        description: `Nome do Vendedor: ${form.nomeVendedor}<br>Tipo de Chamado: ${form.nomeType}<br>Cod. Produto: ${form.produtoCod} <br> Nome Concorrente: ${form.concorrenteNome}<br>URL Concorrente: ${form.concorrenteUrl}<br>Valor Concorrente: ${form.concorrentePreco}<br>Nosso Valor: ${form.nossoPreco}`,
        email: form.emailVendedor,
        type: 'Nova Solicitação',
        priority: 4,
        status: 2,
        cc_emails: CC_EMAIL,
        group_id: +REACT_APP_FRESHDESK_GROUP_ID,
      };
    case 2:
      return {
        subject: `Sugestão Comercial - ${form.nomeType} por ${form.nomeVendedor}`,
        description: `Nome do Vendedor: ${form.nomeVendedor}<br>Tipo de Chamado: ${form.nomeType}<br>Cod. Produto: ${form.produtoCod}`,
        email: form.emailVendedor,
        type: 'Nova Solicitação',
        priority: 4,
        status: 2,
        cc_emails: CC_EMAIL,
        group_id: +REACT_APP_FRESHDESK_GROUP_ID,
      };
    case 3:
      return {
        subject: `Sugestão Comercial - ${form.nomeType} por ${form.nomeVendedor}`,
        description: `Nome do Vendedor: ${form.nomeVendedor}<br>Tipo de Chamado: ${form.nomeType}<br>Nome Produto: ${form.produtoNome}<br>Descrição Produto: ${form.produtoDesc}<br>URL Concorrente: ${form.produtoUrl}<br>Marca Produto: ${form.produtoMarca}`,
        email: form.emailVendedor,
        type: 'Nova Solicitação',
        priority: 4,
        status: 2,
        cc_emails: CC_EMAIL,
        group_id: +REACT_APP_FRESHDESK_GROUP_ID,
      };
    default:
      throw Error('Tipo invalido');
  }
};

export const createTicketAction = (form, snack) => async (dispatch) => {
  dispatch({ type: types.CREATE_TICKET_REQUEST });
  try {
    const body = createTicketBody(form);
    const token = Buffer.from(REACT_APP_FRESHDESK_TOKEN)
      .toString('base64');

    await axios.post('https://gurgelmix.freshdesk.com/api/v2/tickets', body, {
      headers: {
        Authorization: token,
      },
    });
    snack.enqueueSnackbar('Ticket criado com sucesso', {
      variant: 'success',
    });
    dispatch({ type: types.CREATE_TICKET_SUCCESS });
  } catch (e) {
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
    dispatch({ type: types.CREATE_TICKET_FAILURE });
  }
};

export const loadOptionsAction = (fromDetail) => (dispatch) => {
  let options = [
    {
      id: 1,
      name: 'Produto acima do preço do concorrente',
    },
    {
      id: 2,
      name: 'Produto esgotado',
    },
    {
      id: 3,
      name: 'Produto fora do mix da LojaDoMecanico',
    },
  ];
  if (fromDetail) {
    options = options.filter((option) => [1, 2].includes(option.id));
  } else {
    options = options.filter((option) => [3].includes(option.id));
  }
  dispatch({
    type: types.LOAD_OPTIONS,
    payload: {
      options,
    },
  });
};
