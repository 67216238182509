import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  buttonActions: {
    fontSize: 16,
    marginTop: 4,
    height: 48,
    border: '1px solid #FF5225',
    borderRadius: 8,
    textTransform: 'none',
    letterSpacing: 0.5,
    textTransform: 'capitalize',
    fontWeight: 600
  },
}));

export default useStyles;
