import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  selectField: {
    '& .MuiOutlinedInput-root': {
      maxHeight: 48,
      borderRadius: 8,
      '& .MuiSvgIcon-root': {
        color: '#FF5225',
        height: 28,
        width: 28,
      },
      '& .MuiSelect-icon': {
        display: 'none',
      },
      '& .MuiSelect-root': {
        fontSize: 16,
        fontWeight: 600,
        color: '#242424',

        '&:after': {
          content: '""',
          width: 8,
          height: 8,
          position: 'absolute',
          borderTop: '2px solid #FE5000',
          borderRight: '2px solid #FE5000',
          transform: 'rotate(135deg)',
          right: 15,
          top: 18,
          cursor: 'pointer',
          zIndex: 0,
        },
      },

      '& input': {
        width: '100%',
        height: '100%',
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 8,
    },
    '& .Mui-error': {
      fontWeight: 600,
    },
  },
  labelField: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    color: '#7B7F82',
    marginBottom: 6,
  },
  activeLabel: {
    color: '#FE5000',
  },
}));

export default useStyles;
