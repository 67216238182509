import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularLoading from '../../shared/components/loading/CircularLoading';
import { logoutAction } from '../login/action';

const LogoutPage = ({ logout}) => {
  useLayoutEffect(() => {
    logout();
  }, [logout]);

  return (
    <CircularLoading open={true} message="Saindo..."/>
  );
};

LogoutPage.propTypes = {
  login: PropTypes.instanceOf(Object),
}

const mapState = (state) => {
  return {
    login: state.auth.login,
  };
}

export default connect(mapState, {
  logout: logoutAction
})(LogoutPage);

