import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    color: '#888E9D',
    fontSize: 12,
    lineHeight: 1,
    textTransform: 'capitalize',
  },
  text: {
    lineHeight: 1.5,
    fontSize: 16,
  },
}));

export default useStyles;
