import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  cardGrid: {
    display: 'flex',
    justifyContent: 'center',
    // marginTop: 48,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    padding: 0,
    marginBottom: 24,
  },
  contentTitle: {
    fontSize: 14,
    fontWeight: 700,
    color: '#2A2A2A',
    marginTop: 12,
    marginBottom: 12,
  },
  recomendationsContent: {
    fontSize: 14,
    fontWeight: 500,
    color: '#242424',
    '& span': {
      marginRight: 4,
      fontWeight: 600,
      color: '#FE5000',
    },
  },
}));

export default useStyles;
