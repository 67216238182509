import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  content: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundcolor: '#909599',
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#909599',
    },

    '& .MuiTypography-body1': {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 2,
      color: '#242424',
    },

    '& .MuiTypography-h2': {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: 3,
    },

    '& span' :{
      fontWeight: 600,
      color: '#FE5000',
    },

    '& a' :{
      fontSize: 12,
      fontWeight: 600,
      color: '#FE5000'
    }
  },
}));

export default useStyles;
