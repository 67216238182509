import types from './types';
import {
  generateLink, removePayment, savePayment, getConditions, savePaymentPix, cancelPayment,
} from '../../../../shared/repositories/checkoutRepository';
import resolveError from '../../../../shared/utils/resolveError';
// eslint-disable-next-line import/no-cycle
import { getCheckoutAction, checkoutDiscountAction } from '../../actions';
import advancedTypes from './advanced/types';
import { toMoney } from '../../../../shared/utils/parsers';
import apiService from '../../../../shared/services/apiService';
import PAYMENT_OPTIONS from '../../../../shared/utils/paymentOptions';

export const openResendLinkAction = () => ({
  type: types.OPEN_RESEND_LINK,
});

export const closeResendLinkAction = () => ({
  type: types.CLOSE_RESEND_LINK,
});

export const openDialogCreditCardQrCodeAction = () => ({
  type: types.OPEN_DIALOG_CREDIT_CARD_QRCODE,
});

export const openDialogManualPaymentAction = () => ({
  type: types.OPEN_MANUAL_PAYMENT,
});

export const closeDialogCreditCardQrCodeAction = () => ({
  type: types.CLOSE_DIALOG_CREDIT_CARD_QRCODE,
});

export const closeDialogManualPaymentAction = () => ({
  type: types.CLOSE_MANUAL_PAYMENT,
});

export const setCashierPaymentAction = (payment) => ({
  type: types.SET_CASHIER_PAYMENT,
  payload: { payment },
});

export const clearCashierPaymentAction = () => ({ type: types.CLEAR_CASHIER_PAYMENT });

export const generateLinkAction = ({
  idCheckout,
  value,
  idClient,
  installments,
  cep,
  name,
  resendLink,
  paymentItem,
  installmentValue,
  meiosEnvioLinkRemoto,
  destinatarioLinkRemoto,
}) => async (dispatch, getState) => {
  dispatch({ type: types.SAVE_PAYMENT_REQUEST });
  const { snack } = getState().main.app;

  try {
    const checkoutIdPayment = await generateLink({
      idCheckout,
      value,
      idClient,
      installments,
      cep,
      name,
      resendLink,
      paymentItem,
      installmentValue,
      meiosEnvioLinkRemoto,
      destinatarioLinkRemoto,
    });

    const typeMessage = destinatarioLinkRemoto.contacts?.find((contact) => (
      (contact?.type === "SMS"
      || contact?.type === "EMAIL"
      || contact?.type === "WHATSAPP")
    ));

    snack.enqueueSnackbar(`Link Remoto ${typeMessage ? ' enviado' : 'gerado'} com sucesso`, { variant: 'success' });

    dispatch({ type: types.SAVE_PAYMENT_SUCCESS });

    const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
    const { calculateTax } = getState().main.checkout.geral.checkout.resume;

    await dispatch(getCheckoutAction({
      idCheckout: idCheckout,
      isSameDay: checkedSameday,
      isPickup: checkedPickup,
      entregaNitro: checkedNitrous,
      entregaAgendada: checkedSchedule,
      dataEntregaAgendada: dateSchedule,
      calculateTax,
    }));

    dispatch(closeDialogCreditCardQrCodeAction());
    if (!resendLink) {
      dispatch({ type: types.OPEN_RESEND_LINK });
    } else {
      dispatch({ type: types.CLOSE_RESEND_LINK });
    }
    //openDialogCreditCardQrCodeAction();

  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.SAVE_PAYMENT_FAIL });
  }
};

export const getConditionsAction = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_CONDITIONS_REQUEST });

  try {
    const conditions = await getConditions();
    dispatch({
      type: types.GET_CONDITIONS_SUCCESS,
      payload: { conditions },
    });
  } catch (_e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar('Tivemos um problema ao buscar as condições do boleto a prazo!', { variant: 'error' });
    dispatch({ type: types.GET_CONDITIONS_FAIL });
  }
};

export const savePaymentAction = (
  body,
  closeDialogAdvanced = false,
) => async (dispatch, getState) => {
  dispatch({ type: types.SAVE_PAYMENT_REQUEST });
  const { snack } = getState().main.app;
  const { calculateTax } = getState().main.checkout.geral.checkout.resume;
  try {
    const message = await savePayment(body);
    snack.enqueueSnackbar(message, { variant: 'success' });

    const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;

    await dispatch(getCheckoutAction({
      idCheckout: body.checkoutId,
      isSameDay: checkedSameday,
      isPickup: checkedPickup,
      entregaNitro: checkedNitrous,
      entregaAgendada: checkedSchedule,
      dataEntregaAgendada: dateSchedule,
      calculateTax,
    }));
    dispatch({ type: types.SAVE_PAYMENT_SUCCESS });
    if (closeDialogAdvanced) dispatch({ type: advancedTypes.CLOSE_DIALOG });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.SAVE_PAYMENT_FAIL });
  }
};
export const savePaymentPixAction = (
  body,
) => async (dispatch, getState) => {
  dispatch({ type: types.SAVE_PAYMENT_PIX_REQUEST });
  const { snack } = getState().main.app;
  const { calculateTax } = getState().main.checkout.geral.checkout.resume;
  try {
    const { message, value: { checkoutIdPayment } } = await savePaymentPix(body);
    snack.enqueueSnackbar(message, { variant: 'success' });

    const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;
    dispatch({ type: types.SAVE_PAYMENT_PIX_SUCCESS });
    dispatch(getCheckoutAction({
      idCheckout: body.checkoutId,
      isSameDay: checkedSameday,
      isPickup: checkedPickup,
      entregaNitro: checkedNitrous,
      entregaAgendada: checkedSchedule,
      dataEntregaAgendada: dateSchedule,
      calculateTax,
    }));

    return { message, checkoutIdPayment };
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: advancedTypes.CANCEL_PAYMENT });
    dispatch({ type: advancedTypes.CLOSE_DIALOG });
    dispatch({ type: types.SAVE_PAYMENT_PIX_FAIL });
  }
};

export const savePaymentDiscountAction = (body, options = {}) => async (dispatch, getState) => {
  const { snack } = getState().main.app;
  try {
    const {
      percentualDesconto = 10,
      observacaoSolicitacao,
      concederDescontoPrazo,
    } = options;

    const discountReady = await dispatch(checkoutDiscountAction({
      percentualDesconto,
      observacaoSolicitacao,
      concederDescontoPrazo,
    }));

    if (discountReady) {
      const { remainder } = getState().main.checkout.geral.checkout.resume;
      await dispatch(savePaymentAction({
        ...body,
        installmentValue: body.installmentValue || remainder,
        total: body.total || remainder,
      }));
      dispatch({ type: advancedTypes.CLOSE_DIALOG });
    }
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.SAVE_PAYMENT_FAIL });
  }
};

export const removePaymentAction = (checkoutId, paymentId , typePay) => async (dispatch, getState) => {

  dispatch({ type: types.REMOVE_PAYMENT_REQUEST });
  const { snack } = getState().main.app;
  const { calculateTax } = getState().main.checkout.geral.checkout.resume;

  try {
    const { checkedSameday, checkedPickup, checkedNitrous, checkedSchedule, dateSchedule } = getState().main.checkout.geral.checkout;


    if (typePay == "CC") {
      const message = await cancelPayment(paymentId);
      snack.enqueueSnackbar(message, { variant: 'success' });
    } else {
      const message = await removePayment(paymentId);
      snack.enqueueSnackbar(message, { variant: 'success' });
    }


    if (typePay === 'VA' || typePay === 'CP') {
      dispatch({ type: types.COUPON_VALE_LIST_LOAD });
    }

    dispatch({ type: types.REMOVE_PAYMENT_SUCCESS });
    dispatch(getCheckoutAction({
      idCheckout: checkoutId,
      isSameDay: checkedSameday,
      isPickup: checkedPickup,
      entregaNitro: checkedNitrous,
      entregaAgendada: checkedSchedule,
      dataEntregaAgendada: dateSchedule,
      calculateTax,
    }));
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.REMOVE_PAYMENT_FAIL });
  }
};

export const setPaymentMethodAction = ({ paymentMethod }) => ({
  type: types.SET_PAYMENT,
  payload: { paymentMethod },
});

export const savePaymentCashierDiscountAction = ({ payments = [] }) => (dispatch, getState) => {
  const { snack } = getState().main.app;
  const { discountCart } = getState().main.checkout.geral.checkout;
  const { cashierPayment, conditions } = getState().main.checkout.payment;

  const paid = payments.reduce((accumul, payment) => (
    accumul + payment.total
  ), 0);

  if (paid === cashierPayment.total) {
    const paymentTypes = {};
    PAYMENT_OPTIONS.forEach((payment) => {
      paymentTypes[payment.code] = payment.name;
    });

    const observacaoSolicitacao = payments.reduce((accumul, payment) => {
      let curCondition;
      if (payment.condition) {
        curCondition = conditions
          .find((condition) => condition.id === payment.condition)
          .nome;
      }

      return (
        `${accumul} ${toMoney(payment.total)}${payment.type === 'CC' ? ` em ${payment.installments}x` : ''
        } no ${paymentTypes[payment.type]}${curCondition ? ` condição ${curCondition}` : ''
        };`
      );
    }, 'Solicitação de desconto no pagamento caixa:');

    dispatch(clearCashierPaymentAction());

    dispatch(savePaymentDiscountAction(cashierPayment, {
      concederDescontoPrazo: true,
      observacaoSolicitacao,
      percentualDesconto: discountCart,
    }));
  } else {
    const message = paid > cashierPayment.total
      ? 'O valor indicado ultrapassa o valor a ser pago!'
      : 'O valor indicado é inferior ao valor a ser pago!';
    snack.enqueueSnackbar(message, { variant: 'warning' });
  }
};

export const savePaymentBilletDiscountAction = (payment) => (dispatch, getState) => {
  const { discountCart } = getState().main.checkout.geral.checkout;
  const { conditions } = getState().main.checkout.payment;

  const paymentCondition = conditions
    .find((condition) => condition.id === payment.paymentTermId)
    .nome;
  const observacaoSolicitacao = `Solicitação de desconto no boleto à prazo ${paymentCondition}`;

  dispatch(savePaymentDiscountAction(payment, {
    concederDescontoPrazo: true,
    observacaoSolicitacao,
    percentualDesconto: discountCart,
  }));
};

export const searchInstallmentsAction = (
  value,
  discountCart,
  total,
  totalInstallment,
) => async (dispatch) => {
  if (!value) return;
  const valorVista = (value * total) / totalInstallment;
  dispatch({ type: types.SEARCH_INSTALLMENTS_REQUEST });
  try {
    const { data: { results } } = await apiService.post('/carrinho/parcelamento', {
      valorPrazo: value,
      valorVista,
    });
    dispatch({
      type: types.SEARCH_INSTALLMENTS_SUCCESS,
      payload: {
        listInstallments: results.map((item, index) => ({
          label: `${item.quantidadeParcelas} - ${toMoney(item.valorParcela)} ${index === 0 && discountCart > 0 ? '' : ''}`,
          value: item.valorParcela,
          quantity: item.quantidadeParcelas,
        })),
      },
    });
  } catch (e) {
    dispatch({ type: types.SEARCH_INSTALLMENTS_FAIL });
  }
};
