import React from 'react';

import {
  Box,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const LabelTypography = ({
  text, label
}) => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.text} variant="h6" >{text}</Typography>
      </Box>
    </>
  );
};

LabelTypography.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string
};

export default LabelTypography;
