import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  name: {
    [breakpoints.down('sm')]: {
      width: 'auto',
    },
    [breakpoints.up('md')]: {
      width: '25%',
    },
  },
  amount: {
    [breakpoints.down('sm')]: {
      width: 'auto',
    },
    [breakpoints.up('md')]: {
      width: '15%',
    },
  },
  points: {
    [breakpoints.down('sm')]: {
      width: 'auto',
    },
    [breakpoints.up('md')]: {
      width: '10%',
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  customerCell: {
    textTransform: 'uppercase',
  },
}));

export default useStyles;
