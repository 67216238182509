import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  alertContainer: {
    marginTop: '18px',
  },
  alert: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  }
}));

export default useStyles;
