import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  drawerPaper: {
    maxWidth: 256,
    color: '#ffff',
    width: '100%',
    height: '101vh',
    justifyContent: 'space-between',
    backgroundColor: '#FF5225',

    '& .MuiListItem-button': {
      padding: '20px 0 20px 7px',
      marginTop: '0 !important',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },

    [breakpoints.down('sm')]: {
      height: '100vh',
      top: 0,
    },
  },
  drawerPaperClose: {
    maxWidth: 64,
    color: '#ffff',
    width: '100%',
    height: '101vh',
    justifyContent: 'space-between',
    backgroundColor: '#FF5225',

    '& .selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },

    '& .MuiListItemIcon-root': {
      gap: 14,
      marginLeft: 14,
    },

    '& .MuiListItem-button': {
      padding: '20px 0 20px 7px',
      marginTop: '0 !important',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },

    [breakpoints.down('sm')]: {
      background: 'none',
      height: '6vh',
      top: 0,
      overflow: 'hidden',
      borderRight: 0,
    },
  },
  bold: {
    '& > span': {
      fontWeight: 600,
    },
  },
  textDrawerOrçamento: {
    marginLeft: 25,
    color: '#ffff',
    fontSize: 14,
  },
  textDrawer: {
    marginLeft: 27,
    color: '#ffff',
    fontSize: 14,
  },
  version: {
    position: 'absolute',
    color: '#FFF',
    bottom: -2,
    right: 0,
    opacity: 0.6,
    fontSize: 10,
  },
  listItem: {
    alignItems: 'end',
  },
}));

export default useStyles;
