import { Box, Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Check } from '@material-ui/icons';
import { toCEP } from '../../../utils/parsers';
import { FlatExpansionPanelSummary, FlatExpansionPanel } from '../../flatExpansionPanel';

const AddressInfo = ({
  street,
  number,
  city,
  state,
  zipcode,
  deliveryDate,
  readOnly,
  onChangeAddress,
  complement,
  addInfo,
  neighborhood,
}) => {
  const [open, setOpen] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const handleToggle = () => setOpen(!open);

  const copyAddress = () => {
    const fullAddress = `${street}, ${number}, ${neighborhood} - ${city}/${state}- CEP ${zipcode} ${complement && `- Complemento ${complement}`}`;
    navigator.clipboard.writeText(fullAddress);
    setIsAddressCopied(true);
    setTimeout(() => { setIsAddressCopied(false); }, 1000);
  };

  return (
    <Box pl={2} pr={2}>

      <FlatExpansionPanel expanded onChange={handleToggle} style={{ marginTop: '0px', backgroundColor: 'transparent' }}>
        <span>
          <FlatExpansionPanelSummary
            style={{
              border: '0px', height: '10px', padding: 0, minHeight: '34px',
            }}
            aria-controls="resume-content"
            id="resume-header"
          >
            {street && (
            <Typography variant="body2" style={{ fontSize: '12px', fontWeight: 'bold', color: '#1A1C26' }}>
              {`${street}, ${number}, ${neighborhood}` }
            </Typography>
            )}
          </FlatExpansionPanelSummary>
        </span>

        {zipcode && (
        <Typography
          variant="body2"
          style={{
            fontSize: '11px', fontWeight: '400', color: '#1A1C26', padding: '0px 16px',
          }}
        >
          <div>{complement}</div>
          {`${city}/${state} - CEP ${toCEP(zipcode)}`}
          <div style={{ marginTop: 5, marginBottom: 5 }}>{addInfo}</div>
        </Typography>
        )}

        <Typography
          variant="body2"
          style={{
            fontSize: '11px', fontWeight: '400', color: '#1A1C26', padding: '0px 16px',
          }}
        >
          {deliveryDate}
        </Typography>

        <Box display="flex" justifyContent="space-around" flexWrap="wrap">
          <Button
            color="primary"
            size="small"
            onClick={copyAddress}
            style={{
              backgroundColor: `${isAddressCopied ? '#FA4616' : 'transparent'}`, fontSize: '10px', fontWeight: '600', color: `${isAddressCopied ? 'white' : '#FA4616'}`, marginTop: '10px', border: '2px solid #FA4616', height: 30, borderRadius: '4px', padding: '0', width: '45%',
            }}
          >
            {isAddressCopied && <Check color="white" style={{ marginRight: '5px' }} />}
            {isAddressCopied ? 'Copiado' : 'Copiar'}
          </Button>

          {!readOnly && (

          <Button
            color="primary"
            size="small"
            onClick={onChangeAddress}
            style={{
              fontSize: '10px', fontWeight: 'bold', color: '#FA4616', border: '2px solid #FA4616', borderRadius: '4px', width: '45%', marginTop: '10px', marginBottom: '15px', padding: '0', height: 30,
            }}
          >
            Alterar
          </Button>

          )}

        </Box>

      </FlatExpansionPanel>

    </Box>
  );
};

AddressInfo.defaultProps = {
  number: '',
  zipcode: '',
  street: '',
  city: '',
  state: '',
  complement: '',
  addInfo: '',
  neighborhood: '',
  readOnly: true,
  onChangeAddress: () => {},
};

AddressInfo.propTypes = {
  zipcode: PropTypes.string,
  complement: PropTypes.string,
  addInfo: PropTypes.string,
  street: PropTypes.string,
  number: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  neighborhood: PropTypes.string,
  deliveryDate: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  onChangeAddress: PropTypes.func,
};

export default AddressInfo;
