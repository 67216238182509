import { makeStyles } from '@material-ui/core';
import { common } from '@material-ui/core/colors';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  primary: {
    textAlign: 'left',
    color: ({ colorPrimary }) => colorPrimary || common.black,
  },
  primaryText: {
    fontWeight: ({ primaryWeight }) => (primaryWeight ? 700 : 400),
  },
  secondary: {
    color: ({ colorSecondary }) => colorSecondary || palette.primary.main,
    textAlign: 'right',
  },
  secondaryText: {
    fontWeight: 400,
    color: ({ colorSecondary }) => colorSecondary || palette.primary.main,
  },
}));

export default useStyles;
