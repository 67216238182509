/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Dialog,
  IconButton,
  AppBar,
  Toolbar,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { ReactComponent as ZoiperLogo } from '../../../../assets/zoiper.svg';
import ResponsiveTable from '../../../../shared/components/responsiveTable';
import LabelTypography from '../../../../shared/components/labelTypography/LabelTypography';
import { toMoney, toPercent, toPhone } from '../../../../shared/utils/parsers';
import apiService from '../../../../shared/services/apiService';
import {
  recoveryDeniedAction, agendamentoAction, recoveryGiveUpAction, recuperarAction,
} from '../actions';
import resolveError from '../../../../shared/utils/resolveError';
import useStyles from './style';
import { DeleteOutline, ErrorOutline } from '@material-ui/icons';
import CardBarNew from '../../../../shared/components/cardBarNew';
import { recuperar } from '../repository';
import { push } from '../../../../history';

const DetailslistRecovery = ({
  checkoutDetail, recoveryDenied, setTradeDetails, recoveryGiveUp, recuperar, snack, user
}) => {
  const classes = useStyles();
  const { results } = checkoutDetail;
  const [openSemContato, setOpenSemContato] = useState(false);
  const [openDesistiu, setOpenDesistiu] = useState(false);
  const [valueRadioButton, setValueRadioButton] = useState('');
  const [motivo, setMotivo] = useState('');
  const [booleanClip, setBooleanClip] = useState(false);
  const [hasMarketplace, setHasMarketplace] = useState(false);
  const [productsMarketplaceList, setProductsMarketplaceList] = useState([]);
  const [hasRemovedItems, setHasRemovedItems] = useState(false);
  const handleClose = () => {
    setOpenSemContato(false);
    setHasMarketplace(false);
    setOpenDesistiu(false);
    setBooleanClip(false);
    setHasRemovedItems(false);
  };
  const handleFunctionDesistiu = (checkoutId, valueRadioButton, motivo) => {
    const motivoOutros = `${valueRadioButton} - ${motivo !== '' && motivo}`;
    recoveryGiveUp({ motivo: motivoOutros, checkoutId });
    setOpenDesistiu(false);
    setTradeDetails(false);
    setBooleanClip(false);
  };
  const handleFunctionNoContact = (checkoutId, motivo) => {
    recoveryDenied({ motivo, checkoutId });
    setOpenSemContato(false);
    setTradeDetails(false);
    setBooleanClip(false);
  };

  const [requestingRecovery, setRequestingRecovery] = useState(false);

  const handleFunctionAdd = (checkoutId, codCliente) => {
    const itemsMarketplace = results?.produtos?.filter((product) => { return product.idProdMktPlace })

    if (itemsMarketplace.length > 0) {
      console.log(itemsMarketplace)
      setHasMarketplace(true)
      setProductsMarketplaceList(itemsMarketplace)
    } else {

      if (requestingRecovery) return;
      setRequestingRecovery(true);

      recuperar({
        userId: user.idVendedor,
        checkoutId,
        codCliente,
      }).then((response) => {
        push(`/checkout/${response.results}`);
        snack.enqueueSnackbar('Orçamento Recuperado com sucesso!', { variant: 'success' });
      }).finally(() => {
        setBooleanClip(false);
        setRequestingRecovery(false);
      });
    }

  };

  const removeMarketplaceItems = (checkoutId, codCliente) => {
    if (requestingRecovery) return;
    setRequestingRecovery(true);

    setHasMarketplace(false);
    setHasRemovedItems(true);

    recuperar({
      userId: user.idVendedor,
      checkoutId,
      codCliente,
    }).then((response) => {
      push(`/checkout/${response.results}`);
      snack.enqueueSnackbar('Orçamento Recuperado com sucesso!', { variant: 'success' });
    }).finally(() => {
      setBooleanClip(false);
      setRequestingRecovery(false);
    });
  }

  const telefone = results !== undefined && results.dddCliente + results.telefoneCliente;

  const [dadosCliente, setDadosCliente] = useState('');

  const handleLigar = async () => {
    setBooleanClip(true);
    try {
      const data = await apiService.post(`/vendedores/discagem/${user.ramal}/${telefone}`);
      if (data.status) {
        snack.enqueueSnackbar('Estamos ligando para seu cliente!', { variant: 'success' });
      }
    } catch (error) {
      snack.enqueueSnackbar(resolveError(error), { variant: 'error' });
    }
  };

  const getDadosCliente = async () => {
    try {
      setDadosCliente({});
      const data = await apiService.get(`/cliente/${results.codCliente}/detalhescrm`);

      if (data.status) {
        setDadosCliente(data.data.results);
      }
    } catch (error) {
      setDadosCliente({});
    }
  };

  useEffect(() => {
    getDadosCliente();
  }, [results])

  return (
    <>
      <CardBarNew title="Home" />
      <section style={{ marginTop: 7 }}>
        {results !== undefined
      && (
      <>

        <Paper elevation={1} className={classes.customerPaper}>
            {dadosCliente && (dadosCliente.nomeCliente || dadosCliente.dataCadastro) ? (
              <>
                <Typography className={classes.customerTitle} variant="subtitle1" >Dados do Cliente</Typography>
                <Grid container className={classes.customerGrid}>
                  <Grid item sm={3}>
                    <LabelTypography label="Nome do Cliente" text={(dadosCliente.nomeCliente + (dadosCliente.nomeSocial ? ` (${dadosCliente.nomeSocial})`  : '')) || 'N/D'}/>
                  </Grid>

                  <Grid item sm={3}>
                    <LabelTypography label="Data do Cadastro" text={dadosCliente.dataCadastro}/>
                  </Grid>

                  <Grid item sm={2}>
                    <LabelTypography label="Pedidos Realizados" text={dadosCliente.quantidadeTotalDePedidos}/>
                  </Grid>

                  <Grid item sm={2}>
                    <LabelTypography label="Pedidos Pagos" text={dadosCliente.quantidadeDePedidosPagos}/>
                  </Grid>

                  <Grid item sm={2}>
                    <LabelTypography label="Ticket Médio" text={toMoney(dadosCliente.ticketMedioPorPedido)}/>
                  </Grid>

                  <Grid item sm={3}>
                    <LabelTypography label="Data Último Pedido Pago" text={dadosCliente.dataUltimoPedidoPago || 'N/D'}/>
                  </Grid>

                  <Grid item sm={3}>
                    <LabelTypography label="% maior cupom disponível" text={toPercent(dadosCliente.cupomDeDesconto)}/>
                  </Grid>

                  <Grid item sm={2}>
                    <LabelTypography label="Somatória dos Vales" text={toMoney(dadosCliente.saldoTotalValesBSeller)}/>
                  </Grid>

                  <Grid item sm={3}>
                    <LabelTypography label="Média de SKUs" text={dadosCliente.mediaDeSkusPorPedido}/>
                  </Grid>
                </Grid>

                <Divider className={classes.customerDivider}/>
                <Typography className={classes.customerTitle} variant="subtitle1" >Dados do Orçamento</Typography>

                <Grid container >
                  <Grid item xs={3}>
                    <LabelTypography label="Data Orçamento" text={moment(results.dataOrcamento).calendar()}/>
                  </Grid>

                  <Grid item xs={3}>
                    <LabelTypography label="Valor" text={toMoney(results.valor)}/>
                  </Grid>

                  <Grid item xs={3}>
                    <LabelTypography label="E-mail" text={results.emailCliente || 'N/D'}/>
                  </Grid>

                  <Grid item xs={3}>
                    <Box display="flex">
                      <Button
                        onClick={handleLigar}
                        startIcon={<ZoiperLogo />}
                        className={booleanClip ? classes.buttonLigarSucess : classes.buttonLigar}
                      >
                        LIGAR -
                        {' '}
                        {toPhone(results.dddCliente + results.telefoneCliente)}
                      </Button>
                      <CopyToClipboard
                        text={`${toPhone(results.dddCliente + results.telefoneCliente)}`}
                      >
                        <Tooltip title="Copiar Telefone">
                          <Button
                            className={classes.buttonCopyPhone}
                          >
                            <FileCopyOutlinedIcon />
                          </Button>
                        </Tooltip>
                      </CopyToClipboard>
                    </Box>
                  </Grid>
                </Grid>
              </>
          ) : 
            <Box display="flex" justifyContent="center" alignItems="center" gridColumnGap={16}>
              <CircularProgress />
              <Typography variant="body2" style={{ color: '#888E9D' }}>Carregando...</Typography>
            </Box>
          }
        </Paper>

        <Paper elevation={1}>
          <ResponsiveTable pointer>
            <TableHead>
              <TableRow style={{ height: 30 }}>
                <TableCell component="th" align="left" className={classes.textsSilver}>
                  Código
                </TableCell>
                <TableCell component="th" className={classes.textsSilver}>
                  Produto
                </TableCell>
                <TableCell component="th" className={classes.textsSilver}>
                  Qtd.
                </TableCell>
                <TableCell component="th" className={classes.textsSilver}>
                  ST
                </TableCell>
                <TableCell component="th" className={classes.textsSilver}>
                  Vlr.Unitário
                </TableCell>
                <TableCell component="th" className={classes.textsSilver}>
                  Vlr.Total
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {results.produtos.map((item) => (
                <TableRow
                  key={item.codProduto}
                  hover
                >
                  <TableCell datatype="Código" align="left">
                    #
                    {item.codProduto}
                  </TableCell>

                  <TableCell datatype="Produto">
                    <Box display="flex">
                      <Paper elevation={2} style={{ width: 80, height: 90 }}>
                        <img
                          src={`https://img.lojadomecanico.com.br/IMAGENS/${item.foto}`}
                          alt="imagem"
                          style={{ width: 80, height: 90 }}
                        />
                      </Paper>
                      <Box marginLeft={1}>
                        <Typography>{item.produto}</Typography>
                        <Typography className={classes.textsSilver}>
                          Bseller
                          {' '}
                          {item.idItemBseller}
                          {' '}
                          -
                          {' '}
                          {item.codigo}
                        </Typography>
                        <span className={classes.textsSilver}>
                          <span>Você irá receber </span>
                          <span style={{ fontWeight: 'bold' }}>{item.pontos}</span>
                          <span> G-Points</span>
                        </span>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell datatype="Qtd.">
                    {item.quantidade}
                  </TableCell>

                  <TableCell datatype="ST">
                    {toMoney(item.st)}
                  </TableCell>

                  <TableCell datatype="Vlr.Unitário">
                    {toMoney(item.vlrUnitario)}
                  </TableCell>

                  <TableCell datatype="Vlr.Total">
                    {toMoney(item.vlrTotal)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ResponsiveTable>
        </Paper>

        <div className={classes.buttonsBottom}>
          <Button
            className={classes.buttonAdicionarAoCarrinho}
            onClick={() => handleFunctionAdd(results.checkoutId, results.codCliente)}
          >
            ADICIONAR AO CARRINHO
          </Button>
        </div>

        {openSemContato
        && (
        <Dialog onClose={handleClose} open={openSemContato} fullWidth maxWidth="sm">
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography className={classes.titleModal}>SEM CONTATO</Typography>
              <Box position="absolute" top={4} right={4}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <Grid item container sm={11} className={classes.gridDialog}>
            <Typography className={classes.typographDialog}>Observação *</Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
              fullWidth
              rows={5}
              placeholder="Texto aqui...."
              variant="outlined"
            />
          </Grid>
          <div className={classes.divButton}>
            <Button
              disableFocusRipple
              disabled={!(motivo.length > 1)}
              className={classes.buttonSalvar}
              onClick={() => handleFunctionNoContact(results.checkoutId, motivo)}
            >
              Salvar
            </Button>
          </div>
        </Dialog>
        )}

        {openDesistiu
        && (
        <Dialog onClose={handleClose} open={openDesistiu} fullWidth maxWidth="sm">
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography className={classes.titleModal}>DESISTIU DA COMPRA</Typography>
              <Box position="absolute" top={4} right={4}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <Grid item sm={11} className={classes.gridDialog}>
            <Typography className={classes.typographDialog}>Qual o motivo da recusa?</Typography>
            <RadioGroup style={{ marginLeft: 20 }}>
              <FormControlLabel
                value="Cliente já comprou na Loja do Mecânico"
                onChange={(e) => setValueRadioButton(e.target.value)}
                control={<Radio className={classes.radioRecusa} />}
                label="Cliente já comprou na Loja do Mecânico"
              />
              <FormControlLabel
                value="Cliente comprou no concorrente"
                onChange={(e) => setValueRadioButton(e.target.value)}
                control={<Radio className={classes.radioRecusa} />}
                label="Cliente comprou no concorrente"
              />
              <FormControlLabel
                value="Valor do Frete"
                onChange={(e) => setValueRadioButton(e.target.value)}
                control={<Radio className={classes.radioRecusa} />}
                label="Valor do Frete"
              />
              <FormControlLabel
                value="Prazo de Entrega"
                onChange={(e) => setValueRadioButton(e.target.value)}
                control={<Radio className={classes.radioRecusa} />}
                label="Prazo de Entrega"
              />
              <FormControlLabel
                value="Desistiu da Compra"
                onChange={(e) => setValueRadioButton(e.target.value)}
                control={<Radio className={classes.radioRecusa} />}
                label="Desistiu da Compra"
              />
              <FormControlLabel
                value="Produto esgotado"
                onChange={(e) => setValueRadioButton(e.target.value)}
                control={<Radio className={classes.radioRecusa} />}
                label="Produto esgotado"
              />
              <FormControlLabel
                value="Outros"
                onChange={(e) => setValueRadioButton(e.target.value)}
                control={<Radio className={classes.radioRecusa} />}
                label="Outros"
              />
            </RadioGroup>
            {valueRadioButton === 'Outros'
              && (
              <div className={classes.inputOters}>
                <TextField
                  id="outlined-multiline-static"
                  label="Especifique o motivo *"
                  multiline
                  value={motivo}
                  onChange={(e) => setMotivo(e.target.value)}
                  fullWidth
                  rows={4}
                  placeholder="Texto aqui...."
                  variant="outlined"
                />
              </div>
              )}
          </Grid>
          <div className={classes.divButton}>
            <Button
              disableFocusRipple
              disabled={valueRadioButton === 'Outros' && !(motivo.length > 1)}
              className={classes.buttonSalvar}
              onClick={() => handleFunctionDesistiu(results.checkoutId, valueRadioButton, motivo)}
            >
              {'Enviar  >'}
            </Button>
          </div>
        </Dialog>
        )}

        {hasMarketplace && (<Dialog onClose={handleClose} open={hasMarketplace} fullWidth maxWidth="md">
          <AppBar className={classes.alertAppBar}>
            <Toolbar>
              <div className={classes.centerInline}>
                <ErrorOutline className={classes.alertIcon} />
                <Typography className={classes.alertTitle}>Atenção</Typography>
              </div>
              <Box position="absolute" top={4} right={4}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <Divider />
          <Grid item container sm={11} className={classes.gridDialog}>
            <Typography className={classes.typographDialog}>Os itens abaixo são provenientes de Marketplace. Para prosseguir com a venda, é necessario excluí-los do carrinho.</Typography>
              <div className={classes.marketplaceList}>
                {productsMarketplaceList.map((produto) => {
                  return (
                    <Grid container className={classes.productList}>
                      <Grid item className={classes.productImgBadge}>
                        <Typography className={classes.alertBadge}>{produto.quantidade}</Typography>
                        <img className={classes.alertProductImg} src={`https://img.lojadomecanico.com.br/IMAGENS/${produto.foto}`} alt={produto.produto} />
                      </Grid>
                      <Grid item className={classes.productDetails}>
                        <div>
                          <Typography className={classes.productTitle}>{produto.produto}</Typography>
                          <Typography className={classes.productCode}>#{produto.codProduto} • Bseller {produto.idItemBseller} • {produto.codigo}</Typography>
                          <div className={classes.centerInline}><img className={classes.gPoints} src={'https://img.lojadomecanico.com.br/favicons/site/iconscarrinhoGpoints.png'} alt={'G-Points'} /><Typography className={classes.productPoints}><b>{produto.pontos}</b> pontos</Typography></div>
                        </div>
                        <div className={classes.productValue}>
                          <Typography>Total</Typography>
                          <Typography className={classes.productTotal}>{produto.vlrTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  )
                })}
              </div>
          </Grid>
          <div style={{ gap: 10 }} className={classes.divButton}>
          <Button
              className={classes.buttonCancelar}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              className={classes.buttonExcluir}
              onClick={() => {
                const { checkoutId, codCliente } = results
                removeMarketplaceItems(checkoutId, codCliente)
              }}
            >
              Excluir itens
            </Button>
          </div>
        </Dialog>)}

        {hasRemovedItems && (
          <Dialog onClose={handleClose} open={hasRemovedItems} maxWidth="sm">
            <div className={classes.gridDialogRemoved}>
              <div className={classes.removeIconBg}>
                <DeleteOutline className={classes.removeIcon} />
              </div>
              <Typography className={classes.removeTitle}>Itens Excluídos</Typography>
              <Typography className={classes.removeDescription}>Todos os itens provenientes de Marketplace foram excluídos do carrinho com êxito.</Typography>
            </div>
          </Dialog>
        )}

      </>
      )}
      </section>
    </>
  );
};
DetailslistRecovery.defaultProps = {
  snack: null,
};
DetailslistRecovery.propTypes = {
  setTradeDetails: PropTypes.func.isRequired,
  checkoutDetail: PropTypes.instanceOf(Object).isRequired,
  recoveryDenied: PropTypes.func.isRequired,
  recoveryGiveUp: PropTypes.func.isRequired,
  recuperar: PropTypes.func.isRequired,
  snack: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
  nome: PropTypes.string.isRequired,
};

const mapState = (state) => {
  const { snack } = state.main.app;
  const { checkout } = state.main.checkout.geral;
  return ({
    ...state.main.app,
    ...state.main.recovery.list,
    user: state.auth.login.user,
    checkout,
    snack,
  });
};
export default connect(mapState, {
  recoveryDenied: recoveryDeniedAction,
  recoveryGiveUp: recoveryGiveUpAction,
  agendamentoAccept: agendamentoAction,
  recuperar: recuperarAction,
})(DetailslistRecovery);
