/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress,
  Tooltip, Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { green } from '@material-ui/core/colors';
import { CloseRounded } from '@material-ui/icons';
import { closeConfirmDialogAction, confirmFreightAction, submitOrderAction } from '../../actions';
import useStyles from './styles';
import FilialItems from './FilialItems';
import ConfirmFreight from './ConfirmFreight';
import ConfirmPayment from './ConfirmPayments';
import CustomButtom from '../../../../shared/components/customButton';
import LuckyForThoseWhoDo from '../../../../shared/components/campaign/LuckyForThoseWhoDo';
const CheckoutConfirmDialog = ({
  dialogConfirm, closeConfirmDialog, checkout, submitOrder, loading, freighConfirmed,
  confirmFreight, user, handleNewDate
}) => {
  const classes = useStyles();
  const [valueRadioButton] = useState(checkout.customer.revendedor);
  const [nameFile, setNameFile] = useState('');
  const [kbFile, setKbFile] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [confirmAdress, setConfirmAdress] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const ref = useRef();

  const bodyJson = {
    checkoutId: checkout.checkoutId,
    mesmoDia: checkout.checkedSameday,
    retiraCd: checkout.checkedPickup,
    revenda: valueRadioButton,
    arquivoCompra: selectedFile,
  };

  const handleSubmitOrder = () => submitOrder(
    bodyJson,
  );

  let BP;
  checkout.payments.forEach((payment) => {
    if (payment.type === 'BP') {
      BP = true;
      return BP;
    }
    return BP;
  });

  const handleFileChange = (e) => {
    if (e.target.files[0].size >= 5000000) {
      setErrorFile(true);
      ref.current.value = '';
      setSelectedFile('');
      setNameFile('');
      setKbFile('');
    } else {
      setErrorFile(false);
      setSelectedFile(e.target.files[0]);
      setNameFile(`${e.target.files[0].name.substring(0, 19)}...`);
      setKbFile(`(${Math.round(e.target.files[0].size / 100 / 10)} kB)`);
    }
  };

  const handleConfirmAdress = () => {
    setConfirmAdress(!confirmAdress);
  };

  const handleRemoveFile = () => {
    ref.current.value = '';
    setSelectedFile('');
    setNameFile('');
    setKbFile('');
  };

  const isFinalConsumer = () => checkout.customer.customerId === 3422299;

  useEffect(() => {
    if (!dialogConfirm) {
      setConfirmAdress(false);
    }
  }, [dialogConfirm]);

  return (
    <Dialog
      open={dialogConfirm}
      onClose={closeConfirmDialog}
      fullWidth
      classes={{ paper: classes.root }}
      maxWidth="md"
    >
      {loading && <LinearProgress />}

      <DialogTitle>
        Finalizar Pedido
      </DialogTitle>

      <DialogContent>
        {checkout.itemsFilials.map((filial) => (
          <FilialItems
            key={filial.idFilial}
            filial={filial}
          />
        ))}

        <Box
          display="flex"
          alignItems="center"
          p="10px 8px 10px 0"
          color="#939393"
        >
          <Typography variant="subtitle1" color="inherit">
            <Box fontWeight={500}>
              Dados de Entrega
            </Box>
          </Typography>
        </Box>

        <ConfirmFreight
          address={checkout.customer.address}
          checkedPickup={checkout.checkedPickup}
          checkedNitrous={checkout.checkedNitrous}
          enablePickupTransfer={checkout.enablePickupTransfer}
          forceFreight={checkout.forceFreight}
          checkedSameday={checkout.checkedSameday}
          checkedSchedule={checkout.checkedSchedule}
          dataEntregaAgendadaEscolhida={checkout.dataEntregaAgendadaEscolhida}
          handleNewDate={handleNewDate}
          customer={checkout.customer}
          freight={checkout.resume.freight}
          estimatedDeliveryDays={checkout.resume.estimatedDeliveryDays}
          confirmFreight={confirmFreight}
          freighConfirmed={freighConfirmed}
          confirmAdress={confirmAdress}
          handleConfirmAdress={handleConfirmAdress}
        />
      </DialogContent>

      <ConfirmPayment checkout={checkout} />

      {/* Campanha Sorte de Quem Faz */}
      {(user.bitCampanhaAtiva && !isFinalConsumer()) && (
        <LuckyForThoseWhoDo luckyNumbers={checkout.quantidadeNumerosDaSorte} total={checkout.resume.total}/>
      )}

      <DialogActions style={{ padding: '20px 20px' }}>

        {BP && checkout.customer.typePerson === 'J' && (
          <div style={{
            display: 'flex', flexGrow: '1', justifyContent: 'start', flexDirection: 'column', cursor: 'pointer'
          }}
          >
            <div style={{ margin: '-7px 0 10px 0' }}>Ordem de Compra:</div>
            <div className={classes.fileUpload}>
              <p style={{
                color: '#2296F3', fontSize: '14px', display: 'flex',
              }}
              >
                {
                !nameFile && !kbFile ? (
                  <div style={{
                    display: 'flex', columnGap: '4px', alignItems: 'center', cursor: 'pointer',
                  }}
                  >
                    Anexar arquivo
                    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.3337 11.834V8.50065H12.8337L9.50033 4.33398L6.16699 8.50065H8.66699V11.834H10.3337Z" fill="#2296F3" />
                      <path d="M5.33366 11.8337H7.00033V10.167H5.33366C3.95533 10.167 2.83366 9.04533 2.83366 7.66699C2.83366 6.49699 3.83283 5.37033 5.06116 5.15449L5.54533 5.06949L5.70533 4.60449C6.29116 2.89533 7.74616 1.83366 9.50033 1.83366C11.7978 1.83366 13.667 3.70283 13.667 6.00033V6.83366H14.5003C15.4195 6.83366 16.167 7.58116 16.167 8.50033C16.167 9.41949 15.4195 10.167 14.5003 10.167H12.0003V11.8337H14.5003C16.3387 11.8337 17.8337 10.3387 17.8337 8.50033C17.8324 7.75333 17.5809 7.02831 17.1192 6.44102C16.6576 5.85373 16.0125 5.43804 15.287 5.26033C14.9228 2.39199 12.467 0.166992 9.50033 0.166992C7.20366 0.166992 5.20866 1.50949 4.29783 3.62533C2.50783 4.16033 1.16699 5.85033 1.16699 7.66699C1.16699 9.96449 3.03616 11.8337 5.33366 11.8337Z" fill="#2296F3"/>
                    </svg>
                  </div>
                )
                  : (
                    <div style={{
                      display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '2px', cursor: 'pointer',
                    }}
                    >
                      <div>{nameFile}</div>
                      <div style={{ fontSize: '10px', color: '#242424', fontWeight: '500' }}>{kbFile}</div>
                    </div>
                  )
                }
              </p>
              <input
                className={classes.upload}
                type="file"
                onChange={handleFileChange}
                ref={ref}
                accept="application/msword, application/vnd.ms-powerpoint,
                text/plain, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ cursor: 'pointer' }}
              />
            </div>
            {nameFile && kbFile && (
            <div>
              <button type="button" className={classes.buttonRemoveFile} onClick={handleRemoveFile}>
                <CloseRounded style={{ height: '15px', width: '15px' }} />
              </button>
            </div>
            )}
            {errorFile && (
              <div style={{
                color: 'red', fontSize: '11px', textAlign: 'center', marginTop: '4px',
              }}
              >
                Ops! Tamanho máximo 5Mb.
              </div>
            )}
          </div>
        )}

        <Tooltip
          title={!freighConfirmed ? 'Confirme a forma de entrega' : ''}
          disableFocusListener={freighConfirmed && selectedFile}
          disableHoverListener={freighConfirmed && selectedFile}
          disableTouchListener={freighConfirmed && selectedFile}
        >
          <span>
            <CustomButtom
              color={green['500']}
              onClick={handleSubmitOrder}
              disabled={loading || !freighConfirmed || !confirmAdress}
            >
              FINALIZAR PEDIDO
            </CustomButtom>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

CheckoutConfirmDialog.propTypes = {
  dialogConfirm: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  freighConfirmed: PropTypes.bool.isRequired,
  closeConfirmDialog: PropTypes.func.isRequired,
  confirmFreight: PropTypes.func.isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
  handleNewDate: PropTypes.func,
  submitOrder: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => ({
  ...state.main.checkout.geral,
  user: state.auth.login.user,
});

export default connect(mapState, {
  closeConfirmDialog: closeConfirmDialogAction,
  submitOrder: submitOrderAction,
  confirmFreight: confirmFreightAction,
})(CheckoutConfirmDialog);
