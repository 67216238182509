const cardExpiration = (value) => {
  let parsed = value;
  if (!parsed) return '';

  parsed = parsed.toString().replace(/[^0-9]+/g, '');

  if (parsed.length > 2) parsed = `${parsed.substring(0, 2)}/${parsed.substring(2)}`;
  if (parsed.length > 7) parsed = parsed.substring(0, 7);

  return parsed;
};

export default cardExpiration;
