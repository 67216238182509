import { makeStyles } from '@material-ui/core';
import {
  BuildingIcon,
  ClientIcon,
  SellerIcon,
  StoreFilialIcon,
} from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  stockGrid: {
    width: 388,
    marginBottom: 30,
  },
  stockHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F6F6F7',
    border: '1px solid #E3E3E7',
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 700,
    padding: '4px 10px',

    '& span:first-of-type': {
      textTransform: 'uppercase',
    },
  },
  stockBody: {
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #E3E3E7',
    borderTop: 0,

    '& [class*=MuiBox]': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& span:last-of-type': {
        textTransform: 'uppercase',
      },
    },

    '& span:nth-last-child(-n+2)': {
      padding: '0 12px',
    },

    '&:nth-child(-n+4)': {
      display: 'flex',
    },

    '&.showMore': {
      display: 'flex',
    },
  },
  stockImage: {
    width: 32,
    height: 32,
    borderRight: '1px solid #E3E3E7',
    backgroundColor: '#F6F6F7 !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    background: `url(${StoreFilialIcon})`,

    '&.main': {
      background: `url(${BuildingIcon})`,
    },
    '&.user': {
      background: `url(${SellerIcon})`,
      backgroundColor: '#FFF9E8 !important',
    },
    '&.client': {
      background: `url(${ClientIcon})`,
      backgroundColor: '#EAF5FD !important',
    },
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'end',

    '& button': {
      paddingTop: 8,
      border: 0,
      background: 'none',
      fontSize: 12,
      lineHeight: '18px',
      color: '#2296F3',
      fontFamily: ['Poppins', 'sans serif'],
    },
  },
  [breakpoints.down('sm')]: {
    stockGrid: {
      width: 300,
    },
  },
}));

export default useStyles;
