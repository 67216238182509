import React from 'react';
import { Route } from 'react-router-dom';
import DevAcessPage from './Page';
import CustomSwitch from '../../shared/components/customSwitch';

const DevAcessRouter = () => (
  <CustomSwitch>
    <Route
      path="/devAcess"
      component={DevAcessPage}
      exact
    />
  </CustomSwitch>
);

export default DevAcessRouter;
