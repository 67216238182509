import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, zIndex }) => ({
  svgLogo: {
    display: 'flex',
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  svgLogoImg: {
    width: 'auto',
    height: 24,
    animation: 'zoom-in-zoom-out 1s ease infinite',
  },
  svgLogoBorder: {
    content: " ",
    display: 'block',
    position: 'absolute',
    width: 60,
    height: 60,
    margin: 1,
    borderRadius: '50%',
    border: '3px solid #FFFFFF',
    borderColor: '#FFFFFF transparent #FFFFFF transparent',
    animation: 'borda-loading 1.2s linear infinite',
    top: -18,
  },
  loadCar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 9999,
    [breakpoints.down('sm')]: {
      left: '43%',
    },
  },
  label: {
    marginTop: 25,
    // color: '#fa4616',
    color: '#FFFFFF',
    
    textSlign: 'center',
    lineHeight: 1.5,
    fontSize: 14,
    fontWeight: 600,
  },
  backdrop: {
    zIndex: zIndex.drawer + 1,
    color: '#fff',
    // backgroundColor: 'rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(4px)',
  },
}));

export default useStyles;
