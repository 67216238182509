import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, transitions }) => ({
	name: {
		[breakpoints.down('sm')]: {
			width: 'auto',
		},
		[breakpoints.up('md')]: {
			width: '25%',
		},
	},
	amount: {
		[breakpoints.down('sm')]: {
			width: 'auto',
		},
		[breakpoints.up('md')]: {
			width: '15%',
		},
	},
	points: {
		[breakpoints.down('sm')]: {
			width: 'auto',
		},
		[breakpoints.up('md')]: {
			width: '10%',
		},
	},
	paginationContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	customerCell: {
		textTransform: 'uppercase',
		textAlign: 'center',
	},
	tableHeadRow: {
		'& .MuiTableCell-root': {
			fontWeight: 'bold',
			padding: '10px 20px',
		},
	},
	tableHeadCell: {
		textAlign: 'center',
		width: 'calc(100% / 8)',
	},
	tableBodyRow: {
		'& .MuiTableCell-root': {
			padding: '0',
			minHeight: '62px'
		},
	},
	tableBodyCell: {
		textAlign: 'center',
		width: 'calc(100% / 8)',
		'& .MuiChip-root': {
			height: '40px',
			borderRadius: '20px',
		},
		'& .MuiChip-label': {
			fontSize: '12px',
		}
	},
	tableBodyCellCustomer: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		textAlign: 'center',
	},
	tableBodyCellCanalVenda: {
		textAlign: 'center',
		'& span': {
			whiteSpace: 'normal'
		}
	},
	orderStatus: {
		width: '12px',
		height: '12px',
		fontSize: '7px',
		marginRight: '4px',
		borderRadius: '20px',
		'& .MuiBox-root': {
		}
	},
	tableBodyCellClickable: {
		textAlign: 'start',
		'&:hover': {
			'& .MuiBox-root': {
				color: '#FF5225',
				transition: `color .1s`,
			},
		},
		fontWeight: '600',
		cursor: 'pointer',
		color: 'primary',
	},
	tableBodyCellClickableDisabled: {
		textAlign: 'start',
		fontWeight: '600',
		cursor: 'normal',
		color: 'primary',
	},
}));

export default useStyles;
