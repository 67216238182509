/* istanbul ignore file */

import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import reducer from './reducers';
import Routes from './router';
import theme from './shared/theme';
import UnknowError from './errors/UnknowError';
import ClearCache from './clearCache';
import { ClearCacheProvider } from 'react-clear-cache';

const store = createStore(
  reducer,
  applyMiddleware(thunk),
)

export default () => (
  <ThemeProvider theme={theme}>
    <CssBaseline>
      <ErrorBoundary FallbackComponent={UnknowError}>
        <ClearCache>
          <ClearCacheProvider duration={60000}>
            <Provider store={store}>
              <SnackbarProvider maxSnack={6}>
                <Routes />
              </SnackbarProvider>
            </Provider>
          </ClearCacheProvider>
        </ClearCache>
      </ErrorBoundary>
    </CssBaseline>
  </ThemeProvider>
);
