/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@material-ui/core';
import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

const CustomNavLink = forwardRef((props, ref) => (
  <Box
    ref={ref}
    flexGrow={1}
  >
    <NavLink {...props} />
  </Box>
));

export default CustomNavLink;
