/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
  Box,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getPdfPedidosAction } from '../../actions';
import { push } from '../../../../history';
import { toPhone, toMoney } from '../../../../shared/utils/parsers';
import Selectable from '../../Selectable';
import useStyles from './styles';

const OrdersTableBody = ({
  order,
  index,
  statusPayment,
  getPdf,
  loadingPDF,
  setDeliverySelected,
}) => {
  const classes = useStyles();
  const [openTooltip, setOpenTooltip] = useState(false);

  const {
    iconBtn,
    tableRowBody,
    tableCellBody,
    launchIcon,
    circleIcon,
    orderBox,
    labelBtn,
    popper,
    tooltip,
    btnOrderId,
    copyIcon,
    btnTracking,
  } = classes;

  const handleTooltip = () => setOpenTooltip(!openTooltip);

  const copyToClipboard = (idProduct) => {
    navigator.clipboard.writeText(idProduct).catch((err) => console.log(err.message));
    handleTooltip();
  };

  const handleOpenModal = () => {
    setDeliverySelected({
      idEntrega: order.idEntrega,
      codPedido: order.codPedido,
    });
  };

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        handleTooltip();
      }, 1000);
    }
  }, [openTooltip]);

  return (
    <TableRow
      key={`row-${index + 1}`}
      classes={{ root: tableRowBody }}
    >
      <TableCell classes={{ root: tableCellBody }} datatype="Pedido">
        <Box className={orderBox}>
          <Button
            disableRipple
            classes={{ root: iconBtn, label: labelBtn }}
            onClick={() => push(`/order/${order.codPedido}/customer/${order.codCliente}/${order.canalOrigem}`)}
          >
            <Icon classes={{ root: launchIcon }}>launch</Icon>
            <Box>
              {order.codPedido}
            </Box>
          </Button>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltip}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{ popper, tooltip }}
            title="Código copiado"
            placement="top"
          >
            <button
              type="button"
              className={btnOrderId}
              onClick={() => copyToClipboard(order.codPedido)}
            >
              <div className={copyIcon} />
            </button>
          </Tooltip>
          <Tooltip arrow title={statusPayment.descricao}>
            <IconButton classes={{ root: iconBtn }} style={{ color: statusPayment.color }}>
              <Icon
                classes={{ root: circleIcon }}
                style={{ backgroundColor: statusPayment.color }}
                className={statusPayment.colorName}
              >
                circle
              </Icon>
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Entrega">
        <Selectable>
          {order.idEntrega }
        </Selectable>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Cliente">
        <Selectable>
          {order.nomeSocial || order.nome}
        </Selectable>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Telefone">
        <Box
          component="span"
          style={{ cursor: 'text' }}
          onClick={(e) => e.stopPropagation()}
          whiteSpace="nowrap"
        >
          {toPhone((order.ddd || '') + order.telefone)}
        </Box>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Tracking">
        <Button classes={{ root: btnTracking }} onClick={handleOpenModal}>
          {order.descricaoTracking}
        </Button>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Data do Pedido">
        <Selectable>
          {moment(order.dataPedido, 'DD-MM-YYYY').format('DD/MM/YYYY')}
        </Selectable>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Valor">
        <Box
          component="span"
          style={{ cursor: 'text' }}
          onClick={(e) => e.stopPropagation()}
          whiteSpace="nowrap"
        >
          {toMoney(order.total)}
        </Box>
      </TableCell>

      <TableCell classes={{ root: tableCellBody }} datatype="Origem">
        <Selectable>
          {order.canalOrigem}
        </Selectable>
      </TableCell>
      <TableCell classes={{ root: tableCellBody }} datatype="Vendedor">
        <Selectable>
          {order.nomeVendedor}
        </Selectable>
      </TableCell>
      <TableCell classes={{ root: tableCellBody }}>
        <Tooltip title="Imprimir detalhes do pedido">
          <IconButton
            disabled={loadingPDF}
            onClick={(e) => { e.stopPropagation(); getPdf(order.codPedido); }}
          >
            <Icon>print</Icon>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

OrdersTableBody.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  getPdf: PropTypes.func.isRequired,
  loadingPDF: PropTypes.bool.isRequired,
  statusPayment: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  setDeliverySelected: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.main.checkoutsAndOrders,
  loadingPDF: state.main.checkoutsAndOrders.loadings.pdf,
});

export default connect(mapStateToProps, {
  getPdf: getPdfPedidosAction,
})(OrdersTableBody);
