import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Coordenador from './Coordenador/Coordenador';
import ForbidenErrorPage from '../../../errors/ForbidenError';

const RecoveryRelatorioPage = ({ user }) => {
  const relatorio = user.permissoes.find((permission) => (
    permission.id === 2
  ));

  return (
    <>
      {relatorio && <Coordenador />}
      {relatorio === undefined && <ForbidenErrorPage />}

    </>
  );
};
RecoveryRelatorioPage.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
};
const mapState = (state) => ({
  ...state.main.app,
  user: state.auth.login.user,
});

export default connect(mapState, {
})(RecoveryRelatorioPage);
