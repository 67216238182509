const cardFormat = (value) => {
  let parsed = value;
  if (!parsed) return '';

  parsed = parsed.toString().replace(/[^0-9]+/g, '');

  if (parsed.length > 4) parsed = `${parsed.substring(0, 4)} ${parsed.substring(4)}`;
  if (parsed.length > 9) parsed = `${parsed.substring(0, 9)} ${parsed.substring(9)}`;
  if (parsed.length > 14) parsed = `${parsed.substring(0, 14)} ${parsed.substring(14)}`;
  if (parsed.length > 19) parsed = parsed.substring(0, 19);

  return parsed;
};

export default cardFormat;
