import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const CardHeader = ({ title, subtitle, icon }) => {
  const {
    cardHeaderGrid,
    iconHeader,
    typography,
    typographySubtitle,
  } = useStyles();

  return (
    <Grid className={cardHeaderGrid}>
      <span className={`${iconHeader} ${icon}`} />
      <Typography classes={{ root: typography }} variant="h5">{title}</Typography>
      <div className={typographySubtitle}>
        {subtitle && subtitle}
      </div>
      <span />
    </Grid>
  );
};

CardHeader.defaultProps = {
  subtitle: null,
};

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
};

export default CardHeader;
