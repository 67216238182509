import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  DialogContent,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { Close } from '@material-ui/icons';

import { toMoney } from '../../../../shared/utils/parsers';

import useStyles from './style';
import moment from 'moment';

const ModalEntregaQuebrada = ({
  open, entregas, onClose
}) => {
  const classes = useStyles();
  const {
    dialogTitle,
    stockImage,
    stockTitle,
    stockHeader,
    stockBody,
    stockGrid
  } = classes;

  const handleStockTotal = () => {
    return entregas.reduce((acc, { quantity }) => acc + quantity, 0);
  }

  const handleTotalValue = () => {
    return entregas.reduce((acc, { freightValue }) => acc + freightValue, 0);
  }
  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
        <DialogTitle className={dialogTitle}>
          Quebra da Entrega
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid className={stockGrid}>
            <Box className={stockHeader}>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={3} >
                  <span>CD Saída</span>
                </Grid>
                <Grid item xs={4} >
                  <span>Transportadora</span>
                </Grid>
                <Grid item xs={2}>
                  <span>Data</span>
                </Grid>
                <Grid item xs={1}>
                  <span>Qtd.</span>
                </Grid>
                <Grid item xs={1}>
                  <span>Valor</span>
                </Grid>
              </Grid>
            </Box>
            {entregas.map(({
              establishment, bsellerContractId, quantity, freightValue, maxDeliveryDate
            }) => {
              return (
                (
                  <Box key={establishment} className={`${stockBody}`}>
                    <Grid container>
                      <Grid item xs={1}>
                        <Box>
                          <span className={`${stockImage}`} />
                        </Box>
                      </Grid>

                      <Grid item xs={3}>
                        <Box justifyContent="flex-start">
                          <span>{establishment}</span>
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <Box justifyContent="flex-start">
                          <span>{bsellerContractId}</span>
                        </Box>
                      </Grid>

                      <Grid item xs={2}>
                        <Box justifyContent="flex-start">
                          <span>{moment(maxDeliveryDate, 'yyyy-mm-dd').format('DD/MM/YYYY')}</span>
                        </Box>
                      </Grid>

                      <Grid item xs={1}>
                        <Box>
                          <span>{`${quantity.toLocaleString('pt-br')}`}</span>
                        </Box>
                      </Grid>

                      <Grid item xs={1}>
                        <Box>
                          <span>{`${toMoney(freightValue)}`}</span>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )
              );
            })}
            <Box className={`${stockBody} ${stockTitle}`}>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                  <Typography>Estoque total</Typography>
                </Grid>

                <Grid item xs={1}>
                  <Box>
                    <Typography>{`${handleStockTotal().toLocaleString('pt-br')} un.`}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={1}>
                  <Box>
                    <Typography>{`${toMoney(handleTotalValue())}`}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

ModalEntregaQuebrada.defaultProps = {
  entregas: [],
  onClose: () => {},
};

ModalEntregaQuebrada.propTypes = {
  entregas: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
};


export default ModalEntregaQuebrada;
