import { Drawer, makeStyles, withStyles } from '@material-ui/core';
import { CloseIcon, FiltersIcon, TrashIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  clearRefinements: {
    backgroundColor: '#FFFFFF',
    border: 0,
    color: '#2296F3',
    fontSize: 12,
    lineHeight: '18px',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: ['Poppins', 'sans serif'],

    '&:before': {
      content: '""',
      backgroundImage: `url(${TrashIcon})`,
      backgroundRepeat: 'no-repeat',
      padding: '2px 9px',
      marginRight: 3,
    },

    '&:disabled': {
      opacity: 0.6,
      cursor: 'auto',
    },
  },
  filtersBtn: {
    background: `url(${FiltersIcon})`,
    backgroundRepeat: 'no-repeat',
    minWidth: 40,
    height: 40,
    backgroundPosition: 'center',
    border: '1px solid #FE5000',
  },
  filtersIcon: {
    fontSize: '16px',
    lineHeight: '24px',
    '&:before': {
      content: '""',
      background: `url(${FiltersIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: '1px 12px',
      marginRight: 8,
    },
  },
  filterBox: {
    width: 364,
    paddingBottom: 30,

    '& ul': {
      listStyle: 'none',
      padding: '12px 12px 8px 12px',
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },

    [breakpoints.down('sm')]: {
      paddingBottom: 130,
    },
  },
  filterGrid: {
    padding: 18,
    position: 'fixed',
    width: '100%',
    right: 0,
    zIndex: 1,
    background: '#E3E3E7',
    // top: 128,
    top: 79,

    [breakpoints.down('sm')]: {
      top: 80,
    },

    [breakpoints.up('md')]: {
      padding: '25px 36px 18px 94px',
    },

    [breakpoints.up('lg')]: {
      padding: '25px 26px 18px 84px',
    },
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 12px',
    alignItems: 'center',
  },
  close: {
    background: `url(${CloseIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 24,
    height: 24,
  },
  clearAndClose: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  borderHeader: {
    borderBottom: '1px solid #CFCFD4',
    position: 'fixed',
    width: 364,
    backgroundColor: 'white',
    zIndex: 1,
  },
  filterBody: {
    paddingTop: 68,
  },
  filterBoxMain: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sortAndCloseBtns: {
    display: 'flex',
    gap: 20,
  },
  checkboxListBox: {
    flexWrap: 'wrap',
    display: 'flex',
    gap: '10px 30px',

    [breakpoints.down('sm')]: {
      marginTop: 10,
    },

    [breakpoints.down('md')]: {
      gap: 10,
    },
  },
  filtersBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,

    [breakpoints.down('sm')]: {
      gap: 0,
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  [breakpoints.down('xs')]: {
    borderHeader: {
      width: 300,
    },
    filterBox: {
      width: 300,
    },
  },
}));

export const DrawerStyled = withStyles(({ breakpoints }) => ({
  root: {
    zIndex: '1 !important',
  },
  paper: {
    paddingBottom: 50,
    margin: '80px 0 0 64px',

    [breakpoints.down('sm')]: {
      margin: '166px 0 0 0',
    },
  },
}))(Drawer);

export default useStyles;
