import React, {useEffect, useRef, useState} from 'react';
import {
  Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, ListItemText, MenuItem, Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';

const Checkmarks = ({
  input,
  options,
  label,
  meta,
  disabled,
  required,
  readOnly,
  autoFocus,
  multiple,
}) => {
  const showError = meta.error && meta.touched;

  const classes = useStyles();
  const {
    formControl,
    checkbox,
    checked,
    itemBtn,
    menuItem,
    listItem,
  } = classes;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        backgroundColor: '#EDEDED',
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const handleChange = (event) => {
    input.onChange(event.target.value);
  };

  return (
    <FormControl variant="standard" className={formControl} error={showError}>
      <InputLabel id={`${input.name}-label`}>{label}</InputLabel>
      <Select
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...input}
        labelId={`${input.name}-label`}
        id={input.name}
        multiple={multiple}
        renderValue={(selected) => {
          if (multiple) {
            return selected.map(key => options.find(option => option.key === key)?.value).join(', ');
          } else {
            return options.find(option => option.key === selected)?.value;
          }
        }}
        value={multiple ? (input.value || []) : input.value}
        MenuProps={MenuProps}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        autoFocus={autoFocus}
        displayEmpty
      >
        {options.map((option) => (
          <MenuItem
            className={menuItem}
            key={option.key}
            value={option.key}

          >
           <button
           key={option.key}
           value={option.value}
           className={itemBtn}
           onClick={handleChange}
           >
             {
               option.value && (
                 <Checkbox
                   checked={multiple ? (input.value.indexOf(option.key) > -1) : (input.value === option.key)}
                   icon={<span className={checkbox} />}
                   checkedIcon={<span className={clsx((checkbox, checked))} />}
                 />
               )
             }
             <ListItemText className={listItem} primary={option.value} />
           </button>
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

Checkmarks.defaultProps = {
  options: [],
  label: '',
  disabled: false,
  required: false,
  readOnly: false,
  autoFocus: false,
  multiple: false,
};

Checkmarks.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Array),
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default Checkmarks;
