import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  field: {
    width: '100%',
    '&:not(:last-of-type)': {
      marginBottom: 16,
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'end',
    },
  },
  form: {
    width: '100%',
    padding: 16,
    paddingBottom: 0,
  },
  header: {
    backgroundColor: 'transparent',
    border: 'none',
    maxHeight: 49,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
}));

export default useStyles;
