import React, { useEffect, useState } from 'react';
import { FilterList } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { OrcamentoPedidoButton } from './_components/orcamentoPedidoButton';
import Orcamentos from './orcamentos/Orcamentos';
import Pedidos from './pedidos/Pedidos';
import TYPE_PAGES from './typePages';
import {
  getDeliveryDetailsAction,
  getOrcamentosAction,
  getPedidosAction,
  resetDeliveryDetailsAction,
  toggleFilterAction,
} from './actions';
import CardBar from './_components/cardBar';
import FilterCheckout from './_components/filterCheckout/FilterCheckout';
import FilterOrder from './_components/filterOrder/FilterOrder';
import { OrderDetailsModal } from '../../shared/components/orderDetails';

const CheckoutsAndOrdersPage = ({
  page,
  toggleFilter,
  filterOpened,
  getOrcamentos,
  loadings,
  ordersPagination,
  checkoutsPagination,
  getPedidos,
  deliveryDetails,
  getDeliveryDetails,
  resetDeliveryDetails,
}) => {
  const dateFormat = page === TYPE_PAGES.ORCAMENTOS ? 'DD/MM/YYYY' : 'DD-MM-YYYY';
  const d = new Date();
  d.setDate(d.getDate() - 30);

  const startDate = moment(d).format(dateFormat);
  const endDate = moment(new Date()).format(dateFormat);

  const checkoutLocalStorage = localStorage.getItem('filtersCheckout');
  const orderLocalStorage = localStorage.getItem('filtersOrder');

  const filtersCheckout = checkoutLocalStorage ? JSON.parse(checkoutLocalStorage) : {};
  const filtersOrder = orderLocalStorage ? JSON.parse(orderLocalStorage) : {};

  const [filterDataInicioOrcamento, setFilterDataInicioOrcamento] = useState(startDate);
  const [filterDataFimOrcamento, setFilterDataFimOrcamento] = useState(endDate);

  const [filterDataInicioPedido, setFilterDataInicioPedido] = useState(startDate);
  const [filterDataFimPedido, setFilterDataFimPedido] = useState(endDate);
  const [isTracking, setIsTracking] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deliverySelected, setDeliverySelected] = useState(null);

  const closeModal = () => {
    setOpenModal(false);
    setDeliverySelected(null);
    resetDeliveryDetails();
  };

  const toggle = () => toggleFilter({ filterOpened: !filterOpened });

  const handleDateFormat = (date) => (moment(new Date(date)).format(dateFormat));

  const loading = Object.values(loadings).some(Boolean);

  useEffect(() => {
    if (Object.keys(filtersCheckout).length) {
      setFilterDataInicioOrcamento(moment(new Date(filtersCheckout.startDate)).format(dateFormat));
      setFilterDataFimOrcamento(moment(new Date(filtersCheckout.endDate)).format(dateFormat));
    }

    if (Object.keys(filtersOrder).length) {
      setIsTracking(!!filtersOrder.isTracking);
      setFilterDataInicioPedido(moment(new Date(filtersOrder.startDate)).format(dateFormat));
      setFilterDataFimPedido(moment(new Date(filtersOrder.endDate)).format(dateFormat));
    }
  }, []);

  useEffect(() => {
    let startDateCheckout = filterDataInicioOrcamento;
    let endDateCheckout = filterDataFimOrcamento;
    let startDateOrder = filterDataInicioPedido;
    let endDateOrder = filterDataFimPedido;

    if (Object.keys(filtersCheckout).length) {
      startDateCheckout = handleDateFormat(filtersCheckout.startDate);
      endDateCheckout = handleDateFormat(filtersCheckout.endDate);
    }

    if (Object.keys(filtersOrder).length) {
      startDateOrder = handleDateFormat(filtersOrder.startDate);
      endDateOrder = handleDateFormat(filtersOrder.endDate);
    }

    if (page === TYPE_PAGES.ORCAMENTOS) {
      toggleFilter({ filterOpened: !!filtersCheckout.openFilter });
      getOrcamentos(startDateCheckout, endDateCheckout);
    } else if (page === TYPE_PAGES.PEDIDOS) {
      toggleFilter({ filterOpened: !!filtersOrder.openFilter });
      getPedidos(
        startDateOrder,
        endDateOrder,
        Object.keys(filtersOrder).length ? !!filtersOrder.isTracking : isTracking,
      );
    }
  }, [page]);

  useEffect(() => {
    if (deliverySelected) {
      getDeliveryDetails(deliverySelected.idEntrega);
    }
  }, [deliverySelected]);

  useEffect(() => {
    if (deliveryDetails.length) setOpenModal(true);
  }, [deliveryDetails]);

  return (
    <section>
      {
        !!deliveryDetails.length && (
          <OrderDetailsModal
            open={openModal}
            onClose={closeModal}
            detailed={deliveryDetails}
            trackingData={!!deliverySelected && (deliverySelected)}
          />
        )
      }
      {
        page === TYPE_PAGES.ORCAMENTOS ? (
          <>
            <CardBar
              titleElement={(
                <OrcamentoPedidoButton
                  filterDataInicio={filterDataInicioOrcamento}
                  filterDataFim={filterDataFimOrcamento}
                />
              )}
              filterDataInicio={filterDataInicioOrcamento}
              filterDataFim={filterDataFimOrcamento}
              pagination={checkoutsPagination}
              onPageChange={getOrcamentos}
              typePage={TYPE_PAGES.ORCAMENTOS}
            >
              <IconButton onClick={toggle}>
                <FilterList />
              </IconButton>
            </CardBar>

            <FilterCheckout
              setFilterDataInicio={setFilterDataInicioOrcamento}
              setFilterDataFim={setFilterDataFimOrcamento}
              filterDataInicio={filterDataInicioOrcamento}
              filterDataFim={filterDataFimOrcamento}
              loading={loading}
            />

            {loading && <LinearProgress />}
            <Orcamentos />
          </>
        ) : (
          <>
            <CardBar
              titleElement={(
                <OrcamentoPedidoButton
                  filterDataInicio={filterDataInicioPedido}
                  filterDataFim={filterDataFimPedido}
                  isTracking={isTracking}
                />
              )}
              filterDataInicio={filterDataInicioPedido}
              filterDataFim={filterDataFimPedido}
              isTracking={isTracking}
              pagination={ordersPagination}
              onPageChange={getPedidos}
              typePage={TYPE_PAGES.PEDIDOS}
            >
              <IconButton onClick={toggle}>
                <FilterList />
              </IconButton>
            </CardBar>

            <FilterOrder
              setFilterDataInicio={setFilterDataInicioPedido}
              setFilterDataFim={setFilterDataFimPedido}
              filterDataInicio={filterDataInicioPedido}
              filterDataFim={filterDataFimPedido}
              isTrackingState={{ isTracking, setIsTracking }}
              loading={loading}
            />

            {loading && <LinearProgress />}
            <Pedidos
              setDeliverySelected={setDeliverySelected}
            />
          </>
        )
      }
    </section>
  );
};

const mapStateToProps = (state) => ({
  ...state.main.checkoutsAndOrders,
});

CheckoutsAndOrdersPage.propTypes = {
  page: PropTypes.string.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  filterOpened: PropTypes.bool.isRequired,
  getOrcamentos: PropTypes.func.isRequired,
  getPedidos: PropTypes.func.isRequired,
  loadings: PropTypes.instanceOf(Object).isRequired,
  ordersPagination: PropTypes.instanceOf(Object).isRequired,
  checkoutsPagination: PropTypes.instanceOf(Object).isRequired,
  getDeliveryDetails: PropTypes.func.isRequired,
  deliveryDetails: PropTypes.instanceOf(Array).isRequired,
  resetDeliveryDetails: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  toggleFilter: toggleFilterAction,
  getOrcamentos: getOrcamentosAction,
  getPedidos: getPedidosAction,
  getDeliveryDetails: getDeliveryDetailsAction,
  resetDeliveryDetails: resetDeliveryDetailsAction,
})(CheckoutsAndOrdersPage);
