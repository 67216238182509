import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: 16,
    height: 97,
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #CDCDCD',
    borderBottom: '1px solid #CDCDCD',
    textAlign: 'center',
    borderRadius: 0,
  },
  radioLabel: {
    marginRight: 0,
  },
  img: {
    width: '26px',
    height: '26px',
    minWidth: '26px',
    minHeight: '26px',
    color: '#337ab7',
  },
}));

export default useStyles;
