import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, IconButton, Button, LinearProgress } from '@material-ui/core';
import { WarningOutlined } from '@material-ui/icons';
import { Close } from '@material-ui/icons';
import { closeDialogPaymentStatusAction } from './actions';
import AdvancedPaymentFormPix from './AdvancedPaymentFormPix';
import { getConditionsAction } from '../actions';
import ItemAdvancedPaymentDialog from './ItemAdvancedPaymentDialog';
import { toMoney } from '../../../../../shared/utils/parsers';
import apiService from '../../../../../shared/services/apiService';

const DialogPaymentStatus = ({
  closeDialogPs, getConditions, conditions, resume, dialogPsOpened, checkout, selectedItem
}) => {

  const [returnPaymentStatus, setReturnPaymentStatus] = useState({});


  useEffect(() => {
    if (selectedItem.paymentId != undefined) {
      const getInfo = async () => {
        const { data: { results } } = await apiService.get(`/pagamentos/pagamento-remoto/status/${selectedItem.paymentId}`);
        setReturnPaymentStatus(results?.records);
      };
      getInfo();
    }
  }, [selectedItem]);

  return (

    <Dialog
      open={dialogPsOpened}
      onClose={closeDialogPs}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!returnPaymentStatus.acquirerReturnCode && (
        <> 
          <LinearProgress/>
        </>
      )}
      <Box position="relative">
        <Box position="absolute" top={19} left={22}>
          <WarningOutlined style={{color: 'red'}} />
        </Box>

        <DialogTitle id="alert-dialog-title" style={{marginLeft:'31px'}}><span style={{fontSize:17, marginTop: 3, fontWeight: 'bold'}}>{returnPaymentStatus.acquirerReturnCode && `${returnPaymentStatus.acquirerReturnCode} - `} {returnPaymentStatus.acquirerMessage || 'Carregando...'}</span></DialogTitle>

        <DialogContent style={{paddingTop: 0, paddingLeft: 56, paddingRight: 56, paddingBottom: 27, minWidth:'300px'}}>
            {returnPaymentStatus.descriptionStatusGateway}
        </DialogContent>

        <DialogActions style={{justifyContent:'right', backgroundColor: '#F8F9FA'}}>
          <Box display="flex" justifyContent="right" p={1}>
            <span>
              <Button
                color="default"
                fullWidth
                onClick={closeDialogPs}
                style={{borderColor: '#d6d2d2', color: '#615f5f', background: '#ffffff', fontWeight: 'bold', border: '1px solid #d6d2d2', paddingLeft: 13, paddingRight: 13}}
              >
                Fechar
              </Button>
            </span>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

DialogPaymentStatus.propTypes = {
  closeDialogPs: PropTypes.func.isRequired,
  getConditions: PropTypes.func.isRequired,
  dialogPsOpened: PropTypes.bool.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  resume: PropTypes.instanceOf(Object).isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
};

DialogPaymentStatus.defaultProps = {
  conditions: null,
  selectedItem: [],
};

const mapState = (state) => {
  const {
    checkoutId, resume, payments, deniedPayments, orderId,
  } = state.main.checkout.geral.checkout;
  const { chosedFreight } = state.main.checkout.geral;

  return {
    ...state.main.checkout.advanced,
    payments,
    deniedPayments,
    resume,
    checkoutId,
    orderId,
    chosedFreight,
    conditions: state.main.checkout.payment.conditions,
  };
};

export default connect(mapState, {
  closeDialogPs: closeDialogPaymentStatusAction,
  getConditions: getConditionsAction,
})(DialogPaymentStatus);
