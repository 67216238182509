import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, StepConnector } from '@material-ui/core';

const CustomConnector = ({
  color, orientation, active, completed,
}) => {
  const ConnectorWrapper = withStyles({
    root: {
      padding: 0,
    },
    active: {
      '& $line': {
        backgroundColor: color,
      },
    },
    completed: {
      '& $line': {
        backgroundColor: color,
      },
    },
    line: {
      height: 30,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
      width: 2,
    },
  })(StepConnector);

  return (
    <ConnectorWrapper
      orientation={orientation}
      active={active}
      completed={completed}
    />
  );
};

CustomConnector.defaultProps = {
  color: '#C50000',
  orientation: 'vertical',
  completed: false,
  active: false,
};

CustomConnector.propTypes = {
  color: PropTypes.string,
  orientation: PropTypes.string,
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

export default CustomConnector;
