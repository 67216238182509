import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
	rowInfo: {
		display: 'flex',
		flexDirection: 'row',
		[breakpoints.down('md')]: {
      flexDirection: 'column',
    },
	},
	containerInfo: {
		width: '50%',
		[breakpoints.down('md')]: {
      width: '100%',
			padding: 0
    },
	},
	buttonAprove: {
		background: 'green',
		color: 'white',
		'&:hover': {
			background: '#439143',
		},
	},
	buttonReprove: {
		borderColor: '#DC2727',
		color: '#DC2727',
		'&:hover': {
			background: '#ffefef',
		},
	},
	dialogTitle: {
		'& .MuiTypography-root': {
			fontWeight: 'bold',
		},
	},
	customerCell: {
		textTransform: 'uppercase',
		textAlign: 'center',
	},
	tableHeadRow: {
		'& .MuiTableCell-root': {
			fontWeight: 'bold',
			padding: '10px 16px',
		},
	},
	tableHeadCell: {
		textAlign: 'center',
	},
	tableBodyRow: {
		'& .MuiTableCell-root': {
			[breakpoints.down('md')]: {
				padding: '0 8px',
			},
			padding: '0 16px',
		},
	},
	tableBodyCell: {
		textAlign: 'center',
	},
	tableBodyCellClickable: {
		textAlign: 'center',
		'&:hover': {
			'& .MuiBox-root': {
				color: '#FF5225',
				transition: `color .1s`,
			},
		},
		fontWeight: '600',
		cursor: 'pointer',
		color: 'primary',
		[breakpoints.down('md')]: {
			display: 'flex',
			justifyContent: 'end',
			padding: '0',
		}
	},
	gridItem: {
		padding: 0,
		margin: 0,
	},
	detailsRow: {
		marginBottom: '2px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		[breakpoints.down('md')]: {
			'& .MuiTypography-root': {
				paddingRight: '4px',
				fontSize: '12px',
				minWidth: '100px',
			}
    },
	},
	detailsRowBold: {
		fontWeight: 'bold',
	},
	textAlignEndMobile: {
		[breakpoints.down('md')]: {
      textAlign: 'end',
    },
	},
	unbreakingLineMobile: {
		[breakpoints.down('md')]: {
			lineBreak: 'none',
    },
	},
	tableRowError: {
		background: 'rgba(255, 0, 0, 0.1)',
	},
}));

export default useStyles;
