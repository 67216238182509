import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const InputRenderSmall = ({
  input,
  label,
  type,
  placeholder,
  maxLength,
  disabled,
  required,
  min,
  max,
  meta,
  endAdornment,
  variant,
  readOnly,
  autoFocus,
}) => {
  const showError = meta.error && meta.touched;
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <FormControl fullWidth>
      <TextField
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...input}
        label={label}
        variant={variant}
        id={input.name}
        type={type}
        size="small"
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        min={min}
        max={max}
        autoComplete="off"
        error={showError}
        helperText={showError ? meta.error : null}
        autoFocus={autoFocus}
        InputProps={{
          endAdornment,
          readOnly,
          inputProps: {
            maxLength,
          },
        }}
      />
    </FormControl>
  );
};
InputRenderSmall.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  meta: PropTypes.instanceOf(Object).isRequired,
  endAdornment: PropTypes.node,
  variant: PropTypes.string,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

InputRenderSmall.defaultProps = {
  type: 'text',
  placeholder: '',
  maxLength: 200,
  disabled: false,
  required: false,
  min: 0,
  max: 100,
  autoFocus: false,
  readOnly: false,
  variant: 'standard',
  endAdornment: null,
};

export default InputRenderSmall;
