import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  categoryLevelsBox: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    padding: '15px 10px 5px 0',
    flexWrap: 'wrap',

    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },

    [breakpoints.down('md')]: {
      paddingRight: 0,
    },
  },
  categoryLevelsBtn: {
    border: 0,
    background: 'none',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '14px',
    fontFamily: ['Poppins', 'sans serif'],
    cursor: 'pointer',

    '&:hover': {
      color: '#FE5000',
    },
  },
  icon: {
    '&::before': {
      content: "'>'",
      fontSize: 18,
    },
  },
}));

export default useStyles;
