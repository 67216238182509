const PAYMENT_OPTIONS_NITROUS = [
  {
    code: 'PX',
    name: 'Pix',
  },
  {
    code: 'DN',
    name: 'Dinheiro - Pagamento Caixa',
  },
];

export default PAYMENT_OPTIONS_NITROUS;
