import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 8,
      },
    },
    '&::-webkit-scrollbar-track, & ::-webkit-scrollbar-track': {
      backgroundColor: palette.background.level4,
      borderRadius: 12,
    },
    '&::-webkit-scrollbar, & ::-webkit-scrollbar': {
      width: 6,
      height: 6,
      background: palette.background.level4,
    },
    '&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb': {
      background: '#b3b1b1',
      borderRadius: 12,
    },
  },
  img: {
    width: '100%',
    maxWidth: 300,
    margin: 'auto',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: 8,
    marginBottom: 8,
  },
}));

export default useStyles;
