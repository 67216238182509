import React, { useEffect, useState } from 'react';
import { useRange } from 'react-instantsearch';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles, { SliderStyled } from './styles';

const Slider = ({ refinement }, props) => {
  const classes = useStyles();

  const {
    attribute,
  } = refinement;

  const {
    sliderBox,
    sliderSubtitle,
  } = classes;

  const {
    start, range, canRefine, refine,
  } = useRange({ ...props, attribute });
  const { min, max } = range;
  const [discountValue, setDiscountValue] = useState([min, max]);
  const from = Math.max(min, Number.isFinite(start[0]) ? start[0] : min);
  const to = Math.min(max, Number.isFinite(start[1]) ? start[1] : max);

  useEffect(() => {
    setDiscountValue([from, to]);
  }, [from, to]);

  const marks = [
    {
      value: min,
      label: `${min}%`,
    },
    {
      value: max,
      label: `${max}%`,
    },
  ];

  const handleValueLabelFormat = (value) => `${value}%`;

  return (
    <Box className={sliderBox}>
      <span className={sliderSubtitle}>Faixa de desconto:</span>
      <SliderStyled
        min={min}
        max={max}
        value={discountValue}
        onChange={(e, newValue) => setDiscountValue(newValue)}
        onChangeCommitted={() => refine([...discountValue])}
        disabled={!canRefine}
        marks={marks}
        valueLabelFormat={(e) => handleValueLabelFormat(e)}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
    </Box>
  );
};

Slider.propTypes = {
  refinement: PropTypes.instanceOf(Object).isRequired,
};

export default Slider;
