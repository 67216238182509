export default {
  CHECK_LOGIN_REQUEST: '@login/CHECK_LOGIN_REQUEST',
  CHECK_LOGIN_SUCCESS: '@login/CHECK_LOGIN_SUCCESS',
  CHECK_LOGIN_FAILURE: '@login/CHECK_LOGIN_FAILURE',
  SINGIN_REQUEST: '@login/SINGIN_REQUEST',
  SINGIN_SUCCESS: '@login/SINGIN_SUCCESS',
  SINGIN_FAILURE: '@login/SINGIN_FAILURE',
  SET_PAGE: '@login/SET_PAGE',
  LOGOUT: '@login/LOGOUT',
};
