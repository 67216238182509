import { makeStyles } from '@material-ui/core';
import { SearchIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  searchBox: {
    height: 48,
    display: 'flex',
  },
  searchInput: {
    fontFamily: ['Poppins', 'sans-serif'],
    fontSize: 14,
    padding: '8px 16px',
    borderRadius: '8px 0 0 8px',
    border: '1px solid #E3E3E7',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration': {
      '-webkit-appearance': 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    [breakpoints.down('md')]: {
      width: 200,
    },
    [breakpoints.between('lg', 'lg')]: {
      width: 325,
    },
    [breakpoints.up('xl')]: {
      width: 680,
    },
  },
  searchButton: {
    borderRadius: '0 8px 8px 0',
    border: 'none',
    width: 56,
    backgroundColor: '#001A72',
    backgroundImage: `url(${SearchIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 24,
  },
  hitsPopper: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: '#E3E3E7',
    zIndex: 1,
  },
  hitsPaper: {
    height: '100%',
    paddingTop: 80,
    backgroundColor: '#E3E3E7',
    marginLeft: 64,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 25,

    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  searchBoxContainer: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
export default useStyles;
