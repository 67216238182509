export default {
  GET_RELATORIO_PRODUTOS_MC_REQUEST: '@report/GET_RELATORIO_PRODUTOS_MC_REQUEST',
  GET_RELATORIO_PRODUTOS_MC_SUCCESS: '@report/GET_RELATORIO_PRODUTOS_MC_SUCCESS',
  GET_RELATORIO_PRODUTOS_MC_FAILURE: '@report/GET_RELATORIO_PRODUTOS_MC_FAILURE',
  GET_RELATORIO_FECHAMENTO_DE_CAIXA_REQUEST: '@report/GET_RELATORIO_FECHAMENTO_DE_CAIXA_REQUEST',
  GET_RELATORIO_FECHAMENTO_DE_CAIXA_SUCCESS: '@report/GET_RELATORIO_FECHAMENTO_DE_CAIXA_SUCCESS',
  GET_RELATORIO_FECHAMENTO_DE_CAIXA_FAILURE: '@report/GET_RELATORIO_FECHAMENTO_DE_CAIXA_FAILURE',
  GET_RELATORIO_VENDAS_MENSAIS_REQUEST: '@report/GET_RELATORIO_VENDAS_MENSAIS_REQUEST',
  GET_RELATORIO_VENDAS_MENSAIS_SUCCESS: '@report/GET_RELATORIO_VENDAS_MENSAIS_SUCCESS',
  GET_RELATORIO_VENDAS_MENSAIS_FAILURE: '@report/GET_RELATORIO_VENDAS_MENSAIS_FAILURE',
  CHANGE_PAGE: '@recovery/CHANGE_PAGE',
  SET_META: '@recovery/SET_META',
  CLEAR_META: '@recovery/CLEAR_META',
  OPEN_FILTER: '@recovery/OPEN_FILTER',
  CLOSE_FILTER: '@recovery/CLOSE_FILTER',
};
