import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Tooltip,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Done, PriorityHigh } from '@material-ui/icons';
import { useHotkeys } from 'react-hotkeys-hook';

import useStyles from './styles';

const DiscountInfo = ({
  onSubmit, loading, initialValue, hasPayment, discountStatus,
}) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    percentualDesconto: initialValue,
    observacaoSolicitacao: '',
  });
  const valueRef = useRef();
  const discriptionRef = useRef();
  const isInvalid = form.percentualDesconto > 10 && !form.observacaoSolicitacao;

  useEffect(() => {
    setForm({
      percentualDesconto: initialValue,
      observacaoSolicitacao: '',
    });
  }, [initialValue]);

  useHotkeys('shift+w', () => valueRef.current.focus(), [valueRef]);

  const handleChange = ({ target }) => setForm({
    ...form,
    [target.name]: target.value,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(form);
  };

  const handleBlur = () => {
    if (isInvalid) {
      return discriptionRef.current.focus();
    }
    return onSubmit(form);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.formContainer}>
      <Tooltip title="shift + W">
        <TextField
          label="Desconto Total"
          type="number"
          className={classes.fieldValue}
          size="small"
          name="percentualDesconto"
          value={form.percentualDesconto}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          disabled={loading || hasPayment}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                %
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {initialValue > 10.2 && (discountStatus !== 'Desconto aprovado' && discountStatus !== 'Desconto Aprovado') ? (
                  <Tooltip title={discountStatus}>
                    <PriorityHigh />
                  </Tooltip>
                ) : (
                  <Done />
                )}
              </InputAdornment>
            ),
            inputProps: {
              max: 100,
              ref: valueRef,
            },
          }}
        />
      </Tooltip>

      {+form.percentualDesconto > 10 && (
        <>
          <Box pr={2} pb={2} />

          <TextField
            ref={discriptionRef}
            label="Observações"
            disabled={loading || hasPayment}
            fullWidth
            onBlur={handleBlur}
            required
            size="small"
            value={form.observacaoSolicitacao}
            name="observacaoSolicitacao"
            onChange={handleChange}
            helperText={isInvalid && 'Esse campo é obrigatório'}
          />
        </>
      )}

      <Box display="none">
        <input type="submit" />
      </Box>
    </form>
  );
};

DiscountInfo.defaultProps = {
  discountStatus: null,
};

DiscountInfo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValue: PropTypes.string.isRequired,
  discountStatus: PropTypes.string,
  hasPayment: PropTypes.bool.isRequired,
};

export default DiscountInfo;
