import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ItemResumeOrderInfo = ({
  primary, secondary, colorPrimary, colorSecondary, secondaryNoWeight, primaryWeight,
}) => {
  const classes = useStyles({
    colorPrimary,
    colorSecondary,
    secondaryNoWeight,
    primaryWeight,
  });
  return (
    <ListItem className={classes.root} dense>
      <ListItemText
        primary={primary}
        classes={{
          root: classes.primary,
          primary: classes.primaryText,
        }}
      />
      <ListItemText
        classes={{
          root: classes.secondary,
          primary: classes.secondaryText,
        }}
        primary={secondary}
      />
    </ListItem>
  );
};

ItemResumeOrderInfo.defaultProps = {
  colorPrimary: null,
  colorSecondary: null,
  secondaryNoWeight: false,
  primaryWeight: false,
};

ItemResumeOrderInfo.propTypes = {
  primary: PropTypes.node.isRequired,
  secondary: PropTypes.node.isRequired,
  colorPrimary: PropTypes.string,
  colorSecondary: PropTypes.string,
  secondaryNoWeight: PropTypes.bool,
  primaryWeight: PropTypes.bool,
};

export default ItemResumeOrderInfo;
