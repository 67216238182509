import React from 'react';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useStyles from './styles';
import { push } from '../../../../history';
import { getClient } from '../../../services/authClient';

const Cart = ({ client }) => {
  const {
    cartBox,
    cartIcon,
    itemsQty,
  } = useStyles();

  const postCheckout = async () => {
    push(`/checkout/${client ? client.checkoutId : 0}`);
  };

  return (
    <Box className={cartBox}>
      <Button
        disabled={client
          ? !client.checkoutId
          : true}
        onClick={postCheckout}
      >
        {!!(client && client.qtyProducts) && (
          <span className={itemsQty}>
            {client ? client.qtyProducts : 0}
          </span>
        )}
        <span className={cartIcon} />
        <span>Carrinho</span>
      </Button>
    </Box>
  );
};

Cart.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => {
  let { client } = state.authClient;

  client = getClient() || client;

  return ({
    client,
  });
};

export default connect(mapState)(Cart);
