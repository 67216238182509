import React from 'react';
import { Route } from 'react-router-dom';
import DetailPage from './detail/Page';
import CustomSwitch from '../../shared/components/customSwitch';

const OrderRouter = () => (
  <CustomSwitch>
    <Route
      path="/order/:orderId/customer/:customerId/:type"
      component={DetailPage}
      exact
    />
  </CustomSwitch>
);

export default OrderRouter;
