import React from "react";
import * as PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

import { ButtonActions } from "../../../_components";

import useStyles from "./styles";

const AlertModal = ({
  open,
  title,
  message,
  handleClose,
  handleAccept
}) => {
  const {
    actions,
  } = useStyles();

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={actions}>
        <ButtonActions variant="contained" onClick={handleAccept} color="primary">
          Fazer Login
        </ButtonActions>
        <ButtonActions onClick={handleClose} color="primary" autoFocus>
          Continuar cadastro
        </ButtonActions>
      </DialogActions>
    </Dialog>
  )
};

AlertModal.defaultProps = {
  icon: "none"
};

AlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};


export default AlertModal;
