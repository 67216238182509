import React from 'react';
import {
  Card,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';

import useStyles from './styles';

const CardPaper = ({
  children,
  fullHeight,
}) => {
  const {
    paper,
    fullheight,
  } = useStyles();

  return (
    <Card
      elevation={0}
      classes={{ root: paper }}
      className={fullHeight ? fullheight : ''}
    >
      {children}
    </Card>
  );
};

CardPaper.propTypes = {
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
};

export default CardPaper;
