import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import colorStyle from '../../../../../shared/utils/selectBorder';
import {
  getCanalDeVendasFilterAction, putSupervisoresAction,
} from '../../../actions';
import useStyles from '../../../styles';

const DialogNewSupervisor = ({
  handleToggle, canaisDeVendaFilter, unNegocios, getCanalDeVendasFilter, usuarios, page,
  putSupervisores,
}) => {
  const classes = useStyles();
  const [unNegocio, setUnNegocio] = useState('');
  const [canalVendas, setCanalVendas] = useState('');
  const [nomeId, setNomeId] = useState('');
  const [validationMessage] = useState(true);
  const [reloadField, setReloadField] = useState(false);
  const arrayCanalVendas = [];
  canaisDeVendaFilter.forEach((item) => {
    const { idCanalVendas: value, descricao: label } = item;
    arrayCanalVendas.push({ value, label });
  });
  // Alterando valores das variaveis do array para label e value
  const arrayUnNegocios = [];
  unNegocios.forEach((item) => {
    const { idUnidadeNegocio: value, nomeUnidadeNegocio: label } = item;
    arrayUnNegocios.push({ value, label });
  });

  const arrayVendedores = [];
  usuarios.forEach((item) => {
    const { idVendedor: value, nome: label } = item;
    arrayVendedores.push({ value, label });
  });

  useEffect(() => {
    // selectClearRef.current.clearValue();
    setReloadField(!reloadField);
    if (unNegocio !== '') {
      getCanalDeVendasFilter(unNegocio);
    }
  }, [unNegocio]);

  const renderCriarSupervisor = () => {
    handleToggle();
    putSupervisores({
      idCanalDeVendas: canalVendas,
      idVendedor: nomeId,
      page,
      validationMessage,
    });
  };

  return (
    <div className={classes.paper} styles={{ justifyContent: 'center', alignItems: 'center' }}>
      <Box position="relative" style={{ backgroundColor: '#F3F4F6', borderRadius: 4 }}>
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={handleToggle}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogTitle>
          Novo Supervisor
        </DialogTitle>
      </Box>
      <div style={{ padding: 20 }}>
        <Grid item xs={12}>
          <Typography>Usuário</Typography>
          <Select
            styles={colorStyle}
            placeholder="Selecione aqui..."
            options={arrayVendedores}
            onChange={(e) => setNomeId(e.value)}
          />
        </Grid>
        <Grid item container spacing={1} xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Typography>UNIDADE DE NEGÓCIO</Typography>
            <Select
              styles={colorStyle}
              placeholder="Selecione aqui..."
              options={arrayUnNegocios}
              onChange={(e) => setUnNegocio(e.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>CANAL DE VENDAS</Typography>
            <Select
              styles={colorStyle}
              isDisabled={unNegocio === '' && arrayCanalVendas !== []}
              placeholder="Selecione aqui..."
              options={arrayCanalVendas}
              key={reloadField}
              onChange={(e) => setCanalVendas(e.value)}
            />
          </Grid>
        </Grid>
        <div className={classes.checkboxandButton}>
          <Button
            disabled={!!(unNegocio === '' || canalVendas === '' || nomeId === '')}
            className={classes.buttonCriar}
            onClick={renderCriarSupervisor}
          >
            DEFINIR
          </Button>
        </div>
      </div>
    </div>
  );
};

DialogNewSupervisor.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  unNegocios: PropTypes.arrayOf(Object).isRequired,
  canaisDeVendaFilter: PropTypes.arrayOf(Object).isRequired,
  usuarios: PropTypes.arrayOf(Object).isRequired,
  getCanalDeVendasFilter: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  putSupervisores: PropTypes.func.isRequired,
};
const mapState = (state) => state.main.sales;

export default connect(mapState, {
  getCanalDeVendasFilter: getCanalDeVendasFilterAction,
  putSupervisores: putSupervisoresAction,
})(DialogNewSupervisor);
