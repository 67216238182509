import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: {
    fontSize: 14,
    lineHeight: '21px',
    textDecoration: 'none',
    color: '#242424',
    display: 'flex',
    gap: 8,
  },
  countStyled: {
    background: '#F6F6F7',
    borderRadius: 6,
    fontSize: 12,
    lineHeight: '18px',
    color: '#7B7F82',
    padding: '2px 4px',
  },
}));

export default useStyles;
