import React from 'react';
import PropTypes from 'prop-types';
import { toMoney } from '../../../../shared/utils/parsers';
import useStyles from './styles';

const Products = ({ item }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.divProduct} key={item.id}>
        <div className={classes.divProductImg}>
          <div className={classes.borderImage}>
            <img
              src={`https://img.lojadomecanico.com.br/IMAGENS/${item.imagem}`}
              alt=""
              className={classes.img}
            />
          </div>
          <div className={classes.divInfoRightImage}>
            <div className={classes.flagCodPedido}>
              <span>
                Prod.:
                {item.codProduto}
              </span>
            </div>
            <span className={classes.textDescricao}>
              {item.descricao}
            </span>
            <div className={classes.divFabricante}>
              <span className={classes.textFabricante}>{item.fabricante}</span>
              <span>
                <span className={classes.textWeight600}>
                  {item.qtdGpoints}
                  {' '}
                </span>
                <span>G-points.</span>
              </span>
              <span>
                <span>Entrega: </span>
                <span className={classes.textWeight600}>{item.entrega}</span>
              </span>
            </div>
          </div>
        </div>
        <span>
          <span>Unidade(s): </span>
          <span className={classes.textWeight600}>{item.qtde}</span>
        </span>
        <span>
          <span>FRETE: </span>
          <span className={classes.textWeight600}>{toMoney(item.frete)}</span>
        </span>
        <span>
          <span>Vlr. Unitário: </span>
          <span className={classes.textWeight600}>{toMoney(item.vlrUnitario)}</span>
        </span>
        <span>
          <span>Vlr. Total: </span>
          <span className={classes.textWeight600}>{toMoney(item.vlrTotal)}</span>
        </span>
      </div>
    </>
  );
};
Products.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default Products;
