import React from 'react';
import { AccordionSummary, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minHeight: '48px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  content: {
    margin: '12px 0 !important',
  },
});

const FlatExpansionPanelSummary = (props) => {
  const classes = useStyles();

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AccordionSummary {...props} classes={classes} />;
};

export default FlatExpansionPanelSummary;
