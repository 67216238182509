import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableContainer,
} from '@material-ui/core';

import useStyles from './styles';

const ResponsiveTable = ({
  children,
  className,
  striped,
  forceResponsive,
  pointer,
}) => {
  const classes = useStyles({ striped, forceResponsive, pointer });

  return (
    <TableContainer>
      <Table
        className={`${classes.root} ${className}`}
      >
        {children}
      </Table>
    </TableContainer>
  );
};

ResponsiveTable.defaultProps = {
  children: null,
  className: '',
  striped: false,
  pointer: false,
  forceResponsive: false,
};

ResponsiveTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  striped: PropTypes.bool,
  pointer: PropTypes.bool,
  forceResponsive: PropTypes.bool,
};

export default ResponsiveTable;
