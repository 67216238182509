import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Typography,
  Paper,
  Button,
  IconButton,
  Modal,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import editIcon from '../../../../../assets/edit.svg';
import CardBarSale from '../../../../../shared/components/cardBarSale';
import ResponsiveTable from '../../../../../shared/components/responsiveTable';
import DialogNewSupervisor from './DialogNewSupervisor';
import DialogRefreshSupervisor from './DialogRefreshSupervisor';
import {
  getSupervisoresAction, getVendedoresAtivosAction, getUnNegociosAction, getCanalDeVendasAction,
  getDetailSupervisoresAction,
} from '../../../actions';
import useStyles from '../../../styles';

const Supervisor = ({
  loading, supervisores, getSupervisores, totalpagesSupervisor, getCanalDeVendas,
  getVendedoresAtivos, getUnNegocios, getDetailSupervisores,
}) => {
  const [dialog, setDialog] = useState(false);
  const [dialogRefresh, setDialogRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSelects] = useState(null);
  const handleToggle = () => setDialog(!dialog);
  const handleToggleRefresh = () => setDialogRefresh(!dialogRefresh);
  const classes = useStyles();

  useEffect(() => {
    getSupervisores({ page });
    getCanalDeVendas({ page: pageSelects });
    getUnNegocios({ page: pageSelects });
    getVendedoresAtivos({ page: pageSelects });
  }, [page]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleDetails = (idVendedor, idCanalVendas) => {
    handleToggleRefresh();
    getDetailSupervisores({ idVendedor, idCanalVendas });
  };

  return (
    <div className={classes.divRow}>
      <div style={{ width: '100%', minHeight: '80vh' }}>
        <CardBarSale title="Home" />
        {loading && (<LinearProgress />)}
        <div className={classes.titleAndButtonSupervisor}>
          <span>Supervisor</span>
          <Button
            className={classes.buttonTitle}
            onClick={handleToggle}
          >
            DEFINIR SUPERVISOR
          </Button>
        </div>
        <section className={classes.sessionTable}>
          <Paper elevation={1} style={{ width: '85%' }}>
            <ResponsiveTable pointer>
              <TableHead style={{ backgroundColor: '#F9F9F9' }}>
                <TableRow>
                  <TableCell component="th" align="left">
                    NOME
                  </TableCell>
                  <TableCell component="th">
                    UNIDADE DE NEGÔCIO
                  </TableCell>
                  <TableCell component="th">
                    CANAL DE VENDAS
                  </TableCell>
                  <TableCell component="th">
                    AÇÃO
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {supervisores.map((item) => (
                  <TableRow
                    key={item.idVendedor}
                    hover
                  >
                    <TableCell datatype="Nome" align="left">
                      {item.nomeSupervisor}
                    </TableCell>

                    <TableCell datatype="UN.Negôcio" style={{ color: '#888E9D' }}>
                      {item.unidadeDeNegocio}
                    </TableCell>
                    <TableCell datatype="Canal Vendas" style={{ color: '#888E9D' }}>
                      {item.descricaoCanalVendas}
                    </TableCell>

                    <TableCell datatype="Ação">
                      <IconButton onClick={() => handleDetails(
                        item.idVendedor, item.idCanalVendas,
                      )}
                      >
                        <img src={editIcon} alt="edit" className={classes.boxEdit} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

                {!supervisores.length && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Typography variant="body2">
                      {loading ? 'Buscando lista de supervisores...' : 'Nenhum Supervisor Encontrado'}
                    </Typography>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </ResponsiveTable>
          </Paper>
        </section>

        {dialog
       && (
       <Modal
         open={dialog}
         onClose={handleToggle}
         className={classes.modal}
       >
         <DialogNewSupervisor
           dialog={dialog}
           handleToggle={handleToggle}
           page={page}
         />
       </Modal>
       )}
        {dialogRefresh
       && (
       <Modal
         open={dialogRefresh}
         onClose={handleToggleRefresh}
         className={classes.modal}
       >
         <DialogRefreshSupervisor
           dialog={dialogRefresh}
           handleToggle={handleToggleRefresh}
           page={page}
         />
       </Modal>
       )}
      </div>
      <div style={{
        display: 'flex', justifyContent: 'flex-end', marginBottom: 30, marginRight: 90,
      }}
      >
        <Pagination
          count={totalpagesSupervisor}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

Supervisor.propTypes = {
  loading: PropTypes.bool.isRequired,
  supervisores: PropTypes.arrayOf(Object).isRequired,
  // checkoutMeta: PropTypes.instanceOf(Object).isRequired,
  // clearMeta: PropTypes.func.isRequired,
  getSupervisores: PropTypes.func.isRequired,
  getCanalDeVendas: PropTypes.func.isRequired,
  getVendedoresAtivos: PropTypes.func.isRequired,
  getUnNegocios: PropTypes.func.isRequired,
  totalpagesSupervisor: PropTypes.number.isRequired,
  getDetailSupervisores: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.sales;

export default connect(mapState, {
  getVendedoresAtivos: getVendedoresAtivosAction,
  getSupervisores: getSupervisoresAction,
  getCanalDeVendas: getCanalDeVendasAction,
  getUnNegocios: getUnNegociosAction,
  getDetailSupervisores: getDetailSupervisoresAction,
})(Supervisor);
