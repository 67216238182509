/* eslint-disable quote-props */
/* eslint-disable no-useless-computed-key */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: '100%',
  },
  appBar: {
    width: '100%',
    padding: '16px 15px',
    color: '#888E9D',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette.background.level1,
    [breakpoints.up('sm')]: {
      height: 56,
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
    width: 200,
    flexWrap: 'wrap',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    whiteSpace: 'nowrap',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    [breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  buttonAgendamentos: {
    backgroundColor: '#FF5225',
    color: '#ffff',
    height: 30,
    marginRight: 30,
  },

  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#888E9D',
    marginLeft: 10,
    marginRight: 10,
  },
  linkPress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FF5225',
    marginLeft: 10,
    marginRight: 10,
  },
  icon: {
    marginRight: 3,
    fontSize: 16,
  },
  spanLink: {
    marginTop: 2,
    fontSize: 14,
  },
}));

export default useStyles;
