import { handleActions, combineActions } from 'redux-actions';
import types from './types';
import typesPix from './advanced/types';

const INITIAL_STATE = {
  loading: false,
  loadingConditions: false,
  awaitPix: false,
  typePayment: null,
  tradePaymentPix: '',
  paymentMethod: null,
  conditions: null,
  dialogCouponOpened: false,
  dialogDiscountOpened: false,
  dialogCreditCardQrCodeOpened: false,
  resendLink: false,
  dialogManualPaymentOpened: false,
  cashierPayment: null,
  isLoadingInstallment: false,
  listInstallments: [],
};

const startLoading = combineActions(
  types.SAVE_PAYMENT_REQUEST,
  types.REMOVE_PAYMENT_REQUEST,
  types.SAVE_PAYMENT_PIX_REQUEST,
);
const startPix = combineActions(
  types.SAVE_PAYMENT_PIX_REQUEST,
  types.SAVE_PAYMENT_PIX_SUCCESS,
);

const stopLoading = combineActions(
  types.SAVE_PAYMENT_SUCCESS,
  types.REMOVE_PAYMENT_SUCCESS,
  types.SAVE_PAYMENT_FAIL,
  types.REMOVE_PAYMENT_FAIL,
  types.GET_CONDITIONS_FAIL,
  types.GET_CONDITIONS_SUCCESS,
  types.SAVE_PAYMENT_PIX_SUCCESS,
  types.SAVE_PAYMENT_PIX_FAIL,
);

const stopConditionsLoading = combineActions(
  types.GET_CONDITIONS_FAIL,
  types.GET_CONDITIONS_SUCCESS,
);

const payment = handleActions({
  [typesPix.CLOSE_DIALOG]: (state) => ({
    ...state,
    dialogOpened: false,
    typePayment: null,
    awaitPix: false,
  }),
  [startLoading]: (state) => ({
    ...state,
    loading: true,
  }),
  [stopLoading]: (state) => ({
    ...state,
    loading: false,
    awaitPix: false,
  }),
  [startPix]: (state) => ({
    ...state,
    awaitPix: true,
  }),
  [types.SAVE_PAYMENT_PIX_FAIL]: (state) => ({
    ...state,
    awaitPix: false,
    typePayment: null,
  }),
  [types.REMOVE_PAYMENT_REQUEST]: (state) => ({
    ...state,
    awaitPix: false,
  }),
  [types.RESET_CHECKOUT]: (state) => ({
    ...state,
    paymentMethod: null,
  }),
  [types.SET_PAYMENT]: (state, { payload }) => ({
    ...state,
    paymentMethod: payload.paymentMethod,
  }),
  [types.GET_CONDITIONS_REQUEST]: (state) => ({
    ...state,
    loadingConditions: true,
  }),
  [stopConditionsLoading]: (state) => ({
    ...state,
    loadingConditions: false,
  }),
  [types.GET_CONDITIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [types.OPEN_DIALOG_CREDIT_CARD_QRCODE]: (state) => ({
    ...state,
    dialogCreditCardQrCodeOpened: true,
  }),
  [types.CLOSE_DIALOG_CREDIT_CARD_QRCODE]: (state) => ({
    ...state,
    dialogCreditCardQrCodeOpened: false,
  }),
  [types.OPEN_RESEND_LINK]: (state) => ({
    ...state,
    resendLink: true,
  }),
  [types.CLOSE_RESEND_LINK]: (state) => ({
    ...state,
    resendLink: false,
  }),
  [types.OPEN_MANUAL_PAYMENT]: (state) => ({
    ...state,
    dialogManualPaymentOpened: true,
  }),
  [types.CLOSE_MANUAL_PAYMENT]: (state) => ({
    ...state,
    dialogManualPaymentOpened: false,
  }),
  [types.SET_CASHIER_PAYMENT]: (state, { payload }) => ({
    ...state,
    cashierPayment: payload.payment,
  }),
  [types.CLEAR_CASHIER_PAYMENT]: (state) => ({
    ...state,
    cashierPayment: null,
  }),
  [types.SEARCH_INSTALLMENTS_REQUEST]: (state) => ({
    ...state,
    isLoadingInstallment: true,
  }),
  [types.SEARCH_INSTALLMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingInstallment: false,
    listInstallments: payload.listInstallments,
  }),
  [types.SEARCH_INSTALLMENTS_FAIL]: (state) => ({
    ...state,
    isLoadingInstallment: false,
    listInstallments: [],
  }),
}, INITIAL_STATE);

export default payment;
