import { Box } from '@material-ui/core';
import {
  instanceOf, number, oneOfType, string,
} from 'prop-types';
import React from 'react';

const Selectable = ({ children }) => (
  <Box
    component="span"
    style={{ cursor: 'text' }}
    onClick={(e) => e.stopPropagation()}
  >
    {children}
  </Box>
);

Selectable.propTypes = {
  children: oneOfType([instanceOf(Object), string, number]).isRequired,
};

export default Selectable;
