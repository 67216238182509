import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/pt-br';
import clsx from 'clsx';
import { useTheme, useMediaQuery } from '@material-ui/core';
import useStyles from './styles';

moment.locale('pt-br');
const DatePicker = ({
  value,
  onChange,
  disableToolbar,
  variant,
  format,
  margin,
  readOnly,
  id,
  label,
  className,
  keyboardButtonProps,
  disableFuture,
  meta: {
    error, touched,
  },
  disabled,
}) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale="pt-br"
    >
      <KeyboardDatePicker
        disableToolbar={disableToolbar}
        variant={!isMobile ? variant : 'dialog'}
        format={format}
        margin={margin}
        id={id}
        readOnly={readOnly}
        label={label}
        value={value || null}
        KeyboardButtonProps={keyboardButtonProps}
        onChange={(date) => onChange(date ? date.toISOString() : null)}
        disabled={disabled}
        className={clsx(className, {
          [classes.root]: true,
          [classes.error]: touched && error,
        })}
        helperText={touched && error}
        error={touched && !!error}
        disableFuture={disableFuture}
        views={['date']}
        openTo="year"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isMobile ? {
          cancelLabel: 'Cancelar',
          clearLabel: 'Limpar',
          todayLabel: 'Hoje',
        } : {})}
      />
    </MuiPickersUtilsProvider>

  );
};

DatePicker.defaultProps = {
  value: null,
  onChange: () => {},
  disableToolbar: true,
  disabled: false,
  disableFuture: false,
  readOnly: false,
  variant: 'inline',
  format: 'DD/MM/YYYY',
  margin: 'none',
  id: null,
  label: '',
  keyboardButtonProps: {
    'aria-label': 'alterar data',
  },
  className: '',
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.any,
  ]),
  onChange: PropTypes.func,
  disableToolbar: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  variant: PropTypes.string,
  format: PropTypes.string,
  margin: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  keyboardButtonProps: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default DatePicker;
