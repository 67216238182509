import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  subheaderGrid: {
    maxHeight: 48,
    background: '#F6F6F7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    right: 0,
    width: '100%',
    zIndex: 1,
    position: 'fixed',
    padding: '12px 24px 12px 84px',

    '& .MuiButton-root': {
      height: 36,
    },

    '& .MuiButton-label': {
      lineHeight: '14px',
      textTransform: 'math-auto',
    },

    [breakpoints.down('sm')]: {
      display: 'none',
    },

    [breakpoints.between('sm', 'md')]: {
      '& button': {
        '&:first-child': {
          width: 'auto',
        },

        '&:last-child': {
          width: 'auto',
        },
      },

      '& .MuiButton-root': {
        width: 40,
        minWidth: 40,
      },

      '& .MuiButton-label': {
        fontSize: '8px !important',
      },
    },

    [breakpoints.between('md', 'lg')]: {
      '& .MuiButton-label': {
        fontSize: 13,
      },
    },
  },
  pplBtn: {
    width: 120,
    height: 32,
    borderRadius: 8,
    background: '#FE5000',
    color: '#FFFFFF',

    '&:hover': {
      background: '#B23919',
    },
  },
  categoryBtn: {
    color: '#FE5000',
    gap: 6,

    '&:after': {
      content: '""',
      minWidth: 8,
      height: 8,
      borderTop: '2px solid',
      borderRight: '2px solid',
      transform: 'rotate(135deg)',
      marginLeft: 16,
    },
  },
}));

export default useStyles;
