import types from './types';
import resolveError from '../../shared/utils/resolveError';
import {
  getVendedoresAtivos, postVendedores, getDetailsVendedor,
  putStatusVendedores, getVendedoresInativos,
  putVendedores, getCanalDeVenda, getUnNegocios, getCanalDeVendaFiltro,
  getCargos, postCargos, putCargos, getCargoFilter, getSupervisores,
  putSupervisor, getSupervisorDetails, getPermissoes, getParametrizacao,
  getDetailsParametrizacao, postParametrizacao, putParametrizacao, getVendedoresAtivosFiltro, getVendedoresInativosFiltro,
  getNiveis, postCanalDeVendas, getEstabelecimentos
} from './repository';

// FUNÇÕES VENDEDORES
export const getVendedoresAtivosAction = ({ page }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_VENDEDORES_REQUEST });
  try {
    const { results, totalpages } = await getVendedoresAtivos({ page });
    dispatch({
      type: types.GET_VENDEDORES_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_VENDEDORES_FAILURE });
  }
};
export const getVendedoresAtivosFiltroAction = ({ page, docCliente, unNegocio, canalVendas, cargo, nivel }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_VENDEDORES_REQUEST });
  try {
    const { results, totalpages } = await getVendedoresAtivosFiltro({ page, docCliente, unNegocio, canalVendas, cargo, nivel });
    dispatch({
      type: types.GET_VENDEDORES_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_VENDEDORES_FAILURE });
  }
};
export const getVendedoresInativosFiltroAction = ({ page, docCliente, unNegocio, canalVendas, cargo, nivel }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_VENDEDORES_INATIVOS_REQUEST });
  try {
    const { results, totalpages } = await getVendedoresInativosFiltro({ page, docCliente, unNegocio, canalVendas, cargo, nivel });
    dispatch({
      type: types.GET_VENDEDORES_INATIVOS_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_VENDEDORES_INATIVOS_FAILURE });
  }
};
export const getVendedoresInativosAction = ({ page }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_VENDEDORES_INATIVOS_REQUEST });
  try {
    const { results, totalpages } = await getVendedoresInativos({ page });
    dispatch({
      type: types.GET_VENDEDORES_INATIVOS_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_VENDEDORES_INATIVOS_FAILURE });
  }
};
export const getDetailVendedoresAction = ({ idVendedor }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_DETAILS_VENDEDORES_REQUEST });
  try {
    const { results } = await getDetailsVendedor({ idVendedor });
    dispatch({
      type: types.GET_DETAILS_VENDEDORES_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_DETAILS_VENDEDORES_FAILURE });
  }
};

export const postVendedoresAction = ({
  nomeVendedor, login, nivelPermissao, idVendedorERP, ativo, vendedorLoja3, qtdBoletosNPagos,
  abandonoCarrinho, nomeExibicao, emailVendedor, idUnidadeDeNegocio, idCanalDeVendas,
  idCargo, permissoesTela, page, senha, ramal,
}) => async (dispatch, getState) => {
  dispatch({ type: types.POST_VENDEDORES_REQUEST });
  const { snack } = getState().main.app;
  const { user } = getState().auth.login;
  try {
    await postVendedores({
      nomeVendedor,
      login,
      nivelPermissao,
      idVendedorERP,
      ativo,
      vendedorLoja3,
      qtdBoletosNPagos,
      abandonoCarrinho,
      nomeExibicao,
      emailVendedor,
      idUnidadeDeNegocio,
      idCanalDeVendas,
      idCargo,
      permissoesTela,
      senha,
      ramal,
      user,
    });

    const { results, totalpages } = await getVendedoresAtivos({ page });
    dispatch({
      type: types.POST_VENDEDORES_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
    snack.enqueueSnackbar('Vendedor Inserido com sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.POST_VENDEDORES_FAILURE });
  }
};

export const putStatusVendedoresAction = ({
  idVendedores, status,
  page,
}) => async (dispatch, getState) => {
  dispatch({ type: types.PUT_VENDEDORES_REQUEST });
  const { snack } = getState().main.app;
  try {
    await putStatusVendedores({ idVendedores, status });

    if (status === 0) {
      const { results, totalpages } = await getVendedoresAtivos({ page });
      dispatch({
        type: types.PUT_VENDEDORES_SUCCESS,
        payload: {
          results,
          totalpages,
        },
      });
      snack.enqueueSnackbar('Usuário Inativado com Sucesso!', { variant: 'success' });
    } else if (status === 1) {
      const { results, totalpages } = await getVendedoresInativos({ page });
      dispatch({
        type: types.PUT_VENDEDORES_SUCCESS,
        payload: {
          results,
          totalpages,
        },
      });
      snack.enqueueSnackbar('Usuário Ativado com Sucesso!', { variant: 'success' });
    }
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.PUT_VENDEDORES_FAILURE });
  }
};

export const putVendedoresAction = ({
  idVendedor, nomeVendedor, nomeExibicao, idVendedorERP, emailVendedor, login,
  ativo, vendedorLoja3, abandonoCarrinho, idUnidadeDeNegocio, idCanalDeVendas,
  idCargo, idNivel, permissoesTela, senha, ramal, page,
}) => async (dispatch, getState) => {
  dispatch({ type: types.PUT_VENDEDORES_REQUEST });
  const { snack } = getState().main.app;
  const { user } = getState().auth.login;
  try {
    await putVendedores({
      idVendedor,
      nomeVendedor,
      nomeExibicao,
      idVendedorERP,
      emailVendedor,
      login,
      ativo,
      vendedorLoja3,
      abandonoCarrinho,
      idUnidadeDeNegocio,
      idCanalDeVendas,
      idCargo,
      idNivel,
      permissoesTela,
      senha,
      ramal,
      user,
    });
    const { results, totalpages } = await getVendedoresAtivos({ page });
    dispatch({
      type: types.PUT_VENDEDORES_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
    snack.enqueueSnackbar('Usuário Atualizado com Sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.PUT_VENDEDORES_FAILURE });
  }
};
//---------------------------------------------------------------------

// FUNÇÕES CANAL DE VENDAS
export const getCanalDeVendasAction = ({ page }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_CANAL_DE_VENDAS_REQUEST });
  try {
    const { results, totalpages } = await getCanalDeVenda({ page });
    dispatch({
      type: types.GET_CANAL_DE_VENDAS_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CANAL_DE_VENDAS_FAILURE });
  }
};

export const getCanalDeVendasFilterAction = (unNegocio) => async (dispatch, getState) => {
  dispatch({ type: types.GET_CANAL_DE_VENDAS_REQUEST });
  try {
    const { results } = await getCanalDeVendaFiltro(unNegocio);
    dispatch({
      type: types.GET_CANAL_DE_VENDAS_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CANAL_DE_VENDAS_FAILURE });
  }
};

export const postCanalDeVendasAction = ({
  descricao, idBseller, usuarioAlteracao, idUnidadeDeNegocio, filial, idSupervisor, page,
}) => async (dispatch, getState) => {
  dispatch({ type: types.POST_CANAL_DE_VENDAS_REQUEST });
  const { snack } = getState().main.app;
  const { user } = getState().auth.login;
  try {
    await postCanalDeVendas({ descricao, idBseller, usuarioAlteracao, idUnidadeDeNegocio, filial, idSupervisor });

    const { results, totalpages } = await getCanalDeVenda({ page });
    dispatch({
      type: types.GET_CANAL_DE_VENDAS_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
    snack.enqueueSnackbar('Canal de vendas salvo com Sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CANAL_DE_VENDAS_FAILURE });
  }
};
//---------------------------------------------------------------------

// FUNÇÕES UNIDADE DE NEGOCIO
export const getUnNegociosAction = ({ page }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_UNIDADE_DE_NEGOCIO_REQUEST });
  try {
    const { results, totalpages } = await getUnNegocios({ page });
    dispatch({
      type: types.GET_UNIDADE_DE_NEGOCIO_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_UNIDADE_DE_NEGOCIO_FAILURE });
  }
};

// FUNÇÕES FILIAIS SEM CANAIS DE VENDA ATRIBUIDOS
export const getEstabelecimentosAction = ({ page }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_ESTABELECIMENTOS_REQUEST });
  try {
    const { results, totalpages } = await getEstabelecimentos({ page });
    dispatch({
      type: types.GET_ESTABELECIMENTOS_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_ESTABELECIMENTOS_FAILURE });
  }
};



//---------------------------------------------------------------------

// FUNÇÕES CARGOS
export const getCargosAction = ({ page }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_CARGOS_REQUEST });
  try {
    const { results, totalpages } = await getCargos({ page });
    dispatch({
      type: types.GET_CARGOS_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CARGOS_FAILURE });
  }
};

export const getCargoFilterAction = ({ id }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_CARGOS_FILTER_REQUEST });
  try {
    const { results } = await getCargoFilter({ id });
    dispatch({
      type: types.GET_CARGOS_FILTER_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CARGOS_FILTER_FAILURE });
  }
};

export const postCargosAction = ({ nome, page }) => async (dispatch, getState) => {
  dispatch({ type: types.POST_CARGOS_REQUEST });
  const { snack } = getState().main.app;
  try {
    await postCargos({ nome });
    const { results, totalpages } = await getCargos({ page });
    dispatch({
      type: types.POST_CARGOS_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
    snack.enqueueSnackbar('Cargo Inserido com sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.POST_CARGOS_FAILURE });
  }
};

export const putCargosAction = ({
  id, nome, page,
}) => async (dispatch, getState) => {
  dispatch({ type: types.PUT_CARGOS_REQUEST });
  const { snack } = getState().main.app;
  try {
    await putCargos({ id, nome });

    const { results, totalpages } = await getCargos({ page });
    dispatch({
      type: types.PUT_CARGOS_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
    snack.enqueueSnackbar('Cargo Atualizado com Sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.PUT_CARGOS_FAILURE });
  }
};
//---------------------------------------------------------------------

// FUNÇÕES PERMISSOES
export const getPermissoesAction = ({ page }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_PERMISSOES_REQUEST });
  try {
    const results = await getPermissoes({ page });
    dispatch({
      type: types.GET_PERMISSOES_SUCCESS,
      payload:
        results.data,
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_PERMISSOES_FAILURE });
  }
};

//---------------------------------------------------------------------

// FUNÇÕES NÍVEIS
export const getNiveisAction = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_NIVEIS_REQUEST });
  try {
    const { results } = await getNiveis();
    dispatch({
      type: types.GET_NIVEIS_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_NIVEIS_FAILURE });
  }
};

//---------------------------------------------------------------------

// FUNÇÕES SUPERVISORES
export const putSupervisoresAction = ({
  idCanalDeVendas, idVendedor, page, validationMessage,
}) => async (dispatch, getState) => {
  dispatch({ type: types.PUT_SUPERVISORES_REQUEST });
  const { snack } = getState().main.app;
  const { user } = getState().auth.login;
  try {
    await putSupervisor({ idCanalDeVendas, idVendedor, user });

    const { results, totalpages } = await getSupervisores({ page });
    dispatch({
      type: types.PUT_SUPERVISORES_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
    if (validationMessage) {
      snack.enqueueSnackbar('Supervisor Definido com Sucesso!', { variant: 'success' });
    } else {
      snack.enqueueSnackbar('Supervisor Atualizado com Sucesso!', { variant: 'success' });
    }
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.PUT_SUPERVISORES_FAILURE });
  }
};

export const getSupervisoresAction = ({ page }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_SUPERVISORES_REQUEST });
  try {
    const { results, totalpages } = await getSupervisores({ page });
    dispatch({
      type: types.GET_SUPERVISORES_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_SUPERVISORES_FAILURE });
  }
};
export const getDetailSupervisoresAction = ({
  idVendedor,
  idCanalVendas,
}) => async (dispatch, getState) => {
  dispatch({ type: types.GET_DETAILS_SUPERVISORES_REQUEST });
  try {
    const { results } = await getSupervisorDetails({ idVendedor, idCanalVendas });
    dispatch({
      type: types.GET_DETAILS_SUPERVISORES_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_DETAILS_SUPERVISORES_FAILURE });
  }
};

// FUNÇÕES PARAMETRIZAÇÃO
export const getParametrizacaoAction = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_PARAMETRIZACAO_REQUEST });
  try {
    const { results } = await getParametrizacao();
    dispatch({
      type: types.GET_PARAMETRIZACAO_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_PARAMETRIZACAO_FAILURE });
  }
};

export const getDetailParametrizacaoAction = ({ id }) => async (dispatch, getState) => {
  dispatch({ type: types.GET_DETAILS_PARAMETRIZACAO_REQUEST });
  try {
    const { results } = await getDetailsParametrizacao({ id });
    dispatch({
      type: types.GET_DETAILS_PARAMETRIZACAO_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_DETAILS_PARAMETRIZACAO_FAILURE });
  }
};

export const postParametrizacaoAction = ({
  nomeCategoria, numPedidosRefeitos, vlrMinimoVenda,
  taxaConversao, taxaDeclinio, taxaComissao,
  periodoBuscaMaxEmDias, vlrMaximoPedidos,
}) => async (dispatch, getState) => {
  dispatch({ type: types.POST_PARAMETRIZACAO_REQUEST });
  const { snack } = getState().main.app;
  try {
    await postParametrizacao({
      nomeCategoria,
      numPedidosRefeitos,
      vlrMinimoVenda,
      taxaConversao,
      taxaDeclinio,
      taxaComissao,
      periodoBuscaMaxEmDias,
      vlrMaximoPedidos,
    });

    const { results, totalpages } = await getParametrizacao();
    dispatch({
      type: types.POST_PARAMETRIZACAO_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
    snack.enqueueSnackbar('Parametrização Inserida com sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.POST_PARAMETRIZACAO_FAILURE });
  }
};

export const putParametrizacaoAction = ({
  id, nomeCategoria, numPedidosRefeitos, vlrMinimoVenda,
  taxaConversao, taxaDeclinio, taxaComissao,
  periodoBuscaMaxEmDias, vlrMaximoPedidos,
}) => async (dispatch, getState) => {
  dispatch({ type: types.PUT_PARAMETRIZACAO_REQUEST });
  const { snack } = getState().main.app;
  try {
    await putParametrizacao({
      id,
      nomeCategoria,
      numPedidosRefeitos,
      vlrMinimoVenda,
      taxaConversao,
      taxaDeclinio,
      taxaComissao,
      periodoBuscaMaxEmDias,
      vlrMaximoPedidos,
    });
    const { results, totalpages } = await getParametrizacao();
    dispatch({
      type: types.PUT_PARAMETRIZACAO_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
    snack.enqueueSnackbar('Parametrização Atualizada com Sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.PUT_PARAMETRIZACAO_FAILURE });
  }
};
//---------------------------------------------------------------------
