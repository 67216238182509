import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHierarchicalMenu } from 'react-instantsearch';
import { connect } from 'react-redux';
import useStyles from './styles';
import { resetSearchAction, searchByCategoryAction } from '../../../../main/checkout/actions';

const CategoryLevels = ({
  categoryLevels,
  setOpen,
  resetSearch,
  searchByCategory,
  categoryList,
}) => {
  const classes = useStyles();
  const [mainLevels, setMainLevels] = useState([]);
  const [levelsArray, setLevelsArray] = useState([]);
  const [newLevelsArray, setNewLevelsArray] = useState([]);

  useHierarchicalMenu({
    attributes: ['categoryLevels.lvl0', 'categoryLevels.lvl1', 'categoryLevels.lvl2'],
    sortBy: ['isRefined:desc'],
  });

  const {
    categoryLevelsBox,
    categoryLevelsBtn,
    icon,
  } = classes;

  useEffect(() => {
    if (categoryLevels.length) {
      setMainLevels(categoryLevels.slice(-1)[0]);
    }
  }, []);

  useEffect(() => {
    if (mainLevels.length) {
      const array = mainLevels.split(/\s>\s/g);
      array.map((item, i) => {
        setLevelsArray((prevState) => [
          ...prevState,
          {
            name: item,
            value: categoryLevels[i],
            type: i + 1,
            detail: true,
          },
        ]);
      });
    }
  }, [mainLevels]);

  useEffect(() => {
    if (levelsArray.length && categoryList) {
      const isSame = levelsArray.some((level) => level.value.match(`${categoryList.name}`));

      const newArray = [];

      if (isSame) {
        switch (categoryList.type) {
          case 1:
            // eslint-disable-next-line array-callback-return
            levelsArray.map((level) => {
              const newValue = level.type === 1 ? '' : level.value;
              newArray.push({
                ...level,
                value: newValue,
              });
            });
            break;
          case 2:
            // eslint-disable-next-line array-callback-return
            levelsArray.map((level) => {
              let newValue = '';

              if (level.type === 1) {
                newValue = levelsArray[1].value;
              } else if (level.type === 3) {
                newValue = level.value;
              }
              newArray.push({
                ...level,
                value: newValue,
              });
            });
            break;
          default:
            // eslint-disable-next-line array-callback-return
            levelsArray.map((level) => {
              let newValue = '';

              if (level.type === 1) {
                newValue = levelsArray[1].value;
              } else if (level.type === 2) {
                newValue = levelsArray[2].value;
              }
              newArray.push({
                ...level,
                value: newValue,
              });
            });
            break;
        }
      }

      setNewLevelsArray(isSame ? newArray : levelsArray);
    } else setNewLevelsArray(levelsArray);
  }, [levelsArray]);

  const filterProducts = async (item) => {
    await resetSearch(true, ' ');
    await searchByCategory(item.value, item);
    await setOpen(false);
  };

  return (
    <Box className={categoryLevelsBox}>
      {
        newLevelsArray.map((item, i) => (
          <Fragment key={item.name}>
            <button
              type="button"
              className={categoryLevelsBtn}
              onClick={() => filterProducts(item, i)}
            >
              {item.name}
            </button>
            {newLevelsArray.length
              && ((i !== (newLevelsArray.length - 1)) && (<span className={icon} />))}
          </Fragment>
        ))
       }
    </Box>
  );
};

CategoryLevels.propTypes = {
  categoryLevels: PropTypes.instanceOf(Array).isRequired,
  setOpen: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchByCategory: PropTypes.func.isRequired,
  categoryList: PropTypes.instanceOf(Array).isRequired,
};

const mapState = (state) => {
  const {
    categoryHierarchy,
  } = state.main.checkout.geral;

  return ({
    categoryList: categoryHierarchy,
  });
};

export default connect(mapState, {
  resetSearch: resetSearchAction,
  searchByCategory: searchByCategoryAction,
})(CategoryLevels);
