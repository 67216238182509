import React from 'react';
import { Box, Link } from '@material-ui/core';
import useStyles, { BreadcrumbsStyled as Breadcrumbs } from './styles';

const Breadcrumb = () => {
  const classes = useStyles();
  const {
    breadcrumbsBox,
    separator,
  } = classes;

  const options = [
    {
      title: 'Estoque',
      nameId: 'stockTotal',
    },
    {
      title: 'Detalhes do Produto',
      nameId: 'productDetails',
    },
  ];

  return (
    <Box className={breadcrumbsBox}>
      <div className={`${separator} initial`} />
      <Breadcrumbs
        separator={<div className={separator} />}
      >
        {
          options.map(({ title, nameId }) => (
            <Link key={title} href={`#${nameId}`}>
              {title}
            </Link>
          ))
        }
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
