const discountPermition = (userId) => [
  122,
  126,
  130,
  161,
  247,
  283,
  324,
  450,
  658,
  1159,
  1416,
  214,
  1238,
  287,
  961,
  1381,
  1291,
  378,
  1100,
  1101,
].includes(userId);
export default discountPermition;
