import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  clientCell: {
    textTransform: 'uppercase',
    [breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: 300,
      textOverflow: 'ellipsis',
    },
  },
  paginationContainer: {},
  buttonDetails: {
    backgroundColor: '#F3F4F6',
    height: 24,
    width: 90,
    fontWeight: 600,
    color: '#000',
    fontSize: 10,
  },
  buttonDetailsOrçamento: {
    backgroundColor: '#FA4616',
    height: 24,
    width: 90,
    fontWeight: 600,
    color: '#ffff',
    fontSize: 10,
    '&:hover': {
      backgroundColor: '#FB6B45',
    },
  },
  textsSilver: {
    color: '#888E9D',
  },
  textBodyCoordenador: {
    color: '#888E9D',

    fontSize: 14,
  },
  textBodyCoordenadorGrupo: {
    color: '#000',
    fontSize: 15,
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#F3F4F6',
    height: 58,
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'none',
  },
  labelStatusNovo: {
    width: 100,
    height: 25,
    fontWeight: 600,
    borderRadius: 14,
    fontSize: 12,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  divRow: {
    display: 'flex',
    justifyContent: 'row',
  },
  divRight: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 350,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#c4c4c4',
    paddingTop: 10,
    height: '100vh',

  },

  divRightSelect: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 350,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#c4c4c4',
    paddingTop: 10,
    minHeight: '100vh',
  },

  pagination: {
    width: '100%',
    height: 40,
    display: 'flex',
    marginTop: 20,
    marginBottom: 20,
    paddingRight: 10,
    justifyContent: 'flex-end',
  },
  textdivRight: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
    [breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  paperRight: {
    marginTop: 20,
    height: 220,
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#ffff',
  },
  greenPaperRight: {
    backgroundColor: '#21C561',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
  valueTopGreenPaper: {
    color: '#ffff',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  valueGreenPaper: {
    color: '#ffff',
    fontSize: 18,
    fontFamily: 'Source Sans Pro',
  },
  divTextsPaperRight: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#F3F4F6',
    backgroundColor: '#ffff',
    width: '100%',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    paddingRight: 5,
    paddingLeft: 5,
  },
  textPaper: {
    fontSize: 15,
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',
    whiteSpace: 'nowrap',
  },
  paperFilter: {
    width: '100%',
    height: 130,
    marginTop: 5,
    padding: 10,
  },
  buttonFiltro: {
    backgroundColor: '#FF5225',
    height: 36,
    width: 130,
    color: '#ffff',
    fontSize: 12,
    marginTop: 5,
    marginLeft: 5,
    [breakpoints.down('sm')]: {
      width: 80,
      fontSize: 10,
    },
  },
  fontSilverOrcamentoModal: {
    fontSize: 10,
    color: '#888E9D',
  },
  fontSilverModal: {
    fontSize: 11,
    marginBottom: 5,
    color: '#888E9D',
    fontWeight: 400,
  },
  infosFileirasDialog: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 35px 20px 25px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divLineModal: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
  },
  labelStatusNovoModal: {
    width: 100,
    height: 25,
    borderRadius: 14,
    backgroundColor: '#FFF6C2',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  buttonTradeVendedorModal: {
    backgroundColor: '#F3F4F6',
    marginTop: 20,
  },
  inputFakeDate: {
    display: 'flex',
    flexDirection: 'row',
    borderColor: '#C4C4C4',
    justifyContent: 'space-between',
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#6C757D',
    height: 38,
    fontSize: 13,
    paddingLeft: 10,
    cursor: 'pointer',
    alignItems: 'center',
    '&:active': {
      borderColor: '#FF5225',
    },
    [breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  selectDetails: {
    minWidth: 150,
    height: 30,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: '#0000',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
  },
  divSearch: {
    width: 400,
    position: 'absolute',
    right: 24,
    top: 72,
    zIndex: 5,
  },
  divSearchIconContainer: {
    backgroundColor: '#4A4C55',
    width: 48,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '-13px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    '&:hover': {
      backgroundColor: '#1D1F2A',
    },
    '&:disabled': {
      backgroundColor: '#4A4C55',
    },
  },
  searchResultsContainer: {
    backgroundColor: '#FFFFFF',
    width: 'inherit',
    maxHeight: 230,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'overlay',
    boxShadow: '0 0 10px rgba(250, 70, 22, 0.25)',
  },
  searchGridContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: 10,
  },
  title: {
    fontWeight: 600,
  },
  searchResultsBox1: {
    width: 220,
  },
  searchResultsBox2: {
    width: 85,
  },
  searchResultsDate: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  gridSemResultados: {
    flexBasis: 'auto',
  },
}));

export default useStyles;
