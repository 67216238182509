import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: '100%',
  },
  appBar: {
    width: '100%',
    [breakpoints.down('xs')]: {
      height: 'auto',
    },
    padding: '4px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: palette.primary.main,
    backgroundColor: palette.background.level2,
  },
  wrapper: {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  actions: {
    display: 'flex',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    [breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      marginRight: 'auto',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
