import React from 'react';
import * as PropTypes from 'prop-types';
import { Box, Divider, TablePagination } from '@material-ui/core';

import useStyles from './style';
import TYPE_PAGES from '../../typePages';

const CardBar = ({
  titleElement,
  children,
  flat,
  onPageChange,
  pagination: {
    page,
    size,
    rpp,
  },
  filterDataInicio,
  filterDataFim,
  isTracking,
  typePage,
}) => {
  const classes = useStyles();

  return (
    <header>
      <Box className={classes.appBar}>
        <div className={classes.wrapper}>
          {titleElement}

          <div className={classes.actions}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              rowsPerPage={rpp}
              page={page - 1}
              labelRowsPerPage="Registros:"
              onChangePage={(_event, newPage) => {
                if (typePage === TYPE_PAGES.PEDIDOS) {
                  onPageChange(filterDataInicio,
                    filterDataFim, isTracking, { page: newPage + 1 });
                } else {
                  onPageChange(filterDataInicio,
                    filterDataFim, { page: newPage + 1 });
                }
              }}
              onChangeRowsPerPage={({ target }) => {
                if (typePage === TYPE_PAGES.PEDIDOS) {
                  onPageChange(filterDataInicio,
                    filterDataFim, isTracking, { rpp: target.value });
                } else {
                  onPageChange(filterDataInicio,
                    filterDataFim, { rpp: target.value });
                }
              }}
              count={size}
            />
            {children}
          </div>
        </div>
      </Box>

      {!flat && <Divider />}
    </header>
  );
};

CardBar.propTypes = {
  titleElement: PropTypes.element,
  children: PropTypes.node,
  flat: PropTypes.bool,
  pagination: PropTypes.instanceOf(Object).isRequired,
  onPageChange: PropTypes.func.isRequired,
  filterDataFim: PropTypes.string.isRequired,
  filterDataInicio: PropTypes.string.isRequired,
  isTracking: PropTypes.bool.isRequired,
  typePage: PropTypes.string.isRequired,
};

CardBar.defaultProps = {
  titleElement: null,
  children: null,
  flat: false,
};

export default CardBar;
