import React, { useEffect } from 'react';
import {
  Box, Dialog, DialogContent, DialogTitle, Grid,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { toCurrency } from '../../../utils/parsers';
import { getInstallmentsProductAction } from '../actions';
import Types from '../types';
import LoadingDark from '../loadingDark/LoadingDark';

const InstallmentsDialog = ({
  getInstallmentsProduct,
  handleOpen,
  price,
  installments,
  isLoading,
}) => {
  const { open, setOpen } = handleOpen;
  const dispatch = useDispatch();
  const {
    closeIcon,
    dialog,
    installmentGrid,
    title,
    installment,
  } = useStyles();

  const close = () => {
    setOpen(false);
    dispatch({ type: Types.INSTALLMENT_PRODUCT_CLEAR });
  };

  useEffect(() => {
    if (open) {
      getInstallmentsProduct(price);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={close}
      classes={{ paper: dialog }}
    >
      <Grid className={installmentGrid}>
        <DialogTitle classes={{ root: title }}>
          Opções de Parcelamento
        </DialogTitle>
        <button
          type="button"
          aria-label="close details dialog"
          className={closeIcon}
          onClick={close}
        />
      </Grid>
      <DialogContent>
        <Grid>
          {
            installments && installments.map(({
              quantidadeParcelas,
              valorParcela,
              valorTotal,
            }) => (
              <Box className={installment}>
                <Grid item sm={4}><span>{`${quantidadeParcelas}x`}</span></Grid>
                <Grid item sm={4}><span>{`de ${toCurrency(valorParcela)}`}</span></Grid>
                <Grid item sm={4}><span>{`total ${toCurrency(valorTotal)}`}</span></Grid>
              </Box>
            ))
           }
          {isLoading && (<LoadingDark />)}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

InstallmentsDialog.propTypes = {
  getInstallmentsProduct: PropTypes.func.isRequired,
  handleOpen: PropTypes.instanceOf(Object).isRequired,
  price: PropTypes.number.isRequired,
  installments: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapState = (state) => {
  const { installments, isLoading } = state.algolia;

  return ({
    installments,
    isLoading,
  });
};

export default connect(mapState, {
  getInstallmentsProduct: getInstallmentsProductAction,
})(InstallmentsDialog);
