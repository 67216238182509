import { Breadcrumbs, makeStyles, withStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  breadcrumbsBox: {
    display: 'flex',
    alignItems: 'center',
  },
  separator: {
    width: 5,
    height: 10,
    borderTop: '5px solid transparent',
    borderBottom: '5px solid transparent',
    borderLeft: '5px solid #2296F3',
    '&.initial': {
      marginRight: 8,
    },
  },
  [breakpoints.down('sm')]: {
    breadcrumbsBox: {
      paddingBottom: 10,
      justifyContent: 'center',
    },
  },
}));

export const BreadcrumbsStyled = withStyles(({ breakpoints }) => ({
  li: {
    fontSize: 12,
    lineHeight: '18px',
    '& a': {
      color: '#2296F3',
    },
  },
  separator: {
    marginLeft: 40,
  },
  [breakpoints.down('sm')]: {
    li: {
      fontSize: 10,
      lineHeight: '14px',
    },
    separator: {
      marginLeft: 20,
    },
  },
}))(Breadcrumbs);

export default useStyles;
