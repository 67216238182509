/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../components/datePicker';

const DatePickerRender = ({
  input,
  onChange,
  ...others
}) => {
  const handleChange = (event) => {
    input.onChange(event);
    onChange(event);
  };

  return (
    <DatePicker
      {...input}
      {...others}
      onChange={handleChange}
    />
  );
};

DatePickerRender.defaultProps = {
  variant: null,
  disabled: false,
  required: false,
  min: null,
  max: null,
  onChange: () => {},
};

DatePickerRender.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  meta: PropTypes.instanceOf(Object).isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

export default DatePickerRender;
