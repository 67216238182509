import InstantSearch from './instantSearch/InstantSearch';
import AddButton from './addButton/AddButton';
import Autocomplete from './autocomplete/Autocomplete';
import Breadcrumb from './breadcrumb/Breadcrumb';
import Cart from './cart/Cart';
import CategoryLevels from './categoryLevels/CategoryLevels';
import ContentText from './contentText/ContentText';
import DetailsDialog from './detailsDialog/DetailsDialog';
import DrawerToFilters from './drawerToFilters/DrawerToFilters';
import CloseSearchProducts from './closeSearchProducts/CloseSearchProducts';
import FreightInput from './freightInput/FreightInput';
import Hits from './hits/Hits';
import InstallmentsDialog from './installmentsDialog/InstallmentsDialog';
import Location from './location/Location';
import SearchBox from './searchBox/SearchBox';
import Snackbar from './snackBar/Snackbar';
import SortBy from './sortBy/SortBy';
import StockList from './stockList/StockList';
import Tooltip from './tooltip/Tooltip';
import VoltageProductDialog from './voltageProductDialog/VoltageProductDialog';
import ContentGuarantees from './guarantee/_components/contentGuarantees/ContentGuarantees';
import RadioGroupGuarantees from './guarantee/_components/radioGroupGuarantees/RadioGroupGuarantees';
import WithdrawStoreDialog from './withdrawStoreDialog/WithdrawStoreDialog';
import {
  Collapse,
  CheckboxRefinement,
  NumericMenu,
  RefinementList,
  Slider,
} from './filters';

const {
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_SEARCH_API_KEY,
  REACT_APP_ALGOLIA_WRITE_API_KEY,
  REACT_APP_ALGOLIA_INDEX_NAME,
  REACT_APP_ALGOLIA_INDEX_BEST_SELLING_SCORE_DESC,
  REACT_APP_ALGOLIA_INDEX_REVIEW_QUANTITY_DESC,
  REACT_APP_ALGOLIA_INDEX_MARGIN_DESC,
  REACT_APP_ALGOLIA_INDEX_PRICE_DESC,
  REACT_APP_ALGOLIA_INDEX_PRICE_ASC,
} = process.env;

const envVariablesAlgolia = {
  appId: REACT_APP_ALGOLIA_APP_ID || '',
  searchApiKey: REACT_APP_ALGOLIA_SEARCH_API_KEY || '',
  writeApiKey: REACT_APP_ALGOLIA_WRITE_API_KEY || '',
  indexName: REACT_APP_ALGOLIA_INDEX_NAME || '',
  indexBestSellingScoreDesc: REACT_APP_ALGOLIA_INDEX_BEST_SELLING_SCORE_DESC || '',
  indexReviewQuantityDesc: REACT_APP_ALGOLIA_INDEX_REVIEW_QUANTITY_DESC || '',
  indexMarginDesc: REACT_APP_ALGOLIA_INDEX_MARGIN_DESC || '',
  indexPriceDesc: REACT_APP_ALGOLIA_INDEX_PRICE_DESC || '',
  indexPriceAsc: REACT_APP_ALGOLIA_INDEX_PRICE_ASC || '',
};

export {
  AddButton,
  Autocomplete,
  Breadcrumb,
  Cart,
  CategoryLevels,
  Collapse,
  ContentText,
  DetailsDialog,
  DrawerToFilters,
  CloseSearchProducts,
  FreightInput,
  Hits,
  InstallmentsDialog,
  InstantSearch,
  Location,
  NumericMenu,
  RefinementList,
  SearchBox,
  Slider,
  Snackbar,
  SortBy,
  StockList,
  Tooltip,
  CheckboxRefinement,
  VoltageProductDialog,
  ContentGuarantees,
  RadioGroupGuarantees,
  WithdrawStoreDialog,
  envVariablesAlgolia,
};
