import React from "react";

import { connect } from "react-redux";

import PropTypes from "prop-types";
import useStyles from "./styles";
import { Alert, AlertTitle } from "@material-ui/lab";

const AlertTitleLdm = ({ title, text }) => {
  const classes = useStyles();

  return (
    <div className={classes.alertContainer}>
      <Alert variant="filled" severity="error" icon={false} className={classes.alert}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {text}
      </Alert>
    </div>
  );
};

AlertTitleLdm.propTypes = {
  title: PropTypes.number,
  text: PropTypes.string.isRequired,
};

const mapState = (state) => state.main.checkout.geral;

export default connect(mapState, {})(AlertTitleLdm);
