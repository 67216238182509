import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  hitsBox: {
    listStyle: 'none',
    gap: '30px 35px',
    padding: '0 40px 25px',
    // margin: '160px 0 0 0',
    margin: '110px 0 0 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 209px)',
    justifyContent: 'center',

    '&.d-none': {
      display: 'none',
    },

    [breakpoints.down('xs')]: {
      marginTop: 301,
    },

    [breakpoints.between('sm', 'sm')]: {
      marginTop: 198,
    },

    [breakpoints.between('md', 'md')]: {
      marginTop: 175,
    },
  },
  filtersBox: {
    '&.hidden': {
      display: 'none',
    },
  },
  hitsContainer: {
    display: 'grid',
    gridTemplateRows: 'repeat(1, 1fr)',
  },
}));

export default useStyles;
