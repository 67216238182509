import { Checkbox } from '@material-ui/core';
import { bool, func, number } from 'prop-types';
import React from 'react';

const CheckoutItemSelect = ({
  onChange, value, checked
}) => {

  return (
    <>
      <Checkbox checked={checked} value={value} onChange={onChange}/>
    </>
  );
};

CheckoutItemSelect.propTypes = {
  value: number.isRequired,
  onChange: func.isRequired,
  checked: bool.isRequired,
};

export default CheckoutItemSelect;
