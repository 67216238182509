import apiService from '../services/apiService';
import apiServiceOc from '../services/apiServiceOc';
import PAYMENT_TYPE from '../../main/checkout/_components/payment/paymentType';
import { toMoney } from '../utils/parsers';
import { getFilialVendedor } from '../services/app';

const getPaymentMethod = (payments, remainder, remainderInstallment) => {
  const validPayments = payments.filter((payment) => (
    payment.cartaoAutorizado !== false || payment.paymentStatus !== 3
  ));

  if (!validPayments.length) return null;

  const isManual = validPayments.length === 1 && (remainder === 0 || remainderInstallment === 0) && validPayments[0].type === 'MA';

  if (isManual) {
    return PAYMENT_TYPE.CAIXA;
  }
  return PAYMENT_TYPE.AVANÇADO;
};

const mapCustomer = ({ cliente }) => {
  const address = cliente.enderecos.find((item) => item.bitPrincipal && cliente.codCliente === item.codCliente) || {};

  return {
    customerId: cliente.codCliente,
    nomeComprador: cliente.nomeComprador,
		nomeSocial: cliente.nomeSocial,
    name: cliente.nomeRazaoSocial,
    document: cliente.cpfCnpj,
    rgIe: cliente.rgIe,
    typePerson: cliente.tipoPessoa,
    revendedor: cliente.revendedor,
    phone: cliente.ddd + cliente.telefone,
    email: cliente.email,
    customerSince: cliente.dataCadastro ? cliente.dataCadastro.split(' ')[0] : '',
    amountOfOrders: cliente.qtdPedidos,
    idAddress: address.id,
    bitAddressMain: cliente.tipoPessoa === 'J' ? cliente.bitPrincipal : address.bitPrincipal,
    bitInactive: cliente.tipoPessoa === 'J' ? cliente.bitInativoPj : address.bitInativo,
    idMatrix: cliente.codMatriz,
    address: {
      zipCode: address.cep,
      street: address.logradouro,
      neighborhood: address.bairro,
      city: address.cidade,
      state: address.estado,
      number: address.numero,
      complement: address.complemento,
      addInfo: address.informacoesAdicionais,
    },
  };
};

const mapItems = (itens = []) => itens.map((item) => ({
  // ipiTax: item.ipi,
  // stTax: item.st,
  // icmsTax: item.icms,
  // fcpTax: item.fcp,
  codKit: item.codKit,
  idSku: item.idSku,
  productId: item.codProduto,
  name: item.produto,
  image: item.imagem,
  brand: item.marca,
  quantity: item.quantidade,
  subTotalOnTime: item.precoPorLink ? item.precoAPrazoPPL : item.precoAPrazo,
  subTotal: item.precoAVista,
  totalOnTime: item.precoPorLink ? item.precoAPrazoPPL*item.quantidade : item.totalAPrazo,
  total: item.totalAVista,
  margin: item.margem,
  discount: item.totalDesconto,
  discountPercent: item.porcentagemDescontoItem,
  freightType: item.freightType,
  freight: item.freight,
  allowedStock: item.estoquePermitidoVenda,
  ppl: item.precoPorLink,
  pplHash: item.hashPpl,
  pplActive: item.bitAtivoPpl,
  pplExpirated: item.bitExpiradoPpl,
  pplStock: item.estoquePermitidoVendaPpl,
  companyName: item.companyName,
  isSingleDelivery: item.isSingleDelivery,
  idSeller: item.idSeller,
  model: item.modelo,
  idItemBseller: item.idItemBseller,
  typeStock: item.tipoEstoque,
  datasheet: item.fichaTecnica,
  deliveryTime: item.estimatedDeliveryDays,
  shippingCompany: item.bsellerContractId,
  filials: item.filiais,
  points: item.pontosAVista,
  pointsOnTime: item.pontosAPrazo,
  isPma: item.isPma,
  virtualStorage: item.virtual,
  garantias: item.garantias,
}));

const mapResume = (cart) => ({
  // ipiTax: (cart.totalImpostos || {}).totalValorIPI,
  // stTax: (cart.totalImpostos || {}).totalValorST,
  // icmsTax: (cart.totalImpostos || {}).totalValorICMS,
  // fcpTax: (cart.totalImpostos || {}).totalValorFCP,
  // totalTax: (cart.totalImpostos || {}).totalImpostos,
  calculateTax: !!cart.calcularImposto,
  customerHaveTaxes: !!cart.ehClienteCalculoImposto,
  quantityProducts: cart.totalProdutos,
  freight: cart.valorFrete,
  discountCoupon: cart.valorCupom || 0,
  discount: cart.pagamentos.reduce((accum, payment) => accum + payment.desconto, 0),
  maxDiscount: cart.maximoDesconto,
  subTotalPrazo: cart.subTotalPrazo,
  subTotalVista: cart.subTotalVista,
  total: cart.totalVista,
  totalGarantiaEstendida: cart.totalGarantiaEstendida,
  totalInstallment: cart.totalPrazo,
  estimatedDeliveryDays: cart.estimatedDeliveryDays,
  responsable: cart.nomeVendedor,
  discountStatus: cart.statusDesconto,
  discountRefused: !!(cart.solicitacaoDesconto && cart.solicitacaoDesconto.length)
    && cart.solicitacaoDesconto[0].mensagemRecusa,
  namePickup: cart.nomeClienteRetiraCD || cart.cliente.nomeRazaoSocial || '',
  documentNF: cart.documentoNF || cart.cliente.cpfCnpj || '',
  customerType: cart.classificacaoCliente || '',
  orderComments: cart.observacaoPedido || '',
  purchaseOrder: cart.ordemDeCompra || '',
  idSeller: cart.idVendedor,
  sellerName: cart.nomeVendedor,
  remainder: (cart.valoresRestantesDoOrcamento && cart.valoresRestantesDoOrcamento.valorVistaRestante) || '',
  remainderInstallment: (cart.valoresRestantesDoOrcamento && cart.valoresRestantesDoOrcamento.valorVistaRestante) || '',
  rgIe: (cart.pagamentoOrcamento && cart.pagamentoOrcamento.rgIe) || '',
  points: cart.totalPontosVista,
  pointsOnTime: cart.totalPontosPrazo,
  paymentMethod: getPaymentMethod(
    cart.pagamentos,
    (cart.pagamentoOrcamento && cart.valoresRestantesDoOrcamento.valorVistaRestante),
    (cart.pagamentoOrcamento && cart.valoresRestantesDoOrcamento.valorVistaRestante),
  ),
  isNotMarketPlaceOnly: !(cart.itensFiliais || []).every(
    (filial) => filial.itens.every(
      (item) => item.idSkuMktplaceIn,
    ),
  ),
  hasMarketPlace: (cart.itensFiliais || []).some(
    (filial) => filial.itens.some(
      (item) => item.idSkuMktplaceIn,
    ),
  ),
});

const mapPayment = (payments) => payments.map((payment) => ({
  paymentId: payment.checkoutIdPayment,
  discount: payment.desconto,
  paymentMethod: payment.descricaoFormaPagamento,
  installmentQuantity: payment.installmentQuantity,
  type: payment.type,
  emvPix: payment.emvPix || {},
  orquestradorPixDTO: payment.orquestradorPixDTO || {},
  paymentTermId: payment.paymentTermId,
  installmentValue: payment.installmentValue,
  paymentStatus: payment.paymentStatus,
  braspagReturn: payment.retornoBraspag,
  total: payment.total,
  creditCardValid: payment.cartaoAutorizado,
  nomeVoucher: payment.voucherIdBseller,
  voucherIdBseller: payment.voucherIdBseller,
  voucherIdGurgel: payment.voucherIdGurgel,
}));

const mapInstallments = (cart) => cart.parcelamento.map((installment) => ({
  quantity: installment.quantidadeParcelas,
  value: installment.valorParcela,
  label: `${installment.quantidadeParcelas} - ${toMoney(installment.valorParcela)}`,
}));

const mapFilials = (filials = []) => filials.map((filial) => ({
  name: filial.nomeEstabelecimento,
  idFilial: filial.idFilial,
  establishmentId: filial.idEstabelecimento,
  items: mapItems(filial.itens),
  freightType: filial.tipoFrete,
  freight: filial.itens.reduce((accuml, item) => item.freight + accuml, 0),
}));

export const getGiftCard = async (idClient) => {
  const res = await apiService.get(`/clientes/${idClient}/vales`);
  const { data: { results } } = res;
  return (results || [])
    .filter((item) => item.statusVale === 'LIBERADO PARA USO')
    .map((item) => ({
      id: item.idValeCliente,
      date: item.dataVale,
      expirationDate: item.dataValidade,
      value: item.valorVale,
      status: item.statusVale,
      type: item.type,
    }));
};

export const getCheckoutDetails = async ({
  idCheckout, isSameDay, isPickup, notCalculaFrete, idEstabelecimento, calculateTax,
  originFromTax, forcarFrete, entregaNitro, entregaAgendada, dataEntregaAgendada
}) => {
  const { data } = await apiService.post(`/orcamento/${idCheckout}/detalhes?agrupaItens=true`, {
    calculaFrete: !notCalculaFrete,
    mesmoDia: isSameDay,
    retiraCd: isPickup,
    idEstabelecimento,
    calcularImposto: calculateTax,
    flagCalcImpOrigem: originFromTax,
    forcarFrete,
    entregaNitro,
    entregaAgendada,
    dataEntregaAgendada
  });
  if (!data.results) {
    throw new Error('Orçamento não encontrado!');
  }

  const customer = mapCustomer(data.results);
  const resume = mapResume(data.results);
  const payments = mapPayment(data.results.pagamentos.filter((payment) => (
    payment.cartaoAutorizado !== false && payment.paymentStatus !== 3
  )));
  const deniedPayments = mapPayment(data.results.pagamentos.filter((payment) => (
    payment.cartaoAutorizado === false || payment.paymentStatus === 3
  )));
  const installments = mapInstallments(data.results);
  //const listGiftCard = await getGiftCard(customer.customerId);
  const listGiftCard = [];

  const enablePickup = data.results.opcaoRetiraCD;
  const enablePickupTransfer = data.results.opcaoRetiraPorTransferencia;
  const enableNitrous = data.results.opcaoEntregaNitro;
  const forceFreight = data.results.forcarFrete;
  const enableSameDay = data.results.opcaoSameDay;
  const checkedPickup = data.results.bitRetiraCdChecked;
  const checkedSameday = data.results.bitMesmoDiaChecked;
  const checkedNormal = !checkedPickup && !checkedSameday;
  const checkedNitrous = data.results.entregaNitroChecked;
  const enableSchedule = data.results.entregaAgendada;
  const checkedSchedule = data.results.entregaAgendadaChecked;
  const dataEntregaAgendadaEscolhida = data.results.dataEntregaAgendadaEscolhida;
  const dataMinimaEntregaAgendada = data.results.dataMinimaEntregaAgendada;
  const limiteEntregaAgendada = data.results.limiteEntregaAgendada;
  const descricaoEntregaEstimada = data.results.descricaoEntregaEstimada;
  const sameDayFreight = data.results.mesmoDiaFrete || 0;
  const normalFreight = data.results.normalFrete || 0;
  const discountCart = data.results.porcentagemDescontoCarrinho || 0;
  const itemsFilials = mapFilials(data.results.itensFiliais || []);
  const idSeller = data.results.idVendedor;
  const quantidadeNumerosDaSorte = data.results.quantidadeNumerosDaSorte;
  const margemDoCarrinho = data.results.margemDoCarrinho;

  return {
    checkoutId: +idCheckout,
    orderId: data.results.codPedido,
    nomeComprador: data.results.cliente.nomeComprador,
    customer,
    itemsFilials,
    resume,
    listGiftCard,
    payments,
    deniedPayments,
    installments,
    enablePickup,
    enablePickupTransfer,
    enableNitrous,
    forceFreight,
    enableSameDay,
    checkedPickup,
    checkedSameday,
    checkedNormal,
    checkedNitrous,
    checkedSchedule,
    enableSchedule,
    dataEntregaAgendadaEscolhida,
    dataMinimaEntregaAgendada,
    limiteEntregaAgendada,
    descricaoEntregaEstimada,
    sameDayFreight,
    normalFreight,
    discountCart,
    idSeller,
    quantidadeNumerosDaSorte,
    margemDoCarrinho
  };
};

export const getCheckoutMiniDetails = async ({
  idCheckout, isSameDay, isPickup, notCalculaFrete, idEstabelecimento, calculateTax,
  originFromTax, forcarFrete,
}) => {

  const { data } = await apiService.post(`/orcamento/${idCheckout}/mini-detalhes?agrupaItens=true`, {
    calculaFrete: !notCalculaFrete,
    mesmoDia: isSameDay,
    retiraCd: isPickup,
    idEstabelecimento,
    calcularImposto: calculateTax,
    flagCalcImpOrigem: originFromTax,
    forcarFrete,
  });
  if (!data.results) {
    throw new Error('Orçamento não encontrado!');
  }
  console.log("1");
  const customer = mapCustomer(data.results);
  const resume = mapResume(data.results);
  const payments = mapPayment(data.results.pagamentos.filter((payment) => (
    payment.cartaoAutorizado !== false && payment.paymentStatus !== 3
  )));
  const deniedPayments = mapPayment(data.results.pagamentos.filter((payment) => (
    payment.cartaoAutorizado === false || payment.paymentStatus === 3
  )));
  const installments = mapInstallments(data.results);
  //const listGiftCard = await getGiftCard(customer.customerId);
  const listGiftCard = [];

  const enablePickup = data.results.opcaoRetiraCD;
  const enablePickupTransfer = data.results.opcaoRetiraPorTransferencia;
  const forceFreight = data.results.forcarFrete;
  const enableSameDay = data.results.opcaoSameDay;
  const checkedPickup = data.results.bitRetiraCdChecked;
  const checkedSameday = data.results.bitMesmoDiaChecked;
  const checkedNormal = !checkedPickup && !checkedSameday;
  const sameDayFreight = data.results.mesmoDiaFrete || 0;
  const normalFreight = data.results.normalFrete || 0;
  const discountCart = data.results.porcentagemDescontoCarrinho || 0;
  const itemsFilials = mapFilials(data.results.itensFiliais || []);
  const idSeller = data.results.idVendedor;
  console.log("2");
  return {
    checkoutId: +idCheckout,
    orderId: data.results.codPedido,
    customer,
    itemsFilials,
    resume,
    discountCart,
    idSeller,
  };
};

export const updateItem = async ({
  idCheckout, productId, quantity, pplHash, warrantyId,
}) => {
  let filial = getFilialVendedor();
  await apiService.put(`/carrinho/alterar-produto`, {
    checkoutId: idCheckout,
    quantity,
    ppl: pplHash,
    idFilial: filial,
    warrantyId: warrantyId,
    idSku: productId,
    calcularDesconto: true
  });
};

export const removeItem = async ({ checkoutId, productId }) => {
  let filial = getFilialVendedor();
  await apiService.delete(`/carrinho/${checkoutId}/produto/${productId}?idFilial=${filial}`);
  // const { data: { resultsClear } } = await apiService.put(`/inativa-solicitacao-desconto/checkoutId/${checkoutId}`);
};

export const removeItemsList = async ({ checkoutId, selectedItems }) => {
  await apiService.put(`/item-carrinho/remover-produtos`, {
    checkoutId,
    idSkus: [
      ...selectedItems
    ]
  });
};

export const checkoutDiscount = async (checkoutId, body) => {
  const { data: { results } } = await apiService.post(`/orcamento/desconto`, body);
  if (!results.response) {
    throw results;
  }
  return results;
};

export const checkoutRequestDiscount = async (checkoutId, body) => {
  const { data: { results } } = await apiService.post(`/solicitacao-desconto/inclusao-solicitacao-desconto`, body);
  if (!results.response) {
    throw results;
  }
  return results;
};

export const checkDiscountOffer = async (checkoutId) => {
  const {
    data: { results: { statusTexto } },
  } = await apiService.get(`/solicitacao-desconto/buscar-status-solicitacao-desconto-ativa/checkoutId/${checkoutId}`);

  return statusTexto;
};

export const itemDiscount = async (checkoutId, productId, body) => {
  const { data: { resultsClear } } = await apiService.put(`/solicitacao-desconto/inativar/checkoutId/${checkoutId}`);
  const { data: { results } } = await apiService.post(`/orcamento/desconto/produto`, body);
  if (!results.response) {
    throw results;
  }
  return results;
};

export const saveExtraInfo = async (checkoutId, body) => {
  const {
    data: { results: { message } },
  } = await apiService.put(`/orcamento/${checkoutId}/informacoes-adicionais`, body);
  return message;
};

export const savePayment = async (body) => {
  const { data: { results } } = await apiService.post('/pagamentos', body);
  if (!results.response) throw new Error(results.message);
  return results.message;
};

export const savePaymentPix = async (body) => {
  const { data: { results } } = await apiService.post('/pagamentos', body);
  if (!results.response) throw new Error(results.message);
  return results;
};

export const generateLink = async ({
  idCheckout,
  value,
  idClient,
  installments,
  cep,
  name,
  installmentValue,
  type,
  resendLink,
  paymentItem,
  meiosEnvioLinkRemoto,
  destinatarioLinkRemoto,
}) => {

  var contatosValidos = destinatarioLinkRemoto.contacts.filter(element => {
    if (Object.keys(element).length !== 0) {
      return true;
    }

    return false;
  });

  if (resendLink) {
    const { data: { results: { link } } } = await apiService.post(`/pagamentos/pagamento-remoto/reenviar-link-remoto`, {
      checkoutId: idCheckout,
      checkoutIdPayment: paymentItem.paymentId,
      destinatarioLinkRemoto: {name : destinatarioLinkRemoto.name, contacts : contatosValidos},
    });
  } else {
    const { data: { results: { checkoutIdPayment } } } = await apiService.post('/pagamentos/pagamento-remoto/gerar', {
      checkoutId: idCheckout,
      installmentQuantity: installments,
      installmentValue: installmentValue,
      total: value,
      destinatarioLinkRemoto: {name : destinatarioLinkRemoto.name, contacts : contatosValidos},
    });

    return checkoutIdPayment;
  }

  const { REACT_APP_ENV } = process.env;

  return 'Link Remoto enviado com sucesso';
};

export const removePayment = async (paymentId) => {
  const { data: { results } } = await apiService.delete(`pagamentos/${paymentId}`);
  if (!results.response) throw new Error(results.message);
  return results.message;
};

export const cancelPayment = async (paymentId) => {
  const { data: { results } } = await apiService.put(`pagamentos/pagamento-remoto/cancelar/${paymentId}`);
  return "Pagamento inativado com sucesso!";
};

export const insertCoupon = async ({
  idCheckout, idCoupon, hashCupom, idVale, idValeCliente, price, onTimeDiscount, remainder, idClient, tipoVale,
}) => {
  const { data: { results } } = await apiService.post('/vales', {
    hashCupom: hashCupom,
    idVale: idVale,
    idValeCliente: idValeCliente,
    tipoVale: tipoVale,
    checkoutId: idCheckout,
    codCliente: idClient,
    canalVenda: 'PDV',
    valorTotalProdutos: price,
    saldoLiqRestante: remainder,
  });
  return results;
};

export const deleteCoupon = async ({ idCheckout }) => {
  await apiService.delete(`/orcamento/${idCheckout}/vales`);
};

export const checkCoupon = async ({ idCoupon }) => {
  const res = await apiService.get(`/cupons/${idCoupon}/validar`);
  return res.data.results.valido;
};

export const getConditions = async () => {
  const { data: { results } } = await apiService.get('pagamentos/condicoes');
  return results || [];
};

export const submitOrder = async (body) => {
  const { data: { results } } = await apiServiceOc.post('pedidos', body);
  if (!results) throw new Error('Ocorreu algum erro ao salvar pedido!');
  if (results && !results.response) throw new Error(results.message);
  return results;
};

export const getPdf = async (checkoutId) => {
  const { data } = await apiService.get(`relatorios/orcamentos/${checkoutId}/detalhes`, {
    responseType: 'blob',
  });
  return data;
};

export const sendEmail = async ({ customerId, checkoutId }) => {
  const { data: { results } } = await apiService.post('relatorios/envio-por-email', {
    tipo: 'orcamento',
    codCliente: customerId,
    dadosRelatorio: [
      {
        uri: '/relatorios/orcamentos/{codOrcamento}/detalhes',
        id: 'orcamentoDetalhe',
        parameters: {
          parametro: 'codOrcamento',
          tipoParametro: 'url',
          valorParametro: checkoutId,
        },
      },
    ],
  });
  if (!results.response) throw new Error(results.message);
  return results;
};

export const addAllVouchers = async ({ checkoutId, codCliente, valorTotalProdutos }) => {
  const { data: { results } } = await apiService.post('vales/incluir-todos', {
    checkoutId,
    codCliente,
    valorTotalProdutos,
    canalVenda: 'PDV',
  });

  if (!results.response) throw new Error(results.message);

  return results;
};
export const tradeValueProduct = async ({
  checkoutId, codProduto, idItemBseller, valorAnterior,
  valorAtribuido, motivoSolicitacao, userSolicitacao, userAprovacao,
}) => {
  const { data: { results } } = await apiService.post('carrinho/altera-preco-produto', {
    checkoutId,
    codProduto,
    idItemBseller,
    valorAnterior,
    valorAtribuido,
    motivoSolicitacao,
    userSolicitacao,
    userAprovacao,
  });
  return results;
};

export const getEntregaQuebrada = async({checkoutId, idSku}) => {
  const data = await apiService.get(`/entregas/checkout/${checkoutId}/item/${idSku}`);
  return data;
}