/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../styles';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  getRelatorioCoordenadorAction,
  putVendedoresAction,
  setCheckoutMetaAction,
  clearMetaAction,
  takeCheckoutAction, openFilterAction, closeFilterAction,
} from '../actions';
import {
  colourStyles,
} from './utilsRecovery';
import { toCNPJ, toCPF, toMoney } from '../../../../shared/utils/parsers';

const DetailsCoordenador = ({
  setDialog, dialog, detailRelatorio, vendedores, putVendedores, nomeVendedor,
}) => {
  const classes = useStyles();
  const [filterVendedor, setFilterVendedor] = useState('');
  let arrayVendedor = [];
  vendedores.forEach((item) => {
    const { idVendedor: value, nome: label } = item;
    arrayVendedor.push({ value, label });
  });
  const handleToggle = () => setDialog(!dialog);
  const colorStatus = useCallback((item) => {
    switch (item) {
      case 'RECUPERADO':
        return { backgroundColor: '#80EFA2', color: '#007023' };
      case 'CANCELADO':
        return { backgroundColor: '#FE9999', color: '#7E0000' };
      case 'NA FILA':
        return { backgroundColor: '#FFF6C2', color: '#403B1E' };
      case 'AGENDADO':
        return { backgroundColor: '#a6dced', color: '#0963ac' };
      default:
        return {};
    }
  }, []);

  const handlePutVendedor = () => {
    putVendedores(detailRelatorio.checkoutId, filterVendedor);
    setDialog(!dialog);
  };

  return (
    <div className={classes.paper} styles={{ justifyContent: 'center', alignItems: 'center' }}>
      <Box position="relative">
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={handleToggle}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogTitle>
          <div className={classes.divLineModal}>
            <span className={classes.fontSilverOrcamentoModal}>ORÇAMENTO</span>
            #
            {detailRelatorio.checkoutId}
          </div>
        </DialogTitle>
      </Box>
      <div className={classes.infosFileirasDialog}>
        <div className={classes.divLineModal}>
          <span className={classes.fontSilverModal}>CLIENTE</span>
          <span>{detailRelatorio.nomeCliente}</span>
        </div>
        <div className={classes.divLineModal}>
          <span className={classes.fontSilverModal}>CPF/CNPJ</span>
          <span>
            {detailRelatorio.cpfCnpj !== undefined && detailRelatorio.cpfCnpj.length === 11 ? (
              toCPF(detailRelatorio.cpfCnpj)
            ) : (
              toCNPJ(detailRelatorio.cpfCnpj)
            )}
          </span>
        </div>
      </div>

      <div className={classes.infosFileirasDialog}>
        <div className={classes.divLineModal}>
          <span className={classes.fontSilverModal}>VALOR</span>
          <span>{toMoney(detailRelatorio.valor)}</span>
        </div>
        <div className={classes.divLineModal}>
          <span className={classes.fontSilverModal}>DATA</span>
          <span>{detailRelatorio.data}</span>
        </div>
        <div className={classes.divLineModal}>
          <span className={classes.fontSilverModal}>GRUPO</span>
          <span>{detailRelatorio.grupo}</span>
        </div>
        <div className={classes.divLineModal}>
          <span className={classes.fontSilverModal}>CANAL</span>
          <span>{detailRelatorio.canal}</span>
        </div>
      </div>

      <div className={classes.infosFileirasDialog}>

        <div className={classes.divLineModal} style={{ width: 200 }}>
          <span className={classes.fontSilverModal}>VENDEDOR</span>
          <Select
            styles={colourStyles}
            options={arrayVendedor}
            placeholder={`${nomeVendedor}`}
            onChange={(e) => setFilterVendedor(e.value)}
          />

        </div>
        <div className={classes.divLineModal}>
          <Button
            className={classes.buttonTradeVendedorModal}
            onClick={handlePutVendedor}
            disabled={filterVendedor === ''}
          >
            Trocar Vendedor
          </Button>
        </div>
        <div className={classes.divLineModal}>
          <span className={classes.fontSilverModal}>STATUS</span>
          <Box className={classes.labelStatusNovo} style={colorStatus(detailRelatorio.status)}>
            {detailRelatorio.status}
          </Box>
        </div>
      </div>
    </div>
  );
};
DetailsCoordenador.propTypes = {
  dialog: PropTypes.bool.isRequired,
  setDialog: PropTypes.func.isRequired,
  detailRelatorio: PropTypes.instanceOf(Object).isRequired,
  vendedores: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  putVendedores: PropTypes.func.isRequired,
  nomeVendedor: PropTypes.string.isRequired,
};
const mapState = (state) => state.main.recovery.list;
export default connect(mapState, {
  getRelatorio: getRelatorioCoordenadorAction,
  putVendedores: putVendedoresAction,
  setCheckoutMeta: setCheckoutMetaAction,
  clearMeta: clearMetaAction,
  takeCheckout: takeCheckoutAction,
  openFilter: openFilterAction,
  closeFilter: closeFilterAction,
})(DetailsCoordenador);
