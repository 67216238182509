export default {
  SET_DRAWER: '@app/SET_DRAWER',
  SET_WIDTH: '@app/SET_WIDTH',
  OPEN_MENU: '@app/OPEN_MENU',
  CLOSE_MENU: '@app/CLOSE_MENU',
  GET_FILIAL_REQUEST: '@app/GET_FILIAL_REQUEST',
  GET_FILIAL_SUCCESS: '@app/GET_FILIAL_SUCCESS',
  GET_FILIAL_FAIL: '@app/GET_FILIAL_FAIL',
  GET_CONFIGS_REQUEST: '@app/GET_CONFIGS_REQUEST',
  GET_CONFIGS_SUCCESS: '@app/GET_CONFIGS_SUCCESS',
  GET_CONFIGS_FAIL: '@app/GET_CONFIGS_FAIL',
  SET_FILIAL: '@app/SET_FILIAL',
  REGISTER_SNACK: '@app/REGISTER_SNACK',
};
