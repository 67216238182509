import { handleActions, combineActions } from 'redux-actions';
import Types from './types';

const INITIAL_STATE = {
  installments: [],
  page: 0,
  isLoading: false,
  hasException: null,
};

const stopLoading = combineActions(
  Types.INSTALLMENT_PRODUCT_SUCCESS,
  Types.INSTALLMENT_PRODUCT_FAILURE,
);

const startLoading = combineActions(
  Types.INSTALLMENT_PRODUCT_REQUEST,
);

export default handleActions({
  [stopLoading]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [startLoading]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [Types.INSTALLMENT_PRODUCT_SUCCESS]: (state, action) => {
    const { installments } = action;
    return {
      ...state,
      installments,
      page: 0,
    };
  },
  [Types.INSTALLMENT_PRODUCT_CLEAR]: (state) => ({
    ...state,
    installments: [],
    page: 0,
  }),
}, INITIAL_STATE);
