import { makeStyles } from '@material-ui/core';
import { CopyIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  tableRowBody: {
    '&:nth-of-type(2n)': {
      backgroundColor: '#FFFFFF !important',
    },
  },
  tableCellBody: {
    textAlign: 'center',
    height: 64,

    '&:first-child': {
      fontWeight: 700,
    },

    [breakpoints.up('lg')]: {
      padding: '0 16px',
    },
  },
  iconBtn: {
    fontWeight: 700,
    color: '#0F1012',
    padding: 6,

    '&:hover': {
      background: 'none',
      color: '#FA4616',
    },
  },
  labelBtn: {
    gap: 6,
  },
  launchIcon: {
    fontSize: 16,
  },
  checkoutBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.down('xs')]: {
      flexDirection: 'row',
    },

    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  copyIcon: {
    backgroundImage: `url(${CopyIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 16,
    width: 17,
    height: 16,
  },
  btnCheckoutId: {
    display: 'flex',
    textDecoration: 'none',
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'white',
    padding: 0,
  },
  popper: {
    zIndex: 0,
    top: '14px !important',
  },
  tooltip: {
    backgroundColor: '#008000',
    fontSize: 12,
  },
}));

export default useStyles;
