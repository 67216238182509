import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  showMoreBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  showMoreBtn: {
    fontSize: 12,
    lineHeight: '18px',
    textTransform: 'lowercase',
    fontFamily: ['Poppins', 'sans-serif'],
    background: 'none',
    border: '1px solid #E3E3E7',
    borderRadius: 4,
    padding: '4px 16px',
  },
}));

export default useStyles;
