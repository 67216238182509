import React from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { Box, Divider } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { Add as AddIcon, Clear as ClearIcon } from '@material-ui/icons';
import TitleInfo from '../titleInfo/TitleInfo';
import {
  deleteCouponAction,
  insertCouponAction,
} from '../../../../main/checkout/actions';

const CouponInfo = (props) => {
  const {
    listGiftCard, insertCoupon, deleteCoupon, checkout,
  } = props;

  const add = (idCoupon) => {
    const { checkoutId, resume } = checkout;
    const price = resume.subTotalPrazo;
    insertCoupon({ idCheckout: checkoutId, idCoupon, price });
  };

  const remove = () => {
    const { checkoutId } = checkout;
    deleteCoupon({ idCheckout: checkoutId });
  };

  return (
    <Box>
      <Divider />
      <TitleInfo title="Aplicar Desconto" />

      <Box pl={2} pr={2} pb={2}>
        {listGiftCard.map((item) => (
          <ListItem key={`coupon-${item.id}`}>
            <ListItemText primary={`R$ ${item.value} - ${item.expirationDate}`} />
            <IconButton edge="end" aria-label="add" onClick={() => add(item.id)}>
              <AddIcon />
            </IconButton>
            <IconButton edge="end" aria-label="add" onClick={remove}>
              <ClearIcon />
            </IconButton>
          </ListItem>
        ))}
      </Box>

    </Box>
  );
};

CouponInfo.propTypes = {
  insertCoupon: PropTypes.func.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
  listGiftCard: PropTypes.arrayOf(instanceOf(Object)).isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => ({
  ...state.main.checkout.geral,
});

export default connect(mapState, {
  insertCoupon: insertCouponAction,
  deleteCoupon: deleteCouponAction,
})(CouponInfo);
