/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import InputRender from './InputRender';
import { toCNPJ } from '../utils/parsers';

const InputCnpjRender = ({
  input,
  onChange,
  onBlur,
  endAdornment,
  variant,
  readOnly,
  ...others
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    input.onChange(value.replace(/([\D])/g, '').substring(0, 14));
    onChange(event);
  };

  const handleBlur = (event) => {
    const { target: { value } } = event;

    input.onBlur(value.replace(/([\D])/g, ''));
    onChange(event);
  };

  return (
    <InputRender
      {...others}
      variant={variant}
      input={{
        ...input,
        value: toCNPJ(input.value),
        onChange: handleChange,
        onBlur: handleBlur,
      }}
      InputProps={{
        endAdornment,
        readOnly,
      }}
    />
  );
};

InputCnpjRender.defaultProps = {
  type: 'text',
  variant: 'standard',
  // maxLength: 14,
  disabled: false,
  required: false,
  min: 14,
  // max: 14,
  onChange: () => {},
  onBlur: () => {},
  endAdornment: null,
  readOnly: false,
};

InputCnpjRender.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  meta: PropTypes.instanceOf(Object).isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  endAdornment: PropTypes.node,
  readOnly: PropTypes.bool,
};

export default InputCnpjRender;
