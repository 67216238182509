import React from 'react';
import { Route } from 'react-router-dom';
import RecoveryListPage from './list/NavigationTop/Page';
import NewUser from './list/NavigationTop/NewUser/Vendedores';
import Parametrizacao from './list/NavigationTop/Parametrizacao/Parametrizacao';
import VendedoresInativos from './list/NavigationTop/UsersInativos/VendedoresInativos';
import BusinessUnit from './list/NavigationTop/BusinessUnit/BusinessUnit';
import Office from './list/NavigationTop/Office/Office';
import SalesChannel from './list/NavigationTop/SalesChannel/SalesChannel';
import Supervisor from './list/NavigationTop/Supervisor/Supervisor';
import CustomSwitch from '../../shared/components/customSwitch';

const RecoveryRouter = () => (
  <CustomSwitch>
    <Route
      path="/homeSales/:idhomeSales"
      component={RecoveryListPage}
      exact
    />
    <Route
      path="/homeSales/newuser/:idnewuser"
      component={NewUser}
      exact
    />
    <Route
      path="/homeSales/businessunit/:idbusinessunit"
      component={BusinessUnit}
      exact
    />
    <Route
      path="/homeSales/office/:idoffice"
      component={Office}
      exact
    />
    <Route
      path="/homeSales/saleschannel/:idsaleschannel"
      component={SalesChannel}
      exact
    />
    {/* <Route */}
    {/*  path="/homeSales/supervisor/:idsupervisor" */}
    {/*  component={Supervisor} */}
    {/*  exact */}
    {/* /> */}
    <Route
      path="/homeSales/userInativos/:iduserInativos"
      component={VendedoresInativos}
      exact
    />
    <Route
      path="/homeSales/parametrizacao/:idparametrizacao"
      component={Parametrizacao}
      exact
    />
  </CustomSwitch>
);

export default RecoveryRouter;
