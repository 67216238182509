const PAYMENT_TYPE = {
  BOLETO: 'B',
  CARTAO_DIGITAL: 'CC',
  BOLETO_PRAZO: 'BP',
  PIX: 'PX',
  DINHEIRO: 'DN',
  LINK_REMOTO: 'L',
  AVANÇADO: 'A',
  CUPOM: 'CP',
  CAIXA: 'MA',
};

export default PAYMENT_TYPE;
