import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  fieldValue: {
    minWidth: 120,
  },
  formContainer: {
    width: '100%',
    padding: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
