import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as PropTypes from 'prop-types';
import useStyles from './styles';
import logo from '../../assets/logo.svg';
import User from './user';

const Page = ({ page, user, filial }) => {
  const classes = useStyles();
  if (user && user.idVendedor && filial) return <Redirect to="/" />;

  return (
    <main className={classes.wrapper}>
      <section className={classes.content}>
        <img src={logo} alt="Loja do Mecanico" className={classes.logo} />
        <section className={classes.views}>
          <div
            className={
            `${classes.insideView}
              ${page === 1 && classes.pass}
              ${page === 2 && classes.filials}`
            }
          >
            <section className={classes.page}>
              <User />
            </section>
          </div>
        </section>
      </section>
    </main>
  );
};

Page.propTypes = {
  page: PropTypes.number.isRequired,
  user: PropTypes.instanceOf(Object),
  filial: PropTypes.instanceOf(Object),
};

Page.defaultProps = {
  user: null,
  filial: null,
};

const mapStateToProps = (state) => ({
  ...state.auth.login,
  ...state.main.app,
});

export default connect(mapStateToProps, {})(Page);
