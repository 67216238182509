/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResponsiveTable from '../../../shared/components/responsiveTable';
import useStyles from './styles';
import { findStatusPayment } from '../../../shared/utils/ordersHelpers';
import OrdersTableBody from '../_components/ordersTableBody/OrdersTableBody';

const Pedidos = ({
  orders,
  loading,
  setDeliverySelected,
}) => {
  const classes = useStyles();

  const {
    tableHead,
    tableRow,
    tableBody,
    tableCellHead,
    orderTable,
  } = classes;

  return (
    <ResponsiveTable striped className={orderTable}>
      <TableHead classes={{ root: tableHead }}>
        <TableRow classes={{ root: tableRow }}>
          <TableCell classes={{ root: tableCellHead }} component="th">
            Pedido
          </TableCell>
          <TableCell classes={{ root: tableCellHead }} component="th">
            Entrega
          </TableCell>
          <TableCell classes={{ root: tableCellHead }} component="th">
            Cliente
          </TableCell>
          <TableCell classes={{ root: tableCellHead }} component="th">
            Telefone
          </TableCell>
          <TableCell classes={{ root: tableCellHead }} component="th">
            Tracking
          </TableCell>
          <TableCell classes={{ root: tableCellHead }} component="th">
            Data do Pedido
          </TableCell>
          <TableCell classes={{ root: tableCellHead }} component="th">
            Valor
          </TableCell>
          <TableCell classes={{ root: tableCellHead }} component="th">
            Origem
          </TableCell>
          <TableCell classes={{ root: tableCellHead }} component="th">
            Vendedor
          </TableCell>
          <TableCell classes={{ root: tableCellHead }} component="th" />
        </TableRow>
      </TableHead>
      <TableBody classes={{ root: tableBody }}>

        {loading && (
          <TableRow>
            <TableCell colSpan={12} datatype="Mensagem" className="message">
              Buscando Pedidos
            </TableCell>
          </TableRow>
        )}

        {!loading && !orders.length && (
          <TableRow>
            <TableCell colSpan={12} datatype="Mensagem" className="message">
              Nenhum pedido foi encontrado
            </TableCell>
          </TableRow>
        )}

        {!loading && orders.map((item, index) => {
          const statusPayment = findStatusPayment(item.statusPagamento);
          return (
            <OrdersTableBody
              order={item}
              index={index}
              statusPayment={statusPayment}
              key={index}
              setDeliverySelected={setDeliverySelected}
            />
          );
        })}

      </TableBody>
    </ResponsiveTable>
  );
};

Pedidos.propTypes = {
  orders: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  setDeliverySelected: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.main.checkoutsAndOrders,
  loading: state.main.checkoutsAndOrders.loadings.order,
});

export default connect(mapStateToProps, {})(Pedidos);
