import React from 'react';
import { Box, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { push } from '../../../../history';
import { getClient } from '../../../services/authClient';
import { putMainAddressPFAction, putMainAddressPJAction } from '../../../../main/client/pages/addressesPage/actions';

const Location = ({ client, putMainAddressPF, putMainAddressPJ }) => {
  const {
    addressBox,
    locationBox,
    locationIcon,
    locationBtn,
  } = useStyles();
  const param = window.location.pathname.split('/');
  const isListAddressesPage = `${param[1]}/${param[2]}` === 'client-space/addresses';
  const isCheckout = !!param[2] && param[1] === 'checkout';

  const redirectClientAddress = async () => {
    if (!isListAddressesPage) {
      if (client.tipoPessoa === 'J') {
        const address = {
          logradouro: client.logradouro,
          cidade: client.cidade,
          bairro: client.bairro,
          numero: client.numero,
          estado: client.uf,
          bitPrincipal: client.bitPrincipal,
          bitInativo: client.bitInativo,
          idFilial: client.idEndereco,
          razaoSocial: client.nomeRazaoSocial,
          tipoPessoa: client.tipoPessoa,
          codCliente: client.codCliente,
          cnpj: client.cpfCnpj,
          confirmAddress: true,
        };

        await putMainAddressPJ(client.codCliente, address);
      } else {
        const address = {
          logradouro: client.logradouro,
          cidade: client.cidade,
          bairro: client.bairro,
          numero: client.numero,
          uf: client.uf,
        };

        await putMainAddressPF(client.idEndereco, address);
      }
    }
    await push('/client-space/addresses');
  };

  return (
    <Button
      className={locationBtn}
      onClick={client.confirmAddress && isCheckout ? redirectClientAddress : () => {}}
    >
      <Box className={locationBox}>
        <span className={locationIcon} />
        <Box className={addressBox}>
          <span>{`${client.logradouro || '-'}, ${client.numero || '-'}`}</span>
          <span>{`${client.cidade || '-'} - ${client.uf || '-'}`}</span>
        </Box>
      </Box>
    </Button>
  );
};

Location.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  putMainAddressPF: PropTypes.func.isRequired,
  putMainAddressPJ: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  let { client } = state.authClient;
  client = getClient() || client;

  return ({
    ...state.main.home,
    client,
  });
};

export default connect(mapStateToProps, {
  putMainAddressPF: putMainAddressPFAction,
  putMainAddressPJ: putMainAddressPJAction,
})(Location);
