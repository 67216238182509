import React, { useEffect, useState } from 'react';
import {
  useClearRefinements,
  useHierarchicalMenu,
  useNumericMenu,
  useRange,
  useRefinementList,
  useSortBy,
} from 'react-instantsearch';
import PropTypes from 'prop-types';
import { Box, Button, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import useStyles, { DrawerStyled as Drawer } from './styles';
import { handleCheckboxRefinements, handleSortBy } from '../../../utils/algolia';
// eslint-disable-next-line import/no-cycle
import { SortBy, CloseSearchProducts, CheckboxRefinement } from '../index';
import { CheckboxRefinementCustom } from '../filters';
import { searchByCategoryAction } from '../../../../main/checkout/actions';

const DrawerToFilters = ({
  children,
  user,
  customer,
  handleSameFilialCheckbox,
  indexFilialUser,
  hits,
  searchByCategory,
  categoryList,
}) => {
  const { setSameFilialCheckbox } = handleSameFilialCheckbox;
  const sortByOptions = handleSortBy();
  const checkboxList = handleCheckboxRefinements(user, customer);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const {
    borderHeader,
    clearAndClose,
    clearRefinements,
    close,
    filtersBox,
    filtersBtn,
    filtersIcon,
    filterBody,
    filterBox,
    filterBoxMain,
    filterGrid,
    filterHeader,
    sortAndCloseBtns,
    checkboxListBox,
  } = classes;

  useRefinementList({ attribute: 'technicalSpecs.voltage' });
  useRefinementList({ attribute: 'brand.name' });
  useRefinementList({ attribute: 'stockEstablishmentsAvailable' });
  useRange({ attribute: 'price.promotionalDiscountPercentage' });
  useNumericMenu({
    attribute: 'rankingInfo.margin',
    items: [
      { label: '0 ~ 5', end: 5 },
      { label: '5 ~ 10', start: 5, end: 10 },
      { label: '10 ~ 20', start: 10, end: 20 },
      { label: '+ 20', start: 20.01 },
    ],
  });
  useNumericMenu({
    attribute: `priceEstablishments.${indexFilialUser}.term`,
    items: [
      { label: 'R$ 0 ~ R$ 20,00', end: 20 },
      { label: 'R$ 20,00 ~ R$ 40,00', start: 20, end: 40 },
      { label: 'R$ 40,00 ~ R$ 220,00', start: 40, end: 220 },
      { label: 'R$ 220,00 ~ R$ 430,00', start: 220, end: 430 },
      { label: 'R$ 430,00 ~ R$ 940,00', start: 430, end: 940 },
      { label: 'R$ 940,00 ~ R$ 1.750,00', start: 940, end: 1750 },
      { label: 'R$ 1.750,00 ~ R$ 19.000,00', start: 1750, end: 19000 },
    ],
  });
  useHierarchicalMenu({
    attributes: ['categoryLevels.lvl0', 'categoryLevels.lvl1', 'categoryLevels.lvl2'],
  });
  useSortBy({ items: sortByOptions });
  const { canRefine, refine } = useClearRefinements();

  const toggleDrawer = (open) => {
    setIsOpen(open);
  };

  const handleClearRefinements = () => {
    setSameFilialCheckbox({
      stock: false,
      withdrawStore: false,
    });
    if (categoryList) {
      searchByCategory('', null);
    }
    refine();
  };

  useEffect(() => {
    handleClearRefinements();
  }, [!hits.length]);

  const handleFilters = () => (
    <Box className={filterBox}>
      <Box className={borderHeader}>
        <div className={filterHeader}>
          <span className={filtersIcon}>Filtros</span>
          <div className={clearAndClose}>
            <button
              type="button"
              className={clearRefinements}
              disabled={!canRefine}
              onClick={handleClearRefinements}
            >
              Limpar filtros
            </button>
            <IconButton
              onClick={() => toggleDrawer(false)}
              className={close}
            />
          </div>
        </div>
      </Box>
      <Box className={filterBody}>
        {children}
      </Box>
    </Box>
  );

  return (
    <Grid className={filterGrid}>
      <Box className={filterBoxMain}>
        <Box className={filtersBox}>
          <Button className={filtersBtn} onClick={() => toggleDrawer(true)} />
          <Box className={checkboxListBox}>
            {
              checkboxList.map((checkbox, index) => {
                if (checkbox.custom) {
                  return (
                    <CheckboxRefinementCustom
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={`${checkbox.attribute}-${index}`}
                      checkbox={checkbox}
                      handleSameFilialCheckbox={handleSameFilialCheckbox}
                    />
                  );
                }
                return (
                  <CheckboxRefinement
                    key={checkbox.attribute}
                    checkbox={checkbox}
                  />
                );
              })
            }
          </Box>
        </Box>
        <Box className={sortAndCloseBtns}>
          <SortBy />
          <CloseSearchProducts />
        </Box>
        <Drawer anchor="left" open={isOpen} onClose={() => toggleDrawer(false)}>
          {handleFilters()}
        </Drawer>
      </Box>
    </Grid>
  );
};

DrawerToFilters.defaultProps = {
  handleSameFilialCheckbox: null,
};

DrawerToFilters.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  handleSameFilialCheckbox: PropTypes.instanceOf(Object),
  indexFilialUser: PropTypes.number.isRequired,
  hits: PropTypes.instanceOf(Array).isRequired,
  categoryList: PropTypes.instanceOf(Array).isRequired,
  searchByCategory: PropTypes.func.isRequired,
};

const mapState = (state) => {
  const {
    categoryHierarchy,
  } = state.main.checkout.geral;

  return ({
    categoryList: categoryHierarchy,
  });
};

export default connect(mapState, {
  searchByCategory: searchByCategoryAction,
})(DrawerToFilters);
