export default {
  RESET_CHECKOUT: '@checkout/RESET_CHECKOUT',
  GET_CHECKOUT_REQUEST: '@checkout/GET_CHECKOUT_REQUEST',
  GET_CHECKOUT_SUCCESS: '@checkout/GET_CHECKOUT_SUCCESS',
  GET_CHECKOUT_FAIL: '@checkout/GET_CHECKOUT_FAIL',
  UPDATE_ITEM_REQUEST: '@checkout/UPDATE_ITEM_REQUEST',
  UPDATE_ITEM_SUCCESS: '@checkout/UPDATE_ITEM_SUCCESS',
  UPDATE_ITEM_FAIL: '@checkout/UPDATE_ITEM_FAIL',
  UPDATE_VALUE_ITEM_REQUEST: '@checkout/UPDATE_VALUE_ITEM_REQUEST',
  UPDATE_VALUE_ITEM_SUCCESS: '@checkout/UPDATE_VALUE_ITEM_SUCCESS',
  UPDATE_VALUE_ITEM_FAILURE: '@checkout/UPDATE_VALUE_ITEM_FAILURE',
  REMOVE_ITEM_REQUEST: '@checkout/REMOVE_ITEM_REQUEST',
  REMOVE_ITEM_SUCCESS: '@checkout/REMOVE_ITEM_SUCCESS',
  REMOVE_ITEM_FAIL: '@checkout/REMOVE_ITEM_FAIL',
  CHECKOUT_DISCOUNT_REQUEST: '@checkout/CHECKOUT_DISCOUNT_REQUEST',
  CHECKOUT_DISCOUNT_SUCCESS: '@checkout/CHECKOUT_DISCOUNT_SUCCESS',
  CHECKOUT_DISCOUNT_FAIL: '@checkout/CHECKOUT_DISCOUNT_FAIL',
  ITEM_DISCOUNT_REQUEST: '@checkout/ITEM_DISCOUNT_REQUEST',
  ITEM_DISCOUNT_SUCCESS: '@checkout/ITEM_DISCOUNT_SUCCESS',
  ITEM_DISCOUNT_FAIL: '@checkout/ITEM_DISCOUNT_FAIL',
  UPDATE_INFO_REQUEST: '@checkout/UPDATE_INFO_REQUEST',
  UPDATE_INFO_SUCCESS: '@checkout/UPDATE_INFO_SUCCESS',
  UPDATE_INFO_FAIL: '@checkout/UPDATE_INFO_FAIL',
  SUBMIT_ORDER_REQUEST: '@checkout/SUBMIT_ORDER_REQUEST',
  SUBMIT_ORDER_SUCCESS: '@checkout/SUBMIT_ORDER_SUCCESS',
  SUBMIT_ORDER_FAIL: '@checkout/SUBMIT_ORDER_FAIL',
  OPEN_DIALOG_COUPON: '@checkout/OPEN_DIALOG_COUPON',
  CLOSE_DIALOG_COUPON: '@checkout/CLOSE_DIALOG_COUPON',
  OPEN_DIALOG_DISCOUNT: '@checkout/OPEN_DIALOG_DISCOUNT',
  OPEN_DIALOG_DISCOUNT_OBS: '@checkout/OPEN_DIALOG_DISCOUNT_OBS',
  OPEN_DIALOG_DISCOUNT_OBS_DESCRIPTION: '@checkout/OPEN_DIALOG_DISCOUNT_OBS_DESCRIPTION',
  OPEN_DIALOG_DISCOUNT_REQUEST: '@checkout/OPEN_DIALOG_DISCOUNT_REQUEST',
  CLOSE_DIALOG_DISCOUNT: '@checkout/CLOSE_DIALOG_DISCOUNT',
  INSERT_COUPON_REQUEST: '@checkout/INSERT_COUPON_REQUEST',
  INSERT_COUPON_SUCCESS: '@checkout/INSERT_COUPON_SUCCESS',
  INSERT_COUPON_FAIL: '@checkout/INSERT_COUPON_FAIL',
  SHOW_ADD_INFO: '@checkout/SHOW_ADD_INFO',
  HIDE_ADD_INFO: '@checkout/HIDE_ADD_INFO',
  SHOW_INVALID_DIALOG: '@checkout/SHOW_INVALID_DIALOG',
  HIDE_INVALID_DIALOG: '@checkout/HIDE_INVALID_DIALOG',
  CHOSE_FREIGHT: '@checkout/CHOSE_FREIGHT',
  UNCHOSE_FREIGHT: '@checkout/UNCHOSE_FREIGHT',
  CHOSE_SCHEDULE: '@checkout/CHOSE_SCHEDULE',
  DATE_SCHEDULE: '@checkout/DATE_SCHEDULE',
  PDF_REQUEST: '@checkout/PDF_REQUEST',
  PDF_SUCCESS: '@checkout/PDF_SUCCESS',
  PDF_FAIL: '@checkout/PDF_FAIL',
  ADD_ALL_VOUCHERS_REQUEST: '@checkout/ADD_ALL_VOUCHERS_REQUEST',
  ADD_ALL_VOUCHERS_SUCCESS: '@checkout/ADD_ALL_VOUCHERS_SUCCESS',
  ADD_ALL_VOUCHERS_FAIL: '@checkout/ADD_ALL_VOUCHERS_FAIL',
  SHOW_ITEM: '@checkout/SHOW_ITEM',
  HIDE_ITEM: '@checkout/HIDE_ITEM',
  SHOW_ITEM_SEARCH: '@checkout/SHOW_ITEM_SEARCH',
  HIDE_ITEM_SEARCH: '@checkout/HIDE_ITEM_SEARCH',
  OPEN_CONFIRM: '@checkout/OPEN_CONFIRM',
  COUPON_VALE_LIST_LOAD: '@checkout/COUPON_VALE_LIST_LOAD',
  COUPON_VALE_LIST: '@checkout/COUPON_VALE_LIST',
  CLOSE_CONFIRM: '@checkout/CLOSE_CONFIRM',
  CONFIRM_FREIGHT: '@checkout/CONFIRM_FREIGHT',
  OPEN_CUSTOM_LOADING: '@checkout/OPEN_CUSTOM_LOADING',
  CLOSE_CUSTOM_LOADING: '@checkout/CLOSE_CUSTOM_LOADING',
  GET_CREDIT_AVAILABLE_FAIL: '@checkout/GET_CREDIT_AVAILABLE_FAIL',
  GET_TOTAL_COUPONS_AND_VOUCHERS_REQUEST: '@checkout/GET_TOTAL_COUPONS_AND_VOUCHERS_REQUEST',
  GET_TOTAL_COUPONS_AND_VOUCHERS_SUCCESS: '@checkout/GET_TOTAL_COUPONS_AND_VOUCHERS_SUCCESS',
  GET_TOTAL_COUPONS_AND_VOUCHERS_FAIL: '@checkout/GET_TOTAL_COUPONS_AND_VOUCHERS_FAIL',
  RESET_SEARCH: '@checkout/RESET_SEARCH',
  SEARCH_BY_CATEGORY: '@checkout/SEARCH_BY_CATEGORY',
  NEW_CHECKOUT_REQUEST: '@checkout/NEW_CHECKOUT_REQUEST',
  NEW_CHECKOUT_FAILURE: '@checkout/NEW_CHECKOUT_FAILURE',
  NEW_CHECKOUT_SUCCESS: '@checkout/NEW_CHECKOUT_SUCESS',
  CHECKOUT_LOADING: '@checkout/CHECKOUT_LOADING',
  OPEN_DIALOG_SCHEDULE: '@checkout/OPEN_DIALOG_SCHEDULE',
  CLOSE_DIALOG_SCHEDULE: '@checkout/CLOSE_DIALOG_SCHEDULE',
};
