import React, { useRef, useState } from 'react';
import { useSortBy } from 'react-instantsearch';
import {
  Box,
  Button,
  Popover,
} from '@material-ui/core';
import useStyles from './styles';
import { handleSortBy } from '../../../utils/algolia';

const SortBy = (props) => {
  const [open, setOpen] = useState(false);
  const sortByRef = useRef(null);
  const classes = useStyles();
  const {
    sortByBox,
    sortByBoxMain,
    sortByBtn,
    sortByIcon,
    sortByListBtn,
    sortByOption,
    sortByPopover,
  } = classes;

  const sortByOptions = handleSortBy();

  const { currentRefinement, options, refine } = useSortBy({
    ...props,
    items: sortByOptions,
  });

  const handleOpen = () => setOpen((prevOpen) => !prevOpen);

  const handleOnClick = (value) => {
    refine(value);
    handleOpen();
  };

  const verifyKey = (e) => {
    if (e.key === 'Escape') refine('ldm_products');
    handleOpen();
  };

  return (
    <Box className={sortByBoxMain}>
      <Button
        ref={sortByRef}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        className={sortByBtn}
      >
        <span className={sortByIcon} />
      </Button>
      <Popover
        classes={{ paper: sortByPopover }}
        open={open}
        anchorEl={sortByRef.current}
        onClose={(e) => verifyKey(e)}
        elevation={0}
      >
        <ul className={sortByBox}>
          {
            options.map(({ label, value }) => (
              <button
                type="button"
                className={sortByListBtn}
                onClick={() => handleOnClick(value)}
                key={value}
              >
                <li className={`${sortByOption} ${currentRefinement === value && 'selected'}`}>
                  {label}
                </li>
              </button>
            ))
          }
        </ul>
      </Popover>
    </Box>
  );
};

export default SortBy;
