import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { bool, func, number, oneOfType, string } from 'prop-types';
import React from 'react';

const CheckoutItemRemove = ({
  checkoutId, productId, onRemove, loading,
}) => {
  
  const handleRemove = () => {
    onRemove(checkoutId, productId);
  };

  return (
    <>
      <Box  alignItems="center" right={5} style={{marginRight:5}}>
        <Tooltip title="Remover produto do orçamento">
          <span>
            <IconButton
              size="small"
              onClick={handleRemove}
              disabled={loading}
            >
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </>
  );
};

CheckoutItemRemove.propTypes = {
  checkoutId: oneOfType([string, number]).isRequired,
  productId: number.isRequired,
  onRemove: func.isRequired,
  loading: bool.isRequired,
};

export default CheckoutItemRemove;
