/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useLayoutEffect, useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  IconButton,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Visibility, Search, DateRange } from '@material-ui/icons';
import DetailslistRecovery from './detailslistRecovery';
import CardBarNew from '../../../../shared/components/cardBarNew';
import ResponsiveTable from '../../../../shared/components/responsiveTable';
import useStyles from '../styles';
import {
  getCheckoutsAction,
  getCheckoutsDetailsAction,
  setCheckoutMetaAction,
  clearMetaAction,
  takeCheckoutAction,
  openFilterAction,
  closeFilterAction,
  getCheckoutByDocAction,
} from '../actions';

import { toMoney, toPhone } from '../../../../shared/utils/parsers';

const Vendedor = ({
  loading, checkouts, getCheckouts, setCheckoutMeta, getCheckoutsDetails,
  agendamentos, getCheckoutByDoc, checkoutsSearch, user,
}) => {
  const { results } = agendamentos;
  const [tradeDetails, setTradeDetails] = useState(false);
  const [docCliente, setDocCliente] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [nomeSocial, setNomeSocial] = useState('');
  const classes = useStyles();

  const searchResultsContainer = document.getElementById('idSearchResultsContainer');

  const recovery = user.permissoes.find((permission) => (
    permission.id === 1
  ));

  if (!recovery) {
    setLoadingSearch(true);
    window.location.href = process.env.REACT_APP_HOST_CATALAGO;
  }

  const handleBack = () => setTradeDetails(false);

  const handleToggleDetail = () => {
    setTradeDetails(!tradeDetails);
  };

  const handleDetail = (checkoutId) => {
    getCheckoutsDetails(checkoutId);
    handleToggleDetail();
  };

  const handleDetailSearch = async (checkoutId) => {
    setTradeDetails(true);
    getCheckoutsDetails(checkoutId);
  };

  const handleFilter = async () => {
    setLoadingSearch(true);
    setOpenSearch(true);
    await getCheckoutByDoc(docCliente);
    setLoadingSearch(false);
  };

  const handleCloseList = async () => {
    setOpenSearch(false);
  };

  useLayoutEffect(() => {
    if (!tradeDetails) {
      getCheckouts();
    } else {
      setTradeDetails(false);
    }
  }, [getCheckouts, results]);

  useEffect(() => {
    if (searchResultsContainer) {
      if (openSearch) {
        if (searchResultsContainer.style.display === 'none') {
          searchResultsContainer.style.display = 'flex';
        }
      } else {
        searchResultsContainer.style.display = 'none';
      }
    }
  }, [openSearch]);

  useEffect(() => {
    if (searchResultsContainer && !openSearch) searchResultsContainer.style.display = 'none'

    const paginaFull = document.getElementsByClassName('makeStyles-root-1');

    if (paginaFull[0]) {
      paginaFull[0].addEventListener('click', async () => handleCloseList());
    }
  }, [searchResultsContainer]);

  useEffect(() => {
    if (checkouts.length > 0) {
      setTradeDetails(true);
      getCheckoutsDetails(checkouts[0].checkoutId);
    } else {
      setTradeDetails(false);
    }
  }, [checkouts])

  if (tradeDetails) return (
    <>
      <div className={classes.divSearch}>
        <TextField
          id="idSearch"
          size="small"
          fullWidth
          variant="outlined"
          placeholder="Busque por E-mail, CPF ou CNPJ"
          align="right"
          value={docCliente}
          onChange={(e) => setDocCliente(e.target.value)}
          style={{ background: '#FFF' }}
          InputProps={{
            endAdornment: (
              <>
                <div className={classes.divSearchIconContainer}>
                  <IconButton onClick={handleFilter} disabled={docCliente === ''}>
                    <Search style={{ color: '#FFF' }} />
                  </IconButton>
                </div>
              </>
            ),
          }}
        />
        <div className={classes.searchResultsContainer} id="idSearchResultsContainer">
          <Grid container className={classes.searchGridContainer}>
            {(loadingSearch && docCliente !== '') ? <CircularProgress style={{ color: '#FA4616', marginTop: 10 }} size={50} />
              : checkoutsSearch[0] && checkoutsSearch.map((item) => (
                <Grid item xs={12} key={item.checkoutId}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    alignItems="center"
                    marginLeft="10px"
                    marginRight="20px"
                    mt={1}
                  >
                    <div className={classes.searchResultsBox1}>
                      <Typography variant="body2" className={classes.title}>
                        {item && `${item.name}`}
                      </Typography>
                      <Typography variant="caption">
                        <span className={classes.searchResultsDate} style={{ marginRight: 5 }}>
                          <DateRange style={{ color: '#7B7F82' }} size={20} />
                          {item
                          && `${moment(item.dataPedido).format('DD/MM/YYYY')} ${toMoney(item.valor)}`}
                        </span>
                      </Typography>
                    </div>
                    <div className={classes.searchResultsBox2}>
                      <Button
                        disableElevation
                        onClick={() => handleDetailSearch(item.checkoutId)}
                        className={classes.buttonDetailsOrçamento}
                        variant="contained"
                        startIcon={<Visibility style={{ color: '#ffff', fontSize: 14 }} />}
                      >
                        Detalhes
                      </Button>
                    </div>
                  </Box>
                </Grid>
              ))}
            {(!checkoutsSearch.length && !loadingSearch) && (
              <Grid item xs={12} className={classes.gridSemResultados}>
                <div>
                  Sem resultados
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </div>

      <DetailslistRecovery loading={loading} setTradeDetails={setTradeDetails} />
    </>
  );

  if (loading) return (<LinearProgress />);

  return (
    <div>
      <CardBarNew title="Home" handleBack={handleBack} tradeDetails={tradeDetails} setTradeDetails={setTradeDetails} />
      <div className={classes.divSearch}>
        <TextField
          id="idSearch"
          size="small"
          fullWidth
          variant="outlined"
          placeholder="Busque por E-mail, CPF ou CNPJ"
          align="right"
          value={docCliente}
          onChange={(e) => setDocCliente(e.target.value)}
          style={{ background: '#FFF' }}
          InputProps={{
            endAdornment: (
              <>
                <div className={classes.divSearchIconContainer}>
                  <IconButton onClick={handleFilter} disabled={docCliente === ''}>
                    <Search style={{ color: '#FFF' }} />
                  </IconButton>
                </div>
              </>
            ),
          }}
        />
        <div className={classes.searchResultsContainer} id="idSearchResultsContainer">
          <Grid container className={classes.searchGridContainer}>
            {(loadingSearch && docCliente !== '') ? <CircularProgress style={{ color: '#FA4616', marginTop: 10 }} size={50} />
              : checkoutsSearch[0] && checkoutsSearch.map((item) => (
                <Grid item xs={12} key={item.checkoutId}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    alignItems="center"
                    marginLeft="10px"
                    marginRight="20px"
                    mt={1}
                  >
                    <div className={classes.searchResultsBox1}>
                      <Typography variant="body2" className={classes.title}>
                        {item && `${item.name}`}
                      </Typography>
                      <Typography variant="caption">
                        <span className={classes.searchResultsDate} style={{ marginRight: 5 }}>
                          <DateRange style={{ color: '#7B7F82' }} size={20} />
                          {item
                          && `${moment(item.dataPedido).format('DD/MM/YYYY')} ${toMoney(item.valor)}`}
                        </span>
                      </Typography>
                    </div>
                    <div className={classes.searchResultsBox2}>
                      <Button
                        disableElevation
                        onClick={() => handleDetailSearch(item.checkoutId)}
                        className={classes.buttonDetailsOrçamento}
                        variant="contained"
                        startIcon={<Visibility style={{ color: '#ffff', fontSize: 14 }} />}
                      >
                        Detalhes
                      </Button>
                    </div>
                  </Box>
                </Grid>
              ))}
            {(!checkoutsSearch.length && !loadingSearch) && (
              <Grid item xs={12} className={classes.gridSemResultados}>
                <div>
                  Sem resultados
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <section style={{ marginTop: 7 }}>
        {loading && (<LinearProgress />)}
        {!tradeDetails
          && (
          <Paper elevation={1}>
            <ResponsiveTable pointer>
              <TableHead>
                <TableRow>
                  <TableCell component="th" align="left" className={classes.textsSilver}>
                    Nome/Razão Social
                  </TableCell>
                  <TableCell component="th" className={classes.textsSilver}>
                    Data
                  </TableCell>
                  <TableCell component="th" className={classes.textsSilver}>
                    Valor
                  </TableCell>
                  <TableCell component="th" className={classes.textsSilver}>
                    E-mail
                  </TableCell>
                  <TableCell component="th" className={classes.textsSilver}>
                    Telefone
                  </TableCell>
                  <TableCell component="th" className={classes.textsSilver}>
                    Ação
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {checkouts.map((item) => (
                  <TableRow
                    key={item.checkoutId}
                    onClick={({ currentTarget }) => setCheckoutMeta({
                      el: currentTarget,
                      checkoutId: item.checkoutId,
                    })}
                    hover
                  >
                    <TableCell datatype="Nome/Razão Social" align="left">
                      {item.nomeSocial || item.name}
                    </TableCell>

                    <TableCell datatype="Data" style={{ color: '#888E9D', fontSize: 13 }}>
                      {item.dataPedido}
                    </TableCell>

                    <TableCell datatype="Valor" style={{ color: '#888E9D' }} className={classes.clientCell}>
                      {item.valor !== '-' ? toMoney(item.valor) : '-' }
                    </TableCell>

                    <TableCell datatype="E-mail" style={{ color: '#888E9D' }}>
                      {item.email}
                    </TableCell>

                    <TableCell datatype="Telefone" style={{ color: '#888E9D' }}>
                      <Box whiteSpace="nowrap">
                        {item.ddd !== '-' ? toPhone(item.ddd + item.phone) : '-'}
                      </Box>
                    </TableCell>

                    <TableCell datatype="Detalhes">
                      <Button
                        disableElevation
                        disabled={item !== checkouts[0]}
                        onClick={() => handleDetail(item.checkoutId)}
                        className={classes.buttonDetailsOrçamento}
                        variant="contained"
                        startIcon={<Visibility style={{ color: '#ffff', fontSize: 14 }} />}
                      >
                        Detalhes
                      </Button>

                    </TableCell>
                  </TableRow>
                ))}

                {!checkouts.length && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Typography variant="body2">
                      {loading ? 'Buscando Orçamentos...' : 'Nenhum Orçamento Encontrado'}
                    </Typography>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </ResponsiveTable>
          </Paper>
          )}
        {tradeDetails && (
          <DetailslistRecovery loading={loading}  nome={nomeSocial}  setTradeDetails={setTradeDetails} />
        )}
      </section>
    </div>
  );
};
Vendedor.propTypes = {
  loading: PropTypes.bool.isRequired,
  checkouts: PropTypes.arrayOf(Object).isRequired,
  getCheckouts: PropTypes.func.isRequired,
  getCheckoutsDetails: PropTypes.func.isRequired,
  agendamentos: PropTypes.instanceOf(Object).isRequired,
  setCheckoutMeta: PropTypes.func.isRequired,
  getCheckoutByDoc: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};


const mapState = (state) => ({
  ...state.main.recovery.list,
  user: state.auth.login.user,
});

export default connect(mapState, {
  getCheckouts: getCheckoutsAction,
  getCheckoutsDetails: getCheckoutsDetailsAction,
  setCheckoutMeta: setCheckoutMetaAction,
  clearMeta: clearMetaAction,
  takeCheckout: takeCheckoutAction,
  openFilter: openFilterAction,
  closeFilter: closeFilterAction,
  getCheckoutByDoc: getCheckoutByDocAction,
})(Vendedor);
