import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: '#EEE',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: '380px',
    textAlign: 'center',
  },
  logo: {
    maxWidth: '80%',
    height: 38,
    margin: 16,
  },
  views: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
  },
  pass: {},
  filials: {},
  insideView: {
    width: '300%',
    display: 'flex',
    transition: 'all 480ms ease-out',
    '&$pass': {
      transform: 'translateX(-100%)',
    },
    '&$filials': {
      transform: 'translateX(-200%)',
    },
  },
  page: {
    minWidth: '100%',
    width: 0,
    padding: '4px',
  },
}));

export default useStyles;
