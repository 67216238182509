import creditCardType from 'credit-card-type';
import eloRegex from './eloRegex';

const getCardBrand = (num) => {
  const cardNum = num.replace(/\s/g, '');

  // Amex
  if (/^3[47][0-9]{13}$/.test(cardNum)) return 'amex';
  // Elo
  if (eloRegex.test(cardNum)) return 'elo';
  // BCGlobal
  if (/^(6541|6556)[0-9]{12}$/.test(cardNum)) return 'bcglobal';
  // Carte Blanche
  if (/^389[0-9]{11}$/.test(cardNum)) return 'carte-blanche';
  // Diners Club
  if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cardNum)) return 'diners-club';
  // Discover
  if (/^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/.test(cardNum)) return 'discover';
  // Insta Payment
  if (/^63[7-9][0-9]{13}$/.test(cardNum)) return 'mastercard';
  // JCB
  if (/^(?:2131|1800|35\d{3})\d{11}$/.test(cardNum)) return 'jcb';
  // KoreanLocal
  if (/^9[0-9]{15}$/.test(cardNum)) return 'koreanlocal';
  // Laser
  if (/^(6304|6706|6709|6771)[0-9]{12,15}$/.test(cardNum)) return 'laser';
  // Maestro
  if (/^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/.test(cardNum)) return 'maestro';
  // Mastercard
  if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(cardNum)) return 'mastercard';
  // Solo
  if (/^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/.test(cardNum)) return 'solo';
  // Switch
  if (/^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/.test(cardNum)) return 'switch';
  // Union Pay
  if (/^(62[0-9]{14,17})$/.test(cardNum)) return 'union-pay';
  // Visa
  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNum)) return 'visa';
  // Visa Master
  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNum)) return 'visa-master';
  // Voyager
  if (/^8699[0-9]{11}$/.test(cardNum)) return 'voyager';
  // HiperCard
  if (/^(384100|384140|384160|606282|637095|637568|60(?!11))/.test(cardNum)) return 'hipercard';
  // Electron
  if (/^(?:417500|4917\\d{2}|4913\\d{2})\\d{10}$/.test(cardNum)) return 'electron';
  // Disc
  if (/^(?:6011|650\\d)\\d{12}$/.test(cardNum)) return 'disc';
  // Bankcard
  if (/^56(10\\d\\d|022[1-5])\\d{10}$/.test(cardNum)) return 'bankcard';
  // Aura
  if (/^(50[0-9]{14})$/.test(cardNum)) return 'aura';

  // Generic validation
  const card = creditCardType(num);
  if (card.length) return card[0].type;
  return '';
};

export default getCardBrand;
