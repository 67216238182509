import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import { Add, DragHandle, Remove } from '@material-ui/icons';
import { toMoney } from '../../../../../shared/utils/parsers';
import useStyles from './styles';

const ItemAdvancedPaymentDialog = ({
  // eslint-disable-next-line max-len
  value, valueInstallment, valueRemainder, valueInstallmentRemainder, resume, valuetotal, valuetotalinstallment, discountCart,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.divContainerDialog}>
      <Box className={classes.divPaymentDialog}>
        <div className={classes.divValorDialog}>
          <Typography className={classes.textTitlePaymentDialog}>
            Total de Produto (
            {resume.quantityProducts}
            )
          </Typography>
          <div className={classes.divColorDialog}>
            <Typography className={classes.textTitlePaymentDialog}>
              <b>{valueInstallment}</b>
              {' '}
            </Typography>
          </div>
        </div>

        <Add className={classes.iconAddDialog} />

        <div className={classes.divValorDialog}>
          <Typography className={classes.textTitlePaymentDialog}>
          Frete
          </Typography>
          <div className={classes.divColorDialog}>
            <Typography className={classes.textTitlePaymentDialog}>
              {resume.freight === 0 ? (
                <span>
                  <b> Gratis</b>
                </span>
              ) : (
                <>
                  {' '}
                  <b>{`${toMoney(resume.freight)}`}</b>
                </>
              )}
            </Typography>
            {/* <Typography className={classes.textTitlePaymentDialog}>
              Impostos:
              {' '}
              <b>{toMoney(resume.totalTax)}</b>
            </Typography> */}
          </div>
        </div>

        <Remove className={classes.iconAddDialog} />

        <div className={classes.divValorDialog}>
          <Typography className={classes.textTitlePaymentDialog}>
          Desconto
          </Typography>
          <div className={classes.divColorDialog}>
            <Typography className={classes.textTitlePaymentDialog}>
                <>
                  <b>{discountCart}</b>
                </>
            </Typography>
          </div>
        </div>

        <DragHandle className={classes.iconEqualsDialog} />

        <div className={classes.divValorDialog}>
          <Typography className={classes.textTitlePaymentDialog}>Valor Total</Typography>
          <div className={classes.divColorDialog}>
            <Typography className={classes.textTitlePaymentDialog}>
              <b>{valuetotal}</b>
              {' '}
            </Typography>
          </div>
        </div>
      </Box>

      <Box className={classes.divBoxTotalDialog}>
        <Typography className={classes.textTitlePaymentDialog}>Valor Pendente</Typography>
        <div>
          <Typography className={classes.textTitlePaymentDialog}>
            <b>{valueRemainder}</b>
            {' '}
          </Typography>
        </div>
      </Box>
    </div>
  );
};

ItemAdvancedPaymentDialog.propTypes = {
  value: PropTypes.string.isRequired,
  valueInstallment: PropTypes.string.isRequired,
  valueRemainder: PropTypes.string.isRequired,
  valueInstallmentRemainder: PropTypes.string.isRequired,
  resume: PropTypes.instanceOf(Object).isRequired,
  valuetotal: PropTypes.string.isRequired,
  valuetotalinstallment: PropTypes.string.isRequired,
  discountCart: PropTypes.string.isRequired,
};
const mapState = (state) => {
  const {
    checkoutId, resume, payments, deniedPayments, orderId,
  } = state.main.checkout.geral.checkout;
  const { chosedFreight } = state.main.checkout.geral;

  return {
    ...state.main.checkout.advanced,
    payments,
    deniedPayments,
    resume,
    checkoutId,
    orderId,
    chosedFreight,
  };
};

export default connect(mapState, {
})(ItemAdvancedPaymentDialog);
