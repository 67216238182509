/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  change,
  Field,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import * as QRCode from 'qrcode.react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { IconButton, Button, DialogActions, TextField, Checkbox } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from '../../styles';
import Tooltip from '@material-ui/core/Tooltip';
import { InputCurrencyRender, SelectRender } from '../../../../shared/fields';
import { phoneMask, toMoney } from '../../../../shared/utils/parsers';
import { required } from '../../../../shared/fields/validate';
import { generateLinkAction, searchInstallmentsAction } from './actions';
import axios from 'axios';
import CheckoutItemDetails from '../checkoutItem/CheckoutItemDetails';
import apiService from '../../../../shared/services/apiService';

const CreditCardQrCode = ({
  opened, close, value, paymentForm, checkoutId, installments, resendLink,
  remainder, remainderInstallment, dispatch, searchInstallments, listInstallments,
  isLoadingInstallment, generateLink, checkout, customerId, zipCode, name, user, paymentItem, snack,
}) => {
  const classes = useStyles();
  const installmentProps = listInstallments.find((i) => i.quantity === installments) || {};
  const [returnMaquininha, setReturnMaquininha] = useState('');
  const [loadingTef, setLoadingTef] = useState(false);
  const { REACT_APP_TOKEN } = process.env;
  const [callInstallment, setCallInstallment] = useState(true);

  const [sendWhatsapp, setSendWhatsapp] = useState(false);
  const [sendSms, setSendSms] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [loadingSendLink, setLoadingSendLink] = useState(false);

  const [userName, setUserName] = useState(checkout?.customer?.nomeSocial ?? checkout?.customer?.name);
  const [userPhone, setUserPhone] = useState(phoneMask(checkout.customer.phone));
  const [userEmail, setUserEmail] = useState(checkout.customer.email);

  const changeSendWhatsapp = () => {
    setSendWhatsapp(!sendWhatsapp);
  }

  const changeSendSms = () => {
    setSendSms(!sendSms);
  }

  const changeSendEmail = () => {
    setSendEmail(!sendEmail);
  }
 
  const code = btoa(
    JSON.stringify({
      checkoutId,
      value: paymentForm === 'C' && installmentProps.quantity === 1
        ? installmentProps.value
        : value,
      installments: paymentForm === 'C' ? installments : null,
      paymentForm,
    }),
  );


  useEffect(() => {
     dispatch(change('payment/creditCardQrCode', 'value', remainderInstallment));
     if(remainder <= 0.01 && remainderInstallment <= 0.01 && !resendLink) {
        close();
      }
  }, [remainder, remainderInstallment, dispatch, opened]);

  const handleTef = async () => {
    if(opened) {
      setLoadingTef(true);
      try {
        const response = await axios.get(`https://hub.lojadomecanico.com.br/tef-api/api/tef/${ user.idVendedor}`, {
          headers: {
            "x-access-token": `Bearer ${REACT_APP_TOKEN}`
          }
        })
        if(response.data.máquina != "Sem máquina vinculada") {
          const returnPost = await axios.post(`https://hub.lojadomecanico.com.br/tef-api/api/tef/sale`, {
            caixaId: user.idVendedor.toString(),
            checkoutId: checkoutId.toString(),
            valor: paymentForm === 'C' && installmentProps.quantity === 1
            ? installmentProps.value
            : value,
            installments:installments.toString(),
            paymentFor: paymentForm
          },
          {
            headers: { "x-access-token": `Bearer ${REACT_APP_TOKEN}` },
          }).catch(error => console.log(error));
          if(returnPost.data) {
            setReturnMaquininha('Pagamento inserido com sucesso!')
          }
        setLoadingTef(false);
      } else {
        setReturnMaquininha(response.data.máquina);
        setLoadingTef(false);
      }
      } catch (error) {
        setLoadingTef(false);
        setReturnMaquininha("Erro ao Registrar Tef");
      }
     }
  }

  useEffect(() => {
    if (opened) {
      setSendEmail(false);
      setSendSms(false);
      setSendWhatsapp(false);
      setLoadingSendLink(false);
      searchInstallments(
        remainderInstallment,
        checkout.discountCart,
        checkout.resume.remainder,
        checkout.resume.remainderInstallment,
      );
      dispatch(change('payment/creditCardQrCode', 'installments', 1));
    }
  }, [
    dispatch, checkout.resume.remainder, checkout.resume.remainderInstallment,
    searchInstallments, remainderInstallment, checkout.discountCart, opened,
  ]);

  const generate = async () => {
    setLoadingSendLink(true);
    const ddd = userPhone.substring(1, 3);
    const iniciotel = userPhone.substring(5, 10);
    const fimtel = userPhone.substring(11, 15);
    const telefone = `${iniciotel}${fimtel}`;
    await generateLink({
      idCheckout: checkoutId,
      value: resendLink ? paymentItem.total : (installmentProps.quantity === 1 ? installmentProps.value : value),
      idClient: customerId,
      installments: resendLink ? paymentItem.installmentQuantity : installments,
      cep: zipCode,
      name,
      resendLink,
      paymentItem,
      installmentValue: resendLink ? paymentItem.installmentValue : (installmentProps.value || (value / installments)),
      meiosEnvioLinkRemoto: [sendWhatsapp ? "WHATSAPP" : "", sendSms ? "SMS" : "", sendEmail ? "EMAIL" : ""],
      destinatarioLinkRemoto: {
        name: userName,
        contacts: [ sendSms ? {type: "SMS", areaCode: ddd, number: telefone } : {}, sendEmail ? { type: "EMAIL",  email: userEmail} : {}, sendWhatsapp ? { type: "WHATSAPP", areaCode: ddd, number: telefone } : {}]
    }
    })
    setLoadingSendLink(false);
  };

  const getInfo = async () => {
    const { data: { results } } = await apiService.get(`/pagamentos/pagamento-remoto/buscar-link-remoto/${paymentItem.paymentId}`);
    navigator.clipboard.writeText(
      `${results?.records?.uri}`,
    );
    snack.enqueueSnackbar("Link remoto copiado com sucesso!", { variant: 'success' });
  };

  const paymentOptions = [{
    value: 'C',
    name: 'Crédito',
  }];

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={opened}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <Box position="relative">
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Box>

        <form>
          <DialogTitle id="form-dialog-title">
            Link Remoto
          </DialogTitle>

          <DialogContent>

      
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField
                  style={{width:"100%"}}
                  name="clienteName"
                  label="Cliente"
                  size="small"
                  onChange={(e) => setUserName(e.target.value)}
                  value={userName}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  style={{width:"100%"}}
                  name="clienteEmail"
                  label="E-mail"
                  size="small"
                  onChange={(e) => setUserEmail(e.target.value)}
                  value={userEmail}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  style={{width:"100%"}}
                  name="clientePhone"
                  label="Telefone"
                  size="small"
                  onChange={(e) => setUserPhone(phoneMask(e.target.value))}
                  value={phoneMask(userPhone)}
                />
              </Grid>


              <Grid item xs={6}>
                  <TextField
                    style={{width:"100%"}}
                    label="Forma de Pagamento *"
                    size="small"
                    readOnly
                    value="Crédito"
                  />
              </Grid>

              <Grid item xs={6}>
                {resendLink === false ? (
                  <Field
                    name="value"
                    component={InputCurrencyRender}
                    label="Valor *"
                    validate={required}
                    readOnly={resendLink}
                    onChange={(v) => {
                      dispatch(change('payment/creditCardQrCode', 'installments', 1));
                      searchInstallments(
                        v,
                        checkout.discountCart,
                        checkout.resume.remainder,
                        checkout.resume.remainderInstallment,
                      );
                    }}
                    max={
                      paymentForm !== 'C'
                        ? remainderInstallment
                        : remainderInstallment
                    }
                  />
                ) : (
                  <TextField
                    style={{width:"100%"}}
                    name="clienteValor"
                    label="Valor"
                    size="small"
                    readOnly
                    value={toMoney(paymentItem?.total)}
                  />
                )}
              </Grid>
              {isLoadingInstallment && 'Carregando...'}
                <Grid item xs={12}>
                  {resendLink === false ? (
                    <Field
                      name="installments"
                      label="Parcelamento *"
                      readOnly={resendLink}
                      options={listInstallments}
                      component={SelectRender}
                      validate={[required]}
                      model={{
                        id: 'quantity',
                        name: 'label',
                      }}
                    />
                  ) : (
                    <TextField
                      style={{width:"100%"}}
                      name="clienteValorParcelamento"
                      label="Parcelamento"
                      size="small"
                      readOnly
                      value={`${paymentItem?.installmentQuantity} - R$ ${toMoney(paymentItem?.installmentValue)}`}
                    />
                  )}
                </Grid>

              <Grid item xs={12} style={{marginTop: '30px', marginBottom: '10px'}}>Opções de Envio:</Grid>

                <Grid item xs={4} style={{fontSize: '10px', fontWeight: 'bold', fontSize: '10px', backgroundColor: '#F8F9FA', padding: 0, height: 'max-content', maxWidth: '32%', marginRight: '2%'}}>
                
                    <Checkbox
                      checked={sendWhatsapp}
                      color="primary"
                      style={{color: '#FF5225'}}
                      onChange={changeSendWhatsapp}
                    /> ENVIAR POR WHATSAPP
                
                </Grid>

                <Grid item xs={4} style={{fontSize: '10px', fontWeight: 'bold', fontSize: '10px', backgroundColor: '#F8F9FA', padding: 0, height: 'max-content', maxWidth: '32%', marginRight: '2%'}}>
                
                    <Checkbox
                      checked={sendSms}
                      color="primary"
                      style={{color: '#FF5225'}}
                      onChange={changeSendSms}
                    /> ENVIAR POR SMS
                
                </Grid>

                <Grid item xs={4} style={{fontSize: '10px', fontWeight: 'bold', fontSize: '10px', backgroundColor: '#F8F9FA', padding: 0, height: 'max-content', maxWidth: '32%'}}>
                
                    <Checkbox
                      checked={sendEmail}
                      color="primary"
                      style={{color: '#FF5225'}}
                      onChange={changeSendEmail}
                    /> ENVIAR POR E-MAIL
                
                </Grid>
          
            </Grid>

            <Box pb={2} />
            
          </DialogContent>




            
          <DialogActions style={{justifyContent:'center'}}>

          {resendLink && (
            <Box display="flex" justifyContent="center" p={1}>
              <Tooltip
                title={paymentForm === 'D'
                  ? 'Disponivel somente na forma de pagamento Crédito'
                  : 'COPIAR LINK REMOTO'}
              >
                <span>
                  <Button
                    color="default"
                    fullWidth
                    className={classes.buttonTef}
                    disabled={paymentForm === 'D'}
                    onClick={getInfo}
                    style={{borderColor: '#FF5225', color: '#FF5225'}}
                  >
                    {`COPIAR LINK REMOTO`}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          )}

          <Box display="flex" justifyContent="center" p={1}>
              <Tooltip
                title={(!userPhone && (sendSms || sendWhatsapp))
                  ? 'Preencha o telefone'
                  : (!userEmail && sendEmail) ? 'Preencha o e-mail' : 'ENVIAR LINK REMOTO'}
              >
                <span>
                  <Button
                    color="default"
                    fullWidth
                    className={classes.buttonTef}
                    disabled={paymentForm === 'D' || loadingSendLink || (resendLink && !sendEmail && !sendSms && !sendWhatsapp) || (!userPhone && sendSms) || (!userPhone && sendWhatsapp) || (!userEmail && sendEmail)}
                    onClick={generate}
                    style={(loadingSendLink || (resendLink && !sendEmail && !sendSms && !sendWhatsapp) || (!userPhone && sendSms) || (!userPhone && sendWhatsapp) || (!userEmail && sendEmail)) ? {background: '#E7E7E7', border: 0} : {borderColor: '#FF5225', color: '#FFFFFF', background: '#FF5225'} }
                  >
                    {`${(resendLink? 'Reenviar' : (sendEmail || sendSms || sendWhatsapp) ? ' Enviar' : 'Gerar')} Link${paymentForm !== 'PX' ? ' Remoto' : ''}`}
                  </Button>
                </span>
              </Tooltip>
          </Box>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

CreditCardQrCode.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  close: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  resendLink: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  installments: PropTypes.number,
  paymentForm: PropTypes.string,
  checkout: PropTypes.instanceOf(Object).isRequired,
  checkoutId: PropTypes.number.isRequired,
  paymentItem: PropTypes.instanceOf(Object).isRequired,
  remainder: PropTypes.number.isRequired,
  remainderInstallment: PropTypes.number.isRequired,
  searchInstallments: PropTypes.func.isRequired,
  snack: PropTypes.instanceOf(Object),
  generateLink: PropTypes.func.isRequired,
  listInstallments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isLoadingInstallment: PropTypes.bool.isRequired,
  customerId: PropTypes.number.isRequired,
  zipCode: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  name: PropTypes.string,
};

CreditCardQrCode.defaultProps = {
  value: null,
  installments: null,
  paymentForm: 'C',
  name: null,
  snack: null,
  sendEmail: false,
  sendSms: false,
  sendWhatsapp: false,
};

const seletor = formValueSelector('payment/creditCardQrCode');
const mapState = (state) => {
  const { snack } = state.main.app;
  const { config } = state.main.app;
  const { checkout } = state.main.checkout.geral;
  const { resendLink } = state.main.checkout.payment;
  const { resume, checkoutId, discountCart } = checkout;
  const { payment } = state.main.checkout;
  const { customer: { customerId, name } } = checkout;
  const { customer: { address: { zipCode } } } = checkout;
  return {
    user: state.auth.login.user,
    checkoutId,
    customerId,
    zipCode,
    name,
    checkout,
    snack,
    resendLink,
    discountCart,
    ...payment,
    pixEnabled: !!config.bitPix,
    remainder: +resume.remainder,
    remainderInstallment: +resume.remainderInstallment,
    installments: seletor(state, 'installments'),
    value: seletor(state, 'value'),
    paymentForm: seletor(state, 'paymentForm'),
    initialValues: {
      value: resume.remainderInstallment,
      installments: 1,
      paymentForm: 'C',
    },
  };
};

export default connect(mapState, {
  searchInstallments: searchInstallmentsAction,
  generateLink: generateLinkAction,
})(reduxForm({
  form: 'payment/creditCardQrCode',
  enableReinitialize: true,
})(CreditCardQrCode));
