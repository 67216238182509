import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    display: 'flex',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  wrapper: {
    display: 'flex',
  },
  button: {
    border: 0,
    height: 32,
    width: 132,
    backgroundColor: '#EDEDED',
    color: 'rgba(0,0,0,.64)',
    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',
    transition: 'all 180ms ease',
    '&:hover': {
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
    [breakpoints.only('sm')]: {
      width: 137,
    },
  },
  buttonLeft: {
    borderRadius: '16px 0 0 16px',
  },
  buttonRight: {
    borderRadius: '0 16px 16px 0',
  },
  buttonActive: {
    backgroundColor: palette.primary.main,
    color: '#FFF',
  },
  homeButton: {
    whiteSpace: 'nowrap',
    borderRadius: 32,
    marginTop: 8,
    height: 32,
    [breakpoints.up('sm')]: {
      marginLeft: 8,
      marginTop: 0,
    },
  },
}));

export default useStyles;
