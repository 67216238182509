import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  cardGrid: {
    maxWidth: 1200,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  dividerContent: {
    marginTop: 12,
    marginBottom: 12,
  },
}));

export default useStyles;
