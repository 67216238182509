const APP_KEY = 'PDV-LOJA-DO-MECANICO';

export const persistFreight = ({ freightType, checkoutId }) => sessionStorage.setItem(
  `${APP_KEY}-${checkoutId}`,
  freightType,
);

export const getFreight = (checkoutId) => sessionStorage.getItem(
  `${APP_KEY}-${checkoutId}`,
);

export const removeFreight = (checkoutId) => sessionStorage.removeItem(
  `${APP_KEY}-${checkoutId}`,
);
