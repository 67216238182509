import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import * as PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import {
  ContentGuarantees,
  RadioGroupGuarantees
} from '../../../../shared/components/algolia';

const CheckoutItemDialogGuarantee = ({
  showModalGuarantee,
  handleCloseMoreInfoGuarantee,
  garantias,
  handleGarantia,
  valueRadioButton
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog open={showModalGuarantee} onClose={handleCloseMoreInfoGuarantee} maxWidth="sm">
        <DialogTitle className={classes.dialogGuaranteeTitle}>
          <Icon className={classes.iconGuarantee}></Icon>
          <IconButton onClick={handleCloseMoreInfoGuarantee}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <ContentGuarantees />
        </DialogContent>

        <DialogActions className={classes.guaranteeActions}>
          <RadioGroupGuarantees 
            guaranteesOptions={garantias} 
            valueRadioChecked={valueRadioButton}
            handleGuaranteeId={handleGarantia}
          />
          <Button
            className={classes.continueBtn}
            onClick={handleCloseMoreInfoGuarantee}
            color="primary"
            variant="contained"
            fullWidth
            disableElevation
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CheckoutItemDialogGuarantee.defaultProps = {

};

CheckoutItemDialogGuarantee.propTypes = {
  valueRadioButton: PropTypes.number,	
  showModalGuarantee: PropTypes.bool.isRequired,
  handleCloseMoreInfoGuarantee: PropTypes.func.isRequired,
  garantias: PropTypes.instanceOf(Object),
  handleGarantia: PropTypes.func,
};

export default CheckoutItemDialogGuarantee;
