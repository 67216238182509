import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { closeDialogPixAction } from './actions';
import AdvancedPaymentFormPix from './AdvancedPaymentFormPix';
import { getConditionsAction } from '../actions';
import ItemAdvancedPaymentDialog from './ItemAdvancedPaymentDialog';
import { toMoney } from '../../../../../shared/utils/parsers';

const DialogAdvancedPayment = ({
  closeDialogPix, getConditions, conditions, resume, dialogPixOpened, checkout, selectedItem
}) => {
  useEffect(() => {
    if (!conditions) {
      // getConditions();
    }
  }, [getConditions, conditions]);

  return (

    <Dialog
      open={dialogPixOpened}
      onClose={closeDialogPix}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box position="relative">
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={closeDialogPix}>
            <Close />
          </IconButton>
        </Box>

        <DialogTitle id="alert-dialog-title">Forma de Pagamento</DialogTitle>

        <div>
          <ItemAdvancedPaymentDialog
            value={`${toMoney(resume.subTotalVista)}`}
            label="Total"
            valueInstallment={`${toMoney(resume.subTotalPrazo)}`}
            valueRemainder={`${toMoney(resume.remainder)}`}
            valueInstallmentRemainder={`${toMoney(resume.remainderInstallment)}`}
            frete={`${toMoney(resume.freight)}`}
            valuetotal={`${toMoney(resume.total)}`}
            valuetotalinstallment={`${toMoney(resume.totalInstallment)}`}
            discountCart={`${toMoney(resume.maxDiscount)}`}
          />
        </div>

        <DialogContent>
          <AdvancedPaymentFormPix checkout={checkout} selectedItem={selectedItem} />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

DialogAdvancedPayment.propTypes = {
  closeDialogPix: PropTypes.func.isRequired,
  getConditions: PropTypes.func.isRequired,
  dialogPixOpened: PropTypes.bool.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  resume: PropTypes.instanceOf(Object).isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
};

DialogAdvancedPayment.defaultProps = {
  conditions: null,
  selectedItem: [],
};

const mapState = (state) => {
  const {
    checkoutId, resume, payments, deniedPayments, orderId,
  } = state.main.checkout.geral.checkout;
  const { chosedFreight } = state.main.checkout.geral;

  return {
    ...state.main.checkout.advanced,
    payments,
    deniedPayments,
    resume,
    checkoutId,
    orderId,
    chosedFreight,
    conditions: state.main.checkout.payment.conditions,
  };
};

export default connect(mapState, {
  closeDialogPix: closeDialogPixAction,
  getConditions: getConditionsAction,
})(DialogAdvancedPayment);
