import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import SweetScroll from 'sweet-scroll';

import { connect } from 'react-redux';
import { toCPF, toCNPJ, toMoney } from '../../utils/parsers';
import useStyles from './styles';

function GradientLinearProgress(entregas) {
	const tracking = entregas.entregas;
  
	const stopsItems = [];
	const interval = 80 / (tracking.length - 1);

	const gap = tracking.length < 5 ? 15 : 5;
	let lastColor = '';

	tracking.forEach((trackingItem, index) => {
		const color = trackingItem.corTracking;
		let offset = index * interval + gap;

		if (index === tracking.length - 1) {
			offset = 100 - gap;
		}

		if (lastColor !== '' && lastColor !== color) {
			stopsItems.push(<stop key={index} offset={`${offset}%`} stopColor={lastColor} />);
		}

		stopsItems.push(<stop key={index} offset={`${offset}%`} stopColor={color} />);
		lastColor = color;
	});

	return (
		<React.Fragment>
			<svg height={8} width="100%">
				<defs>
					<linearGradient id="gradientTracking" x1="0%" y1="0%" x2="100%" y2="0%">
						{stopsItems}
					</linearGradient>
				</defs>
				<rect width="100%" height="100%" fill="url(#gradientTracking)" style={{ margin: '0 10px' }} />
			</svg>
		</React.Fragment>
	);
}

const OrderDetailsCaixa = ({
  delivery, drawer, width, index, orderId
}) => {
  const classes = useStyles();
  // eslint-disable-next-line no-nested-ternary
  const containerRef = useRef(null);
  const { current } = containerRef;
  const [showArrowLeft, setShowArrowLeft] = useState(false);
  const [showArrowRight, setShowArrowRight] = useState(false);
  const [maxScroll, setMaxScroll] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (current) {
        const { scrollWidth, scrollLeft, clientWidth } = current;
        setMaxScroll(scrollWidth - clientWidth - 56);
        setShowArrowLeft(
          scrollWidth > clientWidth && scrollLeft > 48,
        );
        setShowArrowRight(
          scrollWidth > clientWidth && scrollLeft <= maxScroll,
        );
      }
    }, 250);
  }, [
    current,
    setShowArrowLeft,
    setMaxScroll,
    maxScroll,
    setShowArrowRight,
    drawer,
    width,
  ]);
  const scroller = new SweetScroll({ }, current);

  const onScrollRigth = () => {
    scroller.toLeft(
      width > 600
        ? current.scrollLeft + 300
        : current.scrollLeft + (width - 22),
    );
  };

  const onScrollLeft = () => {
    scroller.toLeft(
      width > 600
        ? current.scrollLeft - 300
        : current.scrollLeft - (width - 22),
    );
  };

  const onScroll = () => {
    const { scrollWidth, scrollLeft, clientWidth } = current;
    setShowArrowLeft(
      scrollWidth > clientWidth && scrollLeft > 0,
    );
    setShowArrowRight(
      scrollWidth > clientWidth && scrollLeft < maxScroll,
    );
    setMaxScroll(scrollWidth - clientWidth - 56);
  };

	const getButtonText = (trackingStatus) => {
		switch (trackingStatus) {
			case 'NFCE EMITIDA':
				return 'Cupom Fiscal (NFCE)';
			case 'DEVOLUÇÃO PARCIAL':
			case 'DEVOLUÇÃO TOTAL':
				return 'Nota de devolução (NFDE)';
			default:
				return '';
		}
	};

  const handleNotaFiscal = (anexo) => window.open(anexo, '_blank');

  const getQuantityItems = (itens) => itens.reduce((acc, item) => acc + item.quantidade, 0);

  const getDocument = () => {
    if (delivery.documentoNF) {
      if (delivery.documentoNF.length > 11) {
        return `CNPJ: ${toCNPJ(delivery.documentoNF)}`;
      } else {
        return `CPF: ${toCPF(delivery.documentoNF)}`;
      }
    } else {
      return ''
    }
  }

  return (
    <> 
      <div className={classes.deliveryTesouraria}>
        <div style={{marginBottom: 10, marginTop: 10,}}>
          <Grid container sm={12} style={{marginBottom: 24}}>
            <Grid item sm={6}>
              <Typography variant="h5">
                <bold>{delivery.nomeCliente}</bold>
              </Typography>
              <Typography variant="subtitle2">
                <bold>{getDocument()}</bold>
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Box display="flex" justifyContent="end" sx={{ gap: 24 }}>
                <Box minWidth={'30%'}>
                  <Typography variant="subtitle2">
                    <bold>Vendedor</bold>
                  </Typography>
                  <Typography variant="subtitle2">
                    <bold>{delivery.nomeVendedor}</bold>
                  </Typography>
                </Box>
                <Box minWidth={'30%'}>
                  <Typography variant="subtitle2">
                    <bold>Data/hora da Venda</bold>
                  </Typography>
                  <Typography variant="subtitle2">
                    <bold>{delivery.dataHoraPedido}</bold>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container className={classes.orderData}>
            <Typography className={classes.deliveryInfoShippingNumberTesouraria} variant="caption">
              <bold>Entrega {index + 1}</bold>
            </Typography>
            <Box className={classes.deliveryInfoContainerTesouraria}>
              <Typography className={classes.deliveryInfoShippingNumberTesouraria} variant="caption">
                <bold>Pedido: {orderId}</bold>
              </Typography>
              <Typography className={classes.deliveryInfoShippingNumberTesouraria} variant="caption">
                <bold>Total: {toMoney(delivery.total)}</bold>
              </Typography>
            </Box>
          </Grid>
          <Grid container>
            <Typography className={classes.deliveryInfoTesouraria} variant="caption">
              com <bold>{getQuantityItems(delivery.itens)}</bold> produto{getQuantityItems(delivery.itens) > 1 && 's'}
            </Typography>
            <Typography className={classes.deliveryInfoTesouraria} variant="body2">
              - Vendido e Entregue por <bold className={classes.ldmBoldTesouraria}>LojaDoMecanico</bold>
            </Typography>
          </Grid>

        </div>
        {delivery && (
          <div className={classes.deliveryContainerTesouraria}>
            {showArrowLeft && (
              <IconButton
                size="small"
                className={`${classes.navigateArrowTesouraria} marginRight`}
                onClick={onScrollLeft}
              >
                <ArrowBack />
              </IconButton>
            )}

            <div
              className={classes.deliveryItemsContainerTesouraria}
              ref={containerRef}
              onScroll={onScroll}
            >
              <section className={classes.deliveryItemsTesouraria}>
                {delivery.itens.map((item, index) => (
                  <article key={`delivery-${index + 1}`} className={classes.itemTesouraria}>
                    <img src={`https://img.lojadomecanico.com.br/IMAGENS/${item.foto}`} alt="" />

                    <Box ml={3} width={200}>
                      <Typography style={{fontSize: '0.675rem'}} component="h2" variant="body2">
                        {item.nomeProduto}
                      </Typography>

                      <Typography style={{fontSize: '0.65rem'}} component="h2" variant="body2">
                        {`
                          ${item.quantidade} Unidade${item.quantidade > 1 ? 's' : ''}
                          - ${toMoney(item.total)} 
                        `}
                      </Typography>
                    
                    </Box>
                  </article>
                ))}
              </section>
            </div>

            {showArrowRight && (
              <IconButton
                size="small"
                className={`${classes.navigateArrowTesouraria} marginLeft`}
                onClick={onScrollRigth}
              >
                <ArrowForward />
              </IconButton>
            )}
          </div>
        )}

        {delivery.mktplaceIn && delivery.mktplaceIn.idSeller && (
          <Box textAlign="end">
            {'Vendido e entregue por: '}
            <span className={classes.companyNameTesouraria}>{delivery.mktplaceIn.companyName}</span>
          </Box>
        )}

        <Box>
          <GradientLinearProgress entregas={delivery.tracking} />
          <div className={classes.textTrackingDivTesouraria}>
            {delivery.tracking.map((tracking) => (
              <div key={tracking.data} className={classes.textTrackingContentTesouraria}>
                <div className={classes.textsTrackingTesouraria}>
                  <p className={classes.textTrackingTesouraria} style={{color: tracking.corTracking}}>{tracking.status}</p>
                  <p className={classes.textTrackingTesouraria} style={{color: tracking.corTracking}}>
                    {tracking.data ?? tracking.dataHoraPedido}
                  </p>
                </div>
                {tracking.anexo && (
                  <div className={classes.buttonTrackingContentTesouraria}>
                    <Button className={classes.buttonTrackingTesouraria} onClick={() => handleNotaFiscal(tracking.anexo)} color="default" variant="outlined">
                      {getButtonText(tracking.status)}
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Box>
      </div>
      <Divider />
    </>
  );
};

OrderDetailsCaixa.defaultProps = {
  isFullWidth: false,
};

OrderDetailsCaixa.propTypes = {
  delivery: PropTypes.instanceOf(Object).isRequired,
  orderId: PropTypes.number,
  index: PropTypes.number,
  isFullWidth: PropTypes.bool,
  drawer: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
};

const mapState = (state) => state.main.app;

export default connect(mapState)(OrderDetailsCaixa);
