export default {
  GET_VENDEDORES_REQUEST: '@sales/GET_VENDEDORES_REQUEST',
  GET_VENDEDORES_SUCCESS: '@sales/GET_VENDEDORES_SUCCESS',
  GET_VENDEDORES_FAILURE: '@sales/GET_VENDEDORES_FAILURE',
  GET_DETAILS_VENDEDORES_REQUEST: '@sales/GET_DETAILS_VENDEDORES_REQUEST',
  GET_DETAILS_VENDEDORES_SUCCESS: '@sales/GET_DETAILS_VENDEDORES_SUCCESS',
  GET_DETAILS_VENDEDORES_FAILURE: '@sales/GET_DETAILS_VENDEDORES_FAILURE',
  POST_VENDEDORES_REQUEST: '@sales/POST_VENDEDORES_REQUEST',
  POST_VENDEDORES_SUCCESS: '@sales/POST_VENDEDORES_SUCCESS',
  POST_VENDEDORES_FAILURE: '@sales/POST_VENDEDORES_FAILURE',
  PUT_VENDEDORES_REQUEST: '@sales/PUT_VENDEDORES_REQUEST',
  PUT_VENDEDORES_SUCCESS: '@sales/PUT_VENDEDORES_SUCCESS',
  PUT_VENDEDORES_FAILURE: '@sales/PUT_VENDEDORES_FAILURE',

  GET_VENDEDORES_INATIVOS_REQUEST: '@sales/GET_VENDEDORES_INATIVOS_REQUEST',
  GET_VENDEDORES_INATIVOS_SUCCESS: '@sales/GET_VENDEDORES_INATIVOS_SUCCESS',
  GET_VENDEDORES_INATIVOS_FAILURE: '@sales/GET_VENDEDORES_INATIVOS_FAILURE',

  GET_SUPERVISORES_REQUEST: '@sales/GET_SUPERVISORES_REQUEST',
  GET_SUPERVISORES_SUCCESS: '@sales/GET_SUPERVISORES_SUCCESS',
  GET_SUPERVISORES_FAILURE: '@sales/GET_SUPERVISORES_FAILURE',

  GET_DETAILS_SUPERVISORES_REQUEST: '@sales/GET_DETAILS_SUPERVISORES_REQUEST',
  GET_DETAILS_SUPERVISORES_SUCCESS: '@sales/GET_DETAILS_SUPERVISORES_SUCCESS',
  GET_DETAILS_SUPERVISORES_FAILURE: '@sales/GET_DETAILS_SUPERVISORES_FAILURE',

  PUT_SUPERVISORES_REQUEST: '@sales/PUT_SUPERVISORES_REQUEST',
  PUT_SUPERVISORES_SUCCESS: '@sales/PUT_SUPERVISORES_SUCCESS',
  PUT_SUPERVISORES_FAILURE: '@sales/PUT_SUPERVISORES_FAILURE',

  GET_UNIDADE_DE_NEGOCIO_REQUEST: '@sales/GET_UNIDADE_DE_NEGOCIO_REQUEST',
  GET_UNIDADE_DE_NEGOCIO_SUCCESS: '@recovery/GET_UNIDADE_DE_NEGOCIO_SUCCESS',
  GET_UNIDADE_DE_NEGOCIO_FAILURE: '@recovery/GET_UNIDADE_DE_NEGOCIO_FAILURE',

  GET_ESTABELECIMENTOS_REQUEST: '@sales/GET_ESTABELECIMENTOS_REQUEST',
  GET_ESTABELECIMENTOS_SUCCESS: '@recovery/GET_ESTABELECIMENTOS_SUCCESS',
  GET_ESTABELECIMENTOS_FAILURE: '@recovery/GET_ESTABELECIMENTOS_FAILURE',

  GET_CANAL_DE_VENDAS_REQUEST: '@sales/GET_CANAL_DE_VENDAS_REQUEST',
  GET_CANAL_DE_VENDAS_SUCCESS: '@recovery/GET_CANAL_DE_VENDAS_SUCCESS',
  GET_CANAL_DE_VENDAS_FAILURE: '@recovery/GET_CANAL_DE_VENDAS_FAILURE',
  POST_CANAL_DE_VENDAS_REQUEST: '@sales/POST_CANAL_DE_VENDAS_REQUEST',

  GET_PERMISSOES_REQUEST: '@sales/GET_PERMISSOES_REQUEST',
  GET_PERMISSOES_SUCCESS: '@sales/GET_PERMISSOES_SUCCESS',
  GET_PERMISSOES_FAILURE: '@sales/GET_PERMISSOES_FAILURE',

  GET_CARGOS_REQUEST: '@sales/GET_CARGOS_REQUEST',
  GET_CARGOS_SUCCESS: '@sales/GET_CARGOS_SUCCESS',
  GET_CARGOS_FAILURE: '@sales/GET_CARGOS_FAILURE',
  GET_CARGOS_FILTER_REQUEST: '@sales/GET_CARGOS_FILTER_REQUEST',
  GET_CARGOS_FILTER_SUCCESS: '@sales/GET_CARGOS_FILTER_SUCCESS',
  GET_CARGOS_FILTER_FAILURE: '@sales/GET_CARGOS_FILTER_FAILURE',
  POST_CARGOS_REQUEST: '@sales/POST_CARGOS_REQUEST',
  POST_CARGOS_SUCCESS: '@sales/POST_CARGOS_SUCCESS',
  POST_CARGOS_FAILURE: '@sales/POST_CARGOS_FAILURE',
  PUT_CARGOS_REQUEST: '@sales/PUT_CARGOS_REQUEST',
  PUT_CARGOS_SUCCESS: '@sales/PUT_CARGOS_SUCCESS',
  PUT_CARGOS_FAILURE: '@sales/PUT_CARGOS_FAILURE',

  GET_NIVEIS_REQUEST: '@sales/GET_NIVEIS_REQUEST',
  GET_NIVEIS_SUCCESS: '@sales/GET_NIVEIS_SUCCESS',
  GET_NIVEIS_FAILURE: '@sales/GET_NIVEIS_FAILURE',

  GET_PARAMETRIZACAO_REQUEST: '@sales/GET_PARAMETRIZACAO_REQUEST',
  GET_PARAMETRIZACAO_SUCCESS: '@sales/GET_PARAMETRIZACAO_SUCCESS',
  GET_PARAMETRIZACAO_FAILURE: '@sales/GET_PARAMETRIZACAO_FAILURE',
  GET_DETAILS_PARAMETRIZACAO_REQUEST: '@sales/GET_DETAILS_PARAMETRIZACAO_REQUEST',
  GET_DETAILS_PARAMETRIZACAO_SUCCESS: '@sales/GET_DETAILS_PARAMETRIZACAO_SUCCESS',
  GET_DETAILS_PARAMETRIZACAO_FAILURE: '@sales/GET_DETAILS_PARAMETRIZACAO_FAILURE',
  POST_PARAMETRIZACAO_REQUEST: '@sales/POST_PARAMETRIZACAO_REQUEST',
  POST_PARAMETRIZACAO_SUCCESS: '@sales/POST_PARAMETRIZACAO_SUCCESS',
  POST_PARAMETRIZACAO_FAILURE: '@sales/POST_PARAMETRIZACAO_FAILURE',
  PUT_PARAMETRIZACAO_REQUEST: '@sales/PUT_PARAMETRIZACAO_REQUEST',
  PUT_PARAMETRIZACAO_SUCCESS: '@sales/PUT_PARAMETRIZACAO_SUCCESS',
  PUT_PARAMETRIZACAO_FAILURE: '@sales/PUT_PARAMETRIZACAO_FAILURE',

};
