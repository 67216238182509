import React from 'react';
import { useNumericMenu } from 'react-instantsearch';
import PropTypes from 'prop-types';
import useStyles from './styles';

const NumericMenu = ({ refinement }, props) => {
  const classes = useStyles();
  const { attribute, ranges } = refinement;
  const { items, refine } = useNumericMenu({
    ...props,
    attribute,
    items: ranges,
  });

  return (
    <ul className={classes.radioBox}>
      {items.map((item) => {
        const {
          value,
          label,
          isRefined,
        } = item;

        return (
          <li key={label}>
            <input
              type="radio"
              name={label}
              checked={isRefined}
              value={value}
              onChange={() => refine(value)}
            />
            <span>{label}</span>
          </li>
        );
      })}
    </ul>
  );
};

NumericMenu.propTypes = {
  refinement: PropTypes.instanceOf(Object).isRequired,
};

export default NumericMenu;
