const discountListTypes = {
  GET_DISCOUNT_REQUEST: '@discountList/GET_DISCOUNT_REQUEST',
  GET_DISCOUNT_SUCCESS: '@discountList/GET_DISCOUNT_SUCCESS',
  GET_DISCOUNT_FAIL: '@discountList/GET_DISCOUNT_FAIL',
  UPDATE_DISCOUNT_REQUEST: '@discountList/UPDATE_DISCOUNT_REQUEST',
  UPDATE_DISCOUNT_SUCCESS: '@discountList/UPDATE_DISCOUNT_SUCCESS',
  UPDATE_DISCOUNT_FAIL: '@discountList/UPDATE_DISCOUNT_FAIL',
  PDF_ORCAMENTOS_REQUEST: '@discountList/PDF_ORCAMENTOS_REQUEST',
  PDF_ORCAMENTOS_SUCCESS: '@discountList/PDF_ORCAMENTOS_SUCCESS',
  PDF_ORCAMENTOS_FAILURE: '@discountList/PDF_ORCAMENTOS_FAILURE',
  OPEN: '@discountList/OPEN',
  CLOSE: '@discountList/CLOSE',
};

export default discountListTypes;
