import { Button } from '@material-ui/core';
import React from 'react';
import useStyles from './style';

const ButtonInfo = ({
  // eslint-disable-next-line react/prop-types
  children, color, colorHover, ...rest
}) => {
  const classes = useStyles({
    color,
    colorHover,
  });
  return (
    <Button
      variant="contained"
      disableElevation
      className={classes.root}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonInfo;
