import React from 'react';
import PropTypes from 'prop-types';
import { Icon, TableCell } from '@material-ui/core';
import useStyles from './styles';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const TableCellHeaderOrdination = ({
  reference,
  column,
  ordination,
  handleOrdination,
  colSpan,
  component,
  children,
}) => {
	const classes = useStyles();

  return (
    <TableCell
      onClick={() => handleOrdination(reference)}
      colSpan={colSpan}
      className={classes.tableHeadCellOrdination}
      component={component}
    >
      {children}
      {column === reference && (
        <Icon className={classes.iconTransition} >
          {ordination === 'Desc' ? <ArrowUpward /> : <ArrowDownward />}
        </Icon>
      )}
      {column !== reference && (
        <svg className={`MuiSvgIcon-root ${classes.iconTransitionHover}`} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
        </svg>
      )}
    </TableCell>
  );
}
TableCellHeaderOrdination.propTypes = {
  handleOrdination: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  column: PropTypes.string,
  ordination: PropTypes.string,
  colSpan: PropTypes.number,
  component: PropTypes.string,
  children: PropTypes.node,
};

export default TableCellHeaderOrdination;
