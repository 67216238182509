import React from 'react';
import * as PropTypes from 'prop-types';
import { CardContent } from '@material-ui/core';
import CardMui from '@material-ui/core/Card';
import useStyles from './styles';
import { CardHeader } from './_components';

const Card = ({
  children,
  title,
  subtitle,
  icon,
  manyAccounts,
}) => {
  const {
    paper,
    cardContent,
  } = useStyles();

  return (
    <CardMui classes={{ root: `${paper} ${manyAccounts && 'cardCustom'}` }}>
      <CardHeader title={title} subtitle={subtitle} icon={icon} />
      <CardContent className={cardContent}>
        {children}
      </CardContent>
    </CardMui>
  );
};

Card.defaultProps = {
  subtitle: null,
  manyAccounts: false,
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  manyAccounts: PropTypes.bool,
};

export default Card;
