/* eslint-disable max-len */
import { getFormValues, reset } from 'redux-form';
import moment from 'moment';
import types from './types';
import apiService from '../../shared/services/apiService';
import {
  getUnidadeDeNegocio, setCanalDeVendas, setFilialVendedor, setUnidadeDeNegocio,
} from '../../shared/services/app';
import resolveError from '../../shared/utils/resolveError';
import {
  getPdf,
} from '../../shared/repositories/checkoutRepository';

export const setPageAction = ({ page }) => ({
  type: types.SET_PAGE,
  payload: { page },
});

export const getPdfOrcamentoAction = (checkoutId) => async (dispatch) => {
  dispatch({ type: types.PDF_ORCAMENTOS_REQUEST });
  try {
    const data = await getPdf(checkoutId);
    const file = new Blob(
      [data],
      { type: 'application/pdf' },
    );
    window.open(URL.createObjectURL(file));
    dispatch({ type: types.PDF_ORCAMENTOS_SUCCESS });
  } catch (_e) {
    dispatch({ type: types.PDF_ORCAMENTOS_FAILURE });
  }
};

export const toggleFilterAction = ({ filterOpened }) => ({
  type: types.TOGGLE_FILTER,
  payload: { filterOpened },
});
export const getPdfPedidosAction = (orderId) => async (dispatch) => {
  dispatch({ type: types.PDF_PEDIDO_REQUEST });
  try {
    const { data } = await apiService.get(`relatorios/pedidos/${orderId}/detalhes`, {
      responseType: 'blob',
    });

    const file = new Blob(
      [data],
      { type: 'application/pdf' },
    );

    window.open(URL.createObjectURL(file));
    dispatch({ type: types.PDF_PEDIDO_SUCCESS });
  } catch (_e) {
    dispatch({ type: types.PDF_PEDIDO_FAILURE });
  }
};

export const getOrcamentosAction = (filterDataInicio, filterDataFim, args = {}) => async (dispatch, getState) => {
  dispatch({ type: types.ORCAMENTOS_REQUEST });
  try {
    const { user: { idVendedor } } = getState().auth.login;
    const { checkoutsPagination: { page, rpp } } = getState().main.checkoutsAndOrders;
    const filters = getFormValues('checkoutsAndOrders/filter/checkout')(getState());

    const {
      data: { meta, results: checkouts },
    } = await apiService.get(`orcamentos/vendedor/${idVendedor}`, {
      params: {
        ...filters,
        rpp: args.rpp || rpp,
        page: args.rpp ? 1 : args.page || page,
        dataInicio: (moment(filterDataInicio, 'DD/MM/YYYY') || moment().subtract(1, 'month')).format('DD/MM/YYYY'),
        dataFim: (moment(filterDataFim, 'DD/MM/YYYY') || moment()).format('DD/MM/YYYY'),
      },
    });

    const res = await apiService.get(`/vendedores/${idVendedor}`);
    const { data: { results: r } } = res;

    setFilialVendedor(r.idFilial);
    setUnidadeDeNegocio(r.idUnidadeDeNegocio);
    setCanalDeVendas(r.idCanalDeVendas);

    const checkoutsPagination = {
      page: meta.page,
      pageCount: meta.totalpages,
      size: meta.size || 0,
      checkouts: 10,
      rpp: args.rpp || rpp,
    };

    dispatch({
      type: types.ORCAMENTOS_SUCCESS,
      payload: {
        checkouts,
        checkoutsPagination,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(
      resolveError(e),
      { variant: 'error' },
    );
    dispatch({ type: types.ORCAMENTOS_FAILURE });
  }
};

export const getPedidosAction = (filterDataInicio, filterDataFim, isTracking, args = {}) => async (dispatch, getState) => {
  dispatch({ type: types.PEDIDO_REQUEST });
  try {
    const { user: { idVendedor, idCanalDeVendas, idCargo } } = getState().auth.login;
    const { ordersPagination: { rpp, page } } = getState().main.checkoutsAndOrders;
    const filters = getFormValues('checkoutsAndOrders/filter/order')(getState());
    const unidadeNegocio = getUnidadeDeNegocio();
    const dataInicioFormatada = moment(filterDataInicio, 'DD-MM-YYYY').format('DD-MM-YYYY');
    const dataFimFormatada = moment(filterDataFim, 'DD-MM-YYYY').format('DD-MM-YYYY');
    const tracking = filters && (filters.pontosTracking && filters.pontosTracking.toString());
    const isSupervisor = [2, 7, 8, 15, 18].includes(idCargo);

    const {
      data: { meta, results: orders },
    } = await apiService.get(`pedidos/vendedor/${idVendedor}`, {
      params: {
        ...filters,
        idCargo,
        idCanalVendas: isSupervisor ? filters.idCanalVendas : filters.idCanalVendas || idCanalDeVendas,
        rpp: args.rpp || rpp,
        page: args.rpp ? 1 : args.page || page,
        dataInicio: dataInicioFormatada,
        dataFim: dataFimFormatada,
        tracking: !!isTracking,
        idUnidadeNegocio: unidadeNegocio,
        pontosTracking: tracking,
      },
    });

    const ordersPagination = {
      page: meta.page,
      pageCount: meta.totalpages,
      size: meta.size || 0,
      checkouts: 10,
      rpp: args.rpp || rpp,
    };

    dispatch({
      type: types.PEDIDO_SUCCESS,
      payload: {
        orders,
        ordersPagination,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(
      resolveError(e),
      { variant: 'error' },
    );
    dispatch({ type: types.PEDIDO_FAILURE });
  }
};

export const resetFiltersAction = (pageType) => async (dispatch, getState) => {
  try {
    dispatch(reset(`checkoutsAndOrders/filter/${pageType}`));
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(
      resolveError(e),
      { variant: 'error' },
    );
    dispatch({ type: types.RESET_FILTERS_FAILURE });
  }
};

export const getDeliveryDetailsAction = (deliveryId) => async (dispatch, getState) => {
  dispatch({ type: types.DELIVERY_DETAILS_REQUEST });
  try {
    const defaultError = 'Consulta indisponível, tente novamente!';

    if (!deliveryId) throw new Error(defaultError);

    const { data, message } = await apiService.get(`tracking-detalhado/entrega/${deliveryId}`);

    if (message) throw new Error(message);
    if (!data.length) throw new Error(defaultError);

    dispatch({
      type: types.DELIVERY_DETAILS_SUCCESS,
      payload: {
        deliveryDetails: data,
      },
    });
  } catch (e) {
    dispatch({ type: types.DELIVERY_DETAILS_FAILURE });
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
  }
};

export const resetDeliveryDetailsAction = () => ({
  type: types.RESET_DELIVERY_DETAILS,
});
