/* istanbul ignore file */

import { instanceOf } from 'prop-types';
import React, { useEffect } from 'react';

const ClearCache = ({ children }) => {
  const refreshCacheAndReload = () => {
    // Service worker cache should be cleared with caches.delete()
    if (caches) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  useEffect(() => {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = process.env.REACT_APP_VERSION;

        const shouldForceRefresh = latestVersion > currentVersion;
        if (shouldForceRefresh) {
          refreshCacheAndReload();
        }
      });
  }, []);

  return (
    <>
      { children }
    </>
  );
};

ClearCache.propTypes = {
  children: instanceOf(Object).isRequired,
};

export default ClearCache;
