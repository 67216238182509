export default {
  GET_FUNCIONALIDADES_REQUEST: '@devAcess/GET_FUNCIONALIDADES_REQUEST',
  GET_FUNCIONALIDADES_SUCCESS: '@devAcess/GET_FUNCIONALIDADES_SUCCESS',
  GET_FUNCIONALIDADES_FAILURE: '@devAcess/GET_FUNCIONALIDADES_FAILURE',

  PUT_FUNCIONALIDADES_REQUEST: '@devAcess/PUT_FUNCIONALIDADES_REQUEST',
  PUT_FUNCIONALIDADES_SUCCESS: '@devAcess/PUT_FUNCIONALIDADES_SUCCESS',
  PUT_FUNCIONALIDADES_FAILURE: '@devAcess/PUT_FUNCIONALIDADES_FAILURE',

};
