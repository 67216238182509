import { reset } from 'redux-form';
import discountListTypes from './types';
import apiService from '../../../shared/services/apiService';
import {
  getPdf,
} from '../../../shared/repositories/checkoutRepository';

export const getPdfOrcamentoAction = (checkoutId) => async (dispatch) => {
  dispatch({ type: discountListTypes.PDF_ORCAMENTOS_REQUEST });
  try {
    const data = await getPdf(checkoutId);
    const file = new Blob(
      [data],
      { type: 'application/pdf' },
    );
    window.open(URL.createObjectURL(file));
    dispatch({ type: discountListTypes.PDF_ORCAMENTOS_SUCCESS });
  } catch (_e) {
    dispatch({ type: discountListTypes.PDF_ORCAMENTOS_FAILURE });
  }
};

export const openAction = (action, selected) => (dispatch) => {
  dispatch(reset('discount/dialog'));
  dispatch({
    type: discountListTypes.OPEN,
    payload: { action, selected },
  });
};

export const closeAction = () => ({ type: discountListTypes.CLOSE });

export const getListDiscountAction = ({ page = 1, idUser, rpp }) => async (dispatch, getState) => {
  try {
    dispatch({ type: discountListTypes.GET_DISCOUNT_REQUEST });
    const { data: { meta, results } } = await apiService.get(`/solicitacao-desconto/gestor/${idUser}/solicitacoes-para-analise`, {
      // params: {
      //   rpp,
      //   page,
      // },
    });

    dispatch({
      type: discountListTypes.GET_DISCOUNT_SUCCESS,
      payload: {
        discounts: results,
        totalPage: meta.totalpages,
        currentPage: page,
        count: meta.size || 0,
        rpp,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
    dispatch({ type: discountListTypes.GET_DISCOUNT_FAIL });
  }
};

export const getListDiscountHistoryAction = ({ page = 1, idUser, idCheckout, rpp }) => async (dispatch, getState) => {
  try {
    dispatch({ type: discountListTypes.GET_DISCOUNT_REQUEST });
    const { data: { meta, results } } = await apiService.get(`/solicitacao-desconto/solicitacoes-por-orcamento/checkoutId/${idCheckout}`, {
      params: {
        rpp,
        page,
      },
    });

    dispatch({
      type: discountListTypes.GET_DISCOUNT_SUCCESS,
      payload: {
        discounts: results,
        totalPage: meta.totalpages,
        currentPage: page,
        count: meta.size || 0,
        rpp,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
    dispatch({ type: discountListTypes.GET_DISCOUNT_FAIL });
  }
};

export const updateStatusDiscountAction = ({
  idDiscount, idUser, reason, action, rpp,
}) => async (dispatch, getState) => {
  const { snack } = getState().main.app;
  dispatch({ type: discountListTypes.UPDATE_DISCOUNT_REQUEST });
  try {
    await apiService.put(`/solicitacao-desconto/liberar-reprovar`, {
      idSolicitacao: idDiscount,
      idVendedor: idUser,
      motivo: reason,
      acao: action,
    });

    dispatch({ type: discountListTypes.UPDATE_DISCOUNT_SUCCESS });
    snack.enqueueSnackbar(
      `Desconto ${action === 1 ? 'aprovado' : 'reprovado'} com sucesso`,
      { variant: 'success' },
    );

    dispatch(getListDiscountAction({ page: 1, idUser, rpp }));
  } catch (e) {
    dispatch({ type: discountListTypes.UPDATE_DISCOUNT_FAIL });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};
