import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import moment from 'moment';
import {
  Button, Typography, Box, Hidden, Paper, Tooltip,
  ButtonGroup, IconButton, useMediaQuery,
  useTheme, Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';
import { Add, KeyboardArrowUp } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import {connect, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import CardBar from '../../shared/components/cardBar';
import useStyles from './styles';
import {
  AdditionalInfo, ResumeOrderInfo, CustomerInfo, AddressInfo,
} from '../../shared/components/info';
import ButtonInfo from '../../shared/components/info/buttonInfo/ButtonInfo';
import { getUnidadeDeNegocio } from '../../shared/services/app';
import rolePermition from '../../shared/utils/rolePermition';
import ListRequestsPage from '../requests/list/Page';
import {
  getCheckoutAction, resetCheckoutAction, saveExtraInfoAction, hideAddInfoAction,
  hideInvalidDialogAction, chooseFreightAction, getPdfAction, sendPdfEmailAction,
  openConfirmDialogAction, closeConfirmDialogAction, checkDiscountOfferAction,
  getTotalCouponsAndVouchersAction,
  chooseScheduleAction,
} from './actions';
import TotalResumeOrderInfo from '../../shared/components/info/resumeOrderInfo/totalResumeOrderInfo/totalResumeOrderInfo';
import { toMoney } from '../../shared/utils/parsers';
import {
  Freight, Payment, InvalidDialog, CheckoutItems,
} from './_components';
import CheckoutConfirmDialog from './_components/checkoutConfirmDialog/CheckoutConfirmDialog';
import { checkDiscountOfferRequestAction } from './actions';
import {
  Close
} from '@material-ui/icons';
import getInvalidOrderMessage from '../../shared/utils/getInvalidOrderMessage';
import apiService from '../../shared/services/apiService';
import {setClient} from "../../shared/services/authClient";
import {Types} from "../authClient/login";
import TypesCheckout from "./types";
import { getFreight, removeFreight } from '../../shared/services/checkoutService';
import DatePickerSchedule from './_components/datePickerSchedule/DatePickerSchedule';
import {push} from "../../history";
import {putMainAddressPFAction, putMainAddressPJAction} from "../client/pages/addressesPage/actions";

const CheckoutPage = ({
  match, getCheckout, checkout, loading, saving, drawer, resetCheckout,
  saveExtraInfo, mainFilial, invalidDialog, hideAddInfo, forceAddInfo,
  hideInvalidDialog, chooseFreight, chosedFreight, getPdf, sendPdfEmail,
  openConfirmDialog, closeConfirmDialog, checkDiscountOffer, user, checkDiscountOfferRequest,
  observacaoDesconto, printing, message, snack, getTotalCouponsVouchers,
  totalCouponsVouchers, client, putMainAddressPF, putMainAddressPJ, chosedSchedule, chooseSchedule, dialogSchedule
}) => {
  const { idCheckout } = match.params;
  const classes = useStyles({ drawerIsOpened: drawer, loading });
  const { customer, resume, payments } = checkout;
  const { address } = customer;
  const containerRef = useRef(null);
  const { current } = containerRef;
  const [arrowScroll, setArrowScroll] = useState(0);
  const [notSeller, setNotSeller] = useState(false);
  const [couponCount, setCouponCount] = useState(0);
  const isInvalidOrder = useMemo(() => {
    let document = '';
    if (checkout.resume) document = customer.document.replace(/\D/, '');
    return getInvalidOrderMessage({ checkout, document, loading, user });
  }, [checkout, loading, customer]);

  const [dateSelected, setDateSelected] = useState(new Date());
  const handleCloseDialogSchedule = () => {
    removeFreight(idCheckout);
    dispatch({type: TypesCheckout.UNCHOSE_FREIGHT});

    getCheckout({
      idCheckout,
      startRequest: true
    });

    snack.enqueueSnackbar('Escolha um frete para continuar', { variant: 'error' });
    chooseSchedule(false);

    dispatch({
      type: TypesCheckout.CLOSE_DIALOG_SCHEDULE
    });
  };

  const handleOpenDialogSchedule = () => {
    dispatch({
      type: TypesCheckout.OPEN_DIALOG_SCHEDULE
    });
  }

  const freight = getFreight(idCheckout);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkout.checkoutId) return;

    if (freight === 'entregaAgendada') {
      if (checkout.dataEntregaAgendadaEscolhida) {
        dispatch({
          type: TypesCheckout.CLOSE_DIALOG_SCHEDULE
        });
      }
    } else {
      chooseSchedule(false);
    }
  }, [checkout, freight, chosedSchedule]);

  const [productReferenceId, setProductReferenceId] = useState(0);
  const [endScroll, setEndScroll] = useState(false);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const [observacaoSolicitacaoDesconto, setObservacaoSolicitacaoDesconto] = useState([false]);
  const [messageDiscountDenied, setMessageDiscountMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { REACT_APP_HOST_CATALAGO } = process.env;

  if (checkout.itemsFilials && productReferenceId === 0) {
    if (checkout?.itemsFilials[0]?.items) {
      setProductReferenceId(checkout.itemsFilials[0].items[0].productId);
    }
  }

  useEffect(() => {
    const verOrcamentos = user.permissoes.find((permission) => (
      permission.id === 10
    ));
    if (checkout.resume?.idSeller && (checkout.resume?.idSeller != user.idVendedor && !verOrcamentos)) {
      setNotSeller(true);
      snack.enqueueSnackbar("O orçamento só pode ser acessado pelo vendedor que o criou", { variant: 'error' });
      setTimeout(() => {
        window.open('/', '_parent');
      }, 2000);
    }

    if (!rolePermition(user.idCargo)) {
      setNotSeller(true);
      window.open(`${process.env.REACT_APP_HOST_CATALAGO}`, '_parent');
    }
  }, [checkout]);

  const requestDiscount  = async () => {
    let percentPermission = 10.2;
    if (getUnidadeDeNegocio() > 1) {
      percentPermission = 0;
    }
    let produtoComDesconto = false;
    if (checkout?.itemsFilials !== undefined) {
      for (let i = 0; i < checkout.itemsFilials.length; i++) {
        if (checkout.itemsFilials[i].items !== undefined) {
          for (let r = 0; r < checkout.itemsFilials[i].items.length; r++) {
            let poncent = (checkout.itemsFilials[i].items[r].discount*100)/(checkout.itemsFilials[i].items[r].totalOnTime);
            if (poncent > percentPermission) {
              produtoComDesconto = true;
            }
          }
        }
      }
    }

    if (checkout.discountCart <= percentPermission && !produtoComDesconto) {
      openConfirmDialog();
    } else {
      if (await checkDiscountOffer()) {
        openConfirmDialog();
      }
    }
  };

  const handleClose = async () => {
    setOpenModal(false);
  };

  useEffect(() => {
    closeConfirmDialog();
    resetCheckout();
    getCheckout({ idCheckout, startRequest: true, calculateTax: false });
  }, [getCheckout, idCheckout, resetCheckout, closeConfirmDialog]);

  useEffect(() => {
    obsRefresh();
  }, [checkout]);

  const obsRefresh  = async () => {
    validarProdutoDesconto();
    if (observacaoSolicitacaoDesconto) {
      await checkDiscountOfferRequest();
    }
  }

  const validarProdutoDesconto = () => {
    let percentPermission = 10.2;
    if (getUnidadeDeNegocio() > 1) {
      percentPermission = 0;
    }

    let produtoComDesconto = false;
    if (checkout?.itemsFilials !== undefined) {
      for (let i = 0; i < checkout.itemsFilials.length; i++) {
        if (checkout.itemsFilials[i].items !== undefined) {
          for (let r = 0; r < checkout.itemsFilials[i].items.length; r++) {
            let poncent = (checkout.itemsFilials[i].items[r].discount*100)/(checkout.itemsFilials[i].items[r].totalOnTime);
            if (poncent > percentPermission) {
              produtoComDesconto = true;
            }
          }
        }
      }
    }

    if (checkout.discountCart <= percentPermission && !produtoComDesconto) {
      setObservacaoSolicitacaoDesconto(false);
    } else {
      setObservacaoSolicitacaoDesconto(true);
    }
  }

  const getMessageDiscountDenied = async() => {
    const { checkoutId } = checkout;
    const { data: { results: { response, value } } } = await apiService.get(`/solicitacao-desconto/buscar-justificativa-reprovacao/checkoutId/${checkoutId}`);
    if(response) {
      setMessageDiscountMessage(value)
    }
  }

  useEffect(() => {
    if(observacaoSolicitacaoDesconto && (observacaoDesconto === 'Solicitação de desconto reprovada.' || observacaoDesconto === 'Solicitação de desconto aprovada.')) {
      getMessageDiscountDenied()
    }
  }, [observacaoSolicitacaoDesconto, observacaoDesconto])


  useEffect(() => {
    setTimeout(() => {
      if (current) {
        const { scrollTop } = current;
        setArrowScroll(
          scrollTop === null && scrollTop === undefined
            ? 0 : scrollTop,
        );
      }
    }, 500);
  }, [current, arrowScroll]);

  const onScroll = () => {
    setArrowScroll(containerRef.current.scrollTop);
    const bottom = containerRef.current.scrollHeight - containerRef.current.scrollTop <= (containerRef.current.clientHeight + 100);
    if (bottom) { setEndScroll(true) } else { setEndScroll(false) }
  };

  const onChangeAddress = async () => {
    if (client.tipoPessoa === 'J') {
      const address = {
        logradouro: client.logradouro,
        cidade: client.cidade,
        bairro: client.bairro,
        numero: client.numero,
        estado: client.uf,
        bitPrincipal: client.bitPrincipal,
        bitInativo: client.bitInativo,
        idFilial: client.idEndereco,
        razaoSocial: client.nomeRazaoSocial,
        tipoPessoa: client.tipoPessoa,
        codCliente: client.codCliente,
        cnpj: client.cpfCnpj,
        confirmAddress: true,
      };

      await putMainAddressPJ(client.codCliente, address);
    } else {
      const address = {
        logradouro: client.logradouro,
        cidade: client.cidade,
        bairro: client.bairro,
        numero: client.numero,
        uf: client.uf,
      };

      await putMainAddressPF(client.idEndereco, address);
    }
    await push('/client-space/addresses');
  };

  useEffect(() => {
    const getCouponCount = async () => {
      const { customerId } = checkout.customer
      const { data: { results } } = await apiService.get(`/clientes/${customerId}/quantidade-vales`);
      setCouponCount(results)
    };

    if(checkout?.customer?.customerId) {
      getCouponCount();
    }

    if (checkout?.dataMinimaEntregaAgendada) {
      setDateSelected(new Date(moment(checkout.dataMinimaEntregaAgendada, "DD/MM/YYYY").format('MM-DD-YYYY')));
    }

  }, [checkout]);

  useEffect(() => {
    if (customer.customerId) {
      getTotalCouponsVouchers(customer.customerId);
    }
  }, [customer]);

  useEffect(() => {
    if (checkout.dataEntregaAgendadaEscolhida) {
      setDateSelected(new Date(moment(checkout.dataEntregaAgendadaEscolhida, "DD/MM/YYYY").format('MM-DD-YYYY')));
    }
  }, [dialogSchedule]);

  useEffect(() => {
    localStorage.removeItem('productInitialToLogin');
    if(customer.customerId && checkout.checkoutId) {
      const client = {
        cidade: customer.address.city,
        bairro: customer.address.neighborhood,
        numero: customer.address.number,
        uf: customer.address.state,
        logradouro: customer.address.street,
        codCliente: customer.customerId,
        cpfCnpj: customer.document,
        email: customer.email,
        nomeRazaoSocial: customer.name || customer.nomeSocial,
        tipoPessoa: customer.typePerson,
        valeTotal: totalCouponsVouchers,
        qtyProducts: checkout.resume.quantityProducts,
        confirmAddress: true,
        checkoutId: checkout.checkoutId,
        loggedByCheckoutDetails: true,
        codClienteMatriz: customer.idMatrix,
        idEndereco: customer.idAddress,
        bitPrincipal: customer.bitAddressMain,
        bitInativo: customer.bitInactive,
      };

      setClient(client);

      dispatch({
        type: Types.SET_CLIENT_SUCCESS,
        payload: { client },
      });
    }
  }, [customer, totalCouponsVouchers]);

  const handleChangeDatePicker = (date) => {
    setDateSelected(date);
  }

  const handleSelectDate = () => {
    getCheckout({ idCheckout, isPickup: false, forcarFrete: false, entregaNitro: false, entregaAgendada: true, dataEntregaAgendada: moment(dateSelected).format('DD/MM/YYYY') });
  }

  const enableDatePickerComponent = checkout.dataMinimaEntregaAgendada && checkout.limiteEntregaAgendada;

  return (
    <>
      {enableDatePickerComponent && (
        <DatePickerSchedule
          open={dialogSchedule}
          onChange={handleChangeDatePicker}
          dateSelected={dateSelected}
          handleClose={handleCloseDialogSchedule}
          dataMinimaEntregaAgendada={checkout.dataMinimaEntregaAgendada}
          limiteEntregaAgendada={checkout.limiteEntregaAgendada}
          handleSelectDate={handleSelectDate}
        />
      )}
      {checkout.resume && !notSeller && (
        <fieldset disabled={saving} className={classes.fieldset}>
          <section className={classes.wrapper}>
            <div style={{ position: 'relative', width: '100%' }}>
              {arrowScroll > 0 && !endScroll && !isMobile
                && (
                  <IconButton
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      left: '50%',
                      zIndex: 9,
                      cursor: 'pointer',
                      opacity: '50%',
                      backgroundColor: '#FA4616',
                      color: '#FFFFFF',
                    }}
                    onClick={() => document.querySelector('#topScroll').scrollTo(0, 0)}
                  >
                    <KeyboardArrowUp />
                  </IconButton>
                )}
              {arrowScroll > 0 && endScroll && !isMobile
                && (
                  <IconButton
                    style={{
                      position: 'fixed',
                      bottom: 20,
                      left: '39%',
                      zIndex: 9,
                      cursor: 'pointer',
                      backgroundColor: '#FA4616',
                      color: '#FFFFFF',
                      fontSize: '14px',
                      borderRadius: '40px',

                    }}
                    onClick={() => document.querySelector('#topScroll').scrollTo(0, 0)}
                  >
                    <KeyboardArrowUp /> Voltar ao topo
                  </IconButton>
                )}
              <Box className={classes.main} id="topScroll" ref={containerRef} onScroll={onScroll}>
                <CardBar title={`Checkout #${idCheckout}`}>
                  <Box>
                    <Hidden xsDown>
                      <Tooltip title={`Responsável pelo orçamento: ${checkout.resume.responsable.toLowerCase()}`}>
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                          style={{ textTransform: 'capitalize', color: '#EDEDED', width: 125, height: 50, position: 'absolute', left: 50, top: 0 }}
                        >

                        </Typography>
                      </Tooltip>
                    </Hidden>
                  </Box>

                  <Box>
                    <Hidden xsDown>
                      <Button
                        startIcon={<Add />}
                        disabled={!!checkout.orderId}
                        variant="text"
                        color="primary"
                        onClick={() => {
                          window.location.href = `${REACT_APP_HOST_CATALAGO
                          }/trocar-orcamento/${idCheckout}`;
                        }}
                      >
                        ADICIONAR MAIS ITENS
                      </Button>
                    </Hidden>
                  </Box>
                </CardBar>

                {(isMobile)
                  && (
                    <div className={classes.details}>
                      <div className={classes.resumeTop}>
                        <CustomerInfo
                          name={customer.name || ''}
                          socialName={customer.nomeSocial || ''}
                          document={customer.document}
                          phone={customer.phone}
                          email={customer.email}
                          typePerson={customer.typePerson}
                        />

                        <AddressInfo
                          state={address.state}
                          number={address.number}
                          city={address.city}
                          street={address.street}
                          zipcode={address.zipCode}
                          neighborhood={address.neighborhood}
                          complement={address.complement}
                          deliveryDate={
                            typeof checkout.resume.estimatedDeliveryDays === 'number'
                              ? `Previsão de Entrega em até ${checkout.resume.estimatedDeliveryDays} dias úteis`
                              : 'Sem previsão de entrega ainda'
                          }
                          readOnly={false}
                          onChangeAddress={onChangeAddress}
                          onLogountCustomer={() => {
                            window.location.href = `${REACT_APP_HOST_CATALAGO}/logout-customer`;
                          }}
                        />

                        <Freight
                          enablePickup={checkout.enablePickup}
                          enablePickupTransfer={checkout.enablePickupTransfer}
                          enableSameDay={checkout.enableSameDay}
                          enableNitrous={checkout.enableNitrous}
                          forceFreight={checkout.forceFreight}
                          checkedSameday={checkout.checkedSameday}
                          checkedPickup={checkout.checkedPickup}
                          checkedNormal={checkout.checkedNormal}
                          checkedNitrous={checkout.checkedNitrous}
                          enableSchedule={checkout.enableSchedule}
                          checkedSchedule={checkout.checkedSchedule}
                          dataEntregaAgendada={dateSelected}
                          dataEntregaAgendadaEscolhida={checkout.dataEntregaAgendadaEscolhida}
                          normalFreight={checkout.normalFreight}
                          sameDayFreight={checkout.sameDayFreight}
                          disabled={loading}
                          hasPayment={checkout.payments && !!checkout.payments.length}
                          idCheckout={idCheckout}
                          chosedFreight={chosedFreight}
                          getCheckout={(args) => {
                            getCheckout({ idCheckout, ...args, calculateTax: checkout.resume.calculateTax });
                            chooseFreight();
                          }}
                        />

                        <Divider />
                      </div>
                    </div>
                  )}

                <Hidden smUp>
                  <Box p={2} display="flex" justifyContent="center" flexWrap="wrap">
                    {(!isMobile)
                      && (
                        <Tooltip title="Responsável pelo orçamento">
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            style={{
                              width: '100%',
                              textAlign: 'center',
                              textTransform: 'capitalize',
                            }}
                          >
                            {checkout.resume.responsable.toLowerCase()}
                          </Typography>
                        </Tooltip>
                      )}
                    <Button
                      startIcon={<Add />}
                      disabled={!!checkout.orderId}
                      variant="text"
                      color="primary"
                      onClick={() => {
                        window.location.href = `${REACT_APP_HOST_CATALAGO
                        }/trocar-orcamento/${idCheckout}`;
                      }}
                    >
                      ADICIONAR MAIS ITENS
                    </Button>
                  </Box>
                  <Divider />
                </Hidden>

                <CheckoutItems checkout={checkout} idCheckout={idCheckout} />
                <Payment />
              </Box>

              {(isMobile)
                && (
                  <div className={classes.details}>
                    <div className={classes.resumeTop}>

                      <ResumeOrderInfo
                        productsCount={resume.quantityProducts}
                        subTotalPrazo={resume.subTotalPrazo}
                        subTotalVista={resume.subTotalVista}
                        shipping={resume.freight}
                        voucher={resume.discountCoupon}
                        maxDiscount={resume.maxDiscount}
                        discount={resume.discount}
                        discountStatus={resume.discountStatus}
                        discountRefused={resume.discountRefused}
                        total={resume.total}
                        totalInstallment={resume.totalInstallment}
                        remainder={resume.remainder}
                        remainderInstallment={resume.remainderInstallment}
                        disabled={loading || (checkout.payments && !!checkout.payments.length)}
                        isNotMarketPlaceOnly={resume.isNotMarketPlaceOnly}
                        hasMarketPlace={resume.hasMarketPlace}
                        couponCount={couponCount}
                        customerId={checkout?.customer?.customerId}
                        points={resume.points}
                        pointsOnTime={resume.pointsOnTime}
                        hasOrder={!!checkout.orderId}
                        payments={payments}
                        totalGarantiaEstendida={resume.totalGarantiaEstendida}
                        totalImpostos={resume}
                      />

                      {/* <TaxesInfo
                        ipiTax={resume.ipiTax}
                        stTax={resume.stTax}
                        icmsTax={resume.icmsTax}
                        fcpTax={resume.fcpTax}
                        totalTax={resume.totalTax}
                        customerHaveTaxes={resume.customerHaveTaxes}
                        calculateTax={resume.calculateTax}
                        onCalculateChange={refreshCheckout}
                        disabled={loading}
                      /> */}

                      <Box style={{padding:17, cursor: 'Pointer'}} onClick={(e) => {
                        setOpenModal(true);
                      }}>
                        <span style={{fontWeight:700}}>OBSERVAÇÕES:</span> {observacaoSolicitacaoDesconto && observacaoDesconto.includes("olicit") && observacaoDesconto}<br />
                        {messageDiscountDenied.length > 0 && (
                          <>
                            {observacaoDesconto.includes("repro") && (
                              <><span style={{fontWeight:700}}>JUSTIFICATIVA:</span> <span style={{color:'#C41E3A'}}>{messageDiscountDenied}</span></>
                            )}
                            {observacaoDesconto.includes("Solicitação de desconto aprovada") && (
                              <><span style={{fontWeight:700}}>JUSTIFICATIVA:</span> <span style={{color:'#297D4E'}}>{messageDiscountDenied}</span></>
                            )}
                          </>
                        )}
                      </Box>
                    </div>
                    <Paper className={classes.resumeBottom} style={{ backgroundColor: 'transparent', position: 'inherit', boxShadow: 'none', marginTop: '5px' }} >
                      <TotalResumeOrderInfo
                        primary="TOTAL"
                        cash={`${toMoney(resume.total)}`}
                        primaryWeight
                      />
                      <AdditionalInfo style={{ fontSize: '14px', fontWeight: '600', color: '#FA4616', border: '2px solid #FA4616', borderRadius: '4px', padding: '8px 24px', width:'92%', marginTop:'5px', marginBottom: '5px' }}
                                      readOnly={!!checkout.orderId}
                                      loading={loading}
                                      isPickup={checkout.checkedPickup}
                                      typePerson={customer.typePerson}
                                      lockedOpen={forceAddInfo}
                                      onSubmit={(body) => {
                                        saveExtraInfo(idCheckout, body);
                                        hideAddInfo();
                                        hideInvalidDialog();
                                      }}
                                      initialValues={{
                                        classificacaoCliente: checkout.resume.customerType,
                                        observacaoDePedido: checkout.resume.orderComments,
                                        ordemDeCompra: checkout.resume.purchaseOrder,
                                        nomeClienteRetiraCD: checkout.resume.namePickup,
                                        documentoNF: checkout.resume.documentNF,
                                        inscricaoEstadual: checkout.resume.rgIe || checkout.customer.rgIe,
                                      }}
                      />

                      <Box p={2} pt={1} pb={1} className={classes.buttonContainer}>
                        <ButtonGroup color="secondary" fullWidth style={{  width:'90%', marginLeft: '5%'}}>
                          <Tooltip title="Imprimir detalhes do orçamento">
                            <Button disabled={loading} onClick={() => getPdf(idCheckout)} style={{ marginRight: '4%', fontSize: '14px', fontWeight: '600', color: '#FA4616', border: '1px solid #FA4616' }}>
                              Imprimir
                            </Button>
                          </Tooltip>

                          <Tooltip title="Enviar detalhes do orçamento no e-mail">
                            <Button 
                              style={{ fontSize: '14px', fontWeight: '600', color: '#FA4616', border: '1px solid #FA4616' }}
                              disabled={loading}
                              onClick={() => sendPdfEmail({
                                checkoutId: idCheckout,
                                customerId: customer.customerId,
                              })}
                            >
                              Enviar
                            </Button>
                          </Tooltip>
                        </ButtonGroup>

                        <Box pt={2} pr={2} />

                        <Tooltip title={!isInvalidOrder ? '' : isInvalidOrder}>
                          <span style={{ width: '100%' }}>
                            <ButtonInfo
                              style={{ width:'90%', marginLeft: '5%'}}
                              color={green['500']}
                              colorHover={green['700']}
                              disabled={!!isInvalidOrder}
                              onClick={() => {
                                requestDiscount();
                              }}
                              fullWidth
                            >
                              FINALIZAR PEDIDO
                            </ButtonInfo>
                          </span>
                        </Tooltip>
                      </Box>
                    </Paper>
                  </div>
                )}
            </div>

            {(!isMobile)
              && (
                <aside className={classes.details}>
                  <div className={classes.resumeTop}>
                    <CustomerInfo
                      name={customer.name || ''}
                      socialName={customer.nomeSocial || ''}
                      document={customer.document}
                      phone={customer.phone}
                      email={customer.email}
                      typePerson={customer.typePerson}
                    />
                    <Divider />
                    <AddressInfo
                      state={address.state}
                      number={address.number}
                      city={address.city}
                      street={address.street}
                      complement={address.complement != 'null' && address.complement != null ? address.complement : ''}
                      addInfo={address.addInfo}
                      zipcode={address.zipCode}
                      neighborhood={address.neighborhood}
                      deliveryDate={
                        typeof checkout.resume.estimatedDeliveryDays === 'number'
                          ? `Previsão de Entrega em até ${checkout.resume.estimatedDeliveryDays} dias úteis`
                          : 'Sem previsão de entrega ainda'
                      }
                      readOnly={false}
                      onChangeAddress={onChangeAddress}
                      onLogountCustomer={() => {
                        window.location.href = `${REACT_APP_HOST_CATALAGO}/logout-customer`;
                      }}
                    />
                    <Divider />

                    <Freight
                      enablePickup={checkout.enablePickup}
                      enablePickupTransfer={checkout.enablePickupTransfer}
                      enableSameDay={checkout.enableSameDay}
                      enableNitrous={checkout.enableNitrous}
                      forceFreight={checkout.forceFreight}
                      checkedSameday={checkout.checkedSameday}
                      enableSchedule={checkout.enableSchedule}
                      checkedSchedule={checkout.checkedSchedule}
                      dataEntregaAgendadaEscolhida={checkout.dataEntregaAgendadaEscolhida}
                      dataEntregaAgendada={dateSelected}
                      checkedPickup={checkout.checkedPickup}
                      checkedNormal={checkout.checkedNormal}
                      checkedNitrous={checkout.checkedNitrous}
                      normalFreight={checkout.normalFreight}
                      sameDayFreight={checkout.sameDayFreight}
                      disabled={loading}
                      hasPayment={checkout.payments && !!checkout.payments.length}
                      idCheckout={idCheckout}
                      chosedFreight={chosedFreight}
                      getCheckout={async (args) => {
                        await getCheckout({ idCheckout, ...args, calculateTax: checkout.resume.calculateTax });
                        if (args.entregaAgendada) handleOpenDialogSchedule();
                        chooseFreight();
                      }}
                    />

                    <Divider />

                    <ResumeOrderInfo
                      productsCount={resume.quantityProducts}
                      subTotalPrazo={resume.subTotalPrazo}
                      subTotalVista={resume.subTotalVista}
                      shipping={resume.freight}
                      voucher={resume.discountCoupon}
                      maxDiscount={resume.maxDiscount}
                      discount={resume.discount}
                      discountStatus={resume.discountStatus}
                      discountRefused={resume.discountRefused}
                      total={resume.total}
                      totalInstallment={resume.totalInstallment}
                      remainder={resume.remainder}
                      remainderInstallment={resume.remainderInstallment}
                      disabled={loading || (checkout.payments && !!checkout.payments.length)}
                      isNotMarketPlaceOnly={resume.isNotMarketPlaceOnly}
                      hasMarketPlace={resume.hasMarketPlace}
                      couponCount={couponCount}
                      customerId={checkout?.customer?.customerId}
                      points={resume.points}
                      pointsOnTime={resume.pointsOnTime}
                      hasOrder={!!checkout.orderId}
                      payments={payments}
                      totalGarantiaEstendida={resume.totalGarantiaEstendida}
                      totalImpostos={resume}
                    />

                    <Divider />

                    {/* <TaxesInfo
                    ipiTax={resume.ipiTax}
                    stTax={resume.stTax}
                    icmsTax={resume.icmsTax}
                    fcpTax={resume.fcpTax}
                    totalTax={resume.totalTax}
                    customerHaveTaxes={resume.customerHaveTaxes}
                    calculateTax={resume.calculateTax}
                    onCalculateChange={refreshCheckout}
                    disabled={loading}
                  /> */}

                    <Box display="flex" justifyContent="end">
                      <span className={classes.margemDoCarrinho}>{checkout.margemDoCarrinho || ''}</span>
                    </Box>
                    <Box style={{padding: '0 17px', cursor: 'Pointer'}} onClick={(e) => {setOpenModal(true);}}>
                      <span style={{fontWeight:700}}>OBSERVAÇÕES:</span> {observacaoSolicitacaoDesconto && observacaoDesconto.includes("olicit") && observacaoDesconto}<br />
                      {messageDiscountDenied.length > 0 && (
                        <>
                          {observacaoDesconto.includes("repro") && (
                            <><span style={{fontWeight:700}}>JUSTIFICATIVA:</span> <span style={{color:'#C41E3A'}}>{messageDiscountDenied}</span></>
                          )}
                          {observacaoDesconto.includes("Solicitação de desconto aprovada") && (
                            <><span style={{fontWeight:700}}>JUSTIFICATIVA:</span> <span style={{color:'#297D4E'}}>{messageDiscountDenied}</span></>
                          )}
                        </>
                      )}
                    </Box>

                  </div>
                  <Paper className={classes.resumeBottom} style={{ backgroundColor: 'transparent', bottom: 12, boxShadow: 'none', marginTop: '0px' }} >
                    <TotalResumeOrderInfo
                      primary="TOTAL"
                      cash={`${toMoney(resume.total)}`}
                      primaryWeight
                    />

                    <Box p={3} pt={1} pb={1} className={classes.buttonContainer} style={{  padding:'0px 15px'}}>
                      <ButtonGroup color="secondary" fullWidth style={{  width:'100%'}}>
                        <AdditionalInfo style={{ fontSize: '11px', fontWeight: '600', color: '#FA4616', border: '2px solid #FA4616', borderRadius: '6px', padding: '0px 0px' }}
                                        readOnly={!!checkout.orderId}
                                        loading={loading}
                                        isPickup={checkout.checkedPickup}
                                        typePerson={customer.typePerson}
                                        lockedOpen={forceAddInfo}
                                        onSubmit={(body) => {
                                          saveExtraInfo(idCheckout, body);
                                          hideAddInfo();
                                          hideInvalidDialog();
                                        }}
                                        initialValues={{
                                          classificacaoCliente: checkout.resume.customerType,
                                          observacaoDePedido: checkout.resume.orderComments,
                                          ordemDeCompra: checkout.resume.purchaseOrder,
                                          nomeClienteRetiraCD: checkout.resume.namePickup,
                                          documentoNF: checkout.resume.documentNF,
                                          inscricaoEstadual: checkout.resume.rgIe || checkout.customer.rgIe,
                                        }}
                        />

                        <Tooltip title="Imprimir detalhes do orçamento">
                          <Button disabled={loading} onClick={() => getPdf(idCheckout)} style={{ marginRight: '4%', fontSize: '11px', color: '#FA4616', border: '1px solid #FA4616', borderRadius: '6px', padding: '4px 4px' }}>
                            Imprimir
                          </Button>
                        </Tooltip>

                        <Tooltip title="Enviar detalhes do orçamento no e-mail">
                          <Button  style={{ fontSize: '11px', color: '#FA4616', border: '1px solid #FA4616', borderRadius: '6px', padding: '4px 4px' }}
                                   disabled={loading}
                                   onClick={() => sendPdfEmail({
                                     checkoutId: idCheckout,
                                     customerId: customer.customerId,
                                   })}
                          >
                            Enviar
                          </Button>
                        </Tooltip>
                      </ButtonGroup>

                      <Box pt={2} pr={2} />
                      <Tooltip title={!isInvalidOrder ? '' : isInvalidOrder}>
                      <span style={{ width: '100%' }}>
                        <ButtonInfo
                          color={green['500']}
                          colorHover={green['700']}
                          disabled={!!isInvalidOrder}
                          onClick={() => {
                            requestDiscount();
                          }}
                          fullWidth
                        >
                          FINALIZAR PEDIDO
                        </ButtonInfo>
                      </span>
                      </Tooltip>
                    </Box>
                  </Paper>
                </aside>
              )}

            <Dialog onClose={handleClose} open={openModal} fullWidth maxWidth="md">
              <DialogTitle position="relative">
                Histórico de solicitações de Descontos
                <Box position="absolute" top={4} right={4}>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                <ListRequestsPage idCheckout={idCheckout}  />
              </DialogContent>
            </Dialog>

          </section>
        </fieldset>
      )}

      {!!invalidDialog && <InvalidDialog />}
      {!!checkout.resume && <CheckoutConfirmDialog handleNewDate={handleOpenDialogSchedule}/>}
    </>
  );
};

CheckoutPage.defaultProps = {
  invalidDialog: null,
  totalCouponsVouchers: 0,
};

CheckoutPage.propTypes = {
  getCheckout: PropTypes.func.isRequired,
  resetCheckout: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  invalidDialog: PropTypes.instanceOf(Object),
  checkout: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  drawer: PropTypes.bool.isRequired,
  chosedFreight: PropTypes.bool.isRequired,
  forceAddInfo: PropTypes.bool.isRequired,
  mainFilial: PropTypes.instanceOf(Object).isRequired,
  saveExtraInfo: PropTypes.func.isRequired,
  hideAddInfo: PropTypes.func.isRequired,
  hideInvalidDialog: PropTypes.func.isRequired,
  chooseFreight: PropTypes.func.isRequired,
  observacaoDesconto: PropTypes.string.isRequired,
  getPdf: PropTypes.func.isRequired,
  sendPdfEmail: PropTypes.func.isRequired,
  openConfirmDialog: PropTypes.func.isRequired,
  checkDiscountOffer: PropTypes.func.isRequired,
  closeConfirmDialog: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  snack: PropTypes.instanceOf(Object),
  getTotalCouponsVouchers: PropTypes.func.isRequired,
  totalCouponsVouchers: PropTypes.number,
  client: PropTypes.instanceOf(Object),
  putMainAddressPJ: PropTypes.func.isRequired,
  putMainAddressPF: PropTypes.func.isRequired,
};

const mapState = (state) => {
  const {
    loading,
    printing,
    message,
    creditAvailable,
    totalCouponsVouchers,
  } = state.main.checkout.geral;
  const { loading: loadingPayment } = state.main.checkout.payment;
  const { client } = state.authClient;

  return ({
    ...state.main.checkout.geral,
    user: state.auth.login.user,
    drawer: state.main.app.drawer,
    snack: state.main.app.snack,
    snackMsg: state.main.app.snack,
    mainFilial: state.main.app.filial,
    loading: loadingPayment || loading,
    printing: printing,
    message: message,
    client,
  });
};

export default connect(mapState, {
  getCheckout: getCheckoutAction,
  getTotalCouponsVouchers: getTotalCouponsAndVouchersAction,
  resetCheckout: resetCheckoutAction,
  saveExtraInfo: saveExtraInfoAction,
  hideAddInfo: hideAddInfoAction,
  hideInvalidDialog: hideInvalidDialogAction,
  chooseFreight: chooseFreightAction,
  chooseSchedule: chooseScheduleAction,
  getPdf: getPdfAction,
  sendPdfEmail: sendPdfEmailAction,
  openConfirmDialog: openConfirmDialogAction,
  closeConfirmDialog: closeConfirmDialogAction,
  checkDiscountOffer: checkDiscountOfferAction,
  checkDiscountOfferRequest: checkDiscountOfferRequestAction,
  putMainAddressPJ: putMainAddressPJAction,
  putMainAddressPF: putMainAddressPFAction,
})(CheckoutPage);
