export default {
  SET_PAGE: '@checkoutsAndOrders/SET_PAGE',
  TOGGLE_FILTER: '@checkoutsAndOrders/TOGGLE_FILTER',
  ORCAMENTOS_REQUEST: '@checkoutsAndOrders/ORCAMENTOS_REQUEST',
  ORCAMENTOS_SUCCESS: '@checkoutsAndOrders/ORCAMENTOS_SUCCESS',
  ORCAMENTOS_FAILURE: '@checkoutsAndOrders/ORCAMENTOS_FAILURE',
  PDF_ORCAMENTOS_REQUEST: '@checkoutsAndOrders/PDF_ORCAMENTOS_REQUEST',
  PDF_ORCAMENTOS_SUCCESS: '@checkoutsAndOrders/PDF_ORCAMENTOS_SUCCESS',
  PDF_ORCAMENTOS_FAILURE: '@checkoutsAndOrders/PDF_ORCAMENTOS_FAILURE',
  PEDIDO_REQUEST: '@checkoutsAndOrders/PEDIDO_REQUEST',
  PEDIDO_SUCCESS: '@checkoutsAndOrders/PEDIDO_SUCCESS',
  PEDIDO_FAILURE: '@checkoutsAndOrders/PEDIDO_FAILURE',
  PDF_PEDIDO_REQUEST: '@checkoutsAndOrders/PDF_PEDIDO_REQUEST',
  PDF_PEDIDO_SUCCESS: '@checkoutsAndOrders/PDF_PEDIDO_SUCCESS',
  PDF_PEDIDO_FAILURE: '@checkoutsAndOrders/PDF_PEDIDO_FAILURE',
  RESET_FILTERS_FAILURE: '@checkoutsAndOrders/RESET_FILTERS_FAILURE',
  DELIVERY_DETAILS_REQUEST: '@checkoutsAndOrders/DELIVERY_DETAILS_REQUEST',
  DELIVERY_DETAILS_SUCCESS: '@checkoutsAndOrders/DELIVERY_DETAILS_SUCCESS',
  DELIVERY_DETAILS_FAILURE: '@checkoutsAndOrders/DELIVERY_DETAILS_FAILURE',
  RESET_DELIVERY_DETAILS: '@checkoutsAndOrders/RESET_DELIVERY_DETAILS',
};
