import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import useStyles from './styles';
// eslint-disable-next-line import/no-cycle
import { Tooltip } from '../index';

const StockList = ({
  stockEstablishments,
  priceEstablishments,
  filial,
  client,
}) => {
  const [stockList, setStockList] = useState([]);
  const [orderedStockList, setOrderedStockList] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const sellerFilialName = filial.nomeFilial.replace('CD', '').trim();
  const clientCity = client ? client.cidade : '';

  const classes = useStyles();
  const {
    buttonBox,
    stockBody,
    stockGrid,
    stockHeader,
    stockImage,
    viewMore,
  } = classes;

  const handleStockList = (newObj) => {
    setStockList((prevState) => [...prevState, newObj]);
  };

  const handleViewMore = () => {
    setShowMore(!showMore);
  };

  const handleStockTotal = () => (Object.keys(stockEstablishments).length
    ? (Object.values(stockEstablishments).reduce((acum, value) => acum + value)) : 0);

  // typeFilial -> 1)cliente 2)vendedor 3)cajamar
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    Object.keys(priceEstablishments).map((key) => {
      const { name } = priceEstablishments[key];
      let typeFilial = 0;
      let className = '';
      let text = '';
      if ((name || '').toUpperCase().includes('CAJAMAR')) {
        typeFilial = 3;
        className = 'main';
      } else if ((name || '').toUpperCase().includes(sellerFilialName)) {
        typeFilial = 2;
        className = 'user';
        text = 'Estoque <b>filial vendedor</b>';
      } else if (client) {
        if ((name || '').toUpperCase().includes(clientCity.toUpperCase())) {
          typeFilial = 1;
          className = 'client';
          text = 'Estoque <b>filial cliente</b';
        }
      }

      if (stockEstablishments[key]) {
        handleStockList({
          id: Number(key),
          establishment: priceEstablishments[key].name.replace('Loja', '').trim(),
          stockQty: stockEstablishments[key] || 0,
          typeFilial,
          className,
          text,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (stockList.length) {
      const sortArray = stockList.sort((a, b) => b.typeFilial - a.typeFilial);
      setOrderedStockList(sortArray);
    }
  }, [stockList]);

  return (
    <Grid className={stockGrid}>
      <Box className={stockHeader}>
        <span>Estoque total</span>
        <span>{`${handleStockTotal().toLocaleString('pt-br')} un.`}</span>
      </Box>
      {orderedStockList.map(({
        stockQty, establishment, className, typeFilial, text,
      }) => {
        if (typeFilial === 2 || typeFilial === 1) {
          return (
            <Tooltip textSecondary={text} key={text} placement="right">
              <Box key={establishment} className={`${stockBody} ${!showMore && 'showMore'}`}>
                <Box>
                  <span className={`${stockImage} ${className}`} />
                  <span>{establishment}</span>
                </Box>
                <span>{`${stockQty.toLocaleString('pt-br')}`}</span>
              </Box>
            </Tooltip>
          );
        }
        return (
          (
            <Box key={establishment} className={`${stockBody} ${!showMore && 'showMore'}`}>
              <Box>
                <span className={`${stockImage} ${className}`} />
                <span>{establishment}</span>
              </Box>
              <span>{`${stockQty.toLocaleString('pt-br')}`}</span>
            </Box>
          )
        );
      })}
      {stockList.length > 3 && (
        <Box className={buttonBox}>
          <button type="button" className={viewMore} onClick={handleViewMore}>
            {showMore ? 'ver mais' : 'ver menos'}
          </button>
        </Box>
      )}
    </Grid>
  );
};

StockList.propTypes = {
  stockEstablishments: PropTypes.instanceOf(Object).isRequired,
  priceEstablishments: PropTypes.instanceOf(Object).isRequired,
  filial: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => {
  const { client } = state.authClient;
  return ({
    ...state.auth.login,
    ...state.main.app,
    client,
  });
};

export default connect(mapState)(StockList);
