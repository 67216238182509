import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import colorStyle from '../../../../../shared/utils/selectBorder';
import {
  getCanalDeVendasFilterAction, putSupervisoresAction, getUnNegociosAction, postCanalDeVendasAction, getEstabelecimentosAction,
} from '../../../actions';
import useStyles from '../../../styles';

const DialogNewChannel = ({
  handleToggle, canaisDeVendaFilter, unNegocios, getCanalDeVendasFilter, usuarios, page,
  putSupervisores, getUnNegocios, user, postCanalDeVendas, estabelecimentos, getEstabelecimentos, 
}) => {
  const classes = useStyles();
  const [unNegocio, setUnNegocio] = useState('');
  const [canalVendas, setCanalVendas] = useState('');
  const [nomeId, setNomeId] = useState('');
  const [nomeCanal, setNomeCanal] = useState('');
  const [idBseller, setIdBseller] = useState('');
  const [filial, setFilial] = useState('');
  const [supervisor, setSupervisor] = useState('');
  const [validationMessage] = useState(true);
  const [reloadField, setReloadField] = useState(false);
  const [pageSelects] = useState(null);
  const arrayCanalVendas = [];

  canaisDeVendaFilter.forEach((item) => {
    const { value, supervisor: label } = item;
    
    let ex = 0;
    for (var i=0; i<arrayCanalVendas.length; i++) {
      if (arrayCanalVendas[i].label == label) {
        ex = 1;
      }
    }
    if (ex == 0) {
      arrayCanalVendas.push({ value : '1', label });
    }
  });

  // Alterando valores das variaveis do array para label e value
  const arrayUnNegocios = [];
  unNegocios.forEach((item) => {
    const { idUnidadeNegocio: value, nomeUnidadeNegocio: label } = item;
    arrayUnNegocios.push({ value, label });
  });

  const arrayVendedores = [];
  usuarios.forEach((item) => {
    const { idVendedor: value, nome: label } = item;
    arrayVendedores.push({ value, label });
  });

  const arrayFiliais = [];
  estabelecimentos.forEach((item) => {
    const { idFilial: value, nomeEstabelecimento: label } = item;
    arrayFiliais.push({ value, label });
  });

  /*useEffect(() => {
    // selectClearRef.current.clearValue();
    setReloadField(!reloadField);
    if (unNegocio !== '') {
      getCanalDeVendasFilter(unNegocio);
    }
  }, [unNegocio]);*/

  useEffect(() => {
    getUnNegocios({ page: pageSelects });
    getEstabelecimentos({ page: pageSelects });
  }, []);

  const renderCriarCanal = () => {
    handleToggle();
    postCanalDeVendas({
      descricao: nomeCanal,
      idBseller: idBseller,
      usuarioAlteracao: "",
      idUnidadeDeNegocio: unNegocio,
      filial: filial,
      idSupervisor: 1381,
      page,
    });
  };

  return (
    <div className={classes.paper} styles={{ justifyContent: 'center', alignItems: 'center' }}>
      <Box position="relative" style={{ backgroundColor: '#F3F4F6', borderRadius: 4 }}>
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={handleToggle}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogTitle>
        Cadastrar novo canal de vendas
        </DialogTitle>
      </Box>
      <div style={{ padding: 20 }}>
        <Grid item container spacing={1} xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
              <Typography>NOME</Typography>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                value={nomeCanal}
                onChange={(e) => setNomeCanal(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>ID BSELLER</Typography>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                value={idBseller}
                onChange={(e) => setIdBseller(e.target.value)}
              />
            </Grid>
          </Grid>
        <Grid item container spacing={1} xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Typography>UNIDADE DE NEGÓCIO</Typography>
            <Select
              styles={colorStyle}
              placeholder="Selecione aqui..."
              options={arrayUnNegocios}
              onChange={(e) => setUnNegocio(e.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>SUPERVISOR</Typography>
            <Select
              styles={colorStyle}
              isDisabled={unNegocio === '' && arrayCanalVendas !== []}
              placeholder="Selecione aqui..."
              options={arrayCanalVendas}
              key={reloadField}
              onChange={(e) => setSupervisor(e.value)}
            />
          </Grid>
        </Grid>

        {unNegocio != '1' && (
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <Typography>FILIAL</Typography>
            <Select
              styles={colorStyle}
              placeholder="Selecione aqui..."
              isDisabled={supervisor === ''}
              options={arrayFiliais}
              onChange={(e) => setFilial(e.value)}
            />
          </Grid>
        )}

        <div className={classes.checkboxandButton}>
          <Button
            disabled={!!(unNegocio === '' || supervisor === '' || nomeCanal === ''|| (filial === '' && unNegocio != '1') || idBseller === '')}
            className={classes.buttonCriar}
            onClick={renderCriarCanal}
          >
            SALVAR
          </Button>
        </div>
      </div>
    </div>
  );
};

DialogNewChannel.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  unNegocios: PropTypes.arrayOf(Object).isRequired,
  canaisDeVendaFilter: PropTypes.arrayOf(Object).isRequired,
  usuarios: PropTypes.arrayOf(Object).isRequired,
  estabelecimentos: PropTypes.arrayOf(Object).isRequired,
  getCanalDeVendasFilter: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  putSupervisores: PropTypes.func.isRequired,
  postCanalDeVendas: PropTypes.func.isRequired,
  getUnNegocios: PropTypes.func.isRequired,
  getEstabelecimentos: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => ({
  ...state.main.sales,
  user: state.auth.login.user,
});

export default connect(mapState, {
  getCanalDeVendasFilter: getCanalDeVendasFilterAction,
  putSupervisores: putSupervisoresAction,
  getUnNegocios: getUnNegociosAction,
  getEstabelecimentos: getEstabelecimentosAction,
  postCanalDeVendas: postCanalDeVendasAction,
})(DialogNewChannel);
