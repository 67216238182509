import React from 'react';
import { Snackbar as SnackbarStyled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { AlertStyled as Alert } from './styles';

const Snackbar = ({ alertOptions, setAlertOptions, className }) => {
  const { open, message, severity } = alertOptions;
  const handleClose = () => setAlertOptions({ ...alertOptions, open: false });

  return (
    <SnackbarStyled
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={5000}
      className={className}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        className={severity}
      >
        {message}
      </Alert>
    </SnackbarStyled>
  );
};

Snackbar.propTypes = {
  className: '',
};

Snackbar.propTypes = {
  alertOptions: PropTypes.instanceOf(Object).isRequired,
  setAlertOptions: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Snackbar;
