import React, { useEffect, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const CheckboxRefinementCustom = ({
  checkbox,
  handleSameFilialCheckbox,
}) => {
  const {
    attribute,
    content,
    withdrawStore,
    customer,
    user,
  } = checkbox;
  const [refinement, setRefinement] = useState({});
  const { sameFilialCheckbox, setSameFilialCheckbox } = handleSameFilialCheckbox;

  const {
    items,
    refine,
  } = useRefinementList({
    attribute,
    limit: 50,
  });

  const classes = useStyles();
  const {
    checkboxRefinementBox,
    checkboxRefinementLabel,
    checkboxRefinementTitle,
    checkboxRefinementCount,
    storeName,
  } = classes;

  useEffect(() => {
    let filial = customer ? customer.cidade.toUpperCase() : user.filiais[0].nomeFilial.replace('CD ', '');
    
    if (user) {
      switch (user.idFilial) {
        case 12:
          filial = "RIBEIRÃO";
  
        case 8: 
          filial = "JUNDIAÍ";
  
        case 25: 
          filial = "MARÍLIA";
  
        case 21:   
          filial = "JACAREÍ";
      }
    }

    const result = items.find((item) => item.value.toUpperCase().match(filial));
    setRefinement({ ...result });
  }, [items]);

  useEffect(() => {
    if (Object.keys(refinement).length) {
      if (user) {
        setRefinement({
          ...refinement,
          isRefined: sameFilialCheckbox.stock,
        });
      } else if (customer) {
        setRefinement({
          ...refinement,
          isRefined: sameFilialCheckbox.withdrawStore,
        });
      }
    }
  }, [sameFilialCheckbox]);

  const handleRefine = (isRefined) => {
    if (user) {
      setSameFilialCheckbox({ ...sameFilialCheckbox, stock: isRefined });
    } else if (customer) {
      setSameFilialCheckbox({ ...sameFilialCheckbox, withdrawStore: isRefined });
    } else setRefinement({ ...refinement, isRefined });

    refine(refinement.value);
  };

  return (
    <Box className={checkboxRefinementBox}>
      <div className={`${checkboxRefinementLabel} ${!refinement.value && ('disabled')}`}>
        <input
          type="checkbox"
          checked={refinement.isRefined}
          value={refinement.value}
          disabled={!refinement.value}
          onChange={(e) => handleRefine(e.target.checked)}
        />
        <span
          className={checkboxRefinementTitle}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <span className={checkboxRefinementCount}>
          {`(${refinement.count || 0})`}
        </span>
        {(withdrawStore && refinement.label) && (
          <span className={storeName} dangerouslySetInnerHTML={{ __html: `- ${refinement.label}` }} />
        )}
      </div>
    </Box>
  );
};

CheckboxRefinementCustom.defaultProps = {
  handleSameFilialCheckbox: null,
};

CheckboxRefinementCustom.propTypes = {
  checkbox: PropTypes.instanceOf(Object).isRequired,
  handleSameFilialCheckbox: PropTypes.instanceOf(Object),
};

export default CheckboxRefinementCustom;
