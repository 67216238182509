import { makeStyles } from '@material-ui/core';
import { ArrowDownIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  priceGrid: {
    padding: '6px 0',
    borderTop: '1px solid #CFCFD4',
    borderBottom: '1px solid #CFCFD4',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 80,
    justifyContent: 'center',

    '&.contentDetailsDialog': {
      border: 'none !important',
      alignItems: 'end',
    },

    '&.disabled': {
      filter: 'grayscale(1)',
      color: '#909599 !important',
    },
  },
  priceOfBox: {
    display: 'flex',
    gap: 16,
  },
  priceOf: {
    color: '#909599',
    fontSize: 12,
    textTransform: 'uppercase',
    textDecoration: 'line-through',
    lineHeight: '18px',
  },
  discount: {
    color: '#21AD34',
    fontSize: 12,
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
  },
  pricePerBox: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  pricePer: {
    lineHeight: '30px',
    fontSize: 24,
    fontWeight: 600,
  },
  centsPer: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '25px',
  },
  installments: {
    fontSize: 10,
    lineHeight: '18px',
  },
  discountIcon: {
    backgroundImage: `url(${ArrowDownIcon})`,
    width: 14,
    height: 14,
  },
  searchInstallmentsBtn: {
    border: 0,
    background: 'none',
    display: 'flex',
    fontSize: 10,
    lineHeight: '18px',
    fontFamily: ['Poppins', 'sans-serif'],
    fontWeight: 600,
    color: '#242424',

    '& span': {
      display: 'flex',
      position: 'relative',
      cursor: 'pointer',

      '&:after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        transform: 'scaleX(0)',
        height: 1,
        bottom: 0,
        left: 0,
        backgroundColor: '#242424',
        transformOrigin: 'bottom right',
        transition: 'transform 0.25s ease-out',
      },

      '&:hover:after': {
        transform: 'scaleX(1)',
        transformOrigin: 'bottom left',
      },
    },
  },
  [breakpoints.down('xs')]: {
    priceGrid: {
      '&.contentDetailsDialog': {
        width: '100%',
        alignItems: 'center',
      },
    },
  },
}));

export default useStyles;
