import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { green } from '@material-ui/core/colors';
import useStyles from './styles';

const TotalResumeOrderInfo = ({
  primary, cash, term, colorPrimary, colorSecondary,
}) => {
  const classes = useStyles({
    colorPrimary,
    colorSecondary,
    primaryWeight: true,
  });
  return (
    <ListItem className={classes.root} dense>
      <ListItemText
        primary={primary}
        classes={{
          root: classes.primary,
          primary: classes.primaryText,
        }}
      />
      <ListItemText
        classes={{
          root: classes.secondary,
          primary: classes.primaryText,
          secondary: classes.secondaryText,
        }}
        primary={term}
        secondary={cash}
      />
    </ListItem>
  );
};

TotalResumeOrderInfo.defaultProps = {
  colorPrimary: green['500'],
  colorSecondary: green['500'],
  cash: null,
};

TotalResumeOrderInfo.propTypes = {
  primary: PropTypes.string.isRequired,
  cash: PropTypes.string,
  term: PropTypes.string.isRequired,
  colorPrimary: PropTypes.string,
  colorSecondary: PropTypes.string,
};

export default TotalResumeOrderInfo;
