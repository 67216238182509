import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({

	tableHeadCellOrdination: {
		textAlign: 'center',
		width: 'calc(100% / 8)',
		position: 'relative',
		transition: 'transform 0.3s ease-in-out',
		'&:hover': {
			'& $iconTransitionHover': {
				opacity: '0.5'
			}
		}
	},
	iconTransition: {
		position: 'absolute',
		opacity: '1',
		bottom: '35%'
	},
	iconTransitionHover: {
		position: 'absolute',
		opacity: '0',
		bottom: '35%'
	}
}));

export default useStyles;
