import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const InputRender = ({
  input,
  label,
  type,
  placeholder,
  maxLength,
  disabled,
  required,
  min,
  max,
  meta,
  endAdornment,
  inputLabelProps,
  multiline,
  rows,
  variant,
  readOnly,
  autoFocus,
  classes,
  autocomplete,
}) => {
  const showError = meta.error && meta.touched;
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <FormControl fullWidth>
      <TextField
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...input}
        label={label}
        variant={variant}
        id={input.name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        InputLabelProps={inputLabelProps}
        required={required}
        multiline={multiline}
        minRows={rows}
        min={min}
        max={max}
        autoComplete={autocomplete}
        error={showError}
        helperText={showError ? meta.error : null}
        autoFocus={autoFocus}
        InputProps={{
          endAdornment,
          readOnly,
          inputProps: {
            maxLength,
          },
        }}
        classes={{ root: classes || '' }}
      />
    </FormControl>
  );
};
InputRender.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  classes: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  inputLabelProps: PropTypes.instanceOf(Object),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  meta: PropTypes.instanceOf(Object).isRequired,
  endAdornment: PropTypes.node,
  variant: PropTypes.string,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autocomplete: PropTypes.string,
};

InputRender.defaultProps = {
  type: 'text',
  placeholder: '',
  classes: '',
  maxLength: 200,
  disabled: false,
  required: false,
  min: 0,
  max: 100,
  autoFocus: false,
  multiline: false,
  rows: 4,
  readOnly: false,
  variant: 'standard',
  endAdornment: null,
  autocomplete: 'off',
};

export default InputRender;
