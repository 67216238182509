import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Paper,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import editIcon from '../../../../../assets/edit.svg';
import CardBarSale from '../../../../../shared/components/cardBarSale';
import ResponsiveTable from '../../../../../shared/components/responsiveTable';
import DialogNewOffice from './DialogNewOffice';
import DialogRefreshOffice from './DialogRefreshOffice';
import { getCargosAction, getCargoFilterAction } from '../../../actions';
import useStyles from '../../../styles';

const Office = ({
  loading, cargos, getCargos, totalpagesCargo, getCargoFilter,
}) => {
  const [dialog, setDialog] = useState(false);
  const [dialogRefresh, setDialogRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [nomeProps, setNomeProps] = useState('');
  const [idProps, setIdProps] = useState(1);
  const handleToggle = () => setDialog(!dialog);
  const handleToggleRefresh = () => setDialogRefresh(!dialogRefresh);
  const classes = useStyles();

  useEffect(() => {
    getCargos({ page });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleCargoFilter = (id) => {
    getCargoFilter({ id });
  };

  return (
    <div className={classes.divRow}>
      <div style={{ width: '100%' }}>
        <CardBarSale title="Home" />
        {loading && (<LinearProgress />)}
        <div className={classes.titleAndButton}>
          <span>Cargo</span>
          <Button className={classes.buttonTitle} onClick={handleToggle}>CRIAR NOVO CARGO</Button>
        </div>
        <section className={classes.sessionTable}>
          <Paper elevation={1} style={{ width: '95%' }}>
            <ResponsiveTable pointer>
              <TableHead style={{ backgroundColor: '#F9F9F9' }}>
                <TableRow>
                  <TableCell component="th" align="left">
                    NOME
                  </TableCell>
                  <TableCell component="th" align="center">
                    AÇÃO
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {cargos.map((item) => (
                  <TableRow
                    key={item.id}
                    hover
                  >
                    <TableCell datatype="Nome">
                      {item.nome}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => {
                        handleToggleRefresh();
                        setNomeProps(item.nome);
                        setIdProps(item.id);
                        handleCargoFilter(item.id);
                      }}
                      >
                        <img src={editIcon} alt="edit" className={classes.boxEdit} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

                {!cargos.length && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Typography variant="body2">
                      {loading ? 'Buscando lista de cargos...' : 'Nenhum Cargo Encontrado'}
                    </Typography>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </ResponsiveTable>
          </Paper>
        </section>

        {dialog
       && (
         <DialogNewOffice dialog={dialog} handleToggle={handleToggle} page={page} />
       )}

        {dialogRefresh
       && (
         <DialogRefreshOffice
           dialog={dialogRefresh}
           handleToggle={handleToggleRefresh}
           page={page}
           idCargoProps={idProps}
           nameCargoProps={nomeProps}
         />
       )}

      </div>
      <div style={{
        display: 'flex', justifyContent: 'flex-end', marginBottom: 30, marginRight: 50,
      }}
      >
        <Pagination
          count={totalpagesCargo}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

Office.propTypes = {
  loading: PropTypes.bool.isRequired,
  cargos: PropTypes.arrayOf(Object).isRequired,
  totalpagesCargo: PropTypes.number.isRequired,
  // checkoutMeta: PropTypes.instanceOf(Object).isRequired,
  // clearMeta: PropTypes.func.isRequired,
  getCargos: PropTypes.func.isRequired,
  getCargoFilter: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.sales;

export default connect(mapState, {
  getCargos: getCargosAction,
  getCargoFilter: getCargoFilterAction,
})(Office);
