import { makeStyles } from '@material-ui/core';
import { AlertIcon } from '../../../../../assets';

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }
}));

export default useStyles;
