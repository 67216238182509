/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  Box, Divider, Link,
} from '@material-ui/core';
// import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import {
  useParams,
} from 'react-router-dom';
import useStyles from './style';

const CardBarNew = ({
  flat, user,
}) => {
  const {
    idRelatorio, idList, idPedido,
  } = useParams();
  const classes = useStyles();

  const recoveryPedido = user.permissoes.find((permission) => (
    permission.id === 3
  ));

  const relatorio = user.permissoes.find((permission) => (
    permission.id === 2
  ));

  const recovery = user.permissoes.find((permission) => (
    permission.id === 1
  ));

  const [loading, setLoading] = useState(false);

  setTimeout(() => {
    if (loading) {
      setLoading(false);
    }
  }, 2000);

  return (
    <header>
      <Box className={idList ? classes.appBarList : classes.appBar}>
        <div className={classes.divHeaderElements}>
          <div className={classes.divIcons}>
            {recovery
            && (
            <Link color="inherit" href="/recovery/carrinhos/true" className={idList ? classes.linkPress : classes.link}>
              <ShoppingCartIcon className={classes.icon} />
              <span className={classes.spanLink}>
                Carrinhos Abandonados
              </span>
            </Link>
            )}
            

            {recoveryPedido
            && <span style={{ color: '#c4c4c4', fontSize: 20 }}>|</span>}
            {recoveryPedido
            && (
            <Link color="inherit" href="/recovery/pedidos/true" className={idPedido ? classes.linkPress : classes.link}>
              <DescriptionOutlinedIcon className={classes.icon} />
              <span className={classes.spanLink}>
                Pedidos
              </span>
            </Link>
            )}
          </div>
        </div>

      </Box>
      {!flat && <Divider />}
    </header>
  );
};

CardBarNew.propTypes = {
  flat: PropTypes.bool,
  user: PropTypes.instanceOf(Object).isRequired,
};

CardBarNew.defaultProps = {
  flat: false,
};

const mapState = (state) => ({
  ...state.main.recovery.list,
  user: state.auth.login.user,
});

export default connect(mapState, {})(CardBarNew);
