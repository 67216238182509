import {
  Dialog, DialogActions, makeStyles, withStyles,
} from '@material-ui/core';
import {
  ClientIcon,
  CloseIcon,
  LocationIcon,
  SellerGreenIcon,
  SellerIcon,
  StoreFilialBlackIcon,
} from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  withdrawStoreDialog: {
    width: 532,
  },
  withdrawalBox: {
    padding: '12px 0',
    borderRadius: 4,
  },
  withdrawalHeader: {
    fontSize: 16,
    color: '#000000',
    lineHeight: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
  },
  closeIcon: {
    background: `url(${CloseIcon})`,
    width: 20,
    height: 20,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 8,
    backgroundPosition: 'center',
    border: '2px solid',
    borderRadius: '100%',
    cursor: 'pointer',
  },
  addressClientMain: {
    padding: 12,
    borderBottom: '1px solid #E3E3E7',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,

    '& span:first-child': {
      fontSize: 12,
      color: '#7B7F82',
    },
  },
  withdrawalIcon: {
    background: `url(${StoreFilialBlackIcon})`,
    width: 20,
    height: 20,
    marginRight: 4,
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    backgroundSize: 'contain !important',

    '&.client': {
      background: `url(${ClientIcon})`,
    },
    '&.seller': {
      background: `url(${SellerIcon})`,
    },
    '&.green': {
      background: `url(${SellerGreenIcon})`,
    },
  },
  addressClientIcon: {
    background: `url(${LocationIcon})`,
    backgroundRepeat: 'no-repeat',
    maxWidth: 16,
    width: '100%',
    height: 20,
  },
  withdrawalOptionsBox: {
    display: 'flex',
    border: '1px solid #E3E3E7',
    margin: '2px 12px',
    borderRadius: 4,
    padding: 12,
  },
  radioBoxMain: {
    width: '100%',
  },
  radioBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  radioLabel: {
    color: '#242424',
    fontSize: 12,
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    gap: 4,

    '&.client': {
      color: '#33488E',
    },

    '&.seller': {
      color: '#FE5000',
    },

    '&.green': {
      color: '#008000',
    },
  },
  withdrawalLabel: {
    fontWeight: 600,
  },
  withdrawalAvailable: {
    fontSize: 10,
    lineHeight: '15px',
    color: '#21AD34',
    background: '#EBFAEF',
    padding: '2px 8px',
    borderRadius: 4,
  },
  withdrawalAddress: {
    fontSize: 12,
    lineHeight: '18px',
  },
  addressClient: {},
  addressClientBox: {
    display: 'flex',
    gap: 8,

    '& span:last-child': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '21px',
      color: '#000000',
      textDecoration: 'underline',
    },
  },
  radioGroupBox: {
    margin: '10px 0px 8px',
  },
  radio: {
    width: 14,
    height: 14,
    marginRight: 8,

    '&.client:checked': {
      accentColor: '#33488E',
    },

    '&.seller:checked': {
      accentColor: '#FE5000',
    },

    '&.green:checked': {
      accentColor: '#008000',
    },
  },
  noPadding: {
    padding: '0 !important',
  },
  confirmButton: {
    height: 32,
    width: 127,
    backgroundColor: '#008000',
    border: 0,
    borderRadius: 4,
    color: '#FFFFFF',
    texTransform: 'uppercase',
    fontSize: 10,
    lineHeight: '10px',
    fontFamily: ['Poppins', 'sans-serif'],
    textTransform: 'uppercase',
  },
  [breakpoints.down('sm')]: {
    addressClient: {
      fontSize: 12,
      lineHeight: '18px',
    },
    addressClientBox: {
      '& span:last-child': {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
    radioBox: {
      gap: 4,
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    withdrawalAddress: {
      fontSize: 10,
      lineHeight: '14px',
    },
    withdrawalHeader: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
}));

export const DialogStyled = withStyles(() => ({
  paper: {
    width: 532,
  },
}))(Dialog);

export const DialogActionsStyled = withStyles(() => ({
  root: {
    padding: 12,
  },
}))(DialogActions);

export default useStyles;
