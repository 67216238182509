import React from "react";
import * as PropTypes from "prop-types";

import useStyles from "./styles";
import { Field } from "redux-form";
import { CheckBoxRender } from "../../../../../shared/fields";

const CheckboxForm = ({
  name,
  label,
  required,
  props
}) => {
  const {
    formControlLabel,
  } = useStyles();

  return (
    <div>
      <Field
        {...props}
        name={name}
        label={label}
        required={required}
        component={CheckBoxRender}
        classes={{root: formControlLabel}}
      />
  </div>
  )
};

CheckboxForm.defaultProps = {
  required: false,
};

CheckboxForm.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default CheckboxForm;
