/* istanbul ignore file */

import React from 'react';
import { Route } from 'react-router-dom';
import { CheckoutsAndOrdersPage } from './checkoutsAndOrders';
import OrderRouter from './order';
import CheckoutPage from './checkout/Page';
import RecoveryRouter from './recoveryCart';
import DevAcessRouter from './devAccess';
import SalesRouter from './salesChannel';
import CreditRequests from './creditRequests';
import DiscountRouter from './discount/Routes';
import RequestRouter from './requests/Routes';
import CustomSwitch from '../shared/components/customSwitch';
import SuggestionRouter from './suggestion/Router';
import LogoutPage from '../auth/logout/Page';
import HomePage from './home/Page';
import Login from './authClient/login/Page';

import RegisterPage from './authClient/register/Page';
import CorporatePersonPage from './authClient/register/corporatePerson/CorporatePerson';
import IndividualPerson from './authClient/register/individualPerson/IndividualPerson';
import SuccessRegisterPage from './authClient/register/SuccessRegister/SuccessRegister';
import ClientRouter from './client/Router';

const MainRouter = () => (
  <CustomSwitch>
    <Route path="/" component={HomePage} exact />
    <Route path="/client-login" component={Login} exact />
    <Route path="/register" component={RegisterPage} exact />
    <Route path="/register/j" component={CorporatePersonPage} exact />
    <Route path="/register/f" component={IndividualPerson} exact />
    <Route path="/register/success" component={SuccessRegisterPage} exact />

    <Route path="/client-space/:tabName" component={ClientRouter} exact />
    <Route path="/client-space/addresses/new" component={ClientRouter} exact />
    <Route path="/client-space/address/edit/:addressId" component={ClientRouter} exact />

    <Route path="/checkouts-orders" component={CheckoutsAndOrdersPage} exact />
    <Route path="/order" component={OrderRouter} />
    <Route path="/checkout/:idCheckout" component={CheckoutPage} exact />
    <Route path="/homeSales" component={SalesRouter} />
    {/* <Route path="/sales-report" component={SalesReport} /> */}
    <Route path="/recovery" component={RecoveryRouter} />
    <Route path="/devAcess" component={DevAcessRouter} />
    <Route path="/discount" component={DiscountRouter} />
    <Route path="/requests" component={RequestRouter} />
    <Route path="/suggestion" component={SuggestionRouter} />
    <Route path="/credit" component={CreditRequests} />
    <Route path="/logout" component={LogoutPage} />
  </CustomSwitch>
);

export default MainRouter;
