import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import { Add, DragHandle, Remove } from '@material-ui/icons';
import { toMoney } from '../../../../../shared/utils/parsers';
import useStyles from './styles';
import {
  useTheme, useMediaQuery,
} from '@material-ui/core';

const ItemAdvancedPaymentResume = ({
  // eslint-disable-next-line max-len
  value, valueInstallment, valueRemainder, valueInstallmentRemainder, resume, valuetotal, valuetotalinstallment, totalGarantiaEstendida, discountCart,
}) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  return (
    <div className={classes.divContaineer}>

        {!isMobile
        ? (
          <>
          <Box
          display="flex"
          paddingTop="9px"
          >
            <div className={classes.divValor}>
              <Typography className={classes.textTitlePayment} style={{textAlign: 'center'}}>
                Total de Produto (
                {resume.quantityProducts}
                )
              </Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textTitlePaymentDialog}>
                  <>
                    <b>{valueInstallment}</b>
                    {' '}
                  </>
                </Typography>
              </div>
            </div>

            <Add className={classes.iconAdd} style={{marginLeft: '16px', marginTop: '23px'}} />
            <Box mr={2} />

            <div className={classes.divValorDialog}>
              <Typography className={classes.textTitlePaymentDialog} style={{textAlign: 'center'}}>
              Garantia Estendida
              </Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textTitlePaymentDialog}>
                    <>
                      <b>{totalGarantiaEstendida}</b>
                    </>
                </Typography>
              </div>
            </div>

            <Add className={classes.iconAdd} style={{marginLeft: '16px', marginTop: '23px'}} />
            <Box mr={2} />

            <div className={classes.divValorDialog}>
              <Typography className={classes.textTitlePaymentDialog} style={{textAlign: 'center'}}>
                Frete
              </Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textTitlePaymentDialog}>
                  {resume.freight === 0 ? (
                    <span>
                      <b> Gratis</b>
                    </span>
                  ) : (
                    <>
                      {' '}
                      <b>{`${toMoney(resume.freight)}`}</b>
                    </>
                  )}
                </Typography>
                {/* <Typography className={classes.textTitlePaymentDialog}>
                  Impostos:
                  {' '}
                  <b>{toMoney(resume.totalTax)}</b>
                </Typography> */}
              </div>
            </div>

            <Remove className={classes.iconAdd}  style={{marginLeft: '16px', marginTop: '23px'}} />
            <Box mr={2} />

            <div className={classes.divValorDialog}>
              <Typography className={classes.textTitlePaymentDialog} style={{textAlign: 'center'}}>
              Desconto 
              </Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textTitlePaymentDialog}>
                    <>
                      <b>{discountCart}</b>
                    </>
                </Typography>
              </div>
            </div>

            <DragHandle className={classes.iconEquals} style={{marginTop: '23px'}} />

            <div className={classes.divValor}>
              <Typography className={classes.textTitlePayment}  style={{textAlign: 'center'}}>Valor Total</Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textValues}>
                  <b>{valuetotal}</b>
                  {' '}
                </Typography>
              </div>
            </div>


            </Box>

            <Box className={classes.divBoxTotal}>
            <Typography className={classes.textTitlePayment}>Valor Pendente</Typography>
            <div>
              <Typography className={classes.textValues}>
                <b>{valueRemainder}</b>
                {' '}
              </Typography>
            </div>
            </Box>
          </>

        ) : (
          <div>
          <Box
          display="flex"
          paddingTop="9px"
          >
            <div className={classes.divValor} style={{width:90}}>
              <Typography className={classes.textTitlePayment} style={{textAlign: 'center'}}>
                Total de Prod.
              </Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textTitlePaymentDialog}>
                  <>
                    <b>{valueInstallment}</b>
                    {' '}
                  </>
                </Typography>
              </div>
            </div>





            <Add className={classes.iconAdd} style={{marginTop: '23px'}} />
         

            <div className={classes.divValorDialog}>
              <Typography className={classes.textTitlePaymentDialog} style={{textAlign: 'center'}}>
              Garant. Est.
              </Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textTitlePaymentDialog}>
                    <>
                      <b>{totalGarantiaEstendida}</b>
                    </>
                </Typography>
              </div>
            </div>

            <Add className={classes.iconAdd} style={{ marginTop: '23px'}} />
          

            <div className={classes.divValorDialog}>
              <Typography className={classes.textTitlePaymentDialog} style={{textAlign: 'center'}}>
                Frete
              </Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textTitlePaymentDialog}>
                  {resume.freight === 0 ? (
                    <span>
                      <b> Gratis</b>
                    </span>
                  ) : (
                    <>
                      {' '}
                      <b>{`${toMoney(resume.freight)}`}</b>
                    </>
                  )}
                </Typography>
                {/* <Typography className={classes.textTitlePaymentDialog}>
                  Impostos:
                  {' '}
                  <b>{toMoney(resume.totalTax)}</b>
                </Typography> */}
              </div>
            </div>

            <Remove className={classes.iconAdd}  style={{marginTop: '23px'}} />

           </Box>

            
        
          <Box
          display="flex"
          paddingTop="9px"
          >
            <div className={classes.divValorDialog}>
              <Typography className={classes.textTitlePaymentDialog} style={{textAlign: 'center'}}>
              Desconto 
              </Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textTitlePaymentDialog}>
                    <>
                      <b>{discountCart}</b>
                    </>
                </Typography>
              </div>
            </div>

            <DragHandle className={classes.iconEquals} style={{marginTop: '23px'}} />

            <div className={classes.divValorDialog}>
              <Typography className={classes.textTitlePayment}  style={{textAlign: 'center'}}>Valor Total</Typography>
              <div className={classes.divColorDialog}>
                <Typography className={classes.textValues}>
                  <b>{valuetotal}</b>
                  {' '}
                </Typography>
              </div>
            </div>

            <div className={classes.divBoxTotal} style={{marginLeft: 20}}>
              <Typography className={classes.textTitlePayment}>Valor Pendente</Typography>
              <div>
                <Typography className={classes.textValues}>
                  <b>{valueRemainder}</b>
                  {' '}
                </Typography>
              </div>
            </div>

          </Box>



          </div>
        )}

      

    </div>
  );
};

ItemAdvancedPaymentResume.propTypes = {
  value: PropTypes.string.isRequired,
  valueInstallment: PropTypes.string.isRequired,
  valueRemainder: PropTypes.string.isRequired,
  valueInstallmentRemainder: PropTypes.string.isRequired,
  resume: PropTypes.instanceOf(Object).isRequired,
  valuetotal: PropTypes.string.isRequired,
  valuetotalinstallment: PropTypes.string.isRequired,
  discountCart: PropTypes.string.isRequired,
};
const mapState = (state) => {
  const {
    checkoutId, resume, payments, deniedPayments, orderId,
  } = state.main.checkout.geral.checkout;
  const { chosedFreight } = state.main.checkout.geral;

  return {
    ...state.main.checkout.advanced,
    payments,
    deniedPayments,
    resume,
    checkoutId,
    orderId,
    chosedFreight,
  };
};

export default connect(mapState, {
})(ItemAdvancedPaymentResume);
