import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import colorStyle from '../../../../../shared/utils/selectBorder';

import useStyles from '../../../styles';
import {
  getVendedoresAtivosAction, postVendedoresAction,
  getCanalDeVendasFilterAction,
  getVendedoresAtivosFiltroAction,
} from '../../../actions';
import apiService from '../../../../../shared/services/apiService';
import apiTef from '../../../../../shared/services/apiTef';

const NewVendedor = ({
  postVendedores, page, setCreateNewUser, unNegocios, canaisDeVendaFilter, getCanalDeVendasFilter,
  cargos, permissoes,
}) => {
  const classes = useStyles();
  const [nome, setNome] = useState('');
  const [ramal, setRamal] = useState('');
  const [nomeExibicao, setNomeExibicao] = useState('');
  const [idVendedorERP, setIdVendedorERP] = useState('');
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [status, setStatus] = useState('');
  const [unNegocio, setUnNegocio] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [canalVendas, setCanalVendas] = useState('');
  const [cargo, setCargo] = useState('');
  const [permissao, setPermissao] = useState([]);
  const [reloadField, setReloadField] = useState(false);

  const [blurRamal, setBlurRamal] = useState(false);
  const [blurBSeller, setBlurBSeller] = useState(false);
  const [blurEmail, setBlurEmail] = useState(false);
  const [blurLogin, setBlurLogin] = useState(false);
  const [blurName, setBlurName] = useState(false);
  const [blurNomeExibicao, setBlurNomeExibicao] = useState(false);
  const [blurSenha, setBlurSenha] = useState(false);

  const statusOptions = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ];

  const arrayCanalVendas = [];
  canaisDeVendaFilter.forEach((item) => {
    const { idCanalVendas: value, descricao: label } = item;
    arrayCanalVendas.push({ value, label });
  });
  const arrayUnNegocios = [];
  unNegocios.forEach((item) => {
    const { idUnidadeNegocio: value, nomeUnidadeNegocio: label } = item;
    arrayUnNegocios.push({ value, label });
  });
  const arrayCargos = [];
  cargos.forEach((item) => {
    const { id: value, nome: label } = item;
    arrayCargos.push({ value, label });
  });
  const arrayPermissoes = [];
  permissoes.forEach((item) => {
    const { id: value, nome: label } = item;
    arrayPermissoes.push({ value, label });
  });

  const handleChange = (e) => {
    setPermissao(Array.isArray(e) ? e.map(x => x.value) : []);
  }

  useEffect(() => {
    // selectClearRef.current.clearValue();
    setReloadField(!reloadField);
    if (unNegocio !== '') {
      getCanalDeVendasFilter(unNegocio);
    }
  }, [unNegocio]);

  // Buscando se existe o mesmo item nos 2 arrays
  const renderPostVendedores = async () => {
    postVendedores({
      nomeVendedor: nome,
      login,
      nivelPermissao: 1,
      idVendedorERP,
      ativo: status,
      vendedorLoja3: 1,
      qtdBoletosNPagos: null,
      abandonoCarrinho: 0,
      nomeExibicao,
      emailVendedor: email,
      idUnidadeDeNegocio: unNegocio,
      idCanalDeVendas: canalVendas,
      idCargo: cargo,
      permissoesTela: permissao,
      page,
      senha: password,
      ramal: ramal === '' ? null : ramal,
    });

    const { data } = await apiService.get(`/vendedores/filtrar?status=1&email=${email}&idUnidadeNegocio=${unNegocio}`);
    const idVendedor = data.results.find((seller) => { return seller.email?.toLowerCase() === email?.toLowerCase() }).idVendedor
    
    // Abertura de Caixa
    await apiTef.post('tef-api/api/tef/register', {
      caixaId: idVendedor?.toString(),
      usuario: login
    }, {
      headers: {
        "x-access-token": `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    });

    setCreateNewUser(false);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Paper elevation={1} style={{ width: '95%', padding: 20 }}>
      <Grid item spacing={1} container xs={12}>
        <Grid item xs={6}>
          <Typography className={classes.textsSilver}>NOME COMPLETO</Typography>
          <TextField
            error={blurName}
            onBlur={() => ((nome.length < 5)
              ? setBlurName(true) : setBlurName(false))}
            helperText={blurName ? 'Minimo 5 Digitos' : ''}
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            onChange={(e) => setNome(e.target.value)}
            value={nome}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.textsSilver}>NOME EXIBIÇÃO</Typography>
          <TextField
            error={blurNomeExibicao}
            onBlur={() => ((nomeExibicao.length < 5)
              ? setBlurNomeExibicao(true) : setBlurNomeExibicao(false))}
            helperText={blurNomeExibicao ? 'Minimo 5 Digitos' : ''}
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            onChange={(e) => setNomeExibicao(e.target.value)}
            value={nomeExibicao}
          />
        </Grid>
      </Grid>
      <Grid item spacing={1} container xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={3}>
          <Typography className={classes.textsSilver}>RAMAL</Typography>
          <TextField
            error={blurRamal}
            onBlur={() => ((ramal.length > 0 && ramal.length > 6)
              ? setBlurRamal(true) : setBlurRamal(false))}
            helperText={blurRamal ? 'Maximo 6 Digitos' : ''}
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            value={ramal.replace(/\D/g, '')}
            onChange={(e) => setRamal(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.textsSilver}>LOGIN</Typography>
          <TextField
            error={blurLogin}
            onBlur={() => ((login.length < 5)
              ? setBlurLogin(true) : setBlurLogin(false))}
            helperText={blurLogin ? 'Minimo 5 Digitos' : ''}
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textsSilver}>SENHA</Typography>
          <TextField
            error={blurSenha}
            onBlur={() => ((password.length < 4)
              ? setBlurSenha(true) : setBlurSenha(false))}
            helperText={blurSenha ? 'Minimo 4 Digitos' : ''}
            size="small"
            className={classes.helperMessage}
            fullWidth
            variant="outlined"
            value={password}
            autoComplete="new-password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

        </Grid>
        <Grid item spacing={1} container xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={5}>
            <Typography className={classes.textsSilver}>E-MAIL</Typography>
            <TextField
              error={blurEmail}
              onBlur={() => ((!(/([@])\w+/g.test(email)))
                ? setBlurEmail(true) : setBlurEmail(false))}
              helperText={blurEmail ? 'Digite um E-mail Valido' : ''}
              size="small"
              fullWidth
              className={classes.helperMessage}
              type="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.textsSilver}>ID BSELLER</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              className={classes.helperMessage}
              error={blurBSeller}
              onBlur={() => ((idVendedorERP.length < 3 || idVendedorERP.length > 5)
                ? setBlurBSeller(true) : setBlurBSeller(false))}
              helperText={blurBSeller ? 'Minimo 3 e Maximo 5  Digitos' : ''}
              value={idVendedorERP.replace(/\D/g, '')}
              onChange={(e) => setIdVendedorERP(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textsSilver}>STATUS</Typography>
            <Select
              placeholder="Selecione aqui..."
              options={statusOptions}
              styles={colorStyle}
              onChange={(e) => setStatus(e.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item spacing={2} container xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={3}>
          <Typography className={classes.textsSilver}>UNIDADE DE NEGÓCIO</Typography>
          <Select
            placeholder="Selecione aqui..."
            options={arrayUnNegocios}
            styles={colorStyle}
            onChange={(e) => setUnNegocio(e.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textsSilver}>CANAL DE VENDAS</Typography>
          <Select
            isDisabled={unNegocio === '' && arrayCanalVendas !== []}
            placeholder="Selecione aqui..."
            styles={colorStyle}
            options={arrayCanalVendas}
            key={reloadField}
            onChange={(e) => setCanalVendas(e.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textsSilver}>CARGO</Typography>
          <Select
            styles={colorStyle}
            placeholder="Selecione aqui..."
            options={arrayCargos}
            onChange={(e) => setCargo(e.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textsSilver}>PERMISSÕES DO VENDEDOR</Typography>
          <Select
            styles={colorStyle}
            isMulti
            placeholder="Selecione aqui..."
            value={arrayPermissoes.filter(obj => permissao.includes(obj.value))}
            options={arrayPermissoes}
            onChange={handleChange}
          />

        </Grid>
      </Grid>
      <div className={classes.divButtonCriar}>
        <Button
           disabled={
                 (!!(nome.length < 5
               || nomeExibicao.length < 5
               || login.length < 5
               || password.length < 4
               || !(/([@])\w+/g.test(email))
               || idVendedorERP.length < 3
               || idVendedorERP.length >= 6
               || status === ''
               || unNegocio === ''
               || canalVendas === ''
               || cargo === ''
               || ramal.length > 6))
             }
          className={classes.buttonCriar}
          onClick={renderPostVendedores}
        >
          CRIAR
        </Button>
      </div>
    </Paper>
  );
};

NewVendedor.propTypes = {
  postVendedores: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setCreateNewUser: PropTypes.func.isRequired,
  unNegocios: PropTypes.arrayOf(Object).isRequired,
  canaisDeVendaFilter: PropTypes.arrayOf(Object).isRequired,
  cargos: PropTypes.arrayOf(Object).isRequired,
  permissoes: PropTypes.arrayOf(Object).isRequired,
  getCanalDeVendasFilter: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.sales;

export default connect(mapState, {
  getVendedoresAtivos: getVendedoresAtivosAction,
  postVendedores: postVendedoresAction,
  getCanalDeVendasFilter: getCanalDeVendasFilterAction,
  getVendedoresAtivosFiltroAction: getVendedoresAtivosFiltroAction
})(NewVendedor);
