export const Valor = [
  { label: 'R$ 00,01 a R$ 99,99', value: '00.01', value2: '99.99' },
  { label: 'R$ 100,00 a R$ 499,99', value: '100.00', value2: '499.99' },
  { label: 'R$ 500,00 a R$ 999,99', value: '500.00', value2: '999.99' },
  { label: 'R$ 1.000,00 a R$ 9.999,99', value: '1000.00', value2: '9999.99' },
  { label: 'R$ 10.000.00 a R$ 99.999,99', value: '10000.00', value2: '99999.99' },
  { label: 'R$ 100.000,00 a R$ 1.000.000,00', value: '100000.00', value2: '1000000.00' },
];

export const colourStyles = {
  control: (styles, {
    isDisabled, isFocused,
  }) => ({
    ...styles,
    boxShadow: 'none',
    color: isDisabled,
    cursor: 'pointer',
    borderColor: isFocused ? '#FF5225' : '#C4C4C4',
    ':hover': {
      ...styles[':hover'],
      boxShadow: 'none',
      borderColor: isFocused && '#FF5225',
    },
  }),
};

export const Canal = [
  { value: '', label: 'TODOS' },
  { value: 'Website', label: 'Website' },
  { value: 'App', label: 'App' },
  { value: 'PDV', label: 'PDV' },
];

export const Tipo = [
  { value: '', label: 'TODOS' },
  { value: 'pedido', label: 'Pedido' },
  { value: 'carrinho', label: 'Carrinho' },
];

export const Status = [
  { value: '', label: 'TODOS' },
  { value: 'RECUPERADO', label: 'RECUPERADO' },
  { value: 'CANCELADO', label: 'CANCELADO' },
  { value: 'NA FILA', label: 'NA FILA' },
  { value: 'AGENDADO', label: 'AGENDADO' },
  { value: 'nao_pago', label: 'PEDIDOS NÃO PAGOS' },
  { value: 'aguandando_pgto', label: 'AGUARDANDO PAGAMENTO' },
];
