import { makeStyles } from '@material-ui/core';
import {
  CartIcon,
  UserIcon,
  UserLegalIcon,
  UserNaturalIcon,
} from '../../../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  cardHeaderGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 14,
  },
  iconHeader: {
    display: 'flex',
    width: 48,
    height: 48,
    borderRadius: '100%',
    backgroundColor: '#FE5000 !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    background: `url(${UserIcon})`,

    '&.cart': {
      background: `url(${CartIcon})`,
    },
    '&.individual-person': {
      background: `url(${UserNaturalIcon})`,
    },
    '&.corporate-person': {
      background: `url(${UserLegalIcon})`,
    },

    [breakpoints.down('sm')]: {
      width: 40,
      height: 40,
      backgroundSize: 16,
    },
  },
  typography: {
    fontWeight: 600,
    maxWidth: 350,
    textAlign: 'center',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    wordBreak: 'break-word',

    [breakpoints.down('sm')]: {
      fontSize: 16,
      maxWidth: 235,
    },
  },
  typographySubtitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 500,
    color: '#7B7F82',
    fontSize: 14,
    '& .MuiButton-root': {
      padding: 0,
      margin: 0,
      textTransform: 'capitalize',
      color: '#FF5225',
      textDecoration: 'underline',
    },

    [breakpoints.down('sm')]: {
      '& span': {
        fontSize: 12,
      },
    },
  },
}));

export default useStyles;
