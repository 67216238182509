import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

const ContentGuarantees = () => {
  const classes = useStyles();
  const {
    content,
  } = classes;

  return (
    <Box className={content}>
      <Typography>
        A <span>Garantia Estendida</span> é um seguro que garante, após o término da garantia do fabricante, o
        conserto ou troca de produtos caso apresentem defeitos. Basta adquirir a garantia na hora
        da compra e ela passa a valer assim que a garantia do fabricante termina, garantindo as 
        mesmas coberturas e exclusões oferecidas pela garantia original do fornecedor.
      </Typography>

      <br/>
      <Typography variant="h2">Como funciona?</Typography>
      <Typography>
        A Garantia Estendida Loja do Mecânico começa a vigorar após o término da garantia original de fábrica de acordo,
        e terá a duração conforme o plano contratado (12 ou 24 meses), que será indicado no seu bilhete de seguro.
      </Typography>

      <br/>
      <Typography variant="h2">Objetivo?</Typography>
      <Typography>
        O objetivo principal deste seguro é realizar os reparos cobertos no produto, se ocorrer alguma falha ou mau 
        funcionamento. A troca do produto por um novo apenas ocorrerá quando não for possível o reparo, sendo classificado 
        como perda total, nos Termos das Condições Gerais do Seguro.
      </Typography>

      <br/>
      <Typography variant="h2">Outras Informações?</Typography>
      <Typography>
        As condições de cobertura e utilização do seu seguro estão detalhadas nas Condições Gerais do produto, 
        que podem ser consultadas no site da Seguradora www.180s.com.br
      </Typography>

      <br/>
      <Typography>
        Recomenda-se que mantenha em arquivo o certificado de garantia do fabricante do produto adquirido durante a 
        vigência do presente seguro.
      </Typography>

      <br/>
      <Typography>
        Seguro Garantia Estendida Original oferecido por 180 Seguros S.A, CNPJ: 39.999.619/0001-97 e processo SUSEP 
        Nº  15414.600393/2024-47. Ouvidoria 180 Seguros S.A. 
        <br/>
        Telefone: <span>0800 723 1454</span> E-mail: <a href="mailto:ouvidoria@180s.com.br">ouvidoria@180s.com.br</a>
      </Typography>

      <br/>
      <Typography>
        O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP. A 
        comercialização de seguro é fiscalizada pela Susep. O segurado poderá consultar a situação cadastral da sociedade 
        seguradora no sítio eletrônico <a href="https://www.susep.gov.br/" target="_blank">www.susep.gov.br</a> ou <span>0800 021 8484.</span>
      </Typography>

      <br/>
      <Typography>
        A contratação de seguro é opcional, sendo possível a desistência do contrato em até 7 (sete) dias corridos com a 
        devolução integral do valor pago. É proibido condicionar desconto no preço do bem à aquisição do seguro.
      </Typography>
    </Box>
  );
};


export default ContentGuarantees;
