import { makeStyles } from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  addMore: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 16px',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
    color: 'rgba(0,0,0,.72)',
    transition: 'all ease 180ms',
    border: 'none',
    borderBottom: '1px solid rgba(0,0,0,.12)',
    backgroundColor: 'transparent',
    outline: 'none',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  divPayment: {
    height: '70px',
    backgroundColor: 'rgb(250, 250, 250)',
    borderBottom: '1px solid #CDCDCD',
    [breakpoints.down('xs')]: {
      height: '150px',
      backgroundColor: 'rgb(250, 250, 250)',
      borderBottom: '1px solid #CDCDCD',
    },
  },
  divPaymentDialog: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    padding: '16px 16px 25px 16px',
    [breakpoints.down('xs')]: {
      paddingBottom: '5px',
    },
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  chipError: {
    backgroundColor: palette.error.main,
    color: 'white',
  },
  chipWarn: {
    backgroundColor: yellow[800],
    color: 'black',
    fontSize: 12,
    fontWeight: 'bold',
    paddingRight: 10,
  },
  chipWarnSusp: {
    backgroundColor: '#F9A825',
  },
  chipSucess: {
    backgroundColor: green[800],
    color: 'white',
  },
  chipSilver: {
    marginLeft: -20,
  },
  divTypePayment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 300,
    marginRight: 30,
    [breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
      marginRight: 0,
      textAlign: 'right',
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  divContaineer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,

    },
  },
  divValor: {
    width: 137,
    [breakpoints.down('xs')]: {
      width: 120,
    },
  },
  divValorFreight: {
    width: 95,
    marginLeft: 25,
    [breakpoints.down('xs')]: {
      width: 75,
      marginLeft: 0,
    },
  },
  textTitlePayment: {
    fontSize: 12,
    [breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  divColor: {
    backgroundColor: 'rgb(196, 196, 196,  0.2)',
    width: 159,
    height: 55,
    paddingTop: 5,
    paddingLeft: 5,
    [breakpoints.down('xs')]: {
      backgroundColor: 'rgb(196, 196, 196,  0.2)',
      padding: 10,
      width: 110,
      height: 50,
    },
  },
  textValues: {
    fontSize: 14,
    [breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  iconAdd: {
    fontSize: 20,
    color: '#666666',
    marginLeft: 40,
    marginTop: 35,
    [breakpoints.down('xs')]: {
      fontSize: 20,
      color: '#666666',
      marginTop: 30,
      marginLeft: 0,
    },
  },
  iconEquals: {
    fontSize: 20,
    color: '#666666',
    marginTop: 35,
    marginRight: 25,
    marginLeft: 25,
    [breakpoints.down('xs')]: {
      fontSize: 20,
      color: '#666666',
      marginTop: 30,
      marginRight: 0,
      marginLeft: 0,
    },
  },
  divFreight: {
    backgroundColor: 'rgb(196, 196, 196,  0.2)',
    width: 95,
    height: 55,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      backgroundColor: 'rgb(196, 196, 196,  0.2)',
      width: 75,
      height: 50,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',

    },
  },
  divBoxTotal: {
    width: '25%',
    minWidth: 100,
    height: 70,
    backgroundColor: 'rgb(196, 196, 196,  0.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      width: 110,
      height: 50,
      backgroundColor: 'rgb(196, 196, 196,  0.2)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
    },
  },
  divContainerDialog: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#F5F5F5',
    margin: '10px 0',
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: 5,

    },
  },
  divValorDialog: {
    width: 139,
    height: 55,
    [breakpoints.down('xs')]: {
      width: 90,
    },
  },
  divColorDialog: {
    backgroundColor: 'rgb(196, 196, 196,  0.2)',
    width: 137,
    height: 29,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      backgroundColor: 'rgb(196, 196, 196,  0.2)',
      width: 90,
      height: 40,
      paddingLeft: 5,
    },
  },
  divDialogAdvanced: {
    backgroundColor: 'rgb(196, 196, 196,  0.2)',
    width: 150,
    height: 50,
    borderRadius: 4,
    display: 'flex',
    paddingLeft: 5,
    paddingTop: 5,
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      backgroundColor: 'rgb(196, 196, 196,  0.2)',
      width: 90,
      height: 40,
      paddingLeft: 5,
    },
  },
  iconAddDialog: {
    fontSize: 20,
    color: '#666666',
    margin: '0 10px 0 15px',
    marginTop: 30,
    marginLeft: 20,
    [breakpoints.down('xs')]: {
      fontSize: 15,
      color: '#666666',
      marginTop: 10,
      marginLeft: 0,
    },
  },
  iconEqualsDialog: {
    fontSize: 20,
    color: '#666666',
    margin: '0 8px',
    marginTop: 30,
    [breakpoints.down('xs')]: {
      fontSize: 15,
      color: '#666666',
      marginTop: 10,
      marginRight: 0,
    },
  },
  divFreightDialog: {
    backgroundColor: 'rgb(196, 196, 196,  0.2)',
    height: 50,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    [breakpoints.down('xs')]: {
      backgroundColor: 'rgb(196, 196, 196,  0.2)',
      height: 40,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',

    },
  },
  divBoxTotalDialog: {
    width: 142,
    height: 100,
    marginLeft: 10,
    borderRadius: 4,
    backgroundColor: 'rgb(196, 196, 196,  0.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: 110,
      height: 50,
      backgroundColor: 'rgb(196, 196, 196,  0.2)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
    },
  },
  textTitlePaymentDialog: {
    fontSize: 13,
    [breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  buttonGerar: {
    display: 'flex',
    backgroundColor: '#FF5225',
    color: '#FFFF',
    width: 300,
    borderRadius: 4,
    height: 30,
    marginBottom: 3,
    '&:disabled': {
      backgroundColor: 'rgba(196, 196, 196, 0.2)',
      color: '#FFFF',
    },
    [breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  gridInfo: {
    display: 'flex',
    height: 200,
  },
  gridPix: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 250,
    width: 200,

  },
  gridInfoGray: {
    backgroundColor: '#FAFAFA',
    display: 'flex',
    height: 100,
    width: 100,
    marginTop: 30,
    borderRadius: 4,
    flexDirection: 'column',
    paddingLeft: 5,
  },
  divWarning: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    color: '#F44336',
    marginBottom: 10,
    marginTop: 10,
  },
  divItemsPix: {
    display: 'flex',
    height: 150,
    alignItems: 'center',
    flexDirection: 'row',
    [breakpoints.down('sm')]: {
      display: 'block',
      height: 'inherit',
      width: 'fit-content',
      margin: 'auto',
    },
  },
  divInfoOne: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      height: 150,
      width: 'fit-content',
      margin: 'initial',
    },
  },
  divInfoTwo: {
    display: 'flex',
    height: 100,
    marginLeft: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [breakpoints.down('sm')]: {
      height: 150,
      width: 'fit-content',
      margin: 'auto',
    },
  },
  divInfoTwoPix: {
    display: 'flex',
    flexDirection: 'column',
    height: 130,
    paddingLeft: 10,
    marginLeft: 10,
    justifyContent: 'space-between',
  },
  divEnvio: {
    backgroundColor: 'rgba(220,220,220, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 46,
    minWidth: 177,
    borderRadius: 4,
    cursor: 'pointer',
  },
  divRequest: {
    backgroundColor: '#FF5225',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 177,
    height: 46,
    marginLeft: 20,
    borderRadius: 4,
    cursor: 'pointer',
  },
  itemPixTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 200,
    height: 50,
    borderRadius: 4,
    backgroundColor: 'rgba(220,220,220, 0.2)',
    cursor: 'pointer',
  },
  itemPixBottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 200,
    marginTop: 10,
    borderRadius: 4,
    height: 50,
    backgroundColor: 'rgba(220,220,220, 0.2)',
    cursor: 'pointer',
  },
  divInfoPix: {
    display: 'flex',
    height: 70,
    marginTop: 20,
    borderRadius: 4,
    flexDirection: 'row',
    backgroundColor: 'rgba(220,220,220, 0.2)',
    padding: 0,
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  infoPix: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
  },
  circleIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 32,
    height: 32,
    borderRadius: 16,
    marginTop: 3,
    marginLeft: 5,
    backgroundColor: '#FF5225',
  },
  divQrCodeValue: {
    display: 'flex',
    flexDirection: 'row',
  },
  gridPixSmsEmailWhats: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    width: 200,
  },
  divButtons: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 150,
    marginTop: 15,
  },
  divEntryItemsPix: {
    display: 'flex',
    height: 150,
    alignItems: 'center',
    flexDirection: 'column',
  },
  divButton: {
    display: 'flex',
    flexDirection: 'row',
    width: 400,
    marginTop: 25,
  },
  divEmail: {
    marginTop: 5,
  },
  cartaogrid: {
    display: 'flex',
    width: 200,
  },
}));

export default useStyles;
