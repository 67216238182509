import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  clientCell: {
    textTransform: 'uppercase',
    [breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: 300,
      textOverflow: 'ellipsis',
    },
  },
  buttonDetails: {
    backgroundColor: '#FF5225',
    height: 24,
    width: 90,
    color: '#ffff',
    fontSize: 10,
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  divSearch: {
    width: 400,
    right: 215,
    zIndex: 5,
    float: 'left',
    marginLeft: 32,
    marginRight: 20,
  },
  divSearchIconContainerFilter: {
    backgroundColor: '#2296F3',
    width: 42,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '-13px',
    borderRadius: '60px',
    opacity: 0.50,
    right: 640,
    float: 'left',
    '&:hover': {
      opacity: 1,
    },
    '&:disabled': {
      backgroundColor: '#4A4C55',
    },
  },
  divSearchIconContainer: {
    backgroundColor: '#4A4C55',
    width: 48,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '-13px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    '&:hover': {
      backgroundColor: '#1D1F2A',
    },
    '&:disabled': {
      backgroundColor: '#4A4C55',
    },
  },
  textsSilver: {
    color: '#888E9D',
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  textsSilverParametrizacao: {
    color: '#888E9D',
    fontSize: 14,
    overflow: 'hidden',
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#F3F4F6',
    height: 58,
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'none',
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  labelStatusNovo: {
    width: 100,
    height: 25,
    borderRadius: 14,
    backgroundColor: '#F2F7FF',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  divRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonFiltro: {
    backgroundColor: '#FF5225',
    height: 36,
    width: 130,
    color: '#ffff',
    fontSize: 12,
    marginTop: 5,
    marginLeft: 5,
    fontFamily: ['Poppins', 'sans serif'].join(','),
  },
  fontSilverOrcamentoModal: {
    fontSize: 10,
    color: '#888E9D',
  },
  fontSilverModal: {
    fontSize: 11,
    marginBottom: 5,
    color: '#888E9D',
  },
  infosFileirasDialog: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 35px 20px 25px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divLineModal: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelStatusNovoModal: {
    width: 100,
    height: 25,
    borderRadius: 14,
    backgroundColor: '#FFF6C2',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  buttonTradeVendedorModal: {
    backgroundColor: '#F3F4F6',
    marginTop: 15,
  },
  sessionTable: {
    marginTop: 20,
    marginBottom: 70,
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
  sessionTableParametrizacao: {
    marginBottom: 70,
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    padding: 24,
  },
  titleAndButton: {
    width: '100%',
    marginTop: 20,
    paddingRight: 30,
    paddingLeft: 30,
    justifyContent: 'space-between',
    alignItems: 'space-between',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: 24,
  },
  titleAndButtonSupervisor: {
    width: '100%',
    marginTop: 20,
    paddingRight: 100,
    paddingLeft: 100,
    justifyContent: 'space-between',
    alignItems: 'space-between',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: 24,
  },
  buttonTitle: {
    backgroundColor: '#FF5225',
    color: '#ffff',
  },
  buttonTitleParametrizacao: {
    backgroundColor: '#21AD34',
    color: '#ffff',
  },
  buttonTitleBack: {
    backgroundColor: 'rgba(189, 193, 202, 0.5)',
    color: '#000',
  },
  buttonTitleBackParametrizacao: {
    color: '#909599',
  },
  boxEdit: {
    borderRadius: 6,
    backgroundColor: 'rgba(242, 247, 255, 1)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxDelet: {
    borderRadius: 6,
    backgroundColor: 'rgba(254, 153, 153, 0.1)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxSucess: {
    display: 'flex',
    borderRadius: 6,
    backgroundColor: 'rgba(0, 179, 76, 0.1)',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#00B34C',
    fontSize: 14,
    minWidth: 20,
    height: 20,
  },
  boxButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonEditarAcesso: {
    backgroundColor: '#F3F4F6',
    height: 36,
    marginTop: 32,
    marginLeft: 40,
  },
  textEditAcess: {
    color: '#888E9D',
    padding: 3,
    fontSize: 13,
  },
  buttonCriar: {
    backgroundColor: '#FF5225',
    color: '#ffff',
    width: 160,
    marginTop: 25,
    '&:disabled': {
      backgroundColor: 'rgba(196, 196, 196, 0.2)',
      color: '#FFFF',
    },
  },
  buttonSalvar: {
    backgroundColor: '#21AD34',
    color: '#ffff',
    width: 160,
    marginTop: 24,
    '&:hover': {
      backgroundColor: '#21AD19',
      color: '#FFFF',
    },
  },
  buttonCancelar: {
    backgroundColor: '#F4282D',
    color: '#ffff',
    width: 160,
    marginTop: 24,
    marginRight: 24,
    '&:hover': {
      backgroundColor: '#F4281D',
      color: '#FFFF',
    },
  },
  divButtonCriar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  checkboxandButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  channelButton: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'center',
  },
  officeButton: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'flex-end',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 600,
    alignItems: 'center',
    backgroundColor: '#ffff',
    borderRadius: 4,
  },
  helperMessage: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: -18,
      left: -8,
      fontSize: 10,
      fontWeight: '600',
    },
  },
  titleAndButtonParametrizacao: {
    width: '100%',
    marginTop: 40,
    paddingRight: 24,
    paddingLeft: 24,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: 18,
  },
  borderTable: {
    border: '1px #E3E3E7 solid',
    color: '#888E9D',
  },
  editarParametrização: {
    width: 89,
    backgroundColor: '#F3F4F6',
    color: '#000',
    fontWeight: 600,
    fontSize: 10,
  },
}));

export default useStyles;
