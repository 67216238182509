import React from 'react';
import { Route } from 'react-router-dom';
import SuggestionPage from './Page';
import CustomSwitch from '../../shared/components/customSwitch';

const SuggestionRouter = () => (
  <CustomSwitch>
    <Route
      path="/suggestion"
      component={SuggestionPage}
      exact
    />
  </CustomSwitch>
);

export default SuggestionRouter;
