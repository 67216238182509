import React from 'react';
import { useToggleRefinement } from 'react-instantsearch';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles, { SwitchStyled as Switch } from './styles';

const ToggleRefinement = ({ refinement }) => {
  const {
    options,
    borderTop,
    title,
    children,
  } = refinement;
  const { value, refine, canRefine } = useToggleRefinement({ ...options });
  const classes = useStyles();

  const {
    contentToggle,
    toggleBox,
  } = classes;

  const handleRefine = (newValue) => refine({ isRefined: newValue });

  return (
    <Box className={`${toggleBox} ${borderTop && ('borderTop')}`}>
      <Switch
        onChange={(e) => handleRefine(!e.target.checked)}
        name={title}
        checked={value.isRefined}
        disabled={!canRefine}
      />
      <div className={contentToggle}>{children}</div>
    </Box>
  );
};

ToggleRefinement.propTypes = {
  refinement: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Node).isRequired,
};

export default ToggleRefinement;
