import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { TableHead, TableRow, TableCell, TableBody, TablePagination, LinearProgress, Box, Chip, Typography, Card, Paper, Badge } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import CardBar from '../../shared/components/cardBar';
import ResponsiveTable from '../../shared/components/responsiveTable';
import { toCNPJ, toCPF, toMoney } from '../../shared/utils/parsers';

import FilterCreditRequest from './filterCreditRequest/FilterCreditRequest';
import { FilterList } from '@material-ui/icons';

import useStyles from './styles';
import { getListCreditRequestsAction, getVendedoresSimplificadoAction, toggleFilterAction } from './actions';
import { FILTER_SALLES_CHANNEL } from '../../shared/utils/creditRequests';
import { getCanalDeVendas } from '../../shared/services/app';
import TableCellHeaderOrdination from '../../shared/components/tableCellHeaderOrdination/TableCellHeaderOrdination';

const CreditRequestsPage = ({ 
		rpp,
		user,
		count,
		idUser,
		loading,
		currentPage,
		toggleFilter,
		filterOpened,
		creditRequests,
		getListCreditRequests
	}) => {
	const classes = useStyles();

	const [dataInicio, setDataInicio] = useState(moment(new Date()).subtract(1, 'month').format('DD-MM-YYYY'));
  const [dataFim, setDataFim] = useState(moment(new Date()).format('DD-MM-YYYY'));

	const handleChangePage = (_, page) => {
		if (!loading) getListCreditRequests({ dataInicio, dataFim, page: page + 1, rpp });
	};

	const handleChangeRpp = ({ target }) => {
		if (!loading) getListCreditRequests({ dataInicio, dataFim, page: 1, rpp: target.value });
	};

	const handleToggleFilter = () => toggleFilter({ filterOpened: !filterOpened });
	const handleUrl = url => window.open(`${window.location.origin}/checkout/${url}`, '_blank');

	const showCanaisDeVenda = FILTER_SALLES_CHANNEL.includes(user.idCargo);

	const getIdCanalVenda = () => {
		return showCanaisDeVenda ? getCanalDeVendas() : '';
	}

	const handleFilter =(dataInicioFilter, dataFimFilter) => {
		if (loading) return;
		const idCanalVendas = getIdCanalVenda();

		getListCreditRequests({ 
			dataInicio: dataInicioFilter || dataInicio,
			dataFim: dataFimFilter || dataFim,
			page: 1,
			idUser,
			rpp: 10,
			idCanalVendas
		});
	};

	const [ordinationColumn, setOrdinationColumn] = useState('');
	const [ordinationDirection, setOrdinationDirection] = useState('Desc');
	
	const handleOrdination = (column) => {
		const idCanalVendas = getIdCanalVenda();

		if (column === ordinationColumn) {
			setOrdinationDirection(ordinationDirection === 'Asc' ? 'Desc' : 'Asc');
		} else {
			setOrdinationColumn(column);
			setOrdinationDirection('Asc');
		}

		getListCreditRequests({ dataInicio, dataFim, page: 1, idUser, rpp: 10, idCanalVendas, ordenacao: ordinationDirection, campoOrdenacao: column });
	}
	const statusColorMap = {
		Aberto: 'yellow',
		Aprovado: 'green',
		Pendente: 'orange',
		Reprovado: 'red',
		'Aprovado com Entrada': 'rgb(6, 102, 6)'
	};

	const getStatusColor = (color) => {
		return statusColorMap[color] || 'grey';
	}

	useEffect(() => {
		if (loading) return;

		const idCanalVendas = getIdCanalVenda();

		getListCreditRequests({ dataInicio, dataFim, page: 1, idUser, rpp: 10, idCanalVendas });
	}, [getListCreditRequests]);

	return (
		<section>
			<CardBar title="Painel de Solicitações de Crédito">
				<TablePagination
					rowsPerPageOptions={[10, 20, 40]}
					component="div"
					count={count}
					rowsPerPage={rpp}
					page={currentPage - 1}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRpp}
					labelRowsPerPage="Registros:"
				/>
				<IconButton onClick={handleToggleFilter}>
          <FilterList />
        </IconButton>
			</CardBar>

			<FilterCreditRequest
				open={filterOpened}
				handleFilter={handleFilter}
				onSubmit={handleFilter}
				setFilterDataInicio={setDataInicio}
				setFilterDataFim={setDataFim}
				filterDataInicio={dataInicio}
				filterDataFim={dataFim}
				loading={false}
			/>

			{loading && <LinearProgress />}

			<ResponsiveTable striped>
				<TableHead>
					<TableRow
						className={classes.tableHeadRow}
						classes={{
							root: classes.oddRow,
						}}
					>
						<TableCell colSpan={1} className={classes.tableHeadCell} component="th">
							Orçamento
						</TableCell>
						<TableCell colSpan={1} className={classes.tableHeadCell} component="th">
							Dados do Cliente
						</TableCell>
						<TableCell colSpan={1} className={classes.tableHeadCell} component="th">
							Vendedor
						</TableCell>
						<TableCell colSpan={1} className={classes.tableHeadCell} component="th">
							Canal de Vendas
						</TableCell>
						<TableCell colSpan={1} className={classes.tableHeadCell} component="th">
							Status
						</TableCell>
						<TableCell colSpan={1} className={classes.tableHeadCell} component="th">
							Crédito Solicitado
						</TableCell>
						<TableCell colSpan={1} className={classes.tableHeadCell} component="th">
							Pedido
						</TableCell>
						<TableCellHeaderOrdination 
							reference='dataSolicitacao'
							column={ordinationColumn}
							ordination={ordinationDirection}
							handleOrdination={handleOrdination}
							colSpan={1}
							component="th"
						>
							Data da Solicitação
						</TableCellHeaderOrdination>
						<TableCell colSpan={1} className={classes.tableHeadCell} component="th">
							Detalhes
						</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>

				<TableBody>
					{loading && (
						<TableRow>
							<TableCell colSpan={10} datatype="Mensagem" className="message">
								Buscando solicitações de desconto...
							</TableCell>
						</TableRow>
					)}

					{!loading && !creditRequests.length && (
						<TableRow>
							<TableCell colSpan={10} datatype="Mensagem" className="message">
								Nenhuma solicitação foi encontrada
							</TableCell>
						</TableRow>
					)}

					{creditRequests.map((item, index) => (
						<TableRow className={classes.tableBodyRow} key={`row-${index + 1}`}>
							<TableCell datatype="Orçamento">
								<Typography variant="subtitle2" className={item.pedidoId ? classes.tableBodyCellClickableDisabled : classes.tableBodyCellClickable}>
									<Box
										onClick={() => !item.pedidoId && handleUrl(item.solicitacaoOrcamento)}
										component="span"
										display="flex"
										alignItems="center"
										justifyContent="center"
										gridGap={6}
									>
										{item.solicitacaoOrcamento || 'N/D'}
										{item.solicitacaoOrcamento && !item.pedidoId && (
											<Icon sx={{ fontSize: 7 }} fontSize="inherit">
												open_in_new
											</Icon>
										)}
									</Box>
								</Typography>
							</TableCell>

							<TableCell datatype="Cliente" className={classes.tableBodyCellCustomer}>
								<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
									{item.solicitacaoClienteNome}
								</Box>
								<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
									{item.solicitacaoClienteDocumento.length === 11 ? (
										toCPF(item.solicitacaoClienteDocumento)
									) : (
										toCNPJ(item.solicitacaoClienteDocumento)
									)}
								</Box>
							</TableCell>

							<TableCell className={classes.tableBodyCell} datatype="Nome Vendedor">
								<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
									{item.solicitacaoVendedorNome}
								</Box>
							</TableCell>

							<TableCell className={classes.tableBodyCellCanalVenda} datatype="Canal de Venda">
								<Box whiteSpace="nowrap" component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
									{item.solicitacaoVendedorCanalVendas}
								</Box>
							</TableCell>

							<TableCell className={classes.tableBodyCell} datatype="Status">
								<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
									<Chip label={item.solicitacaoStatus} color="primary" style={
										{
											backgroundColor: getStatusColor(item.solicitacaoStatus), 
											color: item.solicitacaoStatus === 'Aberto' ? '#000000DE' : 'white',
											fontWeight: 600
										}
									}/>
								</Box>
							</TableCell>

							<TableCell className={classes.tableBodyCell} datatype="Data">
								<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
									{toMoney(item.solicitacaoLimiteSolicitado)}
								</Box>
							</TableCell>

							<TableCell className={classes.tableBodyCell} datatype="Pedido">
								<Box display="flex" justifyContent="center" alignItems="center" gridGap={4}>
									<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
										{item.pedidoId  || '-'}
									</Box>
									{item.pedidoId && item.pedidoStatus && (
										<Box 
											className={classes.orderStatus} 
											style={{
												background: (
													item.pedidoStatus === 'Aprovado' ? 'green' : 
													item.pedidoStatus === 'Pendente' ? 'orange' :
													item.pedidoStatus === 'Reprovado' ? 'red' : 'gray'
												)
											}} 
											fontSize="inherit"
										/>
									)}
								</Box>
							</TableCell>

							<TableCell className={classes.tableBodyCell} datatype="Data">
								<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
									{item.solicitacaoData}
								</Box>
							</TableCell>

							<TableCell className={classes.tableBodyCell} datatype="Detalhes">
								<Box component="span" style={{ cursor: 'text' }} onClick={e => e.stopPropagation()}>
									{item.solicitacaoTimePendente || '-'}
								</Box>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</ResponsiveTable>
		</section>
	);
};

CreditRequestsPage.propTypes = {
	getListCreditRequests: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	toggleFilter: PropTypes.func.isRequired,
	filterOpened: PropTypes.bool.isRequired,
	creditRequests: PropTypes.arrayOf(Object).isRequired,
	currentPage: PropTypes.number.isRequired,
	idUser: PropTypes.number.isRequired,
	totalpages: PropTypes.number.isRequired,
	rpp: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	user: PropTypes.instanceOf(Object).isRequired,
};

const mapState = state => ({
	...state.main.creditRequests,
	idUser: state.auth.login.user.idVendedor,
	drawerOpenned: state.main.app.drawer,
	user: state.auth.login.user,
});

export default connect(mapState, {
	getListCreditRequests: getListCreditRequestsAction,
	getVendedoresSimplificado: getVendedoresSimplificadoAction,
	toggleFilter: toggleFilterAction
})(CreditRequestsPage);
