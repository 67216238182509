import { Button } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';

const CustomButtom = ({
  children, color, hoverColor, className, variant, ...rest
}) => {
  const classes = useStyles({
    color,
    hoverColor,
    variant,
  });

  return (
    <Button
      variant={variant}
      className={`${classes.root} ${className}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Button>
  );
};

CustomButtom.defaultProps = {
  variant: 'text',
  hoverColor: null,
  className: '',
};

CustomButtom.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  variant: PropTypes.oneOf(['outlined', 'contained', 'text']),
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string,
  className: PropTypes.string,
};

export default CustomButtom;
