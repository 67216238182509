import { makeStyles } from '@material-ui/core';
import {
  LocationIcon,
  LocationIconGray,
  LogoutIcon,
  LogoutIconOrange,
  OrdersIcon,
  OrdersIconGray,
  UserIconGray,
  UserIconOrange,
  UserSettingsIcon,
  UserSettingsIconGray,
  VouchersIcon,
  VouchersIconGray,
} from '../../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  clientMenuBoxHeader: {
    display: 'flex',
    height: 80,
    alignItems: 'center',
    minWidth: 159,
    paddingLeft: 16,
    justifyContent: 'space-between',
    gap: 10,

    [breakpoints.up('md')]: {
      borderLeft: '1px solid #CFCFD4',
    },
  },
  clientMenuBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,

    '& > span:first-of-type': {
      fontSize: 12,
      lineHeight: '18px',
      color: '#7B7F82',

      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    '& span:nth-last-child(2)': {
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 600,
      maxWidth: 160,
      textOverflow: 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      display: '-webkit-box',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },

    [breakpoints.down('sm')]: {
      color: '#FFFFFF !important',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10,
    },
  },
  arrowToggleBtn: {
    minWidth: 24,
    height: 24,
    padding: 0,
  },
  arrowIcon: {
    width: 8,
    height: 8,
    transform: 'rotate(135deg)',
    borderTop: '2px solid #FE5000',
    borderRight: '2px solid #FE5000',
    borderRadius: 0,
    zIndex: -1,

    '&.open': {
      transform: 'rotate(315deg)',
    },

    [breakpoints.down('sm')]: {
      borderColor: '#FFFFFF',
    },
  },
  customerSpace: {
    padding: 12,
    fontSize: 12,
    lineHeight: '18px',
    marginBottom: 8,
    textTransform: 'none',
    width: '100%',
    justifyContent: 'start',
    borderRadius: 8,

    '& .MuiButton-label': {
      gap: 10,

      '& span:first-child': {
        width: 16,
        height: 16,
        backgroundSize: 'contain !important',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center !important',
        background: `url(${UserIconGray})`,

        '&.user-settings': {
          background: `url(${UserSettingsIconGray})`,
        },

        '&.location': {
          background: `url(${LocationIconGray})`,
        },

        '&.orders': {
          background: `url(${OrdersIconGray})`,
        },

        '&.vouchers': {
          background: `url(${VouchersIconGray})`,
        },

        '&.logout': {
          background: `url(${LogoutIcon})`,
        },
      },
    },

    '&:hover': {
      backgroundColor: '#F6F6F7',
      color: '#FE5000',

      '& .MuiButton-label': {
        '& span:first-child': {
          background: `url(${UserIconOrange})`,

          '&.user-settings': {
            background: `url(${UserSettingsIcon})`,
          },

          '&.location': {
            background: `url(${LocationIcon})`,
          },

          '&.orders': {
            background: `url(${OrdersIcon})`,
          },

          '&.vouchers': {
            background: `url(${VouchersIcon})`,
          },

          '&.logout': {
            background: `url(${LogoutIconOrange})`,
          },
        },
      },
    },
  },
  customerSpacePopover: {
    transform: 'translate3d(0px, 45px, 0px) !important',

    [breakpoints.down('sm')]: {
      top: '45px !important',
      left: '0 !important',
      transform: 'none !important',
    },
  },
  customerSpacePopoverPaper: {
    right: '10px !important',
    top: '8px !important',
    left: 'auto !important',
    background: 'none',

    [breakpoints.down('sm')]: {
      right: '0px !important',
      // left: '50% !important',
      // transform: 'translate(-50%, 0) !important',
    },

    [breakpoints.down('xs')]: {
      minWidth: 241,
    },
  },
  customerSpacePaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #CFCFD4',
    width: 240,
    maxHeight: 335,
    padding: 16,
    boxShadow: 'initial',
    borderRadius: 16,
    backgroundColor: '#FFFFFF',

    '& div:last-child': {
      // borderTop: '1px solid #CFCFD4',

      '& button': {
        marginTop: 8,
      },
    },

    [breakpoints.down('xs')]: {
      minWidth: 243,
      borderRadius: 8,
    },
  },
  arrowPopover: {
    position: 'relative',
    height: 23,
    display: 'block',

    '&:before': {
      content: '""',
      display: 'block',
      width: 32,
      height: 32,
      position: 'absolute',
      background: '#FFFFFF',
      transform: 'rotate(45deg)',
      border: '1px solid #CFCFD4',
      top: 8,
      right: 30,
      borderRight: 0,
      borderBottom: 0,
      borderRadius: '8px 0',

      [breakpoints.down('sm')]: {
        right: 16,
      },
    },
  },
  clientSignInBtn: {
    color: '#FE5000',
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'capitalize',
    textDecoration: 'underline',

    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },

    [breakpoints.down('sm')]: {
      color: '#FFFFFF',
    },
  },
  clientName: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#000000',
    fontWeight: 600,

    [breakpoints.down('sm')]: {
      color: '#FFFFFF',
      textTransform: 'capitalize',
    },
  },
  avatarMobile: {
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    color: '#FE5000',
    display: 'none',

    [breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 600,
    },
  },
}));

export default useStyles;
