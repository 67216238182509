/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField, LinearProgress, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const AutocompleteRender = (props) => {
  const {
    input,
    options,
    model,
    label,
    loading,
    handleSearch,
    meta: { touched, error },
    ...rest
  } = props;

  const onSearch = (event) => {
    const { value } = event.target;

    if (!value) {
      input.onChange(undefined);
    }

    if (handleSearch) {
      handleSearch(value);
    }
  };

  const onClear = (event) => {
    if (!event) {
      return;
    }

    const { target: { value } } = event;
    if (!value && typeof value !== 'number') {
      handleSearch();
    }
  };

  const onChange = (_event, item) => {
    if (item) {
      return input.onChange(item[model.id]);
    }

    return input.onChange(undefined);
  };

  return (
    <Box className={rest.className} width="100%">
      <Autocomplete
        {...rest}
        fullWidth
        options={options || []}
        onChange={onChange}
        onInputChange={onClear}
        openText="Expandir"
        closeText="Fechar"
        value={(options || []).find((option) => option[model.id] === input.value) || null}
        lang="pt-br"
        noOptionsText="Sem opções"
        getOptionLabel={(option) => option[model.name]}
        renderOption={(option) => (
          option[model.name]
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onSearch}
            label={label}
            error={touched && !!error}
            helperText={touched && error}
          />
        )}
      />
      {loading && <LinearProgress />}
    </Box>
  );
};

AutocompleteRender.defaultProps = {
  model: {
    id: 'id',
    name: 'name',
  },
  options: [],
  loading: false,
  handleSearch: () => {},
};

AutocompleteRender.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Object),
  model: PropTypes.instanceOf(Object),
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  handleSearch: PropTypes.func,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default AutocompleteRender;
