import { makeStyles } from '@material-ui/core';
import { CloseIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(() => ({
  closeSearchBoxBtn: {
    minWidth: 40,
    width: 40,
    height: 40,
    borderRadius: 4,
    border: '1px solid #CFCFD4',
    background: '#FFFFFF',
    padding: 8,
  },
  closeSearchIcon: {
    background: `url(${CloseIcon})`,
    display: 'flex',
    width: 24,
    height: 24,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));

export default useStyles;
