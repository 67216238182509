import React from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useStyles from './styles';
import { toMoney } from '../../../../../utils/parsers';

const RadioGroupGuarantees = ({
  valueRadioChecked,
  guaranteesOptions,
  handleGuaranteeId,
}) => {

  const classes = useStyles();
  const {
    guaranteeActions,
    selectGuarantee,
    selectedGuarantee,
    radioRecusa,
    guaranteeRecomendation,
    guaranteeRecomendationTriangle
  } = classes;

  return (
    <Box className={guaranteeActions}>
      <Box className={guaranteeRecomendation}>
        <Typography>Recomendado</Typography>
        <div className={guaranteeRecomendationTriangle}></div>
      </Box>
      <RadioGroup>
        {!!guaranteesOptions.length
          && guaranteesOptions.map(({
            periodo, valorGarantia, idGarantia,
          }) => (
            <FormControlLabel
              className={`${selectGuarantee} ${valueRadioChecked===idGarantia ? selectedGuarantee : ""}`}
              value={idGarantia}
              checked={valueRadioChecked===idGarantia}
              onChange={(e) => handleGuaranteeId(idGarantia)}
              control={<Radio className={radioRecusa} />}
              label={
                <Box display="flex" justifyContent="space-between">
                  <Typography>+ {periodo} meses de Garantia </Typography>
                  <Box minWidth={80}>
                    <Typography variant="body2">{toMoney(valorGarantia)}</Typography>
                  </Box>
                </Box>
              }
            />
          ))
        }
        <FormControlLabel
          className={`${selectGuarantee} ${valueRadioChecked === 0 ? selectedGuarantee : ""}`}
          value="0"
          checked={valueRadioChecked===0}
          onChange={(e) => handleGuaranteeId(0)}
          control={<Radio className={radioRecusa} />}
          label={<Typography>Somente Garantia de Fábrica </Typography>}
        />
      </RadioGroup>
    </Box>
  );
};

RadioGroupGuarantees.propTypes = {
  open: PropTypes.bool.isRequired,
  valueRadioChecked: PropTypes.number.isRequired,
  guaranteesOptions: PropTypes.instanceOf(Array).isRequired,
};

const mapState = (state) => ({
  loading: state.main.checkout.geral.loading,
});

export default connect(mapState)(RadioGroupGuarantees);
