import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '& > div': {
      overflowY: 'unset',
      overflowX: 'unset',
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 8,
      },
    },
    '&::-webkit-scrollbar-track, & ::-webkit-scrollbar-track': {
      backgroundColor: palette.background.level4,
      borderRadius: 12,
    },
    '&::-webkit-scrollbar, & ::-webkit-scrollbar': {
      width: 6,
      height: 6,
      background: palette.background.level4,
      borderRadius: '0 6px 6px 0',
    },
    '&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb': {
      background: '#b3b1b1',
      borderRadius: 12,
    },
  },
  productText: {
    fontSize: 12,
    color: '#707070',
  },
  pricesContainer: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },
  groupPrice: {
    minWidth: 114,
    [breakpoints.down('xs')]: {
      width: '100%',
      padding: '8px 0',
    },
  },
  oldPrice: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  currentPrice: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  chip: {
    padding: '0 8px',
    borderRadius: 10,
  },
  secondary: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  errorChip: {
    backgroundColor: palette.error.main,
    color: 'white',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  upload: {
    display: 'block',
    height: '30px',
    width: '248px',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    opacity: '0',
    cursor: 'pointer',
  },
  fileUpload: {
    textAlign: 'center',
    border: '1px dashed #2296F3',
    position: 'relative',
    cursor: 'pointer',
    width: '248px',
    height: '30px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EAF5FD',
  },
  buttonRemoveFile: {
    background: 'transparent',
    border: 'none',
    height: '15px',
    width: '15px',
    position: 'absolute',
    marginLeft: '224px',
    marginTop: '-23px',
  },
  boxContainer : {
    background: '#F6F6F7',
    borderRadius: '8px',
    border: '1px solid #CFCFD4',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  boxContainerSelected : {
    background: '#EBFAEF',
    border: '1px solid #21AD34',
  },
  typographyFreightPrimary: {
    fontWeight: 'bold',
    fontSize: '1rem'
  },
  typographyFreightSecondary: {
    fontWeight: '500',
    fontSize: '14px'
  }
}));

export default useStyles;
