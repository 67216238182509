import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    '& thead': {

      '& > tr': {
        backgroundColor: ({ striped }) => (
          striped ? palette.background.level2 : ''
        ),
      },
      '& th': {

      },
      display: ({ forceResponsive }) => forceResponsive && 'none',
      [breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    '& tr': {
      display: ({ forceResponsive }) => forceResponsive && 'flex',
      flexDirection: ({ forceResponsive }) => forceResponsive && 'column',
      [breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    '& tbody tr': {
      cursor: ({ pointer }) => pointer && 'pointer',
      color: '#888E9D',
      fontSize: 14,
      '&:nth-of-type(even)': {
        backgroundColor: ({ striped }) => (
          striped ? palette.background.level2 : ''
        ),
      },
    },
    '& td': {
      display: ({ forceResponsive }) => forceResponsive && 'flex',
      flexDirection: ({ forceResponsive }) => forceResponsive && 'row',
      justifyContent: ({ forceResponsive }) => forceResponsive && 'space-between',
      width: ({ forceResponsive }) => forceResponsive && '100%!important',
      '&:before': {
        content: ({ forceResponsive }) => forceResponsive && 'attr(datatype)',
        fontWeight: ({ forceResponsive }) => forceResponsive && 700,
        marginRight: ({ forceResponsive }) => forceResponsive && 8,
      },
      '&:not(:last-of-type)': {
        borderBottom: ({ forceResponsive }) => forceResponsive && 'none',
      },
      [breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%!important  ',
        '&:before': {
          content: 'attr(datatype)',
          fontWeight: 700,
          marginRight: 8,
        },
        '&:not(:last-of-type)': {
          borderBottom: 'none',
        },
      },
    },
    '& td.message': {

      textAlign: 'center',
      '&:before': {
        content: ({ forceResponsive }) => forceResponsive && 'none',
      },
      [breakpoints.down('xs')]: {
        '&:before': {
          content: 'none',
        },
      },
    },
  },
  message: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.19);',
    minHeight: 52,
  },
}));

export default useStyles;
