import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ transitions, palette, breakpoints }) => ({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 80px)',
    marginLeft: 64,
    marginTop: 80,
    backgroundColor: palette.background.level2,
    [breakpoints.down('xs')]: {
      height: 'calc(100vh - 55px)',
      marginTop: 54,
    },
    [breakpoints.down('sm')]: {
      paddingTop: 27,
      marginLeft: 0,
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 8,
      },
    },
    '&::-webkit-scrollbar-track, & ::-webkit-scrollbar-track': {
      backgroundColor: palette.background.level4,
      borderRadius: 12,
    },
    '&::-webkit-scrollbar, & ::-webkit-scrollbar': {
      width: 6,
      height: 6,
      background: palette.background.level4,
    },
    '&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb': {
      background: '#b3b1b1',
      borderRadius: 12,
    },

    '&.homePage': {
      backgroundColor: '#E3E3E7',
    },
  },
  rootCheckout: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 80px)',
    marginLeft: 66,
    marginTop: 80,
    backgroundColor: palette.background.level2,
    [breakpoints.down('xs')]: {
      height: 'calc(100vh - 55px)',
      marginTop: 54,
    },
    [breakpoints.down('sm')]: {
      height: '100vh',
      marginLeft: 0,
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 8,
      },
    },
    '&::-webkit-scrollbar-track, & ::-webkit-scrollbar-track': {
      backgroundColor: palette.background.level4,
      borderRadius: 12,
    },
    '&::-webkit-scrollbar, & ::-webkit-scrollbar': {
      width: 6,
      height: 6,
      background: palette.background.level4,
    },
    '&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb': {
      background: '#b3b1b1',
      borderRadius: 12,
    },
  },
  drawerOpen: {
    marginLeft: 256,
    transition: transitions.create('margin', { duration: '175ms' }),
  },
  drawerClose: {
    transition: transitions.create('margin', { duration: '255ms' }),
  },
}));

export default useStyles;
