import { handleActions, combineActions } from 'redux-actions';
import types from './types';

const INITIAL_STATE = {
  filterOpened: false,
  loading: false,
  currentPage: 1,
  count: 0,
  rpp: 10,
  totalpages: 1,
  creditRequests: [],
};

const startLoading = combineActions(
  types.GET_CREDIT_REQUESTS_REQUEST,
);

const stopLoading = combineActions(
  types.GET_CREDIT_REQUESTS_SUCCESS,
  types.GET_CREDIT_REQUESTS_FAILURE,
);

const list = handleActions({
  [startLoading]: (state) => ({
    ...state,
    loading: true,
  }),
  [stopLoading]: (state) => ({
    ...state,
    loading: false,
  }),
  [types.TOGGLE_FILTER]: (state, action) => {
    const { filterOpened } = action.payload;
    return {
      ...state,
      filterOpened,
    };
  },
  [types.GET_CREDIT_REQUESTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    creditRequests: payload.creditRequests,
    rpp: payload.rpp,
    currentPage: payload.currentPage,
    count: payload.count,
    totalpages: payload.totalpages,
  }),
}, INITIAL_STATE);

export default list;
