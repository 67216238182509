import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';

const MultiSelectRender = ({
  input,
  label,
  options,
  disabled,
  required,
  meta,
  children,
  multiple,
  model,
  variant,
  readOnly,
  autoFocus,
}) => {
  const showError = meta.error && meta.touched;

  const handleChange = (event) => {
    input.onChange(event.target.value);
  };

  return (
    <FormControl fullWidth variant={variant} error={showError}>
      <InputLabel id={input.name + '-label'}>{label}</InputLabel>
      <Select
        {...input}
        multiple={multiple}
        value={input.value || []}
        onChange={handleChange}
        labelId={input.name + '-label'}
        id={input.name}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        autoFocus={autoFocus}
        renderValue={multiple ? (selected) => selected.map(key => options.find(option => option.key === key)?.value).join(', ') : null}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 224,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {children ? children : 
          options.map((option) => (
            <MenuItem key={option[model.id]} value={option[model.id]}>
              {option[model.name]}
            </MenuItem>
          ))
        }
      </Select>
      {showError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

MultiSelectRender.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  meta: PropTypes.instanceOf(Object).isRequired,
  variant: PropTypes.string,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  multiple: PropTypes.bool,
  model: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

MultiSelectRender.defaultProps = {
  disabled: false,
  required: false,
  readOnly: false,
  autoFocus: false,
  multiple: false,
  variant: 'standard',
  model: {
    id: 'id',
    name: 'name',
    disabled: 'disabled',
  },
};

export default MultiSelectRender;
