import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 12,
    height: 12,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 6.5,
  },
  active: {
    backgroundColor: ({ color }) => color || palette.primary.main,
    width: 22,
    height: 22,
    marginLeft: 1.5,
  },
  completed: {
    backgroundColor: ({ color }) => color || palette.primary.main,
  },
}));

export default useStyles;
