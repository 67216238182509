import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

const RadioGroupRender = ({
  input,
  label,
  classes,
  disabled,
  color,
  options,
  // meta,
}) => {

  return (
    <FormControl component="fieldset" disabled={disabled}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        aria-label={input.name}
        className={classes}
      >
        {options.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio color={color} />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioGroupRender.defaultProps = {
  disabled: false,
  color: 'primary',
  classes: '',
};

RadioGroupRender.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  color: PropTypes.string,
  directionRow: PropTypes.bool,
};

export default RadioGroupRender;
