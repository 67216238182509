import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, LinearProgress, useTheme,
} from '@material-ui/core';

const CustomLinearProgress = ({
  color, value, variant, classes,
}) => {
  const { palette } = useTheme();
  const LinearProgressWrapper = withStyles({
    root: {
      height: 12,
      backgroundColor: palette.background.default,
    },
    bar: {
      backgroundColor: color,
    },
  })(LinearProgress);

  return (
    <LinearProgressWrapper
      variant={variant}
      value={value}
      classes={classes}
    />
  );
};

CustomLinearProgress.defaultProps = {
  variant: 'determinate',
  value: null,
  classes: null,
  color: null,
};

CustomLinearProgress.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.instanceOf(Object),
  variant: PropTypes.string,
  value: PropTypes.number,
};

export default CustomLinearProgress;
