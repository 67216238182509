import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotFoundPage from '../../../errors/NotFoundPage';

const CustomSwitch = ({ children }) => (
  <Switch>
    {children}
    <Route component={NotFoundPage} />
  </Switch>
);

CustomSwitch.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default CustomSwitch;
