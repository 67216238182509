import { makeStyles } from '@material-ui/core';
import {BusinessIcon, CartIcon, UserIcon, UserLegalIcon, UserNaturalIcon} from "../../../../../assets";

const useStyles = makeStyles(() => ({
  buttonAccount: {
    height: 90,
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconHeader: {
    display: 'flex',
    width: 48,
    height: 48,
    borderRadius: '100%',
    backgroundColor: '#CFCFD4 !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    background: `url(${UserIcon})`,
    '&.business': {
      background: `url(${BusinessIcon})`,
    },
    '&.cart': {
      background: `url(${CartIcon})`,
    },
    '&.person-natural': {
      background: `url(${UserNaturalIcon})`,
    },
    '&.person-legal': {
      background: `url(${UserLegalIcon})`,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
  },
  buttonText: {
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&& .MuiTypography-root': {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'column',
    },
    '&& .MuiTypography-subtitle1': {
      fontWeight: 600,
      fontSize: 16,
      color: '#0F1012',
    },
    '&& .MuiTypography-subtitle2': {
      fontWeight: 500,
      fontSize: 14,
      color: '#7B7F82',
    },
  },
}));

export default useStyles;
