import React from 'react';
import algoliasearch from 'algoliasearch';
import {
  Configure,
  InstantSearch as InstantSearchAlgolia,
} from 'react-instantsearch';
import * as PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import { envVariablesAlgolia } from '../index';

const InstantSearch = ({ children }) => {
  const { appId, searchApiKey, indexName } = envVariablesAlgolia;
  const algoliaClient = algoliasearch(appId, searchApiKey);
  const searchClient = {
    ...algoliaClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        });
      }
      return algoliaClient.search(requests);
    },
  };

  return (
    <InstantSearchAlgolia
      indexName={indexName}
      searchClient={searchClient}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure
        filters="marketplace.sellerName:LojaDoMecanico"
      />
      { children }
    </InstantSearchAlgolia>
  );
};

InstantSearch.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InstantSearch;
