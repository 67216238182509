import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  discount: {
    flex: 1,
    '& .MuiOutlinedInput-root ': {
      borderRadius: '4px 0px 0px 4px',
    },
  },
  discountButton: {
    borderRadius: '0px 4px 4px 0px',
    borderLeft: 'none',
    padding: 7,
    width: 56,
    height: 56,
  },
});

export default useStyles;
