import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  promotionalContainer: {
    marginTop: '20px',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  promotionalContent: {
    color: '#242424',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    width: '85%',
    borderRadius: '4px',
    border: '1.5px dashed #cfcfd4',
    background: '#f8f9fa',
  },
  promotionalTitle: {
    color: '#242424',
    fontsize: '16px',
    fontWeight: '600',
    margin: '0',
  },
  promotionalSubtitle: {
    color: '#242424',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '500',
    margin: '0',
    marginTop: '10px',
    '& bold' : {
      fontWeight: '700',
      color: '#FE5000',
    },
  },
  promotionalMessage: {
    textAlign: 'center',
    color: '#7B7F82',
    fontSize: '12px',
    fontWeight: '500',
    marginTop: '10px',
    maxWidth: '80%',
    '& bold' : {
      fontWeight: '700'
    },
    '& p': {
      margin: '0',
    }
  }
}));

export default useStyles;
