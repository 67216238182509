import { makeStyles } from '@material-ui/core';
import {
  ExpressIcon,
  LocationIcon,
  SearchOrangeIcon,
  TruckIcon,
} from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  freightButton: {
    width: 92,
    height: 37,
    border: '1px solid #CFCFD4',
    borderRadius: '0 4px 4px 0',
    borderLeft: 0,
    background: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    fontSize: 14,
    lineHeight: '21px',
    color: '#FA4616',
    fontFamily: ['Poppins', 'sans-serif'],
  },
  freightGrid: {
    display: 'flex',
  },
  freightLabel: {
    fontSize: 18,
    lineHeight: '18px',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 8,
  },
  freightInput: {
    padding: '6px 9px 6px 14px',
    width: 297,
    border: '1px solid #CFCFD4',
    borderRadius: '4px 0 0 4px',
    borderRight: 0,
    fontFamily: ['Poppins', 'sans-serif'],
    fontSize: 14,
    lineHeight: '21px',
  },
  searchIcon: {
    background: `url(${SearchOrangeIcon})`,
    backgroundRepeat: 'no-repeat',
    width: 13,
    height: 14,
  },
  locationIcon: {
    background: `url(${LocationIcon})`,
    width: '100%',
    maxWidth: 16,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  truckIcon: {
    background: `url(${TruckIcon})`,
    backgroundRepeat: 'no-repeat',
    width: 16,
    height: 11,
  },
  freightOptionsGrid: {
    border: '1px solid #CFCFD4',
    borderRadius: 8,
  },
  addressMain: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #CFCFD4',
  },
  addressBox: {
    width: '100%',
    display: 'flex',
    gap: 12,
  },
  addressStyled: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,

    '& span:first-of-type': {
      fontSize: 14,
      fontWeight: 600,
      textDecoration: 'underline',
      color: '#242424',
    },

    '& span': {
      fontSize: 12,
      lineHeight: '18px',
      color: '#7B7F82',
    },
  },
  alterAddress: {
    fontFamily: ['Poppins', 'sans-serif'],
    color: '#2296F3',
    background: 'none',
    border: 0,
    cursor: 'pointer',
  },
  freightTypes: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  freightType: {
    width: 114,
    height: 62,
    border: '1px solid #CFCFD4',
    borderRadius: 8,
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'capitalize',
    background: 'none',
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: ['Poppins', 'sans-serif'],
    '&.selected': {
      border: '2px solid #2296F3',
    },
  },
  express: {
    background: `url(${ExpressIcon})`,
    width: 80,
    height: 16,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    gap: 2,
  },
  freightTypesBox: {
    padding: 12,
  },
  deadlineBoxMain: {
    backgroundColor: '#F8F9FA',
    color: '#7B7F82',
    borderRadius: 8,
    padding: '12px 16px',
    fontSize: 10,
    lineHeight: '18px',
    marginTop: 12,
  },
  deadlineBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  deadline: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  freightValue: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
    color: '#FE5000',
  },
  shippingCompanyBox: {
    display: 'flex',
    gap: 4,
  },
  [breakpoints.down('sm')]: {
    addressMain: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    freightInput: {
      width: 208,
    },
    freightType: {
      fontSize: 10,
      lineHeight: '14px',
      height: 50,
      width: 90,
    },
    freightTypes: {
      gap: 8,
    },
    express: {
      height: 14,
      width: 70,
    },
  },
}));

export default useStyles;
