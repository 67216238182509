import React, { useState } from 'react';

import * as PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { InputAdornment } from '@material-ui/core';

import { InputRender } from '../../../../../shared/fields';
import useStyles from './styles';

const InputFieldForm = ({
  name,
  label,
  type,
  value,
  disabled,
  validate,
  required,
  onChange,
  onBlur,
  onFocus,
  hint,
  component,
  multiline,
  rows,
  endAdornment,
  variant,
  ...props
}) => {
  const {
    input,
    activeLabel,
    disabledLabel,
    endAdornmentIcon,
    hintField,
    labelField,
    fieldContent,
  } = useStyles();

  const [active, setActive] = useState(false);

  const handleFocus = () => {
    onFocus && onFocus();
    setActive(true);
  };

  const handleBlur = () => {
    onBlur && onBlur();
    setActive(false);
  };

  return (
    <div className={fieldContent}>
      <label
        className={`
          ${labelField}
          ${active && activeLabel}
          ${disabled && disabledLabel}
        `}
        htmlFor={name}
      >
        {`${label} ${required ? '*' : ''}`}
      </label>
      <Field
        {...props}
        id={name}
        key={name}
        rows={rows}
        name={name}
        type={type}
        value={value}
        classes={input}
        variant={variant}
        onBlur={handleBlur}
        multiline={multiline}
        disabled={disabled}
        validate={validate}
        onChange={onChange}
        onFocus={handleFocus}
        component={component}
      />
      {endAdornment && (
        <InputAdornment position="end" className={endAdornmentIcon}>
          {endAdornment}
        </InputAdornment>
      )}
      {hint && <span className={hintField}>{hint}</span>}
    </div>
  );
};

InputFieldForm.defaultProps = {
  required: false,
  type: 'input',
  variant: 'outlined',
  disabled: false,
  multiline: false,
  rows: 4,
  validate: [],
  component: InputRender,
};

InputFieldForm.propTypes = {
  component: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  hint: PropTypes.string,
  endAdornment: PropTypes.node,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  variant: PropTypes.string,
  validate: PropTypes.array,
  required: PropTypes.bool,
};

export default InputFieldForm;
