import { makeStyles } from '@material-ui/core';
import { VouchersIcon, LocationIcon, UserGrayIcon } from '../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  cardGrid: {
    display: 'flex',
    justifyContent: 'center',
    // marginTop: 48,

    [breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  content: {
    padding: '23px 0',

    '&.noPadding': {
      padding: '0px !important',
    },

    [breakpoints.down('sm')]: {
      padding: '16px 0',
    },
  },
  input: {
    '& .MuiInputBase-root': {
      borderRadius: 8,
      borderColor: '#CFCFD4',
      maxHeight: 48,
      fontSize: 14,
      lineHeight: '21px',
      color: '#7B7F82',

      '& input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 30px white inset !important',
      },
    },
  },
  form: {
    height: '100%',
  },
  btnBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,

    '&.gapStyled': {
      gap: 16,
    },

    [breakpoints.down('sm')]: {
      gap: 26,
    },
  },
  btn: {
    backgroundColor: '#FE5000',
    color: '#FFFFFF',
    height: 48,
    width: '100%',
    borderRadius: 8,
    padding: '10px 48px',
    fontSize: 16,
    lineHeight: '20px',
    textTransform: 'capitalize',
    fontWeight: 600,
    letterSpacing: 0.5,

    '&:hover': {
      background: '#E24700',
    },

    '&:disabled': {
      backgroundColor: '#D1D1D1 !important',
    },

    '&.outlined': {
      background: '#FFFFFF !important',
      color: '#FE5000',
      border: '1px solid',

      '&:hover': {
        filter: 'none',
        background: 'rgb(254 80 0 / 15%) !important',
      },

      '&:disabled': {
        color: '#a9a9a9',
        borderColor: '#a9a9a9',
      },
    },

    [breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  loginActions: {
    display: 'block',

    '&.actionsCustom': {
      padding: '0 23px 23px 23px !important',
    },

    [breakpoints.down('sm')]: {
      '&.actionsCustom': {
        padding: '0 16px 16px 16px !important',
      },
    },
  },
  vouchersIcon: {
    width: 20,
    height: 26,
    background: `url(${VouchersIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  locationIcon: {
    width: 24,
    height: 26,
    background: `url(${LocationIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  infoUserTitle: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'capitalize',

    [breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  infoUserBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    gap: 12,
  },
  infoUserContent: {
    display: 'flex',
    flexDirection: 'column',
    color: '#7B7F82',
    lineHeight: '21px',
    maxWidth: 450,

    '& span': {
      textOverflow: 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
      overflow: 'hidden',
      display: '-webkit-box',
      wordBreak: 'break-all',
    },

    '& span:first-child': {
      fontSize: 14,
      fontWeight: 600,
      '& b': {
        color: '#FE5000',
      },
    },

    '& span:last-child': {
      fontSize: 12,
    },

    [breakpoints.between('xs', 'sm')]: {
      maxWidth: 200,
    },
  },
  infoUserMainBox: {

  },
  fieldGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,

    '& label': {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
    },

    '& $infoUserMainBox:first-child': {
      '& $infoUserBox': {
        borderBottom: '1px solid #CFCFD4',
        paddingBottom: 24,
        marginBottom: 12,
      },
    },

    [breakpoints.down('sm')]: {
      gap: 10,
      '& label': {
        fontSize: 14,
      },
    },
  },
  userIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,

    '&:before': {
      content: '""',
      background: `url(${UserGrayIcon})`,
      width: 10,
      height: 12,
      display: 'block',
      backgroundSize: 10,
    },
  },
}));

export default useStyles;
