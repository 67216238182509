import React, { useEffect, useState } from 'react';
import {
  TableHead, TableRow, TableCell, TableBody, TablePagination, LinearProgress, Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CardBar from '../../../shared/components/cardBar';
import ResponsiveTable from '../../../shared/components/responsiveTable';
import useStyles from './styles';
import { getListDiscountHistoryAction, openAction, getPdfOrcamentoAction } from './actions';

import DiscountDialog from './Dialog';
import { toMoney, toPercent } from '../../../shared/utils/parsers';

const ListRequestsPage = ({
  getListDiscount, open, loading, discounts, currentPage, idUser, count, rpp, getPdf, loadingPDF, user, idCheckout,
}) => {
  const classes = useStyles();
  const handleChangePage = (_, page) => {
    if (!loading) getListDiscount({ page: page + 1, idUser, idCheckout, rpp });
  };

  const handleChangeRpp = ({ target }) => {
    if (!loading) getListDiscount({ page: 1, idUser, idCheckout, rpp: target.value });
  };

  const [reasonValue, setReasonValue] = useState('');

  useEffect(() => {
    if (idUser != null) getListDiscount({ page: 1, idUser, idCheckout, rpp: 10 });
  }, [getListDiscount, idUser]);

  return (
    <section>

      <CardBar leading={<Box />} title="">
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rpp}
          page={currentPage - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRpp}
          labelRowsPerPage="Registros:"
        />
      </CardBar>

      {loading && <LinearProgress />}
      {loadingPDF && <LinearProgress />}

      {!loading && (
        <ResponsiveTable striped>
          <TableHead>
            <TableRow classes={{root: classes.oddRow,}}>
              <TableCell component="th">
                Vendedor
              </TableCell>
              <TableCell component="th">
                Valor
              </TableCell>
              <TableCell component="th">
                Margem
              </TableCell>
              <TableCell component="th">
                Desconto
              </TableCell>
              <TableCell component="th">
                Data
              </TableCell>
              <TableCell component="th">
                Mensagem Solicitação
              </TableCell>
              <TableCell component="th">
                Status
              </TableCell>
              <TableCell component="th">
                Mensagem Aprovador
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>

            {loading && (
            <TableRow>
              <TableCell colSpan={10} datatype="Mensagem" className="message">
                Buscando solicitações de desconto...
              </TableCell>
            </TableRow>
            )}

            {!loading && !discounts.length && (
            <TableRow>
              <TableCell colSpan={10} datatype="Mensagem" className="message">
                Nenhuma solicitação foi encontrada
              </TableCell>
            </TableRow>
            )}

            {discounts.map((item, index) => (
              <TableRow style={item.ativo ? {backgroundColor: '#dbead5'} : {}}
                key={`row-${index + 1}`}
              >

                <TableCell datatype="Assistente">
                  <Box
                    component="span"
                    style={{ cursor: 'text' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.nomeVendedor}
                  </Box>
                </TableCell>

                <TableCell datatype="Valor">
                  <Box
                    whiteSpace="nowrap"
                    component="span"
                    style={{ cursor: 'text' }}
                    onClick={(e) => e.stopPropagation()}
                  >

                    {toMoney(item.valor)}
                  </Box>
                </TableCell>

                <TableCell datatype="Margem">
                  <Box
                    component="span"
                    style={{ cursor: 'text' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.margem}
                  </Box>
                </TableCell>

                <TableCell datatype="Desconto">
                  <Box
                    component="span"
                    style={{ cursor: 'text' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {toPercent(item.descontoSolicitado)}
                  </Box>
                </TableCell>

                <TableCell datatype="Data">
                  <Box
                    component="span"
                    style={{ cursor: 'text' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.dataSolicitacao}
                  </Box>
                </TableCell>

                <TableCell datatype="Observação">
                  <Box
                    component="span"
                    style={{ cursor: 'text' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.obsSolicitacao}
                  </Box>
                </TableCell>

                <TableCell datatype="Status">
                  <Box
                    component="span"
                    style={{ cursor: 'text' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.statusSolicitacao}
                  </Box>
                </TableCell>

                <TableCell datatype="Mensagem Aprovador">
                  <Box
                    component="span"
                    style={{ cursor: 'text' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.mensagemAprovador}
                  </Box>
                </TableCell>

              </TableRow>
            ))}

          </TableBody>
        </ResponsiveTable>
      )}

    <DiscountDialog reasonValue={reasonValue} setReasonValue={setReasonValue}  />
    </section>
  );
};

ListRequestsPage.propTypes = {
  getListDiscount: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  idCheckout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  loading: PropTypes.bool.isRequired,
  discounts: PropTypes.arrayOf(Object).isRequired,
  currentPage: PropTypes.number.isRequired,
  idUser: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  rpp: PropTypes.number.isRequired,
  getPdf: PropTypes.func.isRequired,
  loadingPDF: PropTypes.bool.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => ({
  ...state.main.discount.list,
  ...state.main.sales,
  idUser: state.auth.login.user.idVendedor,
  drawerOpenned: state.main.app.drawer,
  user: state.auth.login.user,
  loadingPDF: state.main.discount.list.loadings.pdf,
});

export default connect(mapState, {
  getListDiscount: getListDiscountHistoryAction,
  open: openAction,
  getPdf: getPdfOrcamentoAction,
})(ListRequestsPage);
