import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, transitions }) => ({
  wrapper: {
    backgroundColor: '#EAEAEA',
    maxHeight: 0,
    overflow: 'hidden',
    transition: transitions.create('max-height', {
      duration: transitions.duration.shortest,
      easing: transitions.easing.easeInOut,
    }),
  },
  wrapperOpened: {
    maxHeight: 180,
    [breakpoints.down('xs')]: {
      maxHeight: 520,
    },
  },
  searchButton: {
    float: 'right',
  },
  inputFakeDate: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
    borderBottomColor: '#000000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    color: '#6C757D',
    height: 38,
    fontSize: 14,
    paddingLeft: 5,
    cursor: 'pointer',
    alignItems: 'center',
    '&:active': {
      borderColor: '#FF5225',
    },
    [breakpoints.down('sm')]: {
      fontSize: 14,
    },

  },
}));

export default useStyles;
