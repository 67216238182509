import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { pt } from 'date-fns/locale';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Grid,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { getOrcamentosAction, resetFiltersAction } from '../../actions';
import { InputRender, InputPhoneRender } from '../../../../shared/fields';
import useStyles from './styles';

const filtersSelector = [
  'codOrcamento',
  'documento',
  'nome',
  'telefone',
  'email',
];

const FilterCheckout = ({
  filterOpened,
  loading,
  handleSubmit,
  getOrcamentos,
  setFilterDataInicio,
  setFilterDataFim,
  filterDataFim,
  filterDataInicio,
  resetFilters,
  filters,
  filtersStorage,
}) => {
  const classes = useStyles();
  const [dateOpen, setDateOpen] = useState(null);
  const [dateInicio, setDateInicio] = useState('');
  const [dateFim, setDateFim] = useState('');

  const d = new Date();
  d.setDate(d.getDate() - 30);
  const [date, setDate] = useState([
    {
      startDate: d,
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleRequest = () => {
    const startDateIsSame = moment(date[0].startDate).format('DD/MM/YYYY') === moment(d).format('DD/MM/YYYY');
    const endDateIsSame = moment(date[0].endDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY');

    const {
      codOrcamento,
      nome,
      documento,
      telefone,
      email,
    } = filters;
    const open = codOrcamento || nome || documento || telefone
      || email || !startDateIsSame || !endDateIsSame;

    localStorage.setItem('filtersCheckout', JSON.stringify({
      ...filters,
      startDate: date[0].startDate,
      endDate: date[0].endDate,
      openFilter: open,
    }));

    getOrcamentos(filterDataInicio, filterDataFim, { page: 1 });
  };

  const clearFilters = () => {
    localStorage.removeItem('filtersCheckout');
    resetFilters('checkout');
    setDate([{ startDate: d, endDate: new Date(), key: 'selection' }]);
  };

  useEffect(() => {
    if (Object.keys(filtersStorage).length) {
      setDate([{
        startDate: new Date(filtersStorage.startDate),
        endDate: new Date(filtersStorage.endDate),
        key: 'selection',
      }]);
    }
  }, []);

  useEffect(() => {
    setFilterDataInicio(moment(date[0].startDate).format('DD/MM/YYYY'));
    setFilterDataFim(moment(date[0].endDate).format('DD/MM/YYYY'));
    setDateInicio(moment(date[0].startDate).format('DD/MM/YYYY'));
    setDateFim(moment(date[0].endDate).format('DD/MM/YYYY'));
  }, [date]);

  return (
    <div
      className={`${classes.wrapper} ${filterOpened ? classes.wrapperOpened : null}`}
    >
      <form onSubmit={handleSubmit(handleRequest)}>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                name="codOrcamento"
                component={InputRender}
                label="Orçamento"
                format={(v = '') => v.replace(/[\D]/g, '')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                name="documento"
                component={InputRender}
                label="Documento"
                format={(v = '') => v.replace(/[\D]/g, '')}
                maxLength={14}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                name="nome"
                component={InputRender}
                label="Nome"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                name="telefone"
                component={InputPhoneRender}
                label="Telefone"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                name="email"
                component={InputRender}
                type="email"
                label="Email"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={2}>
              <div>
                <div
                  className={classes.inputFakeDate}
                  onClick={(e) => setDateOpen(e.currentTarget)}
                >
                  <div className={classes.dateContainer}>
                    <span>
                      {dateInicio}
                    </span>
                    <span>-</span>
                    <span>{dateFim}</span>
                  </div>
                  <ArrowDropDownIcon style={{ marginLeft: 30 }} />
                </div>
                <Popover
                  open={Boolean(dateOpen)}
                  anchorEl={dateOpen}
                  onClose={() => setDateOpen(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <DateRange
                    months={1}
                    name="date"
                    minDate={addDays(date[0].startDate, -29)}
                    maxDate={addDays(date[0].startDate, 29)}
                    onChange={(item) => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    rangeColors={['#FF5225']}
                    showMonthAndYearPickers={false}
                    locale={pt}
                  />
                </Popover>
              </div>
            </Grid>
            <Grid item className={classes.btnGrid} xs={12} sm={3}>
              <IconButton
                type="submit"
                className={classes.searchButton}
                disabled={loading}
              >
                <SearchIcon />
              </IconButton>
              <Button
                variant="outlined"
                className={classes.clearFilterBtn}
                onClick={clearFilters}
              >
                Limpar filtro
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
};

FilterCheckout.defaultProps = {
  loading: false,
};

FilterCheckout.propTypes = {
  filterOpened: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  getOrcamentos: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFilterDataInicio: PropTypes.func.isRequired,
  setFilterDataFim: PropTypes.func.isRequired,
  filterDataFim: PropTypes.string.isRequired,
  filterDataInicio: PropTypes.string.isRequired,
  resetFilters: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  filtersStorage: PropTypes.instanceOf(Object).isRequired,
};

const selector = formValueSelector('checkoutsAndOrders/filter/checkout');
const mapStateToProps = (state) => {
  const filtersStorage = localStorage.getItem('filtersCheckout')
    ? JSON.parse(localStorage.getItem('filtersCheckout')) : {};

  const filters = selector(state, ...filtersSelector);

  return ({
    ...state.main.checkoutsAndOrders,
    ...state.main.sales,
    idCargo: state.auth.login.user.idCargo,
    filters,
    filtersStorage,
    initialValues: {
      codOrcamento: filtersStorage.codOrcamento,
      documento: filtersStorage.documento,
      nome: filtersStorage.nome,
      telefone: filtersStorage.telefone,
      email: filtersStorage.email,
    },
  });
};

export default connect(mapStateToProps, {
  getOrcamentos: getOrcamentosAction,
  resetFilters: resetFiltersAction,
})(reduxForm({
  form: 'checkoutsAndOrders/filter/checkout',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(FilterCheckout));
