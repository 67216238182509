import React from 'react';
import PropTypes from 'prop-types';
import InputRender from './InputRender';

const InputCredentialRender = ({
  input,
  onChange,
  endAdornment,
  variant,
  readOnly,
  classes,
  autocomplete,
  ...others
}) => {
  const maskCpf = (value) => value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');

  const maskCnpj = (value) => value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');

  const isNotEmail = (value) => !value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

  const handleFocus = (event) => {
    const {
      target: { value },
    } = event;

    let inputValue = value;

    if (isNotEmail(value)) {
      inputValue = value.replace(/(\.|\/|-)/g, '');
    }

    input.onChange(inputValue);
    onChange(event);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    input.onChange(value);

    onChange(event);
  };

  const handleBlur = (event) => {
    const { target: { value } } = event;

    if (value.length <= 11) {
      input.onChange(maskCpf(value));
    } else {
      input.onChange(maskCnpj(value));
    }

    onChange(event);
  };

  return (
    <InputRender
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...others}
      input={{
        ...input,
        value: input.value,
        onFocus: handleFocus,
        onChange: handleChange,
        onBlur: handleBlur,
      }}
      InputProps={{
        endAdornment,
        readOnly,
      }}
      variant="outlined"
      classes={classes}
      autocomplete={autocomplete}
    />
  );
};

InputCredentialRender.defaultProps = {
  type: 'text',
  variant: 'standard',
  classes: '',
  maxLength: 50,
  disabled: false,
  required: false,
  max: 50,
  onChange: () => {},
  endAdornment: null,
  readOnly: false,
  autocomplete: 'off',
};

InputCredentialRender.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  classes: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  max: PropTypes.number,
  meta: PropTypes.instanceOf(Object).isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  endAdornment: PropTypes.node,
  readOnly: PropTypes.bool,
  autocomplete: PropTypes.string,
};

export default InputCredentialRender;
