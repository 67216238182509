import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles, { TooltipStyled } from './styles';

const Tooltip = ({
  children,
  textPrimary,
  textSecondary,
  placement,
}) => {
  const classes = useStyles();
  const {
    textContainer,
    textTooltipPrimary,
    textTooltipSecondary,
  } = classes;

  return (
    <TooltipStyled
      placement={placement}
      arrow
      title={(
        <div className={textContainer}>
          {!!textPrimary
            && (
              <Typography
                className={textTooltipPrimary}
                dangerouslySetInnerHTML={{ __html: textPrimary }}
              />
            )}
          {!!textSecondary
            && (
              <Typography
                className={textTooltipSecondary}
                dangerouslySetInnerHTML={{ __html: textSecondary }}
              />
            )}
        </div>
      )}
    >
      {children}
    </TooltipStyled>
  );
};

Tooltip.defaultProps = {
  textPrimary: '',
  textSecondary: '',
  placement: 'top',
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  textPrimary: PropTypes.string,
  textSecondary: PropTypes.string,
  placement: PropTypes.string,
};

export default Tooltip;
