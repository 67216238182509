import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: '100%',
  },
  appBar: {
    width: '100%',
    padding: '16px 16px',
    display: 'flex',
    alignItems: 'center',
    color: palette.primary.main,
    backgroundColor: palette.background.level1,
    [breakpoints.up('sm')]: {
      height: 56,
    },
  },
  appBarList: {
    width: '100%',
    padding: '16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: palette.primary.main,
    backgroundColor: palette.background.level1,
    [breakpoints.up('sm')]: {
      height: 56,
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
    width: 200,
    flexWrap: 'wrap',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    whiteSpace: 'nowrap',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    [breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  buttonAgendamentos: {
    backgroundColor: '#FF5225',
    color: '#ffff',
    height: 30,
    marginRight: 30,
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#909599',
    marginLeft: 10,
    marginRight: 10,
  },
  linkPress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FF5225',
    marginLeft: 10,
    marginRight: 10,
  },
  icon: {
    marginRight: 3,
    width: 20,
    height: 20,
  },
  spanLink: {
    marginTop: 2,
    fontSize: 14,
  },
  divIcons: {
    display: 'flex',
    flexDirection: 'row',
  },
  divHeaderElements: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  divSearch: {
    width: 325,
  },
  divSearchIconContainer: {
    backgroundColor: '#1D1F2A',
    width: 48,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '-13px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    '&:hover': {
      backgroundColor: '#4A4C55',
    },
  },

}));

export default useStyles;
