/* eslint-disable no-param-reassign */
import axios from 'axios';
import * as Sentry from '@sentry/browser';

const { REACT_APP_API_PDV } = process.env;

const apiServicePIX = axios.create({
  baseURL: REACT_APP_API_PDV,
});

const sentryReport = (error) => {
  Sentry.configureScope(((scope) => scope.setLevel('info')));
  const newError = new Error(error.message);
  newError.name = error.message;
  newError.stack = error.status;
  Sentry.captureException(newError);
};

apiServicePIX.interceptors.request.use((config) => {
  const { REACT_APP_TOKEN } = process.env;
  if (REACT_APP_TOKEN) {
    config.headers.Authorization = `Bearer ${REACT_APP_TOKEN}`;
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
});

apiServicePIX.interceptors.response.use(
  (response) => response,
  (e) => {
    const error = e || {};
    const { response = {} } = error;

    if (response.status >= 500 || !response.status) {
      const undefinedMessage = 'Tivemos um problema, tente novamente mais tarde';
      error.message = response?.data ? (response?.data?.message || response?.data?.description) : undefinedMessage;   
      sentryReport(error);
    } else {
      error.message = response.data.description || response.data.message;
    }

    // eslint-disable-next-line no-console
    console.error('[AXIOS][ERROR]:', error.message);
    return Promise.reject(error);
  },
);

export default apiServicePIX;
