import React, { useEffect, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import PropTypes from 'prop-types';
import useStyles from './styles';

const RefinementList = ({
  refinement,
  user,
  customer,
  handleSameFilialCheckbox,
}) => {
  const {
    attribute,
    limit,
    titleShowMore,
    isSearchable,
    attributeFilial,
    showMoreLimit,
  } = refinement;

  const classes = useStyles();
  const userFilialName = attributeFilial && user.filiais[0].nomeFilial.replace('CD', '');
  const customerFilialName = (customer && attributeFilial) && customer.cidade.toUpperCase();
  const { sameFilialCheckbox, setSameFilialCheckbox } = handleSameFilialCheckbox;
  const [isFilterSameFilial, setIsFilterSameFilial] = useState(null);

  const {
    divBtn,
    refinementList,
    refinementBtn,
    refinementCheckbox,
    refinementLabel,
    refinementCount,
    searchContent,
    searchInput,
    searchInputIcon,
  } = classes;

  const {
    items,
    refine,
    isShowingMore,
    toggleShowMore,
    canToggleShowMore,
    searchForItems,
  } = useRefinementList({
    attribute,
    limit,
    showMore: !!limit,
    showMoreLimit: showMoreLimit || 20,
    sortBy: ['isRefined:desc'],
  });

  const handleSearch = (search) => {
    searchForItems(search);
  };

  const handleRefine = (item, isRefined) => {
    if (attributeFilial && (user || customer)) {
      const userResult = [item].find((i) => i.value.toUpperCase().match(userFilialName));
      const customerResult = customerFilialName && (
        [item].find((i) => i.value.toUpperCase().match(customerFilialName))
      );

      if (userResult) {
        setIsFilterSameFilial({ ...userResult, isRefined, stock: true });
      }
      if (customerResult) {
        setIsFilterSameFilial({ ...customerResult, isRefined, withdrawStore: true });
      }
    }
    refine(item.value);
  };

  useEffect(() => {
    if (attributeFilial && isFilterSameFilial) {
      if (isFilterSameFilial.stock) {
        setSameFilialCheckbox({
          ...sameFilialCheckbox,
          stock: isFilterSameFilial.isRefined,
        });
      }
      if (isFilterSameFilial.withdrawStore) {
        setSameFilialCheckbox({
          ...sameFilialCheckbox,
          withdrawStore: isFilterSameFilial.isRefined,
        });
      }
    }
  }, [items]);

  return (
    <>
      {
        isSearchable && (
          <>
            <input
              type="search"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              placeholder="Buscar"
              spellCheck={false}
              className={searchInput}
              onChange={(e) => handleSearch(e.currentTarget.value)}
            />
            <div className={searchContent}>
              <span className={searchInputIcon} />
            </div>
          </>
        )
      }
      <ul>
        {
          items.map((item) => {
            const {
              label,
              isRefined,
              value,
              count,
            } = item;
            return (
              <li key={label} className={refinementList}>
                <input
                  className={refinementCheckbox}
                  type="checkbox"
                  checked={isRefined}
                  value={value}
                  onChange={(e) => handleRefine(item, e.target.checked)}
                />
                <span className={refinementLabel}>{label}</span>
                <span className={refinementCount}>{count}</span>
              </li>
            );
          })
       }
      </ul>
      {
       !!limit && (
         <div className={divBtn}>
           <button className={refinementBtn} type="button" onClick={toggleShowMore} disabled={!canToggleShowMore}>
             {isShowingMore ? 'Ver menos' : `Ver todas as ${titleShowMore}`}
           </button>
         </div>
       )
     }
    </>
  );
};

RefinementList.defaultProps = {
  user: null,
  customer: null,
  handleSameFilialCheckbox: null,
};

RefinementList.propTypes = {
  refinement: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object),
  customer: PropTypes.instanceOf(Object),
  handleSameFilialCheckbox: PropTypes.instanceOf(Object),
};

export default RefinementList;
