import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  cardGrid: {
    maxWidth: 1200,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,

    '& [class*=infoContent]': {
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
        gap: 10,
      },
    },

    '& .back-icon': {
      borderTop: '2px solid',
      borderLeft: '2px solid',
      width: 8,
      height: 8,
      transform: 'rotate(315deg)',
      marginRight: 10,
    },
  },
  dividerContent: {
    marginTop: 12,
    marginBottom: 12,
  },
  titleTypography: {
    fontWeight: 700,
    fontSize: 18,
    color: '#2A2A2A',
    marginBottom: 12,
  },
}));

export default useStyles;
