import { makeStyles } from '@material-ui/core';
import { CloseIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  closeIcon: {
    background: `url(${CloseIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
    width: 24,
    height: 24,
    border: 0,
  },
  dialog: {
    minWidth: 600,

    [breakpoints.down('sm')]: {
      minWidth: 300,
    },
  },
  installmentGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
  },
  title: {
    padding: 0,
    '& h2': {
      fontSize: 21,
      fontWeight: 700,

      [breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
  },
  installment: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: 16,
    lineHeight: '21px',
    backgroundColor: '#F3F4F6',
    padding: '4px 0',
    marginBottom: 8,

    '& .MuiGrid-root:first-child': {
      display: 'flex',
      justifyContent: 'center',
    },

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
