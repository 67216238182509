export default {
  OPEN_DIALOG: '@checkout-advanced/ADVANCED_OPEN_DIALOG',
  CLOSE_DIALOG: '@checkout-advanced/ADVANCED_CLOSE_DIALOG',
  OPEN_PIX_DIALOG: '@checkout-advanced/ADVANCED_OPEN_PIX_DIALOG',
  CLOSE_PIX_DIALOG: '@checkout-advanced/ADVANCED_CLOSE_PIX_DIALOG',
  OPEN_PS_DIALOG: '@checkout-advanced/ADVANCED_OPEN_PS_DIALOG',
  CLOSE_PS_DIALOG: '@checkout-advanced/ADVANCED_CLOSE_PS_DIALOG',
  UPDATE_PAYMENT_TYPE: '@checkout-advanced/ADVANCED_UPDATE_PAYMENT_TYPE',

  GET_INSTALLMENTS: '@checkout-advanced/GET_INSTALLMENTS',
  GET_INSTALLMENTS_SUCCESS: '@checkout-advanced/GET_INSTALLMENTS_SUCCESS',
  GET_INSTALLMENTS_FAIL: '@checkout-advanced/GET_INSTALLMENTS_FAIL',

  GET_PIX_REQUEST: '@checkout-advanced/GET_PIX_REQUEST',
  GET_PIX_SUCCESS: '@checkout-advanced/GET_PIX_SUCCESS',
  GET_PIX_FAIL: '@checkout-advanced/GET_PIX_FAIL',

  GET_CHECK_PIX_REQUEST: '@checkout-advanced/GET_CHECK_PIX_REQUEST',
  GET_CHECK_PIX_SUCCESS: '@checkout-advanced/GET_CHECK_PIX_SUCCESS',
  GET_CHECK_PIX_FAIL: '@checkout-advanced/GET_CHECK_PIX_FAIL',

  POST_PIX_SMS_REQUEST: '@checkout-advanced/POST_PIX_SMS_REQUEST',
  POST_PIX_SMS_SUCCESS: '@checkout-advanced/POST_PIX_SMS_SUCCESS',
  POST_PIX_SMS_FAIL: '@checkout-advanced/POST_PIX_SMS_FAIL',

  POST_PIX_WHATS_REQUEST: '@checkout-advanced/POST_PIX_WHATS_REQUEST',
  POST_PIX_WHATS_SUCCESS: '@checkout-advanced/POST_PIX_WHATS_SUCCESS',
  POST_PIX_WHATS_FAIL: '@checkout-advanced/POST_PIX_WHATS_FAIL',

  POST_PIX_EMAIL_REQUEST: '@checkout-advanced/POST_PIX_EMAIL_REQUEST',
  POST_PIX_EMAIL_SUCCESS: '@checkout-advanced/POST_PIX_EMAIL_SUCCESS',
  POST_PIX_EMAIL_FAIL: '@checkout-advanced/POST_PIX_EMAIL_FAIL',

  CANCEL_PAYMENT: '@checkout-advanced/CANCEL_PAYMENT',
};
