import { Typography, Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import useStyles from './style';
import TYPE_PAGES from '../../typePages';
import { setPageAction } from '../../actions';

const OrcamentoPedidoButton = ({
  page,
  setPage,
}) => {
  const classes = useStyles();

  const goToOrcamento = () => setPage({ page: TYPE_PAGES.ORCAMENTOS });
  const goToPedidos = () => setPage({ page: TYPE_PAGES.PEDIDOS });

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <button
          type="button"
          onClick={goToOrcamento}
          className={`${classes.button} ${classes.buttonLeft} ${page === TYPE_PAGES.ORCAMENTOS ? classes.buttonActive : null}`}
        >
          <Typography variant="button">ORÇAMENTOS</Typography>
        </button>
        <button
          type="button"
          onClick={goToPedidos}
          className={`${classes.button} ${classes.buttonRight} ${page === TYPE_PAGES.PEDIDOS ? classes.buttonActive : null}`}
        >
          <Typography variant="button">PEDIDOS</Typography>
        </button>
      </div>

      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={classes.homeButton}
        onClick={() => {
          window.location.href = process.env.REACT_APP_HOST_CATALAGO;
        }}
      >
        Abrir Catálogo
      </Button>
    </div>
  );
};

OrcamentoPedidoButton.propTypes = {
  page: PropTypes.string.isRequired,
  setPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => state.main.checkoutsAndOrders;

export default connect(mapStateToProps, {
  setPage: setPageAction,
})(OrcamentoPedidoButton);
