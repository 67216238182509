import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar, Typography, Button } from '@material-ui/core';
import useStyles from './style';
import { PowerSettingsNew, Call } from '@material-ui/icons';
import { toCPF, toCNPJ, toPhone } from '../../../utils/parsers';
import apiService from '../../../../shared/services/apiService';
import { connect } from 'react-redux';
import {clientLogoutAction, clientLogoutCatalogoAction} from "../../../../main/authClient/login/actions";
import { push } from "../../../../history";

const CustomerInfo = ({
	name,
	socialName,
	typePerson,
	document,
	onChangeData,
	phone,
	user,
	email,
	checkout,
	nomeComprador,
  clientLogout,
  clientLogoutCatalogo,
}) => {
	const classes = useStyles({ onChangeData });

	const handleLigar = async event => {
		try {
			const data = await apiService.post(`/vendedores/discagem/${user.ramal}/${phone}`);
			if (data.status) {
				// snack.enqueueSnackbar('Estamos ligando para seu cliente!', { variant: 'success' });
			}
		} catch (error) {
			// snack.enqueueSnackbar(resolveError(error), { variant: 'error' });
		}
	};

  const logout = () => {
    clientLogout();
    clientLogoutCatalogo();
    push('/client-login');
  }

	return (
		<>
			<Toolbar disableGutters className={classes.detailToolbar} onClick={() => onChangeData && onChangeData()}>
				<Box display="flex" flexDirection="column" ml="12px" width="0" flex={1}>
					<Typography
						component="h2"
						variant="caption"
						style={{ marginTop: '13px', fontSize: '14px', fontWeight: 'bold', color: '#1A1C26' }}
					>
						{name || ''}
					</Typography>

					{(typePerson || '') === 'F' && socialName && (
						<Typography
							component="h2"
							variant="caption"
							style={{ marginTop: '4px', fontSize: '14px', fontWeight: '800', color: '#1A1C26' }}
						>
							({socialName})
						</Typography>
					)}

					<Typography component="h2" variant="caption" style={{ marginTop: '9px', fontSize: '14px', fontWeight: '400', color: '#1A1C26' }}>
						<Button
							className={`${classes.actionButton} ${classes.buttonOrange}`}
							color="primary"
							size="small"
							onClick={logout}
							startIcon={<PowerSettingsNew />}
						>
							Deslogar
						</Button>

						{(typePerson || '') === 'F' ? 'CPF: ' : 'CNPJ: '}
						{(typePerson || '') === 'F' ? toCPF(document || '') : toCNPJ(document || '')}
					</Typography>

					<Typography
						component="h2"
						variant="caption"
						style={{ marginTop: '5px', fontSize: '14px', fontWeight: '400', color: '#1A1C26', marginBottom: 5 }}
					>
						<Button
							className={`${classes.actionButton} ${classes.buttonBlue}`}
							color="primary"
							size="small"
							onClick={handleLigar}
							startIcon={<Call />}
						>
							Ligar
						</Button>
						FONE: {toPhone(phone)}
					</Typography>

					<Typography
						component="h2"
						variant="caption"
						style={{ fontSize: '14px', fontWeight: '400', color: '#1A1C26', marginBottom: 5, lineBreak: 'anywhere' }}
					>
						EMAIL: {email.length ? email : 'Não cadastrado'}
					</Typography>
					{(typePerson || '') === 'J' && (checkout.nomeComprador || nomeComprador) && (
						<Typography
							component="h2"
							variant="caption"
							style={{
								fontSize: '14px',
								fontWeight: '400',
								color: '#1A1C26',
								marginBottom: 5,
								marginTop: 5,
								lineBreak: 'anywhere',
							}}
						>
							{checkout.nomeComprador ? `COMPRADOR: ${checkout.nomeComprador}` : `COMPRADOR: ${nomeComprador}`}
						</Typography>
					)}
				</Box>
			</Toolbar>
		</>
	);
};

CustomerInfo.defaultProps = {
	onChangeData: null,
	onLogountCustomer: () => {},
	snack: null,
};

CustomerInfo.propTypes = {
	name: PropTypes.string.isRequired,
	socialName: PropTypes.string,
	typePerson: PropTypes.string.isRequired,
	phone: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	document: PropTypes.string.isRequired,
	onChangeData: PropTypes.func,
	onLogountCustomer: PropTypes.func,
	snack: PropTypes.instanceOf(Object),
	user: PropTypes.instanceOf(Object).isRequired,
	checkout: PropTypes.instanceOf(Object).isRequired,
	nomeComprador: PropTypes.string.isRequired,
  clientLogout: PropTypes.func.isRequired,
};

const mapState = state => {
	const { snack } = state.main.app;
	const { checkout } = state.main.checkout.geral;
	return {
		...state.main.app,
		snack,
		checkout,
		user: state.auth.login.user,
	};
};

export default connect(mapState, {
  clientLogout: clientLogoutAction,
  clientLogoutCatalogo: clientLogoutCatalogoAction,
})(CustomerInfo);
