import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Paper,
  Button,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CardBarSale from '../../../../../shared/components/cardBarSale';
import ResponsiveTable from '../../../../../shared/components/responsiveTable';
import NewParametrizacao from './NewParametrizacao';
import EditParametrizacao from './EditParametrizacao';
import { toMoney } from '../../../../../shared/utils/parsers';
import useStyles from '../../../styles';
import {
  getParametrizacaoAction,
  getDetailParametrizacaoAction,
} from '../../../actions';

const Parametrizacao = ({
  loading, parametrizacao,
  getParametrizacao, getDetailParametrizacao,
}) => {
  const classes = useStyles();
  const [createNewParametrizacao, setCreateNewParametrizacao] = useState(false);
  const [editParametrizacao, setEditParametrizacao] = useState(false);
  const tradeEditParametrizacao = () => {
    setEditParametrizacao(true);
  };
  const handleDetails = async (id) => {
    await getDetailParametrizacao({ id });
    tradeEditParametrizacao();
  };

  useEffect(() => {
    getParametrizacao();
  }, []);

  return (
    <div className={classes.divRow}>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <CardBarSale title="Home" />
        {loading && (<LinearProgress />)}
        {!createNewParametrizacao && !editParametrizacao
          ? (
            <div className={classes.titleAndButtonParametrizacao}>
              <span>Parametrização Geral</span>
              {/* <Button
                className={classes.buttonTitleParametrizacao}
                startIcon={<AddIcon />}
                onClick={() => setCreateNewParametrizacao(true)}
              >
                NOVA PARAMETRIZAÇÃO
              </Button> */}
            </div>
          )
          : (
            <div className={classes.titleAndButtonParametrizacao}>
              {editParametrizacao
                ? <span>Parametrização Geral - Editar</span>
                : <span>Parametrização Geral - Novo</span>}
              <Button
                className={classes.buttonTitleBackParametrizacao}
                startIcon={<ArrowBackIcon />}
                onClick={() => { setCreateNewParametrizacao(false); setEditParametrizacao(false); }}
              >
                VOLTAR
              </Button>
            </div>
          )}
        <section className={classes.sessionTableParametrizacao}>

          {editParametrizacao && (
          <EditParametrizacao
            setEditParametrizacao={setEditParametrizacao}
          />
          )}
          {createNewParametrizacao && (
          <NewParametrizacao
            setCreateNewParametrizacao={setCreateNewParametrizacao}
          />
          )}
          {!createNewParametrizacao && !editParametrizacao
          && (
          <>
            <Paper elevation={1} style={{ width: '100%' }}>
              <ResponsiveTable>
                <TableHead style={{ backgroundColor: '#F9F9F9' }}>
                  <TableRow>
                    <TableCell component="th" align="left" className={classes.borderTable}>
                      Nível
                    </TableCell>
                    <TableCell component="th" className={classes.borderTable}>
                      Pedidos Refeitos
                    </TableCell>
                    <TableCell component="th" className={classes.borderTable}>
                      Valor Min. Venda
                    </TableCell>
                    <TableCell component="th" className={classes.borderTable}>
                      Taxa de Conversão
                    </TableCell>
                    <TableCell component="th" className={classes.borderTable}>
                      Taxa de Declínio
                    </TableCell>
                    <TableCell component="th" className={classes.borderTable}>
                      Taxa de Comissão
                    </TableCell>
                    {/* <TableCell component="th" className={classes.borderTable}>
                      Dt. Cadastro
                    </TableCell> */}
                    <TableCell component="th" className={classes.borderTable}>
                      Ação
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {parametrizacao.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell datatype="Nível" align="left" style={{ color: '#000' }} className={classes.borderTable}>
                        {item.nomeCategoria}
                      </TableCell>
                      <TableCell datatype="Pedidos Refeitos" align="left" className={classes.borderTable}>
                        {item.numPedidosRefeitos}
                      </TableCell>

                      <TableCell datatype="Valor Min. Venda" className={classes.borderTable}>
                        {`${toMoney(item.vlrMinimoVenda)}`}
                      </TableCell>

                      <TableCell datatype="Taxa de Conversão" className={classes.borderTable}>
                        {`${item.taxaConversao}%`}
                      </TableCell>

                      <TableCell datatype="Taxa de Declínio" className={classes.borderTable}>
                        {`${item.taxaDeclinio}%`}
                      </TableCell>
                      <TableCell datatype="Taxa de Comissão" className={classes.borderTable}>
                        {`${item.taxaComissao}%`}
                      </TableCell>
                      {/* <TableCell datatype="Dt. Cadastro" className={classes.borderTable}>
                        10/12/2021
                      </TableCell> */}

                      <TableCell datatype="Ação" className={classes.borderTable}>
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            startIcon={<VisibilityIcon style={{ fontSize: 16 }} />}
                            onClick={() => handleDetails(item.id)}
                            className={classes.editarParametrização}
                          >
                            EDITAR
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ResponsiveTable>
            </Paper>
          </>
          )}
        </section>
      </div>
    </div>
  );
};

Parametrizacao.propTypes = {
  loading: PropTypes.bool.isRequired,
  parametrizacao: PropTypes.arrayOf(Object).isRequired,
  getParametrizacao: PropTypes.func.isRequired,
  getDetailParametrizacao: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.sales;

export default connect(mapState, {
  getParametrizacao: getParametrizacaoAction,
  getDetailParametrizacao: getDetailParametrizacaoAction,
})(Parametrizacao);
