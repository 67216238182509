import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useStyles from './styles';
import ContentGuarantees from '../guarantee/_components/contentGuarantees/ContentGuarantees';
import RadioGroupGuarantees from '../guarantee/_components/radioGroupGuarantees/RadioGroupGuarantees';
import { InfoOutlined, ShoppingCartOutlined } from '@material-ui/icons';

const VoltageProductDialog = ({
  addProduct,
  guaranteesOptions,
  open,
  setOpen,
  voltageOptions,
  handleGuaranteeId,
  loading,
}) => {
  const { guaranteeIdSelected, setGuaranteeIdSelected } = handleGuaranteeId;

  const classes = useStyles();
  const {
    accordion,
    accordionSummary,
    accordionDetails,
    btnMoreInfo,
    iconGuarantee,
    addCartBtn,
    closeIcon,
    dialogPaper,
    header,
    title,
    rootActions,
    rootContent,
    voltageIcon,
    voltageInputContainer,
    voltageContainer
  } = classes;

  const handleClose = () => setOpen(false);

  const handleGuarantees = (guarantee) => setGuaranteeIdSelected(guarantee);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: dialogPaper }}
    >
      <Grid className={header}>
        <DialogTitle className={title}>
          Você está selecionando um produto com a opção abaixo
        </DialogTitle>
        <button
          type="button"
          aria-label="close voltage product dialog"
          className={closeIcon}
          onClick={handleClose}
        />
      </Grid>
      <Grid>
        {
          !!voltageOptions.length && (
            voltageOptions.map(({ id, tensao, selected }) => (
              <ul key={id} className={voltageContainer}>
                <li className={`${voltageInputContainer} ${selected && ('selected')}`}>
                  <input
                    type="radio"
                    name={tensao}
                    checked={selected}
                    value={tensao}
                  />
                  <span>{tensao}</span>
                  <span className={voltageIcon} />
                </li>
              </ul>
            ))
          )
        }
      </Grid>
      <DialogContent classes={{ root: rootContent }}>
        <Accordion classes={{ root: accordion }}>
          <AccordionSummary classes={{ root: accordionSummary }}>
            <Icon className={iconGuarantee}></Icon>
            <Button
              className={btnMoreInfo}
              variant="text"
              color="tertiary"
            >
              <InfoOutlined fontSize="small"/>
              Saiba mais
            </Button>
          </AccordionSummary>
          <AccordionDetails classes={{ root: accordionDetails }}>
            <ContentGuarantees />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions classes={{ root: rootActions }}>
        <RadioGroupGuarantees
          guaranteesOptions={guaranteesOptions} 
          valueRadioChecked={guaranteeIdSelected}
          handleGuaranteeId={handleGuarantees}
        />
        <Button className={addCartBtn} onClick={addProduct} disabled={loading}>
          <ShoppingCartOutlined fontSize='small' />
          Adicionar ao carrinho
        </Button>
      </DialogActions>
    </Dialog>
  );
};

VoltageProductDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  voltageOptions: PropTypes.instanceOf(Array).isRequired,
  guaranteesOptions: PropTypes.instanceOf(Array).isRequired,
  handleGuaranteeId: PropTypes.instanceOf(Object).isRequired,
  addProduct: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapState = (state) => ({
  loading: state.main.checkout.geral.loading,
});

export default connect(mapState)(VoltageProductDialog);
