import { handleActions, combineActions } from 'redux-actions';
import types from './types';

const INITIAL_STATE = {
  totalpages: 1,
  totalpagesinativos: 1,
  totalpagesCanalVendas: 1,
  totalpagesUnNegocios: 1,
  totalpagesEstabelecimentos: 1,
  totalpagesSupervisor: 1,
  totalpagesCargo: 1,
  rpp: 10,
  detailsVendedor: {},
  usuarios: [],
  parametrizacao: [],
  detailsParametrizacao: {},
  usuariosInativos: [],
  canaisDeVenda: [],
  canaisDeVendaFilter: [],
  unNegocios: [],
  estabelecimentos: [],
  cargos: [],
  niveis: [],
  permissoes: [],
  cargosFilter: {},
  supervisores: [],
  detailsSupervisor: {},
  loading: false,
};

const startLoading = combineActions(
  types.GET_VENDEDORES_REQUEST,
  types.GET_VENDEDORES_INATIVOS_REQUEST,
  types.GET_CARGOS_FILTER_REQUEST,
  types.GET_CANAL_DE_VENDAS_REQUEST,
  types.GET_UNIDADE_DE_NEGOCIO_REQUEST,
  types.GET_ESTABELECIMENTOS_REQUEST,
  types.GET_CARGOS_REQUEST,
  types.GET_NIVEIS_REQUEST,
  types.GET_PERMISSOES_REQUEST,
  types.GET_SUPERVISORES_REQUEST,
  types.GET_DETAILS_SUPERVISORES_REQUEST,
  types.GET_DETAILS_VENDEDORES_REQUEST,
  types.GET_PARAMETRIZACAO_REQUEST,
  types.GET_DETAILS_PARAMETRIZACAO_REQUEST,

  types.POST_VENDEDORES_REQUEST,
  types.POST_CARGOS_REQUEST,

  types.PUT_VENDEDORES_REQUEST,
  types.PUT_CARGOS_REQUEST,
  types.PUT_SUPERVISORES_REQUEST,
);

const stopLoading = combineActions(
  types.GET_VENDEDORES_SUCCESS,
  types.GET_VENDEDORES_FAILURE,
  types.GET_VENDEDORES_INATIVOS_SUCCESS,
  types.GET_VENDEDORES_INATIVOS_FAILURE,
  types.GET_CANAL_DE_VENDAS_SUCCESS,
  types.GET_CANAL_DE_VENDAS_FAILURE,
  types.GET_UNIDADE_DE_NEGOCIO_SUCCESS,
  types.GET_UNIDADE_DE_NEGOCIO_FAILURE,
  types.GET_ESTABELECIMENTOS_SUCCESS,
  types.GET_ESTABELECIMENTOS_FAILURE,
  types.GET_CARGOS_SUCCESS,
  types.GET_CARGOS_FAILURE,
  types.GET_NIVEIS_SUCCESS,
  types.GET_NIVEIS_FAILURE,
  types.GET_PERMISSOES_SUCCESS,
  types.GET_PERMISSOES_FAILURE,
  types.GET_CARGOS_FILTER_SUCCESS,
  types.GET_CARGOS_FILTER_FAILURE,
  types.GET_SUPERVISORES_SUCCESS,
  types.GET_SUPERVISORES_FAILURE,
  types.GET_DETAILS_SUPERVISORES_SUCCESS,
  types.GET_DETAILS_SUPERVISORES_FAILURE,
  types.GET_DETAILS_VENDEDORES_SUCCESS,
  types.GET_DETAILS_VENDEDORES_FAILURE,
  types.GET_PARAMETRIZACAO_SUCCESS,
  types.GET_PARAMETRIZACAO_FAILURE,
  types.GET_DETAILS_PARAMETRIZACAO_SUCCESS,
  types.GET_DETAILS_PARAMETRIZACAO_FAILURE,

  types.POST_VENDEDORES_SUCCESS,
  types.POST_VENDEDORES_FAILURE,
  types.POST_CARGOS_SUCCESS,
  types.POST_CARGOS_FAILURE,

  types.PUT_VENDEDORES_SUCCESS,
  types.PUT_VENDEDORES_FAILURE,
  types.PUT_CARGOS_SUCCESS,
  types.PUT_CARGOS_FAILURE,
  types.PUT_SUPERVISORES_SUCCESS,
  types.PUT_SUPERVISORES_FAILURE,
);

const sales = handleActions({
  [startLoading]: (state) => ({
    ...state,
    loading: true,
  }),
  [stopLoading]: (state) => ({
    ...state,
    loading: false,
  }),

  // VENDEDORES

  [types.GET_VENDEDORES_SUCCESS]: (state, { payload }) => ({
    ...state,
    usuarios: payload.results,
    totalpages: payload.totalpages,
  }),
  [types.GET_DETAILS_VENDEDORES_SUCCESS]: (state, { payload }) => ({
    ...state,
    detailsVendedor: payload.results,
  }),
  [types.GET_VENDEDORES_INATIVOS_SUCCESS]: (state, { payload }) => ({
    ...state,
    usuariosInativos: payload.results,
    totalpagesinativos: payload.totalpages,
  }),
  [types.POST_VENDEDORES_SUCCESS]: (state, { payload }) => ({
    ...state,
    usuarios: payload.results,
    totalpages: payload.totalpages,
  }),
  [types.PUT_VENDEDORES_SUCCESS]: (state, { payload }) => ({
    ...state,
    usuarios: payload.results,
    usuariosInativos: payload.results,
    totalpages: payload.totalpages,
  }),

  // CANAL DE VENDAS

  [types.GET_CANAL_DE_VENDAS_SUCCESS]: (state, { payload }) => ({
    ...state,
    canaisDeVenda: payload.results,
    canaisDeVendaFilter: payload.results,
    totalpagesCanalVendas: payload.totalpages,
  }),

  // UNIDADE DE NEGOCIOS

  [types.GET_UNIDADE_DE_NEGOCIO_SUCCESS]: (state, { payload }) => ({
    ...state,
    unNegocios: payload.results,
    totalpagesUnNegocios: payload.totalpages,
  }),

  // FILIAIS

  [types.GET_ESTABELECIMENTOS_SUCCESS]: (state, { payload }) => ({
    ...state,
    estabelecimentos: payload.results,
    totalpagesEstabelecimentos: payload.totalpages,
  }),

  // CARGOS

  [types.GET_CARGOS_SUCCESS]: (state, { payload }) => ({
    ...state,
    cargos: payload.results,
    totalpagesCargo: payload.totalpages,
  }),
  [types.GET_CARGOS_FILTER_SUCCESS]: (state, { payload }) => ({
    ...state,
    cargosFilter: payload.results,
  }),
  [types.POST_CARGOS_SUCCESS]: (state, { payload }) => ({
    ...state,
    cargos: payload.results,
    totalpages: payload.totalpages,
  }),
  [types.PUT_CARGOS_SUCCESS]: (state, { payload }) => ({
    ...state,
    cargos: payload.results,
    totalpagesCargo: payload.totalpages,
  }),

  // NÍVEIS

  [types.GET_NIVEIS_SUCCESS]: (state, { payload }) => ({
    ...state,
    niveis: payload.results,
  }),

  // PERMISSOES

  [types.GET_PERMISSOES_SUCCESS]: (state, { payload }) => ({
    ...state,
    permissoes: payload.data,
    totalpagesPermissoes: 1,
  }),

  // SUPERVISORES

  [types.GET_SUPERVISORES_SUCCESS]: (state, { payload }) => ({
    ...state,
    supervisores: payload.results,
    totalpagesSupervisor: payload.totalpages,
  }),
  [types.GET_DETAILS_SUPERVISORES_SUCCESS]: (state, { payload }) => ({
    ...state,
    detailsSupervisor: payload.results,
  }),
  [types.PUT_SUPERVISORES_SUCCESS]: (state, { payload }) => ({
    ...state,
    supervisores: payload.results,
    totalpagesSupervisor: payload.totalpages,
  }),

  // PARAMETRIZAÇÃO

  [types.GET_PARAMETRIZACAO_SUCCESS]: (state, { payload }) => ({
    ...state,
    parametrizacao: payload.results,
  }),
  [types.GET_DETAILS_PARAMETRIZACAO_SUCCESS]: (state, { payload }) => ({
    ...state,
    detailsParametrizacao: payload.results,
  }),

  [types.POST_PARAMETRIZACAO_SUCCESS]: (state, { payload }) => ({
    ...state,
    parametrizacao: payload.results,
  }),
  [types.PUT_PARAMETRIZACAO_SUCCESS]: (state, { payload }) => ({
    ...state,
    parametrizacao: payload.results,
  }),

}, INITIAL_STATE);

export default sales;
