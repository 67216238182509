import React from 'react';
import * as PropTypes from 'prop-types';

import { Button, Typography } from '@material-ui/core';
import useStyles from './styles';

const TitleButton = ({
  title,
  button,
  onClick,
  icon,
  widthBtn,
}) => {
  const {
    infoContent,
    titleTypography,
    actionButton,
    iconHeader,
  } = useStyles();

  return (
    <div className={infoContent}>
      <Typography className={titleTypography}>{title}</Typography>
      {button && (
        <Button
          classes={{ root: actionButton }}
          style={{ width: widthBtn }}
          onClick={onClick}
          disableElevation
          variant="contained"
          color="primary"
        >
          <span className={`${iconHeader} ${icon}`} />
          {button}
        </Button>
      )}
    </div>
  );
};

TitleButton.defaultProps = {
  onClick: () => {},
  icon: 'edit',
  button: '',
  widthBtn: 'auto',
};
TitleButton.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  widthBtn: PropTypes.number,
};

export default TitleButton;
