/* eslint-disable no-param-reassign */
export const toFloat = (number, decimal = 2) => Number(number).toFixed(decimal);

export const toThousand = (number) => {
  const isNegative = +number < 0;
  const value = toFloat(
    String(number)
      .replace(',', '.')
      .replace(/[^\d.]/g, ''),
    0,
  )
    .replace('.', '')
    .split('')
    .reverse();

  let newValue = '';
  value.forEach((char, index) => {
    let newChar = char;
    if ((index + 1) % 3 === 0 && index + 1 < value.length) newChar = `.${newChar}`;
    newValue = `${newChar}${newValue}`;
  });

  return `${isNegative ? '-' : ''}${newValue}`;
};

export const toMoney = (number) => {
  const isNegative = +number < 0;
  const value = toFloat(
    String(number)
      .replace(',', '.')
      .replace(/[^\d.]/g, ''),
  ).replace('.', '');
  let newCurrency = '';

  for (let i = 0; i < value.length; i += 1) {
    const valueChar = value[value.length - 1 - i];
    if (i === 2) {
      newCurrency = `,${newCurrency}`;
    } else if (i > 3 && !((i - 2) % 3)) {
      newCurrency = `.${newCurrency}`;
    }
    newCurrency = valueChar + newCurrency;
  }

  return `R$ ${isNegative ? '-' : ''}${newCurrency}`;
};

export const toPercent = (percent) => {
  const value = toFloat(
    String(percent)
      .replace(',', '.')
      .replace(/[^\d.]/g, ''),
  ).replace('.', ',');
  return `${value}%`;
};

export const toPhone = (value = '') => {
  let parsedValue = value.replace('-', '').trim();

  if (parsedValue.length > 0) {
    parsedValue = `(${parsedValue}`;
  }
  if (parsedValue.length > 3) {
    parsedValue = `${parsedValue.substring(0, 3)}) ${parsedValue.substring(3)}`;
  }
  if (parsedValue.length > 9 && parsedValue.length < 14) {
    parsedValue = `${parsedValue.substring(0, 9)}-${parsedValue.substring(9)}`;
  } else if (parsedValue.length > 13) {
    parsedValue = `${parsedValue.substring(0, 10)}-${parsedValue.substring(10)}`;
  }
  if (parsedValue.length > 15) {
    parsedValue = parsedValue.substring(0, 15);
  }

  return parsedValue;
};

export const toCEP = (value) => {
  let parsedValue = String(value);
  if (parsedValue.length > 5) {
    parsedValue = `${parsedValue.substring(0, 5)}-${parsedValue.substring(5)}`;
  }
  if (parsedValue.length > 9) {
    parsedValue = parsedValue.substring(0, 9);
  }
  return parsedValue;
};

export const toCPF = (value) => {
  let parsedValue = String(value);

  if (parsedValue.length > 3) {
    parsedValue = `${parsedValue.substring(0, 3)}.${parsedValue.substring(3)}`;
  }
  if (parsedValue.length > 7) {
    parsedValue = `${parsedValue.substring(0, 7)}.${parsedValue.substring(7)}`;
  }
  if (parsedValue.length > 11) {
    parsedValue = `${parsedValue.substring(0, 11)}-${parsedValue.substring(11)}`;
  }
  if (parsedValue.length > 14) {
    parsedValue = parsedValue.substring(0, 14);
  }
  return parsedValue;
};

export const toCPFMasked = (value) => {
  const parsedValue = toCPF(value);
  return `${parsedValue.slice(0, 3)}.XXX.XXX-${parsedValue.slice(-2)}`;
};

export const toCNPJ = (value) => {
  let parsedValue = String(value);

  if (parsedValue.length > 2) {
    parsedValue = `${parsedValue.substring(0, 2)}.${parsedValue.substring(2)}`;
  }
  if (parsedValue.length > 6) {
    parsedValue = `${parsedValue.substring(0, 6)}.${parsedValue.substring(6)}`;
  }
  if (parsedValue.length > 10) {
    parsedValue = `${parsedValue.substring(0, 10)}/${parsedValue.substring(10)}`;
  }
  if (parsedValue.length > 15) {
    parsedValue = `${parsedValue.substring(0, 15)}-${parsedValue.substring(15)}`;
  }
  if (parsedValue.length > 18) {
    parsedValue = parsedValue.substring(0, 18);
  }
  return parsedValue;
};
export function phoneMask(v) {
  let r = v.replace(/\D/g, '');
  r = r.replace(/^0/, '');

  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else if (v.trim() !== '') {
    r = r.replace(/^(\d*)/, '($1');
  }
  return r;
}

export const toCurrency = (value) => value.toLocaleString('pt-br', {
  style: 'currency',
  currency: 'BRL',
});
