import types from './types';
import {
  getSolicitacoesCredito,
  getVendedoresSimplificado
} from './repository';
import { getFormValues } from 'redux-form';
import { getCanalDeVendas, getUnidadeDeNegocio } from '../../shared/services/app';
import { FILTER_SALLES_CHANNEL, FILTER_SELLERS } from '../../shared/utils/creditRequests';
import resolveError from '../../shared/utils/resolveError';

export const toggleFilterAction = ({ filterOpened }) => ({
  type: types.TOGGLE_FILTER,
  payload: { filterOpened },
});

export const getListCreditRequestsAction = ({ 
    dataInicio,
    dataFim,
    page = 1,
    rpp,
    idCanalVendas: idCanalVendasAction,
    ordenacao = '',
    campoOrdenacao = ''
  }) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_CREDIT_REQUESTS_REQUEST });

    const filters = getFormValues('creditRequests/filter')(getState());
    const { user: { idVendedor, idCargo} } = getState().auth.login;
    const idUnidadeNegocio = getUnidadeDeNegocio();
    const idCanalVendas = getCanalDeVendas();

    const status = filters.status.join(',');
    const { orcamento, statusPedido, idCanalVendas: idCanalVendasFilter, idVendedor: idVendedorFilter } = filters;

    const idCanalVendasRequest = () => {
      if (FILTER_SALLES_CHANNEL.includes(idCargo)) {
        return idCanalVendasFilter;
      } else {
        return idCanalVendas;
      }
    };

    const idVendedorRequest = () => {
      if (FILTER_SELLERS.includes(idCargo)) {
        if (!FILTER_SALLES_CHANNEL.includes(idCargo)) {
          return idVendedorFilter;
        } else {
          if (idCanalVendasRequest && idCanalVendasRequest !== '') {
            return idVendedorFilter;
          } else {
            return ''
          }
        }
      } else {
        return idVendedor;
      }
    }

    const { results, meta } = await getSolicitacoesCredito(
      {
        rpp,
        page,
        status,
        orcamento,
        statusPedido,
        idVendedor: idVendedorRequest(),
        idCanalVendas: idCanalVendasRequest(),
        idUnidadeNegocio,
        dataInicio,
        dataFim,
        ordenacao,
        campoOrdenacao
      }
    );

    dispatch({
      type: types.GET_CREDIT_REQUESTS_SUCCESS,
      payload: {
        creditRequests: results,
        rpp: meta.rpp,
        totalpages: meta.totalpages,
        currentPage: meta.page,
        count: meta.size || 0,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CREDIT_REQUESTS_FAILURE });
  }
};

export const getVendedoresSimplificadoAction = (idCanalVendas, idCargos) => async (dispatch, getState) => {
  // dispatch({ type: types.GET_VENDEDORES_REQUEST });
  const filters = getFormValues('creditRequests/filter')(getState());

  const { user: { idGrupo } } = getState().auth.login;
  try {
    const results = await getVendedoresSimplificado({idCanalVendas});

    dispatch({
      type: types.GET_VENDEDORES_ATIVOS_SIMPLIFICADO,
      payload: {
        vendedoresSimplificado: results
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_VENDEDORES_FAILURE });
  }
};