import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, Chip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Close } from '@material-ui/icons';
import { hideItemSearchAction } from '../../actions';
import useStyles from './styles';

const ItemDialogSearch = ({
  showItemSearch, hideItemSearch,
  setOpenAutoComplete, setValidationList, setValidationFooter,
}) => {
  const classes = useStyles();

  const onClose = async () => {
    await hideItemSearch();
    setOpenAutoComplete(true);
    setValidationList(true);
    setValidationFooter(false);
  };
  return (
    <Dialog open={!!showItemSearch} onClose={onClose} fullWidth maxWidth="sm">
      {showItemSearch && (
      <>
        <DialogTitle>
          <Box className={classes.dialogHeader}>
            <Box>
              {showItemSearch.nome}
            </Box>

            <Box position="relative" top={-8} left={14}>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <Box textAlign="center" mb={2}>
            <img
              src={`https://img.lojadomecanico.com.br/IMAGENS/${showItemSearch.imagens[0].url}`}
              alt=""
              className={classes.img}
            />
          </Box>

          <Box className={classes.chipContainer}>
            {showItemSearch.filiais.map((filial) => (
              <Chip
                label={`${filial.nomeFilial}: ${filial.estoque}`}
                key={filial.idFilial}
                className={classes.chip}
              />
            ))}
          </Box>
          <Typography variant="h6">
            DESCRIÇÃO DO PRODUTO
          </Typography>

          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: showItemSearch.fichaTecnica.replace(/(:{2})/g, '<strong>#</strong>'),
            }}
          />
        </DialogContent>
      </>
      )}
    </Dialog>
  );
};

ItemDialogSearch.defaultProps = {
  showItemSearch: null,
};

ItemDialogSearch.propTypes = {
  showItemSearch: PropTypes.instanceOf(Object),
  hideItemSearch: PropTypes.func.isRequired,
  setOpenAutoComplete: PropTypes.func.isRequired,
  setValidationList: PropTypes.func.isRequired,
  setValidationFooter: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.checkout.geral;

export default connect(mapState, {
  hideItemSearch: hideItemSearchAction,
})(ItemDialogSearch);
