import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Close } from '@material-ui/icons';
import { postDeclinedAction, getPedidosAction } from '../actions';
import useStyles from './styles';

const DialogDeclined = ({
  setOpen, optionDeclinio,
  postDeclined, idRecuperacao, getPedidos,
}) => {
  const classes = useStyles();
  const [declined, setDeclined] = useState('');
  const [justificativa, setJustificativa] = useState('');

  const arrayOption = [];
  optionDeclinio.forEach((item) => {
    const { id: value, descricao: label } = item;
    arrayOption.push({ value, label });
  });

  const handleClose = async () => {
    setOpen(false);
  };
  const handleDeclined = async () => {
    await postDeclined({
      idRecuperacao,
      idMotivo: declined,
      mensagemMotivo: justificativa !== '' ? justificativa : null,
    });
    await handleClose();
    getPedidos();
  };

  return (
    <div className={classes.paperDeclined}>
      <Box position="relative">
        <Box position="absolute" top={4} right={4}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        <DialogTitle>
          Declinar Pedido
        </DialogTitle>

        <div className={classes.divBodyModal}>
          <Grid item xs={12}>
            <Typography>Motivo do Declínio</Typography>
            <Select
              options={arrayOption}
              placeholder="Selecione uma opção"
              onChange={(e) => setDeclined(e.value)}
            />
          </Grid>

          {declined === 9
          && (
          <Grid item xs={12} style={{ marginTop: 24 }}>
            <Typography>Justificativa</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              multiline
              rows={11}
              value={justificativa}
              onChange={(e) => setJustificativa(e.target.value)}
            />
          </Grid>
          )}

        </div>

        <DialogActions>
          <Button
            className={classes.buttonModalCancelar}
            variant="contained"
            onClick={handleClose}
          >
            CANCELAR
          </Button>

          <Button
            onClick={handleDeclined}
            className={classes.buttonModalDeclinar}
            variant="contained"
          >
            ENVIAR
          </Button>
        </DialogActions>
      </Box>
    </div>
  );
};

DialogDeclined.propTypes = {
  optionDeclinio: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setOpen: PropTypes.func.isRequired,
  postDeclined: PropTypes.func.isRequired,
  idRecuperacao: PropTypes.number.isRequired,
  getPedidos: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.recovery.list;

export default connect(mapState, {
  postDeclined: postDeclinedAction,
  getPedidos: getPedidosAction,
})(DialogDeclined);
