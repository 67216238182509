import React from "react";
import * as PropTypes from "prop-types";

import { Alert } from "@material-ui/lab";

import useStyles from "./styles";

const AlertMessage = ({
  children,
  icon,
  className
}) => {
  const {
    alertIcon,
    alertShipping
  } = useStyles();

  return (
    <Alert
      icon={icon != "none" && (<span className={alertIcon}></span>)}
      className={`${alertShipping} ${className}`} 
    >
      {children}
    </Alert>
  )
};

AlertMessage.defaultProps = {
  icon: "none",
  className: ""
};

AlertMessage.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOf(["none", "warning"]),
  className: PropTypes.string
};


export default AlertMessage;
