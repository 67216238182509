import apiService from '../services/apiService';

// eslint-disable-next-line import/prefer-default-export
export const getClient = async (clientId) => {
  const { data: { results } } = await apiService.get(`/clientes/${clientId}`);
  const {
    nomeRazaoSocial,
    cpfCnpj,
    tipoPessoa,
    enderecos,
    rgIe,
    ddd,
    telefone,
    email,
    qtdPedidos,
    dataCadastro,
    nomeComprador,
  } = results.value;

  let customerSince;
  if (dataCadastro) {
    const [sinceDay, sinceMonth, sinceYear] = dataCadastro.split('/');
    customerSince = new Date(sinceYear, sinceMonth, sinceDay);
  }

  const {
    cep, logradouro, numero, bairro, cidade, estado, complemento, informacoesAdicionais,
  } = enderecos.find((address) => address.bitPrincipal) || {};

  return {
    clientId,
    name: nomeRazaoSocial,
    document: cpfCnpj,
    rgIe,
    typePerson: tipoPessoa,
    phone: ddd + telefone,
    email,
    amountOfOrders: qtdPedidos,
    nomeComprador,
    customerSince,
    address: {
      zipCode: cep,
      street: logradouro,
      neighborhood: bairro,
      city: cidade,
      state: estado,
      number: numero,
      complement: complemento,
      addInfo: informacoesAdicionais,
    },
  };
};
