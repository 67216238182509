import React from 'react';
import { isModifierClick } from 'react-instantsearch/dist/es/ui/lib';
import PropTypes from 'prop-types';
import useStyles from './styles';

const HierarchicalList = ({
  items,
  createURL,
  onNavigate,
  setSelected,
}) => {
  const classes = useStyles();
  const {
    content,
    countStyled,
  } = classes;

  if (items.length === 0) {
    return null;
  }

  const handleOnNavigate = (event, value) => {
    if (isModifierClick(event)) {
      return;
    }
    event.preventDefault();

    onNavigate(value);
    setSelected(value);
  };

  return (
    <ul>
      {items.map(({
        value, isRefined, label, count, data,
      }) => (
        <li key={`${value}`}>
          <a
            className={content}
            href={createURL(value)}
            onClick={(e) => handleOnNavigate(e, value, isRefined)}
            style={{ fontWeight: isRefined ? 'bold' : 'normal' }}
          >
            <span>{label}</span>
            <span className={countStyled}>{count}</span>
          </a>
          {data && (
          <HierarchicalList
            items={data}
            onNavigate={onNavigate}
            createURL={createURL}
            setSelected={setSelected}
          />
          )}
        </li>
      ))}
    </ul>
  );
};

HierarchicalList.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  createURL: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default HierarchicalList;
