import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Typography,
  List,
  ListItem,
  LinearProgress,
} from '@material-ui/core';

import {
  LocalShipping,
  ExpandMore,
  FlashOn,
  CalendarToday,
} from '@material-ui/icons';


import { toMoney } from '../../../../shared/utils/parsers';
import { FlatExpansionPanelSummary, FlatExpansionPanel } from '../../../../shared/components/flatExpansionPanel';
import useStyles from './styles';

const Freight = ({
  enablePickup,
  enablePickupTransfer,
  enableNitrous,
  forceFreight,
  checkedNormal,
  checkedSameday,
  checkedPickup,
  checkedNitrous,
  checkedSchedule,
  enableSchedule,
  dataEntregaAgendadaEscolhida,
  normalFreight,
  getCheckout,
  disabled,
  hasPayment,
  chosedFreight,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);

  const handleSelect = (object) => {
    getCheckout({ ...object });
    setOpen(false);
  }

  const isEnabledToSelect = (freight) => {
    switch (freight) {
      case 'transportadora':
        return (!chosedFreight && !hasPayment)
            || !checkedNormal
            || forceFreight
            || checkedNitrous;

      case 'retiraLoja':
        return enablePickup
          && !enablePickupTransfer
          && !checkedPickup;

      case 'retiraTransferencia':
        return enablePickupTransfer
            && !checkedPickup;

      case 'forcarCd':
        return !forceFreight;

      case 'entregaAgendada':
        return enableSchedule;

      case 'entregaNitro':
        return enableNitrous
            && !checkedNitrous;

      default:
        return false;
    }
  }

  const isSelected = (freight) => {
    switch (freight) {
      case 'unselected':
        return !chosedFreight
            && !hasPayment;

      case 'transportadora':
        return (chosedFreight || hasPayment)
            && (checkedNormal || (!enablePickup && !enablePickupTransfer))
            && !forceFreight
            && !checkedNitrous
            && !checkedSchedule

      case 'retiraLoja':
        return chosedFreight
            && enablePickup
            && checkedPickup
            && !enablePickupTransfer
            && !forceFreight;

      case 'retiraTransferencia':
        return chosedFreight
            && checkedPickup
            && enablePickupTransfer
            && !forceFreight;

      case 'forcarCd':
        return chosedFreight
            && forceFreight
            && !checkedNitrous;

      case 'sameDay':
        return chosedFreight
            && checkedSameday;

      case 'entregaNitro':
        return chosedFreight
            && checkedNitrous;

      case 'entregaAgendada':
        return chosedFreight
            && checkedSchedule;

      default:
        return false;
    }
  }

  const getEntregaAgendadaInfo = () => {
    if (dataEntregaAgendadaEscolhida) {
      return (
        <>
          {moment(dataEntregaAgendadaEscolhida, 'DD/MM/YYYY').format('DD')
          + ' de '
          + moment(dataEntregaAgendadaEscolhida, 'DD/MM/YYYY').format('MMMM')
          + ' '
          + toMoney(normalFreight)}
        </>
      );
    }

    return '';
  }

  return (
    <>
      <FlatExpansionPanel
        square
        expanded={open}
        onChange={handleToggle}
        className={classes.root}
      >
        <div>
          <FlatExpansionPanelSummary
            disabled={disabled} 
            className={`${classes.accordion} ${classes.orange}`}
            expandIcon={<ExpandMore className={classes.orange}/>}
          >

            <Typography component="h1" className={classes.accordionTitle}>
              <LocalShipping className={classes.accordionIcon}/>

              {isSelected('unselected') && 'SELECIONE UMA ENTREGA'}

              {isSelected('transportadora') && `TRANSPORTADORA - ${toMoney(normalFreight)}`}

              {isSelected('retiraLoja') && `RETIRAR NA LOJA - GRÁTIS`}

              {isSelected('retiraTransferencia') && `RETIRAR POR TRANSFERÊNCIA - GRÁTIS`}

              {isSelected('forcarCd') && `FORÇAR CD DE SAÍDA`}

              {isSelected('sameDay') && `MESMO DIA - R$ 20,00`}

              {isSelected('entregaNitro') && `ENTREGA EXPRESSA - ${toMoney(normalFreight)}`}

              {isSelected('entregaAgendada') && (
                <>
                  ENTREGA AGENDADA
                  <br/>
                  {getEntregaAgendadaInfo()}
                </>
              )}

            </Typography>
          </FlatExpansionPanelSummary>

          {disabled && (<LinearProgress style={{height: 2}}/>)}
        </div>

        <Typography component="h1" className={classes.labelFreight}>
          Entregas disponíveis
        </Typography>

        <List
          component="div"
          disablePadding
          className={classes.list}
        >

          {isEnabledToSelect('transportadora') && (
            <ListItem button onClick={() => handleSelect()}>
              TRANSPORTADORA
            </ListItem>
          )}

          {isEnabledToSelect('retiraLoja') && (
            <ListItem button onClick={() => handleSelect({ isPickup: true })}>
              RETIRAR NA LOJA
            </ListItem>
          )}

          {isEnabledToSelect('retiraTransferencia') && (
            <ListItem button onClick={() => handleSelect({ isPickup: true })} >
              RETIRAR POR TRANSFERÊNCIA - GRÁTIS
            </ListItem>
          )}

          {isEnabledToSelect('forcarCd') && (
            <ListItem button onClick={() => handleSelect({ forcarFrete: true })}>
              FORÇAR CD DE SAÍDA
            </ListItem>
          )}

          {isEnabledToSelect('entregaAgendada') && (
            <ListItem button onClick={() => handleSelect({ entregaAgendada: true })}>
              ENTREGA AGENDADA
              <CalendarToday className={`${classes.iconNitrous} ${classes.orange}`}/>
            </ListItem>
          )}

          {isEnabledToSelect('entregaNitro') && (
            <ListItem button onClick={() => handleSelect({ entregaNitro: true })}>
              ENTREGA EXPRESSA
              <FlashOn className={`${classes.iconNitrous} ${classes.orange}`}/>
            </ListItem>
          )}
        </List>
      </FlatExpansionPanel>
    </>
  );
};

Freight.defaultProps = {
  filial: null,
  dataEntregaAgendadaEscolhida: "",
};

Freight.propTypes = {
  getCheckout: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  hasPayment: PropTypes.bool.isRequired,
  idCheckout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  normalFreight: PropTypes.number.isRequired,
  enablePickup: PropTypes.bool.isRequired,
  enablePickupTransfer: PropTypes.bool.isRequired,
  enableNitrous: PropTypes.bool.isRequired,
  enableSchedule: PropTypes.bool.isRequired,
  forceFreight: PropTypes.bool,
  dataEntregaAgendadaEscolhida: PropTypes.string,
  checkedNormal: PropTypes.bool.isRequired,
  checkedPickup: PropTypes.bool.isRequired,
  checkedSameday: PropTypes.bool.isRequired,
  checkedNitrous: PropTypes.bool.isRequired,
  checkedSchedule: PropTypes.bool.isRequired,
  dataEntregaAgendada: PropTypes.string.isRequired,
  chosedFreight: PropTypes.bool.isRequired,
  filial: PropTypes.instanceOf(Object),
};

const mapState = (state) => ({
  ...state.auth.login,
  ...state.main.app,
});

export default connect(mapState, {
})(Freight);
