import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    width: 560,
    marginTop: 24,
    marginBottom: 24,
    padding: 24,
    boxShadow: 'none',
    borderRadius: 16,
    border: '1px solid #CFCFD4',
    '&& .MuiCardActions-root': {
      display: 'grid',
      gap: 24,
      padding: 0,
      margin: 0,
      '& .MuiButton-root': {
        padding: 0,
        margin: 0,
      },
    },

    '&.cardCustom': {
      padding: '24px 0',
    },

    [breakpoints.up('sm')]: {
      minWidth: 560,

      '&.cardCustom': {
        padding: '16px 0',
      },
    },
  },
  cardContent: {
    '&&': {
      padding: 0,
      margin: 0,
    },
  },
}));

export default useStyles;
