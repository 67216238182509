/* eslint-disable react/no-array-index-key */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  LinearProgress,
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  Modal,
  Tooltip,
  Divider,
  Box,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRange, FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CardBarNew from '../../../../shared/components/cardBarNew';
import { ReactComponent as ZoiperLogo } from '../../../../assets/zoiper.svg';
import useStyles from './styles';
import Products from './Products';
import apiService from '../../../../shared/services/apiService';
import resolveError from '../../../../shared/utils/resolveError';
import DialogDeclined from './DialogDeclined';
import {
  getListDeclinioAction,
  getPedidosAction,
} from '../actions';
import PAYMENT_OPTIONS from '../../../../shared/utils/paymentOptions';
import { toMoney, toPercent, toPhone } from '../../../../shared/utils/parsers';
import LabelTypography from '../../../../shared/components/labelTypography/LabelTypography';

import { postRecuperarPedidos } from '../repository';
import { push } from '../../../../history';
const Pedido = ({
  loading, pedidos, getPedidos, getListDeclinio, snack, user,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [codCliente, setCodCliente] = useState('');
  const [dadosCliente, setDadosCliente] = useState([]);
  const [permission, setPermission] = useState(true);
  const [requestingRecovery, setRequestingRecovery] = useState(false);

  setTimeout(() => {
    const x = PAYMENT_OPTIONS.find((a) => a.code === pedidos.tipoPagamento);
    setPaymentType(x);
  }, 100);
  const handleClose = () => {
    setOpen(false);
  };

  const handleRecuperar = () => {
    if (requestingRecovery) return;

    setRequestingRecovery(true);
    postRecuperarPedidos({
      idRecuperacao: pedidos.idRecuperacao,
    }).then((response) => {
      push(`/checkout/${response.results.checkoutId}`);
      snack.enqueueSnackbar('Pedido Recuperado com sucesso!', { variant: 'success' });
    }).finally(() => {
      setRequestingRecovery(false);
    });
  };

  const handleLigar = async () => {
    try {
      const data = await apiService.post(`/vendedores/discagem/${user.ramal}/${pedidos.telefone}`);
      if (data.status) {
        snack.enqueueSnackbar('Estamos ligando para seu cliente!', { variant: 'success' });
      }
    } catch (error) {
      snack.enqueueSnackbar(resolveError(error), { variant: 'error' });
    }
  };

  const recoveryPedido = user.permissoes.find((permission) => (
    permission.id === 3
  ));

  if (!recoveryPedido) {
    setPermission(false);
    window.location.href = process.env.REACT_APP_HOST_CATALAGO;
  }
  
  const getDadosCliente = async () => {
    try {
      setCodCliente(pedidos.codCliente);
      const data = await apiService.get(`/cliente/${pedidos.codCliente}/detalhescrm`);
      // const data = await apiService.get(`https://mocki.io/v1/c1e2ff7c-37b3-41d7-bc41-70bf20812ea1`);
      if (data.status) {
        setDadosCliente(data.data.results);
      }
    } catch (error) {
    }
  };

  if (pedidos.codCliente && pedidos.codCliente !== codCliente) {
    getDadosCliente();
  }

  useLayoutEffect(() => {
    getPedidos();
    getListDeclinio();
  }, [getPedidos])

  useEffect(() => {
    if (Object.keys(pedidos).length === 0) {
      setDadosCliente({})
    }
  }, [getPedidos, pedidos])
  return (
    <div>
      <CardBarNew title="Home" />
      {loading && permission && (<LinearProgress />)}
      <section className={classes.section}>
        <Paper elevation={1} className={classes.paperHeaderPedido}>
          {Object.keys(pedidos).length > 0 && (dadosCliente.nomeCliente || dadosCliente.dataCadastro) ? (
            <>
              <Typography className={classes.customerTitle} variant="subtitle1" >Dados do Cliente</Typography>
              <Grid container className={classes.customerGrid}>
                <Grid item sm={3}>
                  <LabelTypography label="Nome do Cliente" text={(dadosCliente.nomeCliente + (dadosCliente.nomeSocial ? ` (${dadosCliente.nomeSocial})` : '')) || 'N/D'}/>
                </Grid>

                <Grid item sm={3}>
                  <LabelTypography label="Data do Cadastro" text={dadosCliente.dataCadastro}/>
                </Grid>

                <Grid item sm={2}>
                  <LabelTypography label="Pedidos Realizados" text={dadosCliente.quantidadeTotalDePedidos}/>
                </Grid>

                <Grid item sm={2}>
                  <LabelTypography label="Pedidos Pagos" text={dadosCliente.quantidadeDePedidosPagos}/>
                </Grid>

                <Grid item sm={2}>
                  <LabelTypography label="Ticket Médio" text={toMoney(dadosCliente.ticketMedioPorPedido)}/>
                </Grid>

                <Grid item sm={3}>
                  <LabelTypography label="Data Último Pedido Pago" text={dadosCliente.dataUltimoPedidoPago || 'N/D'}/>
                </Grid>

                <Grid item sm={3}>
                  <LabelTypography label="% maior cupom disponível" text={toPercent(dadosCliente.cupomDeDesconto)}/>
                </Grid>

                <Grid item sm={2}>
                  <LabelTypography label="Somatória dos Vales" text={toMoney(dadosCliente.saldoTotalValesBSeller)}/>
                </Grid>

                <Grid item sm={3}>
                  <LabelTypography label="Média de SKUs" text={dadosCliente.mediaDeSkusPorPedido}/>
                </Grid>
              </Grid>

              <Divider className={classes.customerDivider}/>
              <Typography className={classes.customerTitle} variant="subtitle1" >Dados do Pedido</Typography>

              <Grid container>
                <Grid item xs={3}>
                  <LabelTypography label="Data Pedido" text={pedidos.dataPedido}/>
                </Grid>

                <Grid item xs={3}>
                  <LabelTypography label="Valor" text={toMoney(pedidos.vlrTotal)}/>
                </Grid>

                <Grid item xs={3}>
                  <LabelTypography label="Qtd. Contatos Recebidos" text={pedidos.qtdeContatosRecuperacaoVendas}/>
                </Grid>

                <Grid item xs={3}>
                  <Box display="flex">
                    <Button
                      onClick={handleLigar}
                      startIcon={<ZoiperLogo />}
                      className={classes.buttonLigar}
                    >
                      LIGAR
                      {pedidos.telefone.length > 5 && `- ` + toPhone(pedidos.telefone)}
                    </Button>
                    <CopyToClipboard
                      // text={pedidos.telefone}
                      text={toPhone(pedidos.telefone)}
                    >
                      <Tooltip title="Copiar Telefone">
                        <Button
                          className={classes.buttonCopyPhone}
                        >
                          <FileCopyOutlined />
                        </Button>
                      </Tooltip>
                    </CopyToClipboard>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) :  Object.keys(pedidos).length === 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" gridColumnGap={16}>
              <Typography variant="body2" style={{ fontWeight: 800 }}>Não existem mais pedidos ou carrinhos para recuperar</Typography>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" gridColumnGap={16}>
              <CircularProgress />
              <Typography variant="body2" style={{ color: '#888E9D' }}>Carregando...</Typography>
            </Box>
          )
        }
        </Paper>
        <Paper elevation={3} className={classes.paperListProdutos}>
          <div className={classes.headerProducts}>
            <div className={classes.divInfoPedidoHeader}>
              <span>
                <span>PEDIDO Nº </span>
                <span className={classes.TextOrangeWeight}>{pedidos.codPedido}</span>
              </span>
              <div className={classes.divInfoPedidoDetalheHeader}>
                <div className={classes.divDataHeader}>
                  <DateRange style={{ fontSize: 16 }} />
                  <span>{pedidos.dataPedido}</span>
                </div>
                <span>
                  <span>VALOR: </span>
                  <span className={classes.TextOrangeWeight}>{toMoney(pedidos.vlrTotal)}</span>
                </span>
                <span>
                  <span>FRETE: </span>
                  <span className={classes.TextOrangeWeight}>{toMoney(pedidos.freteTotal)}</span>
                </span>
              </div>
            </div>
            <div className={classes.divInfoPagamentoHeader}>
              <span className={classes.textWeight500}>DETALHES DE PAGAMENTO</span>
              <div className={classes.divInfoPagamentoDetalheHeader}>
                <span>
                  <span>TIPO: </span>
                  <span className={classes.TextOrangeWeight}>{`${paymentType !== undefined ? paymentType.name : 'Indefinido'}`}</span>
                </span>
                <span>
                  <span>VALOR: </span>
                  <span className={classes.TextOrangeWeight}>{toMoney(pedidos.vlrTotal)}</span>
                </span>
              </div>
            </div>
            <div className={classes.divInfoRecusaHeader}>
              {/* <span className={classes.textWeight500}>MOTIVO NÃO PAGAMENTO</span>
              <div className={classes.divInfoRecusaDetalhesHeader}>
                <span className={classes.TextOrangeWeight}>CARTÃO RECUSADO</span>
                <span>
                  <span>ERRO: </span>
                  <span className={classes.TextOrangeWeight}}>08</span>
                </span>
              </div> */}
            </div>
          </div>
          <div className={classes.divScrollProduct}>
            {pedidos.produtos && pedidos.produtos.map((item, index) => (
              <Products item={item} key={`${item.id} - ${index}`} />
            ))}
          </div>
        </Paper>
      </section>
      <div className={classes.divFooter}>
        {Object.keys(pedidos).length > 0
          ? <span>''</span>
          : (
            <span className={classes.textMsgPedidos}>
              Não existem mais pedidos ou carrinhos para recuperar
            </span>
          )}
        <div>
          <Button
            disabled={Object.keys(pedidos).length === 0}
            className={classes.buttonRecuperar}
            onClick={handleRecuperar}
          >
            RECUPERAR PEDIDO

          </Button>
        </div>
      </div>
      <Modal open={open} onClose={handleClose} className={classes.modal}>
        <DialogDeclined setOpen={setOpen} idRecuperacao={pedidos.idRecuperacao} />
      </Modal>

    </div>
  );
};
Pedido.defaultProps = {
  snack: null,
};

Pedido.propTypes = {
  loading: PropTypes.bool.isRequired,
  pedidos: PropTypes.instanceOf(Object).isRequired,
  getPedidos: PropTypes.func.isRequired,
  getListDeclinio: PropTypes.func.isRequired,
  snack: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => {
  const { snack } = state.main.app;
  const { checkout } = state.main.checkout.geral;
  return ({
    ...state.main.app,
    ...state.main.recovery.list,
    user: state.auth.login.user,
    checkout,
    snack,
  });
};
export default connect(mapState, {
  getPedidos: getPedidosAction,
  getListDeclinio: getListDeclinioAction,
})(Pedido);
