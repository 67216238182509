import React from 'react';
import { Route } from 'react-router-dom';
import DiscountPage from './list/Page';
import CustomSwitch from '../../shared/components/customSwitch';

const DiscountRouter = () => (
  <CustomSwitch>
    <Route
      path="/discount"
      component={DiscountPage}
      exact
    />
  </CustomSwitch>
);

export default DiscountRouter;
