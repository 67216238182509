import React from 'react';
import {
  LinearProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ldm from '../../../../assets/ldm.png';
import CardBarNew from '../../../../shared/components/cardBarNew';
import useStyles from '../../../salesChannel/styles';

const HomeRecovery = ({
  loading,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.divRow}>
      <div style={{ width: '100%' }}>
        <CardBarNew title="Home" />
        <section style={{ marginTop: 7, width: '80%' }}>
          {loading && (<LinearProgress />)}
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 250,
          }}
          >
            <img src={ldm} alt="ldm" style={{ height: 500, width: 500 }} />
          </div>
        </section>
      </div>

    </div>
  );
};

HomeRecovery.propTypes = {
  loading: PropTypes.bool.isRequired,
  // checkouts: PropTypes.arrayOf(Object).isRequired,
  // checkoutMeta: PropTypes.instanceOf(Object).isRequired,
  // setCheckoutMeta: PropTypes.func.isRequired,
  // clearMeta: PropTypes.func.isRequired,
  // takeCheckout: PropTypes.func.isRequired,
};

const mapState = (state) => state.main.recovery.list;

export default connect(mapState, {

})(HomeRecovery);
