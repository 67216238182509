import React from 'react';
import { Route } from 'react-router-dom';
import RequestsPage from './list/Page';
import CustomSwitch from '../../shared/components/customSwitch';

const RequestRouter = () => (
  <CustomSwitch>
    <Route
      path="/requests"
      component={RequestsPage}
      exact
    />
  </CustomSwitch>
);

export default RequestRouter;
