import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  change,
  Form,
  formValueSelector,
  hasSubmitFailed,
  reduxForm,
  submit,
  updateSyncErrors,
} from 'redux-form';
import { PropTypes } from 'prop-types';
import { Search } from '@material-ui/icons';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { push } from '../../../../history';

import { getZipcodeDataAction, requestRegisterCustomerAction } from '../actions';
import { CheckboxForm } from '../_components';
import { Card, ButtonActions } from '../../_components';
import { InputFieldForm } from '../../_components/forms';

import {
  InputCEPRender, InputCpfRender, InputPhoneRender, RadioGroupRender,
} from '../../../../shared/fields';
import {
  cpfValid,
  email,
  alphabetic,
  required,
  differentName,
  minLength10,
  minLength3,
  minLength8,
  number,
  maxLength5,
  maxLength14,
  cellphone,
} from '../../../../shared/fields/validate';

import CircularLoading from '../../../../shared/components/loading/CircularLoading';
import AlertModal from '../_components/alertModal/AlertModal';

import useStyles from './styles';
import { clientSignInAction } from '../../login/actions';

const IndividualPerson = ({
  loading,
  handleSubmit,
  getZipcodeData,
  requestRegisterCustomer,
  semNumero,
  produtorRural,
  snack,
  cep,
  bitGenerico,
}) => {
  const {
    cardGrid,
    cardContent,
    contentTitle,
    complementoGrid,
    complementoMobileGrid,
    complementoStyled,
  } = useStyles();

  const anyLoading = loading.customer || loading.customerDocumentValidate || loading.zipcode;
  const dispatch = useDispatch();
  const [customerModal, setCustomerModal] = useState(false);

  const handleSnackbar = (message, variant) => snack.enqueueSnackbar(message, { variant });
  const handleRemoteSubmit = () => dispatch(submit('register/IndividualPerson'));

  const customValidate = (form) => {
    if (differentName(form.nome, form.nomeSocial)) {
      dispatch(updateSyncErrors('register/IndividualPerson', {
        nomeSocial: 'Nome Social deve ser diferente do Nome Completo',
      }));
      handleSnackbar('Dados inválidos. Verifique os dados.', 'error');
      return false;
    }
    return true;
  };

  const handleRequest = async (form) => {
    if (customValidate(form)) {
      await requestRegisterCustomer(form);
    }
  };

  const handleCloseModalCustomer = () => {
    setCustomerModal(false);
    dispatch(change('register/IndividualPerson', 'cpf', ''));
  };

  const handleAcceptModalCustomer = () => {
    setCustomerModal(false);
    push('/client-login');
  };

  const handleCepRequest = () => {
    if (loading.zipcode) return;
    if (cep.length < 8) return;

    getZipcodeData(cep).then((res) => {
      dispatch(change('register/IndividualPerson', 'endereco.bitGenerico', res.flagcepgenerico));
      dispatch(change('register/IndividualPerson', 'endereco.logradouro', res.logradouro));
      dispatch(change('register/IndividualPerson', 'endereco.bairro', res.bairro));
      dispatch(change('register/IndividualPerson', 'endereco.cidade', res.localidade));
      dispatch(change('register/IndividualPerson', 'endereco.estado', res.uf));
    }).catch((_) => {
      dispatch(change('register/IndividualPerson', 'endereco.bitGenerico', false));
      dispatch(change('register/IndividualPerson', 'endereco.rua', ''));
      dispatch(change('register/IndividualPerson', 'endereco.bairro', ''));
      dispatch(change('register/IndividualPerson', 'endereco.cidade', ''));
      dispatch(change('register/IndividualPerson', 'endereco.estado', ''));
    });
  };

  const handleChangeProdutorRural = (_, value) => dispatch(change('register/IndividualPerson', 'produtorRural', Boolean(value)));

  useEffect(() => {
    if (semNumero) dispatch(change('register/IndividualPerson', 'endereco.numero', ''));
  }, [semNumero]);

  useEffect(() => {
    dispatch(change('register/IndividualPerson', 'rgIe', ''));
  }, [produtorRural]);

  return (
    <Grid className={cardGrid}>
      <AlertModal
        open={customerModal}
        title="Cliente já cadastrado"
        message="O cliente já se encontra cadastrado, deseja fazer login?"
        handleClose={handleCloseModalCustomer}
        handleAccept={handleAcceptModalCustomer}
      />
      <CircularLoading open={loading.customer} message="Cadastrando cliente..." />
      <Card
        title="Cadastro de Pessoa Física"
        subtitle={(
          <>
            <Typography variant="subtitle2">Cliente já possui cadastro?</Typography>
            <Button
              variant="text"
              component={Link}
              to="/client-login"
            >
              Entrar
            </Button>
          </>
        )}
        icon="individual-person"
      >

        <CardContent className={cardContent}>
          <Typography className={contentTitle}>Dados Cadastrais:</Typography>
          <Form onSubmit={handleSubmit(handleRequest)}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="nome"
                  label="Nome Completo"
                  minLength={3}
                  maxLength={50}
                  validate={[required, minLength3]}
                  required
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="nomeSocial"
                  minLength={3}
                  maxLength={50}
                  label="Nome Social"
                  validate={[alphabetic, minLength3]}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <InputFieldForm
                  name="telefone"
                  component={InputPhoneRender}
                  label="Telefone"
                  validate={[required, cellphone]}
                  required
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <InputFieldForm
                  name="telefoneSecundario"
                  component={InputPhoneRender}
                  label="Telefone Secundário"
                  validate={[cellphone]}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="email"
                  label="E-mail"
                  required
                  maxLength={60}
                  validate={[email, required]}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="cpf"
                  component={InputCpfRender}
                  placeholder="ex. 100.100.100-10"
                  label="CPF"
                  required
                  disabled={loading.customerDocumentValidate}
                  validate={[required, cpfValid]}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="produtorRural"
                  onChange={handleChangeProdutorRural}
                  value={produtorRural}
                  normalize={(v) => (v === 'true')}
                  component={RadioGroupRender}
                  label="Cliente é produtor rural?"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
                {produtorRural && (
                  <Box marginTop={2}>
                    <InputFieldForm
                      name="rgIe"
                      label="Inscrição Estadual"
                      maxLength={14}
                      validate={[required, number]}
                      required
                    />
                  </Box>
                )}
              </Grid>
            </Grid>

            <Typography className={contentTitle}>Dados para Entrega:</Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.cep"
                  label="CEP"
                  component={InputCEPRender}
                  placeholder="ex. 12345-789"
                  onBlur={handleCepRequest}
                  endAdornment={(
                    <IconButton
                      style={{ marginTop: 10 }}
                      onClick={handleCepRequest}
                    >
                      <Search />
                    </IconButton>
                  )}
                  validate={[required, minLength8]}
                  required
                />
                <LinearProgress hidden={!loading.zipcode} />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.logradouro"
                  label="Rua / Avenida"
                  validate={[required]}
                  required
                  maxLength={60}
                  disabled={loading.zipcode || !bitGenerico}
                />
              </Grid>

              <Grid item sm={12} xs={12} className={complementoStyled}>
                <Box display="flex" alignItems="center">
                  <Grid item sm={2} xs={6} style={{ minHeight: 97 }}>
                    <InputFieldForm
                      name="endereco.numero"
                      label="Número"
                      validate={!semNumero && [required, number, maxLength5]}
                      required
                      pattern="\d*"
                      maxLength={5}
                      disabled={loading.zipcode || semNumero}
                      type="text"
                    />
                  </Grid>

                  <Grid item sm={4} xs={6}>
                    <Box marginLeft={2} height="100%" marginTop={2}>
                      <CheckboxForm
                        name="endereco.semNumero"
                        label="Sem Número"
                        color="primary"
                        disabled={loading.zipcode}
                        active
                        onChange={(status) => console.log(status)}
                      />
                    </Box>
                  </Grid>

                  <Grid item sm={6} className={complementoGrid}>
                    <InputFieldForm
                      name="endereco.complemento"
                      label="Complemento"
                      maxLength={50}
                      disabled={loading.zipcode}
                    />
                  </Grid>
                </Box>

                <Grid item sm={12} className={complementoMobileGrid}>
                  <InputFieldForm
                    name="endereco.complemento"
                    label="Complemento"
                    maxLength={50}
                    disabled={loading.zipcode}
                  />
                </Grid>
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.bairro"
                  label="Bairro"
                  validate={[required]}
                  maxLength={50}
                  required
                  disabled={loading.zipcode || !bitGenerico}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.cidade"
                  label="Cidade"
                  validate={[required]}
                  required
                  maxLength={50}
                  disabled
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.estado"
                  label="Estado"
                  validate={[required]}
                  maxLength={2}
                  required
                  disabled
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <InputFieldForm
                  name="endereco.informacoesAdicionais"
                  label="Informações Adicionais para Entrega"
                  required={semNumero}
                  disabled={loading.zipcode}
                  multiline
                  maxLength={200}
                  rows={4}
                  validate={semNumero && [required, minLength10]}
                  placeholder="Descrição da fachada, pontos de referência, informações de segurança etc."
                />
              </Grid>
            </Grid>
          </Form>
        </CardContent>

        <CardActions>
          <ButtonActions
            onClick={handleRemoteSubmit}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={anyLoading}
          >
            Realizar Cadastro
          </ButtonActions>

          <ButtonActions
            component={Link}
            to="/register"
            variant="outlined"
            color="primary"
            fullWidth
          >
            Voltar
          </ButtonActions>
        </CardActions>
      </Card>
    </Grid>
  );
};
const mapState = (state) => {
  const { snack } = state.main.app;
  const selector = formValueSelector('register/IndividualPerson');
  const nome = selector(state, 'nome');
  const nomeSocial = selector(state, 'nomeSocial');
  const cep = selector(state, 'endereco.cep');
  const produtorRural = selector(state, 'produtorRural');
  const semNumero = selector(state, 'endereco.semNumero');
  const bitGenerico = selector(state, 'endereco.bitGenerico');

  const submitFailed = hasSubmitFailed('register/IndividualPerson')(state);

  return {
    ...state.register,
    snack,
    nome,
    nomeSocial,
    cep,
    produtorRural,
    semNumero,
    submitFailed,
    bitGenerico,
  };
};

IndividualPerson.propTypes = {
  handleSubmit: PropTypes.func,
  snack: PropTypes.instanceOf(Object),
};

export default connect(mapState, {
  getZipcodeData: getZipcodeDataAction,
  requestRegisterCustomer: requestRegisterCustomerAction,
  clientSignIn: clientSignInAction,
})(reduxForm({
  form: 'register/IndividualPerson',
  initialValues: {
    nome: '',
    nomeSocial: '',
    telefone: '',
    telefoneSecundario: '',
    email: '',
    cpf: '',
    produtorRural: false,
    endereco: {
      cep: '',
      logradouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
      complemento: '',
      informacoesAdicionais: '',
      semNumero: false,
      bitPrincipal: true,
      bitInativo: false,
      bitGenerico: false,
    },
    rgIe: '',
  },
})(IndividualPerson));
