import { withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// eslint-disable-next-line import/prefer-default-export
export const AlertStyled = withStyles(() => ({
  root: {
    color: 'white',

    '&[class*=error]': {
      backgroundColor: '#F4282D',
    },

    '&[class*=success]': {
      backgroundColor: '#128745',
    },

    '& svg': {
      color: 'white',
    },
  },
}))(Alert);
