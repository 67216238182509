import { handleActions, combineActions } from 'redux-actions';
import { Types } from './index';

const INITIAL_STATE = {
  info: null,
  loading: {
    searchClient: false,
    updateClient: false,
  },
  hasException: null,
};

const stopLoadingSearch = combineActions(
  Types.GET_CLIENT_INFO_SUCCESS,
  Types.GET_CLIENT_INFO_FAILURE,
);

const stopLoadingUpdate = combineActions(
  Types.UPDATE_CLIENT_INFO_SUCCESS,
  Types.UPDATE_CLIENT_INFO_FAILURE,
);

export default handleActions({
  [stopLoadingSearch]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      searchClient: false,
    }
  }),
  [stopLoadingUpdate]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      updateClient: false,
    }
  }),
  [Types.GET_CLIENT_INFO_REQUEST]: (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        searchClient: true,
      }
    };
  },
  [Types.UPDATE_CLIENT_INFO_REQUEST]: (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateClient: true,
      }
    };
  },
  [Types.GET_CLIENT_INFO_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [Types.UPDATE_CLIENT_INFO_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
}, INITIAL_STATE);
