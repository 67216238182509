import { makeStyles, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  arrowBtn: {
    width: 24,
    height: 24,
    border: 0,
    background: 'none',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'end',
  },
  arrowIcon: {
    borderTop: '2px solid #FE5000',
    borderRight: '2px solid #FE5000',
    display: 'block',
    height: 8,
    width: 8,
    transform: 'rotate(135deg)',
    margin: '0 0 3px 0',

    '&.open': {
      transform: 'rotate(315deg)',
      margin: 0,
    },
  },
}));

// eslint-disable-next-line import/prefer-default-export
export const AutocompleteStyled = withStyles(() => ({
  root: {
    width: 76,
    border: '1px solid #BDC1CA',
    borderRadius: 4,
    padding: '4px 8px',
    height: 32,
    alignItems: 'center',
    display: 'flex',
  },
  listbox: {
    overflow: 'hidden',
    fontSize: 12,
    lineHeight: '18px',
    color: '#5A6474',
    padding: 0,
    border: '1px solid #BDC1CA',
    borderRadius: 4,
  },
  option: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 32,
    height: 32,
    '&:hover': {
      backgroundColor: '#FFF9E8',
      color: '#FE5000',
    },
    '&:last-child': {
      borderTop: '1px solid #BDC1CA',
      marginTop: -1,
    },
  },
  input: {
    textAlign: 'center',
    color: '#5A6474',
    fontSize: 12,
    lineHeight: '18px',
    '-moz-appearance': 'textfield',

    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '&.Mui-disabled': {
      cursor: 'auto',
    },
  },
  inputRoot: {
    '&:before': {
      border: 0,
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '&:after': {
      border: 0,
    },
  },
  groupLabel: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#5A6474',
  },
  popper: {
    width: '76px !important',
    top: '29px !important',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
  },
}))(Autocomplete);

export default useStyles;
