import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CardContent, Box } from '@material-ui/core';
import FlatCard from '../flatCard';

import useStyles from './styles';
import { toMoney } from '../../utils/parsers';

const OrderResumeTesouraria = ({
  pagamentos, pedido
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.shippingContainerTesouraria}>
      <div>
        <FlatCard>
          <Typography className={classes.titleCardResume} variant="subtitle2">
            Valores
          </Typography>
          <CardContent>
            <Box minHeight={80}>
              <div className={classes.paymentItem}>
                <Typography variant="subtitle2">
                  PRODUTOS
                </Typography>

                <Typography variant="caption">
                  {toMoney(pedido.subTotal)}
                </Typography>
              </div>

              <div className={classes.paymentItem}>
                <Typography variant="subtitle2">
                  DESCONTOS
                </Typography>

                <Typography variant="caption">
                  {toMoney(pedido.desconto)}
                </Typography>
              </div>

              <div className={classes.paymentItem}>
                <Typography variant="subtitle2">
                  JUROS
                </Typography>

                <Typography variant="caption">
                  {toMoney(pedido.juros)} 
                </Typography>
              </div>
            </Box>
          </CardContent>
        </FlatCard>
      </div>

      <div>
        <FlatCard>
          <Typography className={classes.titleCardResume} variant="subtitle2">
            Pagamento
          </Typography>
          <CardContent>
            <Box minHeight={80}>
              <div>
                {pagamentos.map((payment, index) => (
                  <Typography style={{fontSize: '12px'}}variant="subtitle2" key={`${index + 1}`}>
                    {payment.descricaoFormaPagamento} - {payment.installmentQuantity}x {toMoney(payment.installmentValue)}
                  </Typography>
                ), 0)}
              </div>
            </Box>
          </CardContent>
        </FlatCard>
      </div>
    </Box>
  );
};

OrderResumeTesouraria.propTypes = {
  pedido: PropTypes.instanceOf(Object).isRequired,
  pagamentos: PropTypes.instanceOf(Object).isRequired,
};

export default OrderResumeTesouraria;
