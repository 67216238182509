import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    padding: 24,
    margin: 0,
    maxWidth: 1200,
    gap: 12,
    justifyContent: 'center',
    borderRadius: 16,
    border: '1px solid #CFCFD4',
    '& div:first-child': {
      marginTop: 0,
    },
  },
  fullheight: {
    '&&': {
      height: 314
    }
  }
}));

export default useStyles;
