import { generateLink } from '../../../../../shared/repositories/checkoutRepository';
import apiService from '../../../../../shared/services/apiService';
import apiServicePIX from '../../../../../shared/services/apiServicePIX';
import resolveError from '../../../../../shared/utils/resolveError';
import types from './types';

export const openDialogPaymentAction = () => ({ type: types.OPEN_DIALOG });

export const closeDialogPaymentAction = () => ({ type: types.CLOSE_DIALOG });

export const openDialogPixAction = () => ({ type: types.OPEN_PIX_DIALOG });

export const openDialogPaymentStatusAction = () => ({ type: types.OPEN_PS_DIALOG });

export const closeDialogPixAction = () => ({ type: types.CLOSE_PIX_DIALOG });

export const closeDialogPaymentStatusAction = () => ({ type: types.CLOSE_PS_DIALOG });

export const updatePaymentTypeAction = (type) => ({
  type: types.UPDATE_PAYMENT_TYPE,
  payload: { type },
});

export const getInstallmentsAction = (value) => async (dispatch) => {
  dispatch({ type: types.GET_INSTALLMENTS });
  try {
    if (!value) return;
    const { data: { results } } = await apiService.post('/carrinho/parcelamento', {
      valorPrazo: value,
      valorVista: value,
    });

    dispatch({
      type: types.GET_INSTALLMENTS_SUCCESS,
      payload: { installments: results },
    });
  } catch (_e) {
    dispatch({ type: types.GET_INSTALLMENTS_FAIL });
  }
};
export const getPixQRCodeAction = (codPagamento) => async (dispatch, getState) => {
  dispatch({ type: types.GET_PIX_REQUEST });
  const { snack } = getState().main.app;
  try {
    dispatch({
      type: types.GET_PIX_SUCCESS,
      payload: {},
    });
    const results = await apiService.get(`/pagamentos/orquestrador-pix/cod-pagamento/${codPagamento}/qr-code`);
    dispatch({
      type: types.GET_PIX_SUCCESS,
      payload: results.data.results.records.qrCode,
    });
    snack.enqueueSnackbar('Pagamento por QR-Code gerado com sucesso!', {
      variant: 'success',
    });
  } catch (e) {
    dispatch({ type: types.GET_PIX_FAIL });
  }
};

export const postPixSmsAction = (codPagamento, smsValue) => async (dispatch, getState) => {
  dispatch({ type: types.POST_PIX_SMS_REQUEST });

  const { snack } = getState().main.app;
  try {
    const ddd = smsValue.substring(1, 3);
    const iniciotel = smsValue.substring(5, 10);
    const fimtel = smsValue.substring(11, 15);
    const telefone = `${iniciotel}${fimtel}`;
    const complemento = "sms";
    const results = await apiService.post('/pagamentos/orquestrador-pix/envio-pix', {
      codPagamento,
      ddd,
      telefone,
      complemento,
    });
    dispatch({
      type: types.POST_PIX_SMS_SUCCESS,
      payload: results.data,
    });
    snack.enqueueSnackbar('Pagamento enviado por sms com sucesso!', {
      variant: 'success',
    });
  } catch (e) {
    snack.enqueueSnackbar('Erro ao processar envio de pagamento!', {
      variant: 'error',
    });
    dispatch({ type: types.POST_PIX_SMS_FAIL });
  }
};
export const postPixWhatsAction = (codPagamento, whatsValue) => async (dispatch, getState) => {
  dispatch({ type: types.POST_PIX_WHATS_REQUEST });
  const { snack } = getState().main.app;
  try {
    const ddd = whatsValue.substring(1, 3);
    const iniciotel = whatsValue.substring(5, 10);
    const fimtel = whatsValue.substring(11, 15);
    const telefone = `${iniciotel}${fimtel}`;
    const complemento = "whats";
    const results = await apiService.post('/pagamentos/orquestrador-pix/envio-pix', {
      codPagamento,
      ddd,
      telefone,
      complemento,
    });
    dispatch({
      type: types.POST_PIX_WHATS_SUCCESS,
      payload: results.data,
    });
    snack.enqueueSnackbar('Pagamento enviado por whatsapp com sucesso!', {
      variant: 'success',
    });
  } catch (e) {
    snack.enqueueSnackbar('Erro ao processar envio de pagamento!', {
      variant: 'error',
    });
    dispatch({ type: types.POST_PIX_WHATS_FAIL });
  }
};
export const postPixEmailAction = (codPagamento, email) => async (dispatch, getState) => {
  dispatch({ type: types.POST_PIX_EMAIL_REQUEST });
  const { snack } = getState().main.app;
  const complemento = "email";
  try {
    const results = await apiService.post('/pagamentos/orquestrador-pix/envio-pix', {
      codPagamento,
      email,
      complemento,
    });
    dispatch({
      type: types.POST_PIX_EMAIL_SUCCESS,
      payload: results.data,
    });
    snack.enqueueSnackbar('Pagamento enviado por e-mail com sucesso!', {
      variant: 'success',
    });
  } catch (e) {
    snack.enqueueSnackbar('Erro ao processar envio de pagamento!', {
      variant: 'error',
    });
    dispatch({ type: types.POST_PIX_EMAIL_FAIL });
  }
};

export const getCheckPixAction = (
  checkoutId,
  codClient,
  checkoutIdPayment,
) => async (dispatch, getState) => {
  const { snack } = getState().main.app;
  dispatch({ type: types.GET_CHECK_PIX_REQUEST });
  try {
    const results = await apiService.get(`pagamentos/verificar-pagamento-pix/${checkoutId}/cliente/${codClient}/cod-pagamento/${checkoutIdPayment}`);

    /*if (results.data.results.response === false) {
      snack.enqueueSnackbar(results.data.results.message, {
        variant: 'info',
      });
    }*/
    dispatch({
      type: types.GET_CHECK_PIX_SUCCESS,
      payload: results.data.results.response,
    });
  } catch (e) {
    dispatch({ type: types.GET_CHECK_PIX_FAIL });
  }
};

export const generateLinkAction = ({
  idCheckout,
  value,
  idClient,
  installments,
  cep,
  name,
  installmentValue,
}) => async (_dispatch, getState) => {
  const { snack } = getState().main.app;
  try {
    const message = await generateLink({
      idCheckout,
      value,
      idClient,
      installments,
      cep,
      name,
      installmentValue,
    });
    snack.enqueueSnackbar(message, { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
  }
};
