import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 402,

    '& span': {
      fontSize: 14,
      lineHeight: '21px',
      color: '#7B7F82',
      textOverflow: 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
      overflow: 'hidden',
      display: '-webkit-box',
      wordBreak: 'break-all',

      '&:first-child': {
        color: '#0F1012',
        fontWeight: 600,
        fontSize: 16,
      },
    },

    '& .main': {
      background: '#21AD34',
      height: 25,
      display: 'inline-flex',
      borderRadius: 4,
      padding: '4px 16px',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '18px',
      color: '#FFFFFF !important',
      fontWeight: 600,
      width: 150,
      justifyContent: 'center',
      marginTop: 6,
    },

    [breakpoints.down('xs')]: {
      maxWidth: 140,
    },

    [breakpoints.between('sm', 'sm')]: {
      maxWidth: 412,
    },

    [breakpoints.down('sm')]: {
      '& span': {
        fontSize: 12,
        lineHeight: '18px',

        '&:first-child': {
          fontSize: 14,
        },
      },
    },
  },
  linkedAccountBox: {
    display: 'flex',
    gap: 16,
    borderBottom: '1px solid #CFCFD4',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 24px',

    '&:hover': {
      background: '#F8F8F8',
    },

    [breakpoints.down('sm')]: {
      padding: '20px 16px',
    },
  },
  mainBox: {
    overflowY: 'auto',
    maxHeight: 291,

    '& div:last-child': {
      border: 'none',
    },
  },
  avatar: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    fontSize: 24,
    lineHeight: '36px',
    color: '#FFFFFF',
    backgroundColor: '#CFCFD4',

    [breakpoints.down('sm')]: {
      width: 40,
      height: 40,
      fontSize: 20,
      lineHeight: '30px',
    },
  },
  mainInfoBox: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  arrowIcon: {
    width: 12,
    height: 12,
    borderTop: '3px solid #FE5000',
    borderRight: '3px solid #FE5000',
    transform: 'rotate(45deg)',

    [breakpoints.down('sm')]: {
      width: 10,
      height: 10,
    },
  },
  accountBtn: {
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 0,
    background: 'none',
    width: '100%',
    textAlign: 'start',
    cursor: 'pointer',
  },
}));

export default useStyles;
