import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Field, Form, reduxForm } from 'redux-form';
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { closeAction, updateStatusDiscountAction } from './actions';
import { InputRender } from '../../../shared/fields';

const DiscountDialog = ({
  opened, close, action, selected, idUser, handleSubmit, updateStatusDiscount,
  saving, rpp, reasonValue, setReasonValue,
}) => (
  <Dialog
    open={opened}
    onClose={close}
    aria-labelledby="form-dialog-title"
    maxWidth="xs"
    fullWidth
  >
    <Box position="relative">
      <Box position="absolute" top={4} right={4}>
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </Box>

      <Form onSubmit={handleSubmit((form) => {
        updateStatusDiscount({
          reason: form.reason,
          idDiscount: selected.descontoId,
          idUser,
          action,
          rpp,
        });
      })}
      >
        <DialogTitle
          id="form-dialog-title"
        >
          {`Tem certeza que deseja  ${action === 1 ? 'aprovar' : 'reprovar'}?`}
        </DialogTitle>
        <DialogContent>
          <Field
            disabled={saving}
            name="reason"
            label="Motivo"
            type="text"
            autoFocus
            value={reasonValue}
            onChange={(e) => setReasonValue(e.target.value)}
            component={InputRender}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            type="button"
            disabled={saving}
          >
            VOLTAR
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={saving || reasonValue.length < 5}
          >
            {action === 1 ? 'Aprovar' : 'Reprovar'}
          </Button>
        </DialogActions>
      </Form>
    </Box>
  </Dialog>
);

DiscountDialog.propTypes = {
  close: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateStatusDiscount: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  action: PropTypes.number,
  idUser: PropTypes.number.isRequired,
  saving: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  selected: PropTypes.instanceOf(Object),
  reasonValue: PropTypes.string.isRequired,
  setReasonValue: PropTypes.string.isRequired,
  rpp: PropTypes.number.isRequired,
};

const mapState = (state) => ({
  ...state.main.discount.list,
  idUser: state.auth.login.user.idVendedor,
});

export default connect(mapState, {
  close: closeAction,
  updateStatusDiscount: updateStatusDiscountAction,
})(reduxForm({
  form: 'discount/dialog',
})(DiscountDialog));
