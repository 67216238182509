import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { AES } from 'crypto-js';

import {
  List,
  ListItem,
  ListItemIcon,
  Drawer,
  Typography,
  Box,
} from '@material-ui/core';

import clsx from 'clsx';
import {
  Menu as MenuIcon,
} from '@material-ui/icons';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PaymentIcon from '@material-ui/icons/Payment';

import devPermition from '../../../shared/utils/devPermition';
import CustomNavLink from '../../../shared/components/customRouterLink';
import rolePermition from '../../../shared/utils/rolePermition';
import { getUser } from '../../../shared/services/auth';

import * as actions from '../../actions';
import { location, listen } from '../../../history';

import useStyles from './styles';

import {
  AdminDrawer,
  BinaryCodeIcon,
  CartMenu,
  DiscountIcon, HomeIcon,
  Logo,
} from '../../../assets/index';

const MainDrawer = ({
  drawer,
  width,
  setWidth,
  setDrawer,
  user,
  devAcess,
  hideNavigation,
  isCheckoutScreen,
  client,
  route,
}) => {
  const { REACT_APP_HOST_CATALAGO } = process.env;
  const classes = useStyles();
  const [active, setActive] = useState(location.pathname);
  const [hrefCatalogo, setHrefCatalogo] = useState('');
  const routesHomePage = ['', 'client-login', 'register'];
  const routesCheckoutsAndOrders = ['checkouts-orders', 'checkout', 'order'];

  const recovery = user.permissoes.find((permission) => (
    permission.id === 1
  ));
  const recoveryCoordenador = user.permissoes.find((permission) => (
    permission.id === 2
  ));
  const recoveryPedido = user.permissoes.find((permission) => (
    permission.id === 3
  ));
  const discountPermission = user.permissoes.find((permission) => (
    permission.id === 8
  ));
  const adminPermission = user.permissoes.find((permission) => (
    permission.id === 11
  ));

  const handleToggle = () => setDrawer(!drawer);
  const handleNavigate = () => {
    if (width < 960) {
      handleToggle();
    }
  };

  listen((route) => {
    setActive(route.pathname);
  });

  useEffect(() => {
    if (!rolePermition(user.idCargo)) {
      window.open(`${process.env.REACT_APP_HOST_CATALAGO}`, '_parent');
    }

    const widthListener = () => setWidth(window.innerWidth);
    window.addEventListener('resize', widthListener);
    widthListener();

    return () => window.removeEventListener('resize', widthListener);
  });
  useHotkeys('alt+m', handleToggle, [handleToggle]);

  const handleTerminalCaixa = async () => {
    let url;

    if (getUser().senha && getUser().identificacao) {
      const usuario = window.btoa(getUser().identificacao);
      const senha = window.btoa(getUser().senha);

      url = `${usuario}/${senha}/false`;
    } else {
      const emailVendedor = window.btoa(getUser().emailVendedor);
      const idVendedor = window.btoa(
        AES.encrypt(
          getUser().idVendedor.toString(),
          process.env.REACT_APP_SECRET_ENCRYPT_KEY,
        ).toString(),
      );

      url = `${emailVendedor}/${idVendedor}/true`;
    }

    window.open(`${process.env.REACT_APP_HOST_TERMINAL_CAIXA}/autorizar-vendedor/${url}`);
  };

  useEffect(() => {
    if (client) {
      if (client.checkoutId) {
        setHrefCatalogo(`${REACT_APP_HOST_CATALAGO}/trocar-orcamento/${client.checkoutId}`);
      } else {
        setHrefCatalogo(REACT_APP_HOST_CATALAGO);
      }
    } else {
      setHrefCatalogo(REACT_APP_HOST_CATALAGO);
    }
  }, [client]);

  return (
    <Drawer
      variant="permanent"
      open={drawer && !hideNavigation}
      onClose={handleToggle}
      classes={{
        paper: clsx({
          [classes.drawerPaper]: drawer,
          [classes.drawerPaperClose]: !drawer,
        }),
      }}
    >
      <List>
        <ListItem
          button
          onClick={handleToggle}
          style={{ paddingLeft: 7 }}
        >
          <ListItemIcon
            classes={{ root: classes.listItem }}
          >
            <MenuIcon style={{ color: '#ffff' }} />
            <Box position="relative">
              <img
                src={`${Logo}`}
                alt="Loja do Mecanico"
                style={{
                  marginLeft: 20, marginBottom: 5, height: 23,
                }}
              />
              <Typography variant="caption" className={classes.version}>
                {process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
          </ListItemIcon>
        </ListItem>
        <ListItem
          component={CustomNavLink}
          to="/"
          button
          onClick={handleNavigate}
          style={{ marginTop: 20, paddingLeft: 7 }}
          selected={routesHomePage.includes(route)}
        >
          <ListItemIcon
            className={clsx({ [classes.bold]: active === '/' })}
          >
            <img src={`${HomeIcon}`} alt="Página Inicial" style={{ height: 21 }} />
            <Typography className={classes.textDrawerOrçamento}>Home</Typography>
          </ListItemIcon>
        </ListItem>
        <ListItem
          component={CustomNavLink}
          to="/checkouts-orders"
          button
          onClick={handleNavigate}
          style={{ marginTop: 20, paddingLeft: 7 }}
          selected={routesCheckoutsAndOrders.includes(route)}
        >
          <ListItemIcon
            className={clsx({ [classes.bold]: active === '/checkouts-orders' })}
          >
            <DescriptionOutlinedIcon style={{ color: '#ffff' }} />
            <Typography className={classes.textDrawerOrçamento}>Orçamentos e Pedidos</Typography>
          </ListItemIcon>
        </ListItem>
        {discountPermission && (
        <ListItem
          component={CustomNavLink}
          to="/discount"
          button
          onClick={handleNavigate}
          style={{ marginTop: 20, paddingLeft: 7 }}
          selected={[route].includes('discount')}
        >

          <ListItemIcon
            className={clsx({ [classes.bold]: active === '/discount' })}
          >
            <img src={`${DiscountIcon}`} alt="Aprovação de Descontos" style={{ height: 25 }} />
            <Typography className={classes.textDrawer}>Aprovação de Descontos</Typography>
          </ListItemIcon>
        </ListItem>
        )}

        {(recovery || recoveryCoordenador || recoveryPedido) && (
        <ListItem
          component={CustomNavLink}
          to="/recovery/true"
          button
          onClick={handleNavigate}
          style={{ marginTop: 20, paddingLeft: 7 }}
          selected={[route].includes('recovery')}
        >
          <ListItemIcon
            className={clsx({ [classes.bold]: active === '/recovery/true' })}
          >
            <img src={`${CartMenu}`} alt="Recuperação de Carrinho" style={{ height: 20, marginLeft: 3 }} />
            <Typography className={classes.textDrawer}>Recuperação de Carrinho</Typography>
          </ListItemIcon>
        </ListItem>
        )}
        {adminPermission
        && (
        <ListItem
          component={CustomNavLink}
          to="/homeSales/true"
          button
          onClick={handleNavigate}
          style={{ marginTop: 20, paddingLeft: 7 }}
          selected={[route].includes('homeSales')}
        >
          <ListItemIcon
            className={clsx({ [classes.bold]: active === '/homeSales/true' })}
          >
            <img src={`${AdminDrawer}`} alt="Admin" style={{ height: 20, marginLeft: 3 }} />
            <Typography className={classes.textDrawer}>Admin</Typography>
          </ListItemIcon>
        </ListItem>
        )}

        {devAcess
        && (
        <ListItem
          component={CustomNavLink}
          to="/devAcess"
          button
          onClick={handleNavigate}
          style={{ marginTop: 20, paddingLeft: 7 }}
          selected={[route].includes('devAcess')}
        >
          <ListItemIcon
            className={clsx({ [classes.bold]: active === '/devAcess' })}
          >
            <img src={`${BinaryCodeIcon}`} alt="devAcess" style={{ height: 20, marginLeft: 3 }} />
            <Typography className={classes.textDrawer}>Acessos Desenvolvimento</Typography>
          </ListItemIcon>
        </ListItem>
        )}

        {
          user.bitTerminalVendedor && (
            <ListItem
              component={CustomNavLink}
              to="/"
              button
              onClick={handleTerminalCaixa}
              style={{ marginTop: 20, paddingLeft: 7 }}
            >
              <ListItemIcon
                className={clsx({ [classes.bold]: active === '/' })}
              >
                <AttachMoneyIcon style={{ color: '#ffff' }} />
                <Typography className={classes.textDrawerOrçamento}>Terminal de Caixa</Typography>
              </ListItemIcon>
            </ListItem>
          )
        }

        <ListItem
          component={CustomNavLink}
          to="/credit/requests"
          button
          onClick={handleNavigate}
          style={{ marginTop: 20, paddingLeft: 7 }}
          selected={[route].includes('credit')}
        >
          <ListItemIcon
            className={clsx({ [classes.bold]: active === '/credit/requests' })}
          >
            <PaymentIcon style={{ color: '#ffff' }} />
            <Typography className={classes.textDrawer}>Solicitações de Crédito</Typography>
          </ListItemIcon>
        </ListItem>

        {
          isCheckoutScreen && (
            <ListItem
              component="a"
              button
              href={hrefCatalogo}
              style={{ marginTop: 20, paddingLeft: 7 }}
            >
              <ListItemIcon
                className={clsx({ [classes.bold]: active === '/' })}
              >
                <ArrowBackIcon style={{ color: '#ffff' }} />
                <Typography className={classes.textDrawerOrçamento}>Voltar ao catálogo</Typography>
              </ListItemIcon>
            </ListItem>
          )
        }
      </List>
    </Drawer>
  );
};

MainDrawer.defaultProps = {
  drawer: true,
  hideNavigation: false,
  width: window.innerWidth,
  isCheckoutScreen: false,
};

MainDrawer.propTypes = {
  drawer: PropTypes.bool,
  hideNavigation: PropTypes.bool,
  width: PropTypes.number,
  setDrawer: PropTypes.func.isRequired,
  setWidth: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  devAcess: PropTypes.bool.isRequired,
  isCheckoutScreen: PropTypes.bool,
  client: PropTypes.instanceOf(Object).isRequired,
  route: PropTypes.string.isRequired,
};

const mapState = (state) => ({
  ...state.main.app,
  user: state.auth.login.user,
  client: state.authClient.client,
  devAcess: devPermition(state.auth.login.user.idVendedor),
});

export default connect(mapState, actions)(MainDrawer);
