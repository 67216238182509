import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: '100%',
  },
  appBar: {
    width: '100%',
    padding: '16px 2px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: palette.primary.main,
    backgroundColor: palette.background.level2,
    [breakpoints.up('sm')]: {
      height: 40,
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  title: {
    whiteSpace: 'nowrap',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    [breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
