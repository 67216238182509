import { makeStyles } from '@material-ui/core';
import { CopyIcon } from '../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  statusItem: {
    textAlign: 'center',
    textTransform: 'capitalize',
    '& h2': {
      lineHeight: 1.25,
      color: palette.text.grey1,
    },
  },
  trackingText: {
    color: palette.text.grey1,
  },
  trackingBar: {
    margin: '20px 0',
  },
  delivery: {
    padding: 16,
  },
  deliveryContainer: {
    overflowY: 'hidden',
    height: 96,
    display: 'flex',
    position: 'relative',
  },
  deliveryItemsContainer: {
    overflowX: 'auto',
    overflowY: 'hidden',
    height: 104,
  },
  deliveryItems: {
    display: 'inline-flex',
    '& article': {
      marginRight: 12,
    },
    '& img': {
      width: 82,
      height: 82,
      objectFit: 'cover',
      minWidth: 82,
    },
    '& h1': {
      marginTop: 8,
      fontWeight: 600,
      overflow: 'hidden',
      maxHeight: '4.29em',
      textOverflow: 'ellipsis',
    },
    '& h2': {
      color: palette.text.grey1,
    },
  },
  item: {
    display: 'flex',
    width: 300,
    [breakpoints.down('xs')]: {
      width: 280,
    },
  },
  active: {
    // color: ({ colorTracking }) => `${colorTracking} !important`,
    fontWeight: 600,
  },
  navigateArrow: {
    margin: 'auto 0',
    backgroundColor: palette.background.level2,
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    '&:hover': {
      backgroundColor: palette.background.level3,
    },
    '&.marginLeft': {
      right: 0,
    },
    '&.marginRight': {
      left: 0,
    },
  },
  paymentItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  shippingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: 16,
    '& > div': {
      maxWidth: 300,
      width: '100%',
      marginTop: 16,
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      '&:not(:last-of-type)': {
        marginRight: 'auto',
      },
      '& > h6': {
        fontWeight: 600,
      },
    },
  },
  shippingContainerTesouraria: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    padding: 16,
    gap: 16,
    '& > div': {
      minWidth: 300,
      marginTop: 16,
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      '& > h6': {
        fontWeight: 600,
      },
    },
  },
  titleCardResume: {
    paddingLeft: '16px',
    paddingTop: '12px',
    fontSize: '16px',
    fontWeight: 600,
  },
  paymentMethood: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'column',
    '& > img': {
      width: 100,
      height: 'auto',
      objectFit: 'cover',
      maxHeigth: 50,
    },
    '& > h6:last-of-type': {
      textAlign: 'right',
    },
  },
  companyName: {
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  headerCell: {
    background: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#FF5225',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  tableBody: {
    '& tr:first-child': {
      '& td': {
        fontWeight: 'bold',
      },
    },
  },
  cell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    textAlign: 'center',
  },
  deliveryInfo: {
    marginRight: 4,
    '& > bold': {
      fontWeight: 700,
    },
  },
  deliveryInfoDate: {
    fontSize: '0.775rem',
    '& > bold': {
      fontWeight: 700,
    },
  },
  nfeButton: {
    fontSize: '0.775rem',
  },
  columnInfo: {
    marginTop: 3,
    display: 'flex',
    flexDirection: 'column',
    color: '#707070',
  },
  trackingDialogBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    gap: 50,
  },
  trackingTitle: {
    wordBreak: 'break-all',
    display: 'flex',
    padding: 0,
    gap: 4,

    [breakpoints.down('xs')]: {
      maxWidth: 130,
    },

    [breakpoints.up('sm')]: {
      maxWidth: 360,
    },
  },
  tracking: {
    fontSize: 12,
    fontWeight: 700,
  },
  subtitle: {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'uppercase',
    marginTop: 6,
    fontSize: 12,
    lineHeight: '16px',
    color: '#000000a3',
    alignItems: 'center',
    gap: 4,
  },
  copyIcon: {
    backgroundImage: `url(${CopyIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 15,
    width: 16,
    height: 15,
  },
  btnTrackingCode: {
    display: 'flex',
    textDecoration: 'none',
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'white',
    padding: 0,
  },
  popper: {
    zIndex: 0,
    top: '14px !important',
  },
  tooltip: {
    backgroundColor: '#008000',
    fontSize: 12,
  },
  trackingCodeBox: {
    display: 'flex',
    gap: 4,
  },
  nfeBtn: {
    color: '#FA4616',
    border: '1px solid',
    fontSize: 10,
    marginTop: 6,
    '&:hover': {
      backgroundColor: '#FA46160D',
    },
  },
  trackingBox: {
    display: 'flex',
    gap: 4,
    '& span:first-child': {
      borderRight: '1px solid',
      paddingRight: 4,
    },

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    }
  },
  deliveryTesouraria: {
    padding: 16,
  },
  deliveryItemsContainerTesouraria: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto'
  },
  deliveryItemsTesouraria: {
    display: 'flex',
    flexDirection: 'row',
    gap: '14px',
    marginBottom: '14px',
    '& img': {
      width: 82,
      height: 82,
      objectFit: 'cover',
      minWidth: 82,
    },
    '& h1': {
      marginTop: 8,
      fontWeight: 600,
      overflow: 'hidden',
      maxHeight: '4.29em',
      textOverflow: 'ellipsis',
    },
    '& h2': {
      color: palette.text.grey1,
      fontWeight: 500,
      overflow: 'hidden',
      maxHeight: '4.29em',
      textOverflow: 'ellipsis',
      fontWeight: 600,
    },
  },
  itemTesouraria: {
    display: 'flex',
    alignItems: 'center',
    // width: 300,
    fontWeight: 'bold',
    [breakpoints.down('xs')]: {
      width: 280,
    },
  },
  navigateArrowTesouraria: {
    margin: 'auto 0',
    backgroundColor: palette.background.level2,
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    '&:hover': {
      backgroundColor: palette.background.level3,
    },
    '&.marginLeft': {
      right: 0,
    },
    '&.marginRight': {
      left: 0,
    },
  },
  companyNameTesouraria: {
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  deliveryInfoTesouraria: {
    fontWeight: 400,
    color: '#232b3e',
    marginRight: 4
  },
  deliveryInfoContainerTesouraria: {
    display: 'flex',
    gap: 12,
  },
  deliveryInfoShippingNumberTesouraria: {
    fontWeight: 500,
    fontSize: 16,
    color: '#232b3e'
  },
  ldmBoldTesouraria: {
    color: '#FF5225',
    fontWeight: 700
  },
  textsTrackingTesouraria: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center',
  },
  textTrackingDivTesouraria:{
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoRows: 'max-content',
    textAlign: 'center',
    marginTop: '16px',
  },
  textTrackingTesouraria: {
    margin: 0,
    fontSize: '12px',
    fontWeight: 600,
  },
  buttonTrackingContentTesouraria: {
    width: '70%',
  },
  textTrackingContentTesouraria: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonTrackingTesouraria: {
    fontSize: '10px',
    marginTop: '8px',
    fontWeight: '500'
  },
  orderData: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  dialogSchedule: {
    '& .MuiDialog-paper': {
      padding: 16,
    }
  },
  dialogScheduleClose: {
    padding: 0,
    '& .MuiIconButton-label': {
      color: '#242424',
    }
  },
  dialogScheduleBoxTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogScheduleTitle: {
    padding: 0,
    '& .MuiTypography-h6': {
      fontWeight: 700,
      color: '#242424',
      fontSize: 16
    }
  },
  dialogScheduleActions: {
    padding: 0,
    marginTop: 8,
    justifyContent: 'start',
    '& .MuiTypography-body1': {
      padding: 0,
      fontWeight: 500,
      color: '#242424',
      fontSize: 14,
      '& span': {
        fontWeight: 700,
      }
    }
  },
  dialogScheduleContent: {
    padding: 0,
    marginTop: 8,
    '& .MuiTypography-body1': {
      padding: 0,
      fontWeight: 500,
      color: '#242424',
      fontSize: 14,
      '& span': {
        fontWeight: 700,
      }
    }
  }
}));

export default useStyles;
