/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import InputRenderSmall from './InputRenderSmall';
import { toMoney } from '../utils/parsers';

const InputCurrencyRenderOutlinedTwo = ({
  input,
  onChange,
  onBlur,
  endAdornment,
  variant,
  readOnly,
  min,
  max,
  ...others
}) => {
  const handleChange = ({ target: { value } }) => {
    const parsedValue = +value.replace(/\D/g, '') / 100;
    input.onChange(
      // eslint-disable-next-line no-nested-ternary
      parsedValue >= min
        ? parsedValue <= max ? parsedValue : max
        : min,
    );
  };

  const handleBlur = ({ target: { value } }) => {
    const parsedValue = +value.replace(/\D/g, '') / 100;
    input.onBlur(
      parsedValue >= min && parsedValue <= max
        ? parsedValue
        : input.value,
    );
    if (onBlur) {
      onBlur(parsedValue >= min && parsedValue <= max
        ? parsedValue
        : input.value);
    }
  };

  const handleDrop = ({ target: { value } }) => {
    const parsedValue = +value.replace(/\D/g, '') / 100;
    input.onDrop(
      parsedValue >= min && parsedValue <= max
        ? parsedValue
        : input.value,
    );
  };

  return (
    <InputRenderSmall
      {...others}
      type="text"
      variant="outlined"
      size="small"
      input={{
        ...input,
        value: toMoney(input.value),
        onChange: handleChange,
        onBlur: handleBlur,
        onDrop: handleDrop,
      }}
      endAdornment={endAdornment}
      readOnly={readOnly}
    />
  );
};

InputCurrencyRenderOutlinedTwo.defaultProps = {
  variant: 'standard',
  min: 0,
  max: 999999999999999,
  disabled: false,
  required: false,
  onChange: () => {},
  onBlur: () => {},
  endAdornment: null,
  readOnly: false,
};

InputCurrencyRenderOutlinedTwo.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  meta: PropTypes.instanceOf(Object).isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  endAdornment: PropTypes.node,
  readOnly: PropTypes.bool,
};

export default InputCurrencyRenderOutlinedTwo;
