// eslint-disable-next-line import/no-cycle
import { Types } from './index';
import apiService from '../../shared/services/apiService';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
const normalizer = (string) => (string === '' ? null : string);

const getClientDataAction = (codCliente, tipoPessoa) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_CLIENT_INFO_REQUEST });

  try {
    const personType = tipoPessoa === 'J' ? ('juridica') : ('fisica');

    const {
      data: {
        content,
      },
    } = await apiService.get(`/clientes/pessoa-${personType}/${codCliente}`);

    dispatch({
      type: Types.GET_CLIENT_INFO_SUCCESS,
      payload: { client: { ...content } },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_CLIENT_INFO_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const updateClientDataAction = (form, tipoPessoa) => async (dispatch, getState) => {
  dispatch({ type: Types.UPDATE_CLIENT_INFO_REQUEST });

  try {
    const personType = tipoPessoa === 'J' ? ('juridica') : ('fisica');

    const data = await apiService.put(`/clientes/alterar/pessoa-${personType}`, {
      ...form,
      nomeComprador: normalizer(form.nomeComprador),
      telefone: normalizer(form.telefone),
      telefoneSecundario: form.telefoneSecundario.trim() || '',
      obsCliente: normalizer(form.obsCliente),
      rgIe: normalizer(form.produtorRural ? form.ie : ''),
      tipoPessoa,
    });

    console.log({ data });

    dispatch({
      type: Types.UPDATE_CLIENT_INFO_SUCCESS,
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.UPDATE_CLIENT_INFO_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  updateClientDataAction,
  getClientDataAction,
};
