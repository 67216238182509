import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  cardGrid: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    width: '70%',
    justifyContent: 'start',
    gap: 16,
    // marginTop: 48,

    [breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      gap: 50,
    },
  },
  boxAlign: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  clientMenuBox: {
    width: '30%',

    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  clientMenuDataBox: {
    width: '70%',

    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
