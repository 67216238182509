import React, { useLayoutEffect } from 'react';

import { PropTypes } from 'prop-types';

import { push } from '../../../../history';
import {
  CardActions,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';

import { Card, ButtonActions } from '../../_components';

import { getClient } from '../../../../shared/services/authClient';
import useStyles from './styles';

const SuccessRegister = () => {
  const {
    cardGrid,
    cardContent,
    contentTitle,
    recomendationsContent,
  } = useStyles();

  const handleHomePage = () => push('/');
  const handleCustomerSpace = () => push('/client-space/registration-data');

  useLayoutEffect(() => {
    if (!getClient()) push('/');
  }, [getClient]);

  return (
    <Grid className={cardGrid}>
      <Card
        title="Cadastro realizado com sucesso!"
        subtitle={
          <Typography align="center" variant="subtitle2">Agora você pode acompanhar seus pedidos e aproveitar todas as funcionalidades da nossa plataforma.</Typography>
        }
        icon={getClient() && getClient().tipoPessoa ==="F" ? "individual-person" : "corporate-person"}
      >

        <CardContent className={cardContent}>
          <Typography className={contentTitle}>O  que você pode fazer agora:</Typography>
          <Typography className={recomendationsContent}>
            <span>1. Acompanhar seus pedidos:</span>
            Acesse sua conta para verificar o status e detalhes dos seus pedidos.
          </Typography>
          <Typography className={recomendationsContent}>
            <span>2. Cadastrar uma nova senha:</span>
            Para sua segurança, recomendamos que você crie uma nova senha.
          </Typography>
          <Typography className={recomendationsContent}>
            Clique no link enviado para o seu email para completar o processo.
          </Typography>
        </CardContent>

        <CardActions>
          <ButtonActions
            onClick={handleHomePage}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Página Inicial
          </ButtonActions>

          <ButtonActions
            onClick={handleCustomerSpace}
            to="/client-space/registration-data"
            variant="outlined"
            color="primary"
            fullWidth
          >
            Espaço do Cliente
          </ButtonActions>
        </CardActions>
      </Card>
    </Grid>
  );
}

SuccessRegister.propTypes = {
  handleSubmit: PropTypes.func,
  snack: PropTypes.instanceOf(Object),
};

export default SuccessRegister;
