import { makeStyles, Slider, withStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  sliderBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 12,
    alignItems: 'center',
  },
  sliderSubtitle: {
    color: '#242424',
    lineHeight: '21px',
    width: '100%',
    marginBottom: 10,
  },
}));

export const SliderStyled = withStyles(() => ({
  root: {
    width: 270,
  },
  thumb: {
    color: '#FFFFFF',
    border: '1px solid #FE5000',
  },
  valueLabel: {
    color: '#FE5000',
  },
}))(Slider);

export default useStyles;
