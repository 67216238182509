import { makeStyles } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

const useStyles = makeStyles(({ breakpoints, zIndex }) => ({
  root: {
    display: 'flex',
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  itemsContainer: {
    flex: 1,
    overflowX: 'hidden',
    width: 'calc(100% - 300px)',
    transition: transitions.create('scroll', { duration: '255ms' }),
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  detailContainer: {
    borderLeft: '1px solid #dcdcdc',
    width: 300,
    minHeight: 'calc(100vh - 65px)',
    [breakpoints.down('sm')]: {
      minHeight: 0,
    },
    [breakpoints.down('xs')]: {
      width: '100%',
      borderLeft: 'none',
      borderBottom: '1px solid #dcdcdc',
    },
  },

  loadCar: {
    position: 'absolute',
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    left: '50%',
    [breakpoints.down('sm')]: {
      left: '30%',
    },
  },
  svgLogo: {
    display: 'flex',
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  svgLogoImg: {
    width: 'auto',
    height: 24,
    animation: 'zoom-in-zoom-out 1s ease infinite',
  },
  svgLogoBorder: {
    content: ' ',
    display: 'block',
    position: 'absolute',
    width: 60,
    height: 60,
    margin: 1,
    borderRadius: '50%',
    border: '3px solid #fa4616',
    borderColor: '#fa4616 transparent #fa4616 transparent',
    animation: 'borda-loading 1.2s linear infinite',
    top: -18,
    left: 60,
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: 25,
    color: '#fa4616',
    textAlign: 'center',
    lineHeight: 1.5,
    fontSize: 14,
    fontWeight: 600,
  },
  backdrop: {
    zIndex: zIndex.drawer + 1,
    color: '#fff',
    background: 'rgba(0,0,0,0.18)',
  },
  upload: {
    display: 'block',
    height: '30px',
    width: '100%',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    opacity: '0',
    cursor: 'pointer',
  },
  fileUpload: {
    textAlign: 'center',
    border: '1px dashed #2296F3',
    position: 'relative',
    cursor: 'pointer',
    width: '100%',
    height: '30px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EAF5FD',
  },
  buttonRemoveFile: {
    background: 'transparent',
    border: 'none',
    height: '15px',
    width: '15px',
    position: 'absolute',
    marginLeft: '224px',
    marginTop: '-23px',
  },
  buttonDisabled: {
    '&buttonDisabled:disabled': {
      opacity: 0.2,
      background: 'red',
    },
    'button[disabled=disabled]': {
      opacity: 0.2,
    },
  },
  containerCaixa: {
    margin: '24px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: '32px'
  },
  paperCaixa: {
    boxShadow: 'none'
  },
  buttonActions: {
    fontSize: '12px',
    marginTop: '8px',
    fontWeight: '500',
    '& .MuiButton-label': {
      gap: '12px',
    }
  }
}));

export default useStyles;
