import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';

const CashierResume = ({ label, value }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Box pr={2}>
      <Typography>{label}</Typography>
    </Box>
    <div>
      <Typography>
        <b>{value}</b>
      </Typography>
    </div>
  </Box>
);

CashierResume.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CashierResume;
