import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Button, useTheme, getLuminance, darken, lighten,
} from '@material-ui/core';

const StyledButton = ({ color, ...others }) => {
  const { palette } = useTheme();
  const Wrapper = withStyles({
    root: {
      color: palette[color].main,
      '&:hover': {
        backgroundColor: color && lighten(palette[color].main, 0.9),
      },
    },
    contained: {
      color: color && getLuminance(palette[color].main) > 450
        ? 'black'
        : 'white',
      backgroundColor: color && palette[color].main,
      '&:hover': {
        backgroundColor: color && darken(palette[color].main, 0.1),
      },
    },
    outlined: {
      borderColor: palette[color].main,
    },
  })(Button);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Wrapper {...others} />;
};

StyledButton.defaultProps = {
  color: null,
};

StyledButton.propTypes = {
  color: PropTypes.oneOf(['success', 'primary', 'secondary', 'error', 'info']),
};

export default StyledButton;
