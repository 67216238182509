/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid, Box, Link, TextField, Button,
} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/Email';
import * as QRCode from 'qrcode.react';
import { reduxForm, Field } from 'redux-form';
import { InputPhoneRender } from '../../../../../shared/fields';
import { required } from '../../../../../shared/fields/validate';
import {
  getPixQRCodeAction, postPixSmsAction, postPixWhatsAction, postPixEmailAction, closeDialogPixAction,
  closeDialogPaymentAction,
  getCheckPixAction,
} from './actions';
import { getCheckoutAction } from '../../../actions';
import qrcodeorange from '../../../../../assets/qrcodeorange.svg';
import smsIcon from '../../../../../assets/SMS.svg';
import whatsapp from '../../../../../assets/whatsapp.svg';
import emailIcon from '../../../../../assets/email.svg';
import smsWhiteIcon from '../../../../../assets/SMSWhite.svg';
import FooterInfoPix from './FooterInfoPix';
import { toMoney, toPhone, phoneMask } from '../../../../../shared/utils/parsers';

import useStyles from './styles';
import { Skeleton } from '@material-ui/lab';

const TradePaymentPix = ({
  paymentPix, setPaymentPix, checkoutId, getPixQRCode, pix, postPixSms, postPixWhats, postPixEmail,
  checkout, getCheckout, closeDialogPix, closeDialogPayment, loadingPix, pixQrCode, selectedItem, pixApiId, valueInput
}) => {
  const classes = useStyles();
  const { customer } = checkout;
  const [smsValue, setSmsValue] = useState(phoneMask(customer.phone));
  const [whatsValue, setWhatsValue] = useState(phoneMask(customer.phone));
  const [email, setEmail] = useState(customer.email);
  const [paymentId, setPaymentId] = useState(0);
  const refreshPayments = () => {
    closeDialogPayment();
    closeDialogPix();
    getCheckout({
      idCheckout: checkoutId,
      isSameDay: checkout.checkedSameday,
      isPickup: checkout.checkedPickup,
      calculateTax: checkout.resume.calculateTax,
    });
  };

  const refreshCheckout = async () => { 
    await getCheckout({
      idCheckout: checkoutId,
      isSameDay: checkout.checkedSameday,
      isPickup: checkout.checkedPickup,
      calculateTax: checkout.resume.calculateTax,
    });
  }

  const handlePixSms = async () => {
    if (!loadingPix) {
      if(pixApiId > 0) {
        postPixSms(pixApiId, smsValue);
      } else if(selectedItem.paymentId) {
        postPixSms(selectedItem.paymentId, smsValue);
      } else {
        await refreshCheckout()
        let paymentPixId = checkout.payments?.find((a) => { return a.type === 'PX' && a.paymentStatus === 1})?.paymentId
        postPixSms(paymentPixId, smsValue);
      }
    }
  };

  const handlePixWhats = async () => {
    if (!loadingPix) {
      if(pixApiId > 0) {
        postPixWhats(pixApiId, whatsValue);
      } else if(selectedItem.paymentId) {
        postPixWhats(selectedItem.paymentId, whatsValue);
      } else {
        await refreshCheckout()
        let paymentPixId = checkout.payments?.find((a) => { return a.type === 'PX' && a.paymentStatus === 1})?.paymentId
        postPixWhats(paymentPixId, whatsValue);
      }
    }
  };

  const handlePixEmail = async () => {
    if (!loadingPix) {
      if(pixApiId > 0) {
        postPixEmail(pixApiId, email);
      } else if(selectedItem.paymentId) {
        postPixEmail(selectedItem.paymentId, email);
      } else {
        await refreshCheckout()
        let paymentPixId = checkout.payments?.find((a) => { return a.type === 'PX' && a.paymentStatus === 1})?.paymentId
        postPixEmail(paymentPixId, email);
      }
    }
  };
  
  const forceRefreshPix = async () => { 
    await getCheckout({
      idCheckout: checkoutId,
      isSameDay: checkout.checkedSameday,
      isPickup: checkout.checkedPickup,
      calculateTax: checkout.resume.calculateTax,
    });
    let paymentPixId = checkout.payments?.find((a) => { return a.type === 'PX' && a.paymentStatus === 1})?.paymentId
    if(paymentPixId) {
      getPixQRCode(paymentPixId)
    } else {
      setPaymentPix('')
      setPaymentPix('qrcode')
    }
  } 

  const totalPix = checkout.payments?.find((a) => { return a.type === 'PX' && a.paymentStatus === 1})?.total
    
  useEffect(() => {    
    if (!loadingPix) {
      if (paymentPix === 'qrcode') {
        if(pixApiId > 0) { 
          getPixQRCode(pixApiId); 
        } else if(selectedItem.paymentId) {
          getPixQRCode(selectedItem.paymentId); 
        } else {
          forceRefreshPix()
        }
      }
    }
  }, [paymentPix, pix.paymentId, selectedItem, pixApiId]);

  if (paymentPix === 'whatsapp') {
    return (
      <Grid item xs={12} className={classes.gridPixSmsEmailWhats}>
        <div className={classes.divEntryItemsPix}>
          <span style={{ marginTop: 5 }}>
            <span>Você escolheu enviar o link por </span>
            <span style={{ fontWeight: 'bold' }}>Whatsapp </span>
            <span>para o número</span>
          </span>
          <div style={{ marginTop: 5 }}>
            <TextField
              name="phone"
              variant="outlined"
              size="small"
              value={phoneMask(whatsValue)}
              onChange={(number) => setWhatsValue(phoneMask(number.target.value))}
            />
          </div>
          <span style={{ marginTop: 10, fontSize: 12 }}>Os dados estão corretos?</span>
          <Grid item xs={12} className={classes.divButtons}>
            <span>
              <Button className={classes.divEnvio} onClick={() => setPaymentPix('')}>
                <ArrowBackIcon style={{ color: '#FF5225', fontSize: 18 }} />
                <span style={{ marginLeft: 10 }}>ALTERAR ENVIO</span>
              </Button>
            </span>
            <span>
              <Button className={classes.divRequest} disabled={loadingPix} onClick={() => handlePixWhats()}>
                <WhatsAppIcon style={{ color: '#FFFF', fontSize: 18 }} />
                <span style={{ marginLeft: 10, color: '#FFF' }}>ENVIAR WHATSAPP</span>
              </Button>
            </span>
          </Grid>
        </div>
        <FooterInfoPix />
      </Grid>
    );
  }
  if (paymentPix === 'sms') {
    return (
      <Grid item xs={12} className={classes.gridPixSmsEmailWhats}>
        <div className={classes.divEntryItemsPix}>
          <span style={{ marginTop: 5 }}>
            <span>Você escolheu enviar o link por </span>
            <span style={{ fontWeight: 'bold' }}>SMS </span>
            <span>para o número</span>
          </span>
          <div style={{ marginTop: 5 }}>
            <TextField
              name="phone"
              variant="outlined"
              size="small"
              value={phoneMask(smsValue)}
              onChange={(number) => setSmsValue(phoneMask(number.target.value))}
            />
          </div>
          <span style={{ marginTop: 10, fontSize: 12 }}>Os dados estão corretos?</span>
          <Grid item xs={12} className={classes.divButtons}>
            <span>
              <Button className={classes.divEnvio} onClick={() => setPaymentPix('')}>
                <ArrowBackIcon style={{ color: '#FF5225', fontSize: 18 }} />
                <span style={{ marginLeft: 10 }}>ALTERAR ENVIO</span>
              </Button>
            </span>
            <span>
              <Button className={classes.divRequest} disabled={loadingPix} onClick={() => handlePixSms()}>
                <img src={smsWhiteIcon} alt="" style={{ color: '#fff', fontSize: 18 }} />
                <span style={{ marginLeft: 10, color: '#FFF' }}>ENVIAR SMS</span>
              </Button>
            </span>
          </Grid>
        </div>
        <FooterInfoPix />
      </Grid>
    );
  } if (paymentPix === 'qrcode') {
    return (
      <Grid item xs={12} className={classes.gridPix}>
        <div className={classes.divItemsPix}>
          <Grid item xs={12} className={classes.divInfoOne}>
            <Grid item xs={6} className={classes.selectedQrCode}>
              <div style={{
                backgroundColor: 'rgba(220, 220, 220, 0.3)',
                display: 'flex',
                height: 140,
                width: 140,
                alignItems: 'center',
                borderRadius: 4,
                justifyContent: 'center',
              }}
              >
                <div style={{
                  height: 130,
                  width: 130,
                  backgroundColor: '#fff',
                  borderRadius: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                >
                  {pixQrCode.length && pix ? (

                   <QRCode value={`${pixQrCode}`} size={120} />

                  ) : (
                    <Skeleton variant={"rectangular"} width={120} height={120} />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.divInfoTwoPix}>
            <span>
              {pixQrCode.length && pix ? 
                (
                  <>
                    <span>QRCODE gerado no valor de </span>
                    <span style={{ fontWeight: 'bold' }}>
                      {selectedItem?.total ? toMoney(selectedItem.total) : toMoney(valueInput)}
                    </span>
                  </>
                ) : 
                (
                  <Skeleton variant={'text'} width={'280px'} />
                )
              }
            </span>
            <Grid item xs={12} className={classes.divButton}>
              <Button className={classes.divEnvio} onClick={() => setPaymentPix('')}>
                <ArrowBackIcon style={{ color: '#FF5225', fontSize: 18 }} />
                <span style={{ marginLeft: 5 }}>ALTERAR ENVIO</span>
              </Button>
            </Grid>
            <span
              style={{
                textDecorationLine: 'underline',
                color: '#2979f5',
                cursor: 'pointer',
              }}
              onClick={refreshPayments}
            >
              O Cliente já fez o pagamento!
            </span>
          </Grid>
        </div>
        <FooterInfoPix />
      </Grid>
    );
  } if (paymentPix === 'email') {
    return (
      <Grid item xs={12} className={classes.gridPixSmsEmailWhats}>
        <div className={classes.divEntryItemsPix}>
          <span style={{ marginTop: 5 }}>
            <span>Você escolheu enviar o link por </span>
            <span style={{ fontWeight: 'bold' }}>E-mail </span>
          </span>
          <div className={classes.divEmail}>
            <TextField
              variant="outlined"
              size="small"
              style={{ width: 300 }}
              fullWidth
              placeholder="XXXXXXX@XXXX.XXX"
              value={email}
              onChange={(text) => setEmail(text.target.value)}
            />
          </div>
          <span style={{ marginTop: 10, fontSize: 12 }}>Os dados estão corretos?</span>
          <Grid item xs={12} className={classes.divButtons} style={{ marginTop: 10 }}>
            <span>
              <Button className={classes.divEnvio} onClick={() => setPaymentPix('')}>
                <ArrowBackIcon style={{ color: '#FF5225', fontSize: 18 }} />
                <span style={{ marginLeft: 10 }}>ALTERAR ENVIO</span>
              </Button>
            </span>
            <span>
              <Button className={classes.divRequest} disabled={loadingPix} onClick={() => handlePixEmail()}>
                <EmailIcon style={{ color: '#FFFF', fontSize: 18 }} />
                <span style={{ marginLeft: 10, color: '#fff' }}>ENVIAR E-MAIL</span>
              </Button>
            </span>
          </Grid>
        </div>
        <FooterInfoPix />
      </Grid>
    );
  }
  return (
    <Grid item xs={12} className={classes.gridPix}>
      <div className={classes.divItemsPix}>
        <Grid item xs={12} className={classes.divInfoOne}>
          <Grid item xs={12} className={classes.itemPixTop} onClick={() => setPaymentPix('qrcode')}>
            <div>
              <img src={qrcodeorange} alt="" style={{ color: '#FF5225', fontSize: 20 }} />
              <span style={{ marginLeft: 10 }}>GERAR QR-CODE</span>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.itemPixBottom} onClick={() => setPaymentPix('whatsapp')}>
            <div>
              <img src={whatsapp} alt="" style={{ color: '#FF5225', fontSize: 20 }} />
              <span style={{ marginLeft: 10 }}>ENVIAR WHATSAPP</span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.divInfoTwo}>
          <Grid item xs={12} className={classes.itemPixTop} onClick={() => setPaymentPix('sms')}>
            <div>
              <img src={smsIcon} alt="" style={{ color: '#FF5225', fontSize: 20 }} />
              <span style={{ marginLeft: 10 }}>ENVIAR SMS</span>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.itemPixBottom} onClick={() => setPaymentPix('email')}>
            <div>
              <img src={emailIcon} alt="" style={{ color: '#FF5225', fontSize: 20 }} />
              <span style={{ marginLeft: 10 }}>ENVIAR E-MAIL</span>
            </div>
          </Grid>
        </Grid>
      </div>
      <FooterInfoPix />
    </Grid>
  );
};
TradePaymentPix.propTypes = {
  setPaymentPix: PropTypes.func.isRequired,
  paymentPix: PropTypes.string.isRequired,
  pix: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.number,
  ]).isRequired,
  getPixQRCode: PropTypes.func.isRequired,
  postPixEmail: PropTypes.func.isRequired,
  postPixSms: PropTypes.func.isRequired,
  postPixWhats: PropTypes.func.isRequired,
  checkoutId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
  getCheckout: PropTypes.func.isRequired,
  closeDialogPix: PropTypes.func.isRequired,
  closeDialogPayment: PropTypes.func.isRequired,
  loadingPix: PropTypes.bool.isRequired,
};
export default connect((state) => {
  const { geral, payment } = state.main.checkout;
  const { awaitPix } = payment;
  const { checkoutId } = geral.checkout;
  return {
    ...state.main.checkout.advanced,
    checkoutId,
    awaitPix,
    loadingPix: state.main.checkout.advanced.loadingPix,
    pixQrCode: state.main.checkout.advanced.pixQrCode,
  };
}, {
  getPixQRCode: getPixQRCodeAction,
  postPixSms: postPixSmsAction,
  postPixWhats: postPixWhatsAction,
  postPixEmail: postPixEmailAction,
  getCheckout: getCheckoutAction,
  closeDialogPix: closeDialogPixAction,
  closeDialogPayment: closeDialogPaymentAction,
  getCheckPixAction: getCheckPixAction,
})(reduxForm({
  form: 'checkout/advanced',
})(TradePaymentPix));
