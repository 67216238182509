import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
} from '@material-ui/core';

import * as PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import CardPaper from '../../_components/cardPaper/CardPaper';
import TitleButton from '../../_components/titleButton/TitleButton';

import useStyles from './styles';
import { AddressEditForm, AddressesList, AddressNewForm } from '../../_components';
import { goBack, push } from '../../../../history';
import { getAddressesClientPFAction, getAddressesClientPJAction } from './actions';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import CircularLoading from '../../../../shared/components/loading/CircularLoading';

const Addresses = ({
  client,
  isLoading,
  getAddressesClientPF,
  getAddressesClientPJ,
}) => {
  const {
    addressId,
  } = useParams();
  const param = window.location.pathname.split('/');
  const isNewAddressPage = param[3] === 'new';
  const isListAddressesPage = !param[3] && !addressId;
  const [component, setComponent] = useState(<></>);
  const isCorporate = client && client.tipoPessoa === 'J';

  const {
    cardGrid,
    titleTypography,
  } = useStyles();

  useEffect(() => {
    if (!client) push('/client-login');

    if (!isNewAddressPage) {
      if (isCorporate) {
        getAddressesClientPJ(client.codClienteMatriz || client.codCliente);
      } else if (isListAddressesPage) {
        getAddressesClientPF(client.codCliente);
      }
    }
  }, [client]);

  const addAddress = () => push(`${param[2]}/new`);

  useEffect(() => {
    let page = <></>;
    if (isListAddressesPage) {
      page = (
        <Box>
          <TitleButton
            title="Endereços do Cliente"
            button="Adicionar Endereço"
            widthBtn={210}
            onClick={addAddress}
          />
          <CardPaper>
            <AddressesList />
          </CardPaper>
        </Box>
      );
    } else if (isNewAddressPage) {
      page = (
        <Box>
          {/* <Typography */}
          {/*  className={titleTypography} */}
          {/* > */}
          {/*  Novo Endereço */}
          {/* </Typography> */}
          <TitleButton
            title="Novo Endereço"
            button="Voltar"
            widthBtn={210}
            onClick={() => goBack()}
            icon="back-icon"
          />
          <CardPaper>
            <AddressNewForm />
          </CardPaper>
        </Box>
      );
    } else {
      page = (
        <Box>
          {/* <Typography */}
          {/*  className={titleTypography} */}
          {/* > */}
          {/*  Alterar Endereço */}
          {/* </Typography> */}
          <TitleButton
            title="Alterar Endereço"
            button="Voltar"
            widthBtn={210}
            onClick={() => goBack()}
            icon="back-icon"
          />
          <CardPaper>
            <AddressEditForm />
          </CardPaper>
        </Box>
      );
    }

    setComponent(page);
  }, []);

  return (
    <Grid className={cardGrid}>
      <CircularLoading
        open={isLoading}
        message="Atualizando..."
      />
      {component}
    </Grid>
  );
};

Addresses.defaultProps = {
  isLoading: false,
};

Addresses.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  getAddressesClientPF: PropTypes.func.isRequired,
  getAddressesClientPJ: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

const mapState = (state) => {
  const { client } = state.authClient;
  const {
    isLoading,
  } = state.main.clientAddresses;

  return {
    client,
    isLoading,
  };
};

export default connect(mapState, {
  getAddressesClientPF: getAddressesClientPFAction,
  getAddressesClientPJ: getAddressesClientPJAction,
})(Addresses);
