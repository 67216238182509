import types from './types';
import { setFilial } from '../shared/services/app';
import apiService from '../shared/services/apiService';

export const setDrawer = (drawer) => ({
  type: types.SET_DRAWER,
  drawer,
});

export const setWidth = (width) => ({
  type: types.SET_WIDTH,
  width,
});

export const openMenuAction = (menuRef) => ({
  type: types.OPEN_MENU,
  payload: { menuRef },
});

export const closeMenuAction = () => ({
  type: types.CLOSE_MENU,
});

export const setFilialAction = (filial) => {
  setFilial(filial.idEstabelecimento);

  return ({
    type: types.SET_FILIAL,
    payload: { filial },
  });
};

export const registerSnackAction = (snack) => ({
  type: types.REGISTER_SNACK,
  payload: { snack },
});

export const getConfigAction = (snack) => async (dispatch) => {
  dispatch({ type: types.GET_CONFIGS_REQUEST });
  try {
    const { data: { results: { value: config } } } = await apiService.get('/config');
    dispatch({
      type: types.GET_CONFIGS_SUCCESS,
      payload: { config },
    });
  } catch (_e) {
    dispatch({ type: types.GET_CONFIGS_FAIL });
    snack.enqueueSnackbar('Não conseguimos buscar as configurações, pix desabilitado!', {
      variant: 'warning',
    });
  }
};
